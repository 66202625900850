import React, { useEffect, useState, createRef } from "react";
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Label,
  Loader,
} from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import * as auth from "../../modules/Auth/_redux/authRedux";
import Moment from "react-moment";

import { NavLink } from "react-router-dom";

import { useLocation, useHistory } from "react-router";
import { toAbsoluteUrl, checkIsActive } from "../../../_metronic/_helpers";

import {
  getAllParentLocations,
  getLocationByID,
  getParentBySubLoc,
  getUserData,
  IMAGES_URL,
} from "../../config/functions";
import { useDebounce } from "use-debounce";
import md5 from "md5";

import Add from "./add";
import Edit from "./edit";

import Preview from "./preview/index";

import Filters from "./filters";

import { Country, State, City } from "country-state-city";

import { connect, useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import { map, cloneDeep } from "lodash";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../components/common/userProfilePlaceholder";
import useGetCurrentTimeAccZone from "../../../hooks/useGetCurrentTimeAccZone";
import moment from "moment";
import DateConvert from "../../../utilFunctions/DateFormat";
import {
  AddIcon,
  AddIconWithCircle,
  AddSubLocationIcon,
  CloseIcon,
  CreateIcon,
  LinkBlueIcon,
  LocationBlueIcon,
  LocationIcon,
  ProcedurePlusIcon,
  SortIcon,
} from "../../../Assets/Icons/svg";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import DataTable from "react-data-table-component";
import { ArrowLeft, ArrowRight } from "../../../Assets/Icons/svg";
import Pagination from "../../components/common/CommonPagination";
import {
  fetchAllLoctionData,
  fetchLocationsInitData,
  fetchLocationsWithFilter,
} from "../../../redux/reducers/locationReducer";
import Animation from "../../components/common/CustomLoader";
// import ReactTooltip from 'react-tooltip';
const customStyles = {
  // rows: {
  //   style: {
  //     height: '72px',
  //     // override the row height
  //   },
  // },
  headCells: {
    style: {
      paddingTop: "11px", // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: "16px", // override the cell padding for data cells
      paddingRight: "16px",
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
};

const customSubStyles = {
  rows: {
    style: {
      // height: '72px',
      background: "#E9F2FF", // override the row height
    },
  },
  headCells: {
    style: {
      paddingTop: "4px", // override the cell padding for head cells
      paddingBottom: "4px",
    },
  },
  cells: {
    style: {
      paddingLeft: "16px", // override the cell padding for data cells
      paddingRight: "16px",
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
};
const Locations = (props) => {
  const dispatch = useDispatch();

  const t = useSelector(selectTranslations);
  const { userPermissions } = usePermissionsSimplified();
  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const locationId = queryParams.get("id");
  const history = useHistory();
  const [seriesEdit, setSeriesEdit] = useState(false);
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [filterOptions, setFilterOptions] = useState({
    customers: [],
    countries: [],
    cities: [],
    createdByUser: [],
    createdAt: {
      value: 0,
      text: t.locations.filter.createdAt,
    },
    priority: [],
    status: [],
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const [searchTitle, setSearchTitle] = useState("");
  const [loader, setloader] = useState(false);
  const [debounceValue] = useDebounce(searchTitle, 500);
  const [loadUserData, setLoadUserData] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const [parentIds, setParentIds] = useState([]);

  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

      location.subLocations.forEach((subLocation) => {
        if (!allIds.some((inv) => inv == subLocation?.id)) {
          allIds.push(subLocation?.id);
        }
      });
    });
  const organisationId = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.location.locationData,
    isLoading: state.location.isLoading,
    error: state.location.error,
    initData: state.location.locationInitData,
    page: state.location.page,
    pageSize: state.location.pageSize,
    total: state.location.total,
    initLoading: state.location.initLoading,
  }));
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortData, setSortData] = useState([]);
  // const [pageSize, setPageSize] = useState(25);

  // const [initData, setInitData] = useState([]);
  // const [data, setData] = useState([]);
  const [expandedRow, setExpandedRow] = useState();
  const [targetedData, setTargetedData] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [selectedParentLocation, setSelectedParentLocation] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showSubTooltip, setShowSubTooltip] = useState({});

  const ExpandedComponent = ({ data }) => {
    const columns = [
      {
        selector: (row) => row.attributes?.subLocations?.data,
        sortable: true,
        width: selectedLanguage === "en" ? "30px" : "60px",
      },
      {
        selector: (row) => row.attributes?.subLocations?.data,
        sortable: true,
        width: "50px",
      },
      {
        selector: (row) => row?.id,
        width: selectedLanguage === "en" ? "150px" : "151px",
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className="id-text-style"
          >
            {row?.attributes?.countId}
          </span>
        ),
      },
      {
        width: selectedLanguage === "en" ? "163px" : "167px",
        selector: (row) => row?.attributes?.name,
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className="id-text-style"
          >
            {row?.attributes?.name
              ? DisplayFormattedText(row?.attributes?.name, 15)
              : t.common.na}
          </span>
        ),
      },
      {
        width: "196px",
        selector: (row) => row?.attributes?.address,
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className=""
          >
            {row?.attributes?.address
              ? DisplayFormattedText(row?.attributes?.address, 19)
              : t.common.na}
          </span>
        ),
      },
      {
        selector: (row) => row?.attributes?.country,
        minWidth: "156px",
        sortable: true,
        cell: (row) =>
          row.attributes.country ? (
            <span
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
            >
              {Country.getCountryByCode(row.attributes.country)?.name}
            </span>
          ) : (
            t.common.na
          ),
      },
      {
        selector: (row) => row.attributes?.city,
        sortable: true,
        minWidth: "156px",
        cell: (row) => {
          const citiesOfCountry = State.getStatesOfCountry(
            row.attributes.country
          );
          const the_city = row.attributes.city
            ? citiesOfCountry.filter((city) => {
                return city.isoCode == row.attributes.city;
              })[0]?.name
            : t.common.na;

          const formatted_address = DisplayFormattedText(the_city, 10);

          return (
            <span
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
            >
              {formatted_address}
            </span>
          );
        },
      },
      {
        selector: (row) => row?.attributes?.customers,
        sortable: true,
        minWidth: "160px",
        cell: (row) => {
          const locationData = row?.attributes.customers?.data;
          if (locationData?.length > 0) {
            const firstLocation = locationData[0]?.attributes?.name.substring(
              0,
              12
            );
            const remainingCount = locationData.length - 1;
            const remainingLocations = locationData
              .slice(1)
              .map((location) => location.attributes.name)
              .join(", ");
            const displayText =
              remainingCount > 0
                ? `${firstLocation} ... +${remainingCount}`
                : firstLocation;
            return (
              <span
                onClick={() => {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }}
                title={locationData
                  .map((location) => location.attributes.name)
                  .join(", ")}
              >
                {displayText}
              </span>
            );
            // const displayText = locationData
            //   .map((location) => {
            //     const nameParts = location?.attributes?.name;

            //     return nameParts;
            //   })
            //   .join(', '); // Adjust the join character as needed
            // return DisplayFormattedText(displayText, 20);
          } else {
            return t.common.na;
          }
        },
      },
      // {
      //   selector: (row) =>
      //     row?.attributes?.createdByUser?.data?.attributes
      //       ? row?.attributes?.createdByUser?.data?.attributes?.firstName +
      //         ' ' +
      //         row?.attributes?.createdByUser?.data?.attributes?.lastName
      //       : t.common.na,
      //   sortable: true,
      //   cell: (row) => {
      //     let createdByUser = row?.attributes?.createdByUser?.data ? (
      //       <div>
      //         <Image
      //           avatar
      //           title={
      //             row.attributes.createdByUser.data.attributes.firstName +
      //             ' ' +
      //             row.attributes.createdByUser.data.attributes.lastName
      //           }
      //           src={
      //             row?.attributes?.createdByUser?.data?.attributes?.profileImage
      //               ?.data?.attributes?.url
      //               ? IMAGES_URL +
      //                 row?.attributes?.createdByUser?.data?.attributes
      //                   ?.profileImage?.data?.attributes?.url
      //               : LetteredAvatarImageUrl(
      //                   row?.attributes?.createdByUser?.data?.attributes
      //                     .firstName +
      //                     ' ' +
      //                     row?.attributes?.createdByUser?.data?.attributes
      //                       ?.lastName
      //                 )
      //           }
      //           style={{ width: '34px', height: '34px' }}
      //         />
      //         <span
      //           style={{ marginLeft: '4px', marginRight: '4px' }}
      //           className="id-text-style"
      //         >
      //           {row.attributes.createdByUser.data.attributes.firstName}
      //         </span>
      //       </div>
      //     ) : (
      //       t.common.na
      //     );
      //     return createdByUser;
      //   },
      // },

      {
        minWidth: "200px",
        name: t.locations.form.propertyType,
        selector: (row) => row.attributes?.propertyType,
        sortable: true,
        cell: (row) => {
          const locationData = row.attributes?.propertyType;
          if (locationData && Array.isArray(locationData)) {
            const displayText = locationData
              .map((location) => {
                const nameParts = location?.property;

                return nameParts;
              })
              .join(", "); // Adjust the join character as needed
            return (
              <span
                onClick={() => {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }}
              >
                {displayText
                  ? DisplayFormattedText(displayText, 20)
                  : t.common.na}
              </span>
            );
          } else {
            return (
              <span
                onClick={() => {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }}
              >
                {t.common.na}{" "}
              </span>
            );
          }
        },
      },

      {
        selector: (row) => row.attributes?.subLocations?.data,
        sortable: true,
      },
    ];
    const filteredDataSub =
      data?.attributes?.subLocations?.data?.filter(
        (item) =>
          allIds.includes(item.id)
      ) || [];
    return (
      <>
        <DataTable
          columns={columns}
          className="datatable-div"
          data={
            !(role === "super_admin" || role === "admin")
              ? filteredDataSub
              : data?.attributes?.subLocations?.data
          }
          // expandableRows
          // expandableRowsComponent={<ExpandedRowComponent />}

          direction={selectedLanguage === "en" ? "ltr" : "rtl"}
          onRowClicked={async (row) => {
            const reponse = await getLocationByID(row.id, organisationId);
            //console.log('reponse', reponse);
            if (reponse.status == 200) {
              setTargetedData(reponse.data.data[0]);
              setTogglePreviw(true);
            }
          }}
          customStyles={customSubStyles}
          persistTableHead={true}
          pointerOnHover={true}
          highlightOnHover={false}
          noTableHead
        />
        {/* {data?.attributes?.subLocations?.data.length > 0 ? (
          data.attributes.subLocations.data.map((sub) => {
            const subCitiesOfCountry = State.getStatesOfCountry(
              sub.attributes.country
            );
            const sub_city = sub.attributes.city
              ? subCitiesOfCountry.filter((city) => {
                  return city.isoCode == sub.attributes.city;
                })[0].name
              : t.common.na;

            let createdByUserSub = sub.attributes.createdByUser?.data
              ?.attributes ? (
              <div>
                <Image
                  avatar
                  title={
                    sub.attributes.createdByUser?.data?.attributes.firstName +
                    ' ' +
                    sub.attributes.createdByUser?.data?.attributes.lastName
                  }
                  src={
                    sub.attributes.createdByUser?.data?.attributes?.profileImage
                      ?.data?.attributes?.url
                      ? IMAGES_URL +
                        sub.attributes.createdByUser?.data?.attributes
                          ?.profileImage?.data?.attributes?.url
                      : LetteredAvatarImageUrl(
                          sub.attributes.createdByUser?.data?.attributes
                            .firstName +
                            ' ' +
                            sub.attributes.createdByUser?.data?.attributes
                              .lastName
                        )
                  }
                  style={{ width: '20px', height: '20px' }}
                />
                <span style={{ marginLeft: '4px' }}>
                  {sub.attributes.createdByUser?.data?.attributes.firstName}
                </span>
              </div>
            ) : (
              t.common.na
            );
            // let customerIMG = t.common.na;
            // if (sub.attributes.customer.data) {
            //   customerIMG = sub.attributes.customer.data
            //     .attributes?.logo.data ? (
            //     <div>
            //       <Image
            //         avatar
            //         title={
            //           sub.attributes.customer.data?.attributes
            //             .name
            //         }
            //         src={
            //           IMAGES_URL +
            //           sub.attributes.customer.data?.attributes
            //             ?.logo?.data?.attributes?.url
            //         }
            //       />
            //       <span style={{ marginLeft: '4px' }}>
            //         {
            //           sub.attributes.customer.data.attributes
            //             .name
            //         }
            //       </span>
            //     </div>
            //   ) : (
            //     <div>
            //       <Image
            //         avatar
            //         title={
            //           sub.attributes.customer.data.attributes
            //             .name
            //         }
            //         src={LetteredAvatarImageUrl(
            //           sub.attributes.customer.data.attributes
            //             .name
            //         )}
            //       />
            //       <span style={{ marginLeft: '4px' }}>
            //         {
            //           sub.attributes.customer.data.attributes
            //             .name
            //         }
            //       </span>
            //     </div>
            //   );
            // }
            let assigneePeople = sub?.attributes?.customers?.data;
            let totalLength = sub?.attributes?.customers?.data
              ? sub?.attributes?.customers?.data.length
              : 0;
            console.log('length', totalLength);
            if (assigneePeople?.length) {
              if (assigneePeople?.length < 3) {
                assigneePeople = assigneePeople.map((people, index) => {
                  if (people?.attributes?.logo?.data?.attributes?.url) {
                    return (
                      <Image
                        key={index}
                        avatar
                        title={people.attributes.name + ' '}
                        src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                        style={
                          index != 0
                            ? {
                                marginLeft: '-6px',
                                width: '34px',
                                height: '34px',
                              }
                            : { width: '34px', height: '34px' }
                        }
                        className={index === 0 ? 'img-first' : ''}
                      />
                    );
                  } else {
                    return (
                      <UserProfilePlaceholder
                        key={index}
                        name={`${
                          people?.attributes?.name
                            ? people?.attributes?.name
                            : ''
                        } ${' '}`}
                        width={'34px'}
                        height={'34px'}
                        className={index === 0 ? 'img-first' : ''}
                      />
                    );
                  }
                });
              } else {
                assigneePeople = assigneePeople
                  .slice(0, 2)
                  .map((people, index) => {
                    if (people?.attributes?.logo?.data?.attributes?.url) {
                      return (
                        <Image
                          key={index}
                          avatar
                          title={people.attributes.name + ' '}
                          src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                          style={
                            index != 0
                              ? {
                                  marginLeft: '-6px',
                                  width: '34px',
                                  height: '34px',
                                }
                              : { width: '34px', height: '34px' }
                          }
                        />
                      );
                    } else {
                      return (
                        <UserProfilePlaceholder
                          key={index}
                          name={`${
                            people?.attributes?.name
                              ? people?.attributes?.name
                              : ''
                          } ${' '}`}
                          width={'34px'}
                          height={'34px'}
                        />
                      );
                    }
                  });

                assigneePeople.push(
                  <Image
                    key="moreImage"
                    onClick={(e) => {
                      e.stopPropagation();
                      setTargetedData(sub);
                      setTogglePreviw(true);
                    }}
                    avatar
                    title={`${totalLength - 2}`}
                    src={`https://ui-avatars.com/api/?name=${totalLength -
                      2}%2B&background=8CCA9D&bold=true&color=4FAB68`}
                    style={{
                      width: '34px',
                      height: '34px',
                    }}
                  />
                );
              }
            } else {
              assigneePeople = t.common.na;
            }

            return (
              <Table.Row
                className="sub-location-div d-flex"
                data-parent={data.id}
                key={sub.id}
                onClick={async () => {
                  const reponse = await getLocationByID(sub.id, organisationId);
                  {
                    console.log(reponse, 'reponse');
                  }
                  //console.log('reponse', reponse);
                  if (reponse.status == 200) {
                    setTargetedData(reponse.data.data[0]);
                    setTogglePreviw(true);
                  }
                }}
              >
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell>{sub.id}</Table.Cell>
                <Table.Cell>
                  <Icon name="clone" />
                  {sub.attributes.name}
                </Table.Cell>
                <Table.Cell>
                  {sub.attributes.address
                    ? sub.attributes.address
                    : t.common.na}
                </Table.Cell>
                <Table.Cell>
                  {sub.attributes.country
                    ? Country.getCountryByCode(sub.attributes.country).name
                    : t.common.na}
                </Table.Cell>
                <Table.Cell>{sub_city}</Table.Cell>
                <Table.Cell>
                  <div className="multi-profile-container">
                    {assigneePeople}
                  </div>
                </Table.Cell>
                <Table.Cell>{createdByUserSub}</Table.Cell>
              </Table.Row>
            );
          })
        ) : (
          <span>nopt datadad</span>
        )} */}
      </>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadUserData(true);
      let parentdata = []
      if (!(role === "super_admin" || role === "admin")) {
        
        const userData = await getUserData(user.id);
        dispatch(props.fulfillUser(userData));

        const Ids = await getParentBySubLoc();
        setParentIds(Ids);
        parentdata = Ids;
      }
    
      const fetchData = await dispatch(
        fetchLocationsInitData({
          organisationId,
          role,
          allIds: parentdata,
          currentUserId,
        })
      );
      //console.log('fetchData', fetchData);
      setLoadUserData(false);
      if (fetchData) {
        // setData(fetchData);
        // setInitData(fetchData);

        setFetchLoading(false);
        if (locationId) {
      
          let fetchallData = await dispatch(
            fetchAllLoctionData({
              organisationId,
              role,
              allIds: parentdata,
              currentUserId,
            })
          );

          let selectedLocation = null;
          for (let i = 0; i < fetchallData.payload.response.length; i++) {
            let each = cloneDeep(fetchallData.payload.response[i]);
          
            if (each?.attributes?.countId == locationId) {
              selectedLocation = each;
              break;
            } else {
              if (each?.attributes?.subLocations?.data) {
                for (
                  let j = 0;
                  j < each.attributes.subLocations.data.length;
                  j++
                ) {
                  let sub = cloneDeep(each.attributes.subLocations.data[j]);
                  //console.log('sub', sub);
                  if (sub.attributes?.countId == locationId) {
                    const reponse = await getLocationByID(
                      sub.id,
                      organisationId
                    );
                    // //console.log('reponse', reponse);
                    if (reponse.status == 200) {
                      selectedLocation = reponse.data.data[0];
                    }
                    break;
                  }
                }
              }
              if (selectedLocation) {
                break;
              }
            }
          }

          if (selectedLocation) {
            setTargetedData(selectedLocation);
            setTogglePreviw(true);
          }
        }
      }
    };

    // //console.log(data);

    fetchData();
  }, [refresh]);

  const handleSort = async (column, sortDirection) => {
    setloader(true);

    // Assuming the selector represents the field to sort
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchLocationsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        country: filterOptions.countries,
        city: filterOptions.cities,
        rate: filterOptions.rate,
        sort,
        role,
        allIds: parentIds,
        currentUserId,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchLocationsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        country: filterOptions.countries,
        city: filterOptions.cities,
        rate: filterOptions.rate,
        sort,
        role,
        allIds: parentIds,
        currentUserId,
      })
    );

    setloader(false);
  };

  const columns = [
    {
      selector: (row) => row.attributes?.subLocations?.data,
      // sortable: true,
      width: selectedLanguage === "en" ? "30px" : "60px",
      cell: (row) =>
        row.attributes.subLocations.data.length > 0 ? (
          <div
            className="sub-header-count align-center"
            onClick={(e) => {
              e.stopPropagation();
              if (expandedRow !== row.id) {
                setExpandedRow(row.id);
              } else {
                setExpandedRow(null);
              }
            }}
          >
            {/* <span
              className="link-text-location"
              style={
                {
                  // position: 'relative',
                  // // borderRadius: '2px',
                  // cursor: 'pointer',
                }
              }
              onMouseEnter={() =>
                setShowSubTooltip({ ...showSubTooltip, [row.id]: true })
              }
              onMouseLeave={() =>
                setShowSubTooltip({ ...showSubTooltip, [row.id]: false })
              }
            >
              <Tooltip
                color="#fff"
                backgroundColor="#788CA5"
                position="top center"
                show={showSubTooltip[row.id]}
                textBoxWidth={selectedLanguage == 'ar' ? '60px' : '65px'}
                arrowAlign={selectedLanguage == 'ar' ? 'center' : 'end'}
                fontSize="9px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="8px 0px 8px 8px"
                borderRadius="5px"
                static
                moveUp="-18px"
                moveRight={selectedLanguage == 'ar' ? '-10px' : '5px'}
              >
                <span className="work-order-tooltip">
                  {t.locations.sub_location_tooltip}
                </span>
              </Tooltip>
              <span className="location-length">
                {row.attributes.subLocations.data.length}
              </span>
            </span> */}
          </div>
        ) : (
          <Table.Cell className="aside-counter flexbox align-center"></Table.Cell>
        ),
    },
    {
      name: t.locations.table.ID,
      // selector: (row) => row?.id,
      sortField: "countNumId",
      width: selectedLanguage === "en" ? "135px" : "141px",
      sortable: true,
      cell: (row) => {
        const filteredDataSub =
          row?.attributes?.subLocations?.data?.filter((item) =>
            allIds?.includes(item.id)
          ) || [];

        const subLocationCount = !(role === "super_admin" || role === "admin")
          ? filteredDataSub?.length
          : row?.attributes?.subLocations?.data?.length;
        return selectedLanguage === "en" ? (
          <div
            onClick={() => {
              if (!(role === "super_admin" || role === "admin")) {
                if (
                  allIds?.includes(row.id)
                ) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className={`id-text-style`}
          >
            {row?.attributes?.countId}
            {subLocationCount > 0 ? (
              <span
                style={{ color: "#0C66E4" }}
                className="ml-2 mr-2 link-span"
              >
                <LinkBlueIcon />
                <span className="ml-1">{subLocationCount}</span>
              </span>
            ) : null}
          </div>
        ) : (
          <div
            onClick={() => {
              if (!(role === "super_admin" || role === "admin")) {
                if (
                  allIds?.includes(row.id)
                ) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className={`id-text-style d-flex`}
          >
            {row?.attributes?.countId}
            {subLocationCount ? (
              <span
                style={{ color: "#0C66E4" }}
                className="ml-2 mr-2 link-span"
              >
                <span className="ml-1">{subLocationCount}</span>
                <LinkBlueIcon />
              </span>
            ) : null}
          </div>
        );
      },
    },
    {
      name: t.locations.form.location_name,
      width: "180px",
      // selector: (row) => row?.attributes?.name,
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            if (!(role === "super_admin" || role === "admin")) {
              if (
                allIds?.includes(row.id)
              ) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
          className="id-text-style"
        >
          {row?.attributes?.name
            ? DisplayFormattedText(row?.attributes?.name, 19)
            : t.common.na}
        </span>
      ),

      // cell: (row) => (
      //   <div className="d-flex align-items-center">
      //     <Label
      //       circular
      //       empty
      //       color={row?.attributes?.status ? 'green' : '#788ca5'}
      //       className="ml-4 mr-4"
      //     />
      //     <div>
      //       <span className="id-text-style">
      //         {DisplayFormattedText(row?.attributes?.name, 8)}
      //       </span>
      //     </div>
      //   </div>
      // ),
    },
    {
      name: t.locations.table.address,
      width: "196px",
      // selector: (row) => row?.attributes?.address,
      sortField: "address",
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            if (!(role === "super_admin" || role === "admin")) {
              if (
                allIds?.includes(row.id)
              ) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
          className=""
        >
          {row?.attributes?.address
            ? DisplayFormattedText(row?.attributes?.address, 19)
            : t.common.na}
        </span>
      ),
      // cell: (row) => (
      //   <div className="d-flex align-items-center">
      //     <Label
      //       circular
      //       empty
      //       color={row?.attributes?.status ? 'green' : '#788ca5'}
      //       className="ml-4 mr-4"
      //     />
      //     <div>
      //       <span className="id-text-style">
      //         {DisplayFormattedText(row?.attributes?.name, 8)}
      //       </span>
      //     </div>
      //   </div>
      // ),
    },
    {
      name: t.locations.table.country,
      // selector: (row) => row?.attributes?.country,
      sortField: "countryName",
      sortable: true,
      minWidth: "156px",
      cell: (row) =>
        row.attributes.country ? (
          <span
            onClick={() => {
              if (!(role === "super_admin" || role === "admin")) {
                if (
                  allIds?.includes(row.id) 
                ) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {DisplayFormattedText(
              Country.getCountryByCode(row.attributes.country)?.name,
              15
            ) || "N/A"}
          </span>
        ) : (
          t.common.na
        ),
      // cell: (row) => (
      //   <div className="d-flex align-items-center">
      //     <Label
      //       circular
      //       empty
      //       color={row?.attributes?.status ? 'green' : '#788ca5'}
      //       className="ml-4 mr-4"
      //     />
      //     <div>
      //       <span className="id-text-style">
      //         {DisplayFormattedText(row?.attributes?.name, 8)}
      //       </span>
      //     </div>
      //   </div>
      // ),
    },
    {
      name: t.locations.table.city,
      minWidth: "156px",
      // selector: (row) => row.attributes?.city,
      sortField: "cityName",
      sortable: true,
      cell: (row) => {
        const citiesOfCountry = State.getStatesOfCountry(
          row.attributes.country
        );
        const the_city = row?.attributes?.city
          ? citiesOfCountry.filter((city) => {
              return city.isoCode == row?.attributes?.city;
            })[0]?.name
          : t.common.na;

        const formatted_address = DisplayFormattedText(the_city, 10);

        return (
          <span
            onClick={() => {
              if (!(role === "super_admin" || role === "admin")) {
                if (
                  allIds?.includes(row.id) 
                ) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {formatted_address}
          </span>
        );
      },
    },
    {
      name: t.locations.table.customer,
      // selector: (row) => row?.attributes?.serialNumber,
      sortField: "customers.name",
      sortable: true,
      minWidth: "160px",
      cell: (row) => {
        const locationData = row?.attributes.customers?.data;
        if (locationData?.length > 0) {
          const firstLocation = locationData[0]?.attributes?.name.substring(
            0,
            12
          );
          const remainingCount = locationData.length - 1;
          const remainingLocations = locationData
            .slice(1)
            .map((location) => location.attributes.name)
            .join(", ");
          const displayText =
            remainingCount > 0
              ? `${firstLocation} ... +${remainingCount}`
              : firstLocation;
          return (
            <span
              onClick={() => {
                if (!(role === "super_admin" || role === "admin")) {
                  if (
                    allIds?.includes(row.id) 
                  ) {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }
                } else {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              }}
              title={locationData
                .map((location) => location.attributes.name)
                .join(", ")}
            >
              {displayText}
            </span>
          );
          // const displayText = locationData
          //   .map((location) => {
          //     const nameParts = location?.attributes?.name;

          //     return nameParts;
          //   })
          //   .join(', '); // Adjust the join character as needed
          // return DisplayFormattedText(displayText, 20);
        } else {
          return t.common.na;
        }
      },
    },

    {
      width: "200px",
      name: t.locations.form.propertyType,
      // selector: (row) => row.attributes?.propertyType,
      sortField: "propertyType.property",
      sortable: true,
      cell: (row) => {
        const locationData = row.attributes?.propertyType;
        if (locationData && Array.isArray(locationData)) {
          const displayText = locationData
            .map((location) => {
              const nameParts = location?.property;

              return nameParts;
            })
            .join(", "); // Adjust the join character as needed
          return (
            <span
              onClick={() => {
                if (!(role === "super_admin" || role === "admin")) {
                  if (
                    allIds?.includes(row.id)
                  ) {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }
                } else {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              }}
            >
              {displayText
                ? DisplayFormattedText(displayText, 20)
                : t.common.na}
            </span>
          );
        }
      },
    },

    {
      name: t.locations.add_sub,
      selector: (row) => row,
      // sortable: true,
      cell: (row) => (
        <>
          {userPermissions?.location?.create ? (
            <span
              title="Add Sub-Location"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedParentLocation(row);
                setToggleAsideModal(true);
              }}
            >
              <span className="item-button-icon-container">
                <AddIconWithCircle width="20px" height="20px" />
              </span>
            </span>
          ) : (
            <span style={{ height: "32px" }} className=""></span>
          )}

          {/* {expandedRow == row.id
            ? row.attributes.subLocations.data.map((sub) => {
                const subCitiesOfCountry = State.getStatesOfCountry(
                  sub.attributes.country
                );
                const sub_city = sub.attributes.city
                  ? subCitiesOfCountry.filter((city) => {
                      return city.isoCode == sub.attributes.city;
                    })[0].name
                  : t.common.na;

                let createdByUserSub = sub.attributes.createdByUser?.data
                  ?.attributes ? (
                  <div>
                    <Image
                      avatar
                      title={
                        sub.attributes.createdByUser?.data?.attributes
                          .firstName +
                        ' ' +
                        sub.attributes.createdByUser?.data?.attributes.lastName
                      }
                      src={
                        sub.attributes.createdByUser?.data?.attributes
                          ?.profileImage?.data?.attributes?.url
                          ? IMAGES_URL +
                            sub.attributes.createdByUser?.data?.attributes
                              ?.profileImage?.data?.attributes?.url
                          : LetteredAvatarImageUrl(
                              sub.attributes.createdByUser?.data?.attributes
                                .firstName +
                                ' ' +
                                sub.attributes.createdByUser?.data?.attributes
                                  .lastName
                            )
                      }
                      style={{ width: '20px', height: '20px' }}
                    />
                    <span style={{ marginLeft: '4px' }}>
                      {sub.attributes.createdByUser?.data?.attributes.firstName}
                    </span>
                  </div>
                ) : (
                  t.common.na
                );
                // let customerIMG = t.common.na;
                // if (sub.attributes.customer.data) {
                //   customerIMG = sub.attributes.customer.data
                //     .attributes?.logo.data ? (
                //     <div>
                //       <Image
                //         avatar
                //         title={
                //           sub.attributes.customer.data?.attributes
                //             .name
                //         }
                //         src={
                //           IMAGES_URL +
                //           sub.attributes.customer.data?.attributes
                //             ?.logo?.data?.attributes?.url
                //         }
                //       />
                //       <span style={{ marginLeft: '4px' }}>
                //         {
                //           sub.attributes.customer.data.attributes
                //             .name
                //         }
                //       </span>
                //     </div>
                //   ) : (
                //     <div>
                //       <Image
                //         avatar
                //         title={
                //           sub.attributes.customer.data.attributes
                //             .name
                //         }
                //         src={LetteredAvatarImageUrl(
                //           sub.attributes.customer.data.attributes
                //             .name
                //         )}
                //       />
                //       <span style={{ marginLeft: '4px' }}>
                //         {
                //           sub.attributes.customer.data.attributes
                //             .name
                //         }
                //       </span>
                //     </div>
                //   );
                // }
                let subCustomers = sub.attributes?.customers?.data;
                if (subCustomers?.length > 0) {
                  subCustomers = subCustomers.map((people, index) => {
                    if (people?.attributes?.logo?.data?.attributes?.url) {
                      return (
                        <Image
                          avatar
                          title={people.attributes.name + ' ' + ' '}
                          src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                          style={
                            index != 0
                              ? {
                                  marginLeft: '-6px',
                                  width: '20px',
                                  height: '20px',
                                }
                              : {
                                  width: '20px',
                                  height: '20px',
                                }
                          }
                        />
                      );
                    } else {
                      return (
                        <UserProfilePlaceholder
                          name={`${
                            people?.attributes?.name
                              ? people?.attributes?.name
                              : ''
                          } ${''}`}
                        />
                      );
                    }
                  });
                } else {
                  subCustomers = '';
                }
                return (
                  <Table.Row
                    className="sub-location"
                    data-parent={row.id}
                    key={sub.id}
                    onClick={async () => {
                      const reponse = await getLocationByID(
                        sub.id,
                        organisationId
                      );
                      //console.log('reponse', reponse);
                      if (reponse.status == 200) {
                        setTargetedData(reponse.data.data[0]);
                        setTogglePreviw(true);
                      }
                    }}
                  >
                    <Table.Cell>{sub.id}</Table.Cell>
                    <Table.Cell>
                      <Icon name="clone" />
                      {sub.attributes.name}
                    </Table.Cell>
                    <Table.Cell>
                      {sub.attributes.address
                        ? sub.attributes.address
                        : t.common.na}
                    </Table.Cell>
                    <Table.Cell>
                      {sub.attributes.country
                        ? Country.getCountryByCode(sub.attributes.country).name
                        : t.common.na}
                    </Table.Cell>
                    <Table.Cell>{sub_city}</Table.Cell>
                    <Table.Cell>
                      {subCustomers ? subCustomers : t.common.na}
                    </Table.Cell>
                    <Table.Cell>{createdByUserSub}</Table.Cell>
                    
                   
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                );
              })
            : ''} */}
        </>
      ),
    },
    // {
    //   name: t.assets.table.model,
    //   selector: (row) => row?.attributes?.model,
    //   sortable: true,
    //   cell: (row) =>
    //     DisplayFormattedText(row?.attributes?.model) || t.common.na,
    // },
  ];

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const totalFilteredPages = Math.ceil(data.length / pageSize);

  return (
    <>
         <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {" "}
            <LocationIcon />
            {t.locations.title}
          </Header>
          <div></div>

          {userPermissions?.location?.create && initData.length > 0 ? (
          //    <button
          //    onClick={() => {
          //      setToggleAsideModal(true);
          //    }}
          //    className={`add-item-btn ${
          //      selectedLanguage == "en" ? "mr-7" : "ml-7"
          //    }`}
          //  >
          //    <span>
          //      <CreateIcon />
          //      {t.locations.create_location}
          //    </span>
          //  </button>
            <Button
              icon="add"
              primary
              className={`add-item-btn ${
                selectedLanguage == "en" ? "mr-7" : "ml-7"
              }`}
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.locations.create_location}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
        {!(initLoading || loadUserData)  && initData?.length > 0 ? (
          <div className="new-listing-filter wo-filter sticky-filter mt-8">
           <Filters
                  data={initData}
               
                  allIds={allIds}
                  parentIds={parentIds}
                  currentUserId={currentUserId}
                  role={role}
                  setDataByFilter={handleSetDataByFilter}
                  hasStatus={false}
                  fileData={data}
                  setFilterOptions={setFilterOptions}
                  filterOptions={filterOptions}
                  setloader={setloader}
                  loader={loader}
                  setSortData={setSortData}
                  sortData={sortData}
                  setSearchTitle={setSearchTitle}
                  searchTitle={searchTitle}
                  debounceValue={debounceValue}
                />
          </div>
        ) : null}
      </div>
    <div className="new-listing-container">

      {/* <Header as="h2">{t.locations.title}</Header>
      <div className="header-actions">
        <Button.Group basic>
          <NavLink to="/locations">
            <Button>
              <Icon name="list ul" color="black" />
              {t.locations.tabs.first}
            </Button>
          </NavLink>

          <NavLink to="/locations-map-view">
            <Button>
              <Icon name="map outline" color="black" />
              {t.locations.tabs.second}
            </Button>
          </NavLink>
        </Button.Group>
        {userPermissions?.location?.create ? (
          <Button
            icon="add"
            content={t.locations.add}
            primary
            onClick={() => {
              setToggleAsideModal(true);
            }}
          />
        ) : null}
      </div> */}
      {initLoading || loadUserData ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "500px" }}
        >
          <Animation />
          {/* <Placeholder fluid>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder> */}
        </div>
      ) : (
        // <div>
        //   <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
        <div>
          <div className="new-listing-filter">
            {initData?.length > 0 ? (
              <>
                
                {/* <Table selectable>
                <Table.Header>
                  <Table.Row>
              
                    <Table.HeaderCell>{t.locations.table.ID}</Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.locations.table.name}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.locations.table.address}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.locations.table.country}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.locations.table.city}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.locations.table.customer}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.locations.table.createdByUser}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.locations.table.createdAt}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.locations.table.updatedAt}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t.locations.table.action}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {getPaginatedData(data)?.map((el) => {
                    const citiesOfCountry = State.getStatesOfCountry(
                      el.attributes.country
                    );
                    const the_city = el.attributes.city
                      ? citiesOfCountry.filter((city) => {
                          return city.isoCode == el.attributes.city;
                        })[0].name
                      : t.common.na;

                    let createdByUser = el.attributes.createdByUser?.data
                      ?.attributes ? (
                      <div>
                        <Image
                          avatar
                          title={
                            el.attributes.createdByUser?.data?.attributes
                              .firstName +
                            ' ' +
                            el.attributes.createdByUser?.data?.attributes
                              .lastName
                          }
                          src={
                            el.attributes.createdByUser?.data?.attributes
                              .profileImage?.data?.attributes?.url
                              ? IMAGES_URL +
                                el.attributes.createdByUser?.data?.attributes
                                  .profileImage?.data?.attributes?.url
                              : LetteredAvatarImageUrl(
                                  el.attributes.createdByUser?.data?.attributes
                                    .firstName +
                                    ' ' +
                                    el.attributes.createdByUser?.data
                                      ?.attributes.lastName
                                )
                          }
                          style={{ width: '20px', height: '20px' }}
                        />
                        <span style={{ marginLeft: '4px' }}>
                          {
                            el.attributes.createdByUser?.data?.attributes
                              .firstName
                          }
                        </span>
                      </div>
                    ) : (
                      t.common.na
                    );
                    let customers = el.attributes?.customers?.data;
                    // if (el.attributes?.customer?.data) {
                    //   customerIMG = el.attributes?.customer?.data?.attributes
                    //     ?.logo.data ? (
                    //     <div>
                    //       <Image
                    //         avatar
                    //         title={el.attributes.customer.data?.attributes.name}
                    //         src={
                    //           IMAGES_URL +
                    //           el.attributes.customer.data?.attributes.logo.data
                    //             ?.attributes.url
                    //         }
                    //       />
                    //       <span style={{ marginLeft: '4px' }}>
                    //         {el.attributes.customer.data.attributes.name}
                    //       </span>
                    //     </div>
                    //   ) : (
                    //     <div>
                    //       <Image
                    //         avatar
                    //         title={
                    //           el.attributes?.customer?.data?.attributes?.name
                    //         }
                    //         src={LetteredAvatarImageUrl(
                    //           el.attributes?.customer?.data?.attributes?.name
                    //         )}
                    //       />
                    //       <span style={{ marginLeft: '4px' }}>
                    //         {el.attributes?.customer?.data?.attributes?.name}
                    //       </span>
                    //     </div>
                    //   );
                    // }
                    if (customers?.length > 0) {
                      customers = customers.map((people, index) => {
                        if (people?.attributes?.logo?.data?.attributes?.url) {
                          return (
                            <Image
                              avatar
                              title={people.attributes.name + ' ' + ' '}
                              src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                              style={
                                index != 0
                                  ? {
                                      marginLeft: '-6px',
                                      width: '20px',
                                      height: '20px',
                                    }
                                  : { width: '20px', height: '20px' }
                              }
                            />
                          );
                        } else {
                          return (
                            <UserProfilePlaceholder
                              name={`${
                                people?.attributes?.name
                                  ? people?.attributes?.name
                                  : ''
                              } ${''}`}
                            />
                          );
                        }
                      });
                    } else {
                      customers = '';
                    }
                    return (
                      <>
                        <Table.Row
                          key={el.id}
                          id={el.id}
                          className="accordion-row"
                          onClick={() => {
                            setTargetedData(el);
                            setTogglePreviw(true);
                          }}
                        >
                          <Table.Cell>{el.id}</Table.Cell>
                          <Table.Cell>
                            {el.attributes.name
                              ? el.attributes.name
                              : t.common.na}
                          </Table.Cell>
                          <Table.Cell>
                            {el.attributes.address
                              ? el.attributes.address
                              : t.common.na}
                          </Table.Cell>
                          <Table.Cell>
                            {el.attributes.country
                              ? Country.getCountryByCode(el.attributes.country)
                                  .name
                              : t.common.na}
                          </Table.Cell>
                          <Table.Cell>{the_city}</Table.Cell>
                          <Table.Cell>
                            {customers ? customers : t.common.na}
                          </Table.Cell>
                          <Table.Cell>{createdByUser}</Table.Cell>
                          <Table.Cell>
                            {
                              <Moment
                                format="DD-MM-YYYY LT"
                                date={el.attributes.createdAt}
                              />
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {
                              <Moment
                                format="DD-MM-YYYY LT"
                                date={el.attributes.updatedAt}
                              />
                            }
                          </Table.Cell>
                          <Table.Cell>
                            {userPermissions?.location?.create ? (
                              <span
                                title="Add Sub-Location"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedParentLocation(el);
                                  setToggleAsideModal(true);
                                }}
                              >
                                <Icon name={'plus mr-0'} />
                              </span>
                            ) : null}
                          </Table.Cell>

                          {el.attributes.subLocations.data.length > 0 ? (
                            <Table.Cell
                              className="aside-counter flexbox align-center"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (expandedRow != el.id) {
                                  setExpandedRow(el.id);
                                } else {
                                  setExpandedRow();
                                }
                              }}
                            >
                              <span>
                                {el.attributes.subLocations.data.length}
                              </span>
                              <Icon
                                name={
                                  expandedRow == el.id
                                    ? 'angle up'
                                    : 'angle down'
                                }
                              />
                            </Table.Cell>
                          ) : (
                            <Table.Cell className="aside-counter flexbox align-center"></Table.Cell>
                          )}
                        </Table.Row>
                        {expandedRow == el.id
                          ? el.attributes.subLocations.data.map((sub) => {
                              const subCitiesOfCountry = State.getStatesOfCountry(
                                sub.attributes.country
                              );
                              const sub_city = sub.attributes.city
                                ? subCitiesOfCountry.filter((city) => {
                                    return city.isoCode == sub.attributes.city;
                                  })[0].name
                                : t.common.na;

                              let createdByUserSub = sub.attributes
                                .createdByUser?.data?.attributes ? (
                                <div>
                                  <Image
                                    avatar
                                    title={
                                      sub.attributes.createdByUser?.data
                                        ?.attributes.firstName +
                                      ' ' +
                                      sub.attributes.createdByUser?.data
                                        ?.attributes.lastName
                                    }
                                    src={
                                      sub.attributes.createdByUser?.data
                                        ?.attributes?.profileImage?.data
                                        ?.attributes?.url
                                        ? IMAGES_URL +
                                          sub.attributes.createdByUser?.data
                                            ?.attributes?.profileImage?.data
                                            ?.attributes?.url
                                        : LetteredAvatarImageUrl(
                                            sub.attributes.createdByUser?.data
                                              ?.attributes.firstName +
                                              ' ' +
                                              sub.attributes.createdByUser?.data
                                                ?.attributes.lastName
                                          )
                                    }
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                  <span style={{ marginLeft: '4px' }}>
                                    {
                                      sub.attributes.createdByUser?.data
                                        ?.attributes.firstName
                                    }
                                  </span>
                                </div>
                              ) : (
                                t.common.na
                              );
                              // let customerIMG = t.common.na;
                              // if (sub.attributes.customer.data) {
                              //   customerIMG = sub.attributes.customer.data
                              //     .attributes?.logo.data ? (
                              //     <div>
                              //       <Image
                              //         avatar
                              //         title={
                              //           sub.attributes.customer.data?.attributes
                              //             .name
                              //         }
                              //         src={
                              //           IMAGES_URL +
                              //           sub.attributes.customer.data?.attributes
                              //             ?.logo?.data?.attributes?.url
                              //         }
                              //       />
                              //       <span style={{ marginLeft: '4px' }}>
                              //         {
                              //           sub.attributes.customer.data.attributes
                              //             .name
                              //         }
                              //       </span>
                              //     </div>
                              //   ) : (
                              //     <div>
                              //       <Image
                              //         avatar
                              //         title={
                              //           sub.attributes.customer.data.attributes
                              //             .name
                              //         }
                              //         src={LetteredAvatarImageUrl(
                              //           sub.attributes.customer.data.attributes
                              //             .name
                              //         )}
                              //       />
                              //       <span style={{ marginLeft: '4px' }}>
                              //         {
                              //           sub.attributes.customer.data.attributes
                              //             .name
                              //         }
                              //       </span>
                              //     </div>
                              //   );
                              // }
                              let subCustomers =
                                sub.attributes?.customers?.data;
                              if (subCustomers?.length > 0) {
                                subCustomers = subCustomers.map(
                                  (people, index) => {
                                    if (
                                      people?.attributes?.logo?.data?.attributes
                                        ?.url
                                    ) {
                                      return (
                                        <Image
                                          avatar
                                          title={
                                            people.attributes.name + ' ' + ' '
                                          }
                                          src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                                          style={
                                            index != 0
                                              ? {
                                                  marginLeft: '-6px',
                                                  width: '20px',
                                                  height: '20px',
                                                }
                                              : {
                                                  width: '20px',
                                                  height: '20px',
                                                }
                                          }
                                        />
                                      );
                                    } else {
                                      return (
                                        <UserProfilePlaceholder
                                          name={`${
                                            people?.attributes?.name
                                              ? people?.attributes?.name
                                              : ''
                                          } ${''}`}
                                        />
                                      );
                                    }
                                  }
                                );
                              } else {
                                subCustomers = '';
                              }
                              return (
                                <Table.Row
                                  className="sub-location"
                                  data-parent={el.id}
                                  key={sub.id}
                                  onClick={async () => {
                                    const reponse = await getLocationByID(
                                      sub.id,
                                      organisationId
                                    );
                                    //console.log('reponse', reponse);
                                    if (reponse.status == 200) {
                                      setTargetedData(reponse.data.data[0]);
                                      setTogglePreviw(true);
                                    }
                                  }}
                                >
                                  <Table.Cell>{sub.id}</Table.Cell>
                                  <Table.Cell>
                                    <Icon name="clone" />
                                    {sub.attributes.name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {sub.attributes.address
                                      ? sub.attributes.address
                                      : t.common.na}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {sub.attributes.country
                                      ? Country.getCountryByCode(
                                          sub.attributes.country
                                        ).name
                                      : t.common.na}
                                  </Table.Cell>
                                  <Table.Cell>{sub_city}</Table.Cell>
                                  <Table.Cell>
                                    {subCustomers ? subCustomers : t.common.na}
                                  </Table.Cell>
                                  <Table.Cell>{createdByUserSub}</Table.Cell>
                                  <Table.Cell>
                                    {
                                      <Moment
                                        format="DD-MM-YYYY LT"
                                        date={sub.attributes.createdAt}
                                      />
                                    }
                                  </Table.Cell>
                                  <Table.Cell>
                                    {
                                      <Moment
                                        format="DD-MM-YYYY LT"
                                        date={sub.attributes.updatedAt}
                                      />
                                    }
                                  </Table.Cell>
                                  <Table.Cell></Table.Cell>
                                  <Table.Cell></Table.Cell>
                                </Table.Row>
                              );
                            })
                          : ''}
                      </>
                    );
                  })}
                </Table.Body>
              </Table> */}

                {error ? (
                  <div className="mt-8 mb-8 ">
                    <Animation />
                    {/* <Loader
                      active
                      inline="centered"
                      content={t.common.loading}
                    /> */}
                  </div>
                ) : (
                  <DataTable
                    className="datatable-div"
                    columns={columns}
                    onSort={handleSort}
                    fixedHeader
                    data={data}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    expandableRowDisabled={(row) =>
                      row.attributes.subLocations?.data.length > 0
                        ? false
                        : true
                    }
                    // expandableRows
                    // expandableRowsComponent={<ExpandedRowComponent />}
                    noDataComponent={
                      initData.length > 0 ? (
                        <div className="no-data-container">
                          <div className="">
                            <p className="no-data-text">
                              {t.common.no_result_found}
                            </p>
                          </div>
                        </div>
                      ) : null
                    }
                    direction={selectedLanguage === "en" ? "ltr" : "rtl"}
                    onRowClicked={(row) => {
                      if (!(role === "super_admin" || role === "admin")) {
                        if (
                          allIds?.includes(row.id)
                        ) {
                          setTargetedData(row);
                          setTogglePreviw(true);
                        }
                      } else {
                        setTargetedData(row);
                        setTogglePreviw(true);
                      }
                      // setTargetedData(row);
                      // setTogglePreviw(true);
                    }}
                    sortIcon={
                      sortData ? (
                        <div>
                          <span>
                            <SortIcon />
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveSorting();
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )
                    }
                    customStyles={customStyles}
                    persistTableHead={true}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    progressPending={loader}
                    progressComponent={
                      <div className="mt-8 mb-8 ">{t.common.loading}</div>
                    }
                  />
                )}

                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={total}
                    prevItem={
                      selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={page}
                    limit={pageSize}
                    handleNext={async () => {
                      setloader(true);
                      await dispatch(
                        fetchLocationsWithFilter({
                          organisationId,
                          searchName: "",
                          page: page + 1,
                          pageSize: pageSize,
                          customer: filterOptions.customers,
                          country: filterOptions.countries,
                          city: filterOptions.cities,
                          sort: sortData,
                          role,
                          allIds: parentIds,
                          currentUserId,
                        })
                      );
                      setloader(false);
                    }}
                    handlePrevious={async () => {
                      setloader(true);
                      await dispatch(
                        fetchLocationsWithFilter({
                          organisationId,
                          searchName: "",
                          page: page - 1,
                          pageSize: pageSize,
                          customer: filterOptions.customers,
                          country: filterOptions.countries,
                          city: filterOptions.cities,
                          sort: sortData,
                          role,
                          allIds: parentIds,
                          currentUserId,
                        })
                      );
                      setloader(false);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="no-data-container">
                <div className="no-work-orders pb-15">
                  <LocationBlueIcon />
                  <p className="no-data-text">
                    {t.common.there_no}{" "}
                    <span
                      className="link-text"
                      style={{ position: "relative", cursor: "pointer" }}
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      {/* <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth="65px"
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily="Roboto"
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight="76px"
                        moveUp="-1px"
                      >
                        
                        <span className="work-order-tooltip">
                          {t.locations.how_to_add_locations}
                        </span>
                      </Tooltip> */}

                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth={
                          selectedLanguage == "ar" ? "90px" : "65px"
                        }
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == "ar"
                            ? "Noto Kufi Arabic"
                            : "Roboto"
                        }
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight={selectedLanguage == "ar" ? "55px" : "76px"}
                        moveUp="-1px"
                      >
                        <span className="work-order-tooltip">
                          {t.locations.how_to_add_locations}
                        </span>
                      </Tooltip>
                      {" " + t.locations.title_small + " "}
                    </span>{" "}
                    {t.common.added}
                  </p>
                  {userPermissions?.location?.create ? (
                    <button
                      className="work-order-button-transparent"
                      onClick={() => {
                        setToggleAsideModal(true);
                      }}
                    >
                      {t.locations.no_location_added}
                    </button>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <Add
        toggled={toggleAsideModal}
        allIds={allIds}
        parentIds={parentIds}
        setParentIds={setParentIds}
        role={role}
        currentUserId={currentUserId}
        untoggle={() => {
          setToggleAsideModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setSelectedParentLocation(null);
        }}
        setRefresh={setRefresh}
        refresh={refresh}
        parentLocation={selectedParentLocation}
      />

      <Edit
        targetedData={targetedData}
        allIds={allIds}
        parentIds={parentIds}
        role={role}
        currentUserId={currentUserId}
        toggled={toggleEditModal}
        refresh={refresh}
        setRefresh={setRefresh}
        untoggle={() => {
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setTargetedData();
          setToggleEditModal(false);
        }}
      />

      <Preview
        targetedData={targetedData}
        toggled={togglePreview}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        edit={() => {
          setTogglePreviw(false);
          setToggleEditModal(true);
        }}
        untoggle={() => {
          setTargetedData();
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setTogglePreviw(false);
          history.push("/locations");
        }}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </div></>
  );
};

export default connect(null, auth.actions)(Locations);
