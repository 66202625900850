import { filter } from "lodash";
import React, { useEffect, useState, createRef, useRef } from "react";
import _ from "lodash";
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
  DropdownMenu,
  DropdownItem,
  Popup,
  Form,
  Divider,
} from "semantic-ui-react";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Link } from "react-router-dom";
import QrScanner from "qr-scanner";
import location_icon from "../../../Assets/Icons/loc_option_parent.png";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import {
  getAllLocations,
  getActiveUsersByRole,
  getAllTeams,
  humanize,
  toggle,
  IMAGES_URL,
  getAllCustomers,
  getAllCategories,
  getAllParentLocations,
  getUsersByRole,
} from "../../config/functions";
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from "../../components/common/userProfilePlaceholder";
import {
  ActionBtnIcon,
  ArrowCalendarLeft,
  ArrowCalendarRight,
  CategoryElectrical,
  CategoryInspection,
  CategoryMechanical,
  CategoryRefrigeration,
  CategorySafety,
  CatgoryPreventive,
  CorrectiveIcon,
  Damage,
  DownChevron,
  DownloadIcon,
  OperationProcedure,
  PdfIcon,
  PlannedIcon,
  Project,
  ShareIcon,
  UnPlannedIcon,
  UploadIcon,
  WhiteDownChevron,
} from "../../../Assets/Icons/svg";
import { cloneDeep } from "lodash";
import ToolTip from "react-power-tooltip";
import LocationSearchDropdown from "../../components/WorkOrder/LocationSearchDropdown";

import PriorityDropdown from "../../components/common/PriorityDropdown";
import WorkOrderStatusDropdown from "../../components/WorkOrder/WorkOrderStatusDropdown";
import WorkOrderSearchDropdown from "../../components/WorkOrder/WorkOrderSearchDropdown";

import moment from "moment";

import { useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import { SearchIcon } from "../../../Assets/Icons/svg";
import CategoryDropdown from "../../components/common/CategoryDropdown";
import DatePicker from "react-multi-date-picker";
import DownloadExcelFileWithData from "../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../services/DownloadPdfFileWithData";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import {  useHistory } from 'react-router';
const CalendarFilters = ({
  data,
  setDataByFilter,
  refresh,
  setRefresh,
  calendarRef = null,
  fileData = [],
  setWeekFlag,
  weekFlag,
  dayFlag,
  setDayFlag,
  roleData,
  allIds,
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const history = useHistory();
  const [showTooltip, setShowTooltip] = useState(false);
  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();

  /*
   * The function getCurrentMonthStartDate returns the start date of the current month.
   */
  const getCurrentMonthStartDate = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    return startOfMonth;
  };

  /*
   * The function getCurrentMonthDates returns an array containing the start and end dates of the current month.
   */
  const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      2
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );

    return [startOfMonth, endOfMonth];
  };

  /*
   * The function getCurrentWeekDates returns the start and end dates of the current week in ISO format based on the input date or the current date..
   */
  const getCurrentWeekDates = (date) => {
    const currentDate = date ? new Date(date) : new Date();
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDay);

    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(currentDate.getDate() + (6 - currentDay));

    const isoStartDate = startOfWeek.toISOString();
    const isoEndDate = endOfWeek.toISOString();

    return [isoStartDate, isoEndDate];
  };

  const [calendarDate, setCalendarDate] = useState(new Date());
  const [monthSelectedDate, setMonthSelectedDate] = useState(
    getCurrentMonthStartDate()
  );
  const [monthSelectedArrayDate, setMonthSelectedArrayDate] = useState(
    getCurrentMonthDates()
  );
  const [weekSelectedDate, setWeekSelectedDate] = useState(
    getCurrentWeekDates()
  );

  const [selectedDates, setSelectedDates] = useState([]);
  const [resetKey, setResetKey] = useState(0);

  const [filtersTrigger, setFiltersTrigger] = useState({
    assigneeType: "people",
    assignees: {
      people: {
        initOptions: [],
        options: [],
      },
      teams: {
        initOptions: [],
        options: [],
      },
    },
    people: {
      initOptions: [],
      options: [],
    },

    teams: {
      initOptions: [],
      options: [],
    },

    locations: {
      initOptions: [],
      options: [],
    },
    customers: {
      initOptions: [],
      options: [],
    },
    createdByUser: {
      initOptions: [],
      options: [],
    },
    dueDate: {
      options: [
        { key: 0, text: t.workOrders.due_date_status.any_date, value: 0 },
        { key: 1, text: t.workOrders.due_date_status.today, value: 1 },
        { key: 2, text: t.workOrders.due_date_status.tomorrow, value: 2 },
        { key: 3, text: t.workOrders.due_date_status.next_7_day, value: 3 },
        { key: 4, text: t.workOrders.due_date_status.next_30_day, value: 4 },
        { key: 5, text: t.workOrders.due_date_status.this_month, value: 5 },
        { key: 7, text: t.workOrders.due_date_status.this_year, value: 6 },
        { key: 8, text: t.workOrders.due_date_status.yesterday, value: 7 },
        { key: 9, text: t.workOrders.due_date_status.last_week, value: 8 },
        { key: 10, text: t.workOrders.due_date_status.last_month, value: 9 },
        { key: 12, text: t.workOrders.due_date_status.last_year, value: 10 },
        { key: 13, text: t.workOrders.due_date_status.past_due, value: 11 },
      ],
    },
    status: {
      options: [
        {
          key: 1,
          text: t.common.all,
          value: "",
          color: "",
        },
        {
          key: 1,
          text: t.workOrders.work_order_status.open,
          value: "open",
          color: "blue",
        },
        {
          key: 3,
          text: t.workOrders.work_order_status.inProgress,
          value: "inProgress",
          color: "yellow",
        },
        {
          key: 2,
          text: t.workOrders.work_order_status.onHold,
          value: "onHold",
          color: "red",
        },
        {
          key: 0,
          text: t.workOrders.work_order_status.inReview,
          value: "inReview",
          color: "black",
        },
        {
          key: 4,
          text: t.workOrders.work_order_status.completed,
          value: "completed",
          color: "green",
        },
      ],
    },

    categories: {
      options: [
        {
          key: 0,
          value: "damage",
          text: t.workOrders.form.category_options[0],
          icon: <Damage />,
        },
        {
          key: 2,
          value: "corrective action Downtime",
          text: t.workOrders.form.category_options[1],
          icon: <CorrectiveIcon />,
        },
        {
          key: 4,
          value: "planned Downtime",
          text: t.workOrders.form.category_options[2],
          icon: <PlannedIcon />,
        },
        {
          key: 10,
          value: "unplanned Downtime",
          text: t.workOrders.form.category_options[3],
          icon: <UnPlannedIcon />,
        },
        {
          key: 11,
          value: "inspection",
          text: t.workOrders.form.category_options[4],
          icon: <CategoryInspection />,
        },
        {
          key: 1,
          value: "electrical",
          text: t.workOrders.form.category_options[5],
          icon: <CategoryElectrical />,
        },

        {
          key: 3,
          value: "mechanical",
          text: t.workOrders.form.category_options[6],
          icon: <CategoryMechanical />,
        },

        {
          key: 6,
          value: "HVAC",
          text: t.workOrders.work_order_category.HVAC,
          icon: <CategoryRefrigeration />,
        },
        {
          key: 16,
          value: "preventive maintenance",
          text: t.workOrders.form.category_options[8],
          icon: <CatgoryPreventive />,
        },
        {
          key: 17,
          value: "project",
          text: t.workOrders.form.category_options[9],
          icon: <Project />,
        },
        {
          key: 7,
          value: "safety",
          text: t.workOrders.form.category_options[10],
          icon: <CategorySafety />,
        },
        {
          key: 27,
          value: "sop",
          text: t.workOrders.form.category_options[11],
          icon: <OperationProcedure />,
        },
      ],
    },
    priority: {
      options: [
        {
          key: 0,
          text: t.common.all,
          value: "",
          color: null,
        },
        {
          key: 1,
          text: t.workOrders.priority_status.low,
          value: "low",
          color: "blue",
        },
        {
          key: 2,
          text: t.workOrders.priority_status.medium,
          value: "medium",
          color: "orange",
        },
        {
          key: 3,
          text: t.workOrders.priority_status.high,
          value: "high",
          color: "red",
        },
      ],
    },
  });

  const [filterOptions, setFilterOptions] = useState({
    serialNumber: "",
    people: [],
    teams: [],
    customers: [],
    locations: [],
    createdByUser: [],
    status: [],
    priority: [],
    categories: [],
  });

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState({
    start: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ),
    end: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
  });
  const [currentView, setCurrentView] = useState("dayGridMonth");

  const [filterSearch, setFilterSearch] = useState({
    location: "",
    assignee: "",
    createdByUser: "",
    teams: "",
  });

  const [searchTitle, setSearchTitle] = useState("");
  const [openBarcode, setOpenBarcode] = useState(false);

  const [newData, setNewData] = useState(data);

  const [openAssigneeType, setOpenAssigneeType] = useState(false);
  const [openAssignees, setOpenAssignees] = useState(false);
  const [openLocations, setOpenLocations] = useState(false);
  const [openDueDate, setOpenDueDate] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openCreatedBy, setOpenCreatedBy] = useState(false);
  const [selectedQrcode, setSelectedQrcode] = useState(null);

  const closeAllFilters = () => {
    setOpenAssigneeType(false);
    setOpenAssignees(false);
    setOpenLocations(false);
    // setOpenBarcode(false);
    setOpenDueDate(false);
    setOpenStatus(false);
    setOpenPriority(false);
    setOpenCreatedBy(false);
  };

  {
    /* Headers for excel and pdf file */
  }
  const headers = [
    { label: t.workOrders.table.ID, key: "id" },
    { label: t.workOrders.table.title, key: "title" },
    { label: t.assets.form.description, key: "description" },
    { label: t.workOrders.table.status, key: "status" },
    { label: t.workOrders.table.priority, key: "priority" },
    { label: t.workOrders.table.assignees, key: "assignees" },
    { label: t.assets.form.category, key: "category" },
    { label: t.workOrders.form.code, key: "code" },
    { label: t.workOrders.table.customer, key: "customer" },
    { label: t.workOrders.table.location, key: "location" },
    { label: t.workOrders.form.parts, key: "parts" },
    { label: t.workOrders.table.procedure, key: "procedure" },
    { label: t.workOrders.details_view.estimation_time, key: "estimationTime" },
    { label: t.workOrders.table.dueDate, key: "dueDate" },
    { label: t.workOrders.table.createdByUser, key: "created By" },
    { label: t.workOrders.table.createdAt, key: "created At" },
  ];
  const categoryOptions = [
    {
      value: "damage",
      label: t.workOrders.form.category_options[0],
    },
    {
      value: "corrective action Downtime",
      label: t.workOrders.form.category_options[1],
    },
    {
      value: "planned Downtime",
      label: t.workOrders.form.category_options[2],
    },
    {
      value: "unplanned Downtime",
      label: t.workOrders.form.category_options[3],
    },
    {
      value: "inspection",
      label: t.workOrders.form.category_options[4],
    },
    {
      value: "electrical",
      label: t.workOrders.form.category_options[5],
    },
    {
      value: "mechanical",
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: "HVAC",
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: "preventive maintenance",
      label: t.workOrders.form.category_options[8],
    },
    {
      value: "project",
      label: t.workOrders.form.category_options[9],
    },
    {
      value: "safety",
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: "sop",
      label: t.workOrders.form.category_options[11],
    },
  ];
  const statusOptions = [
    {
      value: "open",
      color: "blue",
      label: t.workOrders.work_order_status.open,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: "inProgress",
      color: "yellow",
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: "onHold",
      color: "red",
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: "inReview",
      color: "black",
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: "completed",
      color: "green",
      label: t.workOrders.work_order_status.completed,
    },
  ];
  const codeOptions = [
    {
      value: "sop",
      label: t.workOrders.work_order_category.Sop,
    },

    {
      value: "project",
      label: t.workOrders.work_order_category.Project,
    },
    {
      value: "preventive",
      label: t.workOrders.work_order_category.Preventive,
    },
    {
      value: "inspection",
      label: t.workOrders.work_order_category.Inspection,
    },
    {
      value: "damage",
      label: t.workOrders.work_order_category.Damage,
    },
  ];

  /*
   * The `downloadXLS` function processes and sorts data before generating and downloading an Excel file with specific column widths and headers.
   */
  const downloadXLS = () => {
    let csvData = [];

    const sortedCsvData = fileData.sort((a, b) => {
      const startDate = moment(a.attributes.createdAt).format("DD-MM-YYYY LT");
      const endDate = moment(b.attributes.createdAt).format("DD-MM-YYYY LT");

      const start = moment(startDate, "DD-MM-YYYY h:mm A").startOf("day");
      const end = moment(endDate, "DD-MM-YYYY h:mm A").startOf("day");

      const dateDifferenceInDays = end.diff(start, "days");
      if (dateDifferenceInDays == 0) {
        return a.attributes.category.localeCompare(b.attributes.category);
      }
      return dateDifferenceInDays;
    });

    for (let i = 0; i < sortedCsvData.length; i++) {
      let workOrder_data = [];

      workOrder_data.id = sortedCsvData[i].id;
      workOrder_data.title = sortedCsvData[i]?.attributes?.title
        ? sortedCsvData[i].attributes.title
        : t.common.na;
      workOrder_data.description = sortedCsvData[i]?.attributes?.description
        ? sortedCsvData[i].attributes.description
        : t.common.na;
      const workOrderStatus = statusOptions.find(
        (status) => status.value === sortedCsvData[i].attributes.status
      );
      workOrder_data.status = workOrderStatus.label || t.common.na;

      workOrder_data.priority =
        humanize(sortedCsvData[i].attributes.priority) || t.common.na;
      let assigneePeople = sortedCsvData[i].attributes?.people?.data;
      let assignString = "";

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople
          .map(
            (people) =>
              `${people?.attributes?.firstName || ""} ${people.attributes
                .lastName || ""}`
          )
          .join(", ");
      } else {
        assignString = t.common.na;
      }
      workOrder_data.assignee = assignString;

      const categoryStatus = categoryOptions.find(
        (one) => one.value == sortedCsvData[i].attributes.category
      );
      workOrder_data.category = categoryStatus?.label || t.common.na;
      const codeStatus = codeOptions?.find(
        (one) => one.value == sortedCsvData[i].attributes.code
      );
      workOrder_data.code = codeStatus?.label || t.common.na;
      workOrder_data.customer = sortedCsvData[i].attributes?.customer?.data
        ?.attributes?.name
        ? sortedCsvData[i].attributes?.customer?.data?.attributes?.name
        : t.common.na;
      workOrder_data.location = sortedCsvData[i].attributes?.location?.data
        ?.attributes?.name
        ? sortedCsvData[i].attributes?.location?.data?.attributes?.name
        : t.common.na;

      workOrder_data.procedure = sortedCsvData[i].attributes?.procedure?.data
        ?.attributes?.title
        ? sortedCsvData[i].attributes?.procedure?.data?.attributes?.title
        : t.common.na;
      let partsData = sortedCsvData[i].attributes?.parts?.data;
      let partsString = "";

      if (partsData?.length > 0) {
        partsString = partsData.map((each) => each.attributes?.name).join(", ");
      } else {
        partsString = t.common.na;
      }

      workOrder_data.parts = partsString;

      workOrder_data.estimationTime = sortedCsvData[i].attributes
        ?.estimationTime
        ? `${sortedCsvData[i].attributes?.estimationTime}` + " Hour"
        : t.common.na;

      workOrder_data.dueDate =
        moment(sortedCsvData[i].attributes.dueDate).format("DD-MM-YYYY LT") ||
        t.common.na;

      workOrder_data.createdByUser =
        sortedCsvData[i]?.attributes?.createdByUser?.data?.attributes
          ?.firstName || t.common.na;
      workOrder_data.createdAt =
        moment(sortedCsvData[i].attributes.createdAt).format("DD-MM-YYYY LT") ||
        t.common.na;

      csvData.push([
        workOrder_data.id,
        workOrder_data.title,
        workOrder_data.description,
        workOrder_data.status,
        workOrder_data.priority,
        workOrder_data.assignee,
        workOrder_data.category,
        workOrder_data.code,
        workOrder_data.customer,
        workOrder_data.location,
        workOrder_data.parts,
        workOrder_data.procedure,
        workOrder_data.estimationTime,
        workOrder_data.dueDate,
        workOrder_data.createdByUser,
        workOrder_data.createdAt,
      ]);
    }

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Title column
      { wch: 35 }, // Description column
      { wch: 25 }, // Category column
      { wch: 15 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 20 }, // Created At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.workOrders.title
    );
  };

  /*
   * The function `generatePDF` processes data from `fileData` and generates a PDF file with specific formatting.
   */
  const generatePDF = () => {
    let csvData = [];
    for (let i = 0; i < fileData.length; i++) {
      let workOrder_data = {};

      workOrder_data.id = fileData[i].id;
      workOrder_data.title = fileData[i]?.attributes?.title
        ? fileData[i].attributes.title
        : t.common.na;
      const workOrderStatus = statusOptions.find(
        (status) => status.value === fileData[i].attributes.status
      );
      workOrder_data.description = fileData[i]?.attributes?.description
        ? fileData[i].attributes.description
        : t.common.na;
      workOrder_data.status = workOrderStatus?.label || t.common.na;

      workOrder_data.priority =
        humanize(fileData[i].attributes.priority) || t.common.na;
      let assigneePeople = fileData[i].attributes?.people?.data;
      let assignString = "";

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople
          .map(
            (people) =>
              `${people?.attributes?.firstName || ""} ${people.attributes
                .lastName || ""}`
          )
          .join(", ");
      } else {
        assignString = t.common.na;
      }
      workOrder_data.assignee = assignString;

      const categoryStatus = categoryOptions?.find(
        (one) => one.value == fileData[i].attributes.category
      );
      workOrder_data.category = categoryStatus?.label || t.common.na;

      const codeStatus = codeOptions?.find(
        (one) => one.value == fileData[i].attributes.code
      );
      workOrder_data.code = codeStatus?.label || t.common.na;
      workOrder_data.customer = fileData[i].attributes?.customer?.data
        ?.attributes?.name
        ? fileData[i].attributes?.customer?.data?.attributes?.name
        : t.common.na;
      workOrder_data.location = fileData[i].attributes?.location?.data
        ?.attributes?.name
        ? fileData[i].attributes?.location?.data?.attributes?.name
        : t.common.na;

      workOrder_data.procedure = fileData[i].attributes?.procedure?.data
        ?.attributes?.title
        ? fileData[i].attributes?.procedure?.data?.attributes?.title
        : t.common.na;
      let partsData = fileData[i].attributes?.parts?.data;
      let partsString = "";

      if (partsData?.length > 0) {
        partsString = partsData.map((each) => each.attributes?.name).join(", ");
      } else {
        partsString = t.common.na;
      }

      workOrder_data.parts = partsString;

      workOrder_data.estimationTime = fileData[i].attributes?.estimationTime
        ? `${fileData[i].attributes?.estimationTime}` + " Hour"
        : t.common.na;

      workOrder_data.dueDate =
        moment(fileData[i].attributes.dueDate).format("DD-MM-YYYY LT") ||
        t.common.na;

      workOrder_data.createdByUser =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;

      workOrder_data.createdAt =
        moment(fileData[i].attributes.createdAt).format("DD-MM-YYYY LT") ||
        t.common.na;

      csvData.push(workOrder_data);
    }
    const sortedCsvData = csvData.sort((a, b) => {
      const startDate = moment(a.createdAt, "DD-MM-YYYY h:mm A").startOf("day");
      const endDate = moment(b.createdAt, "DD-MM-YYYY h:mm A").startOf("day");

      const dateDifferenceInDays = endDate.diff(startDate, "days");
      if (dateDifferenceInDays == 0) {
        return a.category.localeCompare(b.category);
      }
      return dateDifferenceInDays;
    });

    const columnWidths = [10, 25, 25, 20, 15, 50, 22, 15, 20, 25, 25, 25];
    DownloadPdfFileWithData(
      headers,
      sortedCsvData,
      t.workOrders.title,
      columnWidths,
      "A2"
    );
  };

  const handleQRCode = (setFilterSearch, file) => {
    setSelectedQrcode(file);
    QrScanner.scanImage(file)
      .then((result) => {
        setFilterSearch({
          ...filterOptions,
          serialNumber: result,
        });
      })
      .catch((err) => {
        toast.error(err);
        setSelectedQrcode(null);
      });
  };

  const fetchLocationsOptions = async () => {
    closeAllFilters();

    setOpenLocations(true);
    let the_options = [];

    if (filtersTrigger.locations.initOptions.length < 1) {
      const fetchData = await getAllLocations(organisationId);
      if (fetchData) {
        the_options = fetchData.map((el) => {
          return {
            key: el.id,
            value: el.id,
            text: el.attributes.name,
          };
        });

        setFiltersTrigger({
          ...filtersTrigger,
          locations: {
            ...filtersTrigger.locations,
            initOptions: the_options,
            options: the_options,
          },
        });
      } else {
        setFiltersTrigger({
          ...filtersTrigger,
          locations: {
            ...filtersTrigger.locations,
          },
        });
      }
    }
  };

  /*
   * The function fetches active users by role and prepares options for filtering people based on their names and profile images.
   */
  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "supervisor", "view_only", "admin", "requester", "super_admin"],
      organisationId
    );

    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName + " " + el.lastName,
          image: {
            avatar: true,
            // src: `http://gravatar.com/avatar/${md5(
            //   el.firstName + ' ' + el.lastName
            // )}?d=identicon`,
            src: el?.profileImage?.url
              ? `${IMAGES_URL}${el?.profileImage?.url}`
              : LetteredAvatarImageUrl(`${el.firstName} ${el.lastName}`),
            style: { width: "20px", height: "20px" },
          },
        };
      });
      setFiltersTrigger({
        ...filtersTrigger,
        assignees: {
          teams: {
            initOptions: [],
            options: [],
          },
          people: {
            initOptions: the_options,
            options: the_options,
          },
        },
        createdByUser: {
          initOptions: the_options,
          options: the_options,
        },
      });
    }
  };

  /*
   * The function fetchTeamsOptions fetches team options data and prepares it for display.
   */
  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: {
            avatar: true,
            src: LetteredAvatarImageUrl(`${el.attributes.name}`),
            style: { width: "16px", height: "16px" },
          },
        };
      });
      // setFiltersTrigger({
      //   ...filtersTrigger,
      //   assignees: {
      //     teams: {
      //       initOptions: the_options,
      //       options: the_options,
      //     },
      //     people: {
      //       initOptions: [],
      //       options: [],
      //     },
      //   },
      // });
    }
  };

  const handleAssigneeTypeChange = (value) => {
    setFiltersTrigger({
      ...filtersTrigger,
      assigneeType: value,
    });
    if (value == "people") {
      fetchPeopleOptions();
    }
    if (value == "teams") {
      fetchTeamsOptions();
    }
  };

  const handleSearchTeamsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      teams: e.target.value,
    });
  };

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.teams), "i");
    const isMatch = (result) => {
      re.test(result.text);
    };

    let data = filtersTrigger.teams.initOptions.filter((test) =>
      re.test(test.text)
    );

    setFiltersTrigger({
      ...filtersTrigger,
      teams: {
        ...filtersTrigger.teams,
        options:
          filterSearch.teams.length > 0
            ? data
            : filtersTrigger.teams.initOptions,
      },
    });
  }, [filterSearch.teams]);

  /*
   * The `handleChange` function takes an array of dates, converts them to ISO format, and sets the selected dates.
   */
  const handleChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ""
      );
      setSelectedDates(isoDates);
    }
  };

  // Deselect by setting an empty array
  const handleDeselect = () => {
    setSelectedDates([]);
    setResetKey((pre) => !pre);
  };

  const handleSearchAssigneesChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      assignee: e.target.value,
    });
  };

  /* The above code is using the `useEffect` hook in React to fetch data asynchronously from various API
endpoints. It is fetching data related to customers, teams, locations, and users based on the
`organisationId`. */
  useEffect(() => {
    (async function() {
      const fetchData = await getAllCustomers(
        organisationId,
        "",
        1,
        99999,
        !(roleData === "super_admin" || roleData === "admin") ? allIds : []
      );
      let the_options_customer = [];
      let the_options_people = [];
      let the_options_location = [];
      let the_options_teams = [];
      let the_options_user = [];
      let the_created_user = [];

      if (fetchData?.response) {
        fetchData.response.forEach((el) => {
          the_options_customer.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: el.attributes.logo.data
                ? IMAGES_URL + el.attributes.logo.data.attributes.url
                : LetteredAvatarImageUrl(el.attributes.name + " "),
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }

      const fetchTeamsData = await getAllTeams(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        !(roleData === "super_admin" || roleData === "admin") ? allIds : []
      );

      if (fetchTeamsData?.response) {
        fetchTeamsData.response.map((el) => {
          the_options_teams.push({
            key: el.id,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: LetteredAvatarImageUrl(`${el.attributes.name}`),
              style: { width: "16px", height: "16px" },
            },
          });
        });
      }

      // getAllParentLocations,
      const fetchDataLocation = await getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      );

      let the_options = [];
      let parentOptions = [];
      let subOptions = [];

      if (fetchDataLocation?.response?.length > 0) {
        fetchDataLocation.response.forEach((el) => {
          if (!(roleData === "super_admin" || roleData === "admin")) {
            // Check if the element's ID is included in allIds
            if (allIds?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => allIds?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  text: el.attributes.name,
                  image: { src: location_icon },
                  className: "main-location-dropdown-option",
                  disabled: true, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (allIds?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }
          } else {
            // Add parent option
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              className: "main-location-dropdown-option",
              image: { src: location_icon },
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                subOptions.push({
                  key: subEl.id,
                  value: subEl.id,
                  text: subEl.attributes.name,
                  image: { src: sub_location_icon },
                  className: "sub-location-dropdown-option",
                  parentId: el.id, // Keep track of the parent
                });
              });
            }
          }
        });

        // Sort sub-options alphabetically
        subOptions.sort((a, b) => a.text.localeCompare(b.text));

        // Merge parent options with sorted sub-options
        parentOptions.forEach((parentOption) => {
          the_options.push(parentOption);
          // Add sorted sub-options belonging to this parent
          subOptions.forEach((subOption) => {
            if (subOption.parentId === parentOption.key) {
              the_options.push(subOption);
            }
          });
        });

        // Update the options state
        // setOptions((prev) => ({
        //   ...prev,
        //   locations: the_options,
        // }));

        // return the_options;
      }

      const fetchDataPeople = await getUsersByRole(
        [
          "user",
          "supervisor",
          "view_only",
          "admin",
          "requester",
          "super_admin",
          "limited_admin",
        ],
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        [],
        [],
        {},
        [],
        !(roleData === "super_admin" || roleData === "admin") ? allIds : []
      );

      if (fetchDataPeople?.response) {
        fetchDataPeople.response.map((el) => {
          the_options_user.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.firstName + " " + el.lastName,
            image: {
              avatar: true,
              src: el?.profileImage?.url
                ? IMAGES_URL + el?.profileImage?.url
                : LetteredAvatarImageUrl(el.firstName + " " + el.lastName),
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }

      setFiltersTrigger({
        ...filtersTrigger,
        customers: {
          ...filtersTrigger.customers,
          initOptions: the_options_customer,
          options: the_options_customer,
        },
        locations: {
          ...filtersTrigger.locations,
          initOptions: the_options,
          options: the_options,
        },
        createdByUser: {
          ...filtersTrigger.createdByUser,
          initOptions: the_options_people,
          options: the_options_people,
        },
        teams: {
          ...filtersTrigger.teams,
          initOptions: the_options_teams,
          options: the_options_teams,
        },
        people: {
          ...filtersTrigger.people,
          initOptions: the_options_user,
          options: the_options_user,
        },
        createdByUser: {
          ...filtersTrigger.people,
          initOptions: the_options_user,
          options: the_options_user,
        },
      });
    })();
  }, []);
  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      locations: {
        initOptions: filtersTrigger.locations.initOptions,
        options:
          filterSearch.location.length > 0
            ? _.filter(filtersTrigger.locations.initOptions, isMatch)
            : filtersTrigger.locations.initOptions,
      },
    });
    document.body.addEventListener("click", closeAllFilters);
  }, [filterSearch.location]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.assignee), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      people: {
        ...filtersTrigger.people,
        options:
          filterSearch.assignee.length > 0
            ? _.filter(filtersTrigger.people.initOptions, isMatch)
            : filtersTrigger.people.initOptions,
      },
    });

    // setFiltersTrigger({
    //   ...filtersTrigger,
    //   assignees: {
    //     ...filtersTrigger.assignees,
    //     teams: {
    //       ...filtersTrigger.assignees.teams,
    //       options:
    //         filterSearch.assignee.length > 0
    //           ? _.filter(filtersTrigger.assignees.teams.initOptions, isMatch)
    //           : filtersTrigger.assignees.teams.initOptions,
    //     },
    //   },
    // });
  }, [filterSearch.assignee]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.createdByUser), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      createdByUser: {
        ...filtersTrigger.createdByUser,
        options:
          filterSearch.createdByUser.length > 0
            ? _.filter(filtersTrigger.createdByUser.initOptions, isMatch)
            : filtersTrigger.createdByUser.initOptions,
      },
    });
  }, [filterSearch.createdByUser]);

  useEffect(() => {
    handleAssigneeTypeChange(filtersTrigger.assigneeType);
  }, [openAssignees]);

  let _newOrders = cloneDeep(data);

  /* The above code is a React useEffect hook that filters a list of orders based on various filter
options and search criteria. Here is a breakdown of what the code is doing: */
  useEffect(() => {
 

    if (selectedDates.length == 2) {
      if (selectedDates[0] == selectedDates[1]) {
        _newOrders = _newOrders.filter((el) => {
          var toDate = moment(selectedDates[1]).format("YYYY-MM-DD") || "";
          var fromDate = moment(selectedDates[0]).format("YYYY-MM-DD") || "";
          var createdAt =
            moment(el.attributes.dueDate).format("YYYY-MM-DD") || "";

          return createdAt >= fromDate && createdAt <= toDate;
        });
      } else {
        _newOrders = _newOrders.filter((el) => {
          {
            console.warn(el.attributes, "jasdjakdjkasdjasldjaklsdasldas");
          }
          var toDate = moment(selectedDates[1]).format("YYYY-MM-DD") || "";
          var fromDate = moment(selectedDates[0]).format("YYYY-MM-DD") || "";
          var createdAt =
            moment(el.attributes.dueDate).format("YYYY-MM-DD") || "";

          return createdAt >= fromDate && createdAt <= toDate;
        });
      }
    } else if (selectedDates.length == 1) {
      _newOrders = _newOrders.filter((el) => {
        var fromDate = moment(selectedDates[0]).format("YYYY-MM-DD") || "";

        return el.attributes.dueDate >= fromDate;
      });
    }

    if (filterOptions.teams.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return el?.attributes?.teams?.data?.some((item) =>
          filterOptions.teams.includes(`${item.id}`)
        );
      });

    if (filterOptions.serialNumber.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.serialNumber == el.id;
      });

    if (filterOptions.locations.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.locations.includes( el.attributes?.location?.data?.id
        );
      });

    if (filterOptions.people.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return el?.attributes?.people?.data?.some((item) =>
          filterOptions.people.includes(`${item.id}`)
        );
      });

    // if (filterOptions.teams.length > 0)
    // _newOrders = _newOrders.filter((el) => {
    //   return filterOptions.teams.includes(
    //     `${el.attributes?.teams?.data[0]?.id}`
    //   );
    // });

    if (filterOptions.customers.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.customers.includes(
          `${el.attributes?.customer?.data?.id}`
        );
      });

    if (filterOptions.priority.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.priority.includes(el.attributes.priority);
      });

    if (filterOptions.categories.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.categories.includes(el.attributes.category);
      });

    if (filterOptions.status.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.status.includes(el.attributes.status);
      });

    if (filterOptions.createdByUser.length > 0)
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.createdByUser.includes(
          `${el.attributes.createdByUser?.data?.id}`
        );
      });

    // if (filterOptions.dueDate.value > 0) {
    //   if (filterOptions.dueDate.value == 1) {
    //     _newOrders = _newOrders.filter((el) => {
    //       return moment().isSame(el.attributes.dueDate, 'd');
    //     });
    //     //console.log('today');
    //   }

    //   if (filterOptions.dueDate.value == 2) {
    //     _newOrders = _newOrders.filter((el) => {
    //       var date = moment(el.attributes.dueDate).format('YYYY-MM-DD');
    //       var tomorrow = moment()
    //         .add(1, 'days')
    //         .format('YYYY-MM-DD');

    //       return tomorrow == date;
    //     });
    //     //console.log('tomorrow');
    //   }

    //   if (filterOptions.dueDate.value == 3) {
    //     _newOrders = _newOrders.filter((el) => {
    //       var fromDate = moment().format('YYYY-MM-DD');
    //       var toDate = moment()
    //         .add(7, 'days')
    //         .format('YYYY-MM-DD');

    //       return (
    //         el.attributes.dueDate >= fromDate && el.attributes.dueDate <= toDate
    //       );
    //     });
    //     //console.log('next 7 days');
    //   }

    //   if (filterOptions.dueDate.value == 4) {
    //     _newOrders = _newOrders.filter((el) => {
    //       var fromDate = moment().format('YYYY-MM-DD');
    //       var toDate = moment()
    //         .add(30, 'days')
    //         .format('YYYY-MM-DD');

    //       return (
    //         el.attributes.dueDate >= fromDate && el.attributes.dueDate <= toDate
    //       );
    //     });
    //     //console.log('next 30 days');
    //   }

    //   if (filterOptions.dueDate.value == 5) {
    //     _newOrders = _newOrders.filter((el) => {
    //       return moment().isSame(el.attributes.dueDate, 'month');
    //     });
    //     //console.log('This Month');
    //   }

    //   if (filterOptions.dueDate.value == 6) {
    //     _newOrders = _newOrders.filter((el) => {
    //       return moment().isSame(el.attributes.dueDate, 'year');
    //     });
    //     //console.log('This Year');
    //   }

    //   if (filterOptions.dueDate.value == 7) {
    //     _newOrders = _newOrders.filter((el) => {
    //       // var date = moment(el.dueDate).format("YYYY-MM-DD");
    //       var YESTERDAY = moment()
    //         .subtract(1, 'days')
    //         .format('YYYY-MM-DD');

    //       return moment(el.attributes.dueDate).isSame(YESTERDAY, 'YYYY-MM-DD');
    //     });
    //     //console.log('Yesterday');
    //   }

    //   if (filterOptions.dueDate.value == 8) {
    //     _newOrders = _newOrders.filter((el) => {
    //       var toDate = moment().format('YYYY-MM-DD');
    //       var fromDate = moment()
    //         .subtract(7, 'days')
    //         .format('YYYY-MM-DD');

    //       return (
    //         el.attributes.dueDate >= fromDate && el.attributes.dueDate <= toDate
    //       );
    //     });
    //     //console.log('last 7 days');
    //   }

    //   if (filterOptions.dueDate.value == 9) {
    //     _newOrders = _newOrders.filter((el) => {
    //       return moment()
    //         .subtract(1, 'months')
    //         .isSame(el.attributes.dueDate, 'month');
    //     });
    //     // console.log('Last month');
    //   }

    //   if (filterOptions.dueDate.value == 10) {
    //     _newOrders = _newOrders.filter((el) => {
    //       return moment()
    //         .subtract(1, 'years')
    //         .isSame(el.attributes.dueDate, 'year');
    //     });
    //     //console.log('Last year');
    //   }

    //   if (filterOptions.dueDate.value == 11) {
    //     _newOrders = _newOrders.filter((el) => {
    //       var date = moment(el.attributes.dueDate).format('YYYY-MM-DD');
    //       var now = moment().format('YYYY-MM-DD');

    //       return now > date;
    //     });
    //     //console.log('past due');
    //   }
    // }

    if (searchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(searchTitle), "i");
      const isMatch = (result) => {
        if (re.test(result.attributes.title) || re.test(result.id)) {
          return true;
        } else if (result.attributes?.customer?.data?.attributes?.name) {
          return re.test(result.attributes?.customer?.data?.attributes?.name);
        } else if (result.attributes?.createdByUser?.data?.attributes?.name) {
          return re.test(
            result.attributes?.createdByUser?.data?.attributes?.name
          );
        }
      };

      _newOrders =
        searchTitle.length > 0 ? _.filter(_newOrders, isMatch) : _newOrders;
    }

    if (_newOrders.length > 0) {
      setDataByFilter(_newOrders);
    } else {
      setDataByFilter([]);
    }
  }, [filterOptions, searchTitle, selectedDates]);

  /**
   * The `changeView` function updates the view of a calendar using the FullCalendar library in JavaScript.
   */
  const changeView = (view) => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(view);
      setCurrentView(view);
      //   if (view === 'timeGridWeek' && selectedWeek.start) {
      //     calendarRef.current.getApi().gotoDate(selectedWeek.start);
      //   }
    }
  };

  /*
   * The function `handleCalendarDateChange` takes an array of date values, converts them to ISO format, sets the selected date for the week, and updates the calendar accordingly.
   */
  const handleCalendarDateChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ""
      );
      setWeekSelectedDate(isoDates);

      let formattedValue = format(dateValues[0].valueOf(), "yyyy-MM-dd");
      calendarRef.current.getApi().gotoDate(new Date(formattedValue));
      // let formattedValue = format(value.valueOf(), 'yyyy-MM-dd');
      setCalendarDate(formattedValue);
      // calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    } else {
      let formattedValue = format(value.valueOf(), "yyyy-MM-dd");
      setCalendarDate(formattedValue);
      calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    }
  };
  /*
   * The function `handleCalendarDateLeftArrow` adjusts the calendar date to the previous week, day, or month based on the current view mode.
   */
  const handleCalendarDateLeftArrow = () => {
    if (weekFlag) {
      const newDates = weekSelectedDate.map((dateString) => {
        const originalDate = new Date(dateString);
        originalDate.setDate(originalDate.getDate() - 7); // Add 7 days
        return originalDate.toISOString();
      });
      setWeekSelectedDate([newDates[0], newDates[1]]);

      let date = new Date(newDates[0]);
      // date.setMonth(date.getMonth() + 1);
      let formattedValue = format(date, "yyyy-MM-dd");
      // setCalendarDate(formattedValue);
      calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    } else if (dayFlag) {
      let date = new Date(calendarDate);
      date.setDate(date.getDate() - 1);
      let formattedValue = format(date, "yyyy-MM-dd");
      setCalendarDate(formattedValue);
      calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    } else {
      let date = new Date(calendarDate);
      date.setMonth(date.getMonth() - 1);
      let formattedValue = format(date, "yyyy-MM-dd");
      setCalendarDate(formattedValue);
      calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    }
  };
  /*
   * The `handleCalendarDateRightArrow` function advances the calendar date by either 7 days, 1 day, or 1 month based on the current view mode.
   */
  const handleCalendarDateRightArrow = () => {
    if (weekFlag) {
      const newDates = weekSelectedDate.map((dateString) => {
        const originalDate = new Date(dateString);
        originalDate.setDate(originalDate.getDate() + 7); // Add 7 days
        return originalDate.toISOString();
      });

      setWeekSelectedDate([newDates[0], newDates[1]]);

      let date = new Date(newDates[0]);
      // date.setMonth(date.getMonth() + 1);
      let formattedValue = format(date, "yyyy-MM-dd");
      setCalendarDate(formattedValue);
      calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    } else if (dayFlag) {
      let date = new Date(calendarDate);
      date.setDate(date.getDate() + 1);
      let formattedValue = format(date, "yyyy-MM-dd");
      setCalendarDate(formattedValue);
      calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    } else {
      let date = new Date(calendarDate);
      date.setMonth(date.getMonth() + 1);
      let formattedValue = format(date, "yyyy-MM-dd");
      setCalendarDate(formattedValue);
      calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    }
  };

  return (
    <>
     <div className="filters">
        <div className="table-header-dashbaord team-filter text-right mb-5 pb-5 d-flex justify-content-between align-items-center views">
       <div style={{display:"grid"}}>
        <Button.Group
              className="add-people-btn"
              basic
              fluid
              style={{ width: "125%" }}
              size="medium"
            >
              <Button
                // active={activePane == 0}
                // onClick={() => changeTab(0)}
                type="button"
                onClick={() => history.push("/work-orders")}
               
              >
               {t.workOrders.tabs.first}
              </Button>

              <Button
                // className="add-people-tab"
              
                onClick={() => history.push("/work-orders-kanban-view")}
                type="button"
            
              >
                {t.workOrders.kanbar}
              </Button>

              <Button
                // active={activePane == 0}
                // onClick={() => changeTab(0)}
                type="button"
                active={true}
                
              >
                 {t.workOrders.tabs.second}
              </Button>

              {/* <Button
              active={activePane == 2}
              onClick={() => {
                // setFieldValue('tab', 2).then((errors) => {
                //   changeTab(2, errors, setFieldTouched, values);
                //   if (errors) {
                //     scrollToError();
                //   }
                // });
              }}
              type="button"
            >
              {t.people.attachments_form.attachment_title}
            </Button> */}
            
           
            </Button.Group>
            <Divider  style={{ width: "97%" }} className="mt-0 mb-0" />
            </div>
           
          {/* <div className="list-view-option">
            <p className="single-view">
              <NavLink to="/work-orders">{t.workOrders.tabs.first}</NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/work-orders-kanban-view">
                {t.workOrders.kanbar}
              </NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/work-orders-calendar">
                {t.workOrders.tabs.second}
              </NavLink>
            </p>
          </div> */}
          <div className="calendar-view-options">
            <div
              onClick={() => {
                calendarRef.current.getApi().gotoDate(new Date());
                setCalendarDate(new Date());
                setWeekSelectedDate(getCurrentWeekDates());
              }}
              className="ml-4 mr-4"
            >
              {t.workOrders.today}
            </div>
            <div
              className={`${currentView === "dayGridMonth" ? "active" : ""}`}
              onClick={() => {
                changeView("dayGridMonth");

                setWeekFlag(false);
                setDayFlag(false);
              }}
            >
              {t.workOrders.month}
            </div>
            <div
              className={`${currentView === "timeGridWeek" ? "active" : ""}`}
              onClick={() => {
                changeView("timeGridWeek");

                setWeekFlag(true);
                setDayFlag(false);
              }}
            >
              {t.workOrders.week}
            </div>
            <div
              className={`${currentView === "timeGridDay" ? "active" : ""}`}
              onClick={() => {
                changeView("timeGridDay");

                setDayFlag(true);
                setWeekFlag(false);
              }}
            >
              {t.workOrders.day}
            </div>


            <div>
            <span className="c-pointer" onClick={handleCalendarDateLeftArrow}>
              {selectedLanguage == "ar" ? (
                <ArrowCalendarRight />
              ) : (
                <ArrowCalendarLeft />
              )}
            </span>
            <DatePicker
              style={weekFlag ? { maxWidth: "200px" } : {}}
              weekPicker={weekFlag}
              //   arrow={<div style={{ backgroundColor: 'white' }}>arrow</div>}
              value={
                dayFlag
                  ? new Date(calendarDate)
                  : weekFlag
                  ? weekSelectedDate
                  : new Date(calendarDate)
              }
              onChange={handleCalendarDateChange}
              // showOtherDays={true}
              // showMonthDropdown
              // showYearDropdown
              placeholder={t.workOrders.filter.dueDate}
              className={"active"}
              format={dayFlag ? "DD/MM/YY" : weekFlag ? "DD/MM/YY" : "MMMM"}
              onlyMonthPicker={!weekFlag && !dayFlag}
              // locale={lang == 'ar' ? arabic_ar : ''}
              weekDays={[
                t.workOrders.details_view.sun,
                t.workOrders.details_view.mon,
                t.workOrders.details_view.tue,
                t.workOrders.details_view.wed,
                t.workOrders.details_view.thu,
                t.workOrders.details_view.fri,
                t.workOrders.details_view.sat,
              ]}
              months={[
                t.common.january,
                t.common.february,
                t.common.march,
                t.common.april,
                t.common.may,
                t.common.june,
                t.common.july,
                t.common.august,
                t.common.september,
                t.common.october,
                t.common.november,
                t.common.december,
              ]}
              highlightToday={false}
            >
              <button
                onClick={() => {
                  setCalendarDate(new Date());
                  setWeekSelectedDate(getCurrentWeekDates());

                  let formattedValue = format(
                    new Date().valueOf(),
                    "yyyy-MM-dd"
                  );
                  calendarRef.current
                    .getApi()
                    .gotoDate(new Date(formattedValue));
                }}
                className="btn-datepicker blue"
              >
                {t.common.reset}
              </button>
            </DatePicker>
            <span className="c-pointer" onClick={handleCalendarDateRightArrow}>
              {selectedLanguage == "ar" ? (
                <ArrowCalendarLeft />
              ) : (
                <ArrowCalendarRight />
              )}
            </span>
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          </div>
        

     
        </div>
       
      </div>

      <div className="filters-wrapper justify-content-between align-items-start">
        <div className="flexbox table-right-dropdown">
          <WorkOrderSearchDropdown
            title={t.workRequests.form.people}
            options={filtersTrigger.people.options}
            selectedValues={filterOptions.people}
            searchValue={filterSearch.assignee}
            handleSearchValue={handleSearchAssigneesChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.people, value.value);

              setFilterOptions({
                ...filterOptions,
                people: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                assignee: "",
              });
            }}
          />

          <WorkOrderSearchDropdown
            title={t.teams.add}
            options={filtersTrigger.teams.options}
            selectedValues={filterOptions.teams}
            searchValue={filterSearch.teams}
            handleSearchValue={handleSearchTeamsChange}
            onClick={(value) => {
              let newArray = toggle(filterOptions.teams, value.value);

              setFilterOptions({
                ...filterOptions,
                teams: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                teams: "",
              });
            }}
          />

          <PriorityDropdown
            pill={true}
            title={t.workOrders.filter.priority}
            options={filtersTrigger.priority.options}
            selectedValues={filterOptions.priority}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  priority: e.target.value ? [e.target.value] : [],
                });
              } else {
                setFilterOptions({
                  ...filterOptions,
                  priority: [],
                });
              }
            }}
            icon={false}
            image={false}
          />

          <LocationSearchDropdown
            title={t.workOrders.table.location}
            options={filtersTrigger.locations.options}
            selectedValues={filterOptions.locations}
            searchValue={filterSearch.location}
            handleSearchValue={handleSearchLocationChange}
            onClick={(value) => {
              let finalValue = [value.value];
              if (!value?.parentId) {
                let subData = filtersTrigger.locations.initOptions.filter(
                  (el) => el?.parentId === value.value
                );

                let subDataValues = subData?.map((item) => item?.value);
                let subValues = subData?.map((item) => item?.value);

                subDataValues.push(value.value);
                let filterOptionsValues = filterOptions.locations;

                let filteredSubDataValues = subDataValues.filter((value) =>
                  filterOptionsValues.includes(value)
                );

                let valuesNotInFilterOptions = subValues.filter(
                  (value) => !filterOptionsValues.includes(value)
                );
                let valuesInFilterOptions = subValues.filter((value) =>
                  filterOptionsValues.includes(value)
                );

                if (filteredSubDataValues?.length > 0) {
                  if (
                    subValues.every((value) =>
                      filterOptionsValues.includes(value)
                    ) &&
                    !filterOptionsValues.includes(value.value)
                  ) {
                    finalValue = [value.value];
                  } else if (
                    valuesNotInFilterOptions?.length > 0 &&
                    filterOptionsValues?.includes(value.value)
                  ) {
                    finalValue = [...valuesInFilterOptions, value.value];
                  } else if (valuesNotInFilterOptions?.length > 0) {
                    finalValue = [...valuesNotInFilterOptions, value.value];
                  } else {
                    finalValue = [...filteredSubDataValues];
                  }
                } else {
                  finalValue = [...subDataValues];
                }
              }

              let newArray = toggle(filterOptions.locations, finalValue);
              setFilterOptions({
                ...filterOptions,
                locations: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                location: "",
              });
            }}
          />

          <CategoryDropdown
            title={t.workOrders.table.category}
            options={filtersTrigger.categories.options}
            selectedValues={filterOptions.categories}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  categories: [...filterOptions.categories, e.target.value],
                });
              } else {
                let filteredLocations = filterOptions.categories.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  categories: filteredLocations,
                });
              }
            }}
          />
          {/* {userPermissions?.customer && userPermissions?.customer?.view ?  */}
          <CategoryDropdown
            title={t.workOrders.table.customer}
            options={filtersTrigger.customers.options}
            selectedValues={filterOptions.customers}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  customers: [...filterOptions.customers, e.target.value],
                });
              } else {
                let filteredLocations = filterOptions.customers.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  customers: filteredLocations,
                });
              }
            }}
            icon={false}
            image={false}
          />
          {/* : null} */}
          {/* <CategoryDropdown
            title={t.workOrders.filter.locations}
            options={filtersTrigger.locations.options}
            selectedValues={filterOptions.locations}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  locations: [...filterOptions.locations, e.target.value],
                });
              } else {
                let filteredLocations = filterOptions.locations.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  locations: filteredLocations,
                });
              }
            }}
            icon={false}
            image={false}
          /> */}

          <div className="wo-date-picker" key={resetKey}>
            <DatePicker
              value={selectedDates}
              style={
                selectedDates.length <= 1
                  ? { width: "125px" }
                  : { width: "186px" }
              }
              onChange={handleChange}
              showOtherDays={true}
              range
              showMonthDropdown
              showYearDropdown
              placeholder={t.workOrders.filter.date}
              className={"active"}
              weekDays={[
                t.workOrders.details_view.sun,
                t.workOrders.details_view.mon,
                t.workOrders.details_view.tue,
                t.workOrders.details_view.wed,
                t.workOrders.details_view.thu,
                t.workOrders.details_view.fri,
                t.workOrders.details_view.sat,
              ]}
              months={[
                t.common.january,
                t.common.february,
                t.common.march,
                t.common.april,
                t.common.may,
                t.common.june,
                t.common.july,
                t.common.august,
                t.common.september,
                t.common.october,
                t.common.november,
                t.common.december,
              ]}
              format="DD/MM/YY"
            >
              <button onClick={handleDeselect} className="btn-datepicker blue">
                {t.common.reset}
              </button>
            </DatePicker>
           
          </div>
          {selectedDates.length > 0 ||
          Object.entries(filterOptions).some(([key, value]) =>
            Array.isArray(value) ? value.length > 0 : value !== ""
          ) ? (
          <div className="form-button-container mt-3">
            <button
              // content={t.procedures.form.submit.add}
              // primary
              onClick={() => {
                setSelectedDates([]);
                setFilterOptions({
                  serialNumber: "",
                  
                  people: [],
                  teams: [],
                  customers: [],
                  locations: [],
                  createdByUser: [],
                 
                  status: [],
                  priority: [],
                  categories: [],
                });
              }}
              // loading={buttonStatus == 1}
              style={{ marginTop: "-10px" }}
              className="form-action-button dashboard"
            >
              {t.dashboard.cards.clear}
            </button>
          </div> ) : null}
        </div>
        <div className="d-flex" style={{gap:"10px"}}>
        <div className="table-searchbar work-order-upload">
        <span className="search-svg">
                <SearchIcon />
              </span>
              <Input
              icon={{ name: "search" }}
              placeholder={t.workOrders.filter.search}
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            />
        </div>
        <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ">
          <UncontrolledDropdown className="mr-2" direction="down">
            <DropdownToggle
              style={{ position: "relative", cursor: "pointer" }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              color="primary"
              className="option-toggle"
            >
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "ar" ? "top right" : "top left"}
                show={showTooltip}
                textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                fontSize="12px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="4px 4px 4px 8px"
                borderRadius="5px"
                static
                moveRight="10px"
                moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
              >
                <span className="work-order-tooltip">
                  {t.common.page_action}
                </span>
              </ToolTip>
              <ActionBtnIcon />{" "}
            </DropdownToggle>
            <DropdownMenu className={selectedLanguage == "ar" ? "rtl" : ""}>
              {/* <DropdownItem>
                <ShareIcon /> Share
              </DropdownItem> */}
              <DropdownItem className="divider" />
              <DropdownItem onClick={() => downloadXLS()}>
                <DownloadIcon />
                {t.common.download_sheet}
              </DropdownItem>
              {/* <DropdownItem className="divider" />
              <DropdownItem onClick={() => generatePDF()}>
                <PdfIcon />  {t.common.download_pdf}
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        </div>
      </div>
    </>
  );
};

export default CalendarFilters;
