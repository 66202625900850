import React, { useState, useEffect, useRef } from 'react';
import {
  Icon,
  Image,
  List,
  Popup,
  Menu,
  Divider,
  Loader,
  Modal,
  Button,
  Header,
  Dimmer,
  Grid,
  Tab,
} from 'semantic-ui-react';
import { humanize, deleteTeam, IMAGES_URL } from '../../../config/functions';

import md5 from 'md5';
import Moment from 'react-moment';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { LetteredAvatarImageUrl } from '../../../components/common/userProfilePlaceholder';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectTranslations } from '../../../config/i18n/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseIcon,
  DeleteIcon,
  DownloadIconBucket,
  EditTextIcon,
  FileDownloadIcon,
  LocationBlueNoBackground,
  ShareIcon,
} from '../../../../Assets/Icons/svg';
import useDeletePermissionModal from '../../../../hooks/Messages/useDeletePermissionModal';
import ToolTip from 'react-power-tooltip';
import DownloadExcelFileWithData from '../../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../../services/DownloadPdfFileWithData';
import moment from 'moment';
import Details from './details';
import Log from './log';
import { Resizable } from 're-resizable';

function Preview({
  toggled,
  untoggle,
  targetedData,
  edit,
  refresh,
  setRefresh,
  setSearchTitle,
  setFilterOptions,
  setModalWidth,
  modalWidth,
}) {
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [showExclTooltip, setShowExclTooltip] = useState(false);
  const [showPdfTooltip, setShowPdfTooltip] = useState(false);
  const [showShareTooltip, setShareTooltip] = useState(false);
  const [showEditTooltip, setEditTooltip] = useState(false);
  const [showDeleteTooltip, setDeleteTooltip] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  // const roles = [
  //   {
  //     key: 0,
  //     value: 13,
  //     text: 'Admin',
  //   },
  //   {
  //     key: 98,
  //     value: 11,
  //     text: 'Super Admin',
  //   },
  //   {
  //     key: 89,
  //     value: 9,
  //     text: 'Supervisor',
  //   },
  //   {
  //     key: 1,
  //     value: 14,
  //     text: 'User',
  //   },
  //   {
  //     key: 2,
  //     value: 8,
  //     text: 'Requester only',
  //   },
  //   {
  //     key: 3,
  //     value: 12,
  //     text: 'Viewer only',
  //   },
  // ];

  const deleteHandler = async () => {
    const response = await deleteTeam(targetedData?.id);

    if (response.status == 200) {
      setDeleteErrorMessage(false);
      setFilterOptions({
        
        locations: [],
        customers: [],
        teams: [],
        superior: [],

        priority: [],
        status: [],
      });
      setSearchTitle('');
      toast.success(t.teams.team_deleted);
      toggleDeleteSuccessModal('close');
    } else if (response.status == 400) {
      setDeleteErrorMessage(true);
      toast.error(t.teams.team_connected);
      toggleDeleteSuccessModal();

      // toast.error(
      //   'Asset cannot be deleted as it is connected with other Work orders and procedures.'
      // );
    } else {
      toast.error(t.common.something_wrong);
    }
  };
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    if (data) {
      untoggle();
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    }
  };
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const history = useHistory();

  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();

  const t = useSelector(selectTranslations);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
  const panes = [
    {
      menuItem: t.common.details,
      pane: {
        key: 'details',
        content: (
          <div className="pane-body">
            <Details untoggle={untoggle} targetedData={targetedData} />
          </div>
        ),
      },
    },
    // {
    //   menuItem: t.workOrders.details_view.log,
    //   pane: {
    //     key: 'log',
    //     content: (
    //       <div className="pane-body">
    //         <Log targetedData={targetedData} />
    //       </div>
    //     ),
    //   },
    // },
  ];

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const generatePDF = () => {
    let csvData = [];

    let teams_data = [];

    teams_data.id = targetedData.id;
    teams_data.name = targetedData.attributes.name || t.common.na;

    teams_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, ' ').trim()
      : t.common.na;
    teams_data.superior = targetedData?.attributes?.superior?.data
      ? targetedData?.attributes.superior?.data?.attributes?.firstName +
        ' ' +
        targetedData?.attributes.superior?.data?.attributes?.lastName
      : t.common.na;

    let locationsData = targetedData.attributes.locations?.data;
    let locationsString = '';

    if (locationsData?.length > 0) {
      locationsString = locationsData
        .map((each) => each.attributes?.name)
        .join(', ');
    } else {
      locationsString = t.common.na;
    }

    teams_data.locations = locationsString;

    let assigneePeople = targetedData.attributes?.customers?.data;
    let assignString = '';

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople
        .map((people) => people?.attributes?.name)
        .join(', ');
    } else {
      assignString = t.common.na;
    }
    teams_data.customer = assignString;

    let teamsMember = targetedData.attributes?.users?.data;
    let teamsMemberString = '';
    if (teamsMember?.length > 0) {
      teamsMemberString = teamsMember
        .map(
          (people) =>
            `${people?.attributes?.firstName || ''} ${people.attributes
              .lastName || ''}`
        )
        .join(', ');
    } else {
      teamsMemberString = t.common.na;
    }
    teams_data.teamMember = teamsMemberString;

    teams_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        ' ' +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      teams_data.id,
      teams_data.name,
      teams_data.description,
      teams_data.superior,
      teams_data.locations,
      teams_data.customer,
      teams_data.teamMember,
      teams_data.createdBy,
    ]);

    const columnWidths = [, 30, 30, 30, 25];

    // const columnWidths = [
    //   10,
    //   20,
    //   ,
    //   20,
    //   50,
    //   20,
    //   22,
    //   15,
    //   20,
    //   ,
    //   ,
    //   25,
    //   ,
    //   ,
    //   ,
    //   45,
    //   20,
    // ];
    DownloadPdfFileWithData(
      headers,
      csvData,
      teams_data.name || t.teams.add,
      columnWidths,
      'A3'
    );
  };

  const downloadXLS = () => {
    let csvData = [];

    let teams_data = [];

    teams_data.id = targetedData.id;
    teams_data.name = targetedData.attributes.name || t.common.na;

    teams_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, ' ').trim()
      : t.common.na;
    teams_data.superior = targetedData?.attributes?.superior?.data
      ? targetedData?.attributes.superior?.data?.attributes?.firstName +
        ' ' +
        targetedData?.attributes.superior?.data?.attributes?.lastName
      : t.common.na;

    let locationsData = targetedData.attributes.locations?.data;
    let locationsString = '';

    if (locationsData?.length > 0) {
      locationsString = locationsData
        .map((each) => each.attributes?.name)
        .join(', ');
    } else {
      locationsString = t.common.na;
    }

    teams_data.locations = locationsString;

    let assigneePeople = targetedData.attributes?.customers?.data;
    let assignString = '';

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople
        .map((people) => people?.attributes?.name)
        .join(', ');
    } else {
      assignString = t.common.na;
    }
    teams_data.customer = assignString;

    let teamsMember = targetedData.attributes?.users?.data;
    let teamsMemberString = '';
    if (teamsMember?.length > 0) {
      teamsMemberString = teamsMember
        .map(
          (people) =>
            `${people?.attributes?.firstName || ''} ${people.attributes
              .lastName || ''}`
        )
        .join(', ');
    } else {
      teamsMemberString = t.common.na;
    }
    teams_data.teamMember = teamsMemberString;

    teams_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        ' ' +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      teams_data.id,
      teams_data.name,
      teams_data.description,
      teams_data.superior,
      teams_data.locations,
      teams_data.customer,
      teams_data.teamMember,
      teams_data.createdBy,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 35 }, // Procedure Items column
      { wch: 35 }, // Created By column
      { wch: 35 }, // Created At column
      { wch: 25 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      targetedData?.attributes?.name || t.teams.add
    );
  };

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const headers = [
    { label: t.assets.table.ID, key: 'id' },
    { label: t.people.table.name, key: 'name' },
    { label: t.assets.form.description, key: 'description' },
    { label: t.teams.team_info_form.superior, key: 'superior' },
    { label: t.teams.team_info_form.location, key: 'location' },
    { label: t.teams.team_info_form.customers, key: 'customer' },
    { label: t.teams.team_info_form.team_members, key: 'team_members' },
    { label: t.common.created_by, key: 'createdBy' },
  ];

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? 'aside-modal preview active procedure'
            : 'aside-modal preview procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.teams.add} #{targetedData?.attributes?.countId}
              </div>

              <div className="flexbox align-center detail-header-buttons">
                <span
                  className="header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setShowPdfTooltip(true)}
                  onMouseLeave={() => setShowPdfTooltip(false)}
                  onClick={() => generatePDF()}
                >
                  <DownloadIconBucket />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    arrowAlign="center"
                    position="bottom center"
                    show={showPdfTooltip}
                    textBoxWidth="60px"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.export_pdf}
                    </span>
                  </ToolTip>
                </span>
                {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowExclTooltip(true)}
                onMouseLeave={() => setShowExclTooltip(false)}
                onClick={() => downloadXLS()}
              >
                <FileDownloadIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  arrowAlign="center"
                  position="bottom center"
                  show={showExclTooltip}
                  textBoxWidth="67px"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_excel}
                  </span>
                </ToolTip>
              </span> */}
                {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShareTooltip(true)}
                onMouseLeave={() => setShareTooltip(false)}
              >
                <ShareIcon fill={'#788CA5'} />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  arrowAlign="center"
                  position="bottom center"
                  show={showShareTooltip}
                  textBoxWidth="40px"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.share}
                  </span>
                </ToolTip>
              </span> */}
                {userPermissions?.team?.edit ||
                checkForPermission(
                  targetedData?.attributes?.createdByUser?.data?.id
                ) ? (
                  <span
                    className="header-button c-pointer mt-1"
                    style={{ position: 'relative' }}
                    onMouseOver={() => setEditTooltip(true)}
                    onMouseLeave={() => setEditTooltip(false)}
                    onClick={() => {
                      edit();
                      setPopupIsOpen(false);
                    }}
                  >
                    <EditTextIcon />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      arrowAlign="center"
                      position="bottom center"
                      show={showEditTooltip}
                      textBoxWidth="33px"
                      padding="5px 5px 5px 8px"
                      borderRadius="5px"
                      static
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.edit}
                      </span>
                    </ToolTip>
                  </span>
                ) : null}
                {userPermissions?.team?.delete 
              //   ||
              // checkForPermission(
              //   targetedData?.attributes?.createdByUser?.data?.id
              // ) 
              ? (
                <span
                  className="header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setDeleteTooltip(true)}
                  onMouseLeave={() => setDeleteTooltip(false)}
                  onClick={() => {
                    setDeletePermissionModal(true);
                    setPopupIsOpen(false);
                  }}
                >
                  <DeleteIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                   arrowAlign="center"
                  position="bottom center"
                    show={showDeleteTooltip}
                    textBoxWidth="41px"
                 
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.delete}
                    </span>
                  </ToolTip>
                </span>
              ) : null}
                <span
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == 'en' ? 'ml-1' : 'mr-3'
                  }`}
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() => {
                    untoggle();
                    setModalWidth(Math.max(window.innerWidth / 2, 514));
                  }}
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div className="body">
            {targetedData ? (
              <Tab panes={panes} renderActiveOnly={false} />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => {
          untoggle();
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
      ></div>

      <Modal
        size="tiny"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper red">
              <Icon name="close" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">{t.teams.delete_teams}</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setDeleteModal(false);
              deleteHandler();
            }}
          >
            {t.common.delete}
          </Button>
          <Button color="black" onClick={() => setDeleteModal(false)}>
            {t.common.keep}
          </Button>
        </Modal.Actions>
      </Modal>

      <DeletePermissionModal
        yesCallback={() => {
          setDeleteModal(false);
          deleteHandler();
        }}
        title={t.teams.add}
      >
        <span>{t.teams.delete_teams}</span>
      </DeletePermissionModal>
    </>
  );
}

export default Preview;
