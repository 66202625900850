import React, { useEffect, useState, forwardRef, useRef } from 'react';
import _ from 'lodash';
import { DropdownItem, DropdownMenu, Image, Input } from 'semantic-ui-react';
import { DownChevron, SearchIcon } from '../../../Assets/Icons/svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';

function withClickOutside(WrappedComponent) {
  const Component = (props) => {
    const [open, setOpen] = useState(false);

    const ref = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);

    return (
      <WrappedComponent
        open={open}
        setOpen={setOpen}
        heading={props.heading}
        ref={ref}
        options={props.options || []}
        selectedValues={props.selectedValues || []}
        onClick={props.onClick}
        title={props.title}
        icon={props.icon}
        image={props.image}
        searchValue={props.searchValue}
        handleSearchValue={props.handleSearchValue}
        {...props}
      />
    );
  };

  return Component;
}

const SearchDropdown = forwardRef(
  (
    {
      open,
      setOpen,
      options = [],
      selectedValues = [],
      onClick = () => {},
      heading="",
      title = '',
      icon = true,
      image = false,
      searchValue = '',
      handleSearchValue = () => {},
    },
    ref
  ) => {   
    const { DisplayFormattedText } = useDisplayFormattedText();
    return (
      <>
        <div ref={ref} className="Custom-search-dropdown dashbaord custom-2-assets-dropdown">
          {heading ?  <div className="title-text mb-1">{heading}</div> : null}
       
          <UncontrolledDropdown
            className={`${open && 'active'} ${selectedValues.length > 0 ? 'blue-border' : ''}`}
            direction="down"
          >
            <DropdownToggle onClick={() => setOpen(!open)} color="primary">
              {title} <span className={selectedValues?.length > 0 ?'filter-count-div' : ""}>{selectedValues?.length > 0 ? selectedValues?.length  : null}</span> <DownChevron />{' '}
            </DropdownToggle>
            <DropdownMenu>
              <div className="table-searchbar m-3">
                <SearchIcon />
                <Input
                  autoComplete="new-password"
                  icon="search"
                  iconPosition="left"
                  className="dropdown-search-input search"
                  value={searchValue}
                  onChange={handleSearchValue}
               
                />
              </div>
              {options.map((option) => {
                return (
                  <DropdownItem>
                    <div
                      className={`custom-dropdown-item d-flex align-items-center ${
                        selectedValues.includes(option.value) ? 'selected' : ''
                      }`}
                      onClick={() => {
                        onClick(option);
                      }}
                    >
                      {icon && <Image
                        avatar
                        title={option.text}
                        src={option?.image?.src}
                      />}
                      
                      <span
                        style={{ marginLeft: '4px', marginRight: '4px' }}
                        className="custom-dropdown-text"
                      >
                        {icon ? DisplayFormattedText(option.text, 9) :  DisplayFormattedText(option.text, 16)}
                      </span>
                    </div>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </>
    );
  }
);
export default withClickOutside(SearchDropdown);
