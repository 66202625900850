import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import  People  from './pages/People';
import Teams  from './pages/Teams';
import Locations  from './pages/Locations';
import MapViewLocations from './pages/Locations/map-view';

import { Procedures } from './pages/Procedures';
import { GlobalProcedures } from './pages/Procedures/global';


import { WorkRequestsHistory } from './pages/WorkRequests/history';
import { WorkOrdersCalendar } from './pages/WorkOrders/calendar';

import  Customers  from './pages/Customers';
import  Vendors  from './pages/Vendors';
import { DashboardPage } from './pages/DashboardPage';

import { TimeTracking } from './pages/TimeTracking';
import { Payroll } from './pages/Payroll';

import  Parts  from './pages/Parts';
import { Sets } from './pages/Sets';

import  Assets  from './pages/Assets';
import { Settings } from './pages/Settings';
import Categories from './pages/Categories';
import MyCompanyProfile from './pages/MyCompanyProfile';
import MyProfile from './pages/MyProfile';
import WorkRequests from './pages/WorkRequests';
import AppleForm from './pages/AppleForm';
import ChangePassword from './pages/ChangePassword';
import { usePermissionsSimplified } from '../hooks/usePermissionsSimplified';
import ContactUs from './pages/Contact-us';
import JobTitle from './pages/JobTitle';
import KanbarView from './pages/WorkOrders/kanbarView';
import { Timesheet } from './pages/Timesheet/People';
import { TimesheetTeams } from './pages/Timesheet/Teams';
import Dashboard from './pages/Dashboard';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import  PurchaseOrder  from './pages/PurchaseOrders';

import WorkOrders from './pages/WorkOrders';


const GoogleMaterialPage = lazy(() =>
  import('./modules/GoogleMaterialExamples/GoogleMaterialPage')
);
const ReactBootstrapPage = lazy(() =>
  import('./modules/ReactBootstrapExamples/ReactBootstrapPage')
);
const ECommercePage = lazy(() =>
  import('./modules/ECommerce/pages/eCommercePage')
);
const UserProfilepage = lazy(() =>
  import('./modules/UserProfile/UserProfilePage')
);

export default function BasePage() {
  const { userPermissions } = usePermissionsSimplified();

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  // const organisationModule = useSelector((state) => {
  //   if (state?.auth?.organisation) {
  //     return state?.auth?.organisation.modularity;
  //   } else {
  //     return [];
  //   }
  // });

  // const hasLocationModule = organisationModule.some(
  //   (module) => module.module === 'location' && module.available
  //   );
  //   console.log('hasLocationModule: ', hasLocationModule)
  // const redirectTo = hasLocationModule ? '/locations' : '/profile';
  // console.log('redirectTo: ', redirectTo);

  // useEffect(() => {
  //   console.log('Base page');
  // }, [redirectTo]); // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to={'/locations'} />
        }

        {userPermissions?.people?.view || userPermissions?.team?.view ? (
          <Route path={userRole == "requester" ? "/teams" : "/people"} exact component={userRole == "requester" ? Teams : People} />
        ) : null}
        {userPermissions?.team?.view || userPermissions?.people?.view ? (
          <Route path="/teams" exact component={Teams} />
        ) : null}
        {userPermissions['work-order']?.view ? (
          <Route path="/work-orders" exact component={WorkOrders} />
        ) : null}
        {userPermissions['work-order']?.view ? (
          <Route
            path="/work-orders-calendar"
            exact
            component={WorkOrdersCalendar}
          />
        ) : null}

        {userPermissions['work-order']?.view ? (
          <Route path="/work-orders-kanban-view" exact component={KanbarView} />
        ) : null}

        {userPermissions?.location?.view ? (
          <Route path="/locations" exact component={Locations} />
        ) : null}
        {userPermissions?.location?.view ? (
          <Route
            path="/locations-map-view"
            exact
            component={MapViewLocations}
          />
        ) : null}
        {userPermissions?.procedure?.view ? (
          <Route path="/procedures" exact component={Procedures} />
        ) : null}

        {userPermissions?.customer?.view ? (
          <Route path="/customers" exact component={Customers} />
        ) : null}
        {userPermissions?.vendor?.view ? (
          <Route path="/vendors" exact component={Vendors} />
        ) : null}

        {userPermissions?.asset?.view ? (
          <Route path="/assets" exact component={Assets} />
        ) : null}
        {userPermissions?.part?.view ? (
          <Route path="/parts" exact component={Parts} />
        ) : null}
          {userPermissions?.['purchase-order']?.view ? (
          <Route path="/purchase-orders" exact component={PurchaseOrder} />
        ) : null}
         {userPermissions?.part?.view ? (
          <Route path="/sets" exact component={Sets} />
        ) : null}

        {userPermissions?.category?.view ? (
          <Route path="/category" exact component={Categories} />
        ) : null}

        {userPermissions?.jobTitle?.view ? (
          <Route path="/job-title" exact component={JobTitle} />
        ) : null}

        {userPermissions?.timesheet?.view ? (
          <Route path="/timesheet" exact component={Timesheet} />
        ) : null}
        {userPermissions?.timesheet?.view ? (
          <Route path="/timesheet-teams" exact component={TimesheetTeams} />
        ) : null}

        <Route path="/global-procedures" exact component={GlobalProcedures} />
        <Route path="/time-tracking" exact component={TimeTracking} />
        <Route path="/payroll" exact component={Payroll} />
        {userPermissions?.companySettings?.view ? (
          <Route path="/company-profile" exact component={MyCompanyProfile} />
        ) : null}
        <Route path="/settings" exact component={Settings} />
        {userPermissions?.dashboard?.view ? (
          <Route path="/dashboard" component={Dashboard} />
        ) : null}
      
        <Route path="/profile" exact component={MyProfile} />
        {/* <Route path="/apple-app-site-association" exact component={AppleForm} /> */}
        <Route path="/change-password" exact component={ChangePassword} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/work-requests" exact component={WorkRequests} />

        {/* <Redirect to="error/error-v1" /> */}
      </Switch>
    </Suspense>
  );
}
