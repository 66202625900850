import React, { useEffect, useState } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import {
  AddIconDashboard,
  CostIconEstimate,
  DashboardWOStatusIcon,
  DisposeCostIcon,
  FullArrow,
  MaintenanceCostIcon,
} from "../../../../Assets/Icons/svg";
import ReactApexChart from "react-apexcharts";
import NoDashboardData from "../noDashboardData";

const AssetTCO = ({
  chartConfigTCO,
  assetDashBoardData,
  setChartConfigTCO,
  openModal,
  setModalData,
  setOpenModal,
  setAssetDashBoardData,
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [showTooltip, setShowTooltip] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );

  const [chartData] = useState({
    series: [
      {
        name: 'PRODUCT A',
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: 'PRODUCT B',
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: 'PRODUCT C',
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: 'PRODUCT D',
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 50,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'last', // 'all', 'last'
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: 'datetime',
        categories: [
          '01/01/2011 GMT',
          '01/02/2011 GMT',
          '01/03/2011 GMT',
          '01/04/2011 GMT',
          '01/05/2011 GMT',
          '01/06/2011 GMT',
        ],
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  useEffect(() => {
    if (assetDashBoardData?.TCO?.length > 0) {
      const purchaseCost = assetDashBoardData?.TCO?.map(
        (item) => item.totalPurchaseCost
      );
      const totalMaintenanceCost = assetDashBoardData?.TCO?.map(
        (item) => item.totalMaintenanceCost
      );
      const totalDisposalCost = assetDashBoardData?.TCO?.map(
        (item) => item.totalDisposalCost
      );
      const labels = assetDashBoardData?.assetByCategory?.map(
        (item) => item.category
      );
      // const randomColors = dashBoardData?.workOrdersByAssigneeData?.map(item => {

      //     return item?.name === 'Unassigned' ? '#788CA5' : generateRandomColor();
      //   });

      //   const updatedStatusOptions = statusOptions.map(option => {
      //     const count = dashBoardData?.workOrderStatus[option.value]?.count || 0;
      //     return { ...option, count };
      //   });

      setChartConfigTCO((prevState) => ({
        ...prevState,
        series: [
          {
            name: t.dashboard.asset_card.p_price,
            data: purchaseCost, // Set count data
          },
          {
            name: t.dashboard.asset_card.m_price,
            data: totalMaintenanceCost, // Set count data
          },
          {
            name: t.dashboard.asset_card.disposal_price,
            data: totalDisposalCost, // Set count data
          },
        ],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: labels, // Set label data
          },
        },
      }));

      // const seriesPercentages = [
      //   open.percentage,
      //   inReview.percentage,
      //   onHold.percentage,
      //   completed.percentage,
      //   inProgress.percentage,
      // ];
    }
  }, [assetDashBoardData]);
  return (
    <>
     
        <Grid.Column className="completion-column pl-0 pt-0">
          <div
            className={
              openModal
                ? "completion-column-data no-border"
                : "completion-column-data"
            }
          >
            <div className="completion-cotainer d-flex justify-content-between mt-2">
              <div className="completion-title d-flex">
                <span className="title">{t.dashboard.asset_card.tco}</span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className={
                    selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                  }
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={selectedLanguage == "en" ? "right" : "left"}
                    show={showTooltip}
                    textBoxWidth="140px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="0px 0px 0px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="8px"
                    // moveDown="10px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.asset_tooltip.TCO_asset}
                    </span>
                  </Tooltip>
                  <DashboardWOStatusIcon />
                </p>
              </div>

              <span
                style={{ marginTop: "-10px", gap: "10px" }}
                className="d-flex"
              >
                <span
                  onClick={() => {
                    setOpenModal(true);
                    setModalData("TCO");
                    setShowExpandTooltip(false);
                  }}
                  style={{ marginTop: "2px" }}
                  className=""
                >
                  {openModal ? null : (
                    <p
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        marginTop: "-10px",
                      }}
                      onMouseOver={() => setShowExpandTooltip(true)}
                      onMouseLeave={() => setShowExpandTooltip(false)}
                      className=" mt-1"
                    >
                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top"
                        show={showExpandTooltip}
                        textBoxWidth="80px"
                        fontSize="10px"
                        fontWeight="400"
                        fontFamily="Roboto"
                        padding="8px 8px 8px 8px"
                        borderRadius="5px"
                        static
                        arrowAlign="start"
                        moveRight="5px"
                        moveDown="3px"
                      >
                        <span
                          style={{ padding: "0px 10px" }}
                          className="work-order-tooltip"
                        >
                          {t.dashboard.expand_full}
                        </span>
                      </Tooltip>
                      <FullArrow />
                    </p>
                  )}
                </span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowAddCustomizedTooltip(true)}
                  onMouseLeave={() => setShowAddCustomizedTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showAddCustomizedTooltip}
                    textBoxWidth="105px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="0px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.add_customized}
                    </span>
                  </Tooltip>
                  {/* <AddIconDashboard /> */}
                </p>
              </span>
            </div>
            {assetDashBoardData?.TCO?.length > 0 ? (
            <div className="completion-status-part">
              <Grid className="wo-data-container" columns={1}>
                <Grid.Column className="wo-data-chart  align-items-center text-center">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <p
                        className="d-flex align-items-center"
                        style={
                          selectedLanguage == "en"
                            ? { fontSize: "14px", color: "#44546F", gap: "8px" }
                            : { fontSize: "13px", color: "#44546F", gap: "8px" }
                        }
                      >
                        <span
                          className={selectedLanguage == "en" ? "ml-6" : "mr-6 mt-1"}
                        >
                          <CostIconEstimate width="9px" height="8px" />
                        </span>
                        {t.dashboard.asset_card.p_price}
                        <span
                          className={selectedLanguage == "en" ? "ml-6" : "mr-6 mt-1"}
                        >
                          {" "}
                          <MaintenanceCostIcon />{" "}
                        </span>
                        {t.dashboard.asset_card.m_price}
                        <span
                          className={selectedLanguage == "en" ? "ml-6" : "mr-6 mt-1"}
                        >
                          {" "}
                          <DisposeCostIcon />
                          <span
                            className={
                              selectedLanguage == "en" ? "ml-2" : "mr-2"
                            }
                          >
                            {" "}
                            {t.dashboard.asset_card.disposal_price}{" "}
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                
                  <div
                    className="scroll-data res"
                    style={{ overflowX: "auto", minWidth: "500px" }}
                  >
                    <div style={{ minWidth: "1000px" }}>
                      {" "}
                      {/* Adjust minWidth as needed */}
                      <ReactApexChart
                        //  options={chartData.options}
                        //  series={chartData.series}
                        options={chartConfigTCO?.options}
                        series={chartConfigTCO?.series}
                        type="bar"
                        height={openModal ? 400 : 277}
                      />
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            </div>) :<NoDashboardData height="313px"/>}
          </div>
        </Grid.Column>
      
    </>
  );
};

export default AssetTCO;
