export default {
  parts_small: "قطع",
  title: "قطع الغيار",
  add: "قطعة",
  create_part: "اضافة قطعة",
  part_data_name: "اسم القطع",
  title_parts: "القطع والمخزون",
  filter: {
    partType: "نوع القطعة",
    asset: "الممتلك",
    vendor: "المورد",
    location: "الموقع",
    area: "المنطقة",
    team: "الفريق",
    category: "الفئة",
    searchByName: "البحث عن طريق إسم قطع الغيار",
    searchBySerialNumber: "البحث عن طريق الرقم التسلسلي",
    barcode: "الباركود",
    createdByUser: "انشأ من قبل",
    createdAt: "تاريخ الإنشاء",
    qrcode: "رمز الاستجابة السريعة",
    area: "المنطقة",
    contains: "يتضمن",
    search: "بحث",
    reset: "إعادة ضبط",
    category: "الفئة",
    select_category: "اختر الفئة",
    attach_qr_code: "إرفق رمز الاستجابة السريعة",
    vendor: "المورد",
    select_vendor: "اختر البائع",
    people: "الأفراد",
    select_people: "اختر الافراد",
  },

  sheet: {
    sheet_name: "اسم القطعة*",
    description: "الوصف",
    category: "الفئة",
    unit_cost: "سعر القطعة *",
    unit_type: "وحدة القياس*",
    min_qty: "الحد الأدنى للكمية",
    max_qty: "الحد الأقصى للكمية",
    part_type: "نوع القطعة*",
    stock: "في المخزون / غير موجود في المخزون",
    location: "الموقع*",
    area: "المنطقة*",
    quantity: "الكمية*",
    assets: "الممتلكات",
    vendors: "الموردين",
    people: "الأفراد",
    teams: "الفرق",
    serialNumber: "الرقم التسلسلي*",
    qr_barcode: "رمز الاستجابة السريعة / رمز الباركود*",
  },

  sheet2: {
    sheet_name: "Part Name*",
    description: "Description",
    category: "Category",
    unit_cost: "Unit Cost*",
    unit_type: "Measuring Unit*",
    min_qty: "Min Quantity",
    max_qty: "Max.Quantity",
    part_type: "Part Type*",
    stock: "Stock/ Non-Stock",
    location: "Location *",
    area: "Area *",
    quantity: "Quantity *",
    assets: "Assets",
    vendors: "Vendors",
    people: "People",
    teams: "Teams",
    serialNumber: "Serial Number*",
    qr_barcode: "QR Code/ Bar Code*",
  },

  status: {
    instock: "متوفر في المخزون",
    inStock: "متوفر في المخزون",
    low_stock: "المخزون منخفض",
    out_of_stock: "نفذ من المخزون",
    non_stock: "غير موجود في المخزون",
  },

  qty_unit: {
    piece: "قطعة",
    pic: "قطع",
    items: "عنصر",
    item: "عناصر",
    unit: "وحدة",
    units: "وحدات",
    qty_title: "الوحدات القائمة على الكمية",
  },
  vol_unit: {
    liter: "لتر (ل)",
    gallons: "جالون",
    cubic_mtr: "متر مكعب (م3)",
    cubic_feet: "قدم مكعب (قدم3)",
    barrel: "براميل",
    ltr: "لتر (ل)",
    gal: "جالون",
    cub_mtr: "متر مكعب (م3)",
    cub_feet: "قدم مكعب (قدم3)",
    barrels: "برميل",
    vol_title: "الوحدات القائمة على الحجم",
  },

  mass_unit: {
    kilo: "كيلوغرام (كجم)",
    pounds: "رطل",
    ton: "طن",
    mat_tons: "طن متري",
    kg: "كيلوغرام (كجم)",
    pound: "رطل",
    tons: "طن",
    m_tons: "طن متري",
    mass_title: "الوحدات المعتمدة على الكتلة والوزن",
  },

  type: {
    critical: "مهم",
    non_critical: "غير مهم",
  },

  tab: {
    first: "القطع",
    second: "المجموعات",
  },
  table: {
    avg_unit_cost: "متوسط سعر القطعة",
    qty_in_stock: "الكمية في المخزون",
    team: "الفريق",
    created_on: "أنشىء في",
    updated_on: "تم التحديث في",
    name: "الإسم",
    ID: "الرمز",
    serialNumber: "الرقم التسلسلي",
    quantity: "الكمية",
    cost: "التكلفة",
    barcode: "الباركود",
    area: "المجال",
    category: "الفئة",
    vendor: "البائع",
    createdByUser: "انشأ من قبل",
    createdAt: "تاريخ الإنشاء",
    updatedAt: "تاريخ التحديث",
  },
  created_at_options: {
    any_date: "أي تاريخ",
    today: "اليوم",
    this_month: "هذا الشهر",
    this_year: "هذا العام",
    last_7_days: "٧ ايام الماضية",
    last_month: "الشهر الماضي",
    last_year: "العام الماضي",
  },
  form: {
    inv_loc: "موقع المخزون",
    add_inv_loc: "إضافة موقع المخزون",
    upload_excel_asset_file: "يرجى تحميل الملف الصحيح لإضافة قطع.",
    part_create_success: "تم إنشاء القطعة بنجاح",
    part_already_exist: "Part already exist with given name",
    min_qty_in_stoke_tooltip: "الحد الأدنى لكمية المخزون المتوفر",
    max_qty_in_stoke_tooltip: "الحد الأقصى لكمية المخزون المتوفر",
    add_name: "أضف اسم القطعة",
    add_area: "اختر المنطقة",
    select_part: "اختر نوع القطعة",
    qty_tooltip: "الكمية المراد إضافتها إلى المخزون",
    min_qty_tooltip: "الحد الأدنى لكمية المخزون المتوفر",
    max_qty_tooltip: "الحد الأقصى لكمية المخزون المتوفر",
    people_tooltip: "الأفراد المسؤولين",
    teams_tooltip: "الفرق المسؤولة",
    enter_qty: "أضف الكمية",
    enter_unit_cost: "أضف سعر القطعة",
    store_qty: "المخزون",
    add_store: "إضافة موقع",
    attach_file: "قم بإرفاق ملف للقطعة هذه",
    unit_type: "وحدة القياس",
    store: "Store",
    po: "طلبات الشراء",
    add_part: "اضافة قطعة",
    name: "الاسم",
    enter_part_name: "أدخل اسم القطع",
    description: "الوصف",
    describe_part: "اوصف  القطع",
    category: "الفئة",
    select_category: "اختر الفئة",
    area: "المنطقة",
    enter_part_area: "أدخل منطقة القطع",
    quantity: "الكمية",
    enter_part_quantity: "أدخل كمية القطع",
    value_validation: "يجب أن تكون القيمة >٠ وقيمة عددية صالحة",
    min_quantity: "Current Quantity",
    quantity: "Starting Quantity",
    enter_minimum_qty: "أضف الحد الأدنى من الكمية",
    enter_miximum_qty: "أضف الحد الأقصى من الكمية",
    // enter_min_quantity: 'Enter Part Minimum Quantity',
    minimum_qty: "الحد الأدنى للكمية",
    miximum_qty: "الحد الأقصى للكمية",
    enter_min_quantity: "أدخل الحد الأدنى للكمية",
    non_stock: "غير متوفر في المخزون",
    qr_code: "رمز الاستجابة السريعة",
    serial_number: "الرقم التسلسلي",
    enter_serial_number: "أدخل االرقم التسلسلي",
    cost: "التكلفة",
    enter_part_cost: "أدخل تكلفة الجزء",
    cost_type: "نوع التكلفة",
    select_cost_type: "اختر وحدة القياس",
    asset: "الممتلك",
    select_asset: "اختر الممتلكات",
    vendors: "الموردين",
    select_vendor: "اختر الموردين",
    procedure: "الإجراءات المتبعة",
    select_procedure: "اختر الإجراء",
    additional_part_details: "تفاصيل الجزء الإضافي",
    image: "الصورة",
    add: "إضافة",
    custom_data: "البيانات المخصصة",
    label: "الملصق",
    field_label: "تسمية الملصق",
    value: "القيمة",
    field_value: "قيمة الحقل",
    files: "ملفات",
    remove: "ازل",
    submit: "تقديم",
    cancel: "إلغاء",
    add_files: "إضافة ملفات",
    part: "القطع",
    per_piece: "لكل قطعة",
    per_set: "لكل مجموعة",
    per_liter: "لكل لتر",
    piece: "قطعة",
    set: "مجموعة",
    liter: "لتر",
    edit_part: "تعديل القطع",
    pre_unit: "Per Unit",
    unit_cost: "سعر القطعة",
    add_QR: "أنشىء رمز الاستجابة السريعة/الباركود",
    barcode: "الباركود",
    qrcode: "رمز الاستجابة السريعة",
    qty_in_stoke: "مجموع الكمية في المخزون",
    avg_cost: "متوسط سعر القطعة",
    avl_qty: "الكمية المتوفرة",
    order_qty: "الكمية المطلوبة",
    ordered_qty: "الكمية المطلوبة",
    reserved_qty: "الكمية المحجوزة",
    min_qty: "الحد الأدنى",
    max_qty: "الحد الأقصى",
    qty_in_stoke_tooltip: "الكمية الإجمالية في المخزون لكل موقع",
    avl_qty_tooltip:
      "إجمالي المخزون المتوفر، مطروحًا منه أي كمية محجوزة لأوامر العمل",
    order_qty_tooltip: "كميات طلبات الشراء المطلوبة والتي تنتظر الفوترة",
    reserved_qty_tooltip: "المخزون محجوز أو مجدول لأوامر عمل قادمة",
    no_po_found: "لا يوجد طلبات شراء مضافة",
    changes: "التغييرات",
    cancel_statement:
      "هل أنت متأكد أنك تريد إلغاء هذا القطعة؟ سوف تفقد المعلومات التي قمت بملئها",
    create_po: "إضافة طلب شراء",
    restock: "إعادة تعبئة",
    total_cost: "إجمالي التكلفة",
    restock_success: "تم إعادة التعبئة بنجاح",
  },
  upload_code: "تحميل الرمز",
  delete_part_statement: "هل أنت متأكد أنك تريد حذف هذه القطعة؟",
  delete_success: "تم حذف القطعة بنجاح",
  delete_error: "لا يمكن حذف القطعة. مرتبطة مع نماذج أخرى",
  related_part_error: "لا يمكن حذف القطعة لأنها متصلة بأوامر عمل وإجراءات أخرى",
  no_part_file: "لا توجد ملفات تنتمي إلى القطعة",
  added_success: "تم إضافة القطعة بنجاح!",
  updated_success: "تم تحديث القطعة بنجاح!",
  no_part_available: "يبدو أنك ليس لديك قطع بعد",
  press_to_add_part: "اضغط على زر '+' لإضافة أول قطعة لديك",
  no_parts_added: "ابدء في إضافة القطع  ",
  how_to_add_parts: "كيفية إضافة القطع و المخزون",
  minQty_valid: "الدقيقة. يجب أن تكون الكمية أقل من الحد الأقصى. كمية.",
  maxQty_valid: "يجب أن يكون الحد الأقصى للكمية أكبر من الحد الأدنى للكمية.",
  no_barcode:
    "لا يوجد أي ملف للقطعة مرتبط بهذا الرمز الاستجابة السريعة / الرمز الباركود",
  people_valid: "يجب تحديد إما الأشخاص أو الفرق.",
  errors: {
    "Name is empty.": "الاسم فارغ.",
    "Part name must be unique": "يجب أن يكون اسم القطعة فريدًا.",
    "No Category selected for this part.": "لم يتم تحديد أي فئة لهذه القطعة.",
    "No unitCost selected for this part.":
      "لم يتم تحديد أي تكلفة وحدة لهذه القطعة.",
    "No measuringUnit selected for this part.":
      "لم يتم تحديد أي وحدة قياس لهذه القطعة.",
    "No type selected for this part.": "لم يتم تحديد أي نوع لهذه القطعة.",
    "Serial number is empty.": "الرقم التسلسلي فارغ.",
    "Serial number contain 1 digit as a minimum and 13 digits as a maximum.":
      "يجب أن يحتوي الرقم التسلسلي على رقم واحد كحد أدنى و 13 رقمًا كحد أقصى.",
    "Serial number is not unique.": "الرقم التسلسلي ليس فريدًا.",
    "Serial number contains only digits.":
      "يحتوي الرقم التسلسلي على أرقام فقط.",
    "Serial Numbers must contain alphanumeric data from 1 to 13 digits":
      "يجب أن يحتوي الرقم التسلسلي على بيانات حروف او أرقام مكونة من 1 إلى 13 رقمًا.",
    "No QR/Bar Code selected for this part.":
      "لم يتم تحديد رمز الاستجابة السريعة/الباركود لهذه القطعة.",
    "No Location selected for this part.": "لم يتم تحديد أي موقع لهذه القطعة.",
    "No Area selected for this part.": "لم يتم تحديد أي منطقة لهذه القطعة.",
    "No Quantity selected for this part.": "لم يتم تحديد أي كمية لهذه القطعة.",

    "The part name is not unique.": "اسم القطعة ليس فريدًا.",
    "No unit cost was entered.": "لم يتم إدخال تكلفة الوحدة.",
    "No measuring unit was entered.": "لم يتم إدخال أي وحدة قياس.",
    "No part type was entered.": "لم يتم إدخال أي نوع قطعة.",
    "No location was entered.": "لم يتم إدخال أي موقع.",
    "No area was entered.": "لم يتم إدخال أي منطقة.",
    "No area entered does not exist in the location.":
      "لا توجد منطقة تم إدخالها غير موجودة في الموقع.",
    "No quantity was entered.": "لم يتم إدخال أي كمية.",
    "No QR/Bar Code selected for this part.":
      "لم يتم تحديد رمز الاستجابة السريعة/الباركود لهذه القطعة.",
    "No people or teams exist for this part.":
      "لا يوجد أفراد أو فرق  لهذه القطعة.",
    "The people entered do not exist in the system.":
      "الأفراد الذين تم إدخالهم غير موجودين في النظام.",
    "The team entered does not exist in the system.":
      "الفريق الذي تم إدخاله غير موجود في النظام.",
    "The asset entered does not exist in the system.":
      "الممتلك الذي تم إدخاله غير موجود في النظام.",
    "The vendor entered does not exist in the system.":
      "المورد الذي تم إدخاله غير موجود في النظام.",
    "Both the minimum and maximum quantities must be greater than 0. Additionally, the maximum quantity should be more than the minimum quantity.":
      "يجب أن يكون كل من الحد الأدنى للكمية والحد الأقصى للكمية أكبر من 0. بالإضافة إلى ذلك، يجب أن يكون الحد الأقصى للكمية أكبر من الحد الأدنى للكمية.",
  },
};
