export default {
  sets_small: 'مجموعات',
  title: 'مجموعات',
  add: 'مجموعة',
  add_set: 'اضافة مجموعة',
  create_set: 'إضافة مجموعة',
  edit_set: 'تعديل مجموعة',
  start_add: 'ابدء في إضافة المجموعات',
  how_to_add: 'كيفية إضافة القطع  والمخزون',

  table: {
    no_of_parts: 'عدد القطع',
    total_cost: 'التكلفة الإجمالية',
    items: 'عناصر',
  },

  form: {
    already:"تتضمن مجموعة",
    add_name: 'أضف اسم المجموعة',
    add_parts: 'أضف القطع',
    add_sets: 'أضف المجموعات',
    search_part: 'بحث القطع',
    search: 'بحث المجموعات',
    qty: 'الكمية',
    cancel_msg:
      'هل أنت متأكد أنك تريد إلغاء هذا المجموعة؟ سوف تفقد المعلومات التي قمت بملئها.',
  },
  add_success: 'تم إضافة المجموعة بنجاح!',
  delete_success: 'تم حذف المجموعة بنجاح!',
  update_success: 'تم تعديل المجموعة بنجاح',
  delete_set_statement: 'هل أنت متأكد أنك تريد حذف هذه المجموعة؟',
  delete_error: 'لا يمكن حذف المجموعة. مرتبطة بأمر عمل أو طلب شراء',
  wo_statement: 'يتم استخدام هذه المجموعة حاليًا بواسطة أوامر العمل التالية',
  add_valiadtion: 'يرجى إضافة إما 1قطع و1 مجموعة، أو ٢ قطع، أو ٢ مجموعة',
  no_part: 'لا توجد قطع مضافة حاليا إلى النظام',
  no_set: ' لا توجد مجموعات مُضافة حاليا إلى النظام',
  no_data: 'لا يوجد عناصر لعرضها',
  no_data_wo: '.لا توجد بيانات متاحة حاليًا',
  search_sets: 'بحث المجموعات',
  unique:"Set name must be unique",
};
