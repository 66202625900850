import React, { useEffect, useState } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import {
  AddIconDashboard,
  DashboardWOStatusIcon,
  FullArrow,
  GreenFlag,
  RedFlag,
  YellowFlag,
} from "../../../../Assets/Icons/svg";
import Chart from "react-apexcharts";
import { getResponseTimeByPriorityData } from "../../../config/functions";
import NoDashboardData from "../noDashboardData";

const ResolutionTimeByPriority = ({
  data12,
  dashBoardData,
  options12,
  series,
  setModalData,
  setOpenModal,
  chartConfigResolution,
  setchartConfigResolution,
  dateDropdownOption,
  setChartDateOption,
  chartDateOption,
  openModal
}) => {
    const t = useSelector(selectTranslations);
    const selectedLanguage = useSelector((state) => {
        if (state?.i18n?.lang) {
          return state.i18n.lang;
        } else {
          return "en";
        }
      });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );
  //   useEffect(() => {
  //     const fetchDashboardChartData = async () => {
  //       let chartData = await getResponseTimeByPriorityData(organisationId);
  //       console.log("dashboardData: ", chartData);
  //       // const fetchData = dispatch(
  //       //   fetchAssetsInitData({ organisationId, role, allIds })
  //       // );
  //     };

  //         fetchDashboardChartData();

  //   }, []);

  return (
    <Grid.Column className={"completion-column pl-0 pt-0"}>
      <div className={openModal ? "completion-column-data no-border" : "completion-column-data"}>
        <div className="completion-cotainer d-flex justify-content-between mt-2 pb-2">
          <div className="completion-title d-flex">
            <span className="title">{t.dashboard.cards.resolution}</span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className={
                selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
              }
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "en" ? "right" : "left"}
                show={showTooltip}
                textBoxWidth="140px"
                fontSize="10px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == 'ar'
                    ? 'Noto Kufi Arabic'
                    : 'Roboto'
                }
                padding="0px 0px 0px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="8px"
                // moveDown="10px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                 {t.dashboard.tooltip.resolution_prioprity}
                </span>
              </Tooltip>
              <DashboardWOStatusIcon />
            </p>
          </div>

          <span style={{ marginTop: "-10px", gap: "10px" }} className="d-flex">
            <span
              onClick={() => {
                setOpenModal(true);
                setModalData("PriorityResolution");
                setShowExpandTooltip(false)
              }}
              style={{ marginTop: "2px" }}
              className=""
            >
            {openModal ? null :  <p
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        marginTop: "-10px",
                      }}
                      onMouseOver={() => setShowExpandTooltip(true)}
                      onMouseLeave={() => setShowExpandTooltip(false)}
                      className=" mt-1"
                    >
                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top"
                        show={showExpandTooltip}
                        textBoxWidth="80px"
                        fontSize="10px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == 'ar'
                            ? 'Noto Kufi Arabic'
                            : 'Roboto'
                        }
                        padding="8px 8px 8px 8px"
                        borderRadius="5px"
                        static
                        arrowAlign="start"
                        moveRight="5px"
                        moveDown="3px"
                      >
                        <span
                          style={{ padding: "0px 10px" }}
                          className="work-order-tooltip"
                        >
                          {t.dashboard.expand_full}
                        </span>
                      </Tooltip>
                      <FullArrow />
                    </p>}
            </span>
            <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowAddCustomizedTooltip(true)}
                  onMouseLeave={() => setShowAddCustomizedTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showAddCustomizedTooltip}
                    textBoxWidth="105px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == 'ar'
                        ? 'Noto Kufi Arabic'
                        : 'Roboto'
                    }
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="0px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.add_customized}
                    </span>
                  </Tooltip>
                  {/* <AddIconDashboard /> */}
                </p>
          </span>
        </div>
       
        <div className="completion-status-part">
          <Grid className="wo-data-container" columns={1}>
            <Grid.Column className="wo-data-chart align-items-center text-center">
            <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <p
                    className="d-flex align-items-center"
                    style={
                      selectedLanguage == "en"
                        ? { fontSize: "14px", color: "#44546F", gap: "5px" }
                        : { fontSize: "13px", color: "#44546F", gap: "5px" }
                    }
                  >
                    <span
                      className={selectedLanguage == "en" ? "ml-5" : "mr-5"}
                    >
                      <RedFlag />
                    </span>
                    {t.workOrders.form.priority_options[3]}
                    <YellowFlag />
                    {t.workOrders.form.priority_options[2]}
                    <GreenFlag />
                    {t.workOrders.form.priority_options[1]}
                  </p>
                </div>
                <div className={selectedLanguage == "en" ?  "text-right ml-5 mr-5 " : "text-left ml-5 mr-5"}>
                <Dropdown
                  placeholder={"Date"}
                  className="dashboard-dropdown"
                  selection
                  noResultsMessage={t.common.no_results_found}
                  options={dateDropdownOption}
                  defaultValue={dateDropdownOption[1]?.value}
                  value={chartDateOption?.resolutionTime}
                  onChange={(e, value) => {
                    let findPriority = dateDropdownOption?.find(
                      (one) => one.value == value.value
                    );
                    if (findPriority) {
                      setChartDateOption({
                        ...chartDateOption,
                        resolutionTime: value.value,
                      });
                    }
                  }}
                />
              </div>
              </div>
              {chartConfigResolution?.series?.every(series =>
  series?.data?.every(value => value === 0)
) ?  <NoDashboardData height="296px"/> :
              <div
                className="scroll-data res"
                style={{ overflowX: "auto", minWidth: "500px",paddingLeft:"8px" }}
              >
                <div style={{ minWidth: "1000px" }}>
                  {" "}
                  {/* Adjust minWidth as needed */}
                  <Chart
                    options={chartConfigResolution?.options}
                    series={chartConfigResolution?.series}
                    type="bar"
                    height={openModal ? 400 :277}
                  />
                  
                </div>
              </div>}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </Grid.Column>
  );
};

export default ResolutionTimeByPriority;
