import React, { useState, useEffect, createRef } from 'react';
import {
  Dropdown,
  Modal,
  Input,
  Form,
  Button,
  Icon,
  TextArea,
  List,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import {
  AddIcon,
  CategoryInspection,
  CategoryMechanical,
  CatgoryPreventive,
  CloseIcon,
  CopyIcon,
  CorrectiveIcon,
  Damage,
  Electrical,
  HeadingIcon,
  OperationProcedure,
  PlannedIcon,
  Preventive,
  Project,
  Refrigeration,
  Safety,
  Success,
  UnPlannedIcon,
} from '../../../Assets/Icons/svg';
import {
  updateProcedure,
  removeFile,
  uploadFile,
} from '../../config/functions';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import { Resizable } from 're-resizable';

import FilterCloneList from './filter_clone_list';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import { toast } from 'react-toastify';
import Asterisk from '../../components/common/Asterisk';

import useProcedureFields from '../../../hooks/Procedure/useProcedureFields';
import { useSuccessModal } from '../../../hooks/Messages/useSuccessModal';
import { useCancelModal } from '../../../hooks/Messages/useCancelModal';
import { scrollToError } from '../../../utilFunctions/scrollToError';
import ToolTip from 'react-power-tooltip';
import { nanoid } from 'nanoid';
import useProcedureTFields from '../../../hooks/Procedure/useprocedureTest';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';
import { Spinner } from 'reactstrap';

function Edit({
  targetedData,
  toggled,
  untoggle,
  refresh,
  setRefresh,
  initCloneList,
  setInitCloneList,
  setFilterOptions,
  filterOptions,
  setSearchTitle,
  setSortData,
  setSelectedDates,
}) {
  const { userPermissions } = usePermissionsSimplified();
  const [duplicatedIndex, setDuplicatedIndex] = useState(null);
  const [finalIndex, setFinalIndex] = useState("20-0");
  const [fields, setFields] = useState([]);
  const { generatedProcedureFields } = useProcedureTFields(
    fields,
    duplicatedIndex,
    setDuplicatedIndex,
    setFinalIndex,
    finalIndex
  );
  const { setSuccessModal, SuccessModal } = useSuccessModal();
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [showCancleTooltip, setCancleTooltip] = useState(false);

  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const attachmentsRef = createRef();
  const [validation, setValidation] = useState({
    title: {
      error: true,
      pattern: (title) => title != '',
      skipped: false,
      message: t.common.required_field,
    },
    category: {
      error: true,
      pattern: (category) => category != '',
      skipped: false,
      message: t.common.required_field,
    },
    description: {
      error: true,
      pattern: (label) =>  label.length <= 500,
      skipped: false,
      message: t.common.characters_field
    
    },
  });

  const [initChange, setInitChange] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState({
    isheader: true,
    headerIndex: null,
    fieldIndex: null,
  });

  const [deletingFile, setDeletingFile] = useState(false);
  const [cloneModal, toggleCloneModal] = useState(false);
  const [cloningTemplate, setCloningTemplate] = useState(false);
  const [selectedTemplateID, setSelectedTemplateID] = useState(null);

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [options, setOptions] = useState({
    categories: [
      {
        key: 0,
        value: 'damage',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Damage />
            </span>
            {t.workOrders.form.category_options[0]}
          </span>
        ),
      },
      {
        key: 1,
        value: 'corrective action Downtime',
        text: (
          <span>
            <span className="ml-2 mr-2"><CorrectiveIcon/></span>
            {t.workOrders.form.category_options[1]}
          </span>
        ),
      },
      {
        key: 2,
        value: 'planned Downtime',
        text: (
          <span>
            <span className="ml-2 mr-2"><PlannedIcon/></span>
            {t.workOrders.form.category_options[2]}
          </span>
        ),
      },
      {
        key: 3,
        value: 'unplanned Downtime',
        text: (
          <span>
            <span className="ml-2 mr-2"><UnPlannedIcon/></span>
            {t.workOrders.form.category_options[3]}
          </span>
        ),
      },
      {
        key: 4,
        value: 'inspection',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryInspection />
            </span>
            {t.workOrders.form.category_options[4]}
          </span>
        ),
      },
      {
        key: 5,
        value: 'electrical',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Electrical />
            </span>
            {t.workOrders.form.category_options[5]}
          </span>
        ),
      },

      {
        key: 6,
        value: 'mechanical',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryMechanical />
            </span>
            {t.workOrders.form.category_options[6]}
          </span>
        ),
      },

      {
        key: 7,
        value: 'HVAC',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Refrigeration />
            </span>
            {t.workOrders.work_order_category.HVAC}
          </span>
        ),
      },
      {
        key: 8,
        value: 'preventive maintenance',
        text: (
          <span>
            <span className="ml-2 mr-2"><CatgoryPreventive/></span>
            {t.workOrders.form.category_options[8]}
          </span>
        ),
      },
      {
        key: 9,
        value: 'project',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Project />
            </span>
            {t.workOrders.form.category_options[9]}
          </span>
        ),
      },
      {
        key: 10,
        value: 'safety',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Safety />
            </span>
            {t.workOrders.form.category_options[10]}
          </span>
        ),
      },

      {
        key: 11,
        value: 'sop',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <OperationProcedure />
            </span>
            {t.workOrders.form.category_options[11]}
          </span>
        ),
      },
    ],
  });

  const [data, setData] = useState({
    title: '',
    description: '',
    category: 'damage',
  });

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const [validationProcedureItems, setValidationProcedureItems] = useState([]);

  const transformIncomingData = (incomingData) => {
    return {
      id: nanoid(),
      key: nanoid(),
      label: 'outer_header',
      fieldType: 'heading',
      isRequired: false,
      active: true,
      logic: false,
      attachments: [],
      prevAttachments: [],
      options: [
        {
          item: '',
        },
      ],
      procedureFields: [incomingData],
    };
  };

  const handleIncomingData = (incomingData) => {
    const transformedData = transformIncomingData(incomingData);
    setFields((prevFields) => [...prevFields, transformedData]);
  };

  const [defaultField, setDefaultField] = useState('checklist');

  // console.log(fields, data, validationProcedureItems);

  const resetForm = () => {
    setData({
      ...data,
      title: '',
      description: '',
      category: 'damage',
    });
    setFields([]);
    setFinalIndex("20-0")
    setValidationProcedureItems([]);

    setInitChange(false);

    setDuplicatedIndex(null);
  };

  useEffect(() => {
    if (toggled) {
      if (targetedData) {
        // console.log(targetedData);
        setData({
          ...data,
          title: targetedData?.attributes.title,
          description: targetedData?.attributes.description,
          category: targetedData?.attributes.category || 'damage',
        });

        if (targetedData?.attributes.headers?.length > 0) {
          const regenerate_fields = targetedData?.attributes.headers.map(
            (header) => {
              const headerLabel = header?.label; // Assuming you need the label for the header

              const headerFields = header.fields.map((field) => {
                const prevAttachments_obj = field.attachments?.data
                  ? field.attachments.data.map((file) => ({
                      id: file.id,
                      mime: file.attributes.mime,
                      name: file.attributes.name,
                      url: file.attributes.url,
                    }))
                  : [];

                const attachments_obj = field.attachments?.data
                  ? field.attachments.data.map((file) => file.id)
                  : [];

                if (field.fieldType === 'inspectionCheck') {
                  // Handling inspectionCheck type fields

                  const modifiedField = {
                    ...field,
                    key: nanoid(),
                    logicAnswer: field.failedValue ? field.failedValue : '',
                    logicTrigger:
                      field.logics && field.logics.length
                        ? field.logics.map((one) => one.trigger)
                        : null,
                    actionDetails:
                      field.logics &&
                      field.logics.find((one) => one.trigger === 'action')
                        ? field.logics.find((one) => one.trigger === 'action')
                            .description || ''
                        : '',
                    evidenceDetails:
                      field.logics &&
                      field.logics.find((one) => one.trigger === 'evidence')
                        ? field.logics.find((one) => one.trigger === 'evidence')
                            .description || ''
                        : '',
                  };

                  delete modifiedField.logics; // Remove logics property

                  return {
                    ...modifiedField,
                    attachments: attachments_obj,
                    prevAttachments: prevAttachments_obj,
                  };
                } else {
                  // Default handling for other field types (assuming checklist in this example)
                  return {
                    id: Math.floor(Math.random() * 90) + 10,
                    key: nanoid(),
                    label: field?.label, // Adjust label as needed
                    fieldType: field.fieldType,
                    isRequired: field.isRequired || false,
                    active: true, // Assuming default active status
                    logic: field.logic || false,
                    attachments: attachments_obj,
                    prevAttachments: prevAttachments_obj,
                    options: field.options || [{ item: '' }],
                  };
                }
              });

              // Return the header structure with mapped fields
              return {
                id: Math.floor(Math.random() * 90) + 10,
                key: nanoid(),
                label: `${headerLabel}`, // Assuming you need a label for the header
                placeholder: `${headerLabel}`, // Assuming you need a label for the header
                fieldType: 'heading',
                isRequired: false, // Adjust as needed
                active: true, // Assuming default active status
                logic: false, // Assuming headers don't have logic
                attachments: [],
                prevAttachments: [],
                options: [{ item: '' }], // Adjust options structure if needed
                procedureFields: headerFields, // Assign the mapped fields to procedureFields
              };
            }
          );

          // Assuming setFields and setValidationProcedureItems are functions to update state
          setFields(regenerate_fields);

          //   const itemValidationArray = regenerate_fields.map((header) => [
          //     {
          //       id: {
          //         error: false,
          //         pattern: (id) => id >= 0,
          //         skipped: false,
          //         message: t.common.required_field,
          //       },
          //       label: {
          //         error: true,
          //         pattern: (label) => label !== '',
          //         skipped: false,
          //         message: t.common.required_field,
          //       },
          //       fieldType: {
          //         error: false,
          //         pattern: (fieldType) => fieldType !== '',
          //         skipped: false,
          //         message: t.common.required_field,
          //       },
          //       options: header.options
          //         ? header.options.map((option) => ({
          //             error: false,
          //             pattern: (value, fieldType) =>
          //               ['multipleChoice', 'checklist'].includes(fieldType)
          //                 ? value !== ''
          //                 : true,
          //             skipped: false,
          //             message: t.common.required_field,
          //           }))
          //         : [],
          //     },
          //     ...header.procedureFields.map(() => ({
          //       procedureFields: [
          //         {
          //           id: {
          //             error: false,
          //             pattern: (id) => id >= 0,
          //             skipped: false,
          //             message: t.common.required_field,
          //           },
          //           label: {
          //             error: true,
          //             pattern: (label) => label !== '',
          //             skipped: false,
          //             message: t.common.required_field,
          //           },
          //           fieldType: {
          //             error: false,
          //             pattern: (fieldType) => fieldType !== '',
          //             skipped: false,
          //             message: t.common.required_field,
          //           },
          //           options: header.options
          //             ? header.options.map((option) => ({
          //                 error: false,
          //                 pattern: (value, fieldType) =>
          //                   ['multipleChoice', 'checklist'].includes(fieldType)
          //                     ? value !== ''
          //                     : true,
          //                 skipped: false,
          //                 message: t.common.required_field,
          //               }))
          //             : [],
          //         },
          //       ],
          //     })),
          //   ]);

          const itemValidationArray = regenerate_fields.map((header) => ({
            id: {
              error: false,
              pattern: (id) => id >= 0,
              skipped: false,
              message: t.common.required_field,
            },
            label: {
              error: true,
              pattern: (label) => label !== '' && label.length <= 500,
              skipped: false,
              message: t.common.required_field,
              messageData: (label) => {
                if (label === '') {
                  return t.common.required_field;
                } else if (label.length > 500) {
                  return t.common.characters_field;
                }
                return t.common.required_field;
              },
            },
            fieldType: {
              error: false,
              pattern: (fieldType) => fieldType !== '',
              skipped: false,
              message: t.common.required_field,
            },
            options: header.options
              ? header.options.map((option) => ({
                  error: false,
                  pattern: (value, fieldType) =>
                    ['multipleChoice', 'checklist',"checkbox"].includes(fieldType)
                      ? value !== ''&& value.length <= 500
                      : true,
                  skipped: false,
                  messageData: (label) => {
                    if (label === '') {
                      return t.common.required_field;
                    } else if (label.length > 500) {
                      return t.common.characters_field;
                    }
                    return t.common.required_field;
                  },
                  message: t.common.required_field,
                }))
              : [],

            procedureFields: header.procedureFields.map((optionsData) => ({
              id: {
                error: false,
                pattern: (id) => id >= 0,
                skipped: false,
                message: t.common.required_field,
              },
              label: {
                error: true,
                pattern: (label) => label !== '' && label.length <= 500,
                skipped: false,
                message: t.common.required_field,
                messageData: (label) => {
                  if (label === '') {
                    return t.common.required_field;
                  } else if (label.length > 500) {
                    return t.common.characters_field;
                  }
                  return t.common.required_field;
                },
              },
              fieldType: {
                error: false,
                pattern: (fieldType) => fieldType !== '',
                skipped: false,
                message: t.common.required_field,
              },
              options: optionsData?.options
                ? optionsData?.options?.map((option) => ({
                    error: false,
                    pattern: (value, fieldType) =>
                      ['multipleChoice', 'checklist','checkbox'].includes(fieldType)
                        ? value !== ''&& value.length <= 500
                        : true,
                    skipped: false,
                    message: t.common.required_field,
                    messageData: (label) => {
                      if (label === '') {
                        return t.common.required_field;
                      } else if (label.length > 500) {
                        return t.common.characters_field;
                      }
                      return t.common.required_field;
                    },
                  }))
                : [],
            })),
          }));

          // Now `transformedValidations` contains the desired structur

          setValidationProcedureItems(itemValidationArray);
        }

        if (targetedData?.attributes.fields.length > 0) {
          const regenerate_fields = targetedData?.attributes.fields.map(
            (field) => {
              const prevAttachments_obj = field?.attachments?.data
                ? field?.attachments?.data?.map((file) => {
                    return {
                      id: file.id,
                      mime: file.attributes.mime,
                      name: file.attributes.name,
                      url: file.attributes.url,
                    };
                  })
                : [];

              const attachments_obj = field?.attachments?.data
                ? field?.attachments?.data.map((file) => file.id)
                : [];
              if (field?.fieldType == 'inspectionCheck') {
                const modifiedField = {
                  ...field,
                  key: nanoid(),

                  logicAnswer: field.failedValue ? field.failedValue : '',
                  logicTrigger:
                    field?.logics && field?.logics?.length
                      ? field?.logics?.map((one) => one.trigger)
                      : null,
                  actionDetails:
                    field?.logics &&
                    field?.logics?.find((one) => one?.trigger == 'action')
                      ? field?.logics?.find((one) => one?.trigger == 'action')
                          ?.description || ''
                      : '',
                  evidenceDetails:
                    field?.logics &&
                    field?.logics?.find((one) => one.trigger == 'evidence')
                      ? field.logics.find((one) => one.trigger == 'evidence')
                          ?.description || ''
                      : '',
                };

                // Delete the logics property from the modified object
                delete modifiedField.logics;

                // Use the modified object instead of modifying the original field object
                return {
                  id: field?.id,
                  key: nanoid(),
                  label: 'outer_header',
                  fieldType: 'heading',
                  isRequired: false,
                  active: true,
                  logic: false,
                  attachments: [],
                  prevAttachments: [],
                  options: [
                    {
                      item: '',
                    },
                  ],
                  procedureFields: [
                    {
                      ...modifiedField,
                      attachments: attachments_obj,
                      prevAttachments: prevAttachments_obj,
                    },
                  ],
                };
              }
              return {
                id: field?.id,
                key: nanoid(),
                label: 'outer_header',
                fieldType: 'heading',
                isRequired: false,
                active: true,
                logic: false,
                attachments: [],
                prevAttachments: [],
                options: [
                  {
                    item: '',
                  },
                ],
                procedureFields: [
                  {
                    ...field,
                    key: nanoid(),
                    attachments: attachments_obj,
                    prevAttachments: prevAttachments_obj,
                  },
                ],
              };
            }
          );

          //   regenerate_fields.map((field)=>(handleIncomingData(field)))

          setFields((prevFields) => [...prevFields, ...regenerate_fields]);
          const itemValidationOuterArray = regenerate_fields.map((field) => {
            // Initialize validation object for each field
            const validationObj = {
              procedureFields: [
                {
                  id: {
                    error: false,
                    pattern: (id) => id >= 0,
                    skipped: false,
                    message: t.common.required_field,
                  },
                  label: {
                    error: true,
                    pattern: (label) => label !== '' && label.length <= 500,
                    skipped: false,
                    message: t.common.required_field,
                    messageData: (label) => {
                      if (label === '') {
                        return t.common.required_field;
                      } else if (label.length > 500) {
                        return t.common.characters_field;
                      }
                      return t.common.required_field;
                    },
                  },
                  fieldType: {
                    error: false,
                    pattern: (fieldType) => fieldType !== '',
                    skipped: false,
                    message: t.common.required_field,
                  },
                  options: field.options
                    ? field.options.map((option) => ({
                        error: false,
                        pattern: (value, fieldType) =>
                          ['multipleChoice', 'checklist','checkbox'].includes(fieldType)
                            ? value !== ''&& value.length <= 500
                            : true,
                        skipped: false,
                        message: t.common.required_field,
                        messageData: (label) => {
                          if (label === '') {
                            return t.common.required_field;
                          } else if (label.length > 500) {
                            return t.common.characters_field;
                          }
                          return t.common.required_field;
                        },
                      }))
                    : [],
                },
              ],
            };

            // Now add any additional validations or properties specific to the field if needed

            return validationObj;
          });

          setValidationProcedureItems((prevFields) => [
            ...prevFields,
            ...itemValidationOuterArray,
          ]);
          // Now set the state with the validation array
        }
      }
    }
  }, [targetedData, toggled]);

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    let currentFieldValue = '';
    const dropdown_fields = ['category'];

    if (!dropdown_fields.includes(name)) {
      currentFieldValue = event.target.value;
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      currentFieldValue = value.value;
      setData({
        ...data,
        [name]: value.value,
      });
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
          skipped: true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  const addItem = (isHeading = false) => {
    setInitChange(true);
    const headerCount = fields.filter((field) => field.label !== 'outer_header')
      .length;
    const id = fields.length + 1;

    const totalProcedureFields = fields.reduce((acc, field) => {
      return acc + field.procedureFields.length;
    }, 0);

    const new_fields = [...fields];

    const procedureItemsValidation = [...validationProcedureItems];
    new_fields.forEach((el) => (el.active = false));
    // setActiveIndex(fields.length);

    if (isHeading) {
      let newField = {
        id: id,
        key: nanoid(),
        label: ``,
        placeholder: `${t.procedures.form.header} ${headerCount + 1}`,
        fieldType: 'heading',
        isRequired: false,
        active: true,
        logic: false,
        attachments: [],
        prevAttachments: [],
        options: [
          {
            item: '',
          },
        ],
        procedureFields: [
          // {
          //   id: 1,
          //   key: nanoid(),
          //   label: `${t.procedures.form.add_procedure_item} 1`,
          //   fieldType: 'checklist',
          //   isRequired: false,
          //   active: true,
          //   logic: false,
          //   attachments: [],
          //   prevAttachments: [],
          //   options: [
          //     {
          //       item: '',
          //     },
          //   ],
          // },
        ],
      };

      let newValidationData = {
        id: {
          error: false,
          pattern: (id) => id >= 0,
          skipped: false,
          message: t.common.required_field,
        },
        label: {
          error: true,
          pattern: (label) => label !== '' && label.length <= 500,
          skipped: false,
          message: t.common.required_field,
          messageData: (label) => {
            if (label === '') {
              return t.common.required_field;
            } else if (label.length > 500) {
              return t.common.characters_field;
            }
            return t.common.required_field;
          },
        },
        fieldType: {
          error: false,
          pattern: (fieldType) => fieldType != '',
          skipped: false,
          message: t.common.required_field,
        },
        options: [
          {
            error: false,
            pattern: (value, fieldType) =>
              ['multipleChoice', 'checklist', 'checkbox'].includes(fieldType)
                ? value != ''&& value.length <= 500
                : true,
            skipped: false,
            message: t.common.required_field,
            messageData: (label) => {
              if (label === '') {
                return t.common.required_field;
              } else if (label.length > 500) {
                return t.common.characters_field;
              }
              return t.common.required_field;
            },
          },
        ],

        procedureFields: [
          // {
          //   id: {
          //     error: false,
          //     pattern: (id) => id >= 0,
          //     skipped: false,
          //     message: t.common.required_field,
          //   },
          //   label: {
          //     error: true,
          //     pattern: (label) => label != '',
          //     skipped: false,
          //     message: t.common.required_field,
          //   },
          //   fieldType: {
          //     error: false,
          //     pattern: (fieldType) => fieldType != '',
          //     skipped: false,
          //     message: t.common.required_field,
          //   },
          //   options: [
          //     {
          //       error: false,
          //       pattern: (value, fieldType) =>
          //         ['multipleChoice', 'checklist'].includes(fieldType)
          //           ? value != ''
          //           : true,
          //       skipped: false,
          //       message: t.common.required_field,
          //     },
          //   ],
          // },
        ],
      };

      const updatedFields = [
        ...fields.slice(0, headerCount),
        newField,
        ...fields.slice(headerCount),
      ];

      const updatedValidationProcedureItems = [
        ...validationProcedureItems.slice(0, headerCount),
        newValidationData,
        ...validationProcedureItems.slice(headerCount),
      ];
      setDuplicatedIndex(`${headerCount}`);
      setFields(updatedFields);

      setValidationProcedureItems(updatedValidationProcedureItems);
    } else {
      let my_fields = [...fields];
      let newfields123 = my_fields?.filter(
        (field) =>
          field.procedureFields.length > 0 || field.label !== 'outer_header'
      );
      const lastField = newfields123[newfields123?.length - 1];

      const lastNonOuterHeaderIndex = my_fields
        .map((field, index) => ({ ...field, index })) // Add the original index to each field
        .reverse() // Reverse the array to find the last match
        .find((field) => field.label !== 'outer_header')?.index;

      let type =
      
        new_fields[lastNonOuterHeaderIndex]?.procedureFields[
          new_fields?.procedureFields?.length - 1
        ]?.fieldType;

      // if (lastNonOuterHeaderIndex) {
      //   let newField = {
      //     id: totalProcedureFields + 1,
      //     key: nanoid(),
      //     label: `${
      //       t.procedures.form.add_procedure_item
      //     } ${totalProcedureFields + 1}`,
      //     fieldType: type ? type : 'checklist',
      //     isRequired: false,
      //     active: true,
      //     logic: false,
      //     attachments: [],
      //     prevAttachments: [],
      //     options: [
      //       {
      //         item: '',
      //       },
      //     ],
      //   };
      //   let validationObj = {
      //     id: {
      //       error: false,
      //       pattern: (id) => id >= 0,
      //       skipped: false,
      //       message: t.common.required_field,
      //     },
      //     label: {
      //       error: true,
      //       pattern: (label) => label != '',
      //       skipped: false,
      //       message: t.common.required_field,
      //     },
      //     fieldType: {
      //       error: false,
      //       pattern: (fieldType) => fieldType != '',
      //       skipped: false,
      //       message: t.common.required_field,
      //     },
      //     options: [
      //       {
      //         error: false,
      //         pattern: (value, fieldType) =>
      //           ['multipleChoice', 'checklist', 'checkbox'].includes(fieldType)
      //             ? value != ''
      //             : true,
      //         skipped: false,
      //         message: t.common.required_field,
      //       },
      //     ],
      //   };

      //   let newfields = [...fields];

      //   let procedureValidation = [...validationProcedureItems];
      //   const headerFieldData = newfields[lastNonOuterHeaderIndex];
      //   const procedureFieldsToAdd = headerFieldData?.procedureFields || [];
      //   procedureFieldsToAdd.push(newField);
      //   headerFieldData.procedureFields = procedureFieldsToAdd;

      //   // if (finalIndex == "20-0") {
      //   //   newfields[0] = headerFieldData;
      //   // } else {
      //   newfields[lastNonOuterHeaderIndex] = headerFieldData;
      //   // }
      //   // newfields[index[0]] = headerFieldData;
      //   setFields(newfields);

      //   setDuplicatedIndex(
      //     `${lastNonOuterHeaderIndex}-${procedureFieldsToAdd?.length - 1}`
      //   );

      //   const procedureValidationData =
      //     procedureValidation[lastNonOuterHeaderIndex];
      //   const procedureValidationToAdd =
      //     procedureValidationData?.procedureFields || [];

      //   procedureValidationToAdd.push(validationObj);
      //   procedureValidationData.procedureFields = procedureValidationToAdd;
      //   // if (finalIndex == "20-0") {
      //   procedureValidation[lastNonOuterHeaderIndex] = procedureValidationData;
      //   // }
      //   // else {
      //   //    procedureValidation[index[0]] = procedureValidationData;
      //   // }
      //   // procedureValidation[index[0]] = procedureValidationData;
      //   setValidationProcedureItems(procedureValidation);
      // } 
      // else {
        let checkForType;
        // let index = finalIndex?.split('-');
        // let index = new_fields[new_fields.length - 1];
        let index = new_fields[new_fields.length - 1];
        let headerField;
        // if (finalIndex) {
          checkForType = lastField?.procedureFields[lastField?.procedureFields.length - 1]?.fieldType
            ? lastField?.procedureFields[lastField?.procedureFields.length - 1]?.fieldType
            : 'checklist';

        //   }
          headerField =
          lastField?.label == 'outer_header' ? true : false;
        // if(lastField?.label === "outer_header"){

        // }

        if (headerField) {
          let newField = {
            id: id,
            key: nanoid(),
            label: `outer_header`,
            fieldType: 'heading',
            isRequired: false,
            active: true,
            logic: false,
            attachments: [],
            prevAttachments: [],
            options: [
              {
                item: '',
              },
            ],
            procedureFields: [
              {
                id: totalProcedureFields + 1,
                key: nanoid(),
                label: ``, placeholder: `${
                  t.procedures.form.add_procedure_item
                } ${totalProcedureFields + 1}`,
                fieldType: checkForType ? checkForType : 'checklist',
                isRequired: false,
                active: true,
                logic: false,
                attachments: [],
                prevAttachments: [],
                options: [
                  {
                    item: '',
                  },
                ],
              },
            ],
          };

          let newValidationData = {
            procedureFields: [
              {
                id: {
                  error: false,
                  pattern: (id) => id >= 0,
                  skipped: false,
                  message: t.common.required_field,
                },
                label: {
                  error: true,
                  pattern: (label) => label !== '' && label.length <= 500,
                  skipped: false,
                  message: t.common.required_field,
                  messageData: (label) => {
                    if (label === '') {
                      return t.common.required_field;
                    } else if (label.length > 500) {
                      return t.common.characters_field;
                    }
                    return t.common.required_field;
                  },
                },
                fieldType: {
                  error: false,
                  pattern: (fieldType) => fieldType != '',
                  skipped: false,
                  message: t.common.required_field,
                },
                options: [
                  {
                    error: false,
                    pattern: (value, fieldType) =>
                      ['multipleChoice', 'checklist', 'checkbox'].includes(
                        fieldType
                      )
                        ? value != ''&& value.length <= 500
                        : true,
                    skipped: false,
                    message: t.common.required_field,
                    messageData: (label) => {
                      if (label === '') {
                        return t.common.required_field;
                      } else if (label.length > 500) {
                        return t.common.characters_field;
                      }
                      return t.common.required_field;
                    },
                  },
                ],
              },
            ],
          };
          setDuplicatedIndex(`${fields?.length}-${0}`);
          setFields([...fields, newField]);
          setValidationProcedureItems([
            ...validationProcedureItems,
            newValidationData,
          ]);
        } else {

          let newfieldsData = [...fields];
          let newfields = newfieldsData?.filter(
            (field) =>
              field.procedureFields.length > 0 || field.label !== 'outer_header'
          );
          console.log(newfields,"sssss")
          // let newfields = [...fields];
          let procedureValidationDatas = [...validationProcedureItems];

          const hasOnlyProcedureFields = (item) => {
            return Object.keys(item).length !== 1 ;
        };

        let procedureValidation = procedureValidationDatas?.filter(hasOnlyProcedureFields);      
          // let procedureValidation = procedureValidationDatas?.filter(
          //   (field) =>
          //     field.procedureFields.length > 0
          // );

          checkForType = newfields[newfields?.length - 1 ]?.procedureFields[newfields[newfields?.length - 1].procedureFields.length - 1]?.fieldType
          ? newfields[newfields?.length - 1 ]?.procedureFields[newfields[newfields?.length - 1].procedureFields.length - 1]?.fieldType
          : 'checklist';

          let newField = {
            id: totalProcedureFields + 1,
            key: nanoid(),
            label: ``,     placeholder: `${
              t.procedures.form.add_procedure_item
            } ${totalProcedureFields + 1}`,
            fieldType: checkForType ? checkForType : 'checklist',
            isRequired: false,
            active: true,
            logic: false,
            attachments: [],
            prevAttachments: [],
            options: [
              {
                item: '',
              },
            ],
          };
          let validationObj = {
            id: {
              error: false,
              pattern: (id) => id >= 0,
              skipped: false,
              message: t.common.required_field,
            },
            label: {
              error: true,
              pattern: (label) => label !== '' && label.length <= 500,
              skipped: false,
              message: t.common.required_field,
              messageData: (label) => {
                if (label === '') {
                  return t.common.required_field;
                } else if (label.length > 500) {
                  return t.common.characters_field;
                }
                return t.common.required_field;
              },
            },
            fieldType: {
              error: false,
              pattern: (fieldType) => fieldType != '',
              skipped: false,
              message: t.common.required_field,
            },
            options: [
              {
                error: false,
                pattern: (value, fieldType) =>
                  ['multipleChoice', 'checklist', 'checkbox'].includes(
                    fieldType
                  )
                    ? value != ''&& value.length <= 500
                    : true,
                skipped: false,
                messageData: (label) => {
                  if (label === '') {
                    return t.common.required_field;
                  } else if (label.length > 500) {
                    return t.common.characters_field;
                  }
                  return t.common.required_field;
                },
                message: t.common.required_field,
              },
            ],
          };


     
          const headerFieldData =  newfields[newfields?.length - 1];

          const procedureFieldsToAdd = headerFieldData?.procedureFields || [];
          procedureFieldsToAdd.push(newField);
          headerFieldData.procedureFields = procedureFieldsToAdd;
          newfields[newfields?.length - 1] = headerFieldData;

          let dummy_field={
            id: 1,
            key: nanoid(),
            label: ``,
            placeholder: `${t.procedures.form.header} 1`,
            fieldType: 'heading',
            label: `outer_header`,
            isRequired: false,
            active: true,
            logic: false,
            attachments: [],
            prevAttachments: [],
            options: [
              {
                item: '',
              },
            ],
            procedureFields: [],
          }

          newfields.push(dummy_field)
          // if (finalIndex == '20-0') {
          //   newfields[0] = headerFieldData;
          // } else {
          //   newfields[index[0]] = headerFieldData;
          // }
          // newfields[index[0]] = headerFieldData;
          setFields(newfields);

          // setDuplicatedIndex(
          //   finalIndex == '20-0'
          //     ? `${0}-${procedureFieldsToAdd?.length - 1}`
          //     : `${index[0]}-${procedureFieldsToAdd?.length - 1}`
          // );

          const procedureValidationData =  procedureValidation[procedureValidation?.length - 1]
         
            // finalIndex == '20-0'
          
              // : procedureValidation[index[0]];
          const procedureValidationToAdd =
            procedureValidationData?.procedureFields || [];

          procedureValidationToAdd.push(validationObj);
          procedureValidationData.procedureFields = procedureValidationToAdd;
          // if (finalIndex == '20-0') {
            procedureValidation[procedureValidation?.length - 1] = procedureValidationData;
          // } else {
          //   procedureValidation[index[0]] = procedureValidationData;


          // }
          // procedureValidation[index[0]] = procedureValidationData;

          let dummy_validation =  {
            procedureFields: [],
          }

          procedureValidation.push(dummy_validation)
          setValidationProcedureItems(procedureValidation);

          // setValidationProcedureItems([
          //   ...validationProcedureItems,
          //   newValidationData,
          // ]);
        }
      // }

      // }
    }
  };

  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      title: {
        ...validation.title,
        error: validation.title.pattern(data.title) ? false : true,
        skipped: true,
      },
      category: {
        ...validation.category,
        error: validation.category.pattern(data.category) ? false : true,
        skipped: true,
      }, description: {
        ...validation.description,
        error: validation.description.pattern(data.description) ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    return errorsInData;
  };

  // const procedureItemValidationHandler = () => {
  //   let latestValidation = [];
  //   let error = false;
  //   let itemValidations = cloneDeep(validationProcedureItems);
  //   for (let i = 0; i < itemValidations.length; i++) {
  //     let currentValidation = {
  //       ...itemValidations[i],
  //       id: {
  //         ...itemValidations[i].id,
  //         error: itemValidations[i].id.pattern(fields[i].id) ? false : true,
  //         skipped: true,
  //       },
  //       label: {
  //         ...itemValidations[i].label,
  //         error: itemValidations[i].label.pattern(fields[i].label)
  //           ? false
  //           : true,
  //         skipped: true,
  //       },
  //       fieldType: {
  //         ...itemValidations[i].fieldType,
  //         error: itemValidations[i].fieldType.pattern(fields[i].fieldType)
  //           ? false
  //           : true,
  //         skipped: true,
  //       },
  //       options: itemValidations[i].options.map((one, index) => {
  //         return {
  //           ...one,
  //           error: one.pattern(
  //             fields[i].options[index].item,
  //             fields[i].fieldType
  //           )
  //             ? false
  //             : true,
  //           skipped: true,
  //         };
  //       }),
  //     };
  //     latestValidation.push(currentValidation);
  //     setValidationProcedureItems(latestValidation);
  //     const validationToArray = Object.entries(currentValidation);
  //     const errorsInData = validationToArray.some((el) => {
  //       if (el[1].error) {
  //         return true;
  //       } else if (Array.isArray(el[1])) {
  //         let errorInOptions = el[1].some((op, indexOp) => {
  //           if (op.error) {
  //             return true;
  //           }
  //         });
  //         if (errorInOptions) {
  //           return true;
  //         }
  //       }
  //     });
  //     if (!error) {
  //       error = errorsInData;
  //     }
  //   }

  //   return error;
  // };
  // const procedureItemValidationHandler = () => {
  //   let latestValidation = [];
  //   let error = false;
  //   let itemValidations = cloneDeep(validationProcedureItems);
  //   for (let i = 0; i < itemValidations.length; i++) {
  //     let currentValidation = {
  //       ...itemValidations[i],
  //       id: {
  //         ...itemValidations[i].id,
  //         error: itemValidations[i].id.pattern(fields[i].id) ? false : true,
  //         skipped: true,
  //       },
  //       label: {
  //         ...itemValidations[i].label,
  //         error: itemValidations[i].label.pattern(fields[i].label)
  //           ? false
  //           : true,
  //         skipped: true,
  //       },
  //       fieldType: {
  //         ...itemValidations[i].fieldType,
  //         error: itemValidations[i].fieldType.pattern(fields[i].fieldType)
  //           ? false
  //           : true,
  //         skipped: true,
  //       },
  //       options: itemValidations[i].options.map((one, index) => {
  //         return {
  //           ...one,
  //           error: one.pattern(
  //             fields[i].options[index].item,
  //             fields[i].fieldType
  //           )
  //             ? false
  //             : true,
  //           skipped: true,
  //         };
  //       }),
  //     };
  //     latestValidation.push(currentValidation);
  //     setValidationProcedureItems(latestValidation);
  //     const validationToArray = Object.entries(currentValidation);
  //     const errorsInData = validationToArray.some((el) => {
  //       if (el[1].error) {
  //         return true;
  //       } else if (Array.isArray(el[1])) {
  //         let errorInOptions = el[1].some((op, indexOp) => {
  //           if (op.error) {
  //             return true;
  //           }
  //         });
  //         if (errorInOptions) {
  //           return true;
  //         }
  //       }
  //     });
  //     if (!error) {
  //       error = errorsInData;
  //     }
  //   }

  //   return error;
  // };
  const procedureItemValidationHandler = () => {
    let latestValidation = [];
    let error = false;
    let itemValidations = cloneDeep(validationProcedureItems);

    for (let i = 0; i < itemValidations.length; i++) {
      let currentValidation;
      if (fields[i].label !== 'outer_header') {
        currentValidation = {
          ...itemValidations[i],
          id: {
            ...itemValidations[i].id,
            error: itemValidations[i].id.pattern(fields[i].id) ? false : true,
            skipped: true,
          },
          label: {
            ...itemValidations[i].label,
            error: itemValidations[i].label.pattern(fields[i].label)
              ? false
              : true,
            skipped: true,
          },
          fieldType: {
            ...itemValidations[i].fieldType,
            error: itemValidations[i].fieldType.pattern(fields[i].fieldType)
              ? false
              : true,
            skipped: true,
          },
          options: itemValidations[i]?.options?.map((one, index) => {
            return {
              ...one,
              error: one?.pattern(
                fields[i]?.options[index]?.item,
                fields[i]?.fieldType
              )
                ? false
                : true,
              skipped: true,
            };
          }),
          procedureFields: itemValidations[i]?.procedureFields?.map(
            (pf, pfIndex) => {
              return {
                ...pf,
                id: {
                  ...pf?.id,
                  error: pf?.id?.pattern(fields[i]?.procedureFields[pfIndex]?.id)
                    ? false
                    : true,
                  skipped: true,
                },
                label: {
                  ...pf?.label,
                  error: pf?.label?.pattern(
                    fields[i]?.procedureFields[pfIndex]?.label
                  )
                    ? false
                    : true,
                  skipped: true,
                },
                fieldType: {
                  ...pf?.fieldType,
                  error: pf?.fieldType?.pattern(
                    fields[i]?.procedureFields[pfIndex]?.fieldType
                  )
                    ? false
                    : true,
                  skipped: true,
                },
                options: pf?.options?.map((option, optionIndex) => {
                  return {
                    ...option,
                    error: option?.pattern(
                      fields[i]?.procedureFields[pfIndex]?.options[optionIndex]
                        ?.item,
                      fields[i]?.procedureFields[pfIndex]?.fieldType
                    )
                      ? false
                      : true,
                    skipped: true,
                  };
                }),
              };
            }
          ),
        };
      } else {
        currentValidation = {
          ...itemValidations[i],
          procedureFields: itemValidations[i].procedureFields.map(
            (pf, pfIndex) => {
              return {
                ...pf,
                id: {
                  ...pf.id,
                  error: pf.id.pattern(fields[i].procedureFields[pfIndex].id)
                    ? false
                    : true,
                  skipped: true,
                },
                label: {
                  ...pf.label,
                  error: pf.label.pattern(
                    fields[i].procedureFields[pfIndex].label
                  )
                    ? false
                    : true,
                  skipped: true,
                },
                fieldType: {
                  ...pf.fieldType,
                  error: pf.fieldType.pattern(
                    fields[i].procedureFields[pfIndex].fieldType
                  )
                    ? false
                    : true,
                  skipped: true,
                },
                options: pf?.options?.map((option, optionIndex) => {
                  return {
                    ...option,
                    error: option?.pattern(
                      fields[i]?.procedureFields[pfIndex]?.options[optionIndex]?.item,
                      fields[i]?.procedureFields[pfIndex]?.fieldType
                    )
                      ? false
                      : true,
                    skipped: true,
                  };
                }),
              };
            }
          ),
        };
      }

      latestValidation.push(currentValidation);

      // Check for errors in the current validation
      const validationToArray = Object.entries(currentValidation);
      const errorsInData = validationToArray.some(([key, value]) => {
        if (value.error) {
          return true;
        } else if (key === 'procedureFields') {
       
          return value.some((pf) => {
            return Object.values(pf).some((field) => {
              if (Array.isArray(field)) {
                return field.some((option) => option.error);
              }
             
              return field.error;
            });
          });
        } else if (Array.isArray(value)) {
          return value.some((option) => option.error);
        }
        return false;
      });

      if (!error) {
        error = errorsInData;
      }
    }

    setValidationProcedureItems(latestValidation);

    return error;
  };

  const updateProcedureHandler = async () => {
    let errorsInData = validationHandler();

    let errorInProcedureItem = procedureItemValidationHandler();

    if (!errorsInData && !errorInProcedureItem) {
      let newFieldsData = [...fields];

      let allFields = newFieldsData.filter(
        (field) =>
          field.procedureFields.length > 0 || field.label !== 'outer_header'
      );

      let headerFields = allFields?.filter(
        (field) => field.label !== 'outer_header'
      );
      let outerFields = allFields?.filter(
        (field) => field.label === 'outer_header'
      );

      let finalFields = [];
      outerFields.map((field) =>
        field?.procedureFields.map((procedure) => finalFields?.push(procedure))
      );

      const new_fields = [];
      const header_fields = [];

      if (finalFields?.length > 0) {
        finalFields.map(({ id, prevAttachments, options, ...rest }) => {
          const g_options = options.map((option) => {
            return {
              item: option.item,
            };
          });
          if (rest.label) {
            if (rest.fieldType === 'inspectionCheck') {
              rest.logics = [];
              if (rest.logicTrigger && rest.logicTrigger.length) {
                rest.logicTrigger.map((one) => {
                  rest.logics.push({
                    answer: rest.logicAnswer || '',
                    trigger: one,
                    description:
                      one === 'action'
                        ? rest.actionDetails || ''
                        : rest.evidenceDetails || '',
                  });
                });
              }
            }
            new_fields.push({ ...rest, options: g_options });
          }
        });
      }
      if (headerFields?.length > 0) {
        headerFields.forEach((headerField) => {
          const { procedureFields, id, ...headerRest } = headerField;

          const fields = procedureFields.map(
            ({ id, prevAttachments, options, ...rest }) => {
              const g_options = options.map((option) => {
                return {
                  item: option.item,
                };
              });
              if (rest.label) {
                if (rest.fieldType === 'inspectionCheck') {
                  rest.logics = [];
                  if (rest.logicTrigger && rest.logicTrigger.length) {
                    rest.logicTrigger.forEach((one) => {
                      rest.logics.push({
                        answer: rest.logicAnswer || '',
                        trigger: one,
                        description:
                          one === 'action'
                            ? rest.actionDetails || ''
                            : rest.evidenceDetails || '',
                      });
                    });
                  }
                }
                return { ...rest, options: g_options };
              }
              return rest; // Make sure to return rest if no changes needed
            }
          );

          header_fields.push({ ...headerRest, fields }); // Renaming procedureFields to fields
        });
      }

      if (new_fields?.length == 0 && header_fields?.length == 0) {
        return toast.error(t.procedures.length_valid);
      }
      setButtonStatus(1);
      // console.log("new_fields", new_fields);
      const response = await updateProcedure(
        Number(targetedData?.id),
        String(data.title),
        String(data.description),
        String(data.category),
        new_fields,
        header_fields
      );

      if (response.status == 200) {
        resetForm();
        toast.success(t.procedures.form.update_success_statement);
        setFilterOptions({
          category: [],
          createdByUser: [],
        });
        setSearchTitle('');
        setSelectedDates([]);
        setSortData([]);
        history.push('/procedures');
        setButtonStatus(0);
        setModalWidth(Math.max(window.innerWidth / 2, 514));
        untoggle();
      
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
          // untoggle();
          setButtonStatus(0);
        } else {
          toast.error(t.common.something_went);
          untoggle();
          resetForm();
          setButtonStatus(0);
        }
      }
      setButtonStatus(0);
    } else {
      // toast.error(
      //   'Some required fields are missing values. Please check before submitting.'
      // );
      scrollToError();
    }
  };

  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);
    setInitChange(true);
  };

  const onFilesChange = (e) => {
    let newFiles = [];
    Array.from(e.target.files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    if (newFiles.length > 0) {
      //console.log('activeIndex', activeIndex);
      //console.log('newFiles', newFiles);
      let { isheader, headerIndex, fieldIndex } = activeIndex;
      uploadAttachmentsHandler(newFiles).then((response) => {
        let newfields = [...fields];

        if (isheader) {
          let item = { ...fields[headerIndex] };
          const prevAttachments_obj = response.data.map((file) => {
            return {
              id: file.id,
              mime: file.mime,
              name: file.name,
              url: file.url,
            };
          });

          const undefined_prev_removed = prevAttachments_obj
            .concat(item.prevAttachments)
            .filter((el) => el);
          item.prevAttachments = undefined_prev_removed;

          const attachment_ids = response.data.map((file) => file.id);
          const undefined_attachments_removed = attachment_ids
            .concat(item.attachments)
            .filter((el) => el);
          item.attachments = undefined_attachments_removed;

          newfields[headerIndex] = item;

          // //console.log(newfields[activeIndex]);
          // 5. Set the state to our new copy
          setFields(newfields);
        } else {
          let headerItem = { ...fields[headerIndex] };
          let procedureItem = { ...headerItem.procedureFields[fieldIndex] };

          const prevAttachments_obj = response.data.map((file) => {
            return {
              id: file.id,
              mime: file.mime,
              name: file.name,
              url: file.url,
            };
          });

          const undefined_prev_removed = prevAttachments_obj
            .concat(procedureItem.prevAttachments)
            .filter((el) => el);
          procedureItem.prevAttachments = undefined_prev_removed;

          const attachment_ids = response.data.map((file) => file.id);
          const undefined_attachments_removed = attachment_ids
            .concat(procedureItem.attachments)
            .filter((el) => el);
          procedureItem.attachments = undefined_attachments_removed;

          headerItem.procedureFields[fieldIndex] = procedureItem;
          newfields[headerIndex] = headerItem;

          // 5. Set the state to our new copy
          setFields(newfields);
        }
        // 2. Make a shallow copy of the item you want to mutate
      });
    }
  };

  const uploadAttachmentsHandler = async (files) => {
    let theFiles = files.filter((el) => el.isChanged).map((el) => el.file);

    // console.log(theFiles);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const removeThisFileFromField = async (the_ID) => {
    setDeletingFile(true);
    const response = await removeFile(the_ID);

    if (response.status == 200) {
      let newfields = [...fields];
      // 2. Make a shallow copy of the item you want to mutate
      let item = { ...fields[activeIndex] };

      const filteredPrevAttachments = item.prevAttachments.filter(
        (file) => file.id !== the_ID
      );
      item.prevAttachments = filteredPrevAttachments;

      const filteredAttachments = item.attachments.filter(
        (file) => file !== the_ID
      );
      // console.log('filteredAttachments', filteredAttachments);

      item.attachments = filteredAttachments;

      item.active = true;

      newfields[activeIndex] = item;

      // console.log(newfields[id]);
      // 5. Set the state to our new copy
      setFields(newfields);
      setDeletingFile(false);
    }
  };

  const cloneTemplate = () => {
    toggleCloneModal(true);
  };

  const handleSetDataByFilter = (newData) => {
    setInitCloneList(newData);
  };

  const cloneThisTemplate = (proc_id, targetedData) => {
    setCloningTemplate(true);
    setSelectedTemplateID(proc_id);
    let newFieldValidations = [];
    if (targetedData?.attributes.headers?.length > 0) {
      const regenerate_fields = targetedData?.attributes.headers.map(
        (header) => {
          const headerLabel = header?.label; // Assuming you need the label for the header

          const headerFields = header.fields.map((field) => {
            const prevAttachments_obj = field.attachments?.data
              ? field.attachments.data.map((file) => ({
                  id: file.id,
                  mime: file.attributes.mime,
                  name: file.attributes.name,
                  url: file.attributes.url,
                }))
              : [];

            const attachments_obj = field.attachments?.data
              ? field.attachments.data.map((file) => file.id)
              : [];

            if (field.fieldType === 'inspectionCheck') {
              // Handling inspectionCheck type fields

              const modifiedField = {
                ...field,
                key: nanoid(),
                logicAnswer: field.failedValue ? field.failedValue : '',
                logicTrigger:
                  field.logics && field.logics.length
                    ? field.logics.map((one) => one.trigger)
                    : null,
                actionDetails:
                  field.logics &&
                  field.logics.find((one) => one.trigger === 'action')
                    ? field.logics.find((one) => one.trigger === 'action')
                        .description || ''
                    : '',
                evidenceDetails:
                  field.logics &&
                  field.logics.find((one) => one.trigger === 'evidence')
                    ? field.logics.find((one) => one.trigger === 'evidence')
                        .description || ''
                    : '',
              };

              delete modifiedField.logics; // Remove logics property

              return {
                ...modifiedField,
                attachments: attachments_obj,
                prevAttachments: prevAttachments_obj,
              };
            } else {
              // Default handling for other field types (assuming checklist in this example)
              return {
                id: Math.floor(Math.random() * 90) + 10,
                key: nanoid(),
                label: field?.label, // Adjust label as needed
                fieldType: field.fieldType,
                isRequired: field.isRequired || false,
                active: true, // Assuming default active status
                logic: field.logic || false,
                attachments: attachments_obj,
                prevAttachments: prevAttachments_obj,
                options: field.options || [{ item: '' }],
              };
            }
          });

          // Return the header structure with mapped fields
          return {
            id: Math.floor(Math.random() * 90) + 10,
            key: nanoid(),
            label: `${headerLabel}`, // Assuming you need a label for the header
            fieldType: 'heading',
            isRequired: false, // Adjust as needed
            active: true, // Assuming default active status
            logic: false, // Assuming headers don't have logic
            attachments: [],
            prevAttachments: [],
            options: [{ item: '' }], // Adjust options structure if needed
            procedureFields: headerFields, // Assign the mapped fields to procedureFields
          };
        }
      );

      // Assuming setFields and setValidationProcedureItems are functions to update state
      setFields((prevFields) => [...prevFields, ...regenerate_fields]);

      //   const itemValidationArray = regenerate_fields.map((header) => [
      //     {
      //       id: {
      //         error: false,
      //         pattern: (id) => id >= 0,
      //         skipped: false,
      //         message: t.common.required_field,
      //       },
      //       label: {
      //         error: true,
      //         pattern: (label) => label !== '',
      //         skipped: false,
      //         message: t.common.required_field,
      //       },
      //       fieldType: {
      //         error: false,
      //         pattern: (fieldType) => fieldType !== '',
      //         skipped: false,
      //         message: t.common.required_field,
      //       },
      //       options: header.options
      //         ? header.options.map((option) => ({
      //             error: false,
      //             pattern: (value, fieldType) =>
      //               ['multipleChoice', 'checklist'].includes(fieldType)
      //                 ? value !== ''
      //                 : true,
      //             skipped: false,
      //             message: t.common.required_field,
      //           }))
      //         : [],
      //     },
      //     ...header.procedureFields.map(() => ({
      //       procedureFields: [
      //         {
      //           id: {
      //             error: false,
      //             pattern: (id) => id >= 0,
      //             skipped: false,
      //             message: t.common.required_field,
      //           },
      //           label: {
      //             error: true,
      //             pattern: (label) => label !== '',
      //             skipped: false,
      //             message: t.common.required_field,
      //           },
      //           fieldType: {
      //             error: false,
      //             pattern: (fieldType) => fieldType !== '',
      //             skipped: false,
      //             message: t.common.required_field,
      //           },
      //           options: header.options
      //             ? header.options.map((option) => ({
      //                 error: false,
      //                 pattern: (value, fieldType) =>
      //                   ['multipleChoice', 'checklist'].includes(fieldType)
      //                     ? value !== ''
      //                     : true,
      //                 skipped: false,
      //                 message: t.common.required_field,
      //               }))
      //             : [],
      //         },
      //       ],
      //     })),
      //   ]);

      const itemValidationArray = regenerate_fields.map((header) => ({
        id: {
          error: false,
          pattern: (id) => id >= 0,
          skipped: false,
          message: t.common.required_field,
        },
        label: {
          error: true,
          pattern: (label) => label !== '' && label.length <= 500,
          skipped: false,
          message: t.common.required_field,
          messageData: (label) => {
            if (label === '') {
              return t.common.required_field;
            } else if (label.length > 500) {
              return t.common.characters_field;
            }
            return t.common.required_field;
          },
        },
        fieldType: {
          error: false,
          pattern: (fieldType) => fieldType !== '',
          skipped: false,
          message: t.common.required_field,
        },
        options: header.options
          ? header.options.map((option) => ({
              error: false,
              pattern: (value, fieldType) =>
                ['multipleChoice', 'checklist','checkbox'].includes(fieldType)
                  ? value !== ''&& value.length <= 500
                  : true,
              skipped: false,
              message: t.common.required_field,
              messageData: (label) => {
                if (label === '') {
                  return t.common.required_field;
                } else if (label.length > 500) {
                  return t.common.characters_field;
                }
                return t.common.required_field;
              },
            }))
          : [],
        procedureFields: header.procedureFields.map(() => ({
          id: {
            error: false,
            pattern: (id) => id >= 0,
            skipped: false,
            message: t.common.required_field,
          },
          label: {
            error: true,
            pattern: (label) => label !== '' && label.length <= 500,
            skipped: false,
            message: t.common.required_field,
            messageData: (label) => {
              if (label === '') {
                return t.common.required_field;
              } else if (label.length > 500) {
                return t.common.characters_field;
              }
              return t.common.required_field;
            },
          },
          fieldType: {
            error: false,
            pattern: (fieldType) => fieldType !== '',
            skipped: false,
            message: t.common.required_field,
          },
          options: header.options
            ? header.options.map((option) => ({
                error: false,
                pattern: (value, fieldType) =>
                  ['multipleChoice', 'checklist','checkbox'].includes(fieldType)
                    ? value !== ''&& value.length <= 500
                    : true,
                skipped: false,
                message: t.common.required_field,
                messageData: (label) => {
                  if (label === '') {
                    return t.common.required_field;
                  } else if (label.length > 500) {
                    return t.common.characters_field;
                  }
                  return t.common.required_field;
                },
              }))
            : [],
        })),
      }));

      // Now `transformedValidations` contains the desired structur

      setValidationProcedureItems((prevFields) => [...prevFields, ...itemValidationArray]);
      // setValidationProcedureItems([...validationProcedureItems,itemValidationArray]);
    }

    if (targetedData?.attributes.fields.length > 0) {
      const regenerate_fields = targetedData?.attributes.fields.map((field) => {
        const prevAttachments_obj = field?.attachments?.data
          ? field?.attachments?.data?.map((file) => {
              return {
                id: file.id,
                mime: file.attributes.mime,
                name: file.attributes.name,
                url: file.attributes.url,
              };
            })
          : [];

        const attachments_obj = field?.attachments?.data
          ? field?.attachments?.data.map((file) => file.id)
          : [];
        if (field?.fieldType == 'inspectionCheck') {
          const modifiedField = {
            ...field,
            key: nanoid(),

            logicAnswer: field.failedValue ? field.failedValue : '',
            logicTrigger:
              field?.logics && field?.logics?.length
                ? field?.logics?.map((one) => one.trigger)
                : null,
            actionDetails:
              field?.logics &&
              field?.logics?.find((one) => one?.trigger == 'action')
                ? field?.logics?.find((one) => one?.trigger == 'action')
                    ?.description || ''
                : '',
            evidenceDetails:
              field?.logics &&
              field?.logics?.find((one) => one.trigger == 'evidence')
                ? field.logics.find((one) => one.trigger == 'evidence')
                    ?.description || ''
                : '',
          };

          // Delete the logics property from the modified object
          delete modifiedField.logics;

          // Use the modified object instead of modifying the original field object
          return {
            id: field?.id,
            key: nanoid(),
            label: 'outer_header',
            fieldType: 'heading',
            isRequired: false,
            active: true,
            logic: false,
            attachments: [],
            prevAttachments: [],
            options: [
              {
                item: '',
              },
            ],
            procedureFields: [
              {
                ...modifiedField,
                attachments: attachments_obj,
                prevAttachments: prevAttachments_obj,
              },
            ],
          };
        }
        return {
          id: field?.id,
          key: nanoid(),
          label: 'outer_header',
          fieldType: 'heading',
          isRequired: false,
          active: true,
          logic: false,
          attachments: [],
          prevAttachments: [],
          options: [
            {
              item: '',
            },
          ],
          procedureFields: [
            {
              ...field,
              key: nanoid(),
              attachments: attachments_obj,
              prevAttachments: prevAttachments_obj,
            },
          ],
        };
      });

      //   regenerate_fields.map((field)=>(handleIncomingData(field)))

      setFields((prevFields) => [...prevFields, ...regenerate_fields]);
      const itemValidationOuterArray = regenerate_fields.map((field) => {
        // Initialize validation object for each field
        const validationObj = {
          procedureFields: [
            {
              id: {
                error: false,
                pattern: (id) => id >= 0,
                skipped: false,
                message: t.common.required_field,
              },
              label: {
                error: true,
                pattern: (label) => label !== '' && label.length <= 500,
                skipped: false,
                message: t.common.required_field,
                messageData: (label) => {
                  if (label === '') {
                    return t.common.required_field;
                  } else if (label.length > 500) {
                    return t.common.characters_field;
                  }
                  return t.common.required_field;
                },
              },
              fieldType: {
                error: false,
                pattern: (fieldType) => fieldType !== '',
                skipped: false,
                message: t.common.required_field,
              },
              options: field.options
                ? field.options.map((option) => ({
                    error: false,
                    pattern: (value, fieldType) =>
                      ['multipleChoice', 'checklist','checkbox'].includes(fieldType)
                        ? value !== ''&& value.length <= 500
                        : true,
                    skipped: false,
                    message: t.common.required_field,
                    messageData: (label) => {
                      if (label === '') {
                        return t.common.required_field;
                      } else if (label.length > 500) {
                        return t.common.characters_field;
                      }
                      return t.common.required_field;
                    },
                  }))
                : [],
            },
          ],
        };

        // Now add any additional validations or properties specific to the field if needed

        return validationObj;
      });

      setValidationProcedureItems((prevFields) => [
        ...prevFields,
        ...itemValidationOuterArray,
      ]);
      // Now set the state with the validation array
    }
    // const regenerate_fields = proc?.attributes.fields.map((field) => {
    //   const prevAttachments_obj = field?.attachments?.data?.map((file) => {
    //     return {
    //       id: file.id,
    //       mime: file.attributes.mime,
    //       name: file.attributes.name,
    //       url: file.attributes.url,
    //     };
    //   });

    //   const attachments_obj = field?.attachments?.data?.map((file) => file.id);
    //   newFieldValidations.push({
    //     id: {
    //       error: false,
    //       pattern: (id) => id >= 0,
    //       skipped: false,
    //       message: t.common.required_field,
    //     },
    //     label: {
    //       error: true,
    //       pattern: (label) => label != '',
    //       skipped: false,
    //       message: t.common.required_field,
    //     },
    //     fieldType: {
    //       error: false,
    //       pattern: (fieldType) => fieldType != '',
    //       skipped: false,
    //       message: t.common.required_field,
    //     },
    //     options: field.options
    //       ? field.options.map((one) => {
    //           return {
    //             error: false,
    //             pattern: (value, fieldType) =>
    //               ['multipleChoice', 'checklist'].includes(fieldType)
    //                 ? value != ''
    //                 : true,
    //             skipped: false,
    //             message: t.common.required_field,
    //           };
    //         })
    //       : [],
    //   });

    //   if (field?.fieldType == 'inspectionCheck') {
    //     const modifiedField = {
    //       ...field,
    //       logicAnswer:
    //         field?.logics && field?.logics?.length
    //           ? field?.logics[0]?.answer
    //           : '',
    //       logicTrigger:
    //         field?.logics && field?.logics?.length
    //           ? field?.logics?.map((one) => one.trigger)
    //           : null,
    //       actionDetails:
    //         field?.logics &&
    //         field?.logics?.find((one) => one?.trigger == 'action')
    //           ? field?.logics?.find((one) => one?.trigger == 'action')
    //               ?.description || ''
    //           : '',
    //       evidenceDetails:
    //         field?.logics &&
    //         field?.logics?.find((one) => one.trigger == 'evidence')
    //           ? field.logics.find((one) => one.trigger == 'evidence')
    //               ?.description || ''
    //           : '',
    //     };

    //     // Delete the logics property from the modified object
    //     delete modifiedField.logics;

    //     // Use the modified object instead of modifying the original field object
    //     return {
    //       ...modifiedField,
    //       attachments: attachments_obj,
    //       prevAttachments: prevAttachments_obj,
    //     };
    //   }

    //   return {
    //     ...field,
    //     attachments: attachments_obj,
    //     prevAttachments: prevAttachments_obj,
    //   };
    // });

    // setFields([...fields, ...regenerate_fields]);
    // setValidationProcedureItems([
    //   ...validationProcedureItems,
    //   ...newFieldValidations,
    // ]);
  };

  useEffect(() => {
    setCloningTemplate(false);
    setSelectedTemplateID(null);
    toggleCloneModal(false);
    setInitChange(true);
  }, [fields]);
  // console.log('Whole', fields);
  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? 'aside-modal procedure dynamic-modal kush active add-procedure-container'
            : 'aside-modal procedure add-procedure-container'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.procedures.form.form_title.edit} #{targetedData?.attributes?.countId }
              </div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div  style={{
              height: "90vh",
              overflowX: "hidden",
              overflowY: "scroll",
            }} className="body">
          {buttonStatus == 1 ? (
                  <Dimmer active inverted>
                   <Spinner className="m-5" color="primary">
                {null}
              </Spinner>
                  </Dimmer>
                ) : (
                  ''
                )}
            <Form.Field>
              <label className="label-item">
                {t.procedures.form.procedure_title}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.procedures.form.add_procedure_title}
                value={data.title}
                onChange={onValuesChange('title')}
                error={validation.title.error && validation.title.skipped}
                onBlur={(e) => {
                  if (validation.title.pattern(data.title)) {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.title.error && validation.title.skipped ? (
                <div className="label-error">{validation.title.message}</div>
              ) : (
                ''
              )}
            </Form.Field>
            <Form style={{ marginBottom: '20px' }}>
              <Form.Field>
                <label className="label-item">
                  {t.procedures.form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange('description')}
                  rows={2}
                  fluid
                  placeholder={t.procedures.form.add_description}
                  onBlur={(e) => {
                    if (validation.description.pattern(data.description)) {
                      setValidation({
                        ...validation,
                        description: {
                          ...validation.description,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        description: {
                          ...validation.description,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.description.error && validation.description.skipped ? (
                <div className="label-error">{validation.description.message}</div>
              ) : (
                ''
              )}
              </Form.Field>
            </Form>

            <Form.Field>
              <label className="label-item">
                {t.procedures.details_view.category}
                <Asterisk color="#172B4D" />
              </label>

              <Dropdown
                placeholder={t.procedures.details_view.add_category}
                noResultsMessage={t.common.no_results_found}
                fluid
                clearable
                selection
                options={options.categories}
                value={data.category}
                onChange={onValuesChange('category')}
                error={validation.category.error && validation.category.skipped}
                onBlur={(e) => {
                  if (validation.category.pattern(data.category)) {
                    setValidation({
                      ...validation,
                      category: {
                        ...validation.category,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      category: {
                        ...validation.category,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.category.error && validation.category.skipped ? (
                <div className="label-error">{validation.category.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            {generatedProcedureFields(
              fields,
              setFields,
              validationProcedureItems,
              setValidationProcedureItems,
              attachmentsRef,
              setActiveIndex
            )}
            <div className="procedure-item-add-button-container">
              <div className="item-button-group" onClick={() => addItem(false)}>
                <span className="item-button-icon-container">
                  <AddIcon />
                </span>
                <div className="item-add-option-text">
                  {t.procedures.form.add_procedure_item}
                </div>
              </div>
              <div className="item-button-group" onClick={() => addItem(true)}>
                <span className="item-button-icon-container">
                  <HeadingIcon width="20px" height="20px" fill={'#0C66E4'} />
                </span>
                <div className="item-add-option-text">
                  {t.procedures.form.header}
                </div>
              </div>
              <div
                className="item-button-group"
                onClick={() => cloneTemplate()}
              >
                <span className="item-button-icon-container">
                  <CopyIcon width="20px" height="20px" />
                </span>
                <div className="item-add-option-text">
                  {t.procedures.form.clone_template}
                </div>
              </div>
            </div>

            <div className="form-button-container">
              <button
                onClick={() =>
                  initChange ? setNewCancelModal(true) : cancelHandler()
                }
                className="form-action-button"
              >
                {t.common.cancel}
              </button>

              <button
                className="form-action-button blue"
                onClick={updateProcedureHandler}
                disabled={buttonStatus == 1}
                style={{ minWidth: '50px' }}
              >
                 {buttonStatus == 1 ? (
                  <Spinner color="light" size="sm">
                    <div>{null}</div>
                  </Spinner>
                ) : (
                  t.common.edit
                )}
                
              </button>

              <input
                ref={attachmentsRef}
                type="file"
                accept="application/pdf, image/*"
                hidden
                multiple
                onChange={onFilesChange}
              />
            </div>
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* success modal */}
      <SuccessModal
        closeCallback={() => {
          setFilterOptions({
            category: [],
            createdByUser: [],
          });
          setSearchTitle('');
          setSelectedDates([]);
          setSortData([]);
          history.push('/procedures');
        }}
      >
        <span>{t.procedures.form.update_success_statement}</span>
      </SuccessModal>

      {/* Clone Procedure */}
      <Modal
        size="tiny"
        open={cloneModal}
        className="procedure-modal"
        onClose={() => toggleCloneModal(false)}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.procedures.form.select_local_statement}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                toggleCloneModal(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <Modal.Content scrolling style={{ minHeight: '600px' }}>
          <FilterCloneList
            data={initCloneList}
            setDataByFilter={handleSetDataByFilter}
          />
          <List divided relaxed>
            {initCloneList?.length > 0 ? (
              initCloneList?.map((proc) => (
                <List.Item style={{ padding: '10px 0' }} key={proc.id}>
                  <List.Content floated="right" style={{ padding: 0 }}>
                    <button
                      className="modal-form-action-button light-blue"
                      loading={selectedTemplateID == proc.id}
                      disabled={cloningTemplate}
                      primary
                      onClick={() => cloneThisTemplate(proc.id, proc)}
                    >
                      {t.common.add}
                    </button>
                  </List.Content>
                  <List.Icon
                    name="unordered list"
                    size="large"
                    verticalAlign="middle"
                  />
                  <List.Content>
                    <List.Header>{proc.attributes.title}</List.Header>
                    <List.Description>
                      {proc.attributes.fields.length}{' '}
                      {t.procedures.table.fields_small}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Icon name="search" size="huge" color="blue" />
                <p
                  style={{
                    color: '#2185d0',
                    fontSize: '14px',
                    margin: '15px 0',
                    fontWeight: '600',
                  }}
                >
                  {t.common.na}
                </p>
              </div>
            )}
          </List>
        </Modal.Content>
      </Modal>

      {/* cancel modal */}

      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: '24px' }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
    </>
  );
}

export default Edit;
