import React, { useEffect, useState } from "react";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import { Dimmer, Divider, Image, Loader } from "semantic-ui-react";
import {
  IMAGES_URL,
  getAllParentLocations,
  humanize,
} from "../../../config/functions";
import UserProfilePlaceholder from "../../../components/common/userProfilePlaceholder";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  ContactInfoIcon,
  LinkBlueIcon,
  LocationDropDownIcon,
  LocationDropDownIconBlue,
  SubLocationDropDownBlueIcon,
} from "../../../../Assets/Icons/svg";
import Moment from "react-moment";

const Details = ({
  targetedData,
  createdByUserData,
  attachmentData,
  setRefresh,
  refresh,
  untoggle,
}) => {
  const t = useSelector(selectTranslations);
  const history = useHistory();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const [fetching, setFetchLoading] = useState(true);
  const [data, setData] = useState(null);
  const [locOption, setLocOption] = useState([]);
  useEffect(() => {
    setFetchLoading(true);

    const fetchLocOption = async () => {
    
      if (
        targetedData?.attributes?.locations &&
        targetedData?.attributes?.locations?.data?.length
      ) {
        const fetchData = await getAllParentLocations(
          organisationId,
          "",
          1,
          99999,
          [],
          [],
          [],
          ["name:asc"]
        );
        let the_options = [];
        let parentOptions = [];
        let subOptions = [];

        const allIds = targetedData?.attributes?.locations?.data?.length
          ? targetedData?.attributes?.locations?.data?.map((each) => each?.id)
          : [];


        if (fetchData?.response?.length > 0 && allIds.length > 0) {
          fetchData.response.forEach((el) => {
            const subLocationCount =
              el?.attributes?.subLocations?.data?.filter((subEl) =>
                allIds.includes(subEl.id)
              ).length || 0;
            // Check if the element's ID is included in allIds
            if (allIds?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                countId: el.attributes.countId,
                subLocationCount: subLocationCount,
                // image: { src: location_icon },
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      countId: subEl.attributes.countId,
                      // image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => allIds?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  subLocationCount,
                  text: el.attributes.name,
                  countId: el.attributes.countId,
                  // image: { src: location_icon },
                  disabled: !(
                    roleData === "super_admin" || roleData === "admin"
                  )
                    ? true
                    : false, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (allIds?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        countId: subEl.attributes.countId,
                        // image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }

            // else {
            //   // Add parent option
            //   parentOptions.push({
            //     key: el.id,
            //     value: el.id,
            //     text: el.attributes.name,
            //     image: { src: location_icon },
            //   });

            //   // Collect sub-options
            //   if (el?.attributes?.subLocations?.data?.length > 0) {
            //     el.attributes.subLocations.data.forEach((subEl) => {
            //       subOptions.push({
            //         key: subEl.id,
            //         value: subEl.id,
            //         text: subEl.attributes.name,
            //         image: { src: sub_location_icon },
            //         className: "sub-location-dropdown-option",
            //         parentId: el.id, // Keep track of the parent
            //       });
            //     });
            //   }
            // }
          });

          // Sort sub-options alphabetically
          subOptions.sort((a, b) => a.text.localeCompare(b.text));

          // Merge parent options with sorted sub-options
          parentOptions.forEach((parentOption) => {
            the_options.push(parentOption);
            // Add sorted sub-options belonging to this parent
            subOptions.forEach((subOption) => {
              if (subOption.parentId === parentOption.key) {
                the_options.push(subOption);
              }
            });
          });

          // Update the options state
          setLocOption(the_options);

          return the_options;
        }
      }
    };

    const loadData = async () => {
      await fetchLocOption(); // Wait for fetchLocOption to complete
      setFetchLoading(false); // Set loading state to false after both calls
    };
    loadData();
    if (targetedData) {
      setData(targetedData);
      // setFetchLoading(false);
    }
  }, [targetedData]);

  return (
    <div className="preview-details-wrapper">
      {fetching ? (
     
     <Loader active inline="centered">
     {t.common.loading}
   </Loader>
       
      ) : (
        <>
          <div className="intro">
            <div className="flexbox align-center">
              <div>
                {targetedData?.attributes?.logo?.data?.attributes?.url ? (
                  <div className="d-flex align-items-center">
                    <div className="text-center">
                      <Image
                        circular
                        title={targetedData.attributes?.name}
                        src={`${IMAGES_URL}${targetedData?.attributes?.logo.data.attributes.url}`}
                        style={
                          selectedLanguage === "en"
                            ? { width: "80px", height: "80px", right: "6px" }
                            : { width: "80px", height: "80px", right: "-6px" }
                        }
                        // style={
                        //   index != 0
                        //     ? {
                        //         marginLeft: '-6px',
                        //         width: '34px',
                        //         height: '34px',
                        //       }
                        //     : { width: '34px', height: '34px' }
                        // }
                      />
                    </div>
                    <div
                      className="username-container ml-2"
                      // onClick={() => history.push(`/people?id=${row?.id}`)}
                    >
                      <p className="people-text text-start">
                        {targetedData?.attributes?.name
                          ? humanize(targetedData?.attributes?.name)
                          : ""}
                      </p>
                      {/* <p className="people-role text-left">
                        {targetedData?.role?.name
                          ? role?.text || humanize(targetedData?.role?.name)
                          : ''}
                      </p> */}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="text-center">
                      <UserProfilePlaceholder
                        name={targetedData?.attributes?.name}
                        width={"80px"}
                        height={"80px"}
                        right={selectedLanguage === "en" ? "6px" : "-6px"}
                      />
                    </div>
                    <div
                      className="username-container ml-1"
                      // onClick={() => history.push(`/people?id=${row?.id}`)}
                    >
                      <p className="people-text text-start">
                        {targetedData?.attributes?.name
                          ? humanize(targetedData?.attributes?.name)
                          : ""}
                      </p>
                      {/* <p
                        className={`people-role ${
                          selectedLanguage == 'en'
                            ? 'text-left mr-2'
                            : 'text-right ml-0'
                        }`}
                      >
                        {targetedData?.role?.name
                          ? role?.text || humanize(targetedData?.role?.name)
                          : ''}
                      </p> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="d-flex justify-content-end"> */}
            {/* <div className="d-flex align-items-center mb-3">
                <label className="workorder-intro-label customer-label ">
                  {t.common.created_by}
                  {':'}
                </label>

                <p className="workorder-intro-value">
                  {targetedData?.attributes?.createdByUser?.data?.attributes
                    ?.firstName
                    ? targetedData?.attributes?.createdByUser?.data?.attributes
                        ?.firstName +
                      ' ' +
                      targetedData?.attributes?.createdByUser?.data?.attributes
                        ?.lastName
                    : t.common.na}
                </p>
              </div> */}

            {/* </div> */}
          </div>

          <Divider />

          <div className="details-wrapper">
            <div className="d-flex align-items-start mt-6">
              <label className="workorder-intro-label customer-label ">
                {t.customers.form.description}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.description || t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label customer-label ">
                {t.customers.form.email}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.email
                  ? targetedData?.attributes?.email
                  : t.common.na}
              </p>
            </div>

            {/* <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label ">
                {t.people.personal_info_form.phone}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {data.profile.phone ? `+${data.profile.phone}` : t.common.na}
              </p>
            </div> */}

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label customer-label ">
                {t.customers.form.phone}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.phone
                  ? `${selectedLanguage == "en" ? "+" : ""}${
                      targetedData?.attributes?.phone
                    }${selectedLanguage == "ar" ? "+" : ""}`
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label customer-label ">
                {t.customers.form.location}
                {":"}
              </label>
              <p
                style={{ gap: "10px" }}
                className="workorder-intro-value d-flex text-left flex-column"
              >
                {locOption?.length > 0
                  ? locOption.map((each) => {
                      return (
                        <span
                          className={`${
                            selectedLanguage == "en"
                              ? "text-left"
                              : "text-right"
                          } `}
                          basic
                          onClick={() => {
                            if (!each?.disabled) {
                              history.push(`/locations?id=${each?.countId}`);
                            }
                          }}
                        >
                          <span
                            className={
                              each?.parentId
                                ? selectedLanguage == "en"
                                  ? "ml-5"
                                  : "mr-5"
                                : ""
                            }
                          >
                            {each?.parentId ? (
                              <SubLocationDropDownBlueIcon />
                            ) : each?.disabled ? (
                              <LocationDropDownIcon />
                            ) : (
                              <LocationDropDownIconBlue />
                            )}
                          </span>
                          <span
                            className={`${
                              selectedLanguage == "en" ? "ml-2" : "mr-2"
                            } ${each?.disabled ? "" : "work-order-link"}`}
                          >
                            {each?.text}
                          </span>{" "}
                          <span
                            style={{ color: "#0C66E4" }}
                            className="ml-2 mr-2 link-span"
                          >
                            {each?.subLocationCount &&
                            each?.subLocationCount > 0 ? (
                              <LinkBlueIcon />
                            ) : null}
                            <span
                              style={{ textDecoration: "none" }}
                              className="ml-1"
                            >
                              {each?.subLocationCount &&
                              each?.subLocationCount > 1
                                ? each?.subLocationCount
                                : ""}
                            </span>
                          </span>
                        </span>
                      );
                    })
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label ">
                {t.customers.form.website}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.website
                  ? targetedData?.attributes?.website
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label customer-label ">
                {t.customers.form.service_type}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.serviceType
                  ? targetedData?.attributes?.serviceType
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5 mb-6">
              <label className="workorder-intro-label customer-label ">
                {t.common.industry}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.Industry
                  ? targetedData?.attributes?.Industry
                  : t.common.na}
              </p>
            </div>

            <Divider />

            <div className="billing-info  d-flex mt-7">
              <ContactInfoIcon />
              <p
                style={{ fontSize: "16px" }}
                className={` required-text mt-1 ${
                  selectedLanguage == "en" ? "ml-2" : "mr-2"
                }`}
              >
                {t.customers.form.contact_information}
              </p>
            </div>

            <div className="d-flex align-items-center mt-8">
              <label className="workorder-intro-label customer-label ">
                {t.customers.contact_name}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.contactInformation?.name
                  ? targetedData?.attributes?.contactInformation?.name
                  : t.common.na}
                {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label">
                {t.customers.contact_phone}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.contactInformation?.phone
                  ? `${selectedLanguage == "en" ? "+" : ""}+${
                      targetedData?.attributes?.contactInformation?.phone
                    }${selectedLanguage == "ar" ? "+" : ""}`
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label ">
                {t.customers.contact_email}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.contactInformation?.email
                  ? targetedData?.attributes?.contactInformation?.email
                  : t.common.na}
              </p>
            </div>

            <Divider />

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label  grey">
                {t.workOrders.table.createdByUser}
                {":"}
              </label>
              <p className="workorder-intro-value grey">
                {targetedData?.attributes?.createdByUser?.data?.attributes
                  ?.firstName &&
                targetedData?.attributes?.createdByUser?.data?.attributes
                  ?.lastName
                  ? targetedData?.attributes?.createdByUser?.data?.attributes
                      ?.firstName +
                    " " +
                    targetedData?.attributes?.createdByUser?.data?.attributes
                      ?.lastName
                  : t.common.na}
                {", "}
                <Moment
                  format="DD-MM-YYYY LT"
                  date={targetedData?.attributes?.createdAt}
                />
              </p>
            </div>

            <div className="d-flex align-items-center mt-3">
              <label className="workorder-intro-label customer-label  grey">
                {t.common.update_by}
                {":"}
              </label>
              <p className="workorder-intro-value grey">
                {targetedData?.attributes?.updatedByUser?.data
                  ? targetedData?.attributes?.updatedByUser?.data?.attributes
                      ?.firstName +
                    " " +
                    targetedData?.attributes?.updatedByUser?.data?.attributes
                      ?.lastName +
                    ", " +
                    moment(targetedData?.attributes?.updatedAt).format(
                      "DD-MM-YYYY LT"
                    )
                  : t.common.na}
              </p>
            </div>

            {/* <div className="d-flex align-items-center mt-5">
        <label className="workorder-intro-label customer-label ">
        {t.people.personal_info_form.nationality}
          {':'}
        </label>
        <p className="workorder-intro-value">
        {data.profile.nationality
                      ? Country.getCountryByCode(data.profile.nationality)
                          .name
                      : t.common.na}
        </p>
      </div> */}
          </div>

          {/* <Modal
            size="tiny"
            open={updateModal}
            className="assets-modal"
            onClose={() => {
              setUpdateModal(false);
              setToggleStatus(!targetedData?.blocked);
            }}
            style={{ minHeight: '191px' }}
          >
            <div className="header-container">
              <span className="assets-header">
                {t.people.activate_deactivate_account}
              </span>
              <div>
                <span
                  className="c-pointer text-right"
                  onClick={() => {
                    setUpdateModal(false);
                    setToggleStatus(!targetedData?.blocked);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            </div>

            <Modal.Content>
              <span className="assets-label ml-1">
                {targetedData?.blocked == false
                  ? `${t.people.deactivate_statement} "${targetedData?.firstName} ${targetedData?.lastName}"`
                  : `${t.people.activate_statement} "${targetedData?.firstName} ${targetedData?.lastName}"`}
              </span>
            </Modal.Content>
            <div
              className={`modal-button-container mt-3 ${
                selectedLanguage == 'en' ? 'mr-6' : 'ml-6'
              }`}
            >
              <button
                onClick={() => {
                  setUpdateModal(false); // Close the delete modal
                  setToggleStatus(!targetedData?.blocked);
                }}
                style={{ color: 'black' }}
                className="modal-form-action-button"
              >
                {t.assets.form.cancel}
              </button>

              <button
                onClick={() => {
                  setUpdateModal(false);
                  updateStatusHandler();
                }}
                className="modal-form-action-button light-blue"
              >
                {targetedData?.blocked == false
                  ? t.assets.deactivate
                  : t.assets.activate}
              </button>
            </div>
          </Modal> */}
        </>
      )}
    </div>
  );
};

export default Details;
