import React, { useState, useEffect } from 'react';
import {
  Modal,
  Input,
  Form,
  Divider,
  Dimmer,
  Checkbox,
  Loader,
  Dropdown,
  TextArea,
  Radio,
} from 'semantic-ui-react';

import { useHistory } from 'react-router';

import * as Yup from 'yup';
import { Formik, Form as FormikForm, FieldArray } from 'formik';
import { useSelector } from 'react-redux';

import _, { orderBy } from 'lodash';
import { toast } from 'react-toastify';
import { PhoneNumberUtil } from 'google-libphonenumber';
import {
  AmountBlueIcon,
  AmountLightIcon,
  PercentageBlueIcon,
  PercentageLightIcon,
} from '../../../../Assets/Icons/svg';
import useDisplayFormattedText from '../../../../hooks/useDisplayFormattedText';
import { currencyDecimalPlaces, handleKeyDown } from '../../../config/functions';
import { selectTranslations } from '../../../config/i18n/slice';
import { number } from 'prop-types';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { Spinner } from 'reactstrap';

const phoneUtil = PhoneNumberUtil.getInstance();
const positiveNumRegex = /^[1-9]\d*$/;
const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const customStyles = {
  headCells: {
    style: {
      backgroundColor: '#E9F2FF',
    },
  },
  cells: {
    style: {
      paddingTop: '20px',
      // paddingBottom: '8px',
    },
  },
};

const FullfillForm = ({
  initialFormValues,
  fetchingLocation = false,
  initPartsData,
  partsData = [],
  initSetsData,
  buttonStatus,
  setsData = [],

  handleAddSet = () => {},

  setSetsData = () => {},
  setOpenFuifillModal = () => {},
  setInitSetsData = () => {},
  setInitPartsData = () => {},
  setPartsData = () => {},
}) => {
  const t = useSelector(selectTranslations);
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });

  const history = useHistory();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });


  let allQtyOptions = [
    {
      key: 0,
      value: 'pieces',
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: 'items',
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: 'units',
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: 'liters (L)',
      text: selectedLanguage == 'en' ? 'l' : 'لتر',
    },
    {
      key: 4,
      value: 'gallons (gal)',
      text: selectedLanguage == 'en' ? 'gal' : 'جالون',
    },
    {
      key: 5,
      value: 'cubic meters (m³)',
      text: selectedLanguage == 'en' ? 'm³' : 'م³',
    },
    {
      key: 6,
      value: 'cubic feet (ft³)',
      text: selectedLanguage == 'en' ? 'ft³' : 'ق³',
    },
    {
      key: 7,
      value: 'barrels',
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: 'kilograms (kg)',
      text: selectedLanguage == 'en' ? 'kg' : 'كجم',
    },
    {
      key: 9,
      value: 'pounds (lb)',
      text: selectedLanguage == 'en' ? 'lb' : 'رطل',
    },

    {
      key: 10,
      value: 'tons',
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: 'metric tonnes',
      text: t.parts.mass_unit.m_tons,
    },
  ];

  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();

/*
 * The function `handleDecimalPoints` formats a numerical value to a specified number of decimal places and removes trailing zeros after the decimal point.
 */
  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);
    
    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf('.') !== -1) {
      return formattedValue.replace(/\.?0+$/, '');
    }
  
    return Number(formattedValue);
  };

/*
 * The HandleUnitType function takes in data, finds the corresponding unit type from allQtyOptions based on the value in data, and returns the text of the unit type if data is truthy.
 */
  const HandleUnitType = (data) => {

    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }

    return data ? unitType?.text : null;
  };

  const [emergencyPhone, setEmergencyPhone] = useState(false);
  const PersonValidationSchema = Yup.object().shape({
    orderItems: Yup.object().shape({
      items: Yup.array().of(
        Yup.object().shape({
          parts_id: Yup.string(),
          quantity: Yup.number(),
          receivedQty: Yup.number()
            .nullable()
            .min(0, t.common.quantity_must_be_greater_than_zero)
            .required(t.common.required_field),
          unitCost: Yup.number()
            .nullable()
            .min(0, t.common.quantity_must_be_greater_than_zero)
            .required(t.common.required_field),
          totalCost: Yup.string(),
        })
      ),

      taxesInPercentage: Yup.boolean(), // Assuming it's a boolean
      taxes: Yup.number()
        .nullable()
        .min(0)
        .test(
          'non-negative',
          'taxes must be non-negative',
          (value) => value >= 0
        ),
      shippingCostInPercentage: Yup.boolean(), // Assuming it's a boolean
      shippingCost: Yup.number()
        .nullable()
        .min(0)
        .test(
          'non-negative',
          'shippingCostmust be non-negative',
          (value) => value >= 0
        ),
      otherCostInPercentage: Yup.boolean(), // Assuming it's a boolean
      otherCost: Yup.number()
        .nullable()
        .min(0)
        .test(
          'non-negative',
          'otherCost must be non-negative',
          (value) => value >= 0
        ),
    }),
  });

  ////////////////////////////////////////
  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };
  const { DisplayFormattedText } = useDisplayFormattedText();

  const [addPartModal, setAddPartModal] = useState(false);
  const [noPartModal, setNoPartModal] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [addSetModal, setAddSetModal] = useState(false);
  //   const [partsData, setPartsData] = useState([]);
  //   const [initPartsData, setInitPartsData] = useState([]);
  //   const [initSetsData, setInitSetsData] = useState([]);

  //   const [setsData, setSetsData] = useState([]);
  const [searchSetTitle, setSearchSetTitle] = useState('');

  let _newData = initPartsData;
  useEffect(() => {
    if (searchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
      const isMatch = (result) => re.test(result?.name);
      _newData =
        searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    }
    if (_newData?.length > 0) {
      setPartsData(_newData);
    } else {
      setPartsData([]);
    }
  }, [searchTitle]);

  let _newDataSets = initSetsData;
  useEffect(() => {
    if (searchSetTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(searchSetTitle), 'i');
      const isMatch = (result) => re.test(result?.name);
      _newDataSets =
        searchSetTitle.length > 0
          ? _.filter(_newDataSets, isMatch)
          : _newDataSets;
    }
    if (_newDataSets?.length > 0) {
      setSetsData(_newDataSets);
    } else {
      setSetsData([]);
    }
  }, [searchSetTitle]);

  // add popups

  const handleAddPartData = async () => {
    setAddPartModal(false);
  };

  const handleAddSetData = async () => {
    setAddSetModal(false);
  };

/*
 * The function `handleRowSelected` manages the selection of rows in a table, updating data and calculating costs accordingly.
 */
  const handleRowSelected = (
    row,
    checked,
    setFieldValue,
    selectedPartsData = []
  ) => {
    let allSelectedRows = [...selectedPartsData];
    const updatedData = [...partsData];
    const updatedInitData = [...initPartsData];

    let updateRowData = row;

    if (checked) {
      allSelectedRows.unshift({ ...row, selectedPart: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);

      updateRowData.fillMax = false;
      updateRowData.quantity = 1;
      updateRowData.totalCost = updateRowData.unitCost;

      const rowchnages = updatedData.findIndex((el) => el.id === row.id);

      updatedData[rowchnages] = updateRowData;

      const rowInitchnages = updatedInitData.findIndex(
        (el) => el.id === row.id
      );

      updatedInitData[rowInitchnages] = updatedInitData;
    }

    const selectedRowIds = allSelectedRows.map((row) => row.id);
    // Move selected rows to the top
    selectedRowIds.forEach((selectedRowId, index) => {
      const selectedIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedIndex !== -1 && selectedIndex !== index) {
        const [selectedRow] = updatedData.splice(selectedIndex, 1); // Remove selected row
        updatedData.splice(index, 0, selectedRow); // Insert selected row at the top
      }

      const selectedPartIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedPartIndex !== -1) {
        updatedData[selectedPartIndex].selectedPart = true; // Update selected
        // Update selected
      }

      const selectedInitIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitIndex !== -1 && selectedInitIndex !== index) {
        const [selectedRow] = updatedInitData.splice(selectedInitIndex, 1); // Remove selected row
        updatedInitData.splice(index, 0, selectedRow); // Insert selected row at the top
      }
      const selectedInitPartIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitPartIndex !== -1) {
        updatedInitData[selectedInitPartIndex].selectedPart = true; // Update selected // Update selected
      }
    });

    let subTotalCost = 0;
    allSelectedRows.forEach((row) => {
      subTotalCost += Number(row.totalCost);
    });

    let subTotalCostData = handleDecimalPoints(Number(subTotalCost));

    // Update the selected row in allSelectedRows

    // Update state with the modified data

    // Update state with the modified data
    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setFieldValue('orderItems.items', allSelectedRows);
    setFieldValue(
      'orderItems.subTotal',
      Number(parseFloat(subTotalCostData).toFixed(2)),
      false
    );
    setFieldValue(
      'orderItems.totalCost',
      Number(parseFloat(subTotalCostData).toFixed(2)),
      false
    );
    // setFieldValue('orderItems.taxes', null);
    // setFieldValue('orderItems.shippingCost', null);
    // setFieldValue('orderItems.taxAmt', null);
    // setFieldValue('orderItems.otherAmt', null);
    // setFieldValue('orderItems.shipAmt', null);
    // setFieldValue('orderItems.otherCost', null);
  };

/*
 * The function `handleQtyChange` updates selected rows data and calculates total costs based on quantity changes and other factors.
 */
  const handleQtyChange = (
    row,
    checked,
    setFieldValue,
    selectedPartsData = [],
    index = null,
    setFieldTouched,
    values,
    unitChange = false
  ) => {
    let allSelectedRowsData = Array.isArray(selectedPartsData)
      ? [...selectedPartsData]
      : [];

    if (allSelectedRowsData?.length > 0) {
      const rowIndexSelected = allSelectedRowsData.findIndex(
        (el) => el?.id === row?.id
      );
      const updatedSelectedRow = { ...allSelectedRowsData[rowIndexSelected] };

      if (checked) {
        if (unitChange) {
          updatedSelectedRow.unitCost = Number(checked);
          updatedSelectedRow.totalCost =
            Number(checked) * Number(updatedSelectedRow.receivedQty);
        } else {
          updatedSelectedRow.receivedQty = Number(checked);
          updatedSelectedRow.totalCost =
            Number(checked) * Number(updatedSelectedRow.unitCost);
        }

        // if (updatedSelectedRow.availableQty == Number(checked)) {
        //   updatedSelectedRow.fillMax = true;
        // }
      } else {
        if (unitChange) {
          updatedSelectedRow.unitCost = null;
          updatedSelectedRow.totalCost = 0;
        } else {
          updatedSelectedRow.receivedQty = null;
          updatedSelectedRow.totalCost = 0;
        }

        // Update the selected row in allSelectedRows

        // Update state with the modified data
      }

      allSelectedRowsData[rowIndexSelected] = updatedSelectedRow;

      let subTotalCost = 0;
      allSelectedRowsData.forEach((row) => {
        subTotalCost += Number(row.totalCost);
      });

      if (index !== null) {
        setFieldTouched(`orderItems.items[${index}].receivedQty`, false);
        setFieldTouched(`orderItems.items[${index}].unitCost`, false, false);
      }

      setFieldValue('orderItems.items', allSelectedRowsData, false);
      setFieldValue(
        'orderItems.subTotal',
        Number(handleDecimalPoints(Number(subTotalCost))),
        false
      );

      let texValue ;
      let shipValue ;
      let otherValue;

      if (values.orderItems.taxesInPercentage) {
        texValue = Number(
          handleDecimalPoints(
            subTotalCost * (Number(values.orderItems.taxes) / 100)
          )
        );
        setFieldValue('orderItems.taxAmt', texValue, false);
      } else {
        texValue = handleDecimalPoints(values?.orderItems?.taxAmt) || 0;
      }

      if (values.orderItems.shippingCostInPercentage) {
        shipValue = Number(
          handleDecimalPoints(
            subTotalCost * (Number(values.orderItems.shippingCost) / 100)
          )
        );
        setFieldValue('orderItems.shipAmt', shipValue, false);
      } else {
        shipValue = handleDecimalPoints(values?.orderItems?.shipAmt) || 0;
      }

      if (values.orderItems.otherCostInPercentage) {
        otherValue = Number(
          handleDecimalPoints(
            subTotalCost * (Number(values.orderItems.otherCost) / 100)
          )
        );
        setFieldValue('orderItems.otherAmt', otherValue, false);
      } else {
        otherValue = handleDecimalPoints(values?.orderItems?.otherAmt) || 0;
      }

      let finalCost =
        Number(texValue) +
        Number(shipValue) +
        Number(otherValue) +
        Number(handleDecimalPoints(Number(subTotalCost)));
      setFieldValue(
        'orderItems.totalCost',
        Number(handleDecimalPoints(Number(finalCost)), false)
      );

      // if (subTotalCost == 0) {
      //   setFieldValue('orderItems.taxes', 0);
      //   setFieldValue('orderItems.shippingCost', 0, false);
      //   setFieldValue('orderItems.otherCost', 0, false);
      //   setFieldValue('orderItems.taxAmt', 0, false);
      //   setFieldValue('orderItems.shipAmt', 0, false);
      //   setFieldValue('orderItems.otherAmt', 0, false);
      //   setFieldValue('orderItems.totalCost', 0, false);
      // }
    }

    // setFieldValue('orderItems.taxes', null, false);
    // setFieldValue('orderItems.shippingCost', null, false);
    // setFieldValue('orderItems.otherCost', null, false);
    // setFieldValue('orderItems.taxAmt', null, false);
    // setFieldValue('orderItems.otherAmt', null, false);
    // setFieldValue('orderItems.shipAmt', null, false);
  };

  const handleFillSelected = (
    row,
    checked,
    setFieldValue,
    selectedPartsData = []
  ) => {
    const allSelectedRows = [...selectedPartsData];
    const rowIndex = allSelectedRows.findIndex((el) => el?.id === row?.id);

    const updatedRow = { ...allSelectedRows[rowIndex] };
    if (rowIndex !== -1) {
      if (checked) {
        updatedRow.fillMax = true;
        updatedRow.quantity = Number(updatedRow.availableQty);
        updatedRow.totalCost =
          Number(updatedRow.availableQty) * Number(updatedRow.unitCost);
      } else {
        updatedRow.fillMax = false;
        updatedRow.quantity = 1;
        updatedRow.totalCost = updatedRow.unitCost;
      }
      allSelectedRows[rowIndex] = updatedRow;

      let subTotalCost = 0;
      allSelectedRows.forEach((row) => {
        subTotalCost += Number(row.totalCost);
      });

      // Update the selected row in allSelectedRows

      // Update state with the modified data
      setFieldValue('orderItems.items', allSelectedRows);
      setFieldValue(
        'orderItems.subTotal',
        Number(parseFloat(Number(subTotalCost)).toFixed(2)),
        false
      );
      setFieldValue(
        'orderItems.totalCost',
        Number(parseFloat(Number(subTotalCost)).toFixed(2)),
        false
      );
      setFieldValue('orderItems.taxes', null);
      setFieldValue('orderItems.shippingCost', null);
      setFieldValue('orderItems.otherCost', null);
      setFieldValue('orderItems.taxAmt', null);
      setFieldValue('orderItems.otherAmt', null);
      setFieldValue('orderItems.shipAmt', null);
    }

    // Update the selected row in partsData
    const updatedData = [...partsData];
    const updatedRowIndex = updatedData.findIndex((item) => item.id === row.id);
    if (updatedRowIndex !== -1) {
      updatedData[updatedRowIndex] = {
        ...updatedData[updatedRowIndex],
        ...updatedRow,
      };
    }

    // Update the selected row in initPartsData
    const updatedInitData = [...initPartsData];
    const updatedInitRowIndex = updatedInitData.findIndex(
      (item) => item.id === row.id
    );
    if (updatedInitRowIndex !== -1) {
      updatedInitData[updatedInitRowIndex] = {
        ...updatedInitData[updatedInitRowIndex],
        ...updatedRow,
      };
    }

    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
  };

  const handleSetRowSelected = (
    row,
    checked,
    setFieldValue,
    selectedSetsData = []
  ) => {
    let allSelectedRows = [...selectedSetsData];
    if (checked) {
      allSelectedRows.unshift({ ...row, selectedSet: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
    }

    const updatedData = [...setsData];
    const updatedInitData = [...initSetsData];

    const selectedRowIds = allSelectedRows.map((row) => row.id);
    // Move selected rows to the top
    selectedRowIds.forEach((selectedRowId, index) => {
      const selectedIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedIndex !== -1 && selectedIndex !== index) {
        const [selectedRow] = updatedData.splice(selectedIndex, 1); // Remove selected row
        updatedData.splice(index, 0, selectedRow); // Insert selected row at the top
      }

      const selectedPartIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedPartIndex !== -1) {
        updatedData[selectedPartIndex].selectedSet = true; // Update selected
      }

      const selectedInitIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitIndex !== -1 && selectedInitIndex !== index) {
        const [selectedRow] = updatedInitData.splice(selectedInitIndex, 1); // Remove selected row
        updatedInitData.splice(index, 0, selectedRow); // Insert selected row at the top
      }
      const selectedInitPartIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitPartIndex !== -1) {
        updatedInitData[selectedInitPartIndex].selectedSet = true; // Update selected
      }
    });

    // Update state with the modified data
    setSetsData(updatedData);
    setInitSetsData(updatedInitData);
    setFieldValue('sets', allSelectedRows);
  };

  const removeFromSelectedParts = (
    index,
    selectedPartsData = [],
    setFieldValue = () => {}
  ) => {
    // let partData = partsData.find((parts) => parts.id === updatedSelectedPartsData[index].id)
    const updatedSelectedPartsData = [...selectedPartsData];

    updatedSelectedPartsData[index].selectedPart = false;
    updatedSelectedPartsData[index].fillMax = false;
    updatedSelectedPartsData.splice(index, 1); // Remove the item at the specified index
    setFieldValue('orderItems.items', updatedSelectedPartsData);
    handleRowSelected(
      selectedPartsData[index],
      false,
      setFieldValue,
      updatedSelectedPartsData
    ); // Update the state
  };

  return (
    <>
      {fetchingLocation ? (
        <Dimmer active inverted>
          <Loader inverted content={t.common.loading} />
        </Dimmer>
      ) : (
        <Formik
          initialValues={initialFormValues}
          enableReinitialize={true}
          validationSchema={PersonValidationSchema}
          onSubmit={(values, { resetForm }) => {
            let data = handleAddSet(values, resetForm);
          }}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            setFieldTouched,
            handleBlur,
            submitForm,
            resetForm,
          }) => (
            <FormikForm>
              <div
                // className="body p-6"
                // style={{
                //   height: '90vh',
                //   overflowX: 'hidden',
                //   overflowY: 'scroll',
                // }}
                className="wrapper-new-comment-container"
              >
                {values?.orderItems?.items?.length > 0 ? (
                  <>
                    {/* {console.log(initPartsData, partsData, values)} */}

                    <FieldArray
                      name="parts"
                      render={(arrayHelpers) => (
                        <div className="over-flow-scroll-table">
                          <div
                            className={'mt-6'}
                            style={{ backgroundColor: '#e9f2ff' }}
                          >
                            <div className={`kanbar-header`}>
                              <div
                                style={{
                                  gridTemplateColumns:
                                    '0.5fr 6.5fr 3.5fr 3.1fr 2.5fr 2.5fr',
                                }}
                                class="grid-container-element set"
                              >
                                <div
                                  class="grid-child-element set"
                                  style={{ minWidth: '41px' }}
                                >
                                  {t.po.form.no}
                                </div>
                                <div
                                  class="grid-child-element set"
                                  style={{ minWidth: '230px' }}
                                >
                                  {t.po.form.part_name}
                                </div>

                                <div
                                  class="grid-child-element set"
                                  style={{ minWidth: '171px' }}
                                >
                                  {t.parts.form.inv_loc}
                                </div>
                                <div
                                  class="grid-child-element set"
                                  style={{ minWidth: '130px',textAlign:"end" }}
                                >
                                  {t.po.form.qty_items}
                                </div>

                                <div
                                 class={selectedLanguage == "en" ? "grid-child-element set ml-5" : "grid-child-element set"}
                                  style={{ minWidth: '150px' }}
                                >
                                  {t.po.form.recievedQty}
                                </div>
                                <div
                                  class={selectedLanguage == "en" ? "grid-child-element set ml-5" : "grid-child-element set"}
                                  style={{ minWidth: '150px' }}
                                >
                                  {t.parts.form.unit_cost}
                                </div>
                                <div
                                  class="grid-child-element set"
                                  style={{ minWidth: '120px',textAlign:"end" }}
                                >
                                  {t.parts.form.total_cost}
                                </div>
                              </div>
                            </div>
                          </div>
                          {values.orderItems.items.map((part, index) => (
                           
                            <div className="mt-3 set-section-add" key={index}>
                              {/** both these conventions do the same */}
                      
                              <span
                                className="ml-4"
                                style={{
                                  minWidth: '41px',
                                  display: 'inline-grid',
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                }}
                              >
                                {index + 1}
                              </span>

                              <span
                                className="ml-4"
                                style={{
                                  minWidth: '230px',
                                  display: 'inline-grid',
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                }}
                              >
                                {checkForLinkPermission('part') ? (
                                 <span
                  className={selectedLanguage == "en" ? `detail-intro-link text-left` : "detail-intro-link text-right"}
                  basic
                  onClick={() =>
                    history.push(
                      `/parts?id=${part?.countId}`
                    )
                  }
                >
                {DisplayFormattedText(part?.name, 18)}
                </span>  ) : (<span
                >
                {DisplayFormattedText(part?.name, 18)}
                </span> )}
                                
                              </span>

                              <span
                               style={{
                                fontSize: '16px',
                                lineHeight: '24px',
                                fontWeight:"400",
                                minWidth: '150px',
                                textDecoration:"underline",
                                display: 'inline-block',
                                color:"#0C66E4"
                              }}
                              className='c-pointer'
                              onClick={() =>
                                history.push(
                                  `/locations?id=${part?.locationCId}`
                                )
                              }
                >
                {DisplayFormattedText(part?.location, 15)}
                </span> 

                              <div
                              
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  minWidth: '120px',
                                  display: 'flex',
                                  justifyContent: "end",
                                  gap:"10px"
                                }}
                              >
                                  <div style={{ minWidth: "70px", textAlign: "end" }}>
                          {part?.quantity
                            ? DisplayFormattedText(String(part?.quantity), 6)
                            : 0}{" "}
                        </div>
                               
                                  {part?.unitType && (
                          <div
                            style={{ minWidth: "30px", textAlign: "center" }}
                          >
                            {part?.unitType
                              ? HandleUnitType(part?.unitType)
                              : ""}
                          </div>
                        )}
                               
                                {/* <span
                                  className={` align-items-center justify-content-center ${
                                    selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                                  }`}
                                  style={{
                                    backgroundColor: '#E9F2FF',
                                    color: '#0C66E4',
                                    padding: '9px 3px 9px 2px',
                                    fontSize: '14px',
                                  }}
                                >
                                 
                                </span> */}
                              </div>
                              <div className="new-section-input-er">
                                <Input
                                  autoComplete="new-password"
                                  className="ml-5 d-flex"
                                  style={{ minWidth: '94px', width: '94px' }}
                                  type="number"
                                  onKeyDown={(evt) => handleKeyDown(evt)}
                                  value={part?.receivedQty}
                                  onChange={(e) => {
                                    if (
                                      Number(e.target.value) ||
                                      e.target.value == 0
                                    ) {
                                      setFieldValue(
                                        `orderItems.items[${index}].receivedQty`,
                                        Number(e.target.value)
                                      );
                                      setFieldValue(
                                        `orderItems.items[${index}].totalCost`,
                                        Number(e.target.value * part.unitCost),
                                        false
                                      );

                                      handleQtyChange(
                                        { id: part.id },
                                        e.target.value,
                                        setFieldValue,
                                        values.orderItems.items,
                                        index,
                                        setFieldTouched,
                                        values
                                      );
                                    } else {
                                      setFieldValue(
                                        `orderItems.items[${index}].receivedQty`,
                                        ''
                                      );
                                      setFieldValue(
                                        `orderItems.items[${index}].totalCost`,
                                        0,
                                        false
                                      );

                                      handleQtyChange(
                                        { id: part.id },
                                        e.target.value,
                                        setFieldValue,
                                        [],
                                        // values.orderItems.items,
                                        index,
                                        setFieldTouched,
                                        values
                                      );
                                    }
                                  }}
                                  error={
                                    errors?.orderItems?.items?.length > 0 &&
                                    errors?.orderItems?.items[index]
                                      ?.receivedQty &&
                                    touched?.orderItems?.items[index]
                                      ?.receivedQty
                                  }
                                  onBlur={(e) => {
                                    setFieldTouched(
                                      `orderItems.items[${index}].receivedQty`,
                                      true
                                    );
                                  }}
                                >
                                  <input />

                                  <span
                                    className={` align-items-center justify-content-center ${
                                      selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                                    }`}
                                    style={{
                                      backgroundColor: '#E9F2FF',
                                      color: '#0C66E4',
                                      padding: '9px 3px 9px 2px',
                                      fontSize: '14px',
                                    }}
                                  >
                                    {HandleUnitType(part?.unitType)}
                                  </span>
                                </Input>
                                {errors?.orderItems?.items?.length > 0 &&
                                errors?.orderItems?.items[index]?.receivedQty &&
                                touched?.orderItems?.items[index]
                                  ?.receivedQty ? (
                                  <span className="label-error d-block ml-5">
                                    {
                                      errors?.orderItems?.items[index]
                                        ?.receivedQty
                                    }
                                  </span>
                                ) : null}
                              </div>
                              <div>
                                <Input
                                  autoComplete="new-password"
                                  className="ml-22 d-flex"
                                  style={{ minWidth: '94px', width: '94px' }}
                                  type="number"
                                  onKeyDown={(evt) => handleKeyDown(evt)}
                                  value={part?.unitCost}
                                  onChange={(e) => {
                  
                                    if (
                                      Number(e.target.value) ||
                                      e.target.value == 0
                                    ) {
                                      let value = handleDecimalPoints(e.target.value)
                                      setFieldValue(
                                        `orderItems.items[${index}].unitCost`,
                                        Number(value)
                                      );
                                      setFieldValue(
                                        `orderItems.items[${index}].totalCost`,
                                        Number(
                                          value * part.receivedQty
                                        ),
                                        false
                                      );

                                      handleQtyChange(
                                        { id: part.id },
                                        value,
                                        setFieldValue,
                                        values.orderItems.items,
                                        index,
                                        setFieldTouched,
                                        values,
                                        true
                                      );
                                    } else {
                                      setFieldValue(
                                        `orderItems.items[${index}].unitCost`,
                                        ''
                                      );
                                      setFieldValue(
                                        `orderItems.items[${index}].totalCost`,
                                        0,
                                        false
                                      );

                                      handleQtyChange(
                                        { id: part.id },
                                        e.target.value,
                                        setFieldValue,
                                        [],
                                        // values.orderItems.items,
                                        index,
                                        setFieldTouched,
                                        values,
                                        true
                                      );
                                    }
                                  }}
                                  error={
                                    errors?.orderItems?.items?.length > 0 &&
                                    errors?.orderItems?.items[index]
                                      ?.unitCost &&
                                    touched?.orderItems?.items[index]?.unitCost
                                  }
                                  onBlur={(e) => {
                                    setFieldTouched(
                                      `orderItems.items[${index}].unitCost`,
                                      true
                                    );
                                  }}
                                >
                                  <input />

                                  <span
                                    className={` align-items-center justify-content-center ${
                                      selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                                    }`}
                                    style={{
                                      backgroundColor: '#E9F2FF',
                                      color: '#0C66E4',
                                      padding: '9px 3px 9px 2px',
                                      fontSize: '14px',
                                    }}
                                  >
                                    {organisationCurrency}
                                  </span>
                                </Input>
                                {errors?.orderItems?.items?.length > 0 &&
                                errors?.orderItems?.items[index]?.unitCost &&
                                touched?.orderItems?.items[index]?.unitCost ? (
                                  <span className="label-error d-block ml-22">
                                    {errors?.orderItems?.items[index]?.unitCost}
                                  </span>
                                ) : null}
                              </div>
                              {/* <span
                                  className={` align-items-center justify-content-center ${
                                    selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                                  }`}
                                  style={{
                                    backgroundColor: '#E9F2FF',
                                    color: '#0C66E4',
                                    padding: '9px 3px 9px 2px',
                                    fontSize: '14px',
                                  }}
                                >
                                 
                                </span> */}

                              <div
                                 className='ml-17'
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  minWidth: "120px",
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "end",
                                }}
                              >
                                 <div style={{ minWidth: "86px", textAlign: "end" }}>
                                 {DisplayFormattedText(
                                    String(handleDecimalPoints(part?.totalCost)),
                                    7
                                  )}{" "}
                        </div>
                             
                                 {' '}
                                 <div
                            style={{ minWidth: "30px", textAlign: "center" }}
                          >
                           {organisationCurrency}s
                          </div>
                             
                                {/* <span
                                  className={` align-items-center justify-content-center ${
                                    selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                                  }`}
                                  style={{
                                    backgroundColor: '#E9F2FF',
                                    color: '#0C66E4',
                                    padding: '9px 3px 9px 2px',
                                    fontSize: '14px',
                                  }}
                                >
                               
                                </span> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </>
                ) : null}
                {values.orderItems.items.length > 0 ? (
                  <div className={`change-for-job-btn ${selectedLanguage == "en" ? "text-right" : "text-left"}`}>
                    <div className="new-grey-btn width-content my-3">
                      {t.po.form.subTotal} &nbsp; &nbsp; &nbsp;
                      <span className="grey-btn-number">
                        {handleDecimalPoints(values.orderItems.subTotal)}
                      </span>{' '}
                      &nbsp; {organisationCurrency}
                    </div>

                    <div>
                      <div className="set-position-of-percentage">
                        {' '}
                        <span
                          className="c-pointer"
                          onClick={() => {
                            if (values.orderItems.taxesInPercentage == true) {
                              setFieldValue(
                                'orderItems.taxesInPercentage',
                                false
                              );

                              let value = values.orderItems.taxes;

                              let taxAmount = handleDecimalPoints(Number(value))

                              let subCost = handleDecimalPoints(Number(values.orderItems.subTotal));

                              let totalCostData = handleDecimalPoints(
                                Number(taxAmount) +
                                  Number(values.orderItems.otherAmt) +
                                  Number(values.orderItems.shipAmt) +
                                  Number(subCost)
                              );

                              setFieldValue(
                                `orderItems.taxAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            } else {
                              setFieldValue(
                                'orderItems.taxesInPercentage',
                                true
                              );

                              let value = handleDecimalPoints(Number(values.orderItems.taxes));

                              let totalCost = handleDecimalPoints(values.orderItems.subTotal);
                              let taxAmount = handleDecimalPoints(
                                totalCost * (value / 100)
                              );

                              let finalCost =
                                Number(values.orderItems.shipAmt) +
                                Number(values.orderItems.otherAmt) +
                                Number(taxAmount) +
                                Number(totalCost);

                              // Calculate the total cost including tax
                              let totalCostData = handleDecimalPoints(
                                Number(finalCost)
                              );

                              setFieldValue(
                                `orderItems.taxAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            }
                          }}
                        >
                          {values.orderItems.taxesInPercentage ? (
                            <PercentageBlueIcon />
                          ) : (
                            <PercentageLightIcon />
                          )}
                        </span>
                        <span
                          onClick={() => {
                            if (values.orderItems.taxesInPercentage == true) {
                              setFieldValue(
                                'orderItems.taxesInPercentage',
                                false
                              );

                              let value = values.orderItems.taxes;

                              let taxAmount = handleDecimalPoints(Number(value))

                              let subCost = Number(values.orderItems.subTotal);


                              let totalCostData = handleDecimalPoints(
                                Number(taxAmount) +
                                  Number(values.orderItems.otherAmt) +
                                  Number(values.orderItems.shipAmt) +
                                  Number(subCost)
                              );
                              setFieldValue(
                                `orderItems.taxAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            } else {
                              setFieldValue(
                                'orderItems.taxesInPercentage',
                                true
                              );

                              let value = Number(values.orderItems.taxes);

                              let totalCost = values.orderItems.subTotal;
                              let taxAmount = handleDecimalPoints(
                                totalCost * (value / 100)
                              );

                              let finalCost =
                                Number(values.orderItems.shipAmt) +
                                Number(values.orderItems.otherAmt) +
                                Number(taxAmount) +
                                Number(totalCost);

                              // Calculate the total cost including tax
                              let totalCostData = handleDecimalPoints(
                                Number(finalCost)
                              )

                              setFieldValue(
                                `orderItems.taxAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            }
                          }}
                          className="c-pointer ml-2 mr-2"
                        >
                          {' '}
                          {values.orderItems.taxesInPercentage ? (
                            <AmountLightIcon />
                          ) : (
                            <AmountBlueIcon />
                          )}
                        </span>
                        <span className="ml-2 mr-2 fix-width-label">
                          {' '}
                          {t.po.form.taxes}{' '}
                        </span>
                        <span>
                          <Input
                            style={{ width: '67px' }}
                            // defaultValue="1"
                            autoComplete="new-password"
                            type="number"
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            onWheel={(e) => e.target.blur()}
                            value={values?.orderItems?.taxes || ''}
                            disabled={values?.orderItems?.subTotal == 0}
                            onChange={(e) => {
                              if (Number(e.target.value)) {
                                let value = handleDecimalPoints(
                                  e.target.value
                                )
                                if (
                                  values.orderItems.taxesInPercentage == true
                                ) {
                                  let totalCost = values.orderItems.subTotal;
                                  let taxAmount = handleDecimalPoints(
                                    totalCost * (value / 100)
                                  )

                                  let finalCost =
                                    Number(values.orderItems.shipAmt) +
                                    Number(values.orderItems.otherAmt) +
                                    Number(taxAmount) +
                                    Number(totalCost);

                                  // Calculate the total cost including tax
                                  let totalCostData = handleDecimalPoints(
                                    Number(finalCost)
                                  )


                                  setFieldValue(
                                    `orderItems.taxes`,
                                    Number(value)
                                  );
                                  setFieldValue(
                                    `orderItems.taxAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                } else {
                                  let taxAmount = handleDecimalPoints(
                                    Number(value)
                                  )
                                  let subCost = Number(
                                    values.orderItems.subTotal
                                  );

                                  let totalCostData = handleDecimalPoints(
                                    Number(taxAmount) +
                                      Number(values.orderItems.otherAmt) +
                                      Number(values.orderItems.shipAmt) +
                                      Number(subCost)
                                  )
                                  setFieldValue(
                                    `orderItems.taxes`,
                                    Number(value)
                                  );

                                  setFieldValue(
                                    `orderItems.taxAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                }
                              } else {
                                setFieldValue(`orderItems.taxes`, null);
                                setFieldValue(`orderItems.taxAmt`, null, false);

                                let subCost = values.orderItems.subTotal;
                                let shippingCost = values.orderItems.shipAmt;
                                let otherCost = values.orderItems.otherAmt;

                                let finalCost =
                                    Number(subCost) +
                                    Number(shippingCost) +
                                    Number(otherCost);
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    handleDecimalPoints(finalCost),
                                    false
                                  );
                              }
                            }}
                          >
                            <input />
                            {errors?.orderItems?.taxes ? (
                              <span className="label-error">
                                {errors?.orderItems?.taxes}
                              </span>
                            ) : null}
                          </Input>{' '}
                          <span
                            className={` align-items-center justify-content-center ${
                              selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                            }`}
                            style={{
                              backgroundColor: '#E9F2FF',
                              color: '#0C66E4',
                              padding: '5px',
                              fontSize: '14px',
                              width: '37px',
                              textAlign: 'center',
                              display: 'inline-block',
                            }}
                          >
                            {values.orderItems.taxesInPercentage
                              ? '%'
                              : organisationCurrency}
                          </span>
                        </span>
                        <span className="span-number-per">
                          {values.orderItems.taxesInPercentage == true &&
                          values.orderItems.taxAmt ? (
                            <>
                                 {handleDecimalPoints(values.orderItems.taxAmt)}
                            </>
                          ) : null}
                        </span>
                      </div>

                      <div className="set-position-of-percentage">
                        {' '}
                        <span
                          className="c-pointer"
                          onClick={() => {
                            if (
                              values.orderItems.shippingCostInPercentage == true
                            ) {
                              setFieldValue(
                                'orderItems.shippingCostInPercentage',
                                false
                              );

                              let value = values.orderItems.shippingCost;

                              let taxAmount = handleDecimalPoints(Number(value))
                              let subCost = Number(values.orderItems.subTotal);

                              let totalCostData = handleDecimalPoints(
                                Number(taxAmount) +
                                  Number(values.orderItems.otherAmt) +
                                  Number(values.orderItems.taxAmt) +
                                  Number(subCost)
                              )

                              setFieldValue(
                                `orderItems.shipAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            } else {
                              setFieldValue(
                                'orderItems.shippingCostInPercentage',
                                true
                              );

                              let value = Number(
                                values.orderItems.shippingCost
                              );

                              let totalCost = values.orderItems.subTotal;
                              let taxAmount = handleDecimalPoints(
                                totalCost * (value / 100)
                              )
                              let finalCost =
                                Number(values.orderItems.taxAmt) +
                                Number(values.orderItems.otherAmt) +
                                Number(taxAmount) +
                                Number(totalCost);

                              // Calculate the total cost including tax
                              let totalCostData = handleDecimalPoints(
                                Number(finalCost)
                              )

                              setFieldValue(
                                `orderItems.shipAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            }
                          }}
                        >
                          {values.orderItems.shippingCostInPercentage ? (
                            <PercentageBlueIcon />
                          ) : (
                            <PercentageLightIcon />
                          )}
                        </span>
                        <span
                          onClick={() => {
                            if (
                              values.orderItems.shippingCostInPercentage == true
                            ) {
                              setFieldValue(
                                'orderItems.shippingCostInPercentage',
                                false
                              );

                              let value = values.orderItems.shippingCost;

                              let taxAmount = handleDecimalPoints(Number(value))

                              let subCost = Number(values.orderItems.subTotal);
                              let totalCostData = handleDecimalPoints(
                                Number(taxAmount) +
                                  Number(values.orderItems.otherAmt) +
                                  Number(values.orderItems.taxAmt) +
                                  Number(subCost)
                              )

                              setFieldValue(
                                `orderItems.shipAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            } else {
                              setFieldValue(
                                'orderItems.shippingCostInPercentage',
                                true
                              );

                              let value = Number(
                                values.orderItems.shippingCost
                              );

                              let totalCost = values.orderItems.subTotal;
                              let taxAmount = handleDecimalPoints(
                                totalCost * (value / 100)
                              )

                              let finalCost =
                                Number(values.orderItems.taxAmt) +
                                Number(values.orderItems.otherAmt) +
                                Number(taxAmount) +
                                Number(totalCost);

                              // Calculate the total cost including tax
                              let totalCostData = handleDecimalPoints(
                                Number(finalCost)
                              )

                              setFieldValue(
                                `orderItems.shipAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            }
                          }}
                          className="c-pointer ml-2 mr-2"
                        >
                          {' '}
                          {values.orderItems.shippingCostInPercentage ? (
                            <AmountLightIcon />
                          ) : (
                            <AmountBlueIcon />
                          )}
                        </span>
                        <span className="ml-2 mr-2 fix-width-label">
                          {' '}
                          {t.po.form.shipping_cost}{' '}
                        </span>
                        <span>
                          <Input
                            style={{ width: '67px' }}
                            // defaultValue="1"
                            autoComplete="new-password"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            value={values?.orderItems?.shippingCost || ''}
                            disabled={values?.orderItems?.subTotal == 0}
                            onChange={(e) => {
                              if (Number(e.target.value)) {
                                let value = handleDecimalPoints(
                                  e.target.value
                                )
                                if (
                                  values.orderItems.shippingCostInPercentage ==
                                  true
                                ) {
                                  let totalCost = values.orderItems.subTotal;
                                  let taxAmount = handleDecimalPoints(
                                    totalCost * (value / 100)
                                  )


                                  let finalCost =
                                    Number(values.orderItems.taxAmt) +
                                    Number(values.orderItems.otherCost) +
                                    Number(taxAmount) +
                                    Number(totalCost);

                                  // Calculate the total cost including tax
                                  let totalCostData = handleDecimalPoints(
                                    Number(finalCost)
                                  )

                                  setFieldValue(
                                    `orderItems.shippingCost`,
                                    Number(value)
                                  );
                                  setFieldValue(
                                    `orderItems.shipAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                } else {
                                  let taxAmount = handleDecimalPoints(
                                    Number(e.target.value)
                                  )

                                  let subCost = Number(
                                    values.orderItems.subTotal
                                  );

                                  let totalCostData = handleDecimalPoints(
                                    Number(taxAmount) +
                                      Number(values.orderItems.taxAmt) +
                                      Number(values.orderItems.otherAmt) +
                                      Number(subCost)
                                  )


                                  setFieldValue(
                                    `orderItems.shippingCost`,
                                    Number(value)
                                  );


                                  setFieldValue(
                                    `orderItems.shipAmt`,
                                    Number(taxAmount)
                                  );

                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                }
                              } else {
                                setFieldValue(`orderItems.shippingCost`, null);
                                setFieldValue(
                                  `orderItems.shipAmt`,
                                  null,
                                  false
                                );

                                let subCost = values.orderItems.subTotal;
                                let shippingCost = values.orderItems.taxAmt;
                                let otherCost = values.orderItems.otherAmt;

                                let finalCost =
                                Number(subCost) +
                                Number(shippingCost) +
                                Number(otherCost);
                              setFieldValue(
                                `orderItems.totalCost`,
                                handleDecimalPoints(finalCost),
                                false
                              );
                              }
                            }}

                            // onChange={(e, value) => {
                            //   handleQtyChange(
                            //     row,
                            //     e.target.value,
                            //     setFieldValue,
                            //     values.orderItems.items
                            //   );
                            // }}
                            // error={
                            //   errors?.orderItems?.items?.length > 0 &&
                            //   errors?.orderItems?.items[index]?.quantity &&
                            //   touched?.orderItems?.items[index]?.quantity
                            // }
                            // onFocus={(e) => {
                            //   setFieldTouched(`orderItems.items[${index}].quantity`, true);
                            // }}
                          >
                            <input />
                            {/* {errors?.orderItems?.items?.length > 0 &&
                            errors?.orderItems?.items[index]?.quantity &&
                            touched?.orderItems?.items[index]?.quantity ? (
                              <span className="label-error">
                                {errors?.orderItems?.items[index]?.quantity}
                              </span>
                            ) : null} */}
                          </Input>{' '}
                          <span
                            className={` align-items-center justify-content-center ${
                              selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                            }`}
                            style={{
                              backgroundColor: '#E9F2FF',
                              color: '#0C66E4',
                              padding: '5px',
                              fontSize: '14px',
                              width: '37px',
                              textAlign: 'center',
                              display: 'inline-block',
                            }}
                          >
                            {values.orderItems.shippingCostInPercentage
                              ? '%'
                              : organisationCurrency}
                          </span>
                        </span>
                        <span className="span-number-per">
                          {values.orderItems.shippingCostInPercentage == true &&
                          values.orderItems.shipAmt ? (
                            <>
                              {handleDecimalPoints(values.orderItems.shipAmt)}
                            </>
                          ) : null}
                        </span>
                      </div>

                      <div className="set-position-of-percentage">
                        {' '}
                        <span
                          className="c-pointer"
                          onClick={() => {
                            if (
                              values.orderItems.otherCostInPercentage == true
                            ) {
                              setFieldValue(
                                'orderItems.otherCostInPercentage',
                                false
                              );

                              let value = values.orderItems.otherCost;

                              let taxAmount = handleDecimalPoints(Number(value))

                              let subCost = Number(values.orderItems.subTotal);

                              let totalCostData = handleDecimalPoints(
                                Number(taxAmount) +
                                  Number(values.orderItems.taxAmt) +
                                  Number(values.orderItems.shipAmt) +
                                  Number(subCost)
                              )

                              setFieldValue(
                                `orderItems.otherAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            } else {
                              setFieldValue(
                                'orderItems.otherCostInPercentage',
                                true
                              );

                              let value = handleDecimalPoints(
                                values.orderItems.otherCost
                              )

                              let totalCost = values.orderItems.subTotal;
                              let taxAmount = handleDecimalPoints(
                                totalCost * (value / 100)
                              )

                              let finalCost =
                                Number(values.orderItems.shipAmt) +
                                Number(values.orderItems.taxAmt) +
                                Number(taxAmount) +
                                Number(totalCost);

                              // Calculate the total cost including tax
                              let totalCostData = handleDecimalPoints(
                                Number(finalCost)
                              )

                              setFieldValue(
                                `orderItems.otherAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            }
                          }}
                        >
                          {values.orderItems.otherCostInPercentage ? (
                            <PercentageBlueIcon />
                          ) : (
                            <PercentageLightIcon />
                          )}
                        </span>
                        <span
                          onClick={() => {
                            if (
                              values.orderItems.otherCostInPercentage == true
                            ) {
                              setFieldValue(
                                'orderItems.otherCostInPercentage',
                                false
                              );

                              let value = values?.orderItems?.otherCost;

                              let taxAmount = handleDecimalPoints(Number(value))
                              

                              let subCost = handleDecimalPoints(values.orderItems.subTotal);

                              let totalCostData = handleDecimalPoints(
                                Number(taxAmount) +
                                  Number(values.orderItems.taxAmt) +
                                  Number(values.orderItems.shipAmt) +
                                  Number(subCost)
                              )

                              setFieldValue(
                                `orderItems.otherAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            } else {
                              setFieldValue(
                                'orderItems.otherCostInPercentage',
                                true
                              );
                              let value = handleDecimalPoints(
                                values.orderItems.otherCost
                              )

                              let totalCost = values.orderItems.subTotal;
                              let taxAmount = handleDecimalPoints(
                                totalCost * (value / 100)
                              )

                              let finalCost =
                                Number(values.orderItems.shipAmt) +
                                Number(values.orderItems.taxAmt) +
                                Number(taxAmount) +
                                Number(totalCost);

                              // Calculate the total cost including tax
                              let totalCostData = handleDecimalPoints(
                                Number(finalCost)
                              )

                              setFieldValue(
                                `orderItems.otherAmt`,
                                Number(taxAmount),
                                false
                              );
                              setFieldValue(
                                `orderItems.totalCost`,
                                Number(totalCostData),
                                false
                              );
                            }
                          }}
                          className="c-pointer ml-2 mr-2"
                        >
                          {' '}
                          {values.orderItems.otherCostInPercentage ? (
                            <AmountLightIcon />
                          ) : (
                            <AmountBlueIcon />
                          )}
                        </span>
                        <span className="ml-2 mr-2 fix-width-label">
                          {' '}
                          {t.po.form.other_cost}{' '}
                        </span>
                        <span>
                          <Input
                            style={{ width: '67px' }}
                            // defaultValue="1"
                            autoComplete="new-password"
                            type="number"
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            onWheel={(e) => e.target.blur()}
                            value={values?.orderItems?.otherCost || ''}
                            disabled={values?.orderItems?.subTotal == 0}
                            onChange={(e) => {
                              if (Number(e.target.value)) {
                                let value = handleDecimalPoints(
                                  e.target.value
                                )
                                if (
                                  values.orderItems.otherCostInPercentage ==
                                  true
                                ) {
                                  let totalCost = values.orderItems.subTotal;
                                  let taxAmount = handleDecimalPoints(
                                    totalCost * (value / 100)
                                  )

                                  let finalCost =
                                    Number(values.orderItems.shipAmt) +
                                    Number(values.orderItems.taxAmt) +
                                    Number(taxAmount) +
                                    Number(totalCost);

                                  // Calculate the total cost including tax
                                  let totalCostData = handleDecimalPoints(
                                    Number(finalCost)
                                  )

                                  setFieldValue(
                                    `orderItems.otherCost`,
                                    Number(value)
                                  );
                                  setFieldValue(
                                    `orderItems.otherAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                } else {
                                  let value = handleDecimalPoints(
                                    e.target.value
                                  )
                                  let taxAmount = handleDecimalPoints(
                                    Number(e.target.value)
                                  )
                                  let subCost = Number(
                                    values.orderItems.subTotal
                                  );


                                  let totalCostData = handleDecimalPoints(
                                    Number(taxAmount) +
                                      Number(values.orderItems.taxAmt) +
                                      Number(values.orderItems.shipAmt) +
                                      Number(subCost)
                                  )

                                  setFieldValue(
                                    `orderItems.otherCost`,
                                    Number(value)
                                  );

                                  setFieldValue(
                                    `orderItems.otherAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                }
                              } else {
                                setFieldValue(`orderItems.otherCost`, null);
                                setFieldValue(
                                  `orderItems.otherAmt`,
                                  null,
                                  false
                                );

                                let subCost = values.orderItems.subTotal;
                                let shippingCost = values.orderItems.shipAmt;
                                let otherCost = values.orderItems.taxAmt;

                                let finalCost =
                                Number(subCost) +
                                Number(shippingCost) +
                                Number(otherCost);
                              setFieldValue(
                                `orderItems.totalCost`,
                                handleDecimalPoints(finalCost),
                                false
                              );
                              }
                            }}
                          >
                            <input />
                            {/* {errors?.orderItems?.items?.length > 0 &&
                            errors?.orderItems?.items[index]?.quantity &&
                            touched?.orderItems?.items[index]?.quantity ? (
                              <span className="label-error">
                                {errors?.orderItems?.items[index]?.quantity}
                              </span>
                            ) : null} */}
                          </Input>{' '}
                          <span
                            className={` align-items-center justify-content-center ${
                              selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                            }`}
                            style={{
                              backgroundColor: '#E9F2FF',
                              color: '#0C66E4',
                              padding: '5px',
                              fontSize: '14px',
                              width: '37px',
                              textAlign: 'center',
                              display: 'inline-block',
                            }}
                          >
                            {values.orderItems.otherCostInPercentage
                              ? '%'
                              : organisationCurrency}
                          </span>
                        </span>
                        <span className="span-number-per">
                          {values.orderItems.otherCostInPercentage == true &&
                          values.orderItems.otherAmt ? (
                            <>
                              {handleDecimalPoints(values.orderItems.otherAmt)}
                            </>
                          ) : null}
                        </span>
                      </div>
                      <div className="change-for-job-btn">
                        <div className="new-grey-btn width-content my-3">
                          {t.parts.form.total_cost} &nbsp; &nbsp; &nbsp;
                          <span className="grey-btn-number">
                            {handleDecimalPoints(values.orderItems.totalCost)}
                          </span>{' '}
                          &nbsp;
                          {organisationCurrency}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="form-button-container mt-10">
                  <button
                    type="button"
                    // content={t.procedures.form.cancel_button}
                    // basic
                    onClick={() => setOpenFuifillModal(false)}
                    className="form-action-button"
                  >
                    {t.assets.form.cancel}
                  </button>

                  <button
                    type="submit"
                    style={{ minWidth:"58px" }}
                    // content={t.procedures.form.submit.add}
                    // primary
                    // onClick={createNewTeamHandler}
                    // loading={buttonStatus == 1}
                    className="form-action-button blue"
                  >
                    {buttonStatus == 1 ?  <Spinner color="light" size="sm">
                    <div>{null}</div>
                  </Spinner>  :  t.po.form.fulfill}
                  </button>
                </div>

                {/* Add parts and add sets modal */}
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </>
  );
};

export default FullfillForm;
