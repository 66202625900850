import React, { useEffect, useState } from "react";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import {
  AddIconDashboard,
  CostIconEstimate,
  DashboardWOStatusIcon,
  FullArrow,
  TimeIconEstimate,
} from "../../../../Assets/Icons/svg";
import Chart from "react-apexcharts";
import NoDashboardData from "../noDashboardData";

const MaintenanceCategory = ({
  setChartDateOption,
  chartDateOption,
  setOpenModal,
  openModal,
  setModalData,
  chartConfigCategory,
  setChartConfigCategory,
  setAssetDashBoardData,
  maintenanceData,
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const colors = [
    "#5B48F6",
    "#5ACADA",
    "#8AB4F9",
    "#6A82F7",
    "#73FBF5",
    "#002EF5",
    "#5ABF92",
    "#74FAB2",
    "#454BDC",
    "#C0B3FA",
    "#E8762F",
    "#F8CB5D",
  ];

  const [showTooltip, setShowTooltip] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  useEffect(() => {
    if (maintenanceData?.maintenanceCostPerAssetCategory?.length > 0) {
      // const counts = maintenanceData?.maintenanceCostPerAssetCategory?.map(
      //   (item) => item?.totalActualCost
      // );
      const labels =  maintenanceData?.maintenanceCostPerAssetCategory?.map(
        (item) => item?.categoryName
      );

      const chartData = maintenanceData?.maintenanceCostPerAssetCategory?.map((category, index) => ({
        x: category.categoryName, // Use categoryName for x-axis label
        y: category.totalActualCost, // Use totalActualCost for y-axis value
        fillColor: colors[index % colors.length], // Cycle through colors
      }));
      // const randomColors = dashBoardData?.workOrdersByAssigneeData?.map(item => {

      //     return item?.name === 'Unassigned' ? '#788CA5' : generateRandomColor();
      //   });

      //   const updatedStatusOptions = statusOptions.map(option => {
      //     const count = dashBoardData?.workOrderStatus[option.value]?.count || 0;
      //     return { ...option, count };
      //   });

      setChartConfigCategory((prevState) => ({
        ...prevState,
        series: [
          {
            ...prevState.series[0],
            data: chartData, // Set y values
          },
        ],
        // data: chartData,
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: labels, // Set label data
          },
        },
      }));

      // const seriesPercentages = [
      //   open.percentage,
      //   inReview.percentage,
      //   onHold.percentage,
      //   completed.percentage,
      //   inProgress.percentage,
      // ];
    }
  }, [maintenanceData]);

  return (
    <>
     
        <Grid.Column className="completion-column pl-0 pt-0">
          <div
            className={
              openModal
                ? "completion-column-data no-border"
                : "completion-column-data"
            }
          >
            <div className="completion-cotainer d-flex justify-content-between mt-2">
              <div className="completion-title d-flex">
                <span className="title">
                  {t.dashboard.maintenance_card.category_maintenance}
                </span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className={
                    selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                  }
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={selectedLanguage == "en" ? "right" : "left"}
                    show={showTooltip}
                    textBoxWidth="140px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="0px 0px 0px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="8px"
                    // moveDown="10px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.asset_tooltip.category_asset}
                    </span>
                  </Tooltip>
                  <DashboardWOStatusIcon />
                </p>
              </div>

              <span
                style={{ marginTop: "-10px", gap: "10px" }}
                className="d-flex"
              >
                <span
                  onClick={() => {
                    setOpenModal(true);
                    setModalData("Category");
                    setShowExpandTooltip(false);
                  }}
                  style={{ marginTop: "2px" }}
                  className=""
                >
                  {openModal ? null : (
                    <p
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        marginTop: "-10px",
                      }}
                      onMouseOver={() => setShowExpandTooltip(true)}
                      onMouseLeave={() => setShowExpandTooltip(false)}
                      className=" mt-1"
                    >
                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top"
                        show={showExpandTooltip}
                        textBoxWidth="80px"
                        fontSize="10px"
                        fontWeight="400"
                        fontFamily="Roboto"
                        padding="8px 8px 8px 8px"
                        borderRadius="5px"
                        static
                        arrowAlign="start"
                        moveRight="5px"
                        moveDown="3px"
                      >
                        <span
                          style={{ padding: "0px 10px" }}
                          className="work-order-tooltip"
                        >
                          {t.dashboard.expand_full}
                        </span>
                      </Tooltip>
                      <FullArrow />
                    </p>
                  )}
                </span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowAddCustomizedTooltip(true)}
                  onMouseLeave={() => setShowAddCustomizedTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showAddCustomizedTooltip}
                    textBoxWidth="105px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="0px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.add_customized}
                    </span>
                  </Tooltip>
                  {/* <AddIconDashboard /> */}
                </p>
              </span>
            </div>
            {maintenanceData?.maintenanceCostPerAssetCategory?.length > 0 ? (
            <div className="completion-status-part res">
              <Grid className="wo-data-container" columns={1}>
                <Grid.Column className="wo-data-chart  align-items-center text-center">
                  {" "}
                  {/* Adjust minWidth as needed */}
                  <Chart
                    options={chartConfigCategory?.options}
                    series={chartConfigCategory?.series}
                    type="bar"
                    height={openModal ? 400 : 277}
                  />
                </Grid.Column>
              </Grid>
            </div> 
            ) : <NoDashboardData height={"292px"}/>}
          </div>
        </Grid.Column>
      
    </>
  );
};

export default MaintenanceCategory;



