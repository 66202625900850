export default {
  po_small: 'purchase order',
  title: 'Purchase Orders',
  title_single: 'Purchase Order',
  add: 'Add Purchase Order',
  edit: 'Edit Purchase Order',
  start_add: 'Start adding purchase orders',
  how_to_add: 'How to add purchase order',

  table: {
    po_number: 'P.O Number',
    vendor: 'Vendors',
    no_items: 'No of Items',
    total_qty: 'Total Qty',
    total_cost: 'Total Cost',
    due_date: 'Due Date',
    approved_by: 'Approved By',
  },

  po_status: {
    requested: 'Requested',
    approved: 'Approved',
    declined: 'Declined',
    cancelled: 'Cancelled',
    fulfilled: 'Fulfilled',
    partiallyfulfilled: 'Partially Fulfilled',
    // open: 'Open',
    // open_status: 'open',
    // inProgress: 'In-Progress',
    // onHold: 'On-Hold',
    // inReview: 'In-Review',
    // completed: 'Completed',
    // hold: 'Hold',
    // cancel: 'Cancelled',
    // cancelled: 'Cancelled',
  },

  status: {
    requested: 'Requested',
    approved: 'Approved',
    declined: 'Declined',
    cancelled: 'Cancelled',
    fulfilled: 'Fulfilled',
    partially_fulfilled: 'Partially Fulfilled',
  },
  form: {
    vendor_address:"Vendor Address",
    address_place:"Add vendor address",
    add_po:"Add PO Number",
    part_name:"Part Name",
    part_id:"Part ID",
   qty_to_order:"Qty to Order",
   fill_max:"Fill to max",
   price:"Price",
   item:"Item Name",
    add_part:"Add Part",
     ponumber:"PO Number",
     add_vendor:"Select vendor",
     add_dueDate:"Select duedate",
     additionalInformation:"Additional Information",
     add_additionalInformation:"Add notes",
     details:"Details",
     order_items:"Order Items",
    add_name: 'Add set name',
    add_parts: 'Add Parts',
    add_sets: 'Add Sets',
    search: 'Search parts',
    search: 'Search sets',
    qty: 'Qty',
    cancel_msg:
      'You have unsaved changes on this Purchase Order. Are you sure you want to cancel?',
  
     subTotal:"Subtotal",
     taxes:"Taxes",
     shipping_cost:"Shipping Cost",
     other_cost:"Other Cost",
     shipping_address:"Shipping Address",
     location_name:"Company/Location Name",
     add_location_name:"Select company/location name",
     contact_number:"Contact Phone Number",
     state:"State/Provincer",
     add_state:"Add state/Province",
     zip_code:"Zip Code/Postal Code",
     add_zip_code:"Add zip code/postal code",
     billing_address:"Billing Address",
     use_same:"Use same as shipping address",
     use_different:"Use different address",
     company_name:"Company Name",
     add_company_name:"Add the company name",
     attach_file:"Attach a file to this order",
     add_valiadtion:"Please select atleast one order item",
     add_success:"Purchase order added successfully",
     update_success:"Purchase order updated successfully",
     item_name:"Item Name",
     part_no:"Part ID",
     order_qty:"Ordered Qty",
     receive_qty:"Received Qty",
    total:"Total",
     decline_po:"Decline Purchase Order",
     decline_po_statement:"Are you sure you want to decline this order?",
     decline_success:"Purchase Order declined successfully",
     approve_success:"Purchase Order approved successfully",
     approve_po:"Approve Purchase Order",
     approve_po_statement:"Are you sure you want to approve this order?",
     send_to_vendor:"Approve & Send to Vendor",
     fulfill:"Fulfill",
     fulfill_title:"Fulfill Purchase Order",
     qty_items:"Qty of Items",
     no:"No.",
     
     fulfill_statement:"Confirm or edit items received as well as costs",
     recievedQty:"Recieved Qty",
     update_status_error:"you don't have the permission to update purchase order status",
     close_po:"Close Purchase Order",
     close_statement:"Are you sure you want to close this purchase Order?",
     delete_statement:"Are you sure you want to delete this purchase Order?",
     close_success:"Purchase Order closed successfully",
     cancelled_success:"Purchase Order cancelled successfully",
     delete_success:"Purchase Order deleted successfully",
     fulFill_success:'Purchase Order fulfilled successfully',
     pfulFill_success:'Purchase Order partially fulfilled successfully',
     vendor_email:"Vendor Email",
     vendor_email_not_found:"This vendor does not have an email address",
     subTotal_error:"Subtotal must be more the 0 to fulfill the order",
     decline_purchase_order:"This Purchase Order has been declined",
     approve_download_pdf:"Approve & Download as PDF",
     people_tooltip:"Select the responsible for approving and fulfilling this purchase order",
    
    
    
    },

 
};
