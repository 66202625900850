import React, { useState } from "react";
import {
  Checkbox,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Label,
  Image,
  Grid,
  Menu,
  Tab,
  Divider,
} from "semantic-ui-react";
import {
  CategoryElectrical,
  CategoryInspection,
  CategoryMechanical,
  CategoryRefrigeration,
  CategorySafety,
  CatgoryPreventive,
  CorrectiveIcon,
  Damage,
  DashboardrIcon,
  OperationProcedure,
  PlannedIcon,
  Project,
  UnPlannedIcon,
} from "../../../Assets/Icons/svg";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import WorkOrderManagement from "./workOrderManagement";
import AssetsManagement from "./assetsManagement";
import InventryManagement from "./inventryManagement";
import ResourcePlanner from "./resourcePlanner";
import Timesheet from "./timesheet";
import Cost from "./cost";
import WorkOrderFilter from "./Filters/workOrderFilter";
import AssetFilter from "./Filters/assetFilter";
import MaintenanceManagement from "./maintenanceManagement";
import MaintenanceFilter from "./Filters/maintenanceFilter";

const Dashboard = () => {
  const t = useSelector(selectTranslations);

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });
  const [createSelectedDates, setCreateSelectedDates] = useState([]);
  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }
    });
  const [customSelectedDates, setCustomSelectedDates] = useState([]);
  const [currentTime, setCurrentTime] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  const [filterOptions, setFilterOptions] = useState({
    dueDate: [],
    people: [],
    location: [],
    orderType: [""],
    categories: [],
    asset: [],
    priority: [],
    customer: [],
    teams: [],
    area: [],
  });

  const [filtersTrigger, setFiltersTrigger] = useState({
    assigneeType: "people",
    people: {
      initOptions: [],
      options: [],
    },
    area: {
      initOptions: [],
      options: [],
    },
    orderType: {
      options: [
        {
          key: 1,
          text: t.common.all,
          value: "",
          color: "",
        },
        {
          key: 2,
          text: t.workOrders.form.order_type_1,
          value: "reactive",
        },
        {
          key: 2,
          text: t.workOrders.form.order_type_2,
          value: "scheduled",
        },
      ],
    },
    location: {
      initOptions: [],
      options: [],
    },

    priority: {
      options: [
        {
          key: 0,
          text: t.common.all,
          value: "",
          color: null,
        },
        {
          key: 1,
          text: t.workOrders.priority_status.low,
          value: "low",
          color: "blue",
        },
        {
          key: 2,
          text: t.workOrders.priority_status.medium,
          value: "medium",
          color: "orange",
        },
        {
          key: 3,
          text: t.workOrders.priority_status.high,
          value: "high",
          color: "red",
        },
      ],
    },

    dueDate: {
      options: [
        {
          key: "1",
          value: "lastWeek",
          text: t.dashboard.filter.week,
        },
        {
          key: "2",
          value: "lastMonth",
          text: t.dashboard.filter.month,
        },
        {
          key: "3",
          value: "lastSixMonth",
          text: t.dashboard.filter.month_6,
        },
        {
          key: "4",
          value: "lastYear",
          text: t.dashboard.filter.year,
        },

        // {
        //   key: '5',
        //   value: 'date',
        //   text: t.dashboard.filter.date,
        // },
      ],

      initOptions: [
        {
          key: "1",
          value: "lastWeek",
          text: t.dashboard.filter.week,
        },
        {
          key: "2",
          value: "lastMonth",
          text: t.dashboard.filter.month,
        },
        {
          key: "3",
          value: "lastSixMonth",
          text: t.dashboard.filter.month_6,
        },
        {
          key: "4",
          value: "lastYear",
          text: t.dashboard.filter.year,
        },
      ],
    },

    categories: {
      options: [
        {
          key: 0,
          value: "damage",
          text: t.workOrders.form.category_options[0],
          icon: <Damage />,
        },
        {
          key: 2,
          value: "corrective action Downtime",
          text: t.workOrders.form.category_options[1],
          icon: <CorrectiveIcon />,
        },
        {
          key: 4,
          value: "planned Downtime",
          text: t.workOrders.form.category_options[2],
          icon: <PlannedIcon />,
        },
        {
          key: 10,
          value: "unplanned Downtime",
          text: t.workOrders.form.category_options[3],
          icon: <UnPlannedIcon />,
        },
        {
          key: 11,
          value: "inspection",
          text: t.workOrders.form.category_options[4],
          icon: <CategoryInspection />,
        },
        {
          key: 1,
          value: "electrical",
          text: t.workOrders.form.category_options[5],
          icon: <CategoryElectrical />,
        },

        {
          key: 3,
          value: "mechanical",
          text: t.workOrders.form.category_options[6],
          icon: <CategoryMechanical />,
        },

        {
          key: 6,
          value: "HVAC",
          text: t.workOrders.work_order_category.HVAC,
          icon: <CategoryRefrigeration />,
        },
        {
          key: 16,
          value: "preventive maintenance",
          text: t.workOrders.form.category_options[8],
          icon: <CatgoryPreventive />,
        },
        {
          key: 17,
          value: "project",
          text: t.workOrders.form.category_options[9],
          icon: <Project />,
        },
        {
          key: 7,
          value: "safety",
          text: t.workOrders.form.category_options[10],
          icon: <CategorySafety />,
        },
        {
          key: 27,
          value: "sop",
          text: t.workOrders.form.category_options[11],
          icon: <OperationProcedure />,
        },
      ],
      initOptions: [
        {
          key: 0,
          value: "damage",
          text: t.workOrders.form.category_options[0],
          icon: <Damage />,
        },
        {
          key: 2,
          value: "corrective action Downtime",
          text: t.workOrders.form.category_options[1],
          icon: <CorrectiveIcon />,
        },
        {
          key: 4,
          value: "planned Downtime",
          text: t.workOrders.form.category_options[2],
          icon: <PlannedIcon />,
        },
        {
          key: 10,
          value: "unplanned Downtime",
          text: t.workOrders.form.category_options[3],
          icon: <UnPlannedIcon />,
        },
        {
          key: 11,
          value: "inspection",
          text: t.workOrders.form.category_options[4],
          icon: <CategoryInspection />,
        },
        {
          key: 1,
          value: "electrical",
          text: t.workOrders.form.category_options[5],
          icon: <CategoryElectrical />,
        },

        {
          key: 3,
          value: "mechanical",
          text: t.workOrders.form.category_options[6],
          icon: <CategoryMechanical />,
        },

        {
          key: 6,
          value: "HVAC",
          text: t.workOrders.work_order_category.HVAC,
          icon: <CategoryRefrigeration />,
        },
        {
          key: 16,
          value: "preventive maintenance",
          text: t.workOrders.form.category_options[8],
          icon: <CatgoryPreventive />,
        },
        {
          key: 17,
          value: "project",
          text: t.workOrders.form.category_options[9],
          icon: <Project />,
        },
        {
          key: 7,
          value: "safety",
          text: t.workOrders.form.category_options[10],
          icon: <CategorySafety />,
        },
        {
          key: 27,
          value: "sop",
          text: t.workOrders.form.category_options[11],
          icon: <OperationProcedure />,
        },
      ],
    },

    asset: {
      initOptions: [],
      options: [],
    },
    customer: {
      initOptions: [],
      options: [],
    },

    teams: {
      initOptions: [],
      options: [],
    },
  });

  const [otherFilter, setOtherFilter] = useState({
    period: true,
    location: true,
    orderType: true,
    people: true,
    category: true,
    asset: false,
    teams: false,
    date: false,
    priority: false,
    customer: false,
    area: false,
    refresh: "",
    completed_date: "",
    progress_date: "",
    review_date: "",
    cancel_date: "",
    updated_at: "",
  });

  const [filterOptionsAsset, setFilterOptionsAsset] = useState({
    dueDate: [],
    status: [],
    people: [],
    location: [],
    orderType: [""],
    categories: [],
    asset: [],
    priority: [],
    customer: [],
    vendor: [],
    manufacturer: [],
    teams: [],
    area: [],
  });

  const [filterOptionsMaintenance, setFilterOptionsMaintenance] = useState({
    people: [],
    teams: [],
    location: [],
    categories: [],
    asset: [],
  });

  const [createSelectedDatesAsset, setCreateSelectedDatesAsset] = useState([]);
  const [completedDate, setCompletedDate] = useState([]);
  const [progressDate, setProgressDate] = useState([]);
  const [reviewDate, setReviewDate] = useState([]);
  const [updatedDates, setUpdatedSelectedDates] = useState([]);
  const [updatedDatesWO, setUpdatedSelectedDatesWO] = useState([]);
  const [cancelledDate, setCancelledDate] = useState([]);
  const [customSelectedDatesAsset, setCustomSelectedDatesAsset] = useState([]);
  const [warrentySelectedDatesAsset, setWarrentySelectedDatesAsset] = useState(
    []
  );
  const [currentTimeAsset, setCurrentTimeAsset] = useState("");
  const [currentTimeMaintenance, setCurrentTimeMaintenance] = useState("");
  const [otherFilterAsset, setOtherFilterAsset] = useState({
    period: true,
    location: true,
    category: true,
    asset: true,
    status: true,
    people: false,
    teams: false,
    vendor: false,
    customer: false,
    manufacturer: false,
    warranty_date: false,
    date: false,
    refresh: "",
    created_at: false,
    updated_at: false,
  });
  const [otherFilterMaintenance, setOtherFilterMaintenance] = useState({
    location: true,
    category: true,
    asset: true,
    people: true,
    teams: true,
    refresh: "",
  });

  const [filtersTriggerAsset, setFiltersTriggerAsset] = useState({
    assigneeType: "people",
    people: {
      initOptions: [],
      options: [],
    },
    area: {
      initOptions: [],
      options: [],
    },
    orderType: {
      options: [
        {
          key: 1,
          text: t.common.all,
          value: "",
          color: "",
        },
        {
          key: 2,
          text: t.workOrders.form.order_type_1,
          value: "reactive",
        },
        {
          key: 2,
          text: t.workOrders.form.order_type_2,
          value: "scheduled",
        },
      ],
    },
    location: {
      initOptions: [],
      options: [],
    },
    status: {
      initOptions: [],
      options: [
        {
          key: "status0",
          value: "",
          text: t.common.all,
        },
        {
          key: "status1",
          value: "active",
          text: t.assets.active,
        },
        {
          key: "status2",
          value: "inactive",
          text: t.assets.inActive,
        },
        {
          key: "status3",
          value: "dispose",
          text: t.assets.disposed_asset,
        },
      ],
    },

    priority: {
      options: [
        {
          key: 0,
          text: t.common.all,
          value: "",
          color: null,
        },
        {
          key: 1,
          text: t.workOrders.priority_status.low,
          value: "low",
          color: "blue",
        },
        {
          key: 2,
          text: t.workOrders.priority_status.medium,
          value: "medium",
          color: "orange",
        },
        {
          key: 3,
          text: t.workOrders.priority_status.high,
          value: "high",
          color: "red",
        },
      ],
    },

    dueDate: {
      options: [
        {
          key: "1",
          value: "lastWeek",
          text: t.dashboard.filter.week,
        },
        {
          key: "2",
          value: "lastMonth",
          text: t.dashboard.filter.month,
        },
        {
          key: "3",
          value: "lastSixMonth",
          text: t.dashboard.filter.month_6,
        },
        {
          key: "4",
          value: "lastYear",
          text: t.dashboard.filter.year,
        },

        // {
        //   key: '5',
        //   value: 'date',
        //   text: t.dashboard.filter.date,
        // },
      ],

      initOptions: [
        {
          key: "1",
          value: "lastWeek",
          text: t.dashboard.filter.week,
        },
        {
          key: "2",
          value: "lastMonth",
          text: t.dashboard.filter.month,
        },
        {
          key: "3",
          value: "lastSixMonth",
          text: t.dashboard.filter.month_6,
        },
        {
          key: "4",
          value: "lastYear",
          text: t.dashboard.filter.year,
        },
      ],
    },

    categories: {
      options: [],
      initOptions: [],
    },

    asset: {
      initOptions: [],
      options: [],
    },
    customer: {
      initOptions: [],
      options: [],
    },
    vendor: {
      initOptions: [],
      options: [],
    },
    manufacturer: {
      initOptions: [],
      options: [],
    },

    teams: {
      initOptions: [],
      options: [],
    },
  });

  const [filtersTriggerMaintenance, setFiltersTriggerMaintenance] = useState({
    people: {
      initOptions: [],
      options: [],
    },
    location: {
      initOptions: [],
      options: [],
    },

    categories: {
      options: [],
      initOptions: [],
    },

    asset: {
      initOptions: [],
      options: [],
    },

    teams: {
      initOptions: [],
      options: [],
    },
  });

  const hasNoFilterValues = () => {
    return Object.values(filterOptions).every(
      (value) =>
        (Array.isArray(value) &&
          value.every((item) => item === "" || item.length === 0)) || // Check if all arrays are empty or only contain empty strings
        value === ""
    );
  };

  const hasNoFilterValuesAsset = () => {
    return Object.values(filterOptionsAsset).every(
      (value) =>
        (Array.isArray(value) &&
          value.every((item) => item === "" || item.length === 0)) || // Check if all arrays are empty or only contain empty strings
        value === ""
    );
  };

  const hasNoFilterValuesMaintenance = () => {
    return Object.values(filterOptionsMaintenance).every(
      (value) =>
        (Array.isArray(value) &&
          value.every((item) => item === "" || item.length === 0)) || // Check if all arrays are empty or only contain empty strings
        value === ""
    );
  };

  const panes = [
    {
      menuItem: t.dashboard.tabs.WO_management,
      pane: {
        key: "workorder-management",
        content: (
          <div className="pane-body">
            <WorkOrderManagement
              createSelectedDates={createSelectedDates}
              completedDate={completedDate}
              progressDate={progressDate}
              reviewDate={reviewDate}
              updatedDates={updatedDatesWO}
              cancelledDate={cancelledDate}
              setCustomSelectedDates={setCustomSelectedDates}
              customSelectedDates={customSelectedDates}
              setCreateSelectedDates={setCreateSelectedDates}
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              setFiltersTrigger={setFiltersTrigger}
              filtersTrigger={filtersTrigger}
              setOtherFilter={setOtherFilter}
              otherFilter={otherFilter}
              hasNoFilterValues={hasNoFilterValues}
            />
            {/* <Details
                  untoggle={untoggle}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  data={data}
                /> */}
          </div>
        ),
      },
    },

    {
      menuItem: t.dashboard.tabs.assets_management,
      pane: {
        key: "assets-management",
        content: (
          <div className="pane-body">
            <AssetsManagement
              hasNoFilterValues={hasNoFilterValuesAsset}
              createSelectedDates={createSelectedDatesAsset}
              setCurrentTime={setCurrentTimeAsset}
              currentTime={currentTimeAsset}
              setCustomSelectedDates={setCustomSelectedDatesAsset}
              setWarrentySelectedDates={setWarrentySelectedDatesAsset}
              warrentySelectedDates={warrentySelectedDatesAsset}
              customSelectedDates={customSelectedDatesAsset}
              setCreateSelectedDates={setCreateSelectedDatesAsset}
              setUpdatedSelectedDates={setUpdatedSelectedDates}
              updatedDates={updatedDates}
              setFilterOptions={setFilterOptionsAsset}
              filterOptions={filterOptionsAsset}
              setFiltersTrigger={setFiltersTriggerAsset}
              filtersTrigger={filtersTriggerAsset}
              setOtherFilter={setOtherFilterAsset}
              otherFilter={otherFilterAsset}
            />
          </div>
        ),
      },
    },

    {
      menuItem: t.dashboard.tabs.maintenance_management,
      pane: {
        key: "maintenance-management",
        content: (
          <div className="pane-body">
            <MaintenanceManagement hasNoFilterValues={hasNoFilterValuesMaintenance} />
          </div>
        ),
      },
    },

    // {
    //   menuItem: t.dashboard.tabs.inventory_management,
    //   pane: {
    //     key: 'inverntry-management',
    //     content: (
    //       <div className="pane-body">
    //         <InventryManagement />
    //       </div>
    //     ),
    //   },
    // },
    // {
    //   menuItem: t.dashboard.tabs.recource_planner,
    //   pane: {
    //     key: 'resource-planner',
    //     content: (
    //       <div className="pane-body">
    //         <ResourcePlanner />
    //       </div>
    //     ),
    //   },
    // },
    // {
    //   menuItem: t.dashboard.tabs.timesheet,
    //   pane: {
    //     key: 'timesheet',
    //     content: (
    //       <div className="pane-body">
    //         <Timesheet />
    //       </div>
    //     ),
    //   },
    // },
    // {
    //   menuItem: t.dashboard.tabs.cost,
    //   pane: {
    //     key: 'cost',
    //     content: (
    //       <div className="pane-body">
    //         <Cost />
    //       </div>
    //     ),
    //   },
    // },
  ];

  return (
    <>
      <div
        style={{
          padding: "22px 16px",
          position: "sticky",
          zIndex: "50",
          top: "0px",
          background: "#f9fcff",
        }}
        className="table-header-dashbaord   stick-header"
      >
        <div
          style={{
            position: "sticky",
            zIndex: "50",
            top: "0px",
            marginBottom: "11px",
          }}
          className="header-actions"
        >
          <Header className="procedure-title mt-5" as="h2">
            {" "}
            <DashboardrIcon />
            {t.dashboard.title}
          </Header>
          <div></div>

          {/* {userPermissions?.procedure?.create && initData.length > 0 ? (
    <Button
      icon="add"
      primary
      onClick={() => {
        setToggleAsideModal(true);
      }}
    >
      {t.workOrders.create_WO}
      <ProcedurePlusIcon />
    </Button>
  ) : null} */}
        </div>

        <Button.Group
          style={{ width: "50%", position: "sticky", zIndex: "50" }}
          className="add-people-btn"
          basic
          fluid
          // style={{  }}
          size="medium"
        >
          <Button
            active={activeIndex == 0}
            onClick={() => {
              setActiveIndex(0);
            }}
            type="button"
          >
            {t.dashboard.tabs.WO_management}
          </Button>

          <Button
            className="add-people-tab"
            active={activeIndex == 1}
            onClick={() => {
              setActiveIndex(1);
            }}
            type="button"
          >
            {t.dashboard.tabs.assets_management}
          </Button>

          <Button
            className="add-people-tab"
            active={activeIndex == 2}
            onClick={() => {
              setActiveIndex(2);
            }}
            type="button"
          >
            {t.dashboard.tabs.maintenance_management}
          </Button>
        </Button.Group>
        <Divider className="mt-0" />

        <div className="sticky-filter mt-8">
          {activeIndex == 0 ? (
            <WorkOrderFilter
              completedDate={completedDate}
              allIds={allIds}
              role={role}
              progressDate={progressDate}
              reviewDate={reviewDate}
              updatedDates={updatedDatesWO}
              cancelledDate={cancelledDate}
              setCompletedDate={setCompletedDate}
              setProgressDate={setProgressDate}
              setReviewDate={setReviewDate}
              setUpdatedSelectedDates={setUpdatedSelectedDatesWO}
              setCancelledDate={setCancelledDate}
              hasNoFilterValues={hasNoFilterValues}
              createSelectedDates={createSelectedDates}
              setCustomSelectedDates={setCustomSelectedDates}
              customSelectedDates={customSelectedDates}
              setCreateSelectedDates={setCreateSelectedDates}
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              setFiltersTrigger={setFiltersTrigger}
              filtersTrigger={filtersTrigger}
              setOtherFilter={setOtherFilter}
              otherFilter={otherFilter}
              setCurrentTime={setCurrentTime}
              currentTime={currentTime}
            />
          ) : activeIndex == 1 ? (
            <AssetFilter
              hasNoFilterValues={hasNoFilterValuesAsset}
              allIds={allIds}
              role={role}
              createSelectedDates={createSelectedDatesAsset}
              setCurrentTime={setCurrentTimeAsset}
              setUpdatedSelectedDates={setUpdatedSelectedDates}
              updatedDates={updatedDates}
              currentTime={currentTimeAsset}
              setCustomSelectedDates={setCustomSelectedDatesAsset}
              setWarrentySelectedDates={setWarrentySelectedDatesAsset}
              warrentySelectedDates={warrentySelectedDatesAsset}
              customSelectedDates={customSelectedDatesAsset}
              setCreateSelectedDates={setCreateSelectedDatesAsset}
              setFilterOptions={setFilterOptionsAsset}
              filterOptions={filterOptionsAsset}
              setFiltersTrigger={setFiltersTriggerAsset}
              filtersTrigger={filtersTriggerAsset}
              setOtherFilter={setOtherFilterAsset}
              otherFilter={otherFilterAsset}
            />
          ) : activeIndex == 2 ? (
            <MaintenanceFilter
              hasNoFilterValues={hasNoFilterValuesAsset}
              allIds={allIds}
              role={role}
              setCurrentTime={setCurrentTimeMaintenance}
              currentTime={currentTimeMaintenance}
              setFilterOptions={setFilterOptionsMaintenance}
              filterOptions={filterOptionsMaintenance}
              setFiltersTrigger={setFiltersTriggerMaintenance}
              filtersTrigger={filtersTriggerMaintenance}
              setOtherFilter={setOtherFilterMaintenance}
              otherFilter={otherFilterMaintenance}
            />
          ) : null}
        </div>
      </div>
      <div className="new-listing-container pt-0">
        <div className="body">
          <Tab
            className="dashboard-tabs sticky-tabs"
            panes={panes}
            // onTabChange={handleTabChange}
            defaultActiveIndex={activeIndex == 1 ? 1 : 0}
            activeIndex={activeIndex ? activeIndex : 0}
            renderActiveOnly={false}
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
