import React, { useState, useEffect, createRef, useRef } from 'react';
import Moment from 'react-moment';
import {
  Image as SemanticImage,
  Grid,
  Header,
  Divider,
  Icon,
  Label,
  Image,
} from 'semantic-ui-react';

import { currencyDecimalPlaces, humanize, IMAGES_URL } from '../../../config/functions';
import QRCode from 'react-qr-code';

import md5 from 'md5';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../../components/common/userProfilePlaceholder';
import { selectTranslations } from '../../../config/i18n/slice';
import { useSelector } from 'react-redux';
import {
  CloseIcon,
  FileDownloadIcon,
  Printer,
  TextSnippet,
} from '../../../../Assets/Icons/svg';
import ToolTip from 'react-power-tooltip';
import QRComponentToPrint from './qrCodeComponent';
import moment from 'moment';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';

function Details({ data }) {
  const t = useSelector(selectTranslations);
  const { userPermissions, checkForLinkPermission } = usePermissionsSimplified();

  const partTypeOptions = [
    {
      key: 0,
      text: t.common.all,
      value: '',
      color: '',
    },
    {
      key: 1,
      text: t.parts.type.critical,
      value: 'critical',
    },
    {
      key: 2,
      text: t.parts.type.non_critical,
      value: 'non-critical',
    },
  ];

  const qrRef = createRef();
  const history = useHistory();
  const [fetching, setFetchLoading] = useState(true);
  const [qrImage, setQrImage] = useState(null);

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const [showPrintTooltip, setPrintTooltip] = useState(false);
  const [showDownloadTooltip, setDownloadTooltip] = useState(false);

  useEffect(() => {
    const svg = document.getElementById('QRCodeDownloadablePart' + data.id);
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');
        setQrImage(pngFile);
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
  }, [data]);


  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);
    
    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf('.') !== -1) {
      return formattedValue.replace(/\.?0+$/, '');
    }
  
    return Number(formattedValue);
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

/*
 * The `renderFile` function renders a file element with a clickable link to open the file in a new tab.
 */
  const renderFile = (file, index) => (
    <div
      className={`image-dropzone file d-flex justify-content-between text-center ${
        index == 0 ? '' : 'mt-5'
      }`}
      onClick={() => {
        window.open(file?.attributes?.url, '_blank');
      }}
    >
      <div>
        <label style={{ fontSize: '16px' }} className="mb-0">
          <span
            className={`${
              selectedLanguage == 'en' ? 'ml-5 mr-8' : 'ml-8 mr-5'
            }`}
          >
            <TextSnippet />
          </span>
          {console.log('file: ', file)}
          {file?.attributes?.name}
        </label>
      </div>

      <div className="mr-5 ml-5 mt-1 c-pointer"></div>
    </div>
  );
/*
 * The function `renderAssigneePeople` takes an array of assignee people objects, checks if they have a profile image URL, and renders their names with optional links based on permissions.
 */
  const renderAssigneePeople = (assigneePeople) => {
    if (assigneePeople?.length > 0) {
      return assigneePeople.map((people, index) => {
        if (people?.attributes?.profileImage?.data?.attributes?.url) {
          return (
            <p>
              <Image
                key={index}
                avatar
                title={`${people.attributes.firstName} ${people.attributes.lastName}`}
                src={`${people?.attributes?.profileImage?.data?.attributes?.url}`}
                style={
                  index !== 0
                    ? selectedLanguage == "ar" ?  { right: '-6px', width: '34px', height: '34px' } :{ left: '-6px', width: '34px', height: '34px' }
                    : { width: '34px', height: '34px' }
                }
              />
              {checkForLinkPermission("people") ? (
                <span
                  onClick={() => history.push(`/people?id=${people.attributes?.countId}`)}
                  className="detail-intro-link ml-1 mr-1"
                >
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              ) : (
                <span className=" ml-1 mr-1">
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              )}
            </p>
          );
        } else {
          return (
            <p>
              <UserProfilePlaceholder
                key={index}
                name={`${
                  people?.attributes?.firstName
                    ? people?.attributes?.firstName
                    : ''
                } ${
                  people?.attributes?.lastName
                    ? people?.attributes?.lastName
                    : ''
                }`}
                width={'34px'}
                height={'34px'}
                right={selectedLanguage == "en" ?  '6px' : "-5px"}
              />
              {checkForLinkPermission("people") ? (
                <span
                  onClick={() => history.push(`/people?id=${people.attributes?.countId}`)}
                  className="detail-intro-link "
                >
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              ) : (
                <span className="">
                  {people.attributes.firstName +
                    ' ' +
                    people.attributes.lastName}
                </span>
              )}
            </p>
          );
        }
      });
    } else {
      return t.common.na;
    }
  };

  const partTypeOptionsLabel = partTypeOptions.find(
    (status) => status?.value === data?.attributes?.type
  );

  return (
    <div className="preview-details-wrapper">
      <div className="data-header mt-5">
        <div className="header-title d-flex justify-content-between align-items-start">
          <div className="header-pill-container d-flex align-items-baseline">
            <p className="detail-title">
              {data?.attributes?.name}
            </p>
          </div>
        </div>

        <div   style={
            data?.attributes?.logo?.data?.attributes
              ? { borderRadius: "8px", background: "lightgrey" }
              : {}
          } className="d-flex justify-content-center align-items-center mt-2">
          <SemanticImage
            src={
              data?.attributes.logo?.data?.attributes
                ? data.attributes.logo.data.attributes.url
                : toAbsoluteUrl('/media/default-img.png')
            }
            size="medium"
            className={
              data?.attributes?.logo?.data?.attributes
                ? " data-header-image"
                : "no-img"
            }
          />
        </div>

        <div className="header-title mt-5 d-flex justify-content-between align-items-start">
          <div className="header-pill-container d-flex align-items-baseline">
            <label className="workorder-intro-label part-label">
              {t.workOrders.form.description}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {data?.attributes?.description
                ? data?.attributes?.description
                : t.common.na}
            </p>
          </div>
        </div>
      </div>

      <div className="details-wrapper">
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label">
            {t.assets.form.category}
            {':'}
          </label>
          <p className={`workorder-intro-value`}>
            {data?.attributes?.category?.data?.attributes?.name || t.common.na}
          </p>
        </div>   
        {/* <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label">
            {t.parts.form.unit_cost}
            {':'}
          </label>
          <p className={`workorder-intro-value`}>
            {data?.attributes?.unitCost ? `${handleDecimalPoints(data?.attributes?.unitCost)} ${organisationCurrency}` : t.common.na}
          </p>
        </div> */}
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label">
            {t.parts.filter.partType}
            {':'}
          </label>
          <p
            className={`workorder-intro-value ${
              data?.attributes?.type == 'critical' ? 'text-danger' : ''
            }`}
          >
            {partTypeOptionsLabel?.text || t.common.na}
          </p>
        </div>

        <Divider />

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label">
            {t.workRequests.form.assets}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.assets?.data?.length
              ? data.attributes.assets.data.map((asset, index) => (
                  <React.Fragment key={index}>
                    {checkForLinkPermission("asset") ? (
                      <span
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == 'en' ? 'text-left' : 'text-right'
                        }`}
                        basic
                        onClick={() => history.push(`/assets?id=${asset?.attributes?.countId}`)}
                      >
                        {asset.attributes.name}
                      </span>
                    ) : (
                      <span className=" ml-1 mr-1">
                        {asset?.attributes?.name}
                      </span>
                    )}
                    {index !== data.attributes.assets.data.length - 1 && ', '}
                  </React.Fragment>
                ))
              : t.common.na}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label">
            {t.parts.table.vendor}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.vendors?.data?.length
              ? data.attributes.vendors.data.map((asset, index) => (
                  <React.Fragment key={index}>
                    {checkForLinkPermission("vendor")? (
                      <span
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == 'en' ? 'text-left' : 'text-right'
                        }`}
                        basic
                        onClick={() => history.push(`/vendors?id=${asset?.attributes?.countId}`)}
                      >
                        {asset.attributes.name}
                      </span>
                    ) : (
                      <span className=" ml-1 mr-1">
                        {asset?.attributes?.name}
                      </span>
                    )}
                    {index !== data.attributes.vendors.data.length - 1 && ', '}
                  </React.Fragment>
                ))
              : t.common.na}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label">
            {t.workRequests.form.people}
            {':'}
          </label>
          <p
            className="workorder-intro-value"
            style={
              data?.attributes?.people?.data.length > 0
                ? { marginTop: '-6px' }
                : {}
            }
          >
            {renderAssigneePeople(data?.attributes?.people?.data)}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label">
            {t.workRequests.form.teams}
            {':'}
          </label>
          {data?.attributes?.teams?.data?.id ? (
            <p
              className={`workorder-intro-value mb-1 ${
                selectedLanguage == 'en' ? 'text-left' : 'text-right'
              }`}
              basic
            >
              {checkForLinkPermission("team") ? (
                <span
                  onClick={() =>
                    history.push(
                      `/teams?id=${data?.attributes?.teams?.data?.attributes?.countId}`
                    )
                  }
                  className="detail-intro-link"
                >
                  {data?.attributes?.teams?.data?.attributes?.name}
                </span>
              ) : (
                <span className=" ml-1 mr-1">
                  {data?.attributes?.teams?.data?.attributes?.name}
                </span>
              )}
            </p>
          ) : (
            <p className="workorder-intro-value">{t.common.na}</p>
          )}
        </div>


        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label">
          {t.parts.tab.second}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.sets?.data?.length
              ? data.attributes.sets.data.map((asset, index) => (
                  <React.Fragment key={index}>
                    {checkForLinkPermission("part") ? (
                      <span
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == 'en' ? 'text-left' : 'text-right'
                        }`}
                        basic
                        onClick={() => history.push(`/sets?id=${asset.attributes?.countId}`)}
                      >
                        {asset.attributes.name}
                      </span>
                    ) : (
                      <span className=" ml-1 mr-1">
                        {asset?.attributes?.name}
                      </span>
                    )}
                    {index !== data.attributes.sets.data.length - 1 && ', '}
                  </React.Fragment>
                ))
              : t.common.na}
          </p>
        </div>

        <Divider />
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label">
            {t.parts.table.serialNumber}
            {':'}
          </label>
          <p className="workorder-intro-value">
            {data?.attributes?.serialNumber
              ? data?.attributes?.serialNumber
              : t.common.na}
          </p>
        </div>

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label part-label ">
          {data?.attributes?.qrcode
            ? t.assets.filter.qrcode
            : t.assets.filter.barcode}
            {":"}
          </label>
          <p className="workorder-intro-value set-width">
            <div className="d-flex">
            <div>
            <QRComponentToPrint
              isQRcode={data?.attributes?.qrcode}
              ref={qrRef}
              data={data}
              titleData={t.assets.form.serial_number}
              titleName={t.assets.table.name}
              part={true}
            />
          </div>
          <div className="m-3">
            <ReactToPrint
              trigger={() => (
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setPrintTooltip(true)}
                  onMouseLeave={() => setPrintTooltip(false)}
                >
                  <Printer />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top center"
                    show={showPrintTooltip}
                    textBoxWidth="35px"
                    arrowAlign="center"
                    fontSize="9px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="5px 5px 5px 8px"
                    borderRadius="6px"
                    static
                    moveUp="5px"
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.print}
                    </span>
                  </ToolTip>
                </span>
              )}
              content={() => {
                return qrRef.current;
              }}
            />
            <span
              className="ml-2 mr-2 c-pointer"
              style={{ position: 'relative' }}
              onMouseOver={() => setDownloadTooltip(true)}
              onMouseLeave={() => setDownloadTooltip(false)}
              onClick={() => {
                qrRef.current.exposeDownloadFunction();
              }}
            >
              <FileDownloadIcon />
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position="top center"
                show={showDownloadTooltip}
                textBoxWidth="92px"
                arrowAlign="center"
                fontSize="9px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="5px 5px 5px 8px"
                borderRadius="6px"
                static
                moveUp="5px"
              >
                <span className="work-order-preview-tooltip">
                  {t.common.download_QR}
                </span>
              </ToolTip>
            </span>
          </div>
            </div>
          </p>
        </div>

        {/* <p className="detail-intro asset-info mt-4">
          {data?.attributes?.qrcode
            ? t.assets.filter.qrcode
            : t.assets.filter.barcode}
        </p>
        <div
          style={data?.attributes?.qrcode ? {} : { marginTop: '-11px' }}
          className={`d-flex`}
        >
          <div>
            <QRComponentToPrint
              isQRcode={data?.attributes?.qrcode}
              ref={qrRef}
              data={data}
              titleData={t.assets.form.serial_number}
              titleName={t.assets.table.name}
              part={true}
            />
          </div>
          <div className="m-3">
            <ReactToPrint
              trigger={() => (
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setPrintTooltip(true)}
                  onMouseLeave={() => setPrintTooltip(false)}
                >
                  <Printer />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top center"
                    show={showPrintTooltip}
                    textBoxWidth="35px"
                    arrowAlign="center"
                    fontSize="9px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="5px 5px 5px 8px"
                    borderRadius="6px"
                    static
                    moveUp="5px"
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.print}
                    </span>
                  </ToolTip>
                </span>
              )}
              content={() => {
                return qrRef.current;
              }}
            />
            <span
              className="ml-2 mr-2 c-pointer"
              style={{ position: 'relative' }}
              onMouseOver={() => setDownloadTooltip(true)}
              onMouseLeave={() => setDownloadTooltip(false)}
              onClick={() => {
                qrRef.current.exposeDownloadFunction();
              }}
            >
              <FileDownloadIcon />
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position="top center"
                show={showDownloadTooltip}
                textBoxWidth="92px"
                arrowAlign="center"
                fontSize="9px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="5px 5px 5px 8px"
                borderRadius="6px"
                static
                moveUp="5px"
              >
                <span className="work-order-preview-tooltip">
                  {t.common.download_QR}
                </span>
              </ToolTip>
            </span>
          </div>
        </div> */}

        <div className="mt-5">
          {data?.attributes?.files?.data?.map((file, index) =>
            renderFile(file, index)
          )}

          
        </div>

        <Divider />
        <div className="d-flex align-items-center mt-5">
          <label className="workorder-intro-label part-label  grey">
            {t.workOrders.table.createdByUser}
            {':'}
          </label>
          <p className="workorder-intro-value grey">
            {data?.attributes?.createdByUser?.data?.attributes?.firstName &&
            data?.attributes?.createdByUser?.data?.attributes?.lastName
              ? data?.attributes?.createdByUser?.data?.attributes?.firstName +
                ' ' +
                data?.attributes?.createdByUser?.data?.attributes?.lastName
              : t.common.na}
            {', '}
            <Moment format="DD-MM-YYYY LT" date={data?.attributes?.createdAt} />
          </p>
        </div>

        <div className="d-flex align-items-center mt-3">
          <label className="workorder-intro-label part-label grey">
            {t.common.update_by}
            {':'}
          </label>
          <p className="workorder-intro-value grey">
            {data?.attributes?.updatedByUser?.data
              ? data?.attributes?.updatedByUser?.data?.attributes?.firstName +
                ' ' +
                data?.attributes?.updatedByUser?.data?.attributes?.lastName +
                ', ' +
                moment(data?.attributes?.updatedAt).format('DD-MM-YYYY LT')
              : t.common.na}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Details;
