import React, { useEffect, useState } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import { useHistory } from "react-router";
import {
  AddIconDashboard,
  CostIconEstimate,
  DashboardWOStatusIcon,
  FullArrow,
  POPriceIcon,
  TimeIconEstimate,
} from "../../../../Assets/Icons/svg";
import Chart from "react-apexcharts";
import NoDashboardData from "../noDashboardData";
const PurchasePrice = ({
  chartDateOption,
  setMaintenanceData,
  maintenanceData,
  setChartDateOption,
  openModal,
  chartConfigPurchasePrice,
  setChartConfigPurchasePrice,
  dateDropdownOption,
  setDashBoardData,
  dashBoardData,
  setOpenModal,
  setModalData,
  removeTooltip,
  isHoverDisabled
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const history = useHistory();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  function handleAssetClick(index) {
    history.push(`/assets?id=${index}`);

    // You can add any logic you need here for when an asset is clicked
  }

  useEffect(() => {
    if (maintenanceData?.maintenanceVsPurchasePrice?.length > 0) {
      const totalPurchasePrice = maintenanceData.maintenanceVsPurchasePrice.map((item) => item?.totalPurchasePrice);
      const totalActualCost = maintenanceData.maintenanceVsPurchasePrice.map((item) => item?.totalActualCost);
      const labels = maintenanceData.maintenanceVsPurchasePrice.map((item) => item?.categoryName);

      setChartConfigPurchasePrice((prevState) => ({
        ...prevState,
        series: [
          {
            name: t.dashboard.maintenance_card.purchase_price,
            data: totalPurchasePrice, // Set y values
          },
          {
            name: t.dashboard.maintenance_card.maintenance_price,
            data: totalActualCost, // Set y values
          },
        ],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: labels, // Set label data
          },
          chart: {
            ...prevState.options.chart,
            //         events: {
              events: {
                dataPointSelection: function(
                  event,
                  chartContext,
                  { seriesIndex, dataPointIndex, w }
                ) {
                  isHoverDisabled = true;
        
                  const value = w.config.series[seriesIndex].data[dataPointIndex];
                  const label = w.config.xaxis.categories[dataPointIndex];
                  const selectedData = maintenanceData.maintenanceVsPurchasePrice[dataPointIndex];
                  const assets = selectedData.uniqueAssets;
                  const color = w.config.colors[seriesIndex];
                  const labelData =t.dashboard.asset_card.asset_name;
                  const purchase_price_label =t.dashboard.maintenance_card.purchase_price;
                  const maintenance_label =t.dashboard.maintenance_card.maintenance_price;
                  // Remove any existing click tooltip
                  removeTooltip();
        
                  // Create a new custom tooltip div
                  const tooltipDiv = document.createElement("div");
                  tooltipDiv.className = "click-tooltip";
                  tooltipDiv.style.position = "absolute";
                  tooltipDiv.style.background = "white";
                  tooltipDiv.style.boxShadow = "5px 4px 6px #00000026";
                  tooltipDiv.style.border = "1px solid white";
                  tooltipDiv.style.color = "black";
                  tooltipDiv.style.padding = "5px";
                  tooltipDiv.style.borderRadius = "8px";
                  tooltipDiv.style.zIndex = "1000";
        
                  tooltipDiv.innerHTML = `
                    <div style="display: flex; gap: 5px;">
                      <div style="display: flex; flex-direction: column; gap: 1px; align-items: start; border-right: 2px solid #E4E6EA; padding-right: 20px;">
                        <div style="font-size: 12px; font-weight: 500; color: #44546F;">${labelData}</div>
                                ${assets
                                 .map(
                                   (asset) => `
      <div   style="font-size: 12px; cursor:pointer; color: #0C66E4; font-weight: bold;" data-asset-id="${asset?.countId}">
        ${asset.assetName}
      </div>
    `
                                 )
                                 .join("")}
                      </div>
                    
                     <div style="display: flex; flex-direction: column; gap: 1px; align-items: start; border-right: 2px solid #E4E6EA; padding-right: 20px;">
                        <div style="font-size: 12px; font-weight: 500; color: #44546F;">${purchase_price_label}</div>
                        ${assets
                             .map(
                               (asset) => `
                          <div style="font-size: 12px; color: #172B4D; font-weight: 400;">${asset.purchasePrice}</div>`
                             )
                             .join("")}
                      </div>
                      <div style="display: flex; flex-direction: column; gap: 1px; align-items: start;">
                        <div style="font-size: 12px; font-weight: 500; color: #44546F;">${maintenance_label}</div>
                        ${assets
                             .map(
                               (asset) => `
                          <div style="font-size: 12px; color: #172B4D; font-weight: 400;">${asset.actualCost}</div>`
                             )
                             .join("")}
                      </div>
                    </div>
                  `;
        
                  // Position the tooltip near the click position
                  const { pageX, pageY } = event;
                  tooltipDiv.style.left = `${pageX + 10}px`;
                  tooltipDiv.style.top = `${pageY - 40}px`;
        
                  // Append the tooltip to the body
                  document.body.appendChild(tooltipDiv);
        
                  // Add event listeners to the tooltip for mouseenter and mouseleave
                  tooltipDiv.addEventListener("mouseenter", () => {
                    isHoverDisabled = true;  // Prevent removal when hovering over the tooltip
                  });
        
                  tooltipDiv.addEventListener("mouseleave", () => {
                    removeTooltip();
                    isHoverDisabled = false;  // Enable hover tooltips again when leaving the tooltip
                  });

                  tooltipDiv.addEventListener("click", function (e) {
                  
                    const assetId = e?.target?.getAttribute("data-asset-id");
                    if(assetId){
                      removeTooltip();
                      isHoverDisabled = false;
                      handleAssetClick(assetId);
                    }
                   
                 
                });
                },
        
                dataPointMouseLeave: function() {
                  // Do nothing here, removal will be handled by tooltip hover events
                },
              },
          },
        },
      }));
    }
  }, [maintenanceData]);
  return (
    <Grid.Column className="completion-column pl-0 pt-0">
      <div
        className={
          openModal
            ? "completion-column-data no-border"
            : "completion-column-data"
        }
      >
        <div className="completion-cotainer d-flex justify-content-between mt-2">
          <div className="completion-title d-flex">
            <span className="title">
              {t.dashboard.maintenance_card.purchase_price_title}
            </span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className={selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"}
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "en" ? "right" : "left"}
                show={showTooltip}
                textBoxWidth="140px"
                fontSize="10px"
                fontWeight="400"
                // fontFamily="Roboto"
                padding="0px 0px 0px 8px"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="8px"
                // moveDown="10px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.tooltip.cost}
                </span>
              </Tooltip>
              <DashboardWOStatusIcon />
            </p>
          </div>

          <span style={{ marginTop: "-10px", gap: "10px" }} className="d-flex">
            <span
              onClick={() => {
                setOpenModal(true);
                setModalData("purchasePrice");
                setShowExpandTooltip(false);
              }}
              style={{ marginTop: "2px" }}
              className=""
            >
              {openModal ? null : (
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowExpandTooltip(true)}
                  onMouseLeave={() => setShowExpandTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showExpandTooltip}
                    textBoxWidth="80px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="start"
                    moveRight="5px"
                    moveDown="3px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.expand_full}
                    </span>
                  </Tooltip>
                  <FullArrow />
                </p>
              )}
            </span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowAddCustomizedTooltip(true)}
              onMouseLeave={() => setShowAddCustomizedTooltip(false)}
              className=" mt-1"
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position="top"
                show={showAddCustomizedTooltip}
                textBoxWidth="105px"
                fontSize="10px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="8px 8px 8px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="0px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.add_customized}
                </span>
              </Tooltip>
              {/* <AddIconDashboard /> */}
            </p>
          </span>
        </div>
        {maintenanceData?.maintenanceVsPurchasePrice?.length > 0 ? (
          <div className="completion-status-part">
            <Grid className="wo-data-container" columns={1}>
              <Grid.Column className="wo-data-chart  align-items-center text-center">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <p
                      className="d-flex align-items-center"
                      style={
                        selectedLanguage == "en"
                          ? { fontSize: "14px", color: "#44546F", gap: "10px" }
                          : { fontSize: "13px", color: "#44546F", gap: "10px" }
                      }
                    >
                      <span
                        className={selectedLanguage == "en" ? "ml-6" : "mr-6"}
                      >
                        <POPriceIcon />
                      </span>
                      {t.dashboard.maintenance_card.purchase_price}
                      <POPriceIcon color="#5494EE" />
                      {t.dashboard.maintenance_card.maintenance_price}
                    </p>
                  </div>
                </div>
                <div
                  className="scroll-data res"
                  style={{
                    overflowX: "auto",
                    minWidth: "500px",
                    paddingLeft: "8px",
                  }}
                >
                  <div style={{ minWidth: "1000px" }}>
                    {" "}
                    {/* Adjust minWidth as needed */}
                    <Chart
                      options={chartConfigPurchasePrice?.options}
                      series={chartConfigPurchasePrice?.series}
                      type="bar"
                      height={openModal ? 400 : 277}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </div>
        ) : (
          <NoDashboardData height={"318px"} />
        )}
        {/* } */}
      </div>
    </Grid.Column>
  );
};

export default PurchasePrice;
