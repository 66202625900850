import React, { useEffect, useState, createRef } from 'react';
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Loader,
} from 'semantic-ui-react';

import DataTable from 'react-data-table-component';
import * as auth from "../../modules/Auth/_redux/authRedux";
import md5 from 'md5';

import Moment from 'react-moment';

import { NavLink } from 'react-router-dom';

import { useLocation, useHistory } from 'react-router';
import { toAbsoluteUrl, checkIsActive } from '../../../_metronic/_helpers';

import {
  currencyDecimalPlaces,
  getAllParts,
  getUserData,
  humanize,
  IMAGES_URL,
} from '../../config/functions';

import Add from './add';
import Edit from './edit';

import Preview from './preview/index';

import Filters from './filters';

import { Country, State, City } from 'country-state-city';

import { connect, useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import UserProfilePlaceholder, {
  AssetProfilePlaceholder,
  LetteredAvatarImageUrl,
} from '../../components/common/userProfilePlaceholder';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
import useGetCurrentTimeAccZone from '../../../hooks/useGetCurrentTimeAccZone';
import moment from 'moment';
import DateConvert from '../../../utilFunctions/DateFormat';
import {
  ArrowLeft,
  ArrowRight,
  CloseIcon,
  CreateIcon,
  PartIcon,
  PartsBlueIcon,
  PeopleIcon,
  ProcedurePlusIcon,
  SortIcon,
} from '../../../Assets/Icons/svg';
import {
  fetchAllPartsData,
  fetchPartsInitData,
  fetchPartsWithFilter,
} from '../../../redux/reducers/partReducer';
import Pagination from '../../components/common/CommonPagination';
import EmptyFilter from './emptyFilter';
import ToolTip from 'react-power-tooltip';
import { useDebounce } from 'use-debounce';
import Animation from '../../components/common/CustomLoader';

/* The above code defines a customStyles object contains styling properties for Data table
headCells and cells. */
const customStyles = {
  headCells: {
    style: {
      paddingTop: '11px', // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px', // override the cell padding for data cells
      paddingRight: '16px',
      paddingTop: '19px',
      paddingBottom: '19px',
    },
  },
};

const Parts = (props) => {
  const { userPermissions } = usePermissionsSimplified();
  const dispatch = useDispatch();

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });


  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

     /* The above code is iterating over each subLocation in the location object and checking if the
     subLocation's id is not already present in the allIds array. If the id is not already in the
     array, it is added to the allIds array. This code is essentially filtering out duplicate ids
     from the subLocations and adding unique ids to the allIds array. */
      // location.subLocations.forEach((subLocation) => {
      //   if (!allIds.some((inv) => inv == subLocation?.id)) {
      //     allIds.push(subLocation?.id);
      //   }
      // });
    });

  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [loadUserData, setLoadUserData] = useState(false);
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const [filterOptions, setFilterOptions] = useState({
    vendors: [],
    categories: [],
    teams: [],
    status: [''],
    partType: [''],
    assets: [],
    locations: [],
    area: [],
    serialNumber: '',
  });

  const [searchTitle, setSearchTitle] = useState('');
  const [debounceValue] = useDebounce(searchTitle, 500);

  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const partId = queryParams.get('id');
  const history = useHistory();

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [sortData, setSortData] = useState();
  const [fetching, setFetchLoading] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [expandedRow, setExpandedRow] = useState();
  const [targetedData, setTargetedData] = useState();
  const [loader, setloader] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [activeChecklistReport, setActiveChecklistReport] = useState({
    report: '',
    isOpen: false,
});

  const [activeInspectionReport, setActiveInspectionReport] = useState({
    report: '',
    isOpen: false,
  });

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  /*
   * The handleSort function is used to sort data based on a specified column and sort direction, triggering a fetch request with updated sorting parameters.
   */
  const handleSort = async (column, sortDirection) => {
    setloader(true);
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchPartsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        partType: filterOptions.partType,
        status: filterOptions.status,
        assets: filterOptions.assets,
        vendors: filterOptions.vendors,
        locations: filterOptions.locations,
        area: filterOptions.area,
        teams: filterOptions.teams,
        category: filterOptions.categories,
        serialNumber: filterOptions.serialNumber,
        sort: sort,
        role: role,
        allIds: allIds,
      })
    );

    setloader(false);
  };

  /* The function `handleRemoveSorting` for remove sorts data */
  const handleRemoveSorting = async () => {
    setloader(true);
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchPartsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        partType: filterOptions.partType,
        status: filterOptions.status,
        assets: filterOptions.assets,
        vendors: filterOptions.vendors,
        locations: filterOptions.locations,
        area: filterOptions.area,
        teams: filterOptions.teams,
        category: filterOptions.categories,
        serialNumber: filterOptions.serialNumber,
        sort: sort,
        role: role,
        allIds: allIds,
      })
    );

    setloader(false);
  };

  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf('.') !== -1) {
      return formattedValue.replace(/\.?0+$/, '');
    }

    return formattedValue;
  };

  const partTypeOptions = [
    {
      key: 0,
      text: t.common.all,
      value: '',
      color: '',
    },
    {
      key: 1,
      text: t.parts.type.critical,
      value: 'critical',
    },
    {
      key: 2,
      text: t.parts.type.non_critical,
      value: 'non-critical',
    },
  ];

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.parts.partsData,
    isLoading: state.parts.isLoading,
    error: state.parts.error,
    initData: state.parts.partsInitData,
    page: state.parts.page,
    pageSize: state.parts.pageSize,
    total: state.parts.total,
    initLoading: state.parts.initLoading,
  }));

  /* The above code is setting the data by fetching it from a source using the `fetchData`
  function. */
  useEffect(() => {
    const fetchData = async () => {
      setLoadUserData(true)
      if (!(role === "super_admin" || role === "admin")) {
       
        const userData = await getUserData(user.id);
      
        dispatch(props.fulfillUser(userData));
      }
      const fetchData = await dispatch(
        fetchPartsInitData({ organisationId, role, allIds })
      );
      setLoadUserData(false)
      if (fetchData) {
        setFetchLoading(false);
        if (partId) {
          const fetchAllData = await dispatch(
            fetchAllPartsData({ organisationId, role, allIds })
          );

          let selectedPart = fetchAllData.payload.response.find(
            (each) => each?.attributes?.countId == partId
          );
          if (selectedPart) {
            setTargetedData(selectedPart);
            setTogglePreviw(true);
          }
        }
      }
    };

    fetchData();
  }, [partId]);

  /* The above code defines an array of objects representing columns for a table. Each object in the
array represents a column in the table with properties such as width, name, sortField, sortable,
minWidth, and cell. The cell property contains a function that returns the content to be displayed
in the cell for each row of the table. The code also includes conditional rendering based on the
selected language and data attributes. Additionally, there are commented-out sections that seem to
be alternative implementations for rendering cell content based on certain conditions. */
  const columns = [
    {
      width: selectedLanguage === 'en' ? '126px' : '143px',
      name: t.assets.table.ID,
      sortField: 'countNumId',
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.attributes?.countId}
        </span>
      ),
    },
    {
      name: t.parts.table.name,
      sortField: 'name',
      sortable: true,
      minWidth: '180px',

      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
          className="id-text-style"
        >
          {row?.attributes?.name
            ? DisplayFormattedText(humanize(row?.attributes?.name), 16)
            : t.common.na}
        </span>
      ),
    },

    {
      minWidth: selectedLanguage === 'en' ? '134px' : '144px',
      sortField: 'type',
      name: t.parts.filter.partType,
      sortable: true,
      cell: (row) => {
        const partTypeOptionsLabel = partTypeOptions.find(
          (status) => status?.value === row?.attributes?.type
        );

        return (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className={row?.attributes?.type == 'critical' ? 'text-danger' : ''}
          >
            {partTypeOptionsLabel?.text || t.common.na}
          </span>
        );
      },
    },

    {
      minWidth: selectedLanguage === 'en' ? '160px' : '202px',
      name: t.parts.table.avg_unit_cost,
      sortField: 'avgUnitCost',
      sortable: true,
      cell: (row) => {
        return (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
          >
            {row?.attributes?.avgUnitCost
              ? handleDecimalPoints(row?.attributes?.avgUnitCost)
              : '0'}{' '}
            {organisationCurrency}
          </span>
        );
      },
    },
    {
      minWidth: selectedLanguage === 'en' ? '150px' : '192px',

      name: t.parts.table.qty_in_stock,
      sortField: 'qtyInStock',
      sortable: true,
      cell: (row) => {
        return (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
          >
            {row?.attributes?.qtyInStock ? row?.attributes?.qtyInStock : '0'}
          </span>
        );
      },
    },

    {
      minWidth: selectedLanguage === 'en' ? '150px' : '192px',

      name: t.parts.form.order_qty,
      sortField: 'totalOrderedQuantity',
      sortable: true,
      cell: (row) => {
        return (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
          >
            {row?.attributes?.totalOrderedQuantity
              ? row?.attributes?.totalOrderedQuantity
              : '0'}
          </span>
        );
      },
    },

    {
      minWidth: selectedLanguage === 'en' ? '153px' : '192px',

      name: t.parts.form.reserved_qty,
      sortField: 'totalReservedQuantity',
      sortable: true,
      cell: (row) => {
        return (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
          >
            {row?.attributes?.totalReservedQuantity
              ? row?.attributes?.totalReservedQuantity
              : '0'}
          </span>
        );
      },
    },

    {
      minWidth: selectedLanguage === 'en' ? '153px' : '192px',

      name: t.parts.form.avl_qty,
      sortField: 'totalAvailableQuantity',
      sortable: true,
      cell: (row) => {
        return (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
          >
            {row?.attributes?.totalAvailableQuantity
              ? row?.attributes?.totalAvailableQuantity
              : '0'}
          </span>
        );
      },
    },

    {
      minWidth: selectedLanguage === 'en' ? '160px' : '173px',
      name: t.parts.table.serialNumber,
      sortField: 'serialNumber',
      sortable: true,
      cell: (row) =>
        row.attributes?.serialNumber
          ? row.attributes?.serialNumber
          : t.common.na,
    },

    {
      name: t.parts.table.vendor,
      sortField: 'vendors.name',
      minWidth: '122px',

      sortable: true,
      cell: (row) => {
        const locationData = row?.attributes.vendors?.data;
        if (locationData?.length > 0) {
          const firstLocation = locationData[0]?.attributes?.name;
          const remainingCount = locationData.length - 1;
          const remainingLocations = locationData
            .slice(1)
            .map((location) => location.attributes.name)
            .join(", ");
          const displayText =
            remainingCount > 0
              ? `${firstLocation} ... +${remainingCount}`
              : firstLocation;
          return (
            <span
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              title={locationData
                .map((location) => location.attributes.name)
                .join(", ")}
            >
              {displayText}
            </span>
          );
          // const displayText = locationData
          //   .map((location) => {
          //     const nameParts = location?.attributes?.name;

          //     return nameParts;
          //   })
          //   .join(', '); // Adjust the join character as needed
          // return DisplayFormattedText(displayText, 20);
        } else {
          return t.common.na;
        }
      },
      // cell: (row) => {
      //   let assigneePeople = row?.attributes?.vendors?.data;
      //   let totalLength = row?.attributes?.vendors?.data
      //     ? row?.attributes?.vendors?.data.length
      //     : 0;

      //   if (assigneePeople?.length) {
      //     if (assigneePeople?.length < 3) {
      //       assigneePeople = assigneePeople.map((people, index) => {
      //         if (people?.attributes?.logo?.data?.attributes?.url) {
      //           {
      //             return (
      //               <Image
      //                 onClick={() => {
      //                   setTargetedData(row);
      //                   setTogglePreviw(true);
      //                 }}
      //                 avatar
      //                 title={people.attributes.name}
      //                 src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
      //                 style={
      //                   index != 0
      //                     ? {
      //                         marginLeft: '-7px',
      //                         width: '34px',
      //                         height: '34px',
      //                       }
      //                     : { width: '34px', height: '34px' }
      //                 }
      //                 className={index == 0 ? 'img-first' : ''}
      //               />
      //             );
      //           }
      //         } else {
      //           return (
      //             <div
      //               onClick={() => {
      //                 setTargetedData(row);
      //                 setTogglePreviw(true);
      //               }}
      //             >
      //               <AssetProfilePlaceholder
      //                 name={`${
      //                   people?.attributes?.name ? people?.attributes?.name : ''
      //                 }`}
      //                 width={'34px'}
      //                 height={'34px'}
      //                 right={index !== 0 ? '7px' : ''}
      //                 className={index == 0 ? 'img-first' : ''}
      //               />
      //             </div>
      //           );
      //         }
      //       });
      //     } else {
      //       assigneePeople = assigneePeople.slice(0, 2).map((people, index) => {
      //         if (people?.attributes?.logo?.data?.attributes?.url) {
      //           {
      //             return (
      //               <Image
      //                 onClick={() => {
      //                   setTargetedData(row);
      //                   setTogglePreviw(true);
      //                 }}
      //                 avatar
      //                 title={people.attributes.name}
      //                 src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
      //                 style={
      //                   index != 0
      //                     ? {
      //                         marginLeft: '-7px',
      //                         width: '34px',
      //                         height: '34px',
      //                       }
      //                     : { width: '34px', height: '34px' }
      //                 }
      //               />
      //             );
      //           }
      //         } else {
      //           return (
      //             // <div
      //             //   onClick={() => {
      //             //     setTargetedData(row);
      //             //     setTogglePreviw(true);
      //             //   }}
      //             // >
      //             <AssetProfilePlaceholder
      //               name={`${people?.attributes?.name}`}
      //               width={'34px'}
      //               height={'34px'}
      //               right={
      //                 selectedLanguage === 'ar' && index !== 0
      //                   ? '0px'
      //                   : index === 0 && selectedLanguage === 'en'
      //                   ? '-15px'
      //                   : '-9px'
      //               }
      //             />
      //             // </div>
      //           );
      //         }
      //       });
      //       assigneePeople.push(
      //         <Image
      //           onClick={(e) => {
      //             e.stopPropagation();
      //             setTargetedData(row);
      //             // setTargetedData(row);
      //             setTogglePreviw(true);
      //           }}
      //           avatar
      //           title={`${totalLength - 2}`}
      //           src={`https://ui-avatars.com/api/?name=${totalLength -
      //             2}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
      //           style={{
      //             width: '34px',
      //             height: '34px',
      //             right: '-3px',
      //           }}
      //         />
      //       );
      //     }
      //     return (
      //       <div
      //         onClick={() => {
      //           setTargetedData(row);
      //           setTogglePreviw(true);
      //         }}
      //         className={`multi-profile-container ${
      //           selectedLanguage == 'en' ? '' : 'rtl-body'
      //         }`}
      //       >
      //         {assigneePeople}
      //       </div>
      //     );
      //   } else {
      //     return t.common.na;
      //   }
      // },
    },

    {
      minWidth: selectedLanguage === 'en' ? '110px' : '121px',
      name: t.workRequests.table.asset,
      sortField: 'assets.name',
      sortable: true,
      cell: (row) => {
        let assigneePeople = row?.attributes?.assets?.data;
        let totalLength = row?.attributes?.assets?.data
          ? row?.attributes?.assets?.data.length
          : 0;

        if (assigneePeople?.length) {
          if (assigneePeople?.length < 3) {
            assigneePeople = assigneePeople.map((people, index) => {
              if (people?.attributes?.image?.data?.attributes?.url) {
                {
                  return (
                    <Image
                      onClick={() => {
                        setTargetedData(row);
                        setTogglePreviw(true);
                      }}
                      avatar
                      title={people.attributes.name}
                      src={`${IMAGES_URL}${people?.attributes?.image?.data?.attributes?.url}`}
                      style={
                        index != 0
                          ? {
                              marginLeft: '-7px',
                              width: '34px',
                              height: '34px',
                            }
                          : { width: '34px', height: '34px' }
                      }
                      className={index == 0 ? 'img-first' : ''}
                    />
                  );
                }
              } else {
                return (
                  // <div
                  //   onClick={() => {
                  //     setTargetedData(row);
                  //     setTogglePreviw(true);
                  //   }}
                  // >
                    <AssetProfilePlaceholder
                      name={`${
                        people?.attributes?.name ? people?.attributes?.name : ''
                      }`}
                      width={'34px'}
                      height={'34px'}
                      right={index !== 0 ? '7px' : ''}
                      className={index == 0 ? 'img-first' : ''}
                    />
                  // </div>
                );
              }
            });
          } else {
            assigneePeople = assigneePeople.slice(0, 2).map((people, index) => {
              if (people?.attributes?.image?.data?.attributes?.url) {
                {
                  return (
                    <Image
                      onClick={() => {
                        setTargetedData(row);
                        setTogglePreviw(true);
                      }}
                      avatar
                      title={people.attributes.name}
                      src={`${IMAGES_URL}${people?.attributes?.image?.data?.attributes?.url}`}
                      style={
                        index != 0
                          ? {
                              marginLeft: '-7px',
                              width: '34px',
                              height: '34px',
                            }
                          : { width: '34px', height: '34px' }
                      }
                    />
                  );
                }
              } else {
                return (
                  <AssetProfilePlaceholder
                    name={`${people?.attributes?.name}`}
                    width={'34px'}
                    height={'34px'}
                    right={
                      selectedLanguage === 'ar' && index !== 0
                        ? '0px'
                        : index === 0 && selectedLanguage === 'en'
                        ? '-15px'
                        : '-9px'
                    }
                  />
                );
              }
            });
            assigneePeople.push(
              <Image
                onClick={(e) => {
                  e.stopPropagation();
                  setTargetedData(row);
                  // setTargetedData(row);
                  setTogglePreviw(true);
                }}
                avatar
                title={`${totalLength - 2}`}
                src={`https://ui-avatars.com/api/?name=${totalLength -
                  2}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
                style={{
                  width: '34px',
                  height: '34px',
                  right: '-3px',
                }}
              />
            );
          }
          return (
            <div
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
              className={`multi-profile-container ${
                selectedLanguage == 'en' ? '' : 'rtl-body'
              }`}
            >
              {assigneePeople}
            </div>
          );
        } else {
          return t.common.na;
        }
      },
    },

    {
      minWidth: selectedLanguage === 'en' ? '160px' : '121px',
      name: t.parts.table.team,
      sortField: 'teams.name',
      sortable: true,
      cell: (row) =>
        row?.attributes?.teams?.data?.attributes?.name ? (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
          >
            {DisplayFormattedText(
              row?.attributes?.teams?.data?.attributes?.name,
              15
            )}
          </span>
        ) : (
          t.common.na
        ),
    },

    {
      name: t.assets.form.category,
      sortField: 'category.name',
      minWidth: '160px',

      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
        >
          {DisplayFormattedText(
            row?.attributes?.category?.data?.attributes?.name,
            15
          ) || t.common.na}
        </span>
      ),
    },

    {
      name: t.parts.table.created_on,
      sortField: 'createdAt',
      sortable: true,
      minWidth: '144px',
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
        >
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.createdAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.createdAt} />
          </div>
        </div>
      ),
    },

    {
      name: t.parts.table.updated_on,
      sortField: 'updatedAt',
      sortable: true,
      minWidth: selectedLanguage === 'en' ? '144px' : '170px',
      cell: (row) => (
        <div
          onClick={() => {
            setTargetedData(row);
            setTogglePreviw(true);
          }}
        >
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.updatedAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.updatedAt} />
          </div>
        </div>
      ),
    },
  ];

  /*
   * The function `handleToggleEdit` sets targeted data for a specific location and toggles between preview and edit modes.
   */
  const handleToggleEdit = (location) => {
    setTargetedData(location);
    setTogglePreviw(false);
    setToggleEditModal(true);
  };

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
  };
  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };
  return (
    <>
        <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {' '}
            <PartIcon />
            {t.parts.title_parts}
          </Header>
          <div></div>

          {userPermissions?.part?.create && initData?.length > 0 ? (
          //    <button
          //    onClick={() => {
          //      setToggleAsideModal(true);
          //    }}
          //    className={`add-item-btn ${
          //      selectedLanguage == "en" ? "mr-7" : "ml-7"
          //    }`}
          //  >
          //    <span>
          //      <CreateIcon />
          //      {t.parts.create_part}
          //    </span>
          //  </button>
            <Button
              icon="add"
              primary
              className={`add-item-btn ${
                selectedLanguage == "en" ? "mr-7" : "ml-7"
              }`}
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.parts.create_part}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
        {initData?.length > 0 ? (
          <div className="new-listing-filter wo-filter sticky-filter mt-8">
              <Filters
                data={initData}
                role = {role}
                allIds= {allIds}
                fileData={data}
                setSortData={setSortData}
                setloader={setloader}
                loader={loader}
                debounceValue={debounceValue}
                setSearchTitle={setSearchTitle}
                searchTitle={searchTitle}
                sortData={sortData}
                setDataByFilter={handleSetDataByFilter}
                hasStatus={false}
                setFilterOptions={setFilterOptions}
                filterOptions={filterOptions}
              />

          </div>
        ) : null}
      </div>
    <div className="new-listing-container">
  
      {initLoading || loadUserData ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '500px' }}
        >
          <Animation />
          {/* <Placeholder fluid>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder> */}
        </div>
      ) : (
        // <div>
        //   <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
        <div className="new-listing-filter">
          {initData?.length > 0 ? (
            <>
            
              {error ? (
                <div className="mt-8 mb-8 ">
                  <Animation />
                </div>
              ) : (
                <DataTable
                  className="datatable-div"
                  fixedHeader
                  columns={columns}
                  data={getPaginatedData(data)}
                  onSort={handleSort}
                  customStyles={customStyles}
                  noDataComponent={
                    initData.length > 0 ? (
                      <div className="no-data-container">
                        <div className="">
                          <p className="no-data-text">
                            {t.common.no_result_found}
                          </p>
                        </div>
                      </div>
                    ) : null
                  }
                  direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
                  onRowClicked={(row) => {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }}
                  sortIcon={
                    sortData ? (
                      <div>
                        <span>
                          <SortIcon />
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveSorting();
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    ) : (
                      <span></span>
                    )
                  }
                  persistTableHead={true}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  progressPending={loader}
                  progressComponent={
                    <div className="mt-8 mb-8 ">{t.common.loading}</div>
                  }
                />
              )}
              <div className="d-flex justify-content-end">
                <Pagination
                  ofString={t.common.of}
                  total={total}
                  prevItem={
                    selectedLanguage === 'en' ? <ArrowLeft /> : <ArrowRight />
                  }
                  nextItem={
                    selectedLanguage === 'en' ? <ArrowRight /> : <ArrowLeft />
                  }
                  page={page}
                  limit={pageSize}
                  handleNext={async () => {
                    setloader(true);
                    await dispatch(
                      fetchPartsWithFilter({
                        organisationId,
                        searchName: debounceValue,
                        page: page + 1,
                        pageSize: pageSize,
                        partType: filterOptions.partType,
                        status: filterOptions.status,
                        assets: filterOptions.assets,
                        vendors: filterOptions.vendors,
                        locations: filterOptions.locations,
                        area: filterOptions.area,
                        teams: filterOptions.teams,
                        category: filterOptions.categories,
                        serialNumber: filterOptions.serialNumber,

                        sort: sortData,
                        role: role,
                        allIds: allIds,
                      })
                    );
                    setloader(false);
                  }}
                  handlePrevious={async () => {
                    setloader(true);
                    await dispatch(
                      fetchPartsWithFilter({
                        organisationId,
                        searchName: debounceValue,
                        page: page - 1,
                        pageSize: pageSize,
                        partType: filterOptions.partType,
                        status: filterOptions.status,
                        assets: filterOptions.assets,
                        vendors: filterOptions.vendors,
                        locations: filterOptions.locations,
                        area: filterOptions.area,
                        teams: filterOptions.teams,
                        category: filterOptions.categories,
                        serialNumber: filterOptions.serialNumber,
                        sort: sortData,
                        role: role,
                        allIds: allIds,
                      })
                    );
                    setloader(false);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="">
              <EmptyFilter />
              <div className="no-data-container">
                <div className="no-work-orders pb-15">
                  <PartsBlueIcon />
                  <p className="no-data-text">
                    {t.common.there_no}{' '}
                    <span
                      className="link-text"
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth={
                          selectedLanguage == 'ar' ? '170px' : '163px'
                        }
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == 'ar'
                            ? 'Noto Kufi Arabic'
                            : 'Roboto'
                        }
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight={selectedLanguage == 'ar' ? '40px' : '40px'}
                        moveUp="-1px"
                      >
                        <span className="work-order-tooltip">
                          {t.parts.how_to_add_parts}
                        </span>
                      </ToolTip>
                      {' ' + t.parts.parts_small + ' '}
                    </span>{' '}
                    {t.common.added}
                  </p>
                  {userPermissions?.part?.create ? (
                    <button
                      className="work-order-button-transparent"
                      onClick={() => {
                        setToggleAsideModal(true);
                      }}
                    >
                      {t.parts.no_parts_added}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Add Part modal */}
      <Add
        toggled={toggleAsideModal}
        allIds={allIds}
        role={role}
        untoggle={() => {
          setToggleAsideModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterOptions={setFilterOptions}
      />
      {/* Edit Part modal */}
      <Edit
        allIds={allIds}
        role={role}
        targetedData={targetedData}
        toggled={toggleEditModal}
        untoggle={() => {
          setTargetedData();
          setToggleEditModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        setFilterOptions={setFilterOptions}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      {/*Part Details modal */}
      <Preview
        allIds={allIds}
        role={role}
        setTogglePreviw={setTogglePreviw}
        targetedData={targetedData}
        toggled={togglePreview}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        edit={() => {
          setTogglePreviw(false);
          setToggleEditModal(true);
        }}
        toggleEdit={handleToggleEdit}
        untoggle={() => {
          setTargetedData();
          setTogglePreviw(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          history.push('/parts');
        }}
        refresh={refresh}
        setRefresh={setRefresh}
        setFilterOptions={setFilterOptions}
      />
    </div></>
  );
};

export default connect(null, auth.actions)(Parts);
