import React, { useEffect, useState } from "react";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import {
  AddIconDashboard,
  CostIconEstimate,
  DashboardWOStatusIcon,
  FullArrow,
  TimeIconEstimate,
} from "../../../../Assets/Icons/svg";
import Chart from "react-apexcharts";
import NoDashboardData from "../noDashboardData";
const AssetAge = ({
  setOpenModal,
  openModal,
  setModalData,
  setAssetDashBoardData,
  assetDashBoardData,
  setChartConfigAge,
  chartConfigAge,
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  useEffect(() => {
    if (assetDashBoardData?.assetAge?.countArr?.length > 0) {
      setChartConfigAge((prevState) => ({
        ...prevState,
        series: [
          {
            name: t.dashboard.asset_card.number_assets,
            data: assetDashBoardData?.assetAge?.countArr
              ? assetDashBoardData?.assetAge?.countArr
              : [], // Set count data
          },
        ],
      }));
    }
  }, [assetDashBoardData]);
  return (
    <>
      {assetDashBoardData?.assetAge?.countArr?.length > 0 ? (
        <Grid.Column className="completion-column pl-0 pt-0">
          <div
            className={
              openModal
                ? "completion-column-data no-border"
                : "completion-column-data"
            }
          >
            <div className="completion-cotainer d-flex justify-content-between mt-2">
              <div className="completion-title d-flex">
                <span className="title">{t.dashboard.asset_card.age}</span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className={
                    selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                  }
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={selectedLanguage == "en" ? "right" : "left"}
                    show={showTooltip}
                    textBoxWidth="140px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="0px 0px 0px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="8px"
                    // moveDown="10px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.asset_tooltip.age_asset}
                    </span>
                  </Tooltip>
                  <DashboardWOStatusIcon />
                </p>
              </div>

              <span
                style={{ marginTop: "-10px", gap: "10px" }}
                className="d-flex"
              >
                <span
                  onClick={() => {
                    setOpenModal(true);
                    setModalData("Age");
                    setShowExpandTooltip(false);
                  }}
                  style={{ marginTop: "2px" }}
                  className=""
                >
                  {openModal ? null : (
                    <p
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        marginTop: "-10px",
                      }}
                      onMouseOver={() => setShowExpandTooltip(true)}
                      onMouseLeave={() => setShowExpandTooltip(false)}
                      className=" mt-1"
                    >
                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top"
                        show={showExpandTooltip}
                        textBoxWidth="80px"
                        fontSize="10px"
                        fontWeight="400"
                        fontFamily="Roboto"
                        padding="8px 8px 8px 8px"
                        borderRadius="5px"
                        static
                        arrowAlign="start"
                        moveRight="5px"
                        moveDown="3px"
                      >
                        <span
                          style={{ padding: "0px 10px" }}
                          className="work-order-tooltip"
                        >
                          {t.dashboard.expand_full}
                        </span>
                      </Tooltip>
                      <FullArrow />
                    </p>
                  )}
                </span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowAddCustomizedTooltip(true)}
                  onMouseLeave={() => setShowAddCustomizedTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showAddCustomizedTooltip}
                    textBoxWidth="105px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="0px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.add_customized}
                    </span>
                  </Tooltip>
                  {/* <AddIconDashboard /> */}
                </p>
              </span>
            </div>
            {assetDashBoardData?.assetAge?.countArr?.every(val => val === 0) ? 
       <NoDashboardData height="292px"/>       :<div className="completion-status-part res">
       <Grid className="wo-data-container" columns={1}>
         <Grid.Column className="wo-data-chart  align-items-center text-center">
           {" "}
           {/* Adjust minWidth as needed */}
           <Chart
             options={chartConfigAge?.options}
             series={chartConfigAge?.series}
             type="bar"
             height={openModal ? 400 : 277}
           />
         </Grid.Column>
       </Grid>
     </div> }
          </div>
        </Grid.Column>
      ) :null}{" "}
    </>
  );
};

export default AssetAge;
