export const TIMEZONES = [
  {
    key: 'UTC+2',
    value: 'UTC+2',
    text: 'UTC +2',
  },
  {
    key: 'UTC+3',
    value: 'UTC+3',
    text: 'UTC +3',
  },
  {
    key: 'UTC+4',
    value: 'UTC+4',
    text: 'UTC +4',
  },
];
export const currencyData = [
  { key: 'AFA', value: 'AFA', text: 'AFA' },
  { key: 'ALL', value: 'ALL', text: 'ALL' },
  { key: 'DZD', value: 'DZD', text: 'DZD' },
  { key: 'AOA', value: 'AOA', text: 'AOA' },
  { key: 'ARS', value: 'ARS', text: 'ARS' },
  { key: 'AMD', value: 'AMD', text: 'AMD' },
  { key: 'AWG', value: 'AWG', text: 'AWG' },
  { key: 'AUD', value: 'AUD', text: 'AUD' },
  { key: 'AZN', value: 'AZN', text: 'AZN' },
  { key: 'BSD', value: 'BSD', text: 'BSD' },
  { key: 'BHD', value: 'BHD', text: 'BHD' },
  { key: 'BDT', value: 'BDT', text: 'BDT' },
  { key: 'BBD', value: 'BBD', text: 'BBD' },
  { key: 'BYR', value: 'BYR', text: 'BYR' },
  { key: 'BEF', value: 'BEF', text: 'BEF' },
  { key: 'BZD', value: 'BZD', text: 'BZD' },
  { key: 'BMD', value: 'BMD', text: 'BMD' },
  { key: 'BTN', value: 'BTN', text: 'BTN' },
  { key: 'BTC', value: 'BTC', text: 'BTC' },
  { key: 'BOB', value: 'BOB', text: 'BOB' },
  { key: 'BAM', value: 'BAM', text: 'BAM' },
  { key: 'BWP', value: 'BWP', text: 'BWP' },
  { key: 'BRL', value: 'BRL', text: 'BRL' },
  { key: 'GBP', value: 'GBP', text: 'GBP' },
  { key: 'BND', value: 'BND', text: 'BND' },
  { key: 'BGN', value: 'BGN', text: 'BGN' },
  { key: 'BIF', value: 'BIF', text: 'BIF' },
  { key: 'KHR', value: 'KHR', text: 'KHR' },
  { key: 'CAD', value: 'CAD', text: 'CAD' },
  { key: 'CVE', value: 'CVE', text: 'CVE' },
  { key: 'KYD', value: 'KYD', text: 'KYD' },
  { key: 'XOF', value: 'XOF', text: 'XOF' },
  { key: 'XAF', value: 'XAF', text: 'XAF' },
  { key: 'XPF', value: 'XPF', text: 'XPF' },
  { key: 'CLP', value: 'CLP', text: 'CLP' },
  { key: 'CNY', value: 'CNY', text: 'CNY' },
  { key: 'COP', value: 'COP', text: 'COP' },
  { key: 'KMF', value: 'KMF', text: 'KMF' },
  { key: 'CDF', value: 'CDF', text: 'CDF' },
  { key: 'CRC', value: 'CRC', text: 'CRC' },
  { key: 'HRK', value: 'HRK', text: 'HRK' },
  { key: 'CUC', value: 'CUC', text: 'CUC' },
  { key: 'CZK', value: 'CZK', text: 'CZK' },
  { key: 'DKK', value: 'DKK', text: 'DKK' },
  { key: 'DJF', value: 'DJF', text: 'DJF' },
  { key: 'DOP', value: 'DOP', text: 'DOP' },
  { key: 'XCD', value: 'XCD', text: 'XCD' },
  { key: 'EGP', value: 'EGP', text: 'EGP' },
  { key: 'ERN', value: 'ERN', text: 'ERN' },
  { key: 'EEK', value: 'EEK', text: 'EEK' },
  { key: 'ETB', value: 'ETB', text: 'ETB' },
  { key: 'EUR', value: 'EUR', text: 'EUR' },
  { key: 'FKP', value: 'FKP', text: 'FKP' },
  { key: 'FJD', value: 'FJD', text: 'FJD' },
  { key: 'GMD', value: 'GMD', text: 'GMD' },
  { key: 'GEL', value: 'GEL', text: 'GEL' },
  { key: 'DEM', value: 'DEM', text: 'DEM' },
  { key: 'GHS', value: 'GHS', text: 'GHS' },
  { key: 'GIP', value: 'GIP', text: 'GIP' },
  { key: 'GRD', value: 'GRD', text: 'GRD' },
  { key: 'GTQ', value: 'GTQ', text: 'GTQ' },
  { key: 'GNF', value: 'GNF', text: 'GNF' },
  { key: 'GYD', value: 'GYD', text: 'GYD' },
  { key: 'HTG', value: 'HTG', text: 'HTG' },
  { key: 'HNL', value: 'HNL', text: 'HNL' },
  { key: 'HKD', value: 'HKD', text: 'HKD' },
  { key: 'HUF', value: 'HUF', text: 'HUF' },
  { key: 'ISK', value: 'ISK', text: 'ISK' },
  { key: 'INR', value: 'INR', text: 'INR' },
  { key: 'IDR', value: 'IDR', text: 'IDR' },
  { key: 'IRR', value: 'IRR', text: 'IRR' },
  { key: 'IQD', value: 'IQD', text: 'IQD' },
  { key: 'ILS', value: 'ILS', text: 'ILS' },
  { key: 'ITL', value: 'ITL', text: 'ITL' },
  { key: 'JMD', value: 'JMD', text: 'JMD' },
  { key: 'JPY', value: 'JPY', text: 'JPY' },
  { key: 'JOD', value: 'JOD', text: 'JOD' },
  { key: 'KZT', value: 'KZT', text: 'KZT' },
  { key: 'KES', value: 'KES', text: 'KES' },
  { key: 'KWD', value: 'KWD', text: 'KWD' },
  { key: 'KGS', value: 'KGS', text: 'KGS' },
  { key: 'LAK', value: 'LAK', text: 'LAK' },
  { key: 'LVL', value: 'LVL', text: 'LVL' },
  { key: 'LBP', value: 'LBP', text: 'LBP' },
  { key: 'LSL', value: 'LSL', text: 'LSL' },
  { key: 'LRD', value: 'LRD', text: 'LRD' },
  { key: 'LYD', value: 'LYD', text: 'LYD' },
  { key: 'LTL', value: 'LTL', text: 'LTL' },
  { key: 'MOP', value: 'MOP', text: 'MOP' },
  { key: 'MKD', value: 'MKD', text: 'MKD' },
  { key: 'MGA', value: 'MGA', text: 'MGA' },
  { key: 'MWK', value: 'MWK', text: 'MWK' },
  { key: 'MYR', value: 'MYR', text: 'MYR' },
  { key: 'MVR', value: 'MVR', text: 'MVR' },
  { key: 'MRO', value: 'MRO', text: 'MRO' },
  { key: 'MUR', value: 'MUR', text: 'MUR' },
  { key: 'MXN', value: 'MXN', text: 'MXN' },
  { key: 'MDL', value: 'MDL', text: 'MDL' },
  { key: 'MNT', value: 'MNT', text: 'MNT' },
  { key: 'MAD', value: 'MAD', text: 'MAD' },
  { key: 'MZM', value: 'MZM', text: 'MZM' },
  { key: 'MMK', value: 'MMK', text: 'MMK' },
  { key: 'NAD', value: 'NAD', text: 'NAD' },
  { key: 'NPR', value: 'NPR', text: 'NPR' },
  { key: 'ANG', value: 'ANG', text: 'ANG' },
  { key: 'TWD', value: 'TWD', text: 'TWD' },
  { key: 'NZD', value: 'NZD', text: 'NZD' },
  { key: 'NIO', value: 'NIO', text: 'NIO' },
  { key: 'NGN', value: 'NGN', text: 'NGN' },
  { key: 'KPW', value: 'KPW', text: 'KPW' },
  { key: 'NOK', value: 'NOK', text: 'NOK' },
  { key: 'OMR', value: 'OMR', text: 'OMR' },
  { key: 'PKR', value: 'PKR', text: 'PKR' },
  { key: 'PAB', value: 'PAB', text: 'PAB' },
  { key: 'PGK', value: 'PGK', text: 'PGK' },
  { key: 'PYG', value: 'PYG', text: 'PYG' },
  { key: 'PEN', value: 'PEN', text: 'PEN' },
  { key: 'PHP', value: 'PHP', text: 'PHP' },
  { key: 'PLN', value: 'PLN', text: 'PLN' },
  { key: 'QAR', value: 'QAR', text: 'QAR' },
  { key: 'RON', value: 'RON', text: 'RON' },
  { key: 'RUB', value: 'RUB', text: 'RUB' },
  { key: 'RWF', value: 'RWF', text: 'RWF' },
  { key: 'SVC', value: 'SVC', text: 'SVC' },
  { key: 'WST', value: 'WST', text: 'WST' },
  { key: 'SAR', value: 'SAR', text: 'SAR' },
  { key: 'RSD', value: 'RSD', text: 'RSD' },
  { key: 'SCR', value: 'SCR', text: 'SCR' },
  { key: 'SLL', value: 'SLL', text: 'SLL' },
  { key: 'SGD', value: 'SGD', text: 'SGD' },
  { key: 'SKK', value: 'SKK', text: 'SKK' },
  { key: 'SBD', value: 'SBD', text: 'SBD' },
  { key: 'SOS', value: 'SOS', text: 'SOS' },
  { key: 'ZAR', value: 'ZAR', text: 'ZAR' },
  { key: 'KRW', value: 'KRW', text: 'KRW' },
  { key: 'XDR', value: 'XDR', text: 'XDR' },
  { key: 'LKR', value: 'LKR', text: 'LKR' },
  { key: 'SHP', value: 'SHP', text: 'SHP' },
  { key: 'SDG', value: 'SDG', text: 'SDG' },
  { key: 'SRD', value: 'SRD', text: 'SRD' },
  { key: 'SZL', value: 'SZL', text: 'SZL' },
  { key: 'SEK', value: 'SEK', text: 'SEK' },
  { key: 'CHF', value: 'CHF', text: 'CHF' },
  { key: 'SYP', value: 'SYP', text: 'SYP' },
  { key: 'STD', value: 'STD', text: 'STD' },
  { key: 'TJS', value: 'TJS', text: 'TJS' },
  { key: 'TZS', value: 'TZS', text: 'TZS' },
  { key: 'THB', value: 'THB', text: 'THB' },
  { key: 'TOP', value: 'TOP', text: 'TOP' },
  { key: 'TTD', value: 'TTD', text: 'TTD' },
  { key: 'TND', value: 'TND', text: 'TND' },
  { key: 'TRY', value: 'TRY', text: 'TRY' },
  { key: 'TMT', value: 'TMT', text: 'TMT' },
  { key: 'UGX', value: 'UGX', text: 'UGX' },
  { key: 'UAH', value: 'UAH', text: 'UAH' },
  { key: 'AED', value: 'AED', text: 'AED' },
  { key: 'UYU', value: 'UYU', text: 'UYU' },
  { key: 'USD', value: 'USD', text: 'USD' },
  { key: 'UZS', value: 'UZS', text: 'UZS' },
  { key: 'VUV', value: 'VUV', text: 'VUV' },
  { key: 'VEF', value: 'VEF', text: 'VEF' },
  { key: 'VND', value: 'VND', text: 'VND' },
  { key: 'YER', value: 'YER', text: 'YER' },
  { key: 'ZMK', value: 'ZMK', text: 'ZMK' },
];
export const currencyWithCountry = [
  { key: 'AFA', value: 'AFA', text: 'AFA', country: 'AF' },
  { key: 'ALL', value: 'ALL', text: 'ALL', country: 'AL' },
  { key: 'DZD', value: 'DZD', text: 'DZD', country: 'DZ' },
  { key: 'AOA', value: 'AOA', text: 'AOA', country: 'AO' },
  { key: 'ARS', value: 'ARS', text: 'ARS', country: 'AR' },
  { key: 'AMD', value: 'AMD', text: 'AMD', country: 'AM' },
  { key: 'AWG', value: 'AWG', text: 'AWG', country: 'AW' },
  { key: 'AUD', value: 'AUD', text: 'AUD', country: 'AU' },
  { key: 'AZN', value: 'AZN', text: 'AZN', country: 'AZ' },
  { key: 'BSD', value: 'BSD', text: 'BSD', country: 'BS' },
  { key: 'BHD', value: 'BHD', text: 'BHD', country: 'BH' },
  { key: 'BDT', value: 'BDT', text: 'BDT', country: 'BD' },
  { key: 'BBD', value: 'BBD', text: 'BBD', country: 'BB' },
  { key: 'BYR', value: 'BYR', text: 'BYR', country: 'BY' },
  { key: 'BEF', value: 'BEF', text: 'BEF', country: 'BE' },
  { key: 'BZD', value: 'BZD', text: 'BZD', country: 'BZ' },
  { key: 'BMD', value: 'BMD', text: 'BMD', country: 'BM' },
  { key: 'BTN', value: 'BTN', text: 'BTN', country: 'BT' },
  { key: 'BTC', value: 'BTC', text: 'BTC', country: '' }, // No country code available
  { key: 'BOB', value: 'BOB', text: 'BOB', country: 'BO' },
  { key: 'BAM', value: 'BAM', text: 'BAM', country: 'BA' },
  { key: 'BWP', value: 'BWP', text: 'BWP', country: 'BW' },
  { key: 'BRL', value: 'BRL', text: 'BRL', country: 'BR' },
  { key: 'GBP', value: 'GBP', text: 'GBP', country: 'GB' },
  { key: 'BND', value: 'BND', text: 'BND', country: 'BN' },
  { key: 'BGN', value: 'BGN', text: 'BGN', country: 'BG' },
  { key: 'BIF', value: 'BIF', text: 'BIF', country: 'BI' },
  { key: 'KHR', value: 'KHR', text: 'KHR', country: 'KH' },
  { key: 'CAD', value: 'CAD', text: 'CAD', country: 'CA' },
  { key: 'CVE', value: 'CVE', text: 'CVE', country: 'CV' },
  { key: 'KYD', value: 'KYD', text: 'KYD', country: 'KY' },
  { key: 'XOF', value: 'XOF', text: 'XOF', country: '' }, // No specific country
  { key: 'XAF', value: 'XAF', text: 'XAF', country: '' }, // No specific country
  { key: 'XPF', value: 'XPF', text: 'XPF', country: '' }, // No specific country
  { key: 'CLP', value: 'CLP', text: 'CLP', country: 'CL' },
  { key: 'CNY', value: 'CNY', text: 'CNY', country: 'CN' },
  { key: 'COP', value: 'COP', text: 'COP', country: 'CO' },
  { key: 'KMF', value: 'KMF', text: 'KMF', country: 'KM' },
  { key: 'CDF', value: 'CDF', text: 'CDF', country: 'CD' },
  { key: 'CRC', value: 'CRC', text: 'CRC', country: 'CR' },
  { key: 'HRK', value: 'HRK', text: 'HRK', country: 'HR' },
  { key: 'CUC', value: 'CUC', text: 'CUC', country: 'CU' },
  { key: 'CZK', value: 'CZK', text: 'CZK', country: 'CZ' },
  { key: 'DKK', value: 'DKK', text: 'DKK', country: 'DK' },
  { key: 'DJF', value: 'DJF', text: 'DJF', country: 'DJ' },
  { key: 'DOP', value: 'DOP', text: 'DOP', country: 'DO' },
  { key: 'XCD', value: 'XCD', text: 'XCD', country: '' }, // No specific country
  { key: 'EGP', value: 'EGP', text: 'EGP', country: 'EG' },
  { key: 'ERN', value: 'ERN', text: 'ERN', country: 'ER' },
  { key: 'EEK', value: 'EEK', text: 'EEK', country: 'EE' },
  { key: 'ETB', value: 'ETB', text: 'ETB', country: 'ET' },
  { key: 'EUR', value: 'EUR', text: 'EUR', country: '' }, // Used by multiple European countries
  { key: 'FKP', value: 'FKP', text: 'FKP', country: '' }, // No specific country
  { key: 'FJD', value: 'FJD', text: 'FJD', country: 'FJ' },
  { key: 'GMD', value: 'GMD', text: 'GMD', country: 'GM' },
  { key: 'GEL', value: 'GEL', text: 'GEL', country: 'GE' },
  { key: 'DEM', value: 'DEM', text: 'DEM', country: 'DE' }, // Historic currency of Germany
  { key: 'GHS', value: 'GHS', text: 'GHS', country: 'GH' },
  { key: 'GIP', value: 'GIP', text: 'GIP', country: 'GI' },
  { key: 'GRD', value: 'GRD', text: 'GRD', country: 'GD' },
  { key: 'GTQ', value: 'GTQ', text: 'GTQ', country: 'GT' },
  { key: 'GNF', value: 'GNF', text: 'GNF', country: 'GN' },
  { key: 'GYD', value: 'GYD', text: 'GYD', country: 'GY' },
  { key: 'HTG', value: 'HTG', text: 'HTG', country: 'HT' },
  { key: 'HNL', value: 'HNL', text: 'HNL', country: 'HN' },
  { key: 'HKD', value: 'HKD', text: 'HKD', country: 'HK' },
  { key: 'HUF', value: 'HUF', text: 'HUF', country: 'HU' },
  { key: 'ISK', value: 'ISK', text: 'ISK', country: 'IS' },
  { key: 'INR', value: 'INR', text: 'INR', country: 'IN' },
  { key: 'IDR', value: 'IDR', text: 'IDR', country: 'ID' },
  { key: 'IRR', value: 'IRR', text: 'IRR', country: 'IR' },
  { key: 'IQD', value: 'IQD', text: 'IQD', country: 'IQ' },
  { key: 'ILS', value: 'ILS', text: 'ILS', country: 'IL' },
  { key: 'ITL', value: 'ITL', text: 'ITL', country: 'IT' }, // Historic currency of Italy
  { key: 'JMD', value: 'JMD', text: 'JMD', country: 'JM' },
  { key: 'JPY', value: 'JPY', text: 'JPY', country: 'JP' },
  { key: 'JOD', value: 'JOD', text: 'JOD', country: 'JO' },
  { key: 'KZT', value: 'KZT', text: 'KZT', country: 'KZ' },
  { key: 'KES', value: 'KES', text: 'KES', country: 'KE' },
  { key: 'KWD', value: 'KWD', text: 'KWD', country: 'KW' },
  { key: 'KGS', value: 'KGS', text: 'KGS', country: 'KG' },
  { key: 'LAK', value: 'LAK', text: 'LAK', country: 'LA' },
  { key: 'LVL', value: 'LVL', text: 'LVL', country: 'LV' }, // Historic currency of Latvia
  { key: 'LBP', value: 'LBP', text: 'LBP', country: 'LB' },
  { key: 'LSL', value: 'LSL', text: 'LSL', country: 'LS' },
  { key: 'LRD', value: 'LRD', text: 'LRD', country: 'LR' },
  { key: 'LYD', value: 'LYD', text: 'LYD', country: 'LY' },
  { key: 'LTL', value: 'LTL', text: 'LTL', country: 'LT' }, // Historic currency of Lithuania
  { key: 'MOP', value: 'MOP', text: 'MOP', country: 'MO' },
  { key: 'MKD', value: 'MKD', text: 'MKD', country: 'MK' },
  { key: 'MGA', value: 'MGA', text: 'MGA', country: 'MG' },
  { key: 'MWK', value: 'MWK', text: 'MWK', country: 'MW' },
  { key: 'MYR', value: 'MYR', text: 'MYR', country: 'MY' },
  { key: 'MVR', value: 'MVR', text: 'MVR', country: 'MV' },
  { key: 'MRO', value: 'MRO', text: 'MRO', country: 'MR' },
  { key: 'MUR', value: 'MUR', text: 'MUR', country: 'MU' },
  { key: 'MXN', value: 'MXN', text: 'MXN', country: 'MX' },
  { key: 'MDL', value: 'MDL', text: 'MDL', country: 'MD' },
  { key: 'MNT', value: 'MNT', text: 'MNT', country: 'MN' },
  { key: 'MAD', value: 'MAD', text: 'MAD', country: 'MA' },
  { key: 'MZM', value: 'MZM', text: 'MZM', country: 'MZ' }, // Historic currency of Mozambique
  { key: 'MMK', value: 'MMK', text: 'MMK', country: 'MM' },
  { key: 'NAD', value: 'NAD', text: 'NAD', country: 'NA' },
  { key: 'NPR', value: 'NPR', text: 'NPR', country: 'NP' },
  { key: 'ANG', value: 'ANG', text: 'ANG', country: 'AN' },
  { key: 'TWD', value: 'TWD', text: 'TWD', country: 'TW' },
  { key: 'NZD', value: 'NZD', text: 'NZD', country: 'NZ' },
  { key: 'NIO', value: 'NIO', text: 'NIO', country: 'NI' },
  { key: 'NGN', value: 'NGN', text: 'NGN', country: 'NG' },
  { key: 'KPW', value: 'KPW', text: 'KPW', country: 'KP' },
  { key: 'NOK', value: 'NOK', text: 'NOK', country: 'NO' },
  { key: 'OMR', value: 'OMR', text: 'OMR', country: 'OM' },
  { key: 'PKR', value: 'PKR', text: 'PKR', country: 'PK' },
  { key: 'PAB', value: 'PAB', text: 'PAB', country: 'PA' },
  { key: 'PGK', value: 'PGK', text: 'PGK', country: 'PG' }, // Papua New Guinea
  { key: 'PYG', value: 'PYG', text: 'PYG', country: 'PY' }, // Paraguay
  { key: 'PEN', value: 'PEN', text: 'PEN', country: 'PE' }, // Peru
  { key: 'PHP', value: 'PHP', text: 'PHP', country: 'PH' }, // Philippines
  { key: 'PLN', value: 'PLN', text: 'PLN', country: 'PL' }, // Poland
  { key: 'QAR', value: 'QAR', text: 'QAR', country: 'QA' }, // Qatar
  { key: 'RON', value: 'RON', text: 'RON', country: 'RO' }, // Romania
  { key: 'RUB', value: 'RUB', text: 'RUB', country: 'RU' }, // Russia
  { key: 'RWF', value: 'RWF', text: 'RWF', country: 'RW' }, // Rwanda
  { key: 'SVC', value: 'SVC', text: 'SVC', country: 'SV' }, // El Salvador
  { key: 'WST', value: 'WST', text: 'WST', country: 'WS' }, // Samoa
  { key: 'SAR', value: 'SAR', text: 'SAR', country: 'SA' }, // Saudi Arabia
  { key: 'RSD', value: 'RSD', text: 'RSD', country: 'RS' }, // Serbia
  { key: 'SCR', value: 'SCR', text: 'SCR', country: 'SC' }, // Seychelles
  { key: 'SLL', value: 'SLL', text: 'SLL', country: 'SL' }, // Sierra Leone
  { key: 'SGD', value: 'SGD', text: 'SGD', country: 'SG' }, // Singapore
  { key: 'SKK', value: 'SKK', text: 'SKK', country: 'SK' }, // Slovakia
  { key: 'SBD', value: 'SBD', text: 'SBD', country: 'SB' }, // Solomon Islands
  { key: 'SOS', value: 'SOS', text: 'SOS', country: 'SO' }, // Somalia
  { key: 'ZAR', value: 'ZAR', text: 'ZAR', country: 'ZA' }, // South Africa
  { key: 'KRW', value: 'KRW', text: 'KRW', country: 'KR' }, // South Korea
  { key: 'XDR', value: 'XDR', text: 'XDR', country: 'MF' }, // IMF Special Drawing Rights
  { key: 'LKR', value: 'LKR', text: 'LKR', country: 'LK' }, // Sri Lanka
  { key: 'SHP', value: 'SHP', text: 'SHP', country: 'SH' }, // Saint Helena
  { key: 'SDG', value: 'SDG', text: 'SDG', country: 'SD' }, // Sudan
  { key: 'SRD', value: 'SRD', text: 'SRD', country: 'SR' }, // Suriname
  { key: 'SZL', value: 'SZL', text: 'SZL', country: 'SZ' }, // Eswatini
  { key: 'SEK', value: 'SEK', text: 'SEK', country: 'SE' }, // Sweden
  { key: 'CHF', value: 'CHF', text: 'CHF', country: 'CH' }, // Switzerland
  { key: 'SYP', value: 'SYP', text: 'SYP', country: 'SY' }, // Syria
  { key: 'STD', value: 'STD', text: 'STD', country: 'ST' }, // São Tomé and Príncipe
  { key: 'TJS', value: 'TJS', text: 'TJS', country: 'TJ' }, // Tajikistan
  { key: 'TZS', value: 'TZS', text: 'TZS', country: 'TZ' }, // Tanzania
  { key: 'THB', value: 'THB', text: 'THB', country: 'TH' }, // Thailand
  { key: 'TOP', value: 'TOP', text: 'TOP', country: 'TO' }, // Tonga
  { key: 'TTD', value: 'TTD', text: 'TTD', country: 'TT' }, // Trinidad and Tobago
  { key: 'TND', value: 'TND', text: 'TND', country: 'TN' }, // Tunisia
  { key: 'TRY', value: 'TRY', text: 'TRY', country: 'TR' }, // Turkey
  { key: 'TMT', value: 'TMT', text: 'TMT', country: 'TM' }, // Turkmenistan
  { key: 'UGX', value: 'UGX', text: 'UGX', country: 'UG' }, // Uganda
  { key: 'UAH', value: 'UAH', text: 'UAH', country: 'UA' }, // Ukraine
  { key: 'AED', value: 'AED', text: 'AED', country: 'AE' }, // United Arab Emirates
  { key: 'UYU', value: 'UYU', text: 'UYU', country: 'UY' }, // Uruguay
  { key: 'USD', value: 'USD', text: 'USD', country: 'US' }, // United States
  { key: 'UZS', value: 'UZS', text: 'UZS', country: 'UZ' }, // Uzbekistan
  { key: 'VUV', value: 'VUV', text: 'VUV', country: 'VU' }, // Vanuatu
  { key: 'VEF', value: 'VEF', text: 'VEF', country: 'VE' }, // Venezuela
  { key: 'VND', value: 'VND', text: 'VND', country: 'VN' }, // Vietnam
  { key: 'YER', value: 'YER', text: 'YER', country: 'YE' }, // Yemen
  { key: 'ZMK', value: 'ZMK', text: 'ZMK', country: 'ZM' }, // Zambia
];
