import React, { useEffect, useState } from 'react';
import { selectTranslations } from '../../../config/i18n/slice';
import { useSelector } from 'react-redux';
import { Dimmer, Divider, Image, Loader } from 'semantic-ui-react';
import { IMAGES_URL, humanize } from '../../../config/functions';
import UserProfilePlaceholder from '../../../components/common/userProfilePlaceholder';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { BillingInfoIcon, ContactInfoIcon } from '../../../../Assets/Icons/svg';
import { Country, State, City } from 'country-state-city';

const Customers = ({ targetedData }) => {
  const t = useSelector(selectTranslations);
  const history = useHistory();

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const [fetching, setFetchLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData]);

  return (
    <div className="preview-details-wrapper">
      {fetching ? (
        <Dimmer active inverted>
          <Loader inverted>{t.common.loading}</Loader>
        </Dimmer>
      ) : (
        <>
          <div className="intro">
            <div style={{ marginTop: '-8px' }} className="billing-info  d-flex">
              <BillingInfoIcon />
              <p
                style={{ fontSize: '16px' }}
                className={` required-text mt-1 ${selectedLanguage == "en" ? "ml-2" : "mr-2"}`}
              >
                {t.customers.form.billing_information}
              </p>
            </div>
          </div>

          <div className="details-wrapper">
            <div className="d-flex align-items-center mt-7">
              <label className="workorder-intro-label customer-label1">
                {t.customers.form.name}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.name ? targetedData?.name : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label1">
                {t.locations.table.country}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.country
                  ? Country.getCountryByCode(targetedData?.country)?.name
                  : t.common.na}
              </p>
            </div>

            {/* <div className="d-flex align-items-center mt-5">
                  <label className="workorder-intro-label customer-label1">
                    {t.people.personal_info_form.phone}
                    {':'}
                  </label>
                  <p className="workorder-intro-value">
                    {data.profile.phone ? `+${data.profile.phone}` : t.common.na}
                  </p>
                </div> */}

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label1">
                {t.locations.table.city}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.city
                  ? State.getStateByCodeAndCountry(
                      targetedData?.city,
                      targetedData?.country
                    )?.name
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label1">
                {t.customers.form.postal_code}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.postalCode
                  ? targetedData?.postalCode
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label1">
                {t.customers.form.address}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.address ? targetedData?.address : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label1">
                {t.customers.form.address_line_2}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.addressLine2
                  ? targetedData?.addressLine2
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label1">
                {t.customers.form.address_line_3}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.addressLine3
                  ? targetedData?.addressLine3
                  : t.common.na}
                {/* {educationLevel?.text ? educationLevel?.text : t.common.na} */}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="workorder-intro-label customer-label1">
                {t.customers.form.rate}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.rate
                  ? targetedData?.rate +
                    ' ' +
                    (targetedData?.currency ? targetedData?.currency : '')
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5 ">
              <label className="workorder-intro-label customer-label1">
                {t.customers.form.currency}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.currency ? targetedData?.currency : t.common.na}
              </p>
            </div>

            {/* <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label customer-label1">
            {t.people.personal_info_form.nationality}
              {':'}
            </label>
            <p className="workorder-intro-value">
            {data.profile.nationality
                          ? Country.getCountryByCode(data.profile.nationality)
                              .name
                          : t.common.na}
            </p>
          </div> */}
          </div>

          {/* <Modal
                size="tiny"
                open={updateModal}
                className="assets-modal"
                onClose={() => {
                  setUpdateModal(false);
                  setToggleStatus(!targetedData?.blocked);
                }}
                style={{ minHeight: '191px' }}
              >
                <div className="header-container">
                  <span className="assets-header">
                    {t.people.activate_deactivate_account}
                  </span>
                  <div>
                    <span
                      className="c-pointer text-right"
                      onClick={() => {
                        setUpdateModal(false);
                        setToggleStatus(!targetedData?.blocked);
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                </div>
    
                <Modal.Content>
                  <span className="assets-label ml-1">
                    {targetedData?.blocked == false
                      ? `${t.people.deactivate_statement} "${targetedData?.firstName} ${targetedData?.lastName}"`
                      : `${t.people.activate_statement} "${targetedData?.firstName} ${targetedData?.lastName}"`}
                  </span>
                </Modal.Content>
                <div
                  className={`modal-button-container mt-3 ${
                    selectedLanguage == 'en' ? 'mr-6' : 'ml-6'
                  }`}
                >
                  <button
                    onClick={() => {
                      setUpdateModal(false); // Close the delete modal
                      setToggleStatus(!targetedData?.blocked);
                    }}
                    style={{ color: 'black' }}
                    className="modal-form-action-button"
                  >
                    {t.assets.form.cancel}
                  </button>
    
                  <button
                    onClick={() => {
                      setUpdateModal(false);
                      updateStatusHandler();
                    }}
                    className="modal-form-action-button light-blue"
                  >
                    {targetedData?.blocked == false
                      ? t.assets.deactivate
                      : t.assets.activate}
                  </button>
                </div>
              </Modal> */}
        </>
      )}
    </div>
  );
};

export default Customers;
