import { filter } from "lodash";
import React, { useEffect, useState, createRef } from "react";
import _ from "lodash";
import {
  Form,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  DropdownItem,
  DropdownMenu,
  Popup,
  Divider,
} from "semantic-ui-react";
import {  useHistory } from 'react-router';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ToolTip from "react-power-tooltip";
import md5 from "md5";
import { toast } from "react-toastify";

import {
  getAllLocations,
  getUsersByRole,
  getAllTeams,
  humanize,
  toggle,
  IMAGES_URL,
  getAllJobTitles,
} from "../../config/functions";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { selectTranslations } from "../../config/i18n/slice";
import {
  ActionBtnIcon,
  AdminIcon,
  DownChevron,
  DownloadIcon,
  LimitedAdminIcon,
  PdfIcon,
  SearchIcon,
  ShareIcon,
  TechnicianIcon,
  UploadIcon,
  ViewerIcon,
  WhiteDownChevron,
} from "../../../Assets/Icons/svg";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PriorityDropdown from "../../components/common/PriorityDropdown";
import WorkOrderSearchDropdown from "../../components/WorkOrder/WorkOrderSearchDropdown";
import CategoryDropdown from "../../components/common/CategoryDropdown";
import DownloadExcelFileWithData from "../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../services/DownloadPdfFileWithData";
import {
  fetchAllPeopleData,
  fetchPeopleWithFilter,
} from "../../../redux/reducers/peopleReducer";

const Filters = ({
  data,
  setDataByFilter,
  fileData = [],
  filterOptions = [],
  setFilterOptions,
  setSearchTitle,
  debounceRate,
  searchTitle,
  debounceValue,
  setloader,
  loader,
  sortData,
  roleData,
  allIds,
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const dispatch = useDispatch();

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });
  const history = useHistory();
  const roles = [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,
      icon: <AdminIcon />,
      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description: t.people.add_modal.admin_description,
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,
      icon: <LimitedAdminIcon />,
      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,
      icon: <TechnicianIcon />,
      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,
      icon: <ViewerIcon />,
      description: t.people.add_modal.viewer_description,
    },
    {
      key: 2,
      value: 6,
      text: t.people.add_modal.requester,
      // icon: <ViewerIcon />,
      description: t.people.add_modal.requester_desc,
    },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ];
  const [filtersTrigger, setFiltersTrigger] = useState({
    role: {
      options: [
        {
          key: 2,
          value: "",
          text: t.common.all,
        },
        {
          key: 0,
          value: 5,
          text: t.people.add_modal.admin,
        },

        {
          key: 6,
          value: 9,
          text: t.people.add_modal.limited_admin,
        },

        // {
        //   key: 89,
        //   value: 7,
        //   text: 'Supervisor',
        // },
        {
          key: 1,
          value: 8,
          text: t.people.add_modal.technician,
        },

        {
          key: 4,
          value: 3,
          text: t.people.add_modal.viewer,
        },
        {
          key: 4,
          value: 6,
          text: t.people.add_modal.requester,
        },

        // {
        //   key: 2,
        //   value: 6,
        //   text: 'Requester only',
        // },
        // {
        //   key: 3,
        //   value: 3,
        //   text: 'Viewer only',
        // },
      ],
    },
    supervisors: {
      initOptions: [],
      options: [],
    },

    teams: {
      initOptions: [],
      options: [],
    },
    jobTitle: {
      initOptions: [],
      options: [],
    },

    status: {
      options: [
        {
          key: 0,
          value: "",
          text: t.common.all,
        },
        {
          key: 1,
          value: "false",
          text: t.people.status_options.status_pending,
          color: "yellow",
        },
        {
          key: 2,
          value: "true",
          text: t.people.status_options.status_active,
          color: "green",
        },
        {
          key: 3,
          value: "declined",
          text: t.people.status_options.inactive,
          color: "red",
        },
      ],
    },
    joiningDate: {
      options: [
        { key: 0, text: "Any Date", value: "any_day" },
        { key: 1, text: "Today", value: "today" },
        { key: 2, text: "Tomorrow", value: "tomorrow" },
        { key: 3, text: "Next 7 Days", value: "next_7_days" },
        { key: 4, text: "Next 30 Days", value: "next_30_days" },
        { key: 5, text: "This Month", value: "this_month" },
        { key: 6, text: "This Quarter", value: "this_quarter" },
        { key: 7, text: "This Year", value: "this_year" },
        { key: 8, text: "Yesterday", value: "yesterday" },
        { key: 9, text: "Last Week", value: "last_week" },
        { key: 10, text: "Last Month", value: "last_month" },
        { key: 11, text: "Last Quarter", value: "last_quarter" },
        { key: 12, text: "Last Year", value: "last_year" },
        { key: 13, text: "Past Due", value: "past_due" },
        { key: 14, text: "Unscheduled", value: "unscheduled" },
      ],
    },
  });

  // const [filterOptions, setFilterOptions] = useState({
  //   role: [],
  //   supervisors: [],
  //   jobTitle: [],
  //   teams: [],
  //   status: [],
  //   joiningDate: {
  //     value: 'any_day',
  //     text: 'Any Date',
  //   },
  //   rate: {
  //     from: null,
  //     to: null,
  //   },

  //   contractEnd: {
  //     from: null,
  //     to: null,
  //   },
  // });

  const [filterSearch, setFilterSearch] = useState({
    supervisors: "",
    teams: "",
  });

  // const [searchTitle, setSearchTitle] = useState('');

  const [newData, setNewData] = useState(data);

  const [openRole, setOpenRole] = useState(false);
  const [openContractEnd, setOpenContractEnd] = useState(false);
  const [openSupervisor, setOpenSupervisor] = useState(false);
  const [openTeams, setOpenTeams] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openJoiningDate, setOpenJoiningDate] = useState(false);
  const [openRate, setOpenRate] = useState(false);

  const closeAllFilters = () => {
    setOpenRole(false);
    setOpenSupervisor(false);
    setOpenTeams(false);
    setOpenStatus(false);
    setOpenJoiningDate(false);
    setOpenRate(false);
    setOpenContractEnd(false);
  };

  useEffect(() => {
    (async function() {
      // const fetchData = await getAllCustomers(organisationId);

      let the_options_teams = [];
      let the_supervisor = [];
      let the_options_jobTitle = [];

      const fetchData = await getUsersByRole(
        ["admin", "limited_admin", "super_admin"],
        organisationId,
        "",
        1,
        9999,
        [],
        [],
        [],
        [],
        [],
        {},
        [],
        !(roleData === "super_admin" || roleData === "admin") ? allIds : [],
        true
      );
      // console.log();
      if (fetchData.response) {
        fetchData.response.map((el) => {
          the_supervisor.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.firstName + " " + el.lastName,
            image: {
              avatar: true,
              src: el?.profileImage?.url
                ? IMAGES_URL + el?.profileImage?.url
                : LetteredAvatarImageUrl(el.firstName + " " + el.lastName),
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }

      const fetchTeamsData = await getAllTeams(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        !(roleData === "super_admin" || roleData === "admin") ? allIds : []
      );

      // console.log();
      if (fetchTeamsData.response) {
        fetchTeamsData.response.map((el) => {
          the_options_teams.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: LetteredAvatarImageUrl(el.attributes.name),
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }

      const fetchJobTitleData = await getAllJobTitles(organisationId);

      if (fetchJobTitleData.response) {
        fetchJobTitleData.response.map((el) => {
          the_options_jobTitle.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.title,
          });
        });
      }

      setFiltersTrigger({
        ...filtersTrigger,
        supervisors: {
          ...filtersTrigger.supervisors,
          initOptions: the_supervisor,
          options: the_supervisor,
        },
        teams: {
          ...filtersTrigger.teams,
          initOptions: the_options_teams,
          options: the_options_teams,
        },
        jobTitle: {
          ...filtersTrigger.jobTitle,
          initOptions: the_options_jobTitle,
          options: the_options_jobTitle,
        },
      });

      // if (fetchData) {
      //   fetchData.forEach((el) => {
      //     the_options_customer.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: el.attributes.logo.data
      //           ? IMAGES_URL +
      //             el.attributes.logo.data.attributes.formats.thumbnail.url
      //           : LetteredAvatarImageUrl(el.attributes.name + ' '),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }

      // const fetchTeamsData = await getAllTeams(organisationId);

      // if (fetchTeamsData) {
      //   fetchTeamsData.map((el) => {
      //     the_options_teams.push({
      //       key: el.id,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: LetteredAvatarImageUrl(`${el.attributes.name}`),
      //         style: { width: '16px', height: '16px' },
      //       },
      //     });
      //   });
      // }

      // getAllParentLocations,
      // const fetchDataLocation = await getAllParentLocations(organisationId);

      // if (fetchDataLocation) {
      //   fetchDataLocation.forEach((el) => {
      //     the_options_location.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: `${el.attributes.name}`,
      //     });
      //   });
      // }

      // const fetchDataPeople = await getUsersByRole(
      //   [
      //     'user',
      //     'supervisor',
      //     'view_only',
      //     'admin',
      //     'requester',
      //     'super_admin',
      //   ],
      //   organisationId
      // );

      // if (fetchDataPeople) {
      //   fetchDataPeople.map((el) => {
      //     the_options_user.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.firstName + ' ' + el.lastName,
      //       image: {
      //         avatar: true,
      //         src: el?.profileImage?.url
      //           ? IMAGES_URL + el?.profileImage?.url
      //           : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }
    })();
  }, []);

  const fetchSupervisorsOptions = async () => {
    const fetchData = await getUsersByRole(
      ["admin", "limited_admin", "super_admin"],
      organisationId
    );
    // console.log();
    if (fetchData) {
      let the_options = fetchData.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName + " " + el.lastName,
          image: {
            avatar: true,
            src: el?.profileImage?.url
              ? IMAGES_URL + el?.profileImage?.url
              : LetteredAvatarImageUrl(el.firstName + " " + el.lastName),
            style: { width: "20px", height: "20px" },
          },
        };
      });
      setFiltersTrigger({
        ...filtersTrigger,
        supervisors: {
          ...filtersTrigger.supervisors,
          initOptions: the_options,
          options: the_options,
        },
      });
    }
  };

  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: {
            avatar: true,
            src: LetteredAvatarImageUrl(el.attributes.name),
            style: { width: "20px", height: "20px" },
          },
        };
      });
      setFiltersTrigger({
        ...filtersTrigger,
        teams: {
          ...filtersTrigger.teams,
          initOptions: the_options,
          options: the_options,
        },
      });
    }
  };

  const handleSearchTeamsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      teams: e.target.value,
    });
  };

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.supervisors), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      supervisors: {
        ...filtersTrigger.supervisors,
        options:
          filterSearch.supervisors.length > 0
            ? _.filter(filtersTrigger.supervisors.initOptions, isMatch)
            : filtersTrigger.supervisors.initOptions,
      },
    });
  }, [filterSearch.supervisors]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.teams), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      teams: {
        ...filtersTrigger.teams,
        options:
          filterSearch.teams.length > 0
            ? _.filter(filtersTrigger.teams.initOptions, isMatch)
            : filtersTrigger.teams.initOptions,
      },
    });
  }, [filterSearch.teams]);

  let _newData = newData;
  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      var notInArray = ["contractEndPopup", "ratePopup"];
      if (!notInArray.includes(e.target.id)) closeAllFilters();
    });

    (async function() {
      setloader(true);

      await dispatch(
        fetchPeopleWithFilter({
          role: [
            "user",
            "super_admin",
            "supervisor",
            "view_only",
            "admin",
            "requester",
            "limited_admin",
          ],
          organisationId,
          searchName: debounceValue,
          page: "1",
          pageSize: "25",
          role_type: filterOptions.role,
          supervisor: filterOptions.supervisors,
          team: filterOptions.teams,
          status: filterOptions.status,
          job: filterOptions.jobTitle,
          rate: filterOptions.rate,
          sort: sortData,
          roleData,
          allIds,
        })
      );

      setloader(false);
    })();

    // if (_newData.length > 0) {
    //   setDataByFilter(_newData);
    // } else {
    //   setDataByFilter([]);
    // }
  }, [
    filterOptions.role,
    filterOptions.teams,
    filterOptions.supervisors,
    filterOptions.jobTitle,
    filterOptions.status,
    debounceRate,
    debounceValue,
  ]);

  let supervisorsPlaceholder = t.people.filter.supervisors;
  if (!(filterOptions.supervisors.length > 0)) {
    supervisorsPlaceholder = t.people.filter.supervisors;
  } else {
    supervisorsPlaceholder =
      filterOptions.supervisors.length > 0
        ? filterOptions.supervisors.length + " " + t.people.filter.supervisors
        : "";
  }

  let teamsPlaceholder = t.people.filter.teams;
  if (!(filterOptions.teams.length > 0)) {
    teamsPlaceholder = t.people.filter.teams;
  } else {
    teamsPlaceholder =
      filterOptions.teams.length > 0
        ? filterOptions.teams.length + " " + t.people.filter.teams
        : "";
  }

  let statusPlaceholder = t.people.filter.status;

  if (!filterOptions.status) {
    statusPlaceholder = t.people.filter.status;
  } else {
    statusPlaceholder =
      filterOptions.status == "true" ? (
        <div className="flexbox">
          <Label
            circular
            color="green"
            size="tiny"
            empty
            style={{ borderRadius: "500rem", marginRight: "4px" }}
            title={t.people.status_options.active}
          />
          <span>{t.people.status_options.status_active}</span>
        </div>
      ) : filterOptions.status == "false" ? (
        <div className="flexbox">
          <Label
            circular
            color="yellow"
            size="tiny"
            empty
            style={{ borderRadius: "500rem", marginRight: "4px" }}
            title={t.people.status_options.status_pending}
          />
          <span> {t.people.status_options.status_pending}</span>
        </div>
      ) : filterOptions.status == "declined" ? (
        <div className="flexbox">
          <Label
            circular
            color="red"
            size="tiny"
            empty
            style={{ borderRadius: "500rem", marginRight: "4px" }}
            title={t.people.status_options.inactive}
          />
          <span>{t.people.status_options.inactive}</span>
        </div>
      ) : null;
  }

  let rolePlaceholder = t.people.filter.role;

  if (!(filterOptions.role.length > 0)) {
    rolePlaceholder = t.people.filter.role;
  } else {
    rolePlaceholder =
      filterOptions.role.length > 0
        ? filterOptions.role.length + " selected"
        : "";
  }

  const handleSearchSupervisorChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      supervisors: e.target.value,
    });
  };

  const headers = [
    { label: t.assets.table.ID, key: "id" },
    { label: t.people.table.name, key: "name" },
    { label: t.customers.table.phone, key: "mobile" },
    { label: t.jobTitle.add, key: "jobtitle" },
    { label: t.people.table.role, key: "role" },
    { label: t.people.table.teams, key: "teams" },
    { label: t.people.table.supervisor, key: "supervisor" },
    { label: t.people.table.hourly_rate, key: "hourly_rate" },
    { label: t.people.table.status, key: "status" },
    { label: t.people.table.contract_expiry_date, key: "contract_expiry_date" },
  ];

  const downloadXLS = async () => {
    const rateHasValues = Object.values(filterOptions.rate).some(
      (value) => value !== null && value !== undefined && value !== ""
    );

    // Check if any array in filterOptions has length greater than 0
    const arraysNotEmpty = Object.values(filterOptions).some(
      (option) => Array.isArray(option) && option.length > 0
    );

    if (!rateHasValues && !arraysNotEmpty && searchTitle == "") {
      let mydata = await dispatch(
        fetchAllPeopleData({
          role: [
            "user",
            "supervisor",
            "view_only",
            "admin",
            "requester",
            "limited_admin",
            "super_admin",
          ],
          organisationId,
          roleData,
          allIds,
        })
      );

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let people_data = [];

      people_data.id = fileData[i].id;
      people_data.name =
        fileData[i]?.firstName && fileData[i]?.lastName
          ? fileData[i].firstName + fileData[i]?.lastName
          : t.common.na;

      people_data.mobile = fileData[i]?.userProfile?.phone
        ? `+${fileData[i]?.userProfile?.phone}`
        : t.common.na;

      people_data.jobtitle = fileData[i]?.userProfile?.job?.title
        ? fileData[i]?.userProfile?.job.title
        : t.common.na;

      let roleData = roles.find((each) => each.value == fileData[i].role.id);

      people_data.role = fileData[i]?.role.id
        ? roleData?.text || fileData[i]?.role?.type
        : t.common.na;

      let assigneePeople = fileData[i].teams;
      let assignString = "";

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople.map((people) => people?.name).join(", ");
      } else {
        assignString = t.common.na;
      }
      people_data.teams = assignString;

      people_data.supervisor = fileData[i]?.supervisor?.firstName
        ? fileData[i]?.supervisor?.firstName +
          " " +
          fileData[i]?.supervisor?.lastName
        : t.common.na;

      people_data.hourRate = fileData[i].userProfile?.hourRate
        ? `${fileData[i].userProfile?.hourRate} ${organisationCurrency}`
        : t.common.na;
      people_data.status =
        fileData[i].confirmed && !fileData[i].blocked
          ? t.assets.active
          : fileData[i].blocked
          ? t.assets.inActive
          : t.people.status_options.status_pending;

      people_data.contract_expiry_date = fileData[i]?.userProfile?.contractEnd
        ? moment(fileData[i]?.userProfile?.contractEnd).format("DD.MM.YYYY")
        : t.common.na;

      csvData.push([
        people_data.id,
        people_data.name,
        people_data.mobile,
        people_data.jobtitle,

        people_data.role,
        people_data.teams,
        people_data.supervisor,
        people_data.hourRate,
        people_data.status,

        people_data.contract_expiry_date,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 30 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 15 }, // Updated At column
      { wch: 15 }, // Updated At column

      { wch: 25 }, // Description column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.people.title
    );
  };

  const generatePDF = () => {
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let people_data = [];

      people_data.id = fileData[i].id;
      people_data.name =
        fileData[i]?.firstName && fileData[i]?.lastName
          ? fileData[i].firstName + fileData[i]?.lastName
          : t.common.na;

      people_data.mobile = fileData[i]?.userProfile?.phone
        ? `+${fileData[i]?.userProfile?.phone}`
        : t.common.na;

      people_data.jobtitle = fileData[i]?.userProfile?.job?.title
        ? fileData[i]?.userProfile?.job.title
        : t.common.na;

      let roleData = roles.find((each) => each.value == fileData[i].role.id);

      people_data.role = fileData[i]?.role.id
        ? roleData?.text || fileData[i]?.role?.type
        : t.common.na;

      let assigneePeople = fileData[i].teams;
      let assignString = "";

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople.map((people) => people?.name).join(", ");
      } else {
        assignString = t.common.na;
      }
      people_data.teams = assignString;

      people_data.supervisor = fileData[i]?.supervisor?.firstName
        ? fileData[i]?.supervisor?.firstName +
          " " +
          fileData[i]?.supervisor?.lastName
        : t.common.na;

      people_data.hourRate = fileData[i].userProfile?.hourRate
        ? `${fileData[i].userProfile?.hourRate} ${organisationCurrency}`
        : t.common.na;
      people_data.status =
        fileData[i].confirmed && !fileData[i].blocked
          ? t.assets.active
          : fileData[i].blocked
          ? t.assets.inActive
          : t.people.status_options.status_pending;

      people_data.contract_expiry_date = fileData[i]?.userProfile?.contractEnd
        ? moment(fileData[i]?.userProfile?.contractEnd).format("DD.MM.YYYY")
        : t.common.na;

      csvData.push([
        people_data.id,
        people_data.name,
        people_data.mobile,
        people_data.jobtitle,

        people_data.role,
        people_data.teams,
        people_data.supervisor,
        people_data.hourRate,
        people_data.status,

        people_data.contract_expiry_date,
      ]);
    }
    const columnWidths = [, , , , , , , 20, , , , ,];

    // const columnWidths = [
    //   10,
    //   20,
    //   ,
    //   20,
    //   50,
    //   20,
    //   22,
    //   15,
    //   20,
    //   ,
    //   ,
    //   25,
    //   ,
    //   ,
    //   ,
    //   45,
    //   20,
    // ];
    DownloadPdfFileWithData(
      headers,
      csvData,
      t.people.title,
      columnWidths,
      "A3"
    );
  };

  return (
    <>
      <div className=" table-header-dashbaord team-filter  filters">

      <Button.Group
              className="add-people-btn"
              basic
              fluid
              style={{ width: "26%" }}
              size="medium"
            >
              <Button
                // active={activePane == 0}
                // onClick={() => changeTab(0)}
                type="button"
                active={true}
              >
                {t.people.title}
              </Button>

              <Button
                // className="add-people-tab"
              
                onClick={() => history.push("/teams")}
                type="button"
              >
                {t.people.filter.teams}
              </Button>

              {/* <Button
              active={activePane == 2}
              onClick={() => {
                // setFieldValue('tab', 2).then((errors) => {
                //   changeTab(2, errors, setFieldTouched, values);
                //   if (errors) {
                //     scrollToError();
                //   }
                // });
              }}
              type="button"
            >
              {t.people.attachments_form.attachment_title}
            </Button> */}
            </Button.Group>

            <Divider  style={{ width: "25%" }} className="mt-0 mb-8" />

            {/* {data?.length > 0 ? (
            <div className="table-searchbar d-flex justify-content-end">
              <div className="work-order-upload" style={{ width: "240px" }}>
                <span className="search-svg">
                  <SearchIcon />
                </span>
                <Input
                  icon={{ name: "search" }}
                  placeholder={t.people.filter.search}
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
              </div>
            </div>
          ) : null} */}
        {/* <div className="text-right mb-5 pb-5 d-flex justify-content-between align-items-center views"> */}
          {/* <div className="list-view-option">
            <p className={`single-view`}>
              <NavLink to="/people">{t.people.title}</NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/teams">{t.people.filter.teams}</NavLink>
            </p>
          </div> */}

       
        {/* </div> */}
      </div>
      {data?.length > 0 ? (
        <div className="filters-wrapper justify-content-between">
    

          <div className="flexbox table-right-dropdown d-flex ">
            <PriorityDropdown
              title={t.people.filter.role}
              options={filtersTrigger.role.options}
              pill={false}
              selectedValues={filterOptions.role}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    role: e.target.value
                      ? e.target.value == 5
                        ? [5, 4]
                        : [e.target.value]
                      : [],
                  });
                } else {
                  setFilterOptions({
                    ...filterOptions,
                    role: [],
                  });
                }
              }}
              icon={false}
              image={false}
            />

            {/* Role */}
            {/* <Dropdown
              text={rolePlaceholder}
              icon="user"
              style={{
                marginRight: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
              floating
              labeled
              button
              basic
              multiple
              className="icon"
              open={openRole}
              onOpen={() => {
                closeAllFilters();
                setOpenRole(true);
              }}
            >
              <Dropdown.Menu>
                <Dropdown.Header content={t.people.filter.select_role} />
                <Dropdown.Divider />

                {filtersTrigger.role.options.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    text={option.text}
                    selected={filterOptions.role.includes(option.value)}
                    onClick={() => {
                      const newArray = toggle(filterOptions.role, option.value);
                      setFilterOptions({
                        ...filterOptions,
                        role: newArray,
                      });
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown> */}

            {/* supervisor */}
            <WorkOrderSearchDropdown
              title={t.people.table.supervisor}
              options={filtersTrigger.supervisors.options}
              selectedValues={filterOptions.supervisors}
              searchValue={filterSearch.supervisors}
              handleSearchValue={handleSearchSupervisorChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.supervisors, value.value);

                setFilterOptions({
                  ...filterOptions,
                  supervisors: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  supervisors: "",
                });
              }}
            />

            {/* <Dropdown
            icon="bullhorn"
            floating
            labeled
            button
            basic
            multiple
            className="icon"
            text={supervisorsPlaceholder}
            open={openSupervisor}
            style={{ marginRight: '10px' }}
            onOpen={() => {
              closeAllFilters();
              setOpenSupervisor(true);
              fetchSupervisorsOptions();
            }}
          >
            <Dropdown.Menu>
              <Input
                autoComplete="new-password"
                icon="search"
                iconPosition="left"
                className="search"
                value={filterSearch.supervisors}
                onChange={handleSearchSupervisorChange}
              />
              <Dropdown.Divider />
              <Dropdown.Header content={t.people.filter.select_supervisors} />
              <Dropdown.Menu scrolling>
                {filtersTrigger.supervisors.options.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    {...option}
                    selected={filterOptions.supervisors.includes(option.value)}
                    onClick={() => {
                      const newArray = toggle(
                        filterOptions.supervisors,
                        option.value
                      );

                      setFilterOptions({
                        ...filterOptions,
                        supervisors: newArray,
                      });

                      setFilterSearch({
                        ...filterSearch,
                        supervisors: '',
                      });
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown> */}

            <WorkOrderSearchDropdown
              title={t.people.filter.team}
              options={filtersTrigger.teams.options}
              selectedValues={filterOptions.teams}
              searchValue={filterSearch.teams}
              handleSearchValue={handleSearchTeamsChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.teams, value.value);

                setFilterOptions({
                  ...filterOptions,
                  teams: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  teams: "",
                });
              }}
            />

            {/* teams */}
            {/* <Dropdown
            icon="users"
            floating
            labeled
            button
            basic
            multiple
            className="icon"
            text={teamsPlaceholder}
            open={openTeams}
            style={{ marginRight: '10px' }}
            onOpen={() => {
              closeAllFilters();
              setOpenTeams(true);
              fetchTeamsOptions();
            }}
          >
            <Dropdown.Menu>
              <Input
                autoComplete="new-password"
                icon="search"
                iconPosition="left"
                className="search"
                value={filterSearch.teams}
                onChange={handleSearchTeamsChange}
              />
              <Dropdown.Divider />
              <Dropdown.Header content={t.people.filter.select_teams} />
              <Dropdown.Menu scrolling>
                {filtersTrigger.teams.options.map((option) => (
                  <Dropdown.Item
                    key={option.key}
                    {...option}
                    selected={filterOptions.teams.includes(option.value)}
                    onClick={() => {
                      const newArray = toggle(
                        filterOptions.teams,
                        option.value
                      );

                      setFilterOptions({
                        ...filterOptions,
                        teams: newArray,
                      });

                      setFilterSearch({
                        ...filterSearch,
                        teams: '',
                      });
                    }}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown> */}

            {/* Rate */}
            <Popup
              key={1}
              on="click"
              pinned
              open={openRate}
              position="bottom right"
              id="ratePopup"
              onClick={(e) => e.stopPropagation()}
              trigger={
                <div
                  basic
                  onClick={() => setOpenRate(!openRate)}
                  className={`popup-filter Category-dropdown popup ${
                    filterOptions.rate.from || filterOptions.rate.to
                      ? "blue-border"
                      : ""
                  } ${openRate ? "active" : ""}`}
                >
                  {t.people.table.hourly_rate}
                  <DownChevron />
                  {/* {selectedLanguage === 'ar' ? (
                  <Icon name="dollar sign" color="#878c90" />
                ) : null} */}
                </div>
              }
              className={`${
                selectedLanguage == "ar" ? "asset-filter-rtl" : ""
              }`}
            >
              <div
                className="flexbox"
                style={{ minWidth: "200px" }}
                id="ratePopup"
              >
                <Form.Field id="ratePopup" style={{ width: "100%" }}>
                  <label className="label-item" id="ratePopup">
                    {t.people.filter.from}
                  </label>
                  <Input
                    id="ratePopup"
                    autoComplete="new-password"
                    fluid
                    type="number"
                    placeholder={t.people.filter.from}
                    value={filterOptions.rate.from}
                    onChange={(e) =>
                      setFilterOptions({
                        ...filterOptions,
                        rate: {
                          ...filterOptions.rate,
                          from: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Field>
                <Form.Field
                  id="ratePopup"
                  style={{ width: "100%", marginLeft: 2 }}
                >
                  <label id="ratePopup" className="label-item">
                    {t.people.filter.to}
                  </label>
                  <Input
                    id="ratePopup"
                    autoComplete="new-password"
                    fluid
                    type="number"
                    placeholder={t.people.filter.to}
                    value={filterOptions.rate.to}
                    onChange={(e) =>
                      setFilterOptions({
                        ...filterOptions,
                        rate: {
                          ...filterOptions.rate,
                          to: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Field>
              </div>
              <div className="form-button-container mt-0">
                <button
                  id="ratePopup"
                  className="form-action-button blue"
                  onClick={(e) => {
                    setFilterOptions({
                      ...filterOptions,
                      rate: {
                        from: "",
                        to: "",
                      },
                    });

                    // console.log(filterOptions);
                  }}
                >
                  {t.common.reset}
                </button>
              </div>
            </Popup>

            <PriorityDropdown
              title={t.people.table.status}
              options={filtersTrigger.status.options}
              pill={false}
              selectedValues={filterOptions.status}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    status: e.target.value ? [e.target.value] : [],
                  });
                } else {
                  setFilterOptions({
                    ...filterOptions,
                    status: [],
                  });
                }
              }}
              icon={false}
              image={false}
            />
            {/* Contract End */}
            {/* <Popup
            key={2}
            on="click"
            pinned
            open={openContractEnd}
            position="bottom right"
            id="contractEndPopup"
            onClick={(e) => e.stopPropagation()}
            trigger={
              <Label
                basic
                onClick={() => setOpenContractEnd(!openContractEnd)}
                className="popup-filter"
              >
                {selectedLanguage == 'en' ? (
                  <Icon name="calendar" color="#878c90" />
                ) : null}
                {t.people.filter.contract_expiry_date}
                {selectedLanguage == 'ar' ? (
                  <Icon name="calendar" color="#878c90" />
                ) : null}
              </Label>
            }
            className={`${selectedLanguage == 'ar' ? 'asset-filter-rtl' : ''}`}
          >
            <div
              className="flexbox"
              style={{ minWidth: '300px' }}
              id="contractEndPopup"
            >
              <Form.Field id="contractEndPopup" style={{ width: '100%' }}>
                <label className="label-item" id="contractEndPopup">
                  {t.people.filter.from}
                </label>
                <Input
                  id="contractEndPopup"
                  autoComplete="new-password"
                  fluid
                  type="date"
                  placeholder={t.people.filter.from}
                  value={filterOptions.contractEnd.from}
                  onChange={(e) =>
                    setFilterOptions({
                      ...filterOptions,
                      contractEnd: {
                        ...filterOptions.contractEnd,
                        from: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
              <Form.Field
                id="contractEndPopup"
                style={{ width: '100%', marginLeft: 2 }}
              >
                <label id="contractEndPopup" className="label-item">
                  {t.people.filter.to}
                </label>
                <Input
                  id="contractEndPopup"
                  autoComplete="new-password"
                  fluid
                  type="date"
                  placeholder={t.people.filter.to}
                  value={filterOptions.contractEnd.to}
                  onChange={(e) =>
                    setFilterOptions({
                      ...filterOptions,
                      contractEnd: {
                        ...filterOptions.contractEnd,
                        to: e.target.value,
                      },
                    })
                  }
                />
              </Form.Field>
            </div>
            <div className="filter-button-container">
              <Button
                color="black"
                id="contractEndPopup"
                onClick={(e) => {
                  setFilterOptions({
                    ...filterOptions,
                    contractEnd: {
                      from: null,
                      to: null,
                    },
                  });

                  // console.log(filterOptions);
                }}
              >
                {t.people.filter.reset}
              </Button>
            </div>
          </Popup> */}

            {/* Status */}
            {/* <Dropdown
            text={statusPlaceholder}
            icon="question circle"
            floating
            labeled
            button
            basic
            multiple
            className="icon"
            style={{ display: 'flex', alignItems: 'center' }}
            open={openStatus}
            onOpen={() => {
              closeAllFilters();
              setOpenStatus(true);
            }}
          >
            <Dropdown.Menu>
              <Dropdown.Header content={t.people.filter.select_status} />
              <Dropdown.Divider />

              {filtersTrigger.status.options.map((option) => (
                <Dropdown.Item
                  key={option.key}
                  label={{ color: option.color, empty: true, circular: true }}
                  text={option.text}
                  selected={filterOptions.status == option.value}
                  onClick={() => {
                    setFilterOptions({
                      ...filterOptions,
                      status:
                        filterOptions.status == option.value
                          ? null
                          : option.value,
                    });
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown> */}

            <CategoryDropdown
              title={t.jobTitle.add}
              options={filtersTrigger.jobTitle.options}
              selectedValues={filterOptions.jobTitle}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    jobTitle: [...filterOptions.jobTitle, e.target.value],
                  });
                } else {
                  let filteredLocations = filterOptions.jobTitle.filter(
                    (el) => el !== e.target.value
                  );
                  setFilterOptions({
                    ...filterOptions,
                    jobTitle: filteredLocations,
                  });
                }
              }}
              icon={false}
              image={false}
            />

            {Object.entries(filterOptions).some(([key, value]) =>
              Array.isArray(value)
                ? value?.length > 0 && value?.some((v) => v !== "")
                : typeof value === "object" && value !== null
                ? value?.from !== null || value?.to !== null
                : value !== ""
            ) ? (
              <div className="form-button-container mt-3">
                <button
                  // content={t.procedures.form.submit.add}
                  // primary
                  onClick={() => {
                    // setSelectedDates([]);
                    setFilterOptions({
                      role: [],
                      supervisors: [],
                      jobTitle: [],
                      teams: [],
                      status: [],
                      rate: {
                        from: null,
                        to: null,
                      },
                    });
                  }}
                  // loading={buttonStatus == 1}
                  style={{ marginTop: "-10px" }}
                  className="form-action-button dashboard"
                >
                  {t.dashboard.cards.clear}
                </button>
              </div>
            ) : null}
          </div>
          
        <div className="d-flex" style={{gap:"10px"}}>
        <div className="table-searchbar work-order-upload">
        <span className="search-svg">
                <SearchIcon />
              </span>
          <Input
            icon={{ name: 'search' }}
            placeholder={t.people.filter.search}
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
          />
        </div>
          <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ">

         


            <UncontrolledDropdown className="mr-2" direction="down">
              <DropdownToggle
                style={{ position: "relative", cursor: "pointer" }}
                onMouseOver={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                color="primary"
                className="option-toggle"
              >
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position={selectedLanguage == "ar" ? "top right" : "top left"}
                  show={showTooltip}
                  textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                  arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                  fontSize="12px"
                  fontWeight="400"
                  fontFamily={
                    selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                  }
                  padding="4px 4px 4px 8px"
                  borderRadius="5px"
                  static
                  moveRight="10px"
                  moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
                >
                  <span className="work-order-tooltip">
                    {t.common.page_action}
                  </span>
                </ToolTip>
                <ActionBtnIcon />{" "}
              </DropdownToggle>
              <DropdownMenu className={selectedLanguage == "ar" ? "rtl" : ""}>
                {/* {role !== 'view_only' ? (
                  <>
                    <DropdownItem>
                      <ShareIcon /> {t.common.share}
                    </DropdownItem>
                    <DropdownItem className="divider" />
                  </>
                ) : null} */}

                <DropdownItem className="divider" />
                <DropdownItem onClick={() => downloadXLS()}>
                  <DownloadIcon /> {t.common.download_sheet}
                </DropdownItem>
                {/* <DropdownItem className="divider" />
                <DropdownItem onClick={() => generatePDF()}>
                  <PdfIcon /> {t.common.download_pdf}
                </DropdownItem> */}
                {/* <DropdownItem onClick={() => setAssetUploadModal(true)}>
                <UploadIcon /> Upload
              </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>

            
          </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Filters;
