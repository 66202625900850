import React, { useEffect, useState } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import un_happy from "../../../../Assets/Icons/un_happy.png";
import happy from "../../../../Assets/Icons/happy.png";
// import un_happy from "../../../../Assets/Icons/happy_face.png";
import {
    AddIconDashboard,
    CostIconEstimate,
    DashboardWOStatusIcon,
    FullArrow,
    POPriceIcon,
    TimeIconEstimate,
  } from "../../../../Assets/Icons/svg";
  import Chart from "react-apexcharts";
import NoDashboardData from "../noDashboardData"


const PlannedChart = ({setMaintenanceData, maintenanceData,  chartOptionsPlanned,setChartOptionsPlanned,setModalData,setOpenModal,openModal}) => {
    const t = useSelector(selectTranslations);
    const organisationId = useSelector((state) => {
      if (state?.auth?.user?.organisation) {
        return state.auth.user.organisation.id;
      } else {
        return null;
      }
    });
    const [showTooltip, setShowTooltip] = useState(false);
    const [showExpandTooltip, setShowExpandTooltip] = useState(false);
    const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
      false
    );
    const selectedLanguage = useSelector((state) => {
        if (state?.i18n?.lang) {
          return state.i18n.lang;
        } else {
          return "en";
        }
      });

      useEffect(() => {
        if (maintenanceData?.PPM?.length > 0) {
          const totalEstimationTime = maintenanceData.PPM.map((item) => item?.totalEstimationTime);
          const totalWorkOrderTimeInHours = maintenanceData.PPM.map((item) => item?.totalWorkOrderTimeInHours);
          const labels = maintenanceData.PPM.map((item) => item?.categoryName);
    
          setChartOptionsPlanned((prevState) => ({
            ...prevState,
            series: [
          
              {
                name: t.dashboard.maintenance_card.planned_maintenance_hours,
                data: totalEstimationTime, // Set y values
              },
              {
                name: t.dashboard.maintenance_card.maintenance_hours,
                data: totalWorkOrderTimeInHours, // Set y values
              },
              
            ],
            options: {
              ...prevState.options,
              xaxis: {
                ...prevState.options.xaxis,
                categories: labels, // Set label data
              },
            },
          }));
        }
      }, [maintenanceData]);
  return (
    <Grid.Column className="completion-column pl-0 pt-0">
      <div className={openModal ? "completion-column-data no-border" : "completion-column-data"}>
      <div className="completion-cotainer d-flex justify-content-between mt-2">
        <div className="completion-title d-flex">
          <span className="title">{t.dashboard.maintenance_card.planned_preventive}</span>
          <p
            style={{
              position: "relative",
              cursor: "pointer",
              marginTop: "-10px",
            }}
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            className={
              selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
            }
          >
            <Tooltip
              color="#fff"
              backgroundColor="#172B4D"
              position={selectedLanguage == "en" ? "right" : "left"}
              show={showTooltip}
              textBoxWidth="140px"
              fontSize="10px"
              fontWeight="400"
              // fontFamily="Roboto"
              padding="0px 0px 0px 8px"
              fontFamily={
                selectedLanguage == 'ar'
                  ? 'Noto Kufi Arabic'
                  : 'Roboto'
              }
              borderRadius="5px"
              static
              arrowAlign="end"
              moveRight="8px"
              // moveDown="10px"
            >
              <span
                style={{ padding: "0px 10px" }}
                className="work-order-tooltip"
              >
                {t.dashboard.tooltip.cost}
              </span>
            </Tooltip>
            <DashboardWOStatusIcon />
          </p>
        </div>

        <span
          style={{ marginTop: "-10px", gap: "10px" }}
          className="d-flex"
        >
         
         <span
              onClick={() => {
                setOpenModal(true);
                setModalData("planned");
                setShowExpandTooltip(false)
              }}
              style={{ marginTop: "2px" }}
              className=""
            >
          { openModal ? null :    <p
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        marginTop: "-10px",
                      }}
                      onMouseOver={() => setShowExpandTooltip(true)}
                      onMouseLeave={() => setShowExpandTooltip(false)}
                      className=" mt-1"
                    >
                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top"
                        show={showExpandTooltip}
                        textBoxWidth="80px"
                        fontSize="10px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == 'ar'
                            ? 'Noto Kufi Arabic'
                            : 'Roboto'
                        }
                        padding="8px 8px 8px 8px"
                        borderRadius="5px"
                        static
                        arrowAlign="start"
                        moveRight="5px"
                        moveDown="3px"
                      >
                        <span
                          style={{ padding: "0px 10px" }}
                          className="work-order-tooltip"
                        >
                          {t.dashboard.expand_full}
                        </span>
                      </Tooltip>
                      <FullArrow />
                    </p>}
            </span>
            <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowAddCustomizedTooltip(true)}
                  onMouseLeave={() => setShowAddCustomizedTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showAddCustomizedTooltip}
                    textBoxWidth="105px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == 'ar'
                        ? 'Noto Kufi Arabic'
                        : 'Roboto'
                    }
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="0px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.add_customized}
                    </span>
                  </Tooltip>
                  {/* <AddIconDashboard /> */}
                </p>
        </span>
      </div>
      {maintenanceData?.PPM?.length > 0 ? (
      <div className="completion-status-part">
        <Grid className="wo-data-container" columns={1}>
          <Grid.Column className="wo-data-chart  align-items-center text-center">
          <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <p
                    className="d-flex align-items-center"
                    style={
                      selectedLanguage == "en"
                        ? { fontSize: "14px", color: "#44546F", gap: "10px" }
                        : { fontSize: "13px", color: "#44546F", gap: "10px" }
                    }
                  >
                    <span
                      className={selectedLanguage == "en" ? "ml-6" : "mr-6"}
                    >
                      <POPriceIcon/>
                    </span>
                    {t.dashboard.maintenance_card.maintenance_hours}
                    <POPriceIcon color="#E9F2FF"/>
                    {t.dashboard.maintenance_card.planned_maintenance_hours}
                   
                  </p>
                </div>
                        </div>
          <div
              className="res"
            >
              {/* <div style={{ minWidth: "1000px" }}>  */}
                {" "}
                {/* Adjust minWidth as needed */}

              
      <Chart
            options={chartOptionsPlanned?.options}
            series={chartOptionsPlanned?.series}
            type="bar"
            height="283"
          />
 

              </div>
           
          </Grid.Column>
        </Grid>
      </div>
    ): <NoDashboardData height={"318px"}/>}
    </div>
  </Grid.Column>
  )
}

export default PlannedChart