import React, { useState, useEffect, createRef, useRef } from 'react';
import {
  Icon,
  Menu,
  Popup,
  Image,
  Loader,
  Dimmer,
  Grid,
  Divider,
  Header,
  Modal,
  Button,
  Tab,
} from 'semantic-ui-react';

import { IMAGES_URL, deleteCustomer } from '../../../config/functions';

import Moment from 'react-moment';
import { Country, State, City } from 'country-state-city';

import md5 from 'md5';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { LetteredAvatarImageUrl } from '../../../components/common/userProfilePlaceholder';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectTranslations } from '../../../config/i18n/slice';
import { useSelector } from 'react-redux';
import Details from './details';
import Customers from './customers';
import {
  CloseIcon,
  DeleteIcon,
  DownloadIconBucket,
  EditTextIcon,
  FileDownloadIcon,
  ShareIcon,
} from '../../../../Assets/Icons/svg';
import useDeletePermissionModal from '../../../../hooks/Messages/useDeletePermissionModal';
import ToolTip from 'react-power-tooltip';
import DownloadExcelFileWithData from '../../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../../services/DownloadPdfFileWithData';
import Logs from './log';
import { Resizable } from 're-resizable';

function Preview({
  toggled,
  untoggle,
  edit,
  targetedData,
  refresh,
  setRefresh,
  setFilterOptions,
  filterOptions,
  setSearchTitle,
  setModalWidth,
  modalWidth
}) {
  const t = useSelector(selectTranslations);

  const history = useHistory();
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);

  const [showExclTooltip, setShowExclTooltip] = useState(false);
  const [showPdfTooltip, setShowPdfTooltip] = useState(false);
  const [showShareTooltip, setShareTooltip] = useState(false);
  const [showEditTooltip, setEditTooltip] = useState(false);
  const [showDeleteTooltip, setDeleteTooltip] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();

  const [fetching, setFetchLoading] = useState(true);
  const [data, setData] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData]);

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const popUpRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      let execlude_ids = ['popupTrigger'];
      // console.log(e.target.id);
      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
      // if (e.target.id != "areaPopup") closeAllFilters();
    });
  }, [popUpRef]);

  let createdByName = data?.attributes?.createdByUser?.data?.attributes ? (
    <div>
      <Image
        avatar
        title={
          data?.attributes?.createdByUser?.data?.attributes.firstName +
          ' ' +
          data?.attributes?.createdByUser?.data?.attributes.lastName
        }
        src={
          data?.attributes?.createdByUser?.data?.attributes.profileImage?.data
            ?.attributes?.url
            ? IMAGES_URL +
              data?.attributes?.createdByUser?.data?.attributes.profileImage
                ?.data?.attributes?.url
            : LetteredAvatarImageUrl(
                data?.attributes?.createdByUser?.data?.attributes.firstName +
                  ' ' +
                  data?.attributes?.createdByUser?.data?.attributes.lastName
              )
        }
      />
      <span style={{ marginLeft: '4px' }}>
        {data?.attributes?.createdByUser?.data?.attributes.firstName +
          ' ' +
          data?.attributes?.createdByUser?.data?.attributes.lastName}
      </span>
    </div>
  ) : (
    t.common.na
  );

  const deleteHandler = async () => {
    const response = await deleteCustomer(targetedData.id);

    // console.log(response);

    if (response.status == 200) {
      setDeleteErrorMessage(false);
      toast.success(t.customers.form.customer_delete_success);
      toggleDeleteSuccessModal('close');
    } else if (response.status == 400) {
      setDeleteErrorMessage(true);
      toast.error(t.customers.form.customer_delete_related_error);
      toggleDeleteSuccessModal();

      // toast.error(
      //   'Asset cannot be deleted as it is connected with other Work orders and procedures.'
      // );
    } else {
      toast.error('Something went wrong.');
    }
  };
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    if (data) {
      setData();
      untoggle();
      setFilterOptions({
        countries: [],
        cities: [],
        locations: [],
      
        rate: {
          from: null,
          to: null,
        },
       
      });
      setSearchTitle('');
    }
  };

  const headers = [
    { label: t.assets.table.ID, key: 'id' },
    { label:t.customers.table.name, key: 'name' },
    { label: t.customers.form.description, key: 'description' },
    { label: t.customers.form.email, key: 'email' },
    { label: t.customers.form.phone, key: 'phone' },
    { label: t.workOrders.table.location, key: 'location' },
    { label: t.customers.form.website, key: 'website' },
    { label: t.customers.form.service_type, key: 'service_type' },
    { label: t.common.industry, key: 'industry' },
    { label: t.customers.contact_name, key: 'contact_name' },
    { label: t.customers.contact_email, key: 'contact_email' },
    { label: t.customers.contact_phone, key: 'contact_phone' },
    { label: t.customers.billing_name, key: 'billing_name' },
    { label: t.locations.table.country, key: 'country' },
    { label: t.locations.table.city, key: 'city' },
    { label: t.customers.form.postal_code, key: 'postal_code' },
    { label: t.customers.form.address, key: 'address' },
    { label: t.customers.form.rate, key: 'rate' },
    { label: t.customers.form.currency, key: 'currency' },
    { label: t.workOrders.table.createdByUser, key: 'createdBy' },
  ];

  const downloadXLS = () => {
    let csvData = [];

    let customers_data = [];

    customers_data.id = targetedData.id;
    customers_data.name = targetedData.attributes.name || t.common.na;

    customers_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, ' ').trim()
      : t.common.na;

    customers_data.email = targetedData?.attributes?.email
      ? targetedData.attributes?.email
      : t.common.na;

    customers_data.phone = targetedData?.attributes?.phone
      ? `+${targetedData?.attributes?.phone}`
      : t.common.na;

    customers_data.locations = targetedData?.attributes?.location?.data?.id
      ? targetedData?.attributes?.location?.data?.attributes?.name
      : t.common.na;

    customers_data.website = targetedData?.attributes?.website
      ? targetedData.attributes?.website
      : t.common.na;

    customers_data.service_type = targetedData?.attributes?.serviceType
      ? targetedData.attributes?.serviceType
      : t.common.na;

    customers_data.industry = targetedData?.attributes?.Industry
      ? targetedData.attributes?.Industry
      : t.common.na;

    customers_data.contact_name = targetedData?.attributes?.contactInformation
      ?.name
      ? targetedData?.attributes?.contactInformation?.name
      : t.common.na;

    customers_data.contact_email = targetedData?.attributes?.contactInformation
      ?.email
      ? targetedData?.attributes?.contactInformation?.email
      : t.common.na;

    customers_data.contact_phone = targetedData?.attributes?.contactInformation
      ?.phone
      ? `+${targetedData?.attributes?.contactInformation?.phone}`
      : t.common.na;

    customers_data.billing_name = targetedData?.attributes?.billingInformation
      ?.name
      ? targetedData?.attributes?.billingInformation?.name
      : t.common.na;

    customers_data.country = targetedData?.attributes?.billingInformation
      ?.country
      ? Country.getCountryByCode(
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.city = targetedData?.attributes?.billingInformation?.city
      ? State.getStateByCodeAndCountry(
          targetedData?.attributes?.billingInformation?.city,
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.postal_code = targetedData?.attributes?.billingInformation
      ?.postalCode
      ? targetedData?.attributes?.billingInformation?.postalCode
      : t.common.na;

    customers_data.address = targetedData?.attributes?.billingInformation
      ?.address
      ? targetedData?.attributes?.billingInformation?.address
      : t.common.na;

    customers_data.rate = targetedData?.attributes?.billingInformation?.rate
      ? targetedData?.attributes?.billingInformation?.rate +
        ' ' +
        (targetedData?.attributes?.billingInformation?.currency
          ? targetedData?.attributes?.billingInformation?.currency
          : '')
      : t.common.na;

    customers_data.currency = targetedData?.attributes?.billingInformation
      ?.currency
      ? targetedData?.attributes?.billingInformation?.currency
      : t.common.na;

    customers_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        ' ' +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      customers_data.id,
      customers_data.name,
      customers_data.description,
      customers_data.email,

      customers_data.phone,
      customers_data.locations,
      customers_data.website,
      customers_data.service_type,

      customers_data.industry,
      customers_data.contact_name,
      customers_data.contact_email,
      customers_data.contact_phone,

      customers_data.billing_name,
      customers_data.country,
      customers_data.city,
      customers_data.postal_code,

      customers_data.address,
      customers_data.rate,
      customers_data.currency,
      customers_data.createdBy,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column

      { wch: 25 }, // Description column
      { wch: 15 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 10 }, // Created By column
      { wch: 10 }, // Created At column
      { wch: 25 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      targetedData.attributes.name || t.customers.customer,
    );
  };

  const generatePDF = () => {
    let csvData = [];

    let customers_data = [];

    customers_data.id = targetedData.id;
    customers_data.name = targetedData.attributes.name || t.common.na;

    customers_data.description = targetedData?.attributes?.description
      ? targetedData.attributes.description.replace(/\s+/g, ' ').trim()
      : t.common.na;

    customers_data.email = targetedData?.attributes?.email
      ? targetedData.attributes?.email
      : t.common.na;

    customers_data.phone = targetedData?.attributes?.phone
      ? `+${targetedData?.attributes?.phone}`
      : t.common.na;

    customers_data.locations = targetedData?.attributes?.location?.data?.id
      ? targetedData?.attributes?.location?.data?.attributes?.name
      : t.common.na;

    customers_data.website = targetedData?.attributes?.website
      ? targetedData.attributes?.website
      : t.common.na;

    customers_data.service_type = targetedData?.attributes?.serviceType
      ? targetedData.attributes?.serviceType
      : t.common.na;

    customers_data.industry = targetedData?.attributes?.Industry
      ? targetedData.attributes?.Industry
      : t.common.na;

    customers_data.contact_name = targetedData?.attributes?.contactInformation
      ?.name
      ? targetedData?.attributes?.contactInformation?.name
      : t.common.na;

    customers_data.contact_email = targetedData?.attributes?.contactInformation
      ?.email
      ? targetedData?.attributes?.contactInformation?.email
      : t.common.na;

    customers_data.contact_phone = targetedData?.attributes?.contactInformation
      ?.phone
      ? `+${targetedData?.attributes?.contactInformation?.phone}`
      : t.common.na;

    customers_data.billing_name = targetedData?.attributes?.billingInformation
      ?.name
      ? targetedData?.attributes?.billingInformation?.name
      : t.common.na;

    customers_data.country = targetedData?.attributes?.billingInformation
      ?.country
      ? Country.getCountryByCode(
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.city = targetedData?.attributes?.billingInformation?.city
      ? State.getStateByCodeAndCountry(
          targetedData?.attributes?.billingInformation?.city,
          targetedData?.attributes?.billingInformation?.country
        )?.name
      : t.common.na;

    customers_data.postal_code = targetedData?.attributes?.billingInformation
      ?.postalCode
      ? targetedData?.attributes?.billingInformation?.postalCode
      : t.common.na;

    customers_data.address = targetedData?.attributes?.billingInformation
      ?.address
      ? targetedData?.attributes?.billingInformation?.address
      : t.common.na;

    customers_data.rate = targetedData?.attributes?.billingInformation?.rate
      ? targetedData?.attributes?.billingInformation?.rate +
        ' ' +
        (targetedData?.attributes?.billingInformation?.currency
          ? targetedData?.attributes?.billingInformation?.currency
          : '')
      : t.common.na;

    customers_data.currency = targetedData?.attributes?.billingInformation
      ?.currency
      ? targetedData?.attributes?.billingInformation?.currency
      : t.common.na;

    customers_data.createdBy = targetedData?.attributes?.createdByUser?.data
      ? targetedData?.attributes?.createdByUser.data?.attributes?.firstName +
        ' ' +
        targetedData?.attributes?.createdByUser?.data?.attributes?.lastName
      : t.common.na;

    csvData.push([
      customers_data.id,
      customers_data.name,
      customers_data.description,
      customers_data.email,

      customers_data.phone,
      customers_data.locations,
      customers_data.website,
      customers_data.service_type,

      customers_data.industry,
      customers_data.contact_name,
      customers_data.contact_email,
      customers_data.contact_phone,

      customers_data.billing_name,
      customers_data.country,
      customers_data.city,
      customers_data.postal_code,

      customers_data.address,
      customers_data.rate,
      customers_data.currency,
      customers_data.createdBy,
    ]);
    const columnWidths = [, 30, 30];

    // const columnWidths = [
    //   10,
    //   20,
    //   ,
    //   20,
    //   50,
    //   20,
    //   22,
    //   15,
    //   20,
    //   ,
    //   ,
    //   25,
    //   ,
    //   ,
    //   ,
    //   45,
    //   20,
    // ];
    DownloadPdfFileWithData(
      headers,
      csvData,
      customers_data.name || t.customers.customer,
      columnWidths,
      'A2'
    );
  };

  const panes = [
    {
      menuItem: t.customers.table.details,
      pane: {
        key: 'details',
        content: (
          <div className="pane-body">
            <Details
              untoggle={untoggle}
            
              targetedData={targetedData}
            />
          </div>
        ),
      },
    },
    {
      menuItem:t.customers.form.billing_information,
      pane: {
        key: 'customers',
        content: (
          <div className="pane-body">
            <Customers
              untoggle={untoggle}
             
              targetedData={targetedData?.attributes?.billingInformation}
            />
          </div>
        ),
      },
    },
    // {
    //   menuItem: t.workOrders.details_view.log,
    //   pane: {
    //     key: 'log',
    //     content: (
    //       <div className="pane-body">
    //         <Logs
    //           targetedData={targetedData}
    //         />
    //       </div>
    //     ),
    //   },
    // },
  ];
  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? 'aside-modal preview active procedure' : 'aside-modal preview procedure'
        }
      >
         <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
        <div className="modal-header detail-header">
          <div className="flexbox justify-between align-center">
            <div className="name">
              {t.customers.customer} #{data?.attributes?.countId}
            </div>
            <div className="flexbox align-center detail-header-buttons">
              <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowPdfTooltip(true)}
                onMouseLeave={() => setShowPdfTooltip(false)}
                onClick={() => generatePDF()}
              >
                <DownloadIconBucket />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showPdfTooltip}
                  textBoxWidth="60px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_pdf}
                  </span>
                </ToolTip>
              </span>
              {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowExclTooltip(true)}
                onMouseLeave={() => setShowExclTooltip(false)} 
                 onClick={() => downloadXLS()}
              >
                <FileDownloadIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showExclTooltip}
                  textBoxWidth="67px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_excel}
                  </span>
                </ToolTip>
              </span> */}
              {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShareTooltip(true)}
                onMouseLeave={() => setShareTooltip(false)}
              >
                <ShareIcon fill={'#788CA5'} />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showShareTooltip}
                  textBoxWidth="40px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.share}
                  </span>
                </ToolTip>
              </span> */}
              {userPermissions?.customer?.edit ||
              checkForPermission(
                targetedData?.attributes?.createdByUser?.data?.id
              ) ? (
                <span
                  className="header-button c-pointer mt-1"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setEditTooltip(true)}
                  onMouseLeave={() => setEditTooltip(false)}
                  onClick={() => {
                    edit();
                    setPopupIsOpen(false);
                  }}
                >
                  <EditTextIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="bottom center"
                    show={showEditTooltip}
                    textBoxWidth="33px"
                    arrowAlign="center"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.edit}
                    </span>
                  </ToolTip>
                </span>
              ) : null}
              {userPermissions?.customer?.delete
              // checkForPermission(
              //   targetedData?.attributes?.createdByUser?.data?.id
              // ) 
              ? (
                <span
                  className="header-button  c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setDeleteTooltip(true)}
                  onMouseLeave={() => setDeleteTooltip(false)}
                  onClick={() => {
                    setDeleteModal(true);
                    setDeletePermissionModal(true);
                    setPopupIsOpen(false);
                  }}
                >
                  <DeleteIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="bottom center"
                    show={showDeleteTooltip}
                    textBoxWidth="41px"
                    arrowAlign="center"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.delete}
                    </span>
                  </ToolTip>
                </span>
              ) : null}
              <span
                 className={`procedure-header-button c-pointer ${selectedLanguage == "en" ? "ml-1" : "mr-3"}`}
                style={{ position: 'relative' }}
                onMouseOver={() => setCancleTooltip(true)}
                onMouseLeave={() => setCancleTooltip(false)}
                onClick={() => {
                  untoggle();
                }}
              >
                <CloseIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position={
                    selectedLanguage == 'en' ? 'bottom center' : 'bottom center'
                  }
                  show={showCancleTooltip}
                  textBoxWidth="36px"
                  arrowAlign="center"
                  padding="5px 5px 5px 7px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.close}
                  </span>
                </ToolTip>
              </span>
              {/* <Popup
                trigger={
                  <Icon
                    name="ellipsis vertical"
                    size="large"
                    title="More"
                    color="black"
                    onClick={() => setPopupIsOpen(true)}
                    id="popupTrigger"
                  />
                }
                ref={popUpRef}
                open={popupIsOpen}
                on="click"
                content={
                  <Menu secondary vertical>
                    {userPermissions?.customer?.edit ? (
                      <Menu.Item
                        name={t.common.edit}
                        onClick={() => {
                          edit();
                          setPopupIsOpen(false);
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    ) : null}

                   
                    {userPermissions?.customer?.delete ? (
                      <Menu.Item
                        name={t.common.delete}
                        onClick={() => {
                          setDeleteModal(true);
                          setPopupIsOpen(false);
                        }}
                        style={{ color: '#ff3835', cursor: 'pointer' }}
                      />
                    ) : null}
                  </Menu>
                }
                position="bottom right"
              /> */}
            </div>
          </div>
        </div>
        <div className="body">
          {data ? (
            <Tab
              // className={` ${
              //   selectedLanguage == 'en' ? 'customer-tab' : 'customer-tab-rtl'
              // }`}
              panes={panes}
              renderActiveOnly={false}
            />
          ) : (
            <Dimmer active inverted>
              <Loader inverted>{t.common.loading}</Loader>
            </Dimmer>
          )}

          {/* {data ? (
            <div className="pane-body">
              <div className="preview-details-wrapper">
                <div className="mega-details" style={{ marginBottom: '30px' }}>
                  <div
                    className="flexbox align-center"
                    style={{ fontSize: '15px' }}
                  >
                    <span style={{ marginRight: '2px' }}>
                      {t.common.created_by}:
                    </span>
                    {createdByName}
                    <span style={{ margin: '0 2px' }}>@</span>
                    <Moment
                      format="DD-MM-YYYY LT"
                      date={data?.attributes?.updatedAt}
                    />
                  </div>
                  <div style={{ marginBottom: '15px' }}>
                    <div
                      className="flexbox align-center"
                      style={{ margin: '20px 0' }}
                    >
                      <div>
                        <Image
                          circular={data?.attributes?.logo.data ? false : true}
                          size="tiny"
                          title={data?.attributes?.name}
                          style={
                            data?.attributes?.logo.data
                              ? {}
                              : { height: '80px', objectFit: 'cover' }
                          }
                          src={
                            data?.attributes?.logo.data
                              ? IMAGES_URL +
                                data?.attributes?.logo.data.attributes.url
                              : LetteredAvatarImageUrl(
                                  data?.attributes?.name + ''
                                )
                          }
                        />
                      </div>
                      <div style={{ marginLeft: '10px' }}>
                        <Header as="h2" content={data?.attributes?.name} />
                      </div>
                    </div>

                    <p style={{ marginTop: 0, fontSize: '14px' }}>
                      {data?.attributes?.description}
                    </p>
                  </div>
                </div>

                <Divider />
                <ul className="details-list">
                  <Grid columns="equal">
                    <Grid.Column>
                      <li className="list-item">
                        <label className="item-label">
                          {t.customers.form.email}
                        </label>
                        <div className="item-value">
                          {data?.attributes?.email
                            ? data?.attributes?.email
                            : t.common.na}
                        </div>
                      </li>
                    </Grid.Column>
                    <Grid.Column>
                      <li className="list-item">
                        <label className="item-label">
                          {t.customers.form.phone}
                        </label>
                        <div className="item-value" dir="ltr">
                          {data?.attributes?.phone
                            ? `+${data?.attributes?.phone}`
                            : t.common.na}
                        </div>
                      </li>
                    </Grid.Column>
                  </Grid>
                  <Divider />
                  <Grid columns="equal">
                    <Grid.Column>
                      <li className="list-item">
                        <label className="item-label">
                          {t.customers.form.location}
                        </label>
                        <div className="item-value">
                          {data?.attributes?.location?.data ? (
                            <div
                              onClick={() =>
                                history.push(
                                  `/locations?id=${data?.attributes.location?.data?.id}`
                                )
                              }
                            >
                              <Icon name="map outline" color="blue" />
                              <div
                                className="buttun-type-link"
                                basic
                                style={{ marginLeft: '4px' }}
                              >
                                {
                                  data?.attributes?.location?.data?.attributes
                                    ?.name
                                }
                                <Icon name="angle right" />
                              </div>
                            </div>
                          ) : (
                            t.common.na
                          )}
                        </div>
                      </li>
                    </Grid.Column>
                    <Grid.Column>
                      <li className="list-item">
                        <label className="item-label">
                          {t.customers.form.website}
                        </label>
                        <div className="item-value">
                          {data?.attributes?.website
                            ? data?.attributes?.website
                            : t.common.na}
                        </div>
                      </li>
                    </Grid.Column>
                  </Grid>
                  <Divider />
                  <Grid columns="equal">
                    <Grid.Column>
                      <li className="list-item">
                        <label className="item-label">
                          {t.customers.form.service_type}
                        </label>
                        <div className="item-value">
                          {data?.attributes?.serviceType
                            ? data?.attributes?.serviceType
                            : t.common.na}
                        </div>
                      </li>
                    </Grid.Column>
                    <Grid.Column>
                      <li className="list-item">
                        <label className="item-label">
                          {t.common.industry}
                        </label>
                        <div className="item-value">
                          {data?.attributes?.Industry
                            ? data?.attributes?.Industry
                            : t.common.na}
                        </div>
                      </li>
                    </Grid.Column>
                  </Grid>
                  <Divider />

                  {data?.attributes?.billingInformation ? (
                    <>
                      <Header
                        as="h3"
                        content={t.customers.form.billing_information}
                      />
                      <Grid columns="equal">
                        <Grid.Column>
                          <li className="list-item">
                            <label className="item-label">
                              {t.customers.form.name}
                            </label>
                            <div className="item-value">
                              {data?.attributes?.billingInformation?.name
                                ? data?.attributes?.billingInformation?.name
                                : t.common.na}
                            </div>
                          </li>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                      </Grid>
                      <Divider />
                      <Grid columns="equal">
                        <Grid.Column>
                          <li className="list-item">
                            <label className="item-label">
                              {t.customers.form.country}
                            </label>
                            <div className="item-value">
                              {data?.attributes?.billingInformation?.country
                                ? data?.attributes?.billingInformation?.country
                                : t.common.na}
                            </div>
                          </li>
                        </Grid.Column>
                        <Grid.Column>
                          <li className="list-item">
                            <label className="item-label">
                              {t.customers.form.city}
                            </label>
                            <div className="item-value">
                              {data?.attributes?.billingInformation?.city
                                ? data?.attributes?.billingInformation?.city
                                : t.common.na}
                            </div>
                          </li>
                        </Grid.Column>
                      </Grid>
                      <Divider />
                      <Grid columns="equal">
                        <Grid.Column>
                          <li className="list-item">
                            <label className="item-label">
                              {t.customers.form.postal_code}
                            </label>
                            <div className="item-value">
                              {data?.attributes?.billingInformation?.postalCode
                                ? data?.attributes?.billingInformation
                                    ?.postalCode
                                : t.common.na}
                            </div>
                          </li>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                      </Grid>
                      <Divider />
                      <Grid columns="equal">
                        <Grid.Column>
                          <li className="list-item">
                            <label className="item-label">
                              {t.customers.form.address}
                            </label>
                            <div className="item-value">
                              {data?.attributes?.billingInformation?.address
                                ? data?.attributes?.billingInformation?.address
                                : t.common.na}
                            </div>
                          </li>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                      </Grid>
                      <Divider />
                      <Grid columns="equal">
                        <Grid.Column>
                          <li className="list-item">
                            <label className="item-label">
                              {t.customers.form.address_line_2}
                            </label>
                            <div className="item-value">
                              {data?.attributes?.billingInformation
                                ?.addressLine2
                                ? data?.attributes?.billingInformation
                                    ?.addressLine2
                                : t.common.na}
                            </div>
                          </li>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                      </Grid>
                      <Divider />
                      <Grid columns="equal">
                        <Grid.Column>
                          <li className="list-item">
                            <label className="item-label">
                              {t.customers.form.address_line_3}
                            </label>
                            <div className="item-value">
                              {data?.attributes?.billingInformation
                                ?.addressLine3
                                ? data?.attributes?.billingInformation
                                    ?.addressLine3
                                : t.common.na}
                            </div>
                          </li>
                        </Grid.Column>
                        <Grid.Column></Grid.Column>
                      </Grid>
                      <Divider />
                      <Grid columns="equal">
                        <Grid.Column>
                          <li className="list-item">
                            <label className="item-label">
                              {t.customers.form.rate}
                            </label>
                            <div className="item-value">
                              {data?.attributes?.billingInformation?.rate
                                ? data?.attributes?.billingInformation?.rate
                                : t.common.na}
                            </div>
                          </li>
                        </Grid.Column>
                        <Grid.Column>
                          <li className="list-item">
                            <label className="item-label">
                              {t.customers.form.currency}
                            </label>
                            <div className="item-value">
                              {data?.attributes?.billingInformation?.currency
                                ? data?.attributes?.billingInformation?.currency
                                : t.common.na}
                            </div>
                          </li>
                        </Grid.Column>
                      </Grid>
                      <Divider />
                    </>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            </div>
          ) : (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          )} */}
        </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => {
          setData();
          untoggle();
        }}
      ></div>

      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.customers.customer}
      >
        <span>{t.customers.form.delete_customer_statement}</span>
      </DeletePermissionModal>

      {/* <Modal
        size="tiny"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper red">
              <Icon name="close" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              {t.customers.form.delete_customer_statement}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setDeleteModal(false);
              deleteHandler();
            }}
          >
            {t.common.delete}
          </Button>
          <Button color="black" onClick={() => setDeleteModal(false)}>
            {t.common.keep}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="tiny"
        open={DeleteSuccessModal}
        onClose={() => {
          setDeleteSuccessModal('close');
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              {deleteErrorMessage ? (
                <Icon name="close" color="red" size="huge" />
              ) : (
                <Icon name="check" color="white" size="huge" />
              )}
            </div>
            <p className="status-modal-popup-message">
              {deleteErrorMessage
                ? t.customers.form.customer_delete_related_error
                : t.customers.form.customer_delete_success}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() =>
              toggleDeleteSuccessModal(deleteErrorMessage ? '' : 'close')
            }
          >
            {t.common.ok}
          </Button>
        </Modal.Actions>
      </Modal> */}
    </>
  );
}

export default Preview;
