import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import Animation from "../../components/common/CustomLoader";
import Tooltip from "react-power-tooltip";
import {
  ClockdBlueIcon,
  ClockdLightBlueIcon,
  CloseIcon,
  DashboardWOStatusIcon,
  HashBlueIcon,
  HashSvg,
  NoDashboardIcon,
  PercentageDashBoardBlueIcon,
  PercentageLightBlueIcon,
} from "../../../Assets/Icons/svg";
import { Grid, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import MaintenanceCategory from "./MaintenanceCharts/MaintenanceCategory";
import MTBF from "./MaintenanceCharts/MTBF";
import MTTR from "./MaintenanceCharts/MTTR";
import PurchasePrice from "./MaintenanceCharts/purchasePrice";
import PlannedChart from "./MaintenanceCharts/plannedChart";
import un_happy from "../../../Assets/Icons/un_happy.png";
import happy from "../../../Assets/Icons/happy.png";
import { getMaintenanceDashBoardData } from "../../config/functions";
import NoDashboardData from "./noDashboardData";
const MaintenanceManagement = ({ hasNoFilterValues }) => {
  const t = useSelector(selectTranslations);
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  let isHoverDisabled;
  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });
  const [chartConfigCategory, setChartConfigCategory] = useState({
    series: [
      {
        name: t.dashboard.maintenance_card.total_cost,
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50px", // Adjust this percentage to control overall column width
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      // colors: [
      //   "#5B48F6",  // Color for the first bar
      //   "#5ACADA",  // Color for the second bar
      //   "#8AB4F9",  // Color for the third bar
      //   "#6A82F7",  // Color for the fourth bar
      //   "#73FBF5",  // Color for the fifth bar
      //   "#002EF5",  // Color for the sixth bar
      // ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: t.dashboard.asset_card.asset_category_label,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "12px",
            fontWeight: "400", // Set title font size
          },
        },
      },
      yaxis: {
        title: {
          text:
            t.dashboard.maintenance_card.total_cost +
            " " +
            `(${organisationCurrency})`,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "12px",
            fontWeight: "400", // Set title font size
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          const value = w.config.series[seriesIndex].data[dataPointIndex].y; // Get the value
          const label = t.dashboard.asset_card.m_price; // Get the label from categories
          const color =
            w.config.series[seriesIndex].data[dataPointIndex].fillColor; // Get the color from the series color array

          return `
            <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; ${
            selectedLanguage == "en" ? "margin-right: 4px" : "margin-left: 4px"
          }"></div>
                <span>${label} : </span>&nbsp; <b>${value} ${organisationCurrency}</b>
              </div>
            </div>
          `;
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false, // Disable horizontal lines on the y-axis
          },
        },
      },
    },
  });

  const [maintenanceData, setMaintenanceData] = useState({
    MTBF: [],
    MTTR: [],
    PPM: [],
    maintenanceCostPerAssetCategory: [],
    maintenanceVsPurchasePrice: [],
    preventativeMaintenance: {},
    reactiveMaintenance: {},
    totalCostOfMaintenance: {},
    totalDownTimeEvents: {},
  });

  const [chartConfigMTBF, setChartConfigMTBF] = useState({
    series: [
      {
        name: t.dashboard.asset_card.asset_no,
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
        //         events: {
        //           dataPointSelection: function(
        //             event,
        //             chartContext,
        //             { seriesIndex, dataPointIndex, w }
        //           ) {
        //             isHoverDisabled = true;

        //             const value = w.config.series[seriesIndex].data[dataPointIndex];
        //             const label = w.config.xaxis.categories[dataPointIndex];
        //             const color = w.config.colors[seriesIndex];
        //             const labelData =t.dashboard.asset_card.asset_name;
        //             const mebf_label =t.dashboard.maintenance_card.mtbf_lebal;
        //             const selectedData = maintenanceData?.MTBF[dataPointIndex];
        //             const assets = selectedData.uniqueAssets;
        //             // Remove any existing click tooltip
        //             removeTooltip();

        //             // Create a new custom tooltip div
        //             const tooltipDiv = document.createElement("div");
        //             tooltipDiv.className = "click-tooltip";
        //             tooltipDiv.style.position = "absolute";
        //             tooltipDiv.style.background = "white";
        //             tooltipDiv.style.boxShadow = "5px 4px 6px #00000026";
        //             tooltipDiv.style.border = "1px solid white";
        //             tooltipDiv.style.color = "black";
        //             tooltipDiv.style.padding = "5px";
        //             tooltipDiv.style.borderRadius = "8px";
        //             tooltipDiv.style.zIndex = "1000";

        //             const assetsHTML = assets.map(asset => `

        //  <div style="display: flex; gap: 5px;">
        //                 <div style="display: flex; flex-direction: column; gap: 1px; align-items: start; border-right: 2px solid #E4E6EA; padding-right: 20px;">
        //                   <div style="font-size: 12px; font-weight: 500; color: #44546F;">Asset Name</div>
        //                   <div style="font-size: 12px; cursor:pointer; color: #0C66E4; font-weight: bold;">${asset.assetName}</div>
        //                 </div>
        //                 <div style="border-bottom: 1px solid #ccc;"></div>
        //                 <div style="display: flex; flex-direction: column; gap: 1px; align-items: start;">
        //                   <div style="font-size: 12px; font-weight: 500; color: #44546F;">MTBF (hours)</div>
        //                   <div style="font-size: 12px; color: #172B4D; font-weight: 400;">${asset.totalMTBF}</div>
        //                 </div>
        //               </div>

        //             `).join("");

        //             tooltipDiv.innerHTML = `
        //               <div style="display: flex; flex-direction: column; gap: 5px;">
        //                 ${assetsHTML}
        //               </div>
        //             `;

        //             // Position the tooltip near the click position
        //             const { pageX, pageY } = event;
        //             tooltipDiv.style.left = `${pageX + 10}px`;
        //             tooltipDiv.style.top = `${pageY - 40}px`;

        //             // Append the tooltip to the body
        //             document.body.appendChild(tooltipDiv);

        //             // Add event listeners to the tooltip for mouseenter and mouseleave
        //             tooltipDiv.addEventListener("mouseenter", () => {
        //               isHoverDisabled = true;  // Prevent removal when hovering over the tooltip
        //             });

        //             tooltipDiv.addEventListener("mouseleave", () => {
        //               removeTooltip();
        //               isHoverDisabled = false;  // Enable hover tooltips again when leaving the tooltip
        //             });
        //           },

        //           dataPointMouseLeave: function() {
        //             // Do nothing here, removal will be handled by tooltip hover events
        //           },
        //         },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50px",
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: ["#0A55BD"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: t.dashboard.asset_card.asset_category_label,
          style: {
            color: "#788ca5",
            fontSize: "12px",
            fontWeight: "400",
          },
        },
      },
      yaxis: {
        title: {
          text: t.dashboard.maintenance_card.mtbf_lebal,
          style: {
            color: "#788ca5",
            fontSize: "12px",
            fontWeight: "400",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          if (isHoverDisabled) {
            return "";
          }

          const value = w.config.series[seriesIndex].data[dataPointIndex];
          const label = t.dashboard.maintenance_card.mtbf;
          const color = w.config.colors[seriesIndex];
          return `
            <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 4px;"></div>
                <span>${label} : </span>&nbsp; ${value}
              </div>
            </div>
          `;
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  });

  const [chartConfigMTTR, setChartConfigMTTR] = useState({
    series: [
      {
        name: t.dashboard.asset_card.asset_no,
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: function(
            event,
            chartContext,
            { seriesIndex, dataPointIndex, w }
          ) {
            isHoverDisabled = true;

            const value = w.config.series[seriesIndex].data[dataPointIndex];
            const label = w.config.xaxis.categories[dataPointIndex];
            const color = w.config.colors[seriesIndex];
            const labelData = t.dashboard.asset_card.asset_name;
            const mttr_label = t.dashboard.maintenance_card.mttr_hrs;
            // Remove any existing click tooltip
            removeTooltip();

            // Create a new custom tooltip div
            const tooltipDiv = document.createElement("div");
            tooltipDiv.className = "click-tooltip";
            tooltipDiv.style.position = "absolute";
            tooltipDiv.style.background = "white";
            tooltipDiv.style.boxShadow = "5px 4px 6px #00000026";
            tooltipDiv.style.border = "1px solid white";
            tooltipDiv.style.color = "black";
            tooltipDiv.style.padding = "5px";
            tooltipDiv.style.borderRadius = "8px";
            tooltipDiv.style.zIndex = "1000";

            tooltipDiv.innerHTML = `
              <div style="display: flex; gap: 5px;">
                <div style="display: flex; flex-direction: column; gap: 1px; align-items: start; border-right: 2px solid #E4E6EA; padding-right: 20px;">
                  <div style="font-size: 12px; font-weight: 500; color: #44546F;">${labelData}</div>
                  <div style="font-size: 12px; cursor:pointer; color: #0C66E4; font-weight: bold;">${label}</div>
                  <div style="font-size: 12px; cursor:pointer; color: #0C66E4; font-weight: bold;">${label}</div>
                  <div style="font-size: 12px; cursor:pointer; color: #0C66E4; font-weight: bold;">${label}</div>
                </div>
                <div style="border-bottom: 1px solid #ccc;"></div>
                <div style="display: flex; flex-direction: column; gap: 1px; align-items: start;">
                  <div style="font-size: 12px; font-weight: 500; color: #44546F;">${mttr_label}</div>
                  <div style="font-size: 12px; color: #172B4D; font-weight: 400;">${value}</div>
                  <div style="font-size: 12px; color: #172B4D; font-weight: 400;">${value}</div>
                  <div style="font-size: 12px; color: #172B4D; font-weight: 400;">${value}</div>
                </div>
              </div>
            `;

            // Position the tooltip near the click position
            const { pageX, pageY } = event;
            tooltipDiv.style.left = `${pageX + 10}px`;
            tooltipDiv.style.top = `${pageY - 40}px`;

            // Append the tooltip to the body
            document.body.appendChild(tooltipDiv);

            // Add event listeners to the tooltip for mouseenter and mouseleave
            tooltipDiv.addEventListener("mouseenter", () => {
              isHoverDisabled = true; // Prevent removal when hovering over the tooltip
            });

            tooltipDiv.addEventListener("mouseleave", () => {
              removeTooltip();
              isHoverDisabled = false; // Enable hover tooltips again when leaving the tooltip
            });
          },

          dataPointMouseLeave: function() {
            // Do nothing here, removal will be handled by tooltip hover events
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50px",
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: ["#0A55BD"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "asset",
          "demo",
          "test",
          "123",
          "HVAC",
          "Cost",
          "ljikk",
          "dsd",
          "dsd",
          "nbmg",
          "dbsd",
          "sdsc",
        ],
        title: {
          text: t.dashboard.asset_card.asset_category_label,
          style: {
            color: "#788ca5",
            fontSize: "12px",
            fontWeight: "400",
          },
        },
      },
      yaxis: {
        title: {
          text: t.dashboard.maintenance_card.mttr_lebal,
          style: {
            color: "#788ca5",
            fontSize: "12px",
            fontWeight: "400",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          if (isHoverDisabled) {
            return "";
          }

          const value = w.config.series[seriesIndex].data[dataPointIndex];
          const label = t.dashboard.maintenance_card.mttr_time;
          const color = w.config.colors[seriesIndex];
          return `
            <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 4px;"></div>
                <span>${label} : </span>&nbsp; ${value}
              </div>
            </div>
          `;
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },

      annotations: {
        yaxis: [
          {
            y: 5, // Threshold value
            borderColor: "#4FAB68", // Line color
            borderWidth: 5, // Set border width to 20px
            strokeDashArray: 0, // Make sure the line is solid
          },
        ],
      },
    },
  });

  const [chartConfigPurchasePrice, setChartConfigPurchasePrice] = useState({
    series: [
      {
        name: t.dashboard.maintenance_card.purchase_price,
        data: [],
      },
      {
        name: t.dashboard.maintenance_card.maintenance_price,
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
        events: {
          dataPointSelection: function(
            event,
            chartContext,
            { seriesIndex, dataPointIndex, w }
          ) {
            isHoverDisabled = true;

            const value = w.config.series[seriesIndex].data[dataPointIndex];
            const label = w.config.xaxis.categories[dataPointIndex];
            const color = w.config.colors[seriesIndex];
            const labelData = t.dashboard.asset_card.asset_name;
            const purchase_price_label =
              t.dashboard.maintenance_card.purchase_price;
            const maintenance_label =
              t.dashboard.maintenance_card.maintenance_price;
            // Remove any existing click tooltip
            removeTooltip();

            // Create a new custom tooltip div
            const tooltipDiv = document.createElement("div");
            tooltipDiv.className = "click-tooltip";
            tooltipDiv.style.position = "absolute";
            tooltipDiv.style.background = "white";
            tooltipDiv.style.boxShadow = "5px 4px 6px #00000026";
            tooltipDiv.style.border = "1px solid white";
            tooltipDiv.style.color = "black";
            tooltipDiv.style.padding = "5px";
            tooltipDiv.style.borderRadius = "8px";
            tooltipDiv.style.zIndex = "1000";

            tooltipDiv.innerHTML = `
              <div style="display: flex; gap: 5px;">
                <div style="display: flex; flex-direction: column; gap: 1px; align-items: start; border-right: 2px solid #E4E6EA; padding-right: 20px;">
                  <div style="font-size: 12px; font-weight: 500; color: #44546F;">${labelData}</div>
                  <div style="font-size: 12px; cursor:pointer; color: #0C66E4; font-weight: bold;">${label}</div>
                </div>
              
               <div style="display: flex; flex-direction: column; gap: 1px; align-items: start; border-right: 2px solid #E4E6EA; padding-right: 20px;">
                  <div style="font-size: 12px; font-weight: 500; color: #44546F;">${purchase_price_label}</div>
                  <div style="font-size: 12px; color: #172B4D; font-weight: 400;">${value}</div>
                </div>
                <div style="display: flex; flex-direction: column; gap: 1px; align-items: start;">
                  <div style="font-size: 12px; font-weight: 500; color: #44546F;">${maintenance_label}</div>
                  <div style="font-size: 12px; color: #172B4D; font-weight: 400;">${value}</div>
                </div>
              </div>
            `;

            // Position the tooltip near the click position
            const { pageX, pageY } = event;
            tooltipDiv.style.left = `${pageX + 10}px`;
            tooltipDiv.style.top = `${pageY - 40}px`;

            // Append the tooltip to the body
            document.body.appendChild(tooltipDiv);

            // Add event listeners to the tooltip for mouseenter and mouseleave
            tooltipDiv.addEventListener("mouseenter", () => {
              isHoverDisabled = true; // Prevent removal when hovering over the tooltip
            });

            tooltipDiv.addEventListener("mouseleave", () => {
              removeTooltip();
              isHoverDisabled = false; // Enable hover tooltips again when leaving the tooltip
            });
          },

          dataPointMouseLeave: function() {
            // Do nothing here, removal will be handled by tooltip hover events
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "25%", // Adjust this percentage to control overall column width
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: ["#0C66E4", "#5494EE"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: t.dashboard.asset_card.asset_category_label,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "14px",
            fontWeight: "400", // Set title font size
          },
        },
        // labels: {
        //   formatter: function(value, timestamp, index) {
        //     const date = new Date(value);
        //     const day = date?.getDate();
        //     const month = date?.toLocaleString("default", { month: "short" });
        //     const year = date?.getFullYear();
        //     return `${day}, ${month} ${year}`;
        //   },
        // },
      },
      yaxis: {
        title: {
          text: t.dashboard.cards.cost_currency + `  (${organisationCurrency})`,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "14px",
            fontWeight: "400", // Set title font size
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          if (isHoverDisabled) {
            return "";
          }

          const value = w.config.series[seriesIndex].data[dataPointIndex];
          const label = w.config.series[seriesIndex].name;
          const color = w.config.colors[seriesIndex];
          return `
            <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 4px;"></div>
                <span>${label} : </span>&nbsp; ${value}
              </div>
            </div>
          `;
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  });

  const [chartOptionsPlanned, setChartOptionsPlanned] = useState({
    series: [
      {
        name: "",
        data: [], // Smaller bar inside the main bar
      },
      {
        name: "",
        data: [], // Base column (larger bar)
      },
    ],

    options: {
      chart: {
        type: "bar",
        stacked: true,
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: "50%", // Adjust this percentage to control overall column width
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: ["#E9F2FF", "#0C66E4"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: "Hours",
          style: {
            color: "#788ca5", // Set title color
            fontSize: "14px",
            fontWeight: "400", // Set title font size
          },
        },
      },
      // yaxis: {
      //   title: {
      //     text: t.dashboard.asset_card.asset_name,
      //     style: {
      //       color: "#788ca5", // Set title color
      //       fontSize: "14px",
      //       fontWeight: "400", // Set title font size
      //     },
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        shared: true, // Tooltip showing both values
        intersect: false,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const asset = w.globals.labels[dataPointIndex];
          const color = w.config.colors[1];
          const color_planned = w.config.colors[0];
          const actualHours = series[1][dataPointIndex];
          const plannedHours = series[0][dataPointIndex];
          const maintenance_label =
            t.dashboard.maintenance_card.maintenance_hours;
          const planned_label = t.dashboard.maintenance_card.planned_hours;
          const planned_maintenance_hours =
            t.dashboard.maintenance_card.planned_maintenance_hours;
          const totalHours = actualHours;
          const plannedPercentage = ((totalHours / plannedHours) * 100).toFixed(
            2
          );
          const imageToShow = plannedPercentage > 50 ? happy : un_happy;

          return `<div style="padding:10px; font-size:14px;">
              <b style="display: flex; align-items: start; margin-bottom:10px">${asset}</b>
              <div style="display: flex; align-items: center; margin-bottom:6px">
                <div style="margin-right: 4px;">
                  <img src="${imageToShow}" style="width: 16px; height: 16px;"/> 
                </div>
                <span>${planned_label} </span>&nbsp; <b>${plannedPercentage}%</b>
              </div>
              <div style="display: flex; align-items: center; margin-bottom:6px">
                <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 4px;"></div>
                <span>${maintenance_label}: </span>&nbsp; <b>${actualHours} h</b>
              </div>
              <div style="display: flex; align-items: center;">
                <div style="width: 8px; height: 8px; background-color: ${color_planned}; border-radius: 50%; margin-right: 4px;"></div>
                <span> ${planned_maintenance_hours}: </span>&nbsp; <b>${plannedHours}h</b>  
              </div>
            </div>`;
        },
      },
      grid: {
        borderColor: "#e7e7e7",
      },
    },
    // chart: {
    //   type: 'bar',
    //   stacked: true,
    //   toolbar: {
    //     show: true,
    //   },
    //   zoom: {
    //     enabled: false,
    //   },
    // },
    // plotOptions: {
    //   bar: {
    //     horizontal: true,
    //     barHeight: '50%',
    //     dataLabels: {
    //       position: 'top',
    //     },
    //   },
    // },
    // colors: ['#008FFB', '#00E396'], // First color is for actual, second is for planned
    // dataLabels: {
    //   enabled: true,
    //   formatter: (val, opts) => `${val}h`,
    //   offsetX: 0,
    //   style: {
    //     fontSize: '12px',
    //     colors: ['#fff'],
    //   },
    // },
    // tooltip: {
    //   custom: ({ series, seriesIndex, dataPointIndex, w }) => {
    //     const asset = w.globals.labels[dataPointIndex];
    //     const actualHours = series[0][dataPointIndex];
    //     const plannedHours = series[1][dataPointIndex];
    //     const totalHours = actualHours + plannedHours;
    //     const plannedPercentage = ((plannedHours / totalHours) * 100).toFixed(2);

    //     return (
    //       `<div style="padding:10px; font-size:14px;">
    //         <b>${asset}</b>
    //         <div>Planned Hours: ${plannedPercentage}%</div>
    //         <div>Maintenance Hours: ${actualHours}h</div>
    //         <div>Planned Maintenance Hours: ${plannedHours}h</div>
    //       </div>`
    //     );
    //   },
    // },
    // xaxis: {
    //   categories: ['HVAC 25 Ton', 'Conveyor', 'Compressor', 'Air Compressor', 'Conveyor', 'Air Compressor'],
    //   title: {
    //     text: 'Hours',
    //   },
    // },
    // legend: {
    //   position: 'top',
    //   horizontalAlign: 'left',
    // },
    // grid: {
    //   borderColor: '#e7e7e7',
    // },
  });

  const removeTooltip = () => {
    const existingTooltip = document.querySelector(".click-tooltip");
    if (existingTooltip) {
      existingTooltip.remove();
    }
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [loading, setLoading] = useState(false);
  const [showUnAssign, setShowUnAssign] = useState(false);
  const [percentageTooltip, setpercentageTooltip] = useState({
    box1P: false,
    box1C: false,
    box2P: false,
    box2C: false,
    box3P: false,
    box3C: false,
  });

  const [showPercentage, setShowPercentage] = useState({
    box1: true,
    box2: true,
    box3: true,
  });

  useEffect(
    () => {
      setLoading(true);
      const fetchMaintenanceDashboardData = async () => {
        let dashboardApiData = await getMaintenanceDashBoardData(
          organisationId
        );

        if (dashboardApiData) {
          setMaintenanceData({
            ...maintenanceData,
            MTBF: dashboardApiData?.MTBF,
            MTTR: dashboardApiData?.MTTR,
            PPM: dashboardApiData?.PPM,
            maintenanceCostPerAssetCategory:
              dashboardApiData?.maintenanceCostPerAssetCategory,
            maintenanceVsPurchasePrice:
              dashboardApiData?.maintenanceVsPurchasePrice,
            preventativeMaintenance: dashboardApiData?.preventativeMaintenance,
            reactiveMaintenance: dashboardApiData?.reactiveMaintenance,
            totalCostOfMaintenance: dashboardApiData?.totalCostOfMaintenance,
            totalDownTimeEvents: dashboardApiData?.totalDownTimeEvents,
          });
        } else {
          toast.error(t.common.something_wrong);
        }
        setLoading(false);
      };

      fetchMaintenanceDashboardData();
    },
    [
      // filterOptions,
      //  createSelectedDates, customSelectedDates,warrentySelectedDates,updatedDates
    ]
  );

  return (
    <>
      <div className="dashboard-container">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "1000px " }}
          >
            <Animation />
            {/* <Placeholder fluid>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder> */}
          </div>
        ) : (
          <div className="all-cards">
            {" "}
            <Grid columns={"equal"} className="graph-container m-0">
              <Grid.Column className="completion-column pl-0">
                <div className="completion-column-data">
                  <div className="completion-cotainer d-flex justify-content-between mt-2">
                    <div className="completion-title d-flex">
                      <span className="title">
                        {t.dashboard.maintenance_card.total_downtime}
                      </span>
                    </div>
                  </div>

                  {maintenanceData?.totalDownTimeEvents?.number ? (
                    <>
                      <div
                        style={{
                          padding: "20px 6px",

                          borderRadius: "4px",
                          marginInline: "13px",
                        }}
                        className="d-flex"
                      >
                        <div style={{ fontSize: "40px" }}>
                          {showPercentage?.box1 === true ? (
                            <>
                              {maintenanceData?.totalDownTimeEvents?.time || 0}{" "}
                              <span style={{ fontSize: "20px" }}>Hrs</span>
                            </>
                          ) : (
                            maintenanceData?.totalDownTimeEvents?.number || 0
                          )}
                        </div>
                        {hasNoFilterValues() && (
                          //     createSelectedDates?.length == 0 &&
                          //     warrentySelectedDates?.length == 0 &&  updatedDates?.length == 0 &&
                          //     customSelectedDates?.length == 0 &&
                          <div
                            style={{
                              fontSize: "14px",
                              color: "rgba(120, 140, 165, 1)",
                            }}
                          >
                            <span
                              className={
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }
                              style={
                                maintenanceData?.totalDownTimeEvents?.change ==
                                "increase"
                                  ? { color: "rgba(79, 171, 104, 1)" }
                                  : { color: "rgba(209, 64, 64, 1)" }
                              }
                            >
                              {" "}
                              {showPercentage?.box1 === false
                                ? maintenanceData?.totalDownTimeEvents
                                    ?.change == "decrease"
                                  ? maintenanceData?.totalDownTimeEvents
                                      ?.decrease
                                  : maintenanceData?.totalDownTimeEvents
                                      ?.increase
                                : `${
                                    maintenanceData?.totalDownTimeEvents
                                      ?.percentage
                                      ? maintenanceData?.totalDownTimeEvents
                                          ?.percentage
                                      : 0
                                  }%`}{" "}
                            </span>
                            {maintenanceData?.totalDownTimeEvents?.change ==
                            "decrease"
                              ? t.dashboard.box.decrease
                              : t.dashboard.box.increase}
                          </div>
                        )}
                      </div>

                      <div
                        style={{ gap: "10px" }}
                        className="mt-2 d-flex justify-content-center"
                      ></div>

                      <div
                        style={selectedLanguage == "en" ? { gap: "10px" } : {}}
                        className="mt-2 mb-4 d-flex justify-content-center"
                      >
                        <span
                          className="c-pointer"
                          onClick={() =>
                            setShowPercentage({
                              ...showPercentage,
                              box1: true,
                            })
                          }
                        >
                          <p
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              marginTop: "-10px",
                            }}
                            onMouseOver={() =>
                              setpercentageTooltip({
                                ...percentageTooltip,
                                box1P: true,
                              })
                            }
                            onMouseLeave={() =>
                              setpercentageTooltip({
                                ...percentageTooltip,
                                box1P: false,
                              })
                            }
                            className="ml-3 mt-1"
                          >
                            <Tooltip
                              color="#fff"
                              backgroundColor="#172B4D"
                              position={
                                selectedLanguage == "en" ? "left" : "right"
                              }
                              show={percentageTooltip?.box1P}
                              textBoxWidth="68px"
                              fontSize="10px"
                              fontWeight="400"
                              fontFamily="Roboto"
                              padding="8px 8px 8px 8px"
                              borderRadius="5px"
                              static
                              arrowAlign="start"
                              moveLeft="5px"
                              moveDown="3px"
                            >
                              <span
                                style={{ padding: "0px 10px" }}
                                className="work-order-tooltip"
                              >
                                {t.dashboard.percantage}
                              </span>
                            </Tooltip>
                            {showPercentage?.box1 ? (
                              <ClockdBlueIcon />
                            ) : (
                              <ClockdLightBlueIcon />
                            )}
                          </p>
                        </span>

                        <span
                          className="c-pointer"
                          onClick={() =>
                            setShowPercentage({
                              ...showPercentage,
                              box1: false,
                            })
                          }
                        >
                          <p
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              marginTop: "-10px",
                            }}
                            onMouseOver={() =>
                              setpercentageTooltip({
                                ...percentageTooltip,
                                box1C: true,
                              })
                            }
                            onMouseLeave={() =>
                              setpercentageTooltip({
                                ...percentageTooltip,
                                box1C: false,
                              })
                            }
                            className=" mt-1"
                          >
                            <Tooltip
                              color="#fff"
                              backgroundColor="#172B4D"
                              position={
                                selectedLanguage == "ar" ? "left" : "right"
                              }
                              show={percentageTooltip?.box1C}
                              textBoxWidth="50px"
                              fontSize="10px"
                              fontWeight="400"
                              fontFamily="Roboto"
                              padding="8px 8px 8px 8px"
                              borderRadius="5px"
                              static
                              arrowAlign="start"
                              moveRight="5px"
                              moveDown="3px"
                            >
                              <span
                                style={{ padding: "0px 10px" }}
                                className="work-order-tooltip"
                              >
                                {t.dashboard.number}
                              </span>
                            </Tooltip>
                            {showPercentage?.box1 ? (
                              <HashSvg />
                            ) : (
                              <HashBlueIcon />
                            )}
                          </p>{" "}
                        </span>
                      </div>
                    </>
                  ) : (
                    <NoDashboardData height="118px" />
                  )}
                </div>
              </Grid.Column>

              <Grid.Column className="completion-column pl-0">
                <div className="completion-column-data">
                  <div className="completion-cotainer d-flex justify-content-between mt-2">
                    <div className="completion-title d-flex">
                      <span className="title">
                        {t.dashboard.maintenance_card.reactive_maintenance}
                      </span>
                    </div>
                  </div>
                  {maintenanceData?.reactiveMaintenance?.number ? (
                    <>
                      <div
                        style={{
                          padding: "20px 6px",

                          borderRadius: "4px",
                          marginInline: "13px",
                        }}
                        className="d-flex"
                      >
                        <div style={{ fontSize: "40px" }}>
                          {maintenanceData?.reactiveMaintenance?.number
                            ? maintenanceData?.reactiveMaintenance?.number
                            : 0}
                        </div>
                        {hasNoFilterValues() && (
                          //  &&
                          //     createSelectedDates?.length == 0 &&
                          //     warrentySelectedDates?.length == 0 &&  updatedDates?.length == 0 &&
                          //     customSelectedDates?.length == 0 &&
                          <div
                            style={{
                              fontSize: "14px",
                              color: "rgba(120, 140, 165, 1)",
                            }}
                          >
                            <span
                              className={
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }
                              style={
                                maintenanceData?.reactiveMaintenance?.change ==
                                "decrease"
                                  ? { color: "rgba(209, 64, 64, 1)" }
                                  : { color: "rgba(79, 171, 104, 1)" }
                              }
                            >
                              {maintenanceData?.reactiveMaintenance?.change ==
                              "decrease"
                                ? maintenanceData?.reactiveMaintenance?.decrease
                                : maintenanceData?.reactiveMaintenance
                                    ?.increase}{" "}
                              {"  "}
                            </span>
                            {maintenanceData?.reactiveMaintenance?.change ==
                            "decrease"
                              ? t.dashboard.box.decrease
                              : t.dashboard.box.increase}
                          </div>
                        )}
                      </div>

                      <div
                        style={{ gap: "10px" }}
                        className="mt-2 mb-16 d-flex justify-content-center"
                      ></div>
                    </>
                  ) : (
                    <NoDashboardData height="118px" />
                  )}
                </div>
              </Grid.Column>

              <Grid.Column className="completion-column pl-0">
                <div className="completion-column-data">
                  <div className="completion-cotainer d-flex justify-content-between mt-2">
                    <div className="completion-title d-flex">
                      <span className="title">
                        {t.dashboard.maintenance_card.preventive_maintenance}
                      </span>
                    </div>
                  </div>
                  {maintenanceData?.preventativeMaintenance?.number ? (
                    <>
                      <div
                        style={{
                          padding: "20px 6px",

                          borderRadius: "4px",
                          marginInline: "13px",
                        }}
                        className="d-flex"
                      >
                        <div style={{ fontSize: "40px" }}>
                          {maintenanceData?.preventativeMaintenance?.number
                            ? maintenanceData?.preventativeMaintenance?.number
                            : 0}
                        </div>
                        {hasNoFilterValues() && (
                          //  &&
                          //     createSelectedDates?.length == 0 &&
                          //     warrentySelectedDates?.length == 0 &&  updatedDates?.length == 0 &&
                          //     customSelectedDates?.length == 0 &&
                          <div
                            style={{
                              fontSize: "14px",
                              color: "rgba(120, 140, 165, 1)",
                            }}
                          >
                            <span
                              className={
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }
                              style={
                                maintenanceData?.preventativeMaintenance
                                  ?.change == "decrease"
                                  ? { color: "rgba(209, 64, 64, 1)" }
                                  : { color: "rgba(79, 171, 104, 1)" }
                              }
                            >
                              {maintenanceData?.preventativeMaintenance
                                ?.change == "decrease"
                                ? maintenanceData?.preventativeMaintenance
                                    ?.decrease
                                : maintenanceData?.preventativeMaintenance
                                    ?.increase}{" "}
                              {"  "}
                            </span>
                            {maintenanceData?.preventativeMaintenance?.change ==
                            "decrease"
                              ? t.dashboard.box.decrease
                              : t.dashboard.box.increase}
                          </div>
                        )}
                      </div>

                      <div
                        style={{ gap: "10px" }}
                        className="mt-2 mb-16 d-flex justify-content-center"
                      ></div>
                    </>
                  ) : (
                    <NoDashboardData height="118px" />
                  )}
                </div>
              </Grid.Column>

              <Grid.Column className="completion-column pl-0">
                <div className="completion-column-data">
                  <div className="completion-cotainer d-flex justify-content-between mt-2">
                    <div className="completion-title d-flex">
                      <span className="title">
                        {t.dashboard.maintenance_card.total_cost_maintenance}
                      </span>
                    </div>
                  </div>
                  {maintenanceData?.totalCostOfMaintenance?.number ? (
                    <>
                      <div
                        style={{
                          padding: "20px 6px",

                          borderRadius: "4px",
                          marginInline: "13px",
                        }}
                        className="d-flex"
                      >
                        <div style={{ fontSize: "40px" }}>
                          {maintenanceData?.totalCostOfMaintenance?.number
                            ? maintenanceData?.totalCostOfMaintenance?.number
                            : 0}
                        </div>
                        {hasNoFilterValues() && (
                          //  &&
                          //     createSelectedDates?.length == 0 &&
                          //     warrentySelectedDates?.length == 0 &&  updatedDates?.length == 0 &&
                          //     customSelectedDates?.length == 0 &&
                          <div
                            style={{
                              fontSize: "14px",
                              color: "rgba(120, 140, 165, 1)",
                            }}
                          >
                            <span
                              className={
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }
                              style={
                                maintenanceData?.totalCostOfMaintenance
                                  ?.change == "decrease"
                                  ? { color: "rgba(209, 64, 64, 1)" }
                                  : { color: "rgba(79, 171, 104, 1)" }
                              }
                            >
                              {maintenanceData?.totalCostOfMaintenance
                                ?.change == "decrease"
                                ? maintenanceData?.totalCostOfMaintenance
                                    ?.decrease
                                : maintenanceData?.totalCostOfMaintenance
                                    ?.increase}{" "}
                              {"  "}
                            </span>
                            {maintenanceData?.totalCostOfMaintenance?.change ==
                            "decrease"
                              ? t.dashboard.box.decrease
                              : t.dashboard.box.increase}
                          </div>
                        )}
                      </div>

                      <div
                        style={{ gap: "10px" }}
                        className="mt-2 mb-16 d-flex justify-content-center"
                      ></div>
                    </>
                  ) : (
                    <NoDashboardData height="118px" />
                  )}
                </div>
              </Grid.Column>
            </Grid>
            <Grid columns={"equal"} className="graph-container m-0">
              <MaintenanceCategory
                setOpenModal={setOpenModal}
                setModalData={setModalData}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                openModal={openModal}
                chartConfigCategory={chartConfigCategory}
                // assetDashBoardData={assetDashBoardData}
                // setAssetDashBoardData={setAssetDashBoardData}
                setChartConfigCategory={setChartConfigCategory}
              />
            </Grid>
            <Grid columns={"equal"} className="graph-container m-0">
              <MTBF
                setOpenModal={setOpenModal}
                removeTooltip={removeTooltip}
                isHoverDisabled={isHoverDisabled}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                setModalData={setModalData}
                openModal={openModal}
                setChartConfigMTBF={setChartConfigMTBF}
                chartConfigMTBF={chartConfigMTBF}
              />
              <MTTR
                setOpenModal={setOpenModal}
                removeTooltip={removeTooltip}
                isHoverDisabled={isHoverDisabled}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                setModalData={setModalData}
                openModal={openModal}
                setChartConfigMTTR={setChartConfigMTTR}
                chartConfigMTTR={chartConfigMTTR}
              />
              {/* <WoAssigneeChart
                optionsData={optionsData}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                setModalData={setModalData}
              /> */}
            </Grid>
            <Grid columns={"equal"} className="graph-container m-0">
              <PurchasePrice
                setOpenModal={setOpenModal}
                removeTooltip={removeTooltip}
                isHoverDisabled={isHoverDisabled}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                setModalData={setModalData}
                openModal={openModal}
                setChartConfigPurchasePrice={setChartConfigPurchasePrice}
                chartConfigPurchasePrice={chartConfigPurchasePrice}
              />

              <PlannedChart
                setOpenModal={setOpenModal}
                setModalData={setModalData}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                openModal={openModal}
                setChartOptionsPlanned={setChartOptionsPlanned}
                chartOptionsPlanned={chartOptionsPlanned}
              />

              {/* <WoAssigneeChart
                optionsData={optionsData}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                setModalData={setModalData}
              /> */}
            </Grid>
          </div>
        )}
      </div>

      <Modal
        size="large"
        open={openModal}
        className="assets-modal new-dashboard-modal"
        style={{ height: "auto" }}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {modalData == "Category"
              ? t.dashboard.maintenance_card.category_maintenance
              : modalData == "MTBF"
              ? t.dashboard.maintenance_card.mtbf
              : modalData == "MTTR"
              ? t.dashboard.maintenance_card.mttr
              : modalData == "purchasePrice"
              ? t.dashboard.maintenance_card.purchase_price_title
              : modalData == "planned"
              ? t.dashboard.maintenance_card.planned_preventive
              : ""}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="pb-0">
          <div className="all-cards">
            {modalData == "Category" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                  <MaintenanceCategory
                setOpenModal={setOpenModal}
                setModalData={setModalData}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                openModal={openModal}
                chartConfigCategory={chartConfigCategory}
                setChartConfigCategory={setChartConfigCategory}
              />
              </Grid>
            ) : modalData == "MTBF" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <MTBF
                setOpenModal={setOpenModal}
                removeTooltip={removeTooltip}
                isHoverDisabled={isHoverDisabled}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                setModalData={setModalData}
                openModal={openModal}
                setChartConfigMTBF={setChartConfigMTBF}
                chartConfigMTBF={chartConfigMTBF}
              />
              </Grid>
            ) : modalData == "MTTR" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <MTTR
                setOpenModal={setOpenModal}
                removeTooltip={removeTooltip}
                isHoverDisabled={isHoverDisabled}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                setModalData={setModalData}
                openModal={openModal}
                setChartConfigMTTR={setChartConfigMTTR}
                chartConfigMTTR={chartConfigMTTR}
              />
                 
              </Grid>
            ) : modalData == "purchasePrice" ? (
              <Grid columns={"equal"} className="graph-container m-0">
              <PurchasePrice
                setOpenModal={setOpenModal}
                removeTooltip={removeTooltip}
                isHoverDisabled={isHoverDisabled}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                setModalData={setModalData}
                openModal={openModal}
                setChartConfigPurchasePrice={setChartConfigPurchasePrice}
                chartConfigPurchasePrice={chartConfigPurchasePrice}
              />
              </Grid>
            ) : modalData == "planned" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                 <PlannedChart
                setOpenModal={setOpenModal}
                setModalData={setModalData}
                maintenanceData={maintenanceData}
                setMaintenanceData={setMaintenanceData}
                openModal={openModal}
                setChartOptionsPlanned={setChartOptionsPlanned}
                chartOptionsPlanned={chartOptionsPlanned}
              />
              </Grid>
            
            ) : null}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default MaintenanceManagement;
