import React, { useState, useEffect, createRef, useRef } from 'react';
import {
  Dropdown,
  Input,
  Form,
  Button,
  Icon,
  TextArea,
  List,
  Modal,
} from 'semantic-ui-react';
import {
  createNewProcedure,
  removeFile,
  uploadFile,
} from '../../config/functions';

import { Resizable } from 're-resizable';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';

import FilterCloneList from './filter_clone_list';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import {
  AddIcon,
  CategoryInspection,
  CategoryMechanical,
  CloseIcon,
  CopyIcon,
  Damage,
  Electrical,
  HeadingIcon,
  OperationProcedure,
  Preventive,
  Project,
  Refrigeration,
  Safety,
  Success,
} from '../../../Assets/Icons/svg';

import Asterisk from '../../components/common/Asterisk';
import { useSuccessModal } from '../../../hooks/Messages/useSuccessModal';
import { useCancelModal } from '../../../hooks/Messages/useCancelModal';
import { scrollToError } from '../../../utilFunctions/scrollToError';
import Toggle from 'react-toggle';
import ToolTip from 'react-power-tooltip';
import {
  fetchAllprocedureData,
  fetchProcedureInitData,
} from '../../../redux/reducers/procedureReducer';
import { nanoid } from 'nanoid';
import useProcedureDFields from '../../../hooks/Procedure/useProcedureDummy';
import useProcedureFields from '../../../hooks/Procedure/useProcedureFields';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';
function Add({
  toggled,
  untoggle,
  refresh = '',
  setRefresh = () => {},
  cloneList,
  setFilterOptions,
  filterOptions,
  setSearchTitle,
  setSortData,
  setSelectedDates,
  initCloneList,
  setInitCloneList,
}) {
  const t = useSelector(selectTranslations);
  const { generatedProcedureFields } = useProcedureFields();
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const { setSuccessModal, SuccessModal } = useSuccessModal();
  const dispatch = useDispatch();
  // const { setNewCancelModal, CancelModal } = useCancelModal();
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const { initData } = useSelector((state) => ({
    initData: state.procedure.procedureInitData,
  }));

  const attachmentsRef = createRef();
  const [validation, setValidation] = useState({
    title: {
      error: true,
      pattern: (title) => title != '',
      skipped: false,
      message: t.common.required_field,
    },
    category: {
      error: true,
      pattern: (category) => category != '',
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validationProcedureItems, setValidationProcedureItems] = useState([
    {
      id: {
        error: false,
        pattern: (id) => id >= 0,
        skipped: false,
        message: t.common.required_field,
      },
      label: {
        error: true,
        pattern: (label) => label != '',
        skipped: false,
        message: t.common.required_field,
      },
      fieldType: {
        error: false,
        pattern: (fieldType) => fieldType != '',
        skipped: false,
        message: t.common.required_field,
      },
      options: [
        {
          error: false,
          pattern: (value, fieldType) =>
            ['multipleChoice', 'checklist'].includes(fieldType)
              ? value != ''
              : true,
          skipped: false,
          message: t.common.required_field,
        },
      ],
    },
    {
      id: {
        error: false,
        pattern: (id) => id >= 0,
        skipped: false,
        message: t.common.required_field,
      },
      label: {
        error: true,
        pattern: (label) => label != '',
        skipped: false,
        message: t.common.required_field,
      },
      fieldType: {
        error: false,
        pattern: (fieldType) => fieldType != '',
        skipped: false,
        message: t.common.required_field,
      },
      options: [
        {
          error: false,
          pattern: (value, fieldType) =>
            ['multipleChoice', 'checklist'].includes(fieldType)
              ? value != ''
              : true,
          skipped: false,
          message: t.common.required_field,
        },
      ],
    },
  ]);

  const [initChange, setInitChange] = useState(false);
  const [globalLibrary, setGlobalLibrary] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const [cloneModal, toggleCloneModal] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [cloningTemplate, setCloningTemplate] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);

  const [deletingFile, setDeletingFile] = useState(false);

  const [selectedTemplateID, setSelectedTemplateID] = useState(null);

  const [data, setData] = useState({
    title: '',
    description: '',
    category: 'damage',
  });

  const [defaultField, setDefaultField] = useState('checklist');

  const [fields, setFields] = useState([
    {
      id: 0,
      key: nanoid(),
      label: `${t.procedures.form.header} 1`,
      fieldType: 'heading',
      isRequired: false,
      active: true,
      logic: false,
      attachments: [],
      prevAttachments: [],
      options: [
        {
          item: '',
        },
      ],
    },
    {
      id: 1,
      key: nanoid(),
      label: `${t.procedures.form.add_procedure_item} 1`,
      fieldType: 'checklist',
      isRequired: false,
      active: true,
      logic: false,
      attachments: [],
      prevAttachments: [],
      options: [
        {
          item: '',
        },
      ],
    },
  ]);

  //console.log(fields, data, validationProcedureItems);

  const [options, setOptions] = useState({
    categories: [
      {
        key: 0,
        value: 'damage',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Damage />
            </span>
            {t.workOrders.form.category_options[0]}
          </span>
        ),
      },
      {
        key: 1,
        value: 'electrical',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Electrical />
            </span>
            {t.workOrders.form.category_options[1]}
          </span>
        ),
      },
      {
        key: 2,
        value: 'inspection',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryInspection />
            </span>
            {t.workOrders.form.category_options[2]}
          </span>
        ),
      },
      {
        key: 3,
        value: 'mechanical',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryMechanical />
            </span>
            {t.workOrders.form.category_options[3]}
          </span>
        ),
      },
      {
        key: 4,
        value: 'preventive',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Preventive />
            </span>
            {t.workOrders.form.category_options[4]}
          </span>
        ),
      },
      {
        key: 5,
        value: 'project',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Project />
            </span>
            {t.workOrders.form.category_options[5]}
          </span>
        ),
      },
      {
        key: 6,
        value: 'HVAC',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Refrigeration />
            </span>
            {t.workOrders.work_order_category.HVAC}
          </span>
        ),
      },
      {
        key: 7,
        value: 'safety',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Safety />
            </span>
            {t.workOrders.form.category_options[7]}
          </span>
        ),
      },
      {
        key: 8,
        value: 'sop',
        text: (
          <span>
            <span className="ml-2 mr-2">
              <OperationProcedure />
            </span>
            {t.workOrders.form.category_options[8]}
          </span>
        ),
      },
    ],
  });

  const resetForm = () => {
    setData({
      ...data,
      title: '',
      description: '',
      category: 'damage',
    });
    setFields([
      {
        id: 0,
        key: nanoid(),
        label: `${t.procedures.form.header} 1`,
        fieldType: 'heading',
        isRequired: false,
        active: true,
        logic: false,
        attachments: [],
        prevAttachments: [],
        options: [
          {
            item: '',
          },
        ],
      },
      {
        id: 1,
        key: nanoid(),
        label: `${t.procedures.form.add_procedure_item} 1`,
        fieldType: 'checklist',
        isRequired: false,
        active: true,
        logic: false,
        attachments: [],
        prevAttachments: [],
        options: [
          {
            item: '',
          },
        ],
      },
    ]);
    setValidationProcedureItems([
      {
        id: {
          error: false,
          pattern: (id) => id >= 0,
          skipped: false,
          message: t.common.required_field,
        },
        label: {
          error: true,
          pattern: (label) => label != '',
          skipped: false,
          message: t.common.required_field,
        },
        fieldType: {
          error: false,
          pattern: (fieldType) => fieldType != '',
          skipped: false,
          message: t.common.required_field,
        },
        options: [
          {
            error: false,
            pattern: (value, fieldType) =>
              ['multipleChoice', 'checklist'].includes(fieldType)
                ? value != ''
                : true,
            skipped: false,
            message: t.common.required_field,
          },
        ],
      },
      {
        id: {
          error: false,
          pattern: (id) => id >= 0,
          skipped: false,
          message: t.common.required_field,
        },
        label: {
          error: true,
          pattern: (label) => label != '',
          skipped: false,
          message: t.common.required_field,
        },
        fieldType: {
          error: false,
          pattern: (fieldType) => fieldType != '',
          skipped: false,
          message: t.common.required_field,
        },
        options: [
          {
            error: false,
            pattern: (value, fieldType) =>
              ['multipleChoice', 'checklist'].includes(fieldType)
                ? value != ''
                : true,
            skipped: false,
            message: t.common.required_field,
          },
        ],
      },
    ]);

    setInitChange(false);
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    let currentFieldValue = '';
    const dropdown_fields = ['category'];

    if (!dropdown_fields.includes(name)) {
      currentFieldValue = event.target.value;
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      currentFieldValue = value.value;
      setData({
        ...data,
        [name]: value.value,
      });
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
          skipped: true,
        },
      };
      //console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  const addItem = (isHeading = false) => {
    setInitChange(true);
    const headerCount = fields.filter((field) => field.fieldType === 'heading')
      .length;

    const new_fields = [...fields];
    new_fields.forEach((el) => (el.active = false));
    setActiveIndex(fields.length);
    setFields([
      ...fields,
      {
        id: fields?.length,
        key: nanoid(),
        label: isHeading
          ? `${t.procedures.form.header} ${headerCount + 1}`
          : `${t.procedures.form.add_procedure_item} ${`${fields.length -
              headerCount +
              1}`}`,
        fieldType: isHeading ? 'heading' : defaultField,
        isRequired: false,
        active: true,
        logic: false,
        attachments: [],
        prevAttachments: [],
        options: [
          {
            item: '',
          },
        ],
      },
    ]);
    setValidationProcedureItems([
      ...validationProcedureItems,
      {
        id: {
          error: false,
          pattern: (id) => id >= 0,
          skipped: false,
          message: t.common.required_field,
        },
        label: {
          error: true,
          pattern: (label) => label != '',
          skipped: false,
          message: t.common.required_field,
        },
        fieldType: {
          error: false,
          pattern: (fieldType) => fieldType != '',
          skipped: false,
          message: t.common.required_field,
        },
        options: [
          {
            error: false,
            pattern: (value, fieldType) =>
              ['multipleChoice', 'checklist'].includes(fieldType)
                ? value != ''
                : true,
            skipped: false,
            message: t.common.required_field,
          },
        ],
      },
    ]);
  };

  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      title: {
        ...validation.title,
        error: validation.title.pattern(data.title) ? false : true,
        skipped: true,
      },
      category: {
        ...validation.category,
        error: validation.category.pattern(data.category) ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    return errorsInData;
  };

  const procedureItemValidationHandler = () => {
    let latestValidation = [];
    let error = false;
    let itemValidations = cloneDeep(validationProcedureItems);
    for (let i = 0; i < itemValidations.length; i++) {
      let currentValidation = {
        ...itemValidations[i],
        id: {
          ...itemValidations[i].id,
          error: itemValidations[i].id.pattern(fields[i].id) ? false : true,
          skipped: true,
        },
        label: {
          ...itemValidations[i].label,
          error: itemValidations[i].label.pattern(fields[i].label)
            ? false
            : true,
          skipped: true,
        },
        fieldType: {
          ...itemValidations[i].fieldType,
          error: itemValidations[i].fieldType.pattern(fields[i].fieldType)
            ? false
            : true,
          skipped: true,
        },
        options: itemValidations[i].options.map((one, index) => {
          return {
            ...one,
            error: one.pattern(
              fields[i].options[index].item,
              fields[i].fieldType
            )
              ? false
              : true,
            skipped: true,
          };
        }),
      };
      latestValidation.push(currentValidation);
      setValidationProcedureItems(latestValidation);
      const validationToArray = Object.entries(currentValidation);
      const errorsInData = validationToArray.some((el) => {
        if (el[1].error) {
          return true;
        } else if (Array.isArray(el[1])) {
          let errorInOptions = el[1].some((op, indexOp) => {
            if (op.error) {
              return true;
            }
          });
          if (errorInOptions) {
            return true;
          }
        }
      });
      if (!error) {
        error = errorsInData;
      }
    }

    return error;
  };

  const createNewProcedureHandler = async () => {
    let errorsInData = validationHandler();
    let errorInProcedureItem = procedureItemValidationHandler();
    if (!errorsInData && !errorInProcedureItem) {
      const new_fields = [];
      fields.map(({ id, prevAttachments, options, ...rest }) => {
        const g_options = options.map((option) => {
          return {
            item: option.item,
          };
        });
        if (rest.label) {
          if (rest.fieldType === 'inspectionCheck') {
            rest.logics = [];
            if (rest.logicTrigger && rest.logicTrigger.length) {
              rest.logicTrigger.map((one) => {
                rest.logics.push({
                  answer: rest.logicAnswer || '',
                  trigger: one,
                  description:
                    one === 'action'
                      ? rest.actionDetails || ''
                      : rest.evidenceDetails || '',
                });
              });
            }
          }
          new_fields.push({ ...rest, options: g_options });
        }
      });
      // //console.log(new_fields);
      // return;

      const response = await createNewProcedure(
        String(data.title),
        String(data.description),
        String(data.category),
        new_fields,
        organisationId,
        currentUserId,
        globalLibrary
      );

      if (response.status == 200) {
        setSuccessModal(true);
        setButtonStatus(0);
        untoggle();
        resetForm();
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
          // untoggle();
        } else {
          toast.error(t.common.something_wrong);
          untoggle();
          resetForm();
        }
      }
      setButtonStatus(0);
    } else {
      // toast.error(
      //   'Some required fields are missing values. Please check before submitting.'
      // );
      scrollToError();
    }
  };

  const cancelHandler = () => {
    // removeAllAttachments();
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);
    setInitChange(false);
  };

  const onFilesChange = (e) => {
    let newFiles = [];
    Array.from(e.target.files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    if (newFiles.length > 0) {
      //console.log('activeIndex', activeIndex);
      //console.log('newFiles', newFiles);
      uploadAttachmentsHandler(newFiles).then((response) => {
        let newfields = [...fields];
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...fields[activeIndex] };
        const prevAttachments_obj = response.data.map((file) => {
          return {
            id: file.id,
            mime: file.mime,
            name: file.name,
            url: file.url,
          };
        });

        const undefined_prev_removed = prevAttachments_obj
          .concat(item.prevAttachments)
          .filter((el) => el);
        item.prevAttachments = undefined_prev_removed;

        const attachment_ids = response.data.map((file) => file.id);
        const undefined_attachments_removed = attachment_ids
          .concat(item.attachments)
          .filter((el) => el);
        item.attachments = undefined_attachments_removed;

        newfields[activeIndex] = item;

        // //console.log(newfields[activeIndex]);
        // 5. Set the state to our new copy
        setFields(newfields);
      });
    }
  };

  const uploadAttachmentsHandler = async (files) => {
    let theFiles = files.filter((el) => el.isChanged).map((el) => el.file);

    // //console.log(theFiles);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const removeThisFileFromField = async (the_ID) => {
    setDeletingFile(true);
    const response = await removeFile(the_ID);

    if (response.status == 200) {
      let newfields = [...fields];
      // 2. Make a shallow copy of the item you want to mutate
      let item = { ...fields[activeIndex] };

      const filteredPrevAttachments = item.prevAttachments.filter(
        (file) => file.id !== the_ID
      );
      item.prevAttachments = filteredPrevAttachments;

      const filteredAttachments = item.attachments.filter(
        (file) => file !== the_ID
      );
      //console.log('filteredAttachments', filteredAttachments);

      item.attachments = filteredAttachments;

      item.active = true;

      newfields[activeIndex] = item;

      // //console.log(newfields[id]);
      // 5. Set the state to our new copy
      setFields(newfields);
      setDeletingFile(false);
    }
  };

  const removeAllAttachments = async () => {
    let map_ids = fields.map((field) => {
      return [...field.attachments];
    });

    //console.log('LENGTH', [map_ids.length, map_ids]);
    // await Promise.all(
    //   map_ids.map(async (file) => {
    //     const response = await removeFile(file);
    //     //console.log(response);
    //   })
    // );
  };

  const cloneTemplate = () => {
    toggleCloneModal(true);
  };

  const handleSetDataByFilter = (newData) => {
    setInitCloneList(newData);
  };

  const cloneThisTemplate = (proc_id, proc) => {
    setCloningTemplate(true);
    setSelectedTemplateID(proc_id);
    let newFieldValidations = [];

    const regenerate_fields = proc?.attributes.fields.map((field) => {
      const prevAttachments_obj = field?.attachments?.data?.map((file) => {
        return {
          id: file.id,
          mime: file.attributes.mime,
          name: file.attributes.name,
          url: file.attributes.url,
        };
      });

      const attachments_obj = field?.attachments?.data?.map((file) => file.id);
      newFieldValidations.push({
        id: {
          error: false,
          pattern: (id) => id >= 0,
          skipped: false,
          message: t.common.required_field,
        },
        label: {
          error: true,
          pattern: (label) => label != '',
          skipped: false,
          message: t.common.required_field,
        },
        fieldType: {
          error: false,
          pattern: (fieldType) => fieldType != '',
          skipped: false,
          message: t.common.required_field,
        },
        options: field.options
          ? field.options.map((one) => {
              return {
                error: false,
                pattern: (value, fieldType) =>
                  ['multipleChoice', 'checklist'].includes(fieldType)
                    ? value != ''
                    : true,
                skipped: false,
                message: t.common.required_field,
              };
            })
          : [],
      });

      if (field?.fieldType == 'inspectionCheck') {
        const modifiedField = {
          ...field,
          logicAnswer:
            field?.logics && field?.logics?.length
              ? field?.logics[0]?.answer
              : '',
          logicTrigger:
            field?.logics && field?.logics?.length
              ? field?.logics?.map((one) => one.trigger)
              : null,
          actionDetails:
            field?.logics &&
            field?.logics?.find((one) => one?.trigger == 'action')
              ? field?.logics?.find((one) => one?.trigger == 'action')
                  ?.description || ''
              : '',
          evidenceDetails:
            field?.logics &&
            field?.logics?.find((one) => one.trigger == 'evidence')
              ? field.logics.find((one) => one.trigger == 'evidence')
                  ?.description || ''
              : '',
        };

        // Delete the logics property from the modified object
        delete modifiedField.logics;

        // Use the modified object instead of modifying the original field object
        return {
          ...modifiedField,
          attachments: attachments_obj,
          prevAttachments: prevAttachments_obj,
        };
      }

      return {
        ...field,
        attachments: attachments_obj,
        prevAttachments: prevAttachments_obj,
      };
    });

    setFields([...fields, ...regenerate_fields]);
    setValidationProcedureItems([
      ...validationProcedureItems,
      ...newFieldValidations,
    ]);
  };

  useEffect(() => {
    setCloningTemplate(false);
    setSelectedTemplateID(null);
    toggleCloneModal(false);
    setInitChange(true);
  }, [fields]);

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? 'aside-modal procedure dynamic-modal right-sidebar active add-procedure-container'
            : 'aside-modal procedure add-procedure-container'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">{t.procedures.form.form_title.add}</div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div style={{
              height: "90vh",
              overflowX: "hidden",
              overflowY: "scroll",
            }}  className="body">
            <Form.Field>
              <label className="label-item">
                {t.procedures.form.procedure_title}
                <Asterisk color="#172B4D" />
                {/* <Icon name="asterisk" color="#172B4D" size="small" /> */}
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.procedures.form.add_procedure_title}
                value={data.title}
                onChange={onValuesChange('title')}
                // error={validation.title.error && validation.title.skipped}
                onBlur={(e) => {
                  if (validation.title.pattern(data.title)) {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.title.error && validation.title.skipped ? (
                <div className="label-error">{validation.title.message}</div>
              ) : (
                ''
              )}
            </Form.Field>
            <Form style={{ marginBottom: '20px' }}>
              <Form.Field>
                <label className="label-item">
                  {t.procedures.form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange('description')}
                  rows={2}
                  fluid
                  placeholder={t.procedures.form.add_description}
                />
              </Form.Field>
            </Form>

            <Form.Field>
              <label className="label-item">
                {t.procedures.details_view.category}
                {/* <Icon name="asterisk" color="red" size="small" /> */}
                <Asterisk color="#172B4D" />
              </label>

              <Dropdown
                placeholder={t.procedures.details_view.add_category}
                noResultsMessage={t.common.no_results_found}
                fluid
                selection
                options={options.categories}
                value={data.category}
                onChange={onValuesChange('category')}
                error={validation.category.error && validation.category.skipped}
                onBlur={(e) => {
                  if (validation.category.pattern(data.category)) {
                    setValidation({
                      ...validation,
                      category: {
                        ...validation.category,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      category: {
                        ...validation.category,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.category.error && validation.category.skipped ? (
                <div className="label-error">{validation.category.message}</div>
              ) : (
                ''
              )}
            </Form.Field>
            {/* <Divider horizontal>
            {t.procedures.details_view.procedure_items} ({fields.length})
          </Divider> */}
            {generatedProcedureFields(
              fields,
              setFields,
              validationProcedureItems,
              setValidationProcedureItems,
              attachmentsRef,
              setActiveIndex
            )}

            <div className="procedure-item-add-button-container">
              <div className="item-button-group" onClick={() => addItem(false)}>
                {/* <img className="item-button-icon-container" alt="" src="/voice-notes@2x.png" /> */}
                <span className="item-button-icon-container">
                  <AddIcon />
                </span>
                <div className="item-add-option-text">
                  {t.procedures.form.add_procedure_item}
                </div>
              </div>
              <div className="item-button-group" onClick={() => addItem(true)}>
                {/* <img className="item-button-icon-container" alt="" src="/voice-notes@2x.png" /> */}
                <span className="item-button-icon-container">
                  <HeadingIcon width="20px" height="20px" fill={'#0C66E4'} />
                </span>
                <div className="item-add-option-text">
                  {t.procedures.form.add_heading}
                </div>
              </div>
              <div
                className="item-button-group"
                onClick={() => cloneTemplate()}
              >
                <span className="item-button-icon-container">
                  <CopyIcon width="20px" height="20px" />
                </span>
                <div className="item-add-option-text">
                  {t.procedures.form.clone_template}
                </div>
              </div>
            </div>

            <div className="mt-6 ml-4 d-flex">
              <span
                className={`required-text ${
                  selectedLanguage == 'en' ? 'mr-3' : 'ml-3'
                }`}
              >
                {t.procedures.title2}
              </span>
              {/* <div className="d-flex">
                <Toggle
                  // type="checkbox"
                  className="toggle-data"
                  checked={globalLibrary}
                  onChange={(e) => setGlobalLibrary(e.target.checked)}
                />
              </div> */}
            </div>
            <div className="form-button-container">
              <button
                // content={t.procedures.form.cancel_button}
                // basic
                onClick={() =>
                  initChange ? setNewCancelModal(true) : cancelHandler()
                }
                className="form-action-button"
              >
                {t.common.cancel}
              </button>

              <button
                // content={t.procedures.form.submit.add}
                // primary
                onClick={createNewProcedureHandler}
                // loading={buttonStatus == 1}
                className="form-action-button blue"
                disabled={buttonStatus == 1}
              >
                {t.common.submit}
              </button>

              <input
                ref={attachmentsRef}
                type="file"
                accept="application/pdf, image/*"
                hidden
                multiple
                onChange={onFilesChange}
              />
            </div>
          </div>
        </Resizable>
      </div>

      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* success modal */}
      <SuccessModal
        closeCallback={async () => {
          if (initData?.length == 0) {
            const fetchData = await dispatch(
              fetchProcedureInitData(organisationId)
            );
          }

          setFilterOptions({
            category: [],
            createdByUser: [],
          });
          setSearchTitle('');
          setSelectedDates([]);
          setSortData([]);
        }}
      >
        <span>{t.procedures.form.success.msg}</span>
      </SuccessModal>

      {/* Clone Procedure */}
      <Modal
        size="tiny"
        className="procedure-modal"
        open={cloneModal}
        onClose={() => toggleCloneModal(false)}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.procedures.form.select_local_statement}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                toggleCloneModal(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        {/* <Modal.Header>{t.procedures.form.select_local_statement}</Modal.Header> */}
        <Modal.Content scrolling style={{ minHeight: '600px' }}>
          <FilterCloneList
            data={initCloneList}
            setDataByFilter={handleSetDataByFilter}
          />
          <List divided relaxed>
            {initCloneList?.length > 0 ? (
              initCloneList?.map((proc) => (
                <List.Item style={{ padding: '10px 0' }} key={proc.id}>
                  <List.Content floated="right" style={{ padding: 0 }}>
                    <button
                      className="modal-form-action-button light-blue"
                      loading={selectedTemplateID == proc.id}
                      disabled={cloningTemplate}
                      primary
                      onClick={() => cloneThisTemplate(proc.id, proc)}
                    >
                      {t.common.add}
                    </button>
                  </List.Content>
                  <List.Icon
                    name="unordered list"
                    size="large"
                    verticalAlign="middle"
                  />
                  <List.Content>
                    <List.Header>{proc.attributes.title}</List.Header>
                    <List.Description>
                      {proc.attributes.fields.length}{' '}
                      {t.procedures.table.fields_small}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Icon name="search" size="huge" color="blue" />
                <p
                  style={{
                    color: '#2185d0',
                    fontSize: '14px',
                    margin: '15px 0',
                    fontWeight: '600',
                  }}
                >
                  {t.common.na}
                </p>
              </div>
            )}
          </List>
        </Modal.Content>
      </Modal>

      {/* cancel modal */}

      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
             
             <p style={{lineHeight:"24px"}}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
    </>
  );
}

export default Add;
