export default {
  title: 'الفرق',
  add: 'الفرق',
  
  next_btn: 'التالي',
  add_team: "إضافة فريق" ,
  filter: {
    search: 'بحث',
    people: 'الأفراد',
  },
  team_info_form: {
    add_team_superior: 'اختر مشرف الفريق',
    add_team: 'أضف اسم الفريق',
    team_name: 'اسم الفريق',
    description: 'الوصف',
    description_placeholder: "أضف الوصف",
    team_superior: 'مشرف الفريق',
    superior: 'المشرف',
    team_members: 'أعضاء الفريق',
    add_team_members: 'اختر أعضاء الفريق',
    member: 'أعضاء الفريق',
    members: 'أعضاء',
    customer: 'العميل',
    customers: 'العملاء',
    customer_placeholder: 'اختر العميل',
    location: 'الموقع',
    location_placeholder: 'اختر الموقع',
  },

  team_preview: {
    supervisor: 'المشرف',
    locations: 'المواقع',
    edit_team:"تعديل فريق",
  },
  location_valid:"يجب اختيار موقع",
  selected_data:"قبل الاستمرار",
  security: 'حماية',
  invalid_email: 'بريد إلكتروني خاطئ',
  invalid_phone: 'هاتف غير صالح',
  modified_success: 'تم التعديل بنجاح!',
  no_people_statement: 'يبدو أنه ليس لديك أشخاص حتى الآن.',
  press_to_add_people: 'اضغط على الزر ' + ' لإضافة الشخص الأول.',
  members_of_teams: 'عضو في هذا الفريق',
  result_not_found: 'لم يتم العثور على نتائج',
  no_teams_found: 'يبدو أن لديك أي فرق حتى الآن.',
  add_team_message: "اضغط على زر '+' لإضافة أول فريق لك.",
  something_wrong: 'حدث خطأ ما',
  delete_teams: 'هل أنت متأكد أنك تريد حذف هذا الفريق؟',
  team_connected:
    'لا يمكن حذف الفريق مرتبط بأشكال أخرى',
    team_deleted: 'تم حذف الفريق بنجاح',
  add_teams: 'البدء في إضافة الفرق',
};
