import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Input,
  Form,
  Divider,
  Dimmer,
  Checkbox,
  Loader,
  Dropdown,
  TextArea,
  Radio,
} from "semantic-ui-react";

import { useHistory } from "react-router";

import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import * as Yup from "yup";
import Asterisk from "../../components/common/Asterisk";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import { useSelector } from "react-redux";

import search_icon from "../../../Assets/Icons/search-icon.svg";

import { selectTranslations } from "../../config/i18n/slice";
import {
  handleKeyDown,
  getAllParts,
  getAllSets,
  currencyDecimalPlaces,
  getActiveUsersByRole,
} from "../../config/functions";
import {
  CustomerPlusIcon,
  DeleteIcon,
  CloseIcon,
  SearchIcon,
  ArrowLeft,
  ArrowRight,
  DetailsIcon,
  ItemsIcon,
  PercentageBlueIcon,
  AmountBlueIcon,
  PercentageLightIcon,
  AmountLightIcon,
  HomeIcon,
  TextSnippet,
  AddIconWithCircle,
  AddedFileIcon,
  AddImage,
  InfoIconBlue,
  InfoIcon,
} from "../../../Assets/Icons/svg";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import _, { countBy, orderBy } from "lodash";
import DataTable from "react-data-table-component";
import Pagination from "../../components/common/Pagination";
import { toast } from "react-toastify";
import { PhoneNumberUtil } from "google-libphonenumber";
import DatePicker from "react-multi-date-picker";
import { format } from "date-fns";
import { PhoneInput } from "react-international-phone";
import { useDropzone } from "react-dropzone";
import Add from "../Parts/add";
import ToolTip from "react-power-tooltip";
import { extractAddress } from "../../../services/extractAddress";
import { Map, Marker } from "google-maps-react";
import { defineLocale } from "moment";
import AutocompleteInput from "./InputComp";
import { Spinner } from "reactstrap";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { scrollToError } from "../../../utilFunctions/scrollToError";

const phoneUtil = PhoneNumberUtil.getInstance();
const positiveNumRegex = /^[1-9]\d*$/;
const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#E9F2FF",
      paddingTop: "8px", // override the cell padding for head cells
    },
  },
  cells: {
    style: {
      paddingTop: "20px",
      paddingBottom: "8px",
    },
  },
};

/*
 * The `FileUpload` function in JavaScript handles file uploads with different behaviors based on the file type and name provided.
 */
const FileUpload = ({
  onFileChange,
  onCertificateFileChange,
  name = "",
  attachments = {},
  setAttachments = () => {},
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    audio: ["audio/mpeg"],
    video: ["video/mp4"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: Object.fromEntries(
      [
        ...fileTypes.images,
        ...fileTypes.documents,
        ...fileTypes.audio,
        ...fileTypes.video,
      ].map((type) => [type, []])
    ),
    onDrop: (acceptedFiles) => {
      let totalFile = attachments?.warrantyFile?.length + acceptedFiles?.length;

      if (totalFile > 10) {
        toast.error(`You can only upload a maximum of 10 files.`);
        return;
      }
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);
        const isAudio = fileTypes.audio.includes(file.type);
        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
          ((isImage || isDocument) && file.size < 5 * 1024 * 1024) ||
          ((isAudio || isVideo) && file.size < 50 * 1024 * 1024);

        if (!validSize) {
          toast.error(`File size too large: ${file.name}`);
          return false;
        }
        return true;
      });

      onCertificateFileChange(validFiles, name);
    },
  });
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const renderFile = (file, index) => (
    <div
      className={`image-dropzone file d-flex justify-content-between text-start ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label style={{ fontSize: "16px" }} className="mb-0">
          <span
            className={`${
              selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
            }`}
          >
            <TextSnippet />
          </span>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.warrantyFile.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            warrantyFile: newCovidCopy,
          });
        }}
      >
        <CloseIcon />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );

  const renderImageFile = (file, index) => (
    <div
      className={`image-dropzone file d-flex justify-content-between text-center ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label style={{ fontSize: "16px" }} className="mb-0">
          <span
            className={`${
              selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
            }`}
          >
            <AddedFileIcon />
          </span>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.image.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            image: newCovidCopy,
          });
        }}
      >
        <CloseIcon />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );
  // console.log(attachments);
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          // <div className="image-dropzone file d-flex justify-content-between text-center">
          //   <div>
          //     <label style={{ fontSize: '16px' }} className="mb-0">
          //       <span
          //         className={`${
          //           selectedLanguage == 'en' ? 'ml-5 mr-8' : 'ml-8 mr-5'
          //         }`}
          //       >
          //         <AddedFileIcon />
          //       </span>

          //       {attachments?.image?.name}
          //     </label>
          //   </div>

          //   <div
          //     className="mr-5 ml-5 mt-1 c-pointer"
          //     onClick={(e) => {
          //       e.stopPropagation();
          //       setAttachments({
          //         ...attachments,
          //         image: null,
          //       });
          //     }}
          //   >
          //     <CloseIcon />
          //   </div>
          // </div>

          <div className="image-dropzone file d-flex justify-content-between text-center">
            <div>
              <label style={{ fontSize: "16px" }} className="mb-0">
                <span
                  className={`${
                    selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
                  }`}
                >
                  <AddedFileIcon />
                </span>

                {attachments?.image?.name}
              </label>
            </div>

            <div
              className="mr-5 ml-5 mt-1 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <CloseIcon />
            </div>
          </div>
        ) : (
          <div className="image-dropzone text-center">
            <div>
              <div className="mb-3">
                <AddImage />
              </div>
              <p className="p1">
                <span className="mr-2 ml-2"></span>
                <span className="mt-1">{t.workRequests.form.drag}</span>
                <span className="mt-1" style={{ color: "#0C66E4" }}>
                  {" "}
                  {t.workRequests.form.choose}
                </span>{" "}
                <span className="mt-1">{t.workRequests.form.upload}</span>
                <p className="text-center">PNG or JPEG</p>
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.length > 0 ? (
        <>
          {attachments.warrantyFile.map((file, index) =>
            renderFile(file, index)
          )}

          <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between mt-5">
            <div className="d-flex">
              <span className="mt-1">
                <TextSnippet />
              </span>
              <div className="pl-8 pr-8">
                <p className="p1 m-0">{t.workOrders.form.file}</p>
                <p className="p2 m-0">{t.po.form.attach_file}</p>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        </>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <span className="mt-1">
              <TextSnippet />
            </span>
            <div className="pl-8 pr-8">
              <p className="p1 m-0">{t.workOrders.form.file}</p>
              <p className="p2 m-0">{t.po.form.attach_file}</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};

const PurchaseForm = ({
  initialFormValues,
  prevPartsData = [],
  toggleAsideModal,
  allPartsData = [],
  selectedTablePartData = [],
  fetchingLocation = false,
  edit = false,
  cancelHandler = () => {},
  setToggleAsideModal = () => {},
  buttonStatus,
  initPartsData,
  partsData = [],
  initSetsData,
  setsData = [],
  role,
  allIds,
  options = [],
  initChange,
  setSelectedTablePartData = () => {},
  setPrevPartsData = () => {},
  setAllPartsData = () => {},
  setNewCancelModal = () => {},
  setOptions = () => {},
  handleAddSet = () => {},
  setInitChange = () => {},
  fetchVendorsData = () => {},
  fetchLocations = () => {},
  // fetchArea = () => {},
  setSetsData = () => {},
  setInitSetsData = () => {},
  setInitPartsData = () => {},
  setPartsData = () => {},
  setAttachments = () => {},
  attachments,
}) => {
  const t = useSelector(selectTranslations);
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const isSubmitClicked = useRef(false);

  const fetchPeopleData = async (locations, people) => {
    if (!people) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: [],
      }));
    }
    const fetchData = await getActiveUsersByRole(
      ["limited_admin", "admin"],
      organisationId
      // !(role === "super_admin" || role === "admin") ?locations ? [locations] : allIds :locations? [locations] : []
    );
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "250px",
  };
  const style = {
    width: "100%",
    height: "100%",
  };
  // const handlePlaceSelect = async (setFieldValue) => {
  //   let ele = document.getElementById(`location_1`);
  //   const AutoAddress = new window.google.maps.places.Autocomplete(ele, {});
  //   const place = AutoAddress.getPlace();
  //   console.log('place: ', place);

  //   if (!place?.address_components) {
  //     return;
  //   }
  //   const address = extractAddress(place);

  //   let longitudeData = address.lng;
  //   let latitudeData = address.lat;
  //   setDefaultCenter({ lat: latitudeData, lng: longitudeData });

  //   let the_options = [];

  //   let city = '';

  //   // if (address?.stateCode?.length > 3) {
  //   //   const fetchData = State.getStatesOfCountry(address.countryCode);

  //   //   if (fetchData.length > 0) {
  //   //     // console.log(fetchData);
  //   //     the_options = fetchData.map((el) => {
  //   //       return {
  //   //         key: el.isoCode,
  //   //         value: el.isoCode,
  //   //         text: el.name,
  //   //       };
  //   //     });
  //   //   } else {
  //   //     the_options = [];
  //   //   }

  //   //   city = the_options?.find((city) =>
  //   //     city?.text?.startsWith(address.stateCode)
  //   //   );

  //   //   //     cityAddress = city?.value
  //   //   //     console.log(cityAddress,"dsdssdsdsd")
  //   // }

  //   setFieldValue('details.address', address.address3);

  //   // setData((prev) => {
  //   //   return {
  //   //     ...prev,
  //   //     address: address.address3,
  //   //     city: city?.value
  //   //       ? city.value
  //   //       : address.stateCode
  //   //       ? address.stateCode
  //   //       : '',
  //   //     country: address.countryCode ? address.countryCode : '',
  //   //   };
  //   // });

  //   // let validcity = city?.value
  //   //   ? city.value
  //   //   : address.stateCode
  //   //   ? address.stateCode
  //   //   : '';

  //   // if (validcity.length > 3) {
  //   //   setValidation({
  //   //     ...validation,
  //   //     city: {
  //   //       ...validation.city,
  //   //       error: true,
  //   //     },
  //   //   });

  //   //   setData((prev) => {
  //   //     return {
  //   //       ...prev,

  //   //       city: '',
  //   //     };
  //   //   });
  //   // }
  // };

  const [showPeopleTooltip, setPeopleTooltip] = useState(false);

  const history = useHistory();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  /*
   * The function `handleDecimalPoints` formats a numerical value to a specified number of decimal places and removes trailing zeros after the decimal point.
   */
  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };

  /*
   * The function `onCertificateFileChange` takes in files and a name, generates random IDs for each file, and adds them to the attachments object under the specified name.
   */
  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };

  const [emergencyPhone, setEmergencyPhone] = useState(false);
  /* The above code is defining a validation schema using Yup for a person's details, order items,
shipping address, and billing address. */
  const PersonValidationSchema = Yup.object().shape({
    details: Yup.object().shape({
      title: Yup.string().required(t.common.required_field),
      vendor: Yup.string().required(t.common.required_field),
      poNumber: Yup.string().required(t.common.required_field),
      address: Yup.string().required(t.common.required_field),
      people: Yup.array()
        .of(Yup.number())
        .min(1, t.common.required_field)
        .required(t.common.required_field),
    }),

    orderItems: Yup.object().shape({
      items: Yup.array().of(
        Yup.object().shape({
          parts_id: Yup.string(),
          quantity: Yup.number()
            .nullable()
            .min(0.1, t.common.quantity_must_be_greater_than_zero)
            .test(
              "is-valid-quantity",
              t.common.valid_digits, // Error message for invalid quantity
              (value) => {
                // Check if quantity matches the pattern
                if (!value) return true; // If quantity is empty, return true
                const regex = /^\d{1,8}(\.\d{1,4})?$/;
                return regex.test(value.toString());
              }
            )
            .required(t.common.required_field),
          unitCost: Yup.number()
            .nullable()
            .min(0.1, t.common.cost_must_be_greater_than_zero)
            .test(
              "is-valid-quantity",
              t.common.valid_digits, // Error message for invalid quantity
              (value) => {
                // Check if quantity matches the pattern
                if (!value) return true; // If quantity is empty, return true
                const regex = /^\d{1,8}(\.\d{1,4})?$/;
                return regex.test(value.toString());
              }
            )
            .required(t.common.required_field),
          totalCost: Yup.string(),
        })
      ),

      taxesInPercentage: Yup.boolean(), // Assuming it's a boolean
      texInAmount: Yup.boolean(),
      taxes: Yup.number()
        .nullable()
        .positive()
        .test(
          "is-valid-quantity",
          t.common.valid_digits, // Error message for invalid taxes
          (value) => {
            // Check if taxes matches the pattern
            if (!value) return true; // If taxes is empty, return true
            const regex = /^\d{1,8}(\.\d{1,4})?$/;
            return regex.test(value.toString());
          }
        )
        .test(
          "non-negative",
          "taxes must be non-negative", // Error message for non-negative taxes
          (value) => value >= 0
        ),
      shippingCostInPercentage: Yup.boolean(), // Assuming it's a boolean
      shippingCostInAmount: Yup.boolean(),
      shippingCost: Yup.number()
        .nullable()
        .positive()
        .test(
          "is-valid-quantity",
          t.common.valid_digits, // Error message for invalid taxes
          (value) => {
            // Check if taxes matches the pattern
            if (!value) return true; // If taxes is empty, return true
            const regex = /^\d{1,8}(\.\d{1,4})?$/;
            return regex.test(value.toString());
          }
        )
        .test(
          "non-negative",
          "taxes must be non-negative", // Error message for non-negative taxes
          (value) => value >= 0
        ),
      otherCostInPercentage: Yup.boolean(), // Assuming it's a boolean
      otherCostInAmount: Yup.boolean(),
      otherCost: Yup.number()
        .nullable()
        .positive()
        .test(
          "is-valid-quantity",
          t.common.valid_digits, // Error message for invalid taxes
          (value) => {
            // Check if taxes matches the pattern
            if (!value) return true; // If taxes is empty, return true
            const regex = /^\d{1,8}(\.\d{1,4})?$/;
            return regex.test(value.toString());
          }
        )
        .test(
          "non-negative",
          "taxes must be non-negative", // Error message for non-negative taxes
          (value) => value >= 0
        ),
    }),

    shippingAddress: Yup.object().shape({
      contactPhoneNumber: Yup.string()
        .nullable()
        .test("correct-format", t.people.invalid_phone, function(value) {
          if (!value || isPhoneValid(value)) {
            return true; // Validation passes
          }

          return false; // Validation fails
        }),
      companyName: Yup.string().required(t.common.required_field),
      // area: Yup.string().required(t.common.required_field),

      zipcode: Yup.number()
        .nullable()
        .test(
          "non-negative",
          "zipcode must be non-negative",
          (value) => value >= 0
        ),
    }),

    billingAddress: Yup.object().shape({
      sameAsShippingAddress: Yup.boolean(),
      contactPhoneNumber: Yup.string()
        .nullable()
        .test("correct-format", t.people.invalid_phone, function(value) {
          if (!value || isPhoneValid(value)) {
            return true; // Validation passes
          }

          return false; // Validation fails
        }),
    }),
  });

  /*
   * The function `getPaginatedData` takes an array of data and returns a paginated subset based on the
   * current page and page size.
   */
  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };
  const { DisplayFormattedText } = useDisplayFormattedText();

  const [addPartModal, setAddPartModal] = useState(false);
  const [noPartModal, setNoPartModal] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [addSetModal, setAddSetModal] = useState(false);

  let _newData = initPartsData;
  useEffect(() => {
    if (searchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(searchTitle), "i");
      const isMatch = (result) => re.test(result?.name);
      _newData =
        searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    }
    // console.log('_newData: ', _newData);
    if (_newData?.length > 0) {
      setPartsData(_newData);
    } else {
      setPartsData([]);
    }
  }, [searchTitle]);

  // add popups
  const addParts = async (selectedPartsData = [], fetchdata = false) => {
    setInitChange(true);

    if (partsData?.length <= 0 || fetchdata) {
      setLoading(true);
      const fetchData = await getAllParts(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        "",
        [],
        role,
        allIds
      );
      let allPartData = fetchData?.response?.flatMap((part) => {
        if (part?.attributes?.inventory?.length > 0) {
          return part.attributes.inventory
            .filter((inv) => {
              // If the role is not super_admin or admin, check if lodatacation ID is in allIds
              if (!(role === "super_admin" || role === "admin")) {
                return allIds?.includes(inv?.location?.data?.id);
              }
              // For super_admin or admin, skip the check and include all data
              return true;
            })
            .map((inv) => {
              return {
                id: `${part?.id}-${inv?.location?.data?.id}`,
                part_id: part?.id,
                countId: part?.attributes?.countId,
                name: part.attributes.name,
                location: inv?.location?.data?.attributes?.name,
                locationId: inv?.location?.data?.id,
                totalCost: inv?.unitCost !== null ? inv?.unitCost : 0,
                quantity: 1,
                unitCost: inv?.unitCost !== null ? inv?.unitCost : 0,
                unitType: part.attributes.measuringUnit,
                selectedPart: false,
                fillMax: false,
                // disable: inv?.availableQuantity ? false : true,
                availableQty: inv?.availableQuantity
                  ? inv.availableQuantity
                  : 0,
                orderedQuantity: inv?.orderedQuantity
                  ? inv?.orderedQuantity
                  : 0,
              };
            });
        }

        // If there's no inventory, return an empty array
        return [];
      });
      if (fetchData?.response) {
        if (fetchData?.response?.length == 0) {
          setLoading(false);
          setNoPartModal(true);
        } else {
          // let allPartData = fetchData?.response?.map((part) => (
          //   part?.attributes?.inventory?.length > 0 ?

          //   part.attributes.inventory.map((inv) =>{
          //     return {
          //       id: `${part.id}-${inv?.location?.data?.id}`,
          //       part_id: part.id,
          //       countId: part?.attributes?.countId,
          //       name: part.attributes.name,
          //       location: inv?.location?.data?.attributes?.name,
          //       locationId: inv?.location?.data?.id,
          //       totalCost: inv?.unitCost,
          //       quantity: 1,
          //       unitCost: inv?.unitCost,
          //       unitType: part.attributes.measuringUnit,
          //       selectedPart: false,
          //       fillMax: false,
          //       availableQty: inv?.availableQuantity
          //         ? inv.availableQuantity
          //         : 0,
          //       orderedQuantity: inv?.orderedQuantity
          //         ? inv?.orderedQuantity
          //         : 0,
          //     };
          //   })
          // )

          // );
          // let allPartData = fetchData?.response?.flatMap((part) => {
          //   if (part?.attributes?.inventory?.length > 0) {
          //     // Store the mapped inventory data in a variable
          //      inventoryData = part.attributes.inventory.map((inv) => {
          //       return {
          //         id: `${part.id}-${inv?.location?.data?.id}`,
          //         part_id: part.id,
          //         countId: part?.attributes?.countId,
          //         name: part.attributes.name,
          //         location: inv?.location?.data?.attributes?.name,
          //         locationId: inv?.location?.data?.id,
          //         totalCost: inv?.unitCost,
          //         quantity: 1,
          //         unitCost: inv?.unitCost,
          //         unitType: part.attributes.measuringUnit,
          //         selectedPart: false,
          //         fillMax: false,
          //         availableQty: inv?.availableQuantity ? inv.availableQuantity : 0,
          //         orderedQuantity: inv?.orderedQuantity ? inv?.orderedQuantity : 0,
          //       };
          //     });

          //     // Return the stored variable (or do further operations if needed)
          //     return inventoryData;
          //   }

          //   // If there's no inventory, return an empty array
          //   return [];
          // });
          //  allPartData = fetchData?.response?.flatMap((part) => {
          //   if (part?.attributes?.inventory?.length > 0) {
          //     // Store the mapped inventory data in a variable
          //     return part.attributes.inventory.map((inv) => {
          //       return {
          //         id: `${part.id}-${inv?.location?.data?.id}`,
          //         part_id: part.id,
          //         countId: part?.attributes?.countId,
          //         name: part.attributes.name,
          //         location: inv?.location?.data?.attributes?.name,
          //         locationId: inv?.location?.data?.id,
          //         totalCost: inv?.unitCost,
          //         quantity: 1,
          //         unitCost: inv?.unitCost,
          //         unitType: part.attributes.measuringUnit,
          //         selectedPart: false,
          //         fillMax: false,
          //         availableQty: inv?.availableQuantity ? inv.availableQuantity : 0,
          //         orderedQuantity: inv?.orderedQuantity ? inv?.orderedQuantity : 0,
          //       };
          //     });
          //   }

          //   // If there's no inventory, return an empty array (flatMap will merge these automatically)
          //   return [];
          // });
          const filteredParts = allPartData
            ?.map((part) => {
              // let selectedPart = selectedPartsData.find(
              //   (el) => el?.id == part?.id
              // );

              const isSelected = selectedPartsData.some(
                (selectedPart) => selectedPart.id == part.id
              );

              if (isSelected) {
                return null; // Skip this part
              } else {
                return {
                  id: part?.id,
                  part_id: part.part_id,
                  countId: part?.countId,
                  name: part.name,
                  location: part?.location,
                  locationId: part?.locationId,
                  totalCost: part?.totalCost,
                  quantity: part?.quantity ? part?.quantity : null,
                  unitCost: part?.unitCost,
                  unitType: part?.unitType,
                  selectedPart: false,
                  fillMax: part?.fillMax,
                  availableQty: part.availableQty,
                  orderedQuantity: part.orderedQuantity,
                  // disable: part?.disable,
                };
              }
            })
            .filter((part) => part !== null);

          setPartsData(filteredParts);
          setInitPartsData(filteredParts);
          setAllPartsData(allPartData);
          setLoading(false);
          if (!fetchdata) {
            setAddPartModal(true);
          }
        }
      } else {
        toast.error(t.common.something_wrong);
        setLoading(false);
        if (!fetchdata) {
          setAddPartModal(true);
        }
      }
      setLoading(false);
    } else {
      setLoading(true);
      setAddPartModal(true);
      const filteredParts = allPartsData
        ?.map((part) => {
          const isSelected = selectedPartsData.some(
            (selectedPart) => selectedPart.id == part.id
          );
          if (isSelected) {
            return null; // Skip this part
          } else {
            return {
              id: part?.id,
              part_id: part.part_id,
              countId: part?.countId,
              name: part.name,
              location: part?.location,
              locationId: part?.locationId,
              totalCost: part?.totalCost,
              quantity: part?.quantity ? part?.quantity : null,
              unitCost: part?.unitCost,
              unitType: part?.unitType,
              selectedPart: false,
              fillMax: part?.fillMax,
              availableQty: part.availableQty,
              orderedQuantity: part.orderedQuantity,
              // disable: part?.disable,
              // selectedPart: false,
              // id: part.id,
              // countId: part.countId,
              // name: part.name,
              // totalCost: part.totalCost,
              // quantity: part?.quantity ? part?.quantity : null,
              // unitCost: part.unitCost,
              // unitType: part.unitType,
              // availableQty: part.availableQty,
              // orderedQuantity: part.orderedQuantity,
              // fillMax: part?.fillMax,
            };
          }
        })
        .filter((part) => part !== null);

      setPartsData(filteredParts);
      setInitPartsData(filteredParts);
      setLoading(false);
    }
  };

  /*
   * The function `handleAddPartData` closes a modal, clears previous data, resets search title, and sets field value with selected table part data.
   */
  const handleAddPartData = async (setFieldValue) => {
    setAddPartModal(false);
    setPrevPartsData([]);
    setSearchTitle("");
    setFieldValue("orderItems.items", selectedTablePartData);
  };

  const handleAddSetData = async () => {
    setAddSetModal(false);
  };

  /*
   * The function `handleRowSelected` manages the selection of rows in a table, updates data accordingly, calculates costs, and updates the state with the modified data.
   */
  const handleRowSelected = (
    row,
    checked,
    setFieldValue,
    selectedPartsData = [],
    values
  ) => {
    let allSelectedRows = [...selectedPartsData];
    let selectedRows = [...prevPartsData];
    const updatedData = [...partsData];
    const updatedInitData = [...initPartsData];

    let updateRowData = row;

    if (checked) {
      allSelectedRows.unshift({ ...row, selectedPart: true });
      selectedRows.unshift({ ...row, selectedPart: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id != row?.id);
      selectedRows = selectedRows.filter((el) => el?.id != row?.id);
      updateRowData.fillMax = false;
      updateRowData.quantity = 1;
      updateRowData.totalCost = updateRowData.unitCost;

      const rowchnages = updatedData.findIndex((el) => el.id == row.id);

      updatedData[rowchnages] = updateRowData;

      const rowInitchnages = updatedInitData.findIndex((el) => el.id == row.id);

      updatedInitData[rowInitchnages] = updateRowData;
    }

    const selectedRowIds = allSelectedRows.map((row) => row.id);

    updatedData.forEach((item) => {
      if (selectedRowIds?.length === 0) {
        // If selectedRowIds is empty, set selectedPart to false for all items
        item.selectedPart = false;
      } else {
        // Otherwise, set selectedPart to true if the item is in selectedRowIds array
        item.selectedPart = selectedRowIds.includes(item.id);
      } // Set selectedPart to true if the item is in selectedRowIds array
    });

    updatedInitData.forEach((item) => {
      if (selectedRowIds?.length === 0) {
        // If selectedRowIds is empty, set selectedPart to false for all items
        item.selectedPart = false;
      } else {
        // Otherwise, set selectedPart to true if the item is in selectedRowIds array
        item.selectedPart = selectedRowIds.includes(item.id);
      } // Set selectedPart to true if the item is in selectedRowIds array
    });
    // Move selected rows to the top
    selectedRowIds.forEach((selectedRowId, index) => {
      const selectedIndex = updatedData.findIndex(
        (item) => item.id == selectedRowId
      );
      if (selectedIndex != -1 && selectedIndex != index) {
        const [selectedRow] = updatedData.splice(selectedIndex, 1); // Remove selected row
        updatedData.splice(index, 0, selectedRow); // Insert selected row at the top
      }

      const selectedPartIndex = updatedData.findIndex(
        (item) => item.id == selectedRowId
      );
      if (selectedPartIndex != -1) {
        updatedData[selectedPartIndex].selectedPart = true; // Update selected
        // Update selected
      }

      const selectedInitIndex = updatedInitData.findIndex(
        (item) => item.id == selectedRowId
      );
      if (selectedInitIndex !== -1 && selectedInitIndex !== index) {
        const [selectedRow] = updatedInitData.splice(selectedInitIndex, 1); // Remove selected row
        updatedInitData.splice(index, 0, selectedRow); // Insert selected row at the top
      }
      const selectedInitPartIndex = updatedInitData.findIndex(
        (item) => item.id == selectedRowId
      );
      if (selectedInitPartIndex !== -1) {
        updatedInitData[selectedInitPartIndex].selectedPart = true; // Update selected // Update selected
      }
    });

    let subTotalCost = 0;
    allSelectedRows.forEach((row) => {
      subTotalCost += Number(row.totalCost);
    });

    let subTotalCostData = handleDecimalPoints(Number(subTotalCost));

    // Update the selected row in allSelectedRows

    // Update state with the modified data

    // Update state with the modified data
    setPartsData(updatedData);
    setInitPartsData(updatedInitData);

    // setFieldValue('orderItems.items', allSelectedRows);
    setSelectedTablePartData(allSelectedRows);
    setPrevPartsData(selectedRows);
    setFieldValue("orderItems.subTotal", Number(subTotalCostData), false);

    let texValue;
    let shipValue;
    let otherValue;

    if (values?.orderItems?.taxesInPercentage) {
      texValue = handleDecimalPoints(
        Number(subTotalCostData * (Number(values.orderItems.taxes) / 100))
      );
      setFieldValue("orderItems.taxAmt", texValue, false);
    } else {
      texValue = handleDecimalPoints(Number(values?.orderItems?.taxAmt)) || 0;
    }

    if (values?.orderItems?.shippingCostInPercentage) {
      shipValue = Number(
        handleDecimalPoints(
          subTotalCostData * (Number(values.orderItems.shippingCost) / 100)
        )
      );
      setFieldValue("orderItems.shipAmt", shipValue, false);
    } else {
      shipValue = handleDecimalPoints(values?.orderItems?.shipAmt) || 0;
    }

    if (values.orderItems.otherCostInPercentage) {
      otherValue = Number(
        handleDecimalPoints(
          subTotalCostData * (Number(values.orderItems.otherCost) / 100)
        )
      );
      setFieldValue("orderItems.otherAmt", otherValue, false);
    } else {
      otherValue = handleDecimalPoints(values?.orderItems?.otherAmt) || 0;
    }

    let finalCost =
      Number(texValue) +
      Number(shipValue) +
      Number(otherValue) +
      Number(subTotalCostData);

    setFieldValue(
      "orderItems.totalCost",
      Number(handleDecimalPoints(finalCost), false)
    );

    // setFieldValue('orderItems.taxes', null);
    // setFieldValue('orderItems.shippingCost', null);
    // setFieldValue('orderItems.taxAmt', null);
    // setFieldValue('orderItems.otherAmt', null);
    // setFieldValue('orderItems.shipAmt', null);
    // setFieldValue('orderItems.otherCost', null);
  };

  /*
   * The function `handleQtyChange` updates quantity and cost values for selected parts in a table based
   * on user input.
   */
  const handleQtyChange = (
    values,
    row,
    checked,
    setFieldValue,
    selectedPartsData = [],
    index = null,
    setFieldTouched
  ) => {
    let value = parseFloat(checked).toFixed(4);
    let allSelectedRows = [...partsData];
    let allSelectedRowsData = Array.isArray(selectedPartsData)
      ? [...selectedPartsData]
      : [];
    let allSelectedInitRows = [...initPartsData];
    const rowIndex = allSelectedRows.findIndex((el) => el?.id === row?.id);
    const rowInitIndex = allSelectedInitRows.findIndex(
      (el) => el?.id === row?.id
    );

    const updatedRow = { ...allSelectedRows[rowIndex] };
    const updatedInitRow = { ...allSelectedInitRows[rowInitIndex] };
    if (checked) {
      updatedRow.quantity = Number(value);
      updatedInitRow.quantity = Number(value);
      updatedRow.totalCost = handleDecimalPoints(
        Number(value) * Number(updatedRow.unitCost)
      );
      updatedInitRow.totalCost = handleDecimalPoints(
        Number(value) * Number(updatedInitRow.unitCost)
      );
      if (updatedRow.availableQty == Number(value)) {
        updatedRow.fillMax = true;
      } else {
        updatedRow.fillMax = false;
      }
      if (updatedInitRow.availableQty == Number(value)) {
        updatedInitRow.fillMax = true;
      } else {
        updatedInitRow.fillMax = false;
      }
    } else {
      updatedRow.quantity = null;
      updatedInitRow.quantity = null;
      updatedRow.fillMax = false;
      updatedInitRow.fillMax = false;
      updatedRow.totalCost = Number(updatedRow.unitCost);
      updatedInitRow.totalCost = Number(updatedInitRow.unitCost);
    }

    allSelectedRows[rowIndex] = updatedRow;
    allSelectedInitRows[rowInitIndex] = updatedInitRow;

    if (allSelectedRowsData?.length > 0) {
      const rowIndexSelected = allSelectedRowsData.findIndex(
        (el) => el?.id === row?.id
      );
      const updatedSelectedRow = { ...allSelectedRowsData[rowIndexSelected] };

      if (checked) {
        updatedSelectedRow.quantity = Number(value);
        updatedSelectedRow.totalCost = handleDecimalPoints(
          Number(value) * Number(updatedSelectedRow.unitCost)
        );

        if (Number(updatedSelectedRow.availableQty) == Number(value)) {
          updatedSelectedRow.fillMax = true;
        } else {
          updatedSelectedRow.fillMax = false;
        }
        // if (updatedSelectedRow.availableQty == Number(checked)) {
        //   updatedSelectedRow.fillMax = true;
        // }
      } else {
        updatedSelectedRow.quantity = null;
        updatedSelectedRow.fillMax = false;
        updatedSelectedRow.totalCost = 0;
        // Update the selected row in allSelectedRows

        // Update state with the modified data
      }

      allSelectedRowsData[rowIndexSelected] = updatedSelectedRow;

      let subTotalCost = 0;
      allSelectedRowsData.forEach((row) => {
        subTotalCost += Number(row.totalCost);
      });

      if (index !== null) {
        setFieldTouched(`orderItems.items[${index}].quantity`, false);
      }

      setSelectedTablePartData(allSelectedRowsData);

      handleDecimalPoints(subTotalCost);
      // setFieldValue('orderItems.items', allSelectedRowsData, false);
      setFieldValue("orderItems.subTotal", Number(subTotalCost), false);

      let texValue;
      let shipValue;
      let otherValue;

      if (values.orderItems.taxesInPercentage) {
        texValue = Number(
          handleDecimalPoints(
            subTotalCost * (Number(values.orderItems.taxes) / 100)
          )
        );
        setFieldValue("orderItems.taxAmt", texValue, false);
      } else {
        texValue = handleDecimalPoints(values?.orderItems?.taxAmt) || 0;
      }

      if (values.orderItems.shippingCostInPercentage) {
        shipValue = Number(
          handleDecimalPoints(
            subTotalCost * (Number(values.orderItems.shippingCost) / 100)
          )
        );
        setFieldValue("orderItems.shipAmt", shipValue, false);
      } else {
        shipValue = handleDecimalPoints(values?.orderItems?.shipAmt) || 0;
      }

      if (values.orderItems.otherCostInPercentage) {
        otherValue = Number(
          handleDecimalPoints(
            subTotalCost * (Number(values.orderItems.otherCost) / 100)
          )
        );
        setFieldValue("orderItems.otherAmt", otherValue, false);
      } else {
        otherValue = handleDecimalPoints(values?.orderItems?.otherAmt) || 0;
      }

      let finalCost =
        Number(texValue) +
        Number(shipValue) +
        Number(otherValue) +
        Number(subTotalCost);

      setFieldValue(
        "orderItems.totalCost",
        handleDecimalPoints(Number(finalCost)),
        false
      );
    }
    setPartsData(allSelectedRows);
    setInitPartsData(allSelectedInitRows);
  };

  /*
   * The function `handleUnitCostChange` updates the unit cost and total cost of selected rows in a table
   * based on user input, and calculates the subtotal, tax amount, shipping amount, other costs, and total cost for an order.
   */
  const handleUnitCostChange = (
    values,
    row,
    checked,
    setFieldValue,
    selectedPartsData = [],
    index = null,
    setFieldTouched
  ) => {
    let allSelectedRowsData = Array.isArray(selectedPartsData)
      ? [...selectedPartsData]
      : [];

    let value = checked;
    // let allSelectedRows = [...partsData];
    // let allSelectedInitRows = [...initPartsData];
    // const rowIndex = allSelectedRows.findIndex((el) => el?.id === row?.id);
    // const rowInitIndex = allSelectedInitRows.findIndex(
    //   (el) => el?.id === row?.id
    // );

    // const updatedRow = { ...allSelectedRows[rowIndex] };
    // const updatedInitRow = { ...allSelectedInitRows[rowInitIndex] };
    // if (checked) {
    //   updatedRow.quantity = Number(checked);
    //   updatedInitRow.quantity = Number(checked);
    //   updatedRow.totalCost = parseFloat(
    //     Number(checked) * Number(updatedRow.unitCost)
    //   ).toFixed(4);
    //   updatedInitRow.totalCost = parseFloat(
    //     Number(checked) * Number(updatedInitRow.unitCost)
    //   ).toFixed(4);
    //   if (updatedRow.availableQty == Number(checked)) {
    //     updatedRow.fillMax = true;
    //   } else {
    //     updatedRow.fillMax = false;
    //   }
    //   if (updatedInitRow.availableQty == Number(checked)) {
    //     updatedInitRow.fillMax = true;
    //   } else {
    //     updatedInitRow.fillMax = false;
    //   }
    // } else {
    //   updatedRow.quantity = null;
    //   updatedInitRow.quantity = null;
    //   updatedRow.fillMax = false;
    //   updatedInitRow.fillMax = false;
    //   updatedRow.totalCost = Number(updatedRow.unitCost);
    //   updatedInitRow.totalCost = Number(updatedInitRow.unitCost);
    // }

    // allSelectedRows[rowIndex] = updatedRow;
    // allSelectedInitRows[rowInitIndex] = updatedInitRow;

    if (allSelectedRowsData?.length > 0) {
      const rowIndexSelected = allSelectedRowsData.findIndex(
        (el) => el?.id === row?.id
      );
      const updatedSelectedRow = { ...allSelectedRowsData[rowIndexSelected] };

      if (checked) {
        updatedSelectedRow.unitCost = handleDecimalPoints(value);
        updatedSelectedRow.totalCost = handleDecimalPoints(
          Number(value) * Number(updatedSelectedRow.quantity)
        );
        // if (updatedSelectedRow.availableQty == Number(checked)) {
        //   updatedSelectedRow.fillMax = true;
        // }
      } else {
        updatedSelectedRow.unitCost = null;
        updatedSelectedRow.totalCost = 0;

        // Update the selected row in allSelectedRows

        // Update state with the modified data
      }

      allSelectedRowsData[rowIndexSelected] = updatedSelectedRow;

      let subTotalCost = 0;
      allSelectedRowsData.forEach((row) => {
        subTotalCost += Number(row.totalCost);
      });

      if (index !== null) {
        setFieldTouched(`orderItems.items[${index}].unitCost`, false);
      }
      Number(subTotalCost);
      handleDecimalPoints(subTotalCost);

      // setFieldValue('orderItems.items', allSelectedRowsData, false);
      setSelectedTablePartData(allSelectedRowsData);
      setFieldValue("orderItems.subTotal", Number(subTotalCost), false);

      let texValue;
      let shipValue;
      let otherValue;

      if (values.orderItems.taxesInPercentage) {
        texValue = Number(
          handleDecimalPoints(
            subTotalCost * (Number(values.orderItems.taxes) / 100)
          )
        );
        setFieldValue("orderItems.taxAmt", texValue, false);
      } else {
        texValue = handleDecimalPoints(values?.orderItems?.taxAmt) || 0;
      }

      if (values.orderItems.shippingCostInPercentage) {
        shipValue = Number(
          handleDecimalPoints(
            subTotalCost * (Number(values.orderItems.shippingCost) / 100)
          )
        );
        setFieldValue("orderItems.shipAmt", shipValue, false);
      } else {
        shipValue = handleDecimalPoints(values?.orderItems?.shipAmt) || 0;
      }

      if (values.orderItems.otherCostInPercentage) {
        otherValue = Number(
          handleDecimalPoints(
            subTotalCost * (Number(values.orderItems.otherCost) / 100)
          )
        );
        setFieldValue("orderItems.otherAmt", otherValue, false);
      } else {
        otherValue = handleDecimalPoints(values?.orderItems?.otherAmt) || 0;
      }

      let finalCost =
        Number(texValue) +
        Number(shipValue) +
        Number(otherValue) +
        Number(subTotalCost);

      setFieldValue(
        "orderItems.totalCost",
        handleDecimalPoints(Number(finalCost)),
        false
      );
    }

    // setFieldValue('orderItems.taxes', null, false);
    // setFieldValue('orderItems.shippingCost', null, false);
    // setFieldValue('orderItems.otherCost', null, false);
    // setFieldValue('orderItems.taxAmt', null, false);
    // setFieldValue('orderItems.otherAmt', null, false);
    // setFieldValue('orderItems.shipAmt', null, false);

    // setPartsData(allSelectedRows);
    // setInitPartsData(allSelectedInitRows);
  };

  /*
   * The function `handleFillSelected` updates selected rows in a table based on user input and calculates total costs and other values accordingly.
   */
  const handleFillSelected = (
    values,
    row,
    checked,
    setFieldValue,
    selectedPartsData = []
  ) => {
    const allSelectedRows = [...selectedPartsData];
    const rowIndex = allSelectedRows.findIndex((el) => el?.id === row?.id);

    const updatedRow = { ...allSelectedRows[rowIndex] };
    if (rowIndex !== -1) {
      if (checked) {
        updatedRow.fillMax = true;
        updatedRow.quantity = Number(updatedRow.availableQty);
        updatedRow.totalCost = handleDecimalPoints(
          Number(updatedRow.availableQty) * Number(updatedRow.unitCost)
        );
      } else {
        updatedRow.fillMax = false;
        updatedRow.quantity = 1;
        updatedRow.totalCost = updatedRow.unitCost;
      }
      allSelectedRows[rowIndex] = updatedRow;

      let subTotalCost = 0;
      allSelectedRows.forEach((row) => {
        subTotalCost += Number(row.totalCost);
      });

      Number(subTotalCost);

      handleDecimalPoints(subTotalCost);

      // Update the selected row in allSelectedRows

      // Update state with the modified data
      // setFieldValue('orderItems.items', allSelectedRows);
      setSelectedTablePartData(allSelectedRows);

      setFieldValue("orderItems.subTotal", subTotalCost, false);

      let texValue;
      let shipValue;
      let otherValue;

      if (values.orderItems.taxesInPercentage) {
        texValue = handleDecimalPoints(
          subTotalCost * (Number(values.orderItems.taxes) / 100)
        );

        setFieldValue("orderItems.taxAmt", texValue, false);
      } else {
        texValue = handleDecimalPoints(values?.orderItems?.taxAmt) || 0;
      }

      if (values.orderItems.shippingCostInPercentage) {
        shipValue = handleDecimalPoints(
          subTotalCost * (Number(values.orderItems.shippingCost) / 100)
        );

        setFieldValue("orderItems.shipAmt", shipValue, false);
      } else {
        shipValue = handleDecimalPoints(values?.orderItems?.shipAmt) || 0;
      }

      if (values.orderItems.otherCostInPercentage) {
        otherValue = handleDecimalPoints(
          subTotalCost * (Number(values.orderItems.otherCost) / 100)
        );

        setFieldValue("orderItems.otherAmt", otherValue, false);
      } else {
        otherValue = handleDecimalPoints(values?.orderItems?.otherAmt) || 0;
      }

      let finalCost =
        Number(texValue) +
        Number(shipValue) +
        Number(otherValue) +
        subTotalCost;

      setFieldValue(
        "orderItems.totalCost",
        handleDecimalPoints(Number(finalCost), false)
      );
      // setFieldValue('orderItems.taxes', null);
      // setFieldValue('orderItems.shippingCost', null);
      // setFieldValue('orderItems.otherCost', null);
      // setFieldValue('orderItems.taxAmt', null);
      // setFieldValue('orderItems.otherAmt', null);
      // setFieldValue('orderItems.shipAmt', null);
    }

    // Update the selected row in partsData
    const updatedData = [...partsData];
    const updatedRowIndex = updatedData.findIndex((item) => item.id === row.id);
    const updatedPartRow = { ...updatedData[updatedRowIndex] };
    if (updatedRowIndex !== -1) {
      if (checked) {
        updatedPartRow.fillMax = true;
        updatedPartRow.quantity = Number(updatedPartRow.availableQty);
        updatedPartRow.totalCost = handleDecimalPoints(
          Number(updatedPartRow.availableQty) * Number(updatedPartRow.unitCost)
        );
      } else {
        updatedPartRow.fillMax = false;
        updatedPartRow.quantity = 1;
        updatedPartRow.totalCost = updatedPartRow.unitCost;
      }

      updatedData[updatedRowIndex] = {
        ...updatedData[updatedRowIndex],
        ...updatedPartRow,
      };
    }

    // Update the selected row in initPartsData
    const updatedInitData = [...initPartsData];
    const updatedInitRowIndex = updatedInitData.findIndex(
      (item) => item.id === row.id
    );
    const updatedPartInitRow = { ...updatedInitData[updatedInitRowIndex] };
    if (updatedInitRowIndex !== -1) {
      if (checked) {
        updatedPartInitRow.fillMax = true;
        updatedPartInitRow.quantity = Number(updatedPartInitRow.availableQty);
        updatedPartInitRow.totalCost = handleDecimalPoints(
          Number(updatedPartInitRow.availableQty) *
            Number(updatedPartInitRow.unitCost)
        );
      } else {
        updatedPartInitRow.fillMax = false;
        updatedPartInitRow.quantity = 1;
        updatedPartInitRow.totalCost = updatedPartInitRow.unitCost;
      }
      updatedInitData[updatedInitRowIndex] = {
        ...updatedInitData[updatedInitRowIndex],
        ...updatedPartInitRow,
      };
    }

    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
  };

  /*
   * The function `handleSetRowSelected` manages the selection of rows in a data set, moving selected rows to the top and updating the state accordingly
   */
  const handleSetRowSelected = (
    row,
    checked,
    setFieldValue,
    selectedSetsData = []
  ) => {
    let allSelectedRows = [...selectedSetsData];
    if (checked) {
      allSelectedRows.unshift({ ...row, selectedSet: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
    }

    const updatedData = [...setsData];
    const updatedInitData = [...initSetsData];

    const selectedRowIds = allSelectedRows.map((row) => row.id);
    // Move selected rows to the top
    selectedRowIds.forEach((selectedRowId, index) => {
      const selectedIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedIndex !== -1 && selectedIndex !== index) {
        const [selectedRow] = updatedData.splice(selectedIndex, 1); // Remove selected row
        updatedData.splice(index, 0, selectedRow); // Insert selected row at the top
      }

      const selectedPartIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedPartIndex !== -1) {
        updatedData[selectedPartIndex].selectedSet = true; // Update selected
      }

      const selectedInitIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitIndex !== -1 && selectedInitIndex !== index) {
        const [selectedRow] = updatedInitData.splice(selectedInitIndex, 1); // Remove selected row
        updatedInitData.splice(index, 0, selectedRow); // Insert selected row at the top
      }
      const selectedInitPartIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitPartIndex !== -1) {
        updatedInitData[selectedInitPartIndex].selectedSet = true; // Update selected
      }
    });

    // Update state with the modified data
    setSetsData(updatedData);
    setInitSetsData(updatedInitData);
    setFieldValue("sets", allSelectedRows);
  };

  /*
   * The function `removeFromSelectedParts` removes an item from a list of selected parts and updates the
   * state accordingly.
   */
  const removeFromSelectedParts = (
    index,
    selectedPartsData = [],
    setFieldValue = () => {},
    values
  ) => {
    // let partData = partsData.find((parts) => parts.id === updatedSelectedPartsData[index].id)
    const updatedSelectedPartsData = [...selectedPartsData];

    updatedSelectedPartsData[index].selectedPart = false;
    updatedSelectedPartsData[index].fillMax = false;
    updatedSelectedPartsData.splice(index, 1); // Remove the item at the specified index
    setFieldValue("orderItems.items", updatedSelectedPartsData);
    handleRowSelected(
      selectedPartsData[index],
      false,
      setFieldValue,
      updatedSelectedPartsData,
      values
    ); // Update the state
  };

  const removeFromSelectedSets = (
    index,
    selectedSetsData = [],
    setFieldValue = () => {}
  ) => {
    const updatedSelectedSetsData = [...selectedSetsData];
    updatedSelectedSetsData[index].selectedSet = false;
    updatedSelectedSetsData.splice(index, 1); // Remove the item at the specified index
    setFieldValue("sets", updatedSelectedSetsData);
    handleSetRowSelected(null, false, setFieldValue, updatedSelectedSetsData); // Update the state
  };

  let allQtyOptions = [
    {
      key: 0,
      value: "pieces",
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: "items",
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: "units",
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: "liters (L)",
      text: selectedLanguage == "en" ? "l" : "لتر",
    },
    {
      key: 4,
      value: "gallons (gal)",
      text: selectedLanguage == "en" ? "gal" : "جالون",
    },
    {
      key: 5,
      value: "cubic meters (m³)",
      text: selectedLanguage == "en" ? "m³" : "م³",
    },
    {
      key: 6,
      value: "cubic feet (ft³)",
      text: selectedLanguage == "en" ? "ft³" : "ق³",
    },
    {
      key: 7,
      value: "barrels",
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: "kilograms (kg)",
      text: selectedLanguage == "en" ? "kg" : "كجم",
    },
    {
      key: 9,
      value: "pounds (lb)",
      text: selectedLanguage == "en" ? "lb" : "رطل",
    },

    {
      key: 10,
      value: "tons",
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: "metric tonnes",
      text: t.parts.mass_unit.m_tons,
    },
  ];

  /*
   * The function `getData` fetches geocode data using latitude and longitude, sets the address field value, and resolves with the formatted address.
   */

  /*
   * The function `onMarkerDragEnd` updates the marker position on a map, fetches data based on the new
   * coordinates, and retrieves address information using geocoding.
   */

  /*
   * The function `HandleUnitType` takes in data and returns the corresponding text value from `allQtyOptions` based on the data provided.
   */
  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

  /*
   * The function `handleClosePartModal` calculates and updates various cost values based on selected parts data and order item values.
   */
  const handleClosePartModal = (setFieldValue, values) => {
    let newAddedData = [...prevPartsData];
    let parts = [...selectedTablePartData];

    parts = parts?.filter(
      (part) => !newAddedData.some((addedPart) => addedPart.id == part.id)
    );

    setAddPartModal(false);
    setSearchTitle("");

    setSelectedTablePartData(parts);

    let subTotalCost = 0;
    parts.forEach((row) => {
      subTotalCost += Number(row.totalCost);
    });

    Number(subTotalCost);

    let texValue;
    let shipValue;
    let otherValue;

    if (values.orderItems.taxesInPercentage) {
      texValue = handleDecimalPoints(
        subTotalCost * (Number(values.orderItems.taxes) / 100)
      );

      setFieldValue("orderItems.taxAmt", texValue, false);
    } else {
      texValue = handleDecimalPoints(values?.orderItems?.taxAmt) || 0;
    }

    if (values.orderItems.shippingCostInPercentage) {
      shipValue = handleDecimalPoints(
        subTotalCost * (Number(values.orderItems.shippingCost) / 100)
      );

      setFieldValue("orderItems.shipAmt", shipValue, false);
    } else {
      shipValue = handleDecimalPoints(values?.orderItems?.shipAmt) || 0;
    }

    if (values.orderItems.otherCostInPercentage) {
      otherValue = handleDecimalPoints(
        subTotalCost * (Number(values.orderItems.otherCost) / 100)
      );
      setFieldValue("orderItems.otherAmt", otherValue, false);
    } else {
      otherValue = handleDecimalPoints(values?.orderItems?.otherAmt) || 0;
    }

    let finalCost =
      Number(texValue) + Number(shipValue) + Number(otherValue) + subTotalCost;

    setFieldValue("orderItems.subTotal", Number(subTotalCost));
    setFieldValue("orderItems.totalCost", Number(finalCost), false);
  };

  return (
    <>
      {fetchingLocation ? (
        <div style={{ minHeight: "100vh" }}>
          <Dimmer active inverted>
            <Loader inverted content={t.common.loading} />
          </Dimmer>
        </div>
      ) : (
        <Formik
          initialValues={initialFormValues}
          enableReinitialize={true}
          validationSchema={PersonValidationSchema}
          validate={(values) => {
            const validationErrors = {};
            try {
              PersonValidationSchema.validateSync(values, {
                abortEarly: false,
              });
            } catch (err) {
              if (isSubmitClicked?.current) {
                isSubmitClicked.current = false;
                // Trigger scrollToError only if the submit button was clicked
                scrollToError();
              }
          
            }
            return validationErrors;
          }}
          onSubmit={(values, { resetForm }) => {
            isSubmitClicked.current = false;
            let data = handleAddSet(values, resetForm);

          }}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            setFieldTouched,
            handleBlur,
            submitForm,
            resetForm,
          }) => {
            return (
              <>
                {buttonStatus == 1 ? (
                  <Dimmer active inverted>
                    <Spinner className="m-5" color="primary">
                      {null}
                    </Spinner>
                    {/* <Loader inverted content="Loading" /> */}
                  </Dimmer>
                ) : (
                  ""
                )}
                <FormikForm>
                  <div
                    className="body p-6"
                    style={{
                      height: "90vh",
                      overflowX: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <div
                      className={`billing-info billing-info2 ${
                        selectedLanguage == "en" ? "" : ""
                      }`}
                    >
                      <DetailsIcon />
                      <span
                        style={{ fontSize: "16px" }}
                        className={` required-text ${
                          selectedLanguage == "en" ? "ml-2" : "mr-2"
                        }`}
                      >
                        {t.po.form.details}
                      </span>
                    </div>
                    <Form.Field>
                      <label className="label-item mt-5">
                        {t.workOrders.table.title}

                        <Asterisk color="#172B4D" />
                      </label>

                      <Input
                        autoComplete="new-password"
                        fluid
                        placeholder={t.workOrders.form.title_name}
                        value={values?.details?.title}
                        onChange={(e, value) =>
                          setFieldValue("details.title", e.target.value)
                        }
                        error={
                          errors?.details?.title && touched?.details?.title
                        }
                        onBlur={(e) => {
                          setInitChange(true);
                          setFieldTouched("details.title", true);
                        }}
                      />
                      {errors?.details?.title && touched?.details?.title ? (
                        <div className="label-error">
                          {errors?.details?.title}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Field>

                    <Form.Field>
                      <label className="label-item">
                        {t.po.form.ponumber}
                        <Asterisk color="#172B4D" />
                      </label>
                      <Input
                        autoComplete="new-password"
                        fluid
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onBlur={(e) => {
                          setInitChange(true);
                          setFieldTouched("details.poNumber", true);
                        }}
                        error={
                          errors?.details?.poNumber &&
                          touched?.details?.poNumber
                        }
                        placeholder={t.po.form.add_po}
                        onKeyDown={(evt) => handleKeyDown(evt)}
                        value={values?.details?.poNumber}
                        onChange={(e, value) => {
                          if (value) {
                            setFieldValue("details.poNumber", e.target.value);
                          } else {
                            setFieldValue("details.poNumber", null);
                          }
                        }}
                      />
                      {errors?.details?.poNumber &&
                      touched?.details?.poNumber ? (
                        <div className="label-error">
                          {errors?.details?.poNumber}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Field>

                    <Form.Field>
                      <label className="label-item">
                        {" "}
                        {t.po.table.vendor} <Asterisk color="#172B4D" />
                      </label>
                      <Dropdown
                        placeholder={t.po.form.add_vendor}
                        noResultsMessage={t.common.no_results_found}
                        fluid
                        clearable
                        search
                        selection
                        options={options.vendor}
                        value={values.details.vendor}
                        onChange={(e, value) => {
                          setFieldValue("details.vendor", value.value);
                          let address = options?.vendor?.find(
                            (el) => el.value === value.value
                          );

                          if (address?.address) {
                            setFieldTouched("details.address", false);

                            setFieldValue(
                              "details.address",
                              address?.address,
                              false
                            );
                          } else {
                            setFieldValue("details.address", "", false);
                          }
                        }}
                        onOpen={!edit ? () => fetchVendorsData() : null}
                        error={
                          errors?.details?.vendor && touched?.details?.vendor
                        }
                        onBlur={(e) => {
                          setFieldTouched("details.vendor", true);
                        }}
                      />

                      {errors?.details?.vendor && touched?.details?.vendor ? (
                        <div className="label-error">
                          {errors?.details?.vendor}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Field>

                    {/* <Form.Field>
                    <label className="label-item">
                      {t.locations.table.address}
                      <Asterisk color="#172B4D" />
                    </label>
                    <div>

                      <AutocompleteInput
                        value={values}
                        t={t}
                        onChange={(e) => {
                          setFieldValue('details.address', e.target.value);
                          setDefaultCenter({ lat: 0, lng: 0 });
                        }}
                        onBlur={(e) => {
                          setInitChange(true);
                          setFieldTouched('details.address', true);
                        }}
                        setFieldValue={setFieldValue}
                        error={
                          errors?.details?.address && touched?.details?.address
                        }
                        setDefaultCenter={setDefaultCenter}
                      />
                     
                    </div>
                    {errors?.details?.address && touched?.details?.address ? (
                      <div
                        style={
                          !values?.details?.address
                            ? { marginTop: '-15px' }
                            : {}
                        }
                        className="label-error"
                      >
                        {errors?.details?.address}
                      </div>
                    ) : (
                      ''
                    )}
                  </Form.Field> */}

                    <Form.Field>
                      <label className="label-item">
                        {t.po.form.vendor_address}

                        <Asterisk color="#172B4D" />
                      </label>

                      <Input
                        autoComplete="new-password"
                        fluid
                        placeholder={t.po.form.address_place}
                        value={values?.details?.address}
                        onChange={(e, value) =>
                          setFieldValue("details.address", e.target.value)
                        }
                        error={
                          errors?.details?.address && touched?.details?.address
                        }
                        onBlur={(e) => {
                          setInitChange(true);
                          setFieldTouched("details.address", true);
                        }}
                      />
                      {errors?.details?.address && touched?.details?.address ? (
                        <div className="label-error">
                          {errors?.details?.address}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Field>

                    {/* {defaultCenter?.lat && defaultCenter?.lng ? (
                    <div>
                      <Map
                        google={window.google}
                        style={style}
                        containerStyle={containerStyle}
                        center={defaultCenter}
                        initialCenter={defaultCenter}
                        // zoom={editingLocationMode ? 12 : 10}
                        version="weekly"
                      >
                        <Marker
                          position={defaultCenter}
                          draggable={true}
                          icon={{
                            url: CustomMarkerIcon,
                            scaledSize: new window.google.maps.Size(50, 50),
                          }}
                          onDragend={(t, map, coord) =>
                            onMarkerDragEnd(t, map, coord, setFieldValue)
                          }
                        />
                      </Map>
                    </div>
                  ) : null} */}

                    <Form.Field>
                      <label className="label-item">
                        {t.workOrders.form.people} <Asterisk color="#172B4D" />
                        <span
                          className="c-pointer"
                          style={{ position: "relative" }}
                          onMouseOver={() => setPeopleTooltip(true)}
                          onMouseLeave={() => setPeopleTooltip(false)}
                        >
                          {showPeopleTooltip ? (
                            <>
                              <InfoIconBlue />
                              <ToolTip
                                color="#fff"
                                backgroundColor="#172B4D"
                                position={
                                  selectedLanguage == "ar"
                                    ? "left top"
                                    : "right top"
                                }
                                show={showPeopleTooltip}
                                textBoxWidth="200px"
                                arrowAlign="center"
                                fontSize="9px"
                                fontWeight="400"
                                fontFamily={
                                  selectedLanguage == "ar"
                                    ? "Noto Kufi Arabic"
                                    : "Roboto"
                                }
                                padding="4px 14px 4px 7px"
                                lineHeight="16px"
                                borderRadius="6px"
                                static
                                moveDown="0px"
                              >
                                <span className="work-order-preview-tooltip parts">
                                  {t.po.form.people_tooltip}
                                </span>
                              </ToolTip>
                            </>
                          ) : (
                            <InfoIcon />
                          )}
                        </span>
                      </label>
                      <Dropdown
                        placeholder={t.workOrders.form.people_placeholder}
                        noResultsMessage={t.common.no_results_found}
                        fluid
                        search
                        multiple
                        selection
                        // disabled={!values?.shippingAddress?.companyName && !(role === 'super_admin' || role === 'admin')}
                        options={options.people}
                        value={values.details.people}
                        onChange={(e, value) =>
                          setFieldValue("details.people", value.value)
                        }
                        onOpen={() =>
                          fetchPeopleData(
                            values?.shippingAddress?.companyName,
                            values.details.people
                          )
                        }
                        error={
                          errors?.details?.people && touched?.details?.people
                        }
                        onBlur={(e) => {
                          setFieldTouched("details.people", true);
                        }}
                      />

                      {errors?.details?.people && touched?.details?.people ? (
                        <div
                          style={{ marginTop: "-15px" }}
                          className="label-error"
                        >
                          {errors?.details?.people}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Field>

                    <Form.Field>
                      <label className="label-item">
                        {t.po.table.due_date}
                        {/* <Icon name="asterisk" color="red" size="small" /> */}
                      </label>

                      <div
                        className="change-date-time-inll position-relative"
                        style={{ marginBottom: "-13px" }}
                      >
                        <DatePicker
                          minDate={new Date().setHours(0, 0, 0, 0)}
                          style={{ width: "100%" }}
                          value={
                            initialFormValues.details.dueDate
                              ? new Date(initialFormValues.details.dueDate)
                              : null
                          }
                          highlightToday={false}
                          onChange={(value) => {
                            if (value && value.valueOf()) {
                              setFieldValue(
                                "details.dueDate",
                                format(value.valueOf(), "yyyy-MM-dd")
                              );
                            } else {
                              setFieldValue("details.dueDate", null);
                            }
                          }}
                          containerClassName="date-picker-people"
                          showMonthDropdown
                          showYearDropdown
                          placeholder={t.po.form.add_dueDate}
                          className={"active"}
                          // locale={lang == 'ar' ? arabic_ar : ''}
                          weekDays={[
                            t.workOrders.details_view.sun,
                            t.workOrders.details_view.mon,
                            t.workOrders.details_view.tue,
                            t.workOrders.details_view.wed,
                            t.workOrders.details_view.thu,
                            t.workOrders.details_view.fri,
                            t.workOrders.details_view.sat,
                          ]}
                          months={[
                            t.common.january,
                            t.common.february,
                            t.common.march,
                            t.common.april,
                            t.common.may,
                            t.common.june,
                            t.common.july,
                            t.common.august,
                            t.common.september,
                            t.common.october,
                            t.common.november,
                            t.common.december,
                          ]}
                          placeholderStyle={{ color: "red" }}
                          format="DD.MM.YYYY"
                        ></DatePicker>
                        <img
                          className="position-absolute"
                          style={
                            selectedLanguage == "en"
                              ? { top: "8px", right: "7px" }
                              : { top: "8px", left: "7px" }
                          }
                          src={calendar_icon}
                        />
                      </div>
                    </Form.Field>
                    <Form className="mb-5">
                      <Form.Field>
                        <label className={`label-item mt-2`}>
                          {t.po.form.additionalInformation}
                        </label>
                        <TextArea
                          autoComplete="new-password"
                          fluid
                          value={values.details.additionalInformation}
                          onChange={(e, value) =>
                            setFieldValue(
                              "details.additionalInformation",
                              e.target.value
                            )
                          }
                          rows={3}
                          placeholder={t.po.form.add_additionalInformation}
                        />
                      </Form.Field>
                    </Form>

                    <Divider />

                    <div
                      className={`billing-info billing-info2 mt-5 ${
                        selectedLanguage == "en" ? "" : ""
                      }`}
                    >
                      <ItemsIcon />
                      <span
                        style={{ fontSize: "16px" }}
                        className={` required-text ${
                          selectedLanguage == "en" ? "ml-2" : "mr-2"
                        }`}
                      >
                        {t.po.form.order_items}
                      </span>
                    </div>
                    <div className="form-button-container justify-content-start mt-3 new-button-add-parts">
                      <button
                        type="button"
                        // content={t.procedures.form.cancel_button}
                        // basic
                        onClick={() => addParts(selectedTablePartData)}
                        className="form-action-button blue "
                      >
                        {t.po.form.add_part}
                      </button>

                      <button
                        type="button"
                        // content={t.procedures.form.submit.add}
                        // primary
                        onClick={() => setToggleAsideModal(true)}
                        // loading={buttonStatus == 1}
                        className="form-action-button light-blue-col"
                      >
                        {t.parts.create_part}
                      </button>
                    </div>
                    {values?.orderItems?.items?.length > 0 ? (
                      <>
                        {/* {console.log(initPartsData, partsData, values)} */}

                        <FieldArray
                          name="parts"
                          render={(arrayHelpers) => (
                            <div
                              className="scroll-data"
                              style={{ overflow: "auto" }}
                            >
                              <div className={"mt-6"}>
                                <div className={`kanbar-header`}>
                                  <div class="grid-container-element set po-form">
                                    <div
                                      style={{
                                        minWidth: "86px",
                                        backgroundColor: "#e9f2ff",
                                      }}
                                      class="grid-child-element set"
                                    >
                                      {t.po.form.item}
                                    </div>
                                    <div
                                      style={{
                                        minWidth: "140px",
                                        backgroundColor: "#e9f2ff",
                                      }}
                                      class="grid-child-element set"
                                    >
                                      {t.parts.form.inv_loc}
                                    </div>
                                    <div
                                      style={{
                                        minWidth: "120px",
                                        backgroundColor: "#e9f2ff",
                                      }}
                                      class="grid-child-element set"
                                    >
                                      {t.po.form.qty_to_order}
                                    </div>
                                    <div
                                      style={{
                                        minWidth: "130px",
                                        backgroundColor: "#e9f2ff",
                                      }}
                                      class="grid-child-element set"
                                    >
                                      {t.parts.form.unit_cost}
                                    </div>
                                    <div
                                      style={{
                                        minWidth: "143px",
                                        backgroundColor: "#e9f2ff",
                                        textAlign: "end",
                                      }}
                                      class="grid-child-element set"
                                    >
                                      {t.parts.form.total_cost}
                                    </div>
                                    <div
                                      style={{
                                        minWidth: "40px",
                                        backgroundColor: "#e9f2ff",
                                      }}
                                      class="grid-child-element set"
                                    ></div>
                                  </div>
                                </div>
                              </div>

                              {values.orderItems.items.map((part, index) => (
                                <div
                                  className="mt-3 grid-container-element set po-form form"
                                  key={index}
                                >
                                  {/** both these conventions do the same */}
                                  <div
                                    className="grid-child-element set form"
                                    style={{
                                      padding: "14px 9px",
                                      minWidth: "86px",
                                    }}
                                  >
                                    {DisplayFormattedText(part?.name, 11)}
                                  </div>

                                  <div
                                    className="grid-child-element set form "
                                    style={{
                                      padding: "14px 9px",
                                      minWidth: "140px",
                                      //  color:"#0c66e4",
                                      //  wordBreak:"break-all"
                                    }}
                                    // onClick={()=>history.push(`/locations?id=${part?.locationId}`)}
                                    // className="ml-4"
                                    // style={{
                                    //   width: '145px',
                                    //   minWidth: '145px',
                                    //   display: 'inline-grid',
                                    //   fontSize: '16px',
                                    //   lineHeight: '24px',
                                    // }}
                                  >
                                    {DisplayFormattedText(part?.location, 11)}
                                  </div>
                                  <div
                                    className="wrap-input-set grid-child-element set form"
                                    style={{
                                      minWidth: "120px",
                                    }}
                                  >
                                    <Input
                                      autoComplete="new-password"
                                      style={{ width: "52px" }}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      onKeyDown={(evt) => handleKeyDown(evt)}
                                      value={part?.quantity}
                                      className="d-flex"
                                      onChange={(e) => {
                                        if (Number(e.target.value)) {
                                          let value = parseFloat(
                                            e.target.value
                                          ).toFixed(4);
                                          setFieldValue(
                                            `orderItems.items[${index}].quantity`,
                                            Number(value)
                                          );
                                          setFieldValue(
                                            `orderItems.items[${index}].totalCost`,
                                            handleDecimalPoints(
                                              Number(value * part.unitCost)
                                            ),
                                            false
                                          );

                                          handleQtyChange(
                                            values,
                                            { id: part.id },
                                            value,
                                            setFieldValue,
                                            selectedTablePartData,
                                            index,
                                            setFieldTouched
                                          );
                                        } else {
                                          setFieldValue(
                                            `orderItems.items[${index}].quantity`,
                                            ""
                                          );
                                          setFieldValue(
                                            `orderItems.items[${index}].totalCost`,
                                            0,
                                            false
                                          );

                                          handleQtyChange(
                                            values,
                                            { id: part.id },
                                            e.target.value,
                                            setFieldValue,
                                            [],
                                            // values.orderItems.items,
                                            index,

                                            setFieldTouched
                                          );
                                        }
                                      }}
                                      error={
                                        errors?.orderItems?.items?.length > 0 &&
                                        errors?.orderItems?.items[index]
                                          ?.quantity &&
                                        touched?.orderItems?.items[index]
                                          ?.quantity
                                      }
                                      onFocus={(e) => {
                                        setFieldTouched(
                                          `orderItems.items[${index}].quantity`,
                                          true
                                        );
                                      }}
                                    >
                                      <input />

                                      <span
                                        className={` align-items-center justify-content-center ${
                                          selectedLanguage == "en"
                                            ? "ml-2"
                                            : "mr-2"
                                        }`}
                                        style={{
                                          backgroundColor: "#E9F2FF",
                                          color: "#0C66E4",
                                          padding: "9px 3px 9px 2px",
                                          fontSize: "14px",
                                          minWidth:
                                            selectedLanguage == "en"
                                              ? "38px"
                                              : "54px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {part?.unitType
                                          ? HandleUnitType(part?.unitType)
                                          : null}
                                      </span>
                                    </Input>
                                    {errors?.orderItems?.items?.length > 0 &&
                                    errors?.orderItems?.items[index]
                                      ?.quantity &&
                                    touched?.orderItems?.items[index]
                                      ?.quantity ? (
                                      <span className="label-error">
                                        {
                                          errors?.orderItems?.items[index]
                                            ?.quantity
                                        }
                                      </span>
                                    ) : null}
                                  </div>

                                  <div
                                    className="wrap-input-set grid-child-element set form po "
                                    style={{
                                      minWidth: "130px",
                                    }}
                                  >
                                    <Input
                                      autoComplete="new-password"
                                      style={{ width: "52px" }}
                                      type="number"
                                      onKeyDown={(evt) => handleKeyDown(evt)}
                                      onWheel={(e) => e.target.blur()}
                                      value={part?.unitCost}
                                      className="d-flex"
                                      onChange={(e) => {
                                        if (Number(e.target.value)) {
                                          let value = handleDecimalPoints(
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `orderItems.items[${index}].unitCost`,
                                            Number(value)
                                          );
                                          setFieldValue(
                                            `orderItems.items[${index}].totalCost`,
                                            Number(
                                              handleDecimalPoints(
                                                value * part.quantity
                                              )
                                            ),
                                            false
                                          );

                                          handleUnitCostChange(
                                            values,
                                            { id: part.id },
                                            value,
                                            setFieldValue,
                                            selectedTablePartData,
                                            index,
                                            setFieldTouched
                                          );
                                        } else {
                                          let value = handleDecimalPoints(
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `orderItems.items[${index}].unitCost`,
                                            ""
                                          );
                                          setFieldValue(
                                            `orderItems.items[${index}].totalCost`,
                                            0,
                                            false
                                          );

                                          handleUnitCostChange(
                                            values,
                                            { id: part.id },
                                            value,
                                            setFieldValue,
                                            [],
                                            // values.orderItems.items,
                                            index,

                                            setFieldTouched
                                          );
                                        }
                                      }}
                                      error={
                                        errors?.orderItems?.items?.length > 0 &&
                                        errors?.orderItems?.items[index]
                                          ?.unitCost &&
                                        touched?.orderItems?.items[index]
                                          ?.unitCost
                                      }
                                      onFocus={(e) => {
                                        setFieldTouched(
                                          `orderItems.items[${index}].unitCost`,
                                          true
                                        );
                                      }}
                                    >
                                      <input />

                                      <span
                                        className={` align-items-center justify-content-center ${
                                          selectedLanguage == "en"
                                            ? "ml-2"
                                            : "mr-2"
                                        }`}
                                        style={{
                                          backgroundColor: "#E9F2FF",
                                          color: "#0C66E4",
                                          padding: "9px 3px 9px 2px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {organisationCurrency}
                                      </span>
                                    </Input>
                                    {errors?.orderItems?.items?.length > 0 &&
                                    errors?.orderItems?.items[index]
                                      ?.unitCost &&
                                    touched?.orderItems?.items[index]
                                      ?.unitCost ? (
                                      <span className="label-error">
                                        {
                                          errors?.orderItems?.items[index]
                                            ?.unitCost
                                        }
                                      </span>
                                    ) : null}
                                  </div>

                                  <div
                                    className={
                                      selectedLanguage == "en"
                                        ? "grid-child-element set form ml-4"
                                        : "grid-child-element set form mr-4"
                                    }
                                    // className="ml-16"
                                    style={{
                                      minWidth: "130px",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "75px",
                                        display: "inline-block",
                                        textAlign: "end",
                                      }}
                                      className="number-cost-set mt-3"
                                    >
                                      {part?.totalCost
                                        ? DisplayFormattedText(
                                            String(part?.totalCost),
                                            7
                                          )
                                        : 0}
                                    </div>
                                    <span
                                      className={` align-items-center justify-content-center ${
                                        selectedLanguage == "en"
                                          ? "ml-2"
                                          : "mr-2"
                                      }`}
                                      style={{
                                        backgroundColor: "#E9F2FF",
                                        color: "#0C66E4",
                                        padding: "9px 3px 9px 2px",
                                        fontSize: "14px",
                                        textAlign: "end",
                                      }}
                                    >
                                      {organisationCurrency}
                                    </span>
                                  </div>

                                  <div
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                      removeFromSelectedParts(
                                        index,
                                        values.orderItems.items,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    className="grid-child-element set form mt-2"
                                  >
                                    <DeleteIcon />
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        />
                      </>
                    ) : null}
                    {values.orderItems.items.length > 0 ? (
                      <div
                        className={`change-for-job-btn ${
                          selectedLanguage == "en" ? "text-right" : "text-left"
                        }`}
                      >
                        <div className="new-grey-btn width-content my-3">
                          {t.po.form.subTotal} &nbsp; &nbsp; &nbsp;
                          {values.orderItems.subTotal} &nbsp;{" "}
                          {organisationCurrency}
                        </div>

                        <div>
                          <div className="set-position-of-percentage">
                            {" "}
                            <span
                              className="c-pointer"
                              onClick={() => {
                                if (
                                  values.orderItems.taxesInPercentage == true
                                ) {
                                  setFieldValue(
                                    "orderItems.taxesInPercentage",
                                    false
                                  );

                                  let value = values.orderItems.taxes;

                                  let taxAmount = handleDecimalPoints(
                                    Number(value)
                                  );

                                  let subCost = handleDecimalPoints(
                                    Number(values.orderItems.subTotal)
                                  );

                                  let totalCostData = handleDecimalPoints(
                                    Number(taxAmount) +
                                      Number(values.orderItems.otherAmt) +
                                      Number(values.orderItems.shipAmt) +
                                      Number(subCost)
                                  );

                                  setFieldValue(
                                    `orderItems.taxAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                } else {
                                  setFieldValue(
                                    "orderItems.taxesInPercentage",
                                    true
                                  );

                                  let value = handleDecimalPoints(
                                    Number(values.orderItems.taxes)
                                  );

                                  let totalCost = handleDecimalPoints(
                                    values.orderItems.subTotal
                                  );
                                  let taxAmount = handleDecimalPoints(
                                    totalCost * (value / 100)
                                  );

                                  let finalCost =
                                    Number(values.orderItems.shipAmt) +
                                    Number(values.orderItems.otherAmt) +
                                    Number(taxAmount) +
                                    Number(totalCost);

                                  // Calculate the total cost including tax
                                  let totalCostData = handleDecimalPoints(
                                    Number(finalCost)
                                  );

                                  setFieldValue(
                                    `orderItems.taxAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                }
                              }}
                            >
                              {values.orderItems.taxesInPercentage ? (
                                <PercentageBlueIcon />
                              ) : (
                                <PercentageLightIcon />
                              )}
                            </span>
                            <span
                              onClick={() => {
                                if (
                                  values.orderItems.taxesInPercentage == true
                                ) {
                                  setFieldValue(
                                    "orderItems.taxesInPercentage",
                                    false
                                  );

                                  let value = values.orderItems.taxes;

                                  let taxAmount = handleDecimalPoints(
                                    Number(value)
                                  );

                                  let subCost = Number(
                                    values.orderItems.subTotal
                                  );

                                  let totalCostData = handleDecimalPoints(
                                    Number(taxAmount) +
                                      Number(values.orderItems.otherAmt) +
                                      Number(values.orderItems.shipAmt) +
                                      Number(subCost)
                                  );

                                  setFieldValue(
                                    `orderItems.taxAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                } else {
                                  setFieldValue(
                                    "orderItems.taxesInPercentage",
                                    true
                                  );

                                  let value = Number(values.orderItems.taxes);

                                  let totalCost = values.orderItems.subTotal;
                                  let taxAmount = handleDecimalPoints(
                                    totalCost * (value / 100)
                                  );

                                  let finalCost =
                                    Number(values.orderItems.shipAmt) +
                                    Number(values.orderItems.otherAmt) +
                                    Number(taxAmount) +
                                    Number(totalCost);

                                  // Calculate the total cost including tax
                                  let totalCostData = handleDecimalPoints(
                                    Number(finalCost)
                                  );

                                  setFieldValue(
                                    `orderItems.taxAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                }
                              }}
                              className="c-pointer ml-2 mr-2"
                            >
                              {" "}
                              {values.orderItems.taxesInPercentage ? (
                                <AmountLightIcon />
                              ) : (
                                <AmountBlueIcon />
                              )}
                            </span>
                            <span className="ml-2 mr-2 fix-width-label">
                              {" "}
                              {t.po.form.taxes}{" "}
                            </span>
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "baseline",
                              }}
                            >
                              <span style={{ width: "67px" }}>
                                <Input
                                  style={{ width: "67px" }}
                                  // defaultValue="1"
                                  autoComplete="new-password"
                                  type="number"
                                  onKeyDown={(evt) => handleKeyDown(evt)}
                                  onWheel={(e) => e.target.blur()}
                                  value={values?.orderItems?.taxes || ""}
                                  onChange={(e) => {
                                    if (Number(e.target.value)) {
                                      let value = handleDecimalPoints(
                                        e.target.value
                                      );
                                      if (
                                        values.orderItems.taxesInPercentage ==
                                        true
                                      ) {
                                        let totalCost =
                                          values.orderItems.subTotal;
                                        let taxAmount = handleDecimalPoints(
                                          totalCost * (value / 100)
                                        );

                                        let finalCost =
                                          Number(values.orderItems.shipAmt) +
                                          Number(values.orderItems.otherAmt) +
                                          Number(taxAmount) +
                                          Number(totalCost);

                                        // Calculate the total cost including tax
                                        let totalCostData = handleDecimalPoints(
                                          Number(finalCost)
                                        );

                                        setFieldValue(
                                          `orderItems.taxes`,
                                          Number(value)
                                        );
                                        setFieldValue(
                                          `orderItems.taxAmt`,
                                          Number(taxAmount),
                                          false
                                        );
                                        setFieldValue(
                                          `orderItems.totalCost`,
                                          Number(totalCostData),
                                          false
                                        );
                                      } else {
                                        let taxAmount = handleDecimalPoints(
                                          Number(value)
                                        );

                                        let subCost = Number(
                                          values.orderItems.subTotal
                                        );

                                        let totalCostData = handleDecimalPoints(
                                          Number(taxAmount) +
                                            Number(values.orderItems.otherAmt) +
                                            Number(values.orderItems.shipAmt) +
                                            Number(subCost)
                                        );
                                        setFieldValue(
                                          `orderItems.taxes`,
                                          Number(value)
                                        );

                                        setFieldValue(
                                          `orderItems.taxAmt`,
                                          Number(taxAmount),
                                          false
                                        );
                                        setFieldValue(
                                          `orderItems.totalCost`,
                                          Number(totalCostData),
                                          false
                                        );
                                      }
                                    } else {
                                      setFieldValue(`orderItems.taxes`, null);
                                      setFieldValue(
                                        `orderItems.taxAmt`,
                                        null,
                                        false
                                      );

                                      let subCost = values.orderItems.subTotal;
                                      let shippingCost =
                                        values.orderItems.shipAmt;
                                      let otherCost =
                                        values.orderItems.otherAmt;

                                      let finalCost =
                                        Number(subCost) +
                                        Number(shippingCost) +
                                        Number(otherCost);
                                      setFieldValue(
                                        `orderItems.totalCost`,
                                        handleDecimalPoints(finalCost),
                                        false
                                      );
                                    }
                                  }}
                                >
                                  <input />
                                </Input>
                                {errors?.orderItems?.taxes ? (
                                  <span className="label-error d-block">
                                    {errors?.orderItems?.taxes}
                                  </span>
                                ) : null}{" "}
                              </span>

                              <span
                                className={` align-items-center justify-content-center ${
                                  selectedLanguage == "en" ? "ml-2" : "mr-2"
                                }`}
                                style={{
                                  backgroundColor: "#E9F2FF",
                                  color: "#0C66E4",
                                  padding: "5px",
                                  fontSize: "14px",
                                  width: "37px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              >
                                {values.orderItems.taxesInPercentage
                                  ? "%"
                                  : organisationCurrency}
                              </span>
                            </span>
                            <span className="span-number-per">
                              {values.orderItems.taxesInPercentage == true &&
                              values.orderItems.taxAmt ? (
                                <>
                                  {handleDecimalPoints(
                                    values.orderItems.taxAmt
                                  )}
                                </>
                              ) : null}
                            </span>
                          </div>

                          <div className="set-position-of-percentage">
                            {" "}
                            <span
                              className="c-pointer"
                              onClick={() => {
                                if (
                                  values.orderItems.shippingCostInPercentage ==
                                  true
                                ) {
                                  setFieldValue(
                                    "orderItems.shippingCostInPercentage",
                                    false
                                  );

                                  let value = values.orderItems.shippingCost;

                                  let taxAmount = handleDecimalPoints(
                                    Number(value)
                                  );
                                  let subCost = Number(
                                    values.orderItems.subTotal
                                  );

                                  let totalCostData = handleDecimalPoints(
                                    Number(taxAmount) +
                                      Number(values.orderItems.otherAmt) +
                                      Number(values.orderItems.taxAmt) +
                                      Number(subCost)
                                  );
                                  setFieldValue(
                                    `orderItems.shipAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                } else {
                                  setFieldValue(
                                    "orderItems.shippingCostInPercentage",
                                    true
                                  );

                                  let value = Number(
                                    values.orderItems.shippingCost
                                  );

                                  let totalCost = values.orderItems.subTotal;
                                  let taxAmount = handleDecimalPoints(
                                    totalCost * (value / 100)
                                  );
                                  let finalCost =
                                    Number(values.orderItems.taxAmt) +
                                    Number(values.orderItems.otherAmt) +
                                    Number(taxAmount) +
                                    Number(totalCost);

                                  // Calculate the total cost including tax
                                  let totalCostData = handleDecimalPoints(
                                    Number(finalCost)
                                  );

                                  setFieldValue(
                                    `orderItems.shipAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                }
                              }}
                            >
                              {values.orderItems.shippingCostInPercentage ? (
                                <PercentageBlueIcon />
                              ) : (
                                <PercentageLightIcon />
                              )}
                            </span>
                            <span
                              onClick={() => {
                                if (
                                  values.orderItems.shippingCostInPercentage ==
                                  true
                                ) {
                                  setFieldValue(
                                    "orderItems.shippingCostInPercentage",
                                    false
                                  );

                                  let value = values.orderItems.shippingCost;

                                  let taxAmount = handleDecimalPoints(
                                    Number(value)
                                  );
                                  let subCost = Number(
                                    values.orderItems.subTotal
                                  );

                                  let totalCostData = handleDecimalPoints(
                                    Number(taxAmount) +
                                      Number(values.orderItems.otherAmt) +
                                      Number(values.orderItems.taxAmt) +
                                      Number(subCost)
                                  );

                                  setFieldValue(
                                    `orderItems.shipAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                } else {
                                  setFieldValue(
                                    "orderItems.shippingCostInPercentage",
                                    true
                                  );

                                  let value = Number(
                                    values.orderItems.shippingCost
                                  );

                                  let totalCost = values.orderItems.subTotal;
                                  let taxAmount = handleDecimalPoints(
                                    totalCost * (value / 100)
                                  );

                                  let finalCost =
                                    Number(values.orderItems.taxAmt) +
                                    Number(values.orderItems.otherAmt) +
                                    Number(taxAmount) +
                                    Number(totalCost);

                                  // Calculate the total cost including tax
                                  let totalCostData = handleDecimalPoints(
                                    Number(finalCost)
                                  );

                                  setFieldValue(
                                    `orderItems.shipAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                }
                              }}
                              className="c-pointer ml-2 mr-2"
                            >
                              {" "}
                              {values.orderItems.shippingCostInPercentage ? (
                                <AmountLightIcon />
                              ) : (
                                <AmountBlueIcon />
                              )}
                            </span>
                            <span className="ml-2 mr-2 fix-width-label">
                              {" "}
                              {t.po.form.shipping_cost}{" "}
                            </span>
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "baseline",
                              }}
                            >
                              <span style={{ width: "67px" }}>
                                <Input
                                  style={{ width: "67px" }}
                                  // defaultValue="1"
                                  autoComplete="new-password"
                                  type="number"
                                  onKeyDown={(evt) => handleKeyDown(evt)}
                                  onWheel={(e) => e.target.blur()}
                                  value={values?.orderItems?.shippingCost || ""}
                                  onChange={(e) => {
                                    if (Number(e.target.value)) {
                                      let value = handleDecimalPoints(
                                        e.target.value
                                      );
                                      if (
                                        values.orderItems
                                          .shippingCostInPercentage == true
                                      ) {
                                        let totalCost =
                                          values.orderItems.subTotal;
                                        let taxAmount = handleDecimalPoints(
                                          totalCost * (value / 100)
                                        );

                                        let finalCost =
                                          Number(values.orderItems.taxAmt) +
                                          Number(values.orderItems.otherCost) +
                                          Number(taxAmount) +
                                          Number(totalCost);

                                        // Calculate the total cost including tax
                                        let totalCostData = handleDecimalPoints(
                                          Number(finalCost)
                                        );

                                        setFieldValue(
                                          `orderItems.shippingCost`,
                                          Number(value)
                                        );
                                        setFieldValue(
                                          `orderItems.shipAmt`,
                                          Number(taxAmount),
                                          false
                                        );
                                        setFieldValue(
                                          `orderItems.totalCost`,
                                          Number(totalCostData),
                                          false
                                        );
                                      } else {
                                        let taxAmount = handleDecimalPoints(
                                          Number(e.target.value)
                                        );

                                        let subCost = Number(
                                          values.orderItems.subTotal
                                        );

                                        let totalCostData = handleDecimalPoints(
                                          Number(taxAmount) +
                                            Number(values.orderItems.taxAmt) +
                                            Number(values.orderItems.otherAmt) +
                                            Number(subCost)
                                        );

                                        setFieldValue(
                                          `orderItems.shippingCost`,
                                          Number(value)
                                        );

                                        setFieldValue(
                                          `orderItems.shipAmt`,
                                          Number(taxAmount)
                                        );

                                        setFieldValue(
                                          `orderItems.totalCost`,
                                          Number(totalCostData),
                                          false
                                        );
                                      }
                                    } else {
                                      setFieldValue(
                                        `orderItems.shippingCost`,
                                        null
                                      );
                                      setFieldValue(
                                        `orderItems.shipAmt`,
                                        null,
                                        false
                                      );

                                      let subCost = values.orderItems.subTotal;
                                      let shippingCost =
                                        values.orderItems.taxAmt;
                                      let otherCost =
                                        values.orderItems.otherAmt;

                                      let finalCost =
                                        Number(subCost) +
                                        Number(shippingCost) +
                                        Number(otherCost);
                                      setFieldValue(
                                        `orderItems.totalCost`,
                                        handleDecimalPoints(finalCost),
                                        false
                                      );
                                    }
                                  }}

                                  // onChange={(e, value) => {
                                  //   handleQtyChange(
                                  //     row,
                                  //     e.target.value,
                                  //     setFieldValue,
                                  //     values.orderItems.items
                                  //   );
                                  // }}
                                  // error={
                                  //   errors?.orderItems?.items?.length > 0 &&
                                  //   errors?.orderItems?.items[index]?.quantity &&
                                  //   touched?.orderItems?.items[index]?.quantity
                                  // }
                                  // onFocus={(e) => {
                                  //   setFieldTouched(`orderItems.items[${index}].quantity`, true);
                                  // }}
                                >
                                  <input />

                                  {/* {errors?.orderItems?.items?.length > 0 &&
                            errors?.orderItems?.items[index]?.quantity &&
                            touched?.orderItems?.items[index]?.quantity ? (
                              <span className="label-error">
                                {errors?.orderItems?.items[index]?.quantity}
                              </span>
                            ) : null} */}
                                </Input>{" "}
                                {errors?.orderItems?.shippingCost ? (
                                  <span className="label-error d-block">
                                    {errors?.orderItems?.shippingCost}
                                  </span>
                                ) : null}
                              </span>
                              <span
                                className={` align-items-center justify-content-center ${
                                  selectedLanguage == "en" ? "ml-2" : "mr-2"
                                }`}
                                style={{
                                  backgroundColor: "#E9F2FF",
                                  color: "#0C66E4",
                                  padding: "5px",
                                  fontSize: "14px",
                                  width: "37px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              >
                                {values.orderItems.shippingCostInPercentage
                                  ? "%"
                                  : organisationCurrency}
                              </span>
                            </span>
                            <span className="span-number-per">
                              {values.orderItems.shippingCostInPercentage ==
                                true && values.orderItems.shipAmt ? (
                                <>
                                  {handleDecimalPoints(
                                    values.orderItems.shipAmt
                                  )}
                                </>
                              ) : null}
                            </span>
                          </div>

                          <div className="set-position-of-percentage">
                            {" "}
                            <span
                              className="c-pointer"
                              onClick={() => {
                                if (
                                  values.orderItems.otherCostInPercentage ==
                                  true
                                ) {
                                  setFieldValue(
                                    "orderItems.otherCostInPercentage",
                                    false
                                  );

                                  let value = values.orderItems.otherCost;

                                  let taxAmount = handleDecimalPoints(
                                    Number(value)
                                  );

                                  let subCost = Number(
                                    values.orderItems.subTotal
                                  );

                                  let totalCostData = handleDecimalPoints(
                                    Number(taxAmount) +
                                      Number(values.orderItems.taxAmt) +
                                      Number(values.orderItems.shipAmt) +
                                      Number(subCost)
                                  );

                                  setFieldValue(
                                    `orderItems.otherAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                } else {
                                  setFieldValue(
                                    "orderItems.otherCostInPercentage",
                                    true
                                  );

                                  let value = handleDecimalPoints(
                                    values.orderItems.otherCost
                                  );

                                  let totalCost = values.orderItems.subTotal;
                                  let taxAmount = handleDecimalPoints(
                                    totalCost * (value / 100)
                                  );

                                  let finalCost =
                                    Number(values.orderItems.shipAmt) +
                                    Number(values.orderItems.taxAmt) +
                                    Number(taxAmount) +
                                    Number(totalCost);

                                  // Calculate the total cost including tax
                                  let totalCostData = handleDecimalPoints(
                                    Number(finalCost)
                                  );

                                  setFieldValue(
                                    `orderItems.otherAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                }
                              }}
                            >
                              {values.orderItems.otherCostInPercentage ? (
                                <PercentageBlueIcon />
                              ) : (
                                <PercentageLightIcon />
                              )}
                            </span>
                            <span
                              onClick={() => {
                                if (
                                  values.orderItems.otherCostInPercentage ==
                                  true
                                ) {
                                  setFieldValue(
                                    "orderItems.otherCostInPercentage",
                                    false
                                  );

                                  let value = values?.orderItems?.otherCost;

                                  let taxAmount = handleDecimalPoints(
                                    Number(value)
                                  );

                                  let subCost = handleDecimalPoints(
                                    values.orderItems.subTotal
                                  );

                                  let totalCostData = handleDecimalPoints(
                                    Number(taxAmount) +
                                      Number(values.orderItems.taxAmt) +
                                      Number(values.orderItems.shipAmt) +
                                      Number(subCost)
                                  );

                                  setFieldValue(
                                    `orderItems.otherAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                } else {
                                  setFieldValue(
                                    "orderItems.otherCostInPercentage",
                                    true
                                  );

                                  let value = handleDecimalPoints(
                                    values.orderItems.otherCost
                                  );

                                  let totalCost = values.orderItems.subTotal;
                                  let taxAmount = handleDecimalPoints(
                                    totalCost * (value / 100)
                                  );

                                  let finalCost =
                                    Number(values.orderItems.shipAmt) +
                                    Number(values.orderItems.taxAmt) +
                                    Number(taxAmount) +
                                    Number(totalCost);

                                  // Calculate the total cost including tax
                                  let totalCostData = handleDecimalPoints(
                                    Number(finalCost)
                                  );

                                  setFieldValue(
                                    `orderItems.otherAmt`,
                                    Number(taxAmount),
                                    false
                                  );
                                  setFieldValue(
                                    `orderItems.totalCost`,
                                    Number(totalCostData),
                                    false
                                  );
                                }
                              }}
                              className="c-pointer ml-2 mr-2"
                            >
                              {" "}
                              {values.orderItems.otherCostInPercentage ? (
                                <AmountLightIcon />
                              ) : (
                                <AmountBlueIcon />
                              )}
                            </span>
                            <span className="ml-2 mr-2 fix-width-label">
                              {" "}
                              {t.po.form.other_cost}{" "}
                            </span>
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "baseline",
                              }}
                            >
                              <span style={{ width: "67px" }}>
                                <Input
                                  style={{ width: "67px" }}
                                  // defaultValue="1"
                                  autoComplete="new-password"
                                  type="number"
                                  onKeyDown={(evt) => handleKeyDown(evt)}
                                  onWheel={(e) => e.target.blur()}
                                  value={values?.orderItems?.otherCost || ""}
                                  onChange={(e) => {
                                    if (Number(e.target.value)) {
                                      let value = handleDecimalPoints(
                                        e.target.value
                                      );
                                      if (
                                        values.orderItems
                                          .otherCostInPercentage == true
                                      ) {
                                        let totalCost =
                                          values.orderItems.subTotal;
                                        let taxAmount = handleDecimalPoints(
                                          totalCost * (value / 100)
                                        );
                                        let finalCost =
                                          Number(values.orderItems.shipAmt) +
                                          Number(values.orderItems.taxAmt) +
                                          Number(taxAmount) +
                                          Number(totalCost);

                                        // Calculate the total cost including tax
                                        let totalCostData = handleDecimalPoints(
                                          Number(finalCost)
                                        );

                                        setFieldValue(
                                          `orderItems.otherCost`,
                                          Number(value)
                                        );
                                        setFieldValue(
                                          `orderItems.otherAmt`,
                                          Number(taxAmount),
                                          false
                                        );
                                        setFieldValue(
                                          `orderItems.totalCost`,
                                          Number(totalCostData),
                                          false
                                        );
                                      } else {
                                        let value = handleDecimalPoints(
                                          e.target.value
                                        );
                                        let taxAmount = handleDecimalPoints(
                                          Number(e.target.value)
                                        );

                                        let subCost = Number(
                                          values.orderItems.subTotal
                                        );

                                        let totalCostData = handleDecimalPoints(
                                          Number(taxAmount) +
                                            Number(values.orderItems.taxAmt) +
                                            Number(values.orderItems.shipAmt) +
                                            Number(subCost)
                                        );

                                        setFieldValue(
                                          `orderItems.otherCost`,
                                          Number(value)
                                        );

                                        setFieldValue(
                                          `orderItems.otherAmt`,
                                          Number(taxAmount),
                                          false
                                        );
                                        setFieldValue(
                                          `orderItems.totalCost`,
                                          Number(totalCostData),
                                          false
                                        );
                                      }
                                    } else {
                                      setFieldValue(
                                        `orderItems.otherCost`,
                                        null
                                      );
                                      setFieldValue(
                                        `orderItems.otherAmt`,
                                        null,
                                        false
                                      );

                                      let subCost = values.orderItems.subTotal;
                                      let shippingCost =
                                        values.orderItems.shipAmt;
                                      let otherCost = values.orderItems.taxAmt;

                                      let finalCost =
                                        Number(subCost) +
                                        Number(shippingCost) +
                                        Number(otherCost);
                                      setFieldValue(
                                        `orderItems.totalCost`,
                                        handleDecimalPoints(finalCost),
                                        false
                                      );
                                    }
                                  }}
                                >
                                  <input />

                                  {/* {errors?.orderItems?.items?.length > 0 &&
                            errors?.orderItems?.items[index]?.quantity &&
                            touched?.orderItems?.items[index]?.quantity ? (
                              <span className="label-error">
                                {errors?.orderItems?.items[index]?.quantity}
                              </span>
                            ) : null} */}
                                </Input>{" "}
                                {errors?.orderItems?.otherCost ? (
                                  <span className="label-error">
                                    {errors?.orderItems?.otherCost}
                                  </span>
                                ) : null}
                              </span>
                              <span
                                className={` align-items-center justify-content-center ${
                                  selectedLanguage == "en" ? "ml-2" : "mr-2"
                                }`}
                                style={{
                                  backgroundColor: "#E9F2FF",
                                  color: "#0C66E4",
                                  padding: "5px",
                                  fontSize: "14px",
                                  width: "37px",
                                  textAlign: "center",
                                  display: "inline-block",
                                }}
                              >
                                {values.orderItems.otherCostInPercentage
                                  ? "%"
                                  : organisationCurrency}
                              </span>
                            </span>
                            <span className="span-number-per">
                              {values.orderItems.otherCostInPercentage ==
                                true && values.orderItems.otherAmt ? (
                                <>
                                  {handleDecimalPoints(
                                    values.orderItems.otherAmt
                                  )}
                                </>
                              ) : null}
                            </span>
                          </div>
                          <div className="change-for-job-btn">
                            <div className="new-grey-btn width-content my-3">
                              {t.parts.form.total_cost} &nbsp; &nbsp; &nbsp;
                              {handleDecimalPoints(
                                values.orderItems.totalCost
                              )}{" "}
                              &nbsp; {organisationCurrency}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <Divider />

                    <div
                      className={`billing-info billing-info2 mt-5 ${
                        selectedLanguage == "en" ? "" : ""
                      }`}
                    >
                      <HomeIcon />
                      <span
                        style={{ fontSize: "16px" }}
                        className={` required-text ${
                          selectedLanguage == "en" ? "ml-2" : "mr-2"
                        }`}
                      >
                        {t.po.form.shipping_address}
                      </span>
                    </div>

                    <Form.Field>
                      <label className="label-item mt-5">
                        {" "}
                        {t.po.form.location_name} <Asterisk color="#172B4D" />
                      </label>
                      <Dropdown
                        placeholder={t.po.form.add_location_name}
                        noResultsMessage={t.common.no_results_found}
                        fluid
                        clearable
                        className="loc-dropdown"
                        search
                        selection
                        options={options.locations}
                        error={
                          errors?.shippingAddress?.companyName &&
                          touched?.shippingAddress?.companyName
                        }
                        value={values.shippingAddress.companyName}
                        onChange={(e, value) => {
                          setFieldValue(
                            "shippingAddress.companyName",
                            value.value
                          );
                          // setFieldValue('shippingAddress.area', '', false);

                          // setFieldValue('details.people', '', false);
                          // setFieldTouched('details.people', false);

                          let address = options?.locations?.find(
                            (el) => el.value === value.value
                          );

                          setFieldValue(
                            "shippingAddress.address",
                            address?.address,
                            false
                          );
                          setFieldValue(
                            "shippingAddress.city",
                            address?.city,
                            false
                          );
                          // fetchArea(value.value);
                        }}
                        onBlur={(e) => {
                          setFieldTouched("shippingAddress.companyName", true);
                        }}
                        // onOpen={() => fetchLocations()}
                      />
                      {errors?.shippingAddress?.companyName &&
                      touched?.shippingAddress?.companyName ? (
                        <div className="label-error">
                          {errors?.shippingAddress?.companyName}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Field>

                    {/* <Form.Field>
                    <label className="label-item">
                      {' '}
                      {t.parts.filter.area} <Asterisk color="#172B4D" />
                    </label>
                    <Dropdown
                      placeholder={t.parts.form.add_area}
                      noResultsMessage={t.common.no_results_found}
                      fluid
                      search
                      selection
                      disabled={!values.shippingAddress.companyName}
                      options={options.area}
                      error={
                        errors?.shippingAddress?.companyName &&
                        touched?.shippingAddress?.companyName
                      }
                      value={values.shippingAddress.area}
                      onChange={(e, value) =>
                        setFieldValue('shippingAddress.area', value.value)
                      }
                      onBlur={(e) => {
                        setFieldTouched('shippingAddress.area', true);
                      }}
                      // onOpen={() => fetchArea()}
                    />
                    {errors?.shippingAddress?.area &&
                    touched?.shippingAddress?.area ? (
                      <div className="label-error">
                        {errors?.shippingAddress?.area}
                      </div>
                    ) : (
                      ''
                    )}
                  </Form.Field> */}

                    <Form.Field>
                      <label className="label-item">
                        {t.po.form.contact_number}
                      </label>

                      <PhoneInput
                        defaultCountry="jo"
                        // forceDialCode={true}
                        value={values.shippingAddress.contactPhoneNumber || ""}
                        placeholder={t.people.personal_info_form.add_phone}
                        onChange={(value) => {
                          if (!emergencyPhone) {
                            setEmergencyPhone(true);
                            return;
                          }

                          if (value) {
                            setFieldValue(
                              "shippingAddress.contactPhoneNumber",
                              value
                            );
                          } else {
                            setFieldValue(
                              "shippingAddress.contactPhoneNumber",
                              null
                            );
                          }
                        }}
                        onBlur={(e) =>
                          setFieldTouched(
                            "shippingAddress.contactPhoneNumber",
                            true
                          )
                        }
                        // onBlur={(e) => setFieldTouched('emergencyPhone', false)}
                        error={
                          errors?.shippingAddress?.contactPhoneNumber &&
                          touched?.shippingAddress?.contactPhoneNumber
                        }
                      />
                      {errors?.shippingAddress?.contactPhoneNumber &&
                      touched?.shippingAddress?.contactPhoneNumber ? (
                        <div className="label-error">
                          {errors?.shippingAddress?.contactPhoneNumber}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <Input
                        autoComplete="new-password"
                        label={
                          <Dropdown
                            button
                            basic
                            search
                            defaultValue="962"
                            options={country_codes}
                            onChange={(e, value) =>
                              setFieldValue('emergencyCountryCode', value.value)
                            }
                          />
                        }
                        type="number"
                        fluid
                        placeholder="Emergency Phone"
                        value={values.emergencyPhone}
                        onChange={(e) =>
                          setFieldValue('emergencyPhone', e.target.value)
                        }
                      /> */}
                    </Form.Field>

                    <Form.Field>
                      <label className="label-item">
                        {t.people.personal_info_form.address}
                      </label>
                      <Input
                        autoComplete="new-password"
                        fluid
                        type="text"
                        placeholder={t.people.personal_info_form.add_address}
                        value={values.shippingAddress.address}
                        onChange={(e) => {
                          if (e.target.value) {
                            setFieldValue(
                              "shippingAddress.address",
                              e.target.value
                            );
                          } else if (!e.target.value) {
                            setFieldValue("shippingAddress.address", "");
                          }
                        }}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label className="label-item">
                        {t.locations.form.city}
                      </label>
                      <Input
                        autoComplete="new-password"
                        fluid
                        type="text"
                        placeholder={t.locations.form.city_add}
                        value={values.shippingAddress.city}
                        onChange={(e) => {
                          if (e.target.value) {
                            setFieldValue(
                              "shippingAddress.city",
                              e.target.value
                            );
                          } else if (!e.target.value) {
                            setFieldValue("shippingAddress.city", "");
                          }
                        }}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label className="label-item">{t.po.form.state}</label>
                      <Input
                        autoComplete="new-password"
                        fluid
                        type="text"
                        placeholder={t.po.form.add_state}
                        value={values.shippingAddress.state}
                        onChange={(e) => {
                          if (e.target.value) {
                            setFieldValue(
                              "shippingAddress.state",
                              e.target.value
                            );
                          } else if (!e.target.value) {
                            setFieldValue("shippingAddress.state", "");
                          }
                        }}
                      />
                    </Form.Field>

                    <label className="label-item">{t.po.form.zip_code}</label>
                    <Input
                      autoComplete="new-password"
                      fluid
                      type="number"
                      placeholder={t.po.form.add_zip_code}
                      value={values.shippingAddress.zipcode}
                      onChange={(e) => {
                        if (e.target.value) {
                          setFieldValue(
                            "shippingAddress.zipcode",
                            e.target.value
                          );
                        } else if (!e.target.value) {
                          setFieldValue("shippingAddress.zipcode", "");
                        }
                      }}
                    />

                    {errors?.shippingAddress?.zipcode &&
                    touched?.shippingAddress?.zipcode ? (
                      <div className="label-error">
                        {errors?.shippingAddress?.zipcode}
                      </div>
                    ) : (
                      ""
                    )}

                    <Divider className="mt-7" />
                    <div className="change-radio-icon-color">
                      <div
                        className={`billing-info billing-info2 mt-5 ${
                          selectedLanguage == "en" ? "" : ""
                        }`}
                      >
                        <HomeIcon />
                        <span
                          style={{ fontSize: "16px" }}
                          className={` required-text ${
                            selectedLanguage == "en" ? "ml-2" : "mr-2"
                          }`}
                        >
                          {t.po.form.billing_address}
                        </span>
                      </div>

                      <div className="d-flex mt-5 ml-2 mr-2">
                        <Radio
                          // label="This work order "
                          name="radioGroup"
                          className="po-radio"
                          value={values.billingAddress.sameAsShippingAddress}
                          checked={values.billingAddress.sameAsShippingAddress}
                          onChange={() => {
                            if (
                              values.billingAddress.sameAsShippingAddress ==
                              true
                            ) {
                              setFieldValue(
                                "billingAddress.sameAsShippingAddress",
                                false
                              );
                              setFieldTouched(
                                "billingAddress.contactPhoneNumber",
                                false
                              );
                            } else {
                              setFieldValue(
                                "billingAddress.sameAsShippingAddress",
                                true
                              );
                              setFieldValue(
                                "billingAddress.companyName",
                                "",
                                false
                              );
                              setFieldValue(
                                "billingAddress.address",
                                "",
                                false
                              );
                              setFieldValue(
                                "billingAddress.contactPhoneNumber",
                                null,
                                false
                              );
                              setFieldTouched(
                                "billingAddress.contactPhoneNumber",
                                false
                              );
                            }
                          }}
                        />
                        <label className="radio-text ml-3 mr-3">
                          {" "}
                          {t.po.form.use_same}
                        </label>
                      </div>

                      <div className="d-flex mt-3 ml-2 mr-2">
                        <Radio
                          className="po-radio"
                          name="radioGroup"
                          value={!values.billingAddress.sameAsShippingAddress}
                          checked={!values.billingAddress.sameAsShippingAddress}
                          onChange={() => {
                            if (
                              values.billingAddress.sameAsShippingAddress ==
                              true
                            ) {
                              setFieldValue(
                                "billingAddress.sameAsShippingAddress",
                                false
                              );
                              setFieldValue(
                                "billingAddress.contactPhoneNumber",
                                null,
                                true
                              );
                            } else {
                              setFieldValue(
                                "billingAddress.sameAsShippingAddress",
                                true
                              );
                            }
                          }}
                        />
                        <label className="radio-text ml-3 mr-3">
                          {" "}
                          {t.po.form.use_different}
                        </label>
                      </div>

                      {!values.billingAddress.sameAsShippingAddress ? (
                        <>
                          <Form.Field>
                            <label className="label-item mt-5">
                              {t.po.form.company_name}
                            </label>
                            <Input
                              autoComplete="new-password"
                              fluid
                              type="text"
                              placeholder={t.po.form.add_company_name}
                              value={values.billingAddress.companyName}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setFieldValue(
                                    "billingAddress.companyName",
                                    e.target.value
                                  );
                                } else if (!e.target.value) {
                                  setFieldValue(
                                    "billingAddress.companyName",
                                    ""
                                  );
                                }
                              }}
                            />
                          </Form.Field>
                          <Form.Field>
                            <label className="label-item">
                              {t.people.personal_info_form.address}
                            </label>
                            <Input
                              autoComplete="new-password"
                              fluid
                              type="text"
                              placeholder={
                                t.people.personal_info_form.add_address
                              }
                              value={values.billingAddress.address}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setFieldValue(
                                    "billingAddress.address",
                                    e.target.value
                                  );
                                } else if (!e.target.value) {
                                  setFieldValue("billingAddress.address", "");
                                }
                              }}
                            />
                          </Form.Field>

                          <Form.Field>
                            <label className="label-item">
                              {t.po.form.contact_number}
                            </label>

                            <PhoneInput
                              defaultCountry="jo"
                              // forceDialCode={true}
                              value={
                                values.billingAddress.contactPhoneNumber || ""
                              }
                              placeholder={
                                t.people.personal_info_form.add_phone
                              }
                              onChange={(value) => {
                                if (value) {
                                  setFieldValue(
                                    "billingAddress.contactPhoneNumber",
                                    value
                                  );
                                } else {
                                  setFieldValue(
                                    "billingAddress.contactPhoneNumber",
                                    null
                                  );
                                }
                              }}
                              onBlur={(e) =>
                                setFieldTouched(
                                  "billingAddress.contactPhoneNumber",
                                  true
                                )
                              }
                              // onBlur={(e) => setFieldTouched('emergencyPhone', false)}
                              error={
                                errors?.billingAddress?.contactPhoneNumber &&
                                touched?.billingAddress?.contactPhoneNumber
                              }
                            />
                            {errors?.billingAddress?.contactPhoneNumber &&
                            touched?.billingAddress?.contactPhoneNumber ? (
                              <div className="label-error">
                                {errors?.billingAddress?.contactPhoneNumber}
                              </div>
                            ) : (
                              ""
                            )}
                          </Form.Field>
                        </>
                      ) : null}

                      <Form.Field className="">
                        <FileUpload
                          onCertificateFileChange={onCertificateFileChange}
                          name="warrantyFile"
                          attachments={attachments}
                          setAttachments={setAttachments}
                        />
                      </Form.Field>

                      {/* Location  */}

                      <div className="form-button-container mt-10">
                        <button
                          type="button"
                          onClick={() =>
                            initChange
                              ? setNewCancelModal(true)
                              : cancelHandler()
                          }
                          className="form-action-button"
                        >
                          {t.assets.form.cancel}
                        </button>

                        <button
                          type="submit"
                          // content={t.procedures.form.submit.add}
                          // primary
                          onClick={() => {
                            isSubmitClicked.current = true; // Set ref to true on submit button click
                          }}  
                          // onClick={createNewTeamHandler}
                          // loading={buttonStatus == 1}
                          className="form-action-button blue"
                          style={{ minWidth: "68px" }}
                        >
                          {buttonStatus == 1 ? (
                            <Spinner color="light" size="sm">
                              <div>{null}</div>
                            </Spinner>
                          ) : edit ? (
                            t.common.edit
                          ) : (
                            t.common.add
                          )}
                        </button>
                      </div>
                    </div>
                    {/* Add parts and add sets modal */}
                    {toggleAsideModal && (
                      <Add
                        toggled={toggleAsideModal}
                        untoggle={() => {
                          setToggleAsideModal(false);
                        }}
                        role={role}
                        allIds={allIds}
                        fromPO={true}
                        handleSuccess12={(res) => {
                          let existPartData = values?.orderItems?.items
                            ? values?.orderItems?.items
                            : [];
                          res?.data?.attributes?.inventory?.length > 0 &&
                            res.data.attributes.inventory.forEach((inv) => {
                              console.log("inv: ", inv);
                              let existItem = {
                                id: `${res?.data?.id}-${inv?.location?.id}`,
                                // disable:false,
                                part_id: res?.data?.id,
                                countId: res?.data?.attributes?.countId,
                                name: res?.data?.attributes?.name,
                                location: inv?.location?.name,
                                locationId: inv?.location?.id,
                                availableQty: inv?.availableQuantity
                                  ? inv.availableQuantity
                                  : 0,
                                orderedQuantity: inv?.orderedQuantity
                                  ? inv?.orderedQuantity
                                  : 0,
                                quantity: 1,
                                fillMax: false,
                                selectedPart: true,
                                totalCost:
                                  inv?.unitCost !== null ? inv?.unitCost : 0,
                                unitCost:
                                  inv?.unitCost !== null ? inv?.unitCost : 0,
                                unitType: res?.data?.attributes?.measuringUnit,
                              };

                              existPartData.push(existItem);
                            });

                          setSelectedTablePartData(existPartData);
                          // selectedPart(existPartData)

                          setFieldValue("orderItems.items", existPartData);
                          // let selectedPart = {
                          //   id: res?.data?.id,
                          //   availableQty: res?.data?.attributes
                          //     ?.totalAvailableQuantity
                          //     ? res?.data?.attributes?.totalAvailableQuantity
                          //     : 0,
                          //   quantity: 1,
                          //   fillMax: false,
                          //   name: res?.data?.attributes?.name,
                          //   orderedQuantity: res?.data?.attributes
                          //     ?.totalOrderedQuantity
                          //     ? res?.data?.attributes?.totalOrderedQuantity
                          //     : 0,
                          //   selectedPart: true,
                          //   totalCost: res?.data?.attributes?.unitCost,
                          //   unitCost: res?.data?.attributes?.unitCost,
                          //   unitType: res?.data?.attributes?.measuringUnit,
                          // };

                          let subTotalCost = 0;
                          existPartData.forEach((row) => {
                            subTotalCost += Number(row.totalCost);
                          });

                          let texValue;
                          let shipValue;
                          let otherValue;

                          if (values.orderItems.taxesInPercentage) {
                            texValue = Number(
                              parseFloat(
                                subTotalCost *
                                  (Number(values.orderItems.taxes) / 100)
                              ).toFixed(4)
                            );
                            setFieldValue("orderItems.taxAmt", texValue, false);
                          } else {
                            texValue = values?.orderItems?.taxAmt || 0;
                          }

                          if (values.orderItems.shippingCostInPercentage) {
                            shipValue = Number(
                              parseFloat(
                                subTotalCost *
                                  (Number(values.orderItems.shippingCost) / 100)
                              ).toFixed(4)
                            );
                            setFieldValue(
                              "orderItems.shipAmt",
                              shipValue,
                              false
                            );
                          } else {
                            shipValue = values?.orderItems?.shipAmt || 0;
                          }

                          if (values.orderItems.otherCostInPercentage) {
                            otherValue = Number(
                              parseFloat(
                                subTotalCost *
                                  (Number(values.orderItems.otherCost) / 100)
                              ).toFixed(4)
                            );
                            setFieldValue(
                              "orderItems.otherAmt",
                              otherValue,
                              false
                            );
                          } else {
                            otherValue = values?.orderItems?.otherAmt || 0;
                          }

                          let finalCost =
                            Number(texValue) +
                            Number(shipValue) +
                            Number(otherValue) +
                            Number(parseFloat(Number(subTotalCost)).toFixed(4));

                          setFieldValue(
                            "orderItems.subTotal",
                            Number(
                              parseFloat(Number(subTotalCost)).toFixed(4),
                              false
                            ),
                            false
                          );

                          setFieldValue(
                            "orderItems.totalCost",
                            Number(
                              parseFloat(Number(finalCost)).toFixed(4),
                              false
                            )
                          );

                          addParts(existPartData, true);
                        }}
                      />
                    )}
                    <Modal
                      size="large"
                      open={addPartModal}
                      className="assets-modal new-purchase-order-modal"
                      style={{ height: "730px" }}
                      onClose={() => {
                        handleClosePartModal(setFieldValue, values);
                      }}
                    >
                      <div className="header-container">
                        <span className="assets-header">
                          {t.sets.form.add_parts}
                        </span>
                        <div>
                          <span
                            className="c-pointer text-right"
                            onClick={() => {
                              handleClosePartModal(setFieldValue, values);
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      </div>

                      <Modal.Content className="mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="set-input">
                            <span className="search-svg-set">
                              <SearchIcon />
                            </span>
                            <Input
                              style={{ width: "470px" }}
                              placeholder={t.sets.form.search_part}
                              value={searchTitle}
                              onChange={(e) => setSearchTitle(e.target.value)}
                            />
                          </div>
                          <span className="new-grey-btn">
                            {t.po.form.subTotal} &nbsp; &nbsp; &nbsp;
                            {values?.orderItems?.subTotal
                              ? values?.orderItems?.subTotal
                              : 0}{" "}
                            {organisationCurrency}s
                          </span>
                        </div>

                        {loading ? (
                          <Dimmer active inverted>
                            <Loader inverted content={t.common.loading} />
                          </Dimmer>
                        ) : (
                          <>
                            <div className=" mt-5 bulk-upload-table">
                              <DataTable
                                className="wo-table-div"
                                fixedHeader
                                columns={[
                                  {
                                    width: "20px",
                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <Checkbox
                                            //  disabled={row?.disable}
                                            className="set-checkbox-gery"
                                            // value={row?.selectedPart}
                                            checked={row?.selectedPart}
                                            onChange={(e, value) => {
                                              handleRowSelected(
                                                row,
                                                value.checked,
                                                setFieldValue,
                                                selectedTablePartData,
                                                values
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ),
                                  },
                                  {
                                    minWidth: "90px",
                                    name: t.po.form.part_id,

                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <span className="id-text-style">
                                            {row?.countId ? row?.countId : ""}
                                          </span>
                                        </div>
                                      </div>
                                    ),
                                  },

                                  {
                                    minWidth: "270px",
                                    name: t.po.form.part_name,

                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <span className="id-text-style">
                                            {row?.name ? row?.name : ""}
                                          </span>
                                        </div>
                                      </div>
                                    ),
                                  },

                                  {
                                    minWidth: "150px",
                                    name: t.parts.form.inv_loc,

                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <span className="id-text-style">
                                            {row?.location ? row?.location : ""}
                                          </span>
                                        </div>
                                      </div>
                                    ),
                                  },
                                  {
                                    minWidth: "109px",
                                    name: t.parts.form.unit_cost,
                                    right: true,
                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            style={{
                                              textAlign: "end",
                                              minWidth: "65px", // Set a fixed width based on the longest expected value
                                              // Align text to the right within the fixed space
                                              display: "inline-block",
                                            }}
                                          >
                                            {row?.unitCost ? row?.unitCost : 0}
                                          </span>
                                          <span
                                            className={` align-items-center justify-content-center ${
                                              selectedLanguage == "en"
                                                ? "ml-2"
                                                : "mr-2"
                                            }`}
                                            style={{
                                              backgroundColor: "#E9F2FF",
                                              color: "#0C66E4",
                                              minWidth: "33px",
                                              padding: "9px 3px 9px 2px",
                                              fontSize: "14px",
                                              display: "inline-block",
                                            }}
                                          >
                                            {organisationCurrency}
                                          </span>
                                        </div>
                                      </div>
                                    ),
                                  },

                                  {
                                    minWidth: "112px",
                                    name: t.po.form.qty_to_order,

                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <Input
                                          style={{ width: "90px" }}
                                          // defaultValue="1"
                                          autoComplete="new-password"
                                          type="number"
                                          onKeyDown={(evt) =>
                                            handleKeyDown(evt)
                                          }
                                          onWheel={(e) => e.target.blur()}
                                          value={row?.quantity}
                                          // onChange={(e) => {

                                          //   if (
                                          //    Number(e.target.value)
                                          //   ) {
                                          //     console.log('e.target.value: ', e.target.value);
                                          //     setFieldValue(
                                          //       `orderItems.items[${index}].quantity`,
                                          //       Number(e.target.value)
                                          //     );
                                          //     setFieldValue(
                                          //       `orderItems.items[${index}].totalCost`,
                                          //       Number(e.target.value * part.unitCost),
                                          //       false
                                          //     );
                                          //   } else {
                                          //     setFieldValue(`orderItems.items[${index}].quantity`, '');
                                          //     setFieldValue(
                                          //       `orderItems.items[${index}].totalCost`,
                                          //       part.unitCost
                                          //     );
                                          //   }
                                          // }}

                                          onChange={(e, value) => {
                                            handleQtyChange(
                                              values,
                                              row,
                                              e.target.value,
                                              setFieldValue,
                                              selectedTablePartData
                                            );
                                          }}
                                          // error={
                                          //   errors?.orderItems?.items?.length > 0 &&
                                          //   errors?.orderItems?.items[index]?.quantity &&
                                          //   touched?.orderItems?.items[index]?.quantity
                                          // }
                                          // onFocus={(e) => {
                                          //   setFieldTouched(`orderItems.items[${index}].quantity`, true);
                                          // }}
                                        >
                                          <input />
                                          {/* {errors?.orderItems?.items?.length > 0 &&
                            errors?.orderItems?.items[index]?.quantity &&
                            touched?.orderItems?.items[index]?.quantity ? (
                              <span className="label-error">
                                {errors?.orderItems?.items[index]?.quantity}
                              </span>
                            ) : null} */}
                                        </Input>
                                      </div>
                                    ),
                                  },
                                  {
                                    minWidth: "110px",
                                    name: t.po.table.total_cost,
                                    right: true,
                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            style={{
                                              textAlign: "end",
                                              minWidth: "65px", // Set a fixed width based on the longest expected value
                                              // Align text to the right within the fixed space
                                              display: "inline-block",
                                            }}
                                          >
                                             {row?.totalCost
                                              ? DisplayFormattedText(String(row?.totalCost),6) 
                                              : 0}
                                          </span>
                                          <span
                                            className={` align-items-center justify-content-center ${
                                              selectedLanguage == "en"
                                                ? "ml-2"
                                                : "mr-2"
                                            }`}
                                            style={{
                                              backgroundColor: "#E9F2FF",
                                              color: "#0C66E4",
                                              minWidth: "33px",
                                              padding: "9px 3px 9px 2px",
                                              fontSize: "14px",
                                              display: "inline-block",
                                            }}
                                          >
                                            {organisationCurrency}
                                          </span>
                                        </div>
                                      </div>
                                    ),
                                  },
                                  {
                                    minWidth: "135px",
                                    name: t.parts.form.avl_qty,
                                    right: true,
                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <>
                                            <div className=" d-flex align-items-center">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    textAlign: "end",
                                                    minWidth: "65px", // Set a fixed width based on the longest expected value
                                                    // Align text to the right within the fixed space
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  {row?.availableQty
                                                    ? row?.availableQty
                                                    : 0}
                                                </span>
                                                <span
                                                  className={`align-items-center justify-content-center ${
                                                    selectedLanguage === "en"
                                                      ? "ml-2"
                                                      : "mr-2"
                                                  }`}
                                                  style={{
                                                    backgroundColor: "#E9F2FF",
                                                    color: "#0C66E4",

                                                    padding: "9px 3px 9px 2px",
                                                    fontSize: "14px",
                                                    minWidth:
                                                      selectedLanguage == "en"
                                                        ? "38px"
                                                        : "55px",
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {row?.unitType
                                                    ? HandleUnitType(
                                                        row?.unitType
                                                      )
                                                    : null}
                                                </span>
                                              </div>
                                            </div>
                                          </>

                                          {/* {row?.availableQty
                                            ? row?.availableQty
                                            : <span style={{color: "#788CA5"}}>{t.parts.status.out_of_stock}</span> } */}
                                        </div>
                                      </div>
                                    ),
                                  },
                                  {
                                    minWidth: "135px",
                                    name: t.parts.form.order_qty,
                                    right: true,
                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <>
                                            <div className=" d-flex align-items-center">
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    textAlign: "end",
                                                    minWidth: "65px", // Set a fixed width based on the longest expected value
                                                    // Align text to the right within the fixed space
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  {row?.orderedQuantity
                                                    ? row?.orderedQuantity
                                                    : 0}
                                                </span>
                                                <span
                                                  className={`align-items-center justify-content-center ${
                                                    selectedLanguage === "en"
                                                      ? "ml-2"
                                                      : "mr-2"
                                                  }`}
                                                  style={{
                                                    backgroundColor: "#E9F2FF",
                                                    color: "#0C66E4",

                                                    padding: "9px 3px 9px 2px",
                                                    fontSize: "14px",
                                                    minWidth:
                                                      selectedLanguage == "en"
                                                        ? "38px"
                                                        : "55px",
                                                    display: "inline-block",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {row?.unitType
                                                    ? HandleUnitType(
                                                        row?.unitType
                                                      )
                                                    : null}
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        </div>
                                      </div>
                                    ),
                                    // cell: (row) => (
                                    //   <div className="d-flex align-items-center">
                                    //     <div>
                                    //       <span className="">
                                    //         {row?.orderedQuantity
                                    //           ? row?.orderedQuantity
                                    //           : 0}
                                    //       </span>
                                    //     </div>
                                    //   </div>
                                    // ),
                                  },
                                  {
                                    width: "160px",
                                    cell: (row) => (
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <Checkbox
                                            // value={row?.selectedPart}
                                            className="part-checkbox"
                                            checked={row?.fillMax}
                                            defaultChecked={row.fillMax}
                                            disabled={
                                              !row?.selectedPart ||
                                              !row.availableQty
                                            }
                                            onChange={(e, value) => {
                                              handleFillSelected(
                                                values,
                                                row,
                                                value.checked,
                                                setFieldValue,
                                                selectedTablePartData
                                              );
                                            }}
                                          />
                                        </div>
                                        <span className="ml-2 mr-2">
                                          {t.po.form.fill_max}
                                        </span>
                                      </div>
                                    ),
                                  },
                                ]}
                                data={getPaginatedData(partsData)}
                                customStyles={customStyles}
                                onRowClicked={(row) => {
                                  // if (!row?.disable) {
                                  handleRowSelected(
                                    row,
                                    row?.selectedPart ? false : true,
                                    setFieldValue,
                                    selectedTablePartData,
                                    values
                                  );
                                  // }
                                }}
                                // noHeader={true}
                              />
                            </div>

                            <div className="d-flex justify-content-end">
                              <Pagination
                                ofString={t.common.of}
                                total={partsData.length}
                                prevItem={
                                  selectedLanguage === "en" ? (
                                    <ArrowLeft />
                                  ) : (
                                    <ArrowRight />
                                  )
                                }
                                nextItem={
                                  selectedLanguage === "en" ? (
                                    <ArrowRight />
                                  ) : (
                                    <ArrowLeft />
                                  )
                                }
                                page={currentPage}
                                limit={pageSize}
                                handleNext={() => {
                                  setCurrentPage(currentPage + 1);
                                }}
                                handlePrevious={() => {
                                  setCurrentPage(currentPage - 1);
                                }}
                                parts={true}
                              />
                            </div>
                          </>
                        )}
                      </Modal.Content>
                      {!loading && (
                        <div
                          className={`modal-button-container ${
                            selectedLanguage == "en" ? "mr-8" : "ml-10"
                          }`}
                        >
                          <button
                            className="modal-form-action-button black"
                            onClick={() => {
                              handleClosePartModal(setFieldValue, values);
                            }}
                          >
                            {t.assets.form.cancel}
                          </button>

                          <button
                            onClick={() => handleAddPartData(setFieldValue)}
                            className="modal-form-action-button light-blue"
                          >
                            {t.common.add}
                          </button>
                        </div>
                      )}
                    </Modal>
                  </div>
                </FormikForm>
              </>
            );
          }}
        </Formik>
      )}

      <Modal
        size="tiny"
        className="assets-modal"
        open={noPartModal}
        onClose={() => {
          setNoPartModal(false);

          // setToggleStatus(true);
        }}
      >
        <div className="header-container">
          <span className="assets-header">{t.parts.create_part}</span>

          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setNoPartModal(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <Modal.Content>
          <span className="assets-label ml-1">{t.sets.no_part}</span>

          {/* {selectedPeopleWorkOrder.length ? (
                <div className="d-flex mt-7">
                  <p className="status-modal-popup-message mt-3">
                    {t.people.security_form.re_assign_to}:{' '}
                  </p>
                  <Form.Field style={{ minHeight: '60px' }}>
                    <Dropdown
                      style={{ width: '250px' }}
                      placeholder={t.people.security_form.select_one}
                      noResultsMessage={t.common.no_results_found}
                      selection
                      className="ml-4"
                      fluid
                      maxHeight={50}
                      upward
                      options={activeUsersList}
                      value={selectedUserToReAssign}
                      onChange={(e, value) =>
                        setSelectedUserToReAssign(value.value)
                      }
                    />
                  </Form.Field>
                </div>
              ) : null} */}
        </Modal.Content>

        <div
          className={`modal-button-container mt-3 ${
            selectedLanguage == "en" ? "mr-6" : "ml-10"
          }`}
        >
          <button
            onClick={() => {
              setNoPartModal(false);
            }}
            style={{ color: "black" }}
            className="modal-form-action-button black"
          >
            {t.assets.form.cancel}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default PurchaseForm;
