export default {
  title: "Dashboard",

  tabs: {
    WO_management: "Work Order Management",
    assets_management: "Asset Management",
    inventory_management: "Inventory Management",
    maintenance_management: "Maintenance Management",
    recource_planner: "Recource Planner",
    timesheet: "Time sheet",
    cost: "Cost",
  },

  filter: {
    period: "Period",
    time_period: "Time period",
    today: "Today",
    yesterday: "Yesterday",
    week: "Last week",
    month: "Last month",
    month_6: "Last 6 month",
    year: "Last year",
    date: "Select date",
    last_7: "Last 7 days",
    last_14: "Last 14 days",
    last_28: "Last 28 days",
    last_30: "Last 30 days",
    last_90: "Last 90 days",
    last_180: "Last 180 days",
    last_365: "Last 365 days",
    year_to_date: "Year to date",
    this_week: "This week",
    this_month: "This month",
    this_quarter: "This quarter",
    this_year: "This year",
    previous_week: "Previous Week",
    previous_month: "Previous Month",
    previous_quarter: "Previous Quarter",
    previous_year: "Previous Year",
  },

  box: {
    unassign: "Work Orders Unassigned",
    overDue: "Work Orders Overdue",
    ontime: "Work Orders on Time",
    increase: "increase over last month",
    decrease: "decrease over last month",
  },

  cards: {
    clear: "Clear all",
    total_wos: "Total WO",
    status: "Work Order Status",
    assignee: "Work Order by Assignee",
    total: "Total number",
    response: "Average Response Time by Priority",
    response_time_label: "Average Response Time (Hrs)",
    date: "Date",
    technician_res: "Response Time by Technician",
    technician_name: "Technician Name",
    no_of_WO: "Number of WO",
    avg_time: "Average Time",
    high: "High",
    low: "Low",
    med: "Med",
    resolution: "Average Resolution Time by Priority",
    resolution_time_label: "Average Resolution Time (Hrs)",
    resolution_technician: "Resolution Time by Technician",
    cost: "Cost Estimate Vs. Actual",
    time: "Time Estimate Vs. Actual",
    avg_time_data: "Avg. Estimate Time",
    avg_actual_time: "Avg. Actual Time",
    avg_cost: "Avg. Estimate Cost",
    avg_actual: "Avg. Actual Cost",
    days: "Days",
    cost_currency: "Cost",
    time_hrs: "Time (Hrs)",
    top10Wo: "Тop 10 Work Orders",
    category: "Category",
    received: "Received",
    comp: "Completed",
    overdueWO: "Overdue",
    utilisation_rate: "Utilization Rate",
    avail_hr: "Available hours",
    incurred_hr: "Incurred hours",
    actual_hr: "Actual Hours",
    uti: "Utilization",
    other_filters: "Other Filters",
    refresh: "Refresh",
    refreshed: "Refreshed",
  },

  dateOptions: {
    day: "Day",
    week: "Week",
    month: "Month",
    presets: "Presets",
    custom: "Custom",
  },
  percantage: "Percentage",
  number: "Number",
  expand_full: "Expand to full screen",
  add_customized: "Add to customized dashboard",

  tooltip: {
    unAssgin:
      "Percentage of work orders that are created but not yet assigned to any worker.",
    overDue:
      "Percentage of work orders that have not been completed by their due date.",
    onTime:
      "Percentage of work orders completed within the scheduled time frame.",
    WOstatus:
      "Number and percentage of workorders that are Completed: Work orders that are finished and closed.In-review: Work orders done but awaiting supervisor approval.In-progress: Work orders currently being worked on.On-hold: Work orders pending due to specific reasons.Open: Newly received work orders not yet started.",
    assignee: "Number and percentage of work orders assigned to each worker.",
    response_prioprity:
      "Average time taken to start working on a work order from the moment it is received, categorized by priority level.",
    resolution_prioprity:
      "Total time taken from the creation of a work order until its completion, categorized by priority level.",
    resolution_technician:
      "Total time taken from the creation of a work order until its completion, categorized by the technician assigned.",
    response_technician:
      "Average time taken to start working on a work order from the moment it is received, categorized by the technician assigned.",
    cost:
      "Comparison of the estimated costs for work orders versus the actual costs incurred during their resolution.",
    time:
      "Comparison of the estimated hours for work orders versus the actual hours spent on completing them.",
    top10: "Types and numbers of work orders received, completed, and overdue.",
    utilisation:
      "Percentage of an employee’s total working hours that are productively spent on work orders.",
  },

  asset_box: {
    total_asset: "Total Number of Assets",
    active: "Active",
    inActive_asset: "Inactive Assets",
    active_asset: "Active Assets",
    inActive: "Inactive",
  },

  asset_card: {
    asset_location: "Assets by Location",
    red: "Red",

    orange: "Orange",
    green: "Green",
    asset500: "> 500 Assets",
    asset250: "250-500 Assets",
    assetless250: "< 250 Assets",
    asset_category: "Assets by Category",
    asset_no: "Assets No",
    category: "Category",
    age: "Asset Age",
    number_assets: "Number of assets",
    age_yr: "Age (Years)",
    tco: "Total Cost of Ownership (TCO)",
    p_price: "Purchase Cost",
    m_price: "Maintenance Costs",
    disposal_price: "Disposal Costs",
    asset_category_label: "Asset Category",
    price: "Price",
    value: "Asset Value",
    disposal_schedule: "Disposal Schedule",
    asset_id: "Asset ID",
    asset_name: "Asset Name",
    remaining_life: "Remaining useful life",
    scheduled_date: "Scheduled Disposal Date",
    reason: "Reason for Disposal",
    status: "Status",
  },

  asset_tooltip: {
    total_asset:
      "Count of all assets registered. This includes all types of assets currently recorded in the system.",
    active_asset:
      "Count of active assets vs. inactive assets. The number changes when you move the toggle between active and inactive states.",
    category_asset:
      "Count of assets grouped by category. See the distribution of assets across different categories.",
    location_asset:
      "Count of assets grouped by location. Visualize where assets are located, helping to manage and allocate resources efficiently.",
    age_asset:
      "Count of assets grouped by age grouping, considering that the asset age starts from when the asset was placed in service.",
    disposal:
      "Number of assets scheduled for disposal.  Track upcoming disposals and manage the phasing out of assets.",
    value_asset:
      "Total purchase price of all assets registered minus depreciation. Shows the current book value of your assets.",
    TCO_asset:
      "Sum of acquisition cost, maintenance cost, and disposal cost. Gives a comprehensive view of the total expenditure on assets.",
  },

  maintenance_card: {
    total_downtime: "Total Downtime Events",
    reactive_maintenance: "Reactive Maintenance Events",
    preventive_maintenance: "Preventive Maintenance Events",
    total_cost_maintenance: "Total Cost of Maintenance",
    category_maintenance: "Maintenance Cost Per Asset Category",
    total_cost: "Total Cost",
    mtbf: "MTBF",
    mtbf_lebal: "MTBF (hours)",
    mttr: "MTTR",
    mttr_lebal: "Mean Time to Repair (hours)",
    purchase_price_title: "Maintenance Vs Purchase Price",
    purchase_price: "Purchase Price",
    maintenance_price: "Maintenance Cost",
    mttr_hrs: "MTTR (hours) ",
    mttr_time: "Mean Time",
    world_class: "World-class MTTR: ",
    hr_5: "<5 hours",
    planned_preventive: "Planned Preventive Maintenance",
    maintenance_hours: "Maintenance Hours",
    planned_maintenance_hours: "Planned Maintenance Hours",
    planned_hours: "Planned Hours",
  },
};
