import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Icon, Button, Input, Grid, Form, Divider } from 'semantic-ui-react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

import { LoadScript, GoogleMap, Polygon, Circle } from '@react-google-maps/api';
import { selectTranslations } from '../../../config/i18n/slice';

import CustomIcon from '../../../../Assets/Icons/marker.svg';

import {
  GMAPAPIKEY,
  updateMainLocation,
  updateGeofencingCoords,
} from '../../../config/functions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { LocationMapIcon } from '../../../../Assets/Icons/svg';
import { cloneDeep } from 'lodash';

function Gmap({ google, data, reload = () => {} }) {
  const t = useSelector(selectTranslations);
  const { userPermissions } = usePermissionsSimplified();

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [buttonStatus, setButtonStatus] = useState(0);
  const [radius, setRadius] = useState(1000);
  const [successButton, setSuccessButton] = useState(false);
  const [buttonGeoStatus, setGeoButtonStatus] = useState(0);
  const [successGeoButton, setSuccessGeoButton] = useState(false);
  const [editingLocationMode, setEditingLocationMode] = useState(false);
  const [editingGeoCoordsMode, setEditingGeoCoordsMode] = useState(false);
  const [editingPolyMode, setEditingPolyMode] = useState(false);
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 32.0503808,
    lng: 35.8776832,
  });

  const [defaultGeoCenter, setDefaultGeoCenter] = useState({
    lat: 32.0503808,
    lng: 35.8776832,
  });

  const [markerPosition, setMarkerPosition] = useState({
    lat: false,
    lng: false,
  });

  const [restMarkerPosition, setResetMarkerPosition] = useState({
    lat: false,
    lng: false,
  });

  // Store Polygon path in state
  const [geoActualCoords, setGeoActualCoords] = useState([]);

  const [restGeoCoords, setResetGeoCoords] = useState([]);

  useEffect(() => {
    if (data.attributes.mainLocation) {
      setMarkerPosition({
        lat: data.attributes.mainLocation.lat,
        lng: data.attributes.mainLocation.lng,
      });
      setResetMarkerPosition({
        lat: data.attributes.mainLocation.lat,
        lng: data.attributes.mainLocation.lng,
      });
    }

    if (data.attributes.geofencingCoords.length > 0) {
      setDefaultGeoCenter(data.attributes.geofencingCoords[0]);
      setGeoActualCoords(data.attributes.geofencingCoords);
      setResetGeoCoords(data.attributes.geofencingCoords);
      setRadius(data.attributes.geofencingCoords[0]?.redius || 1000);
    }
  }, [data]);

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setMarkerPosition({ lat, lng });

    setDefaultCenter({ lat, lng });
  };
  const handleChange = (name) => (event) => {
    setMarkerPosition({
      ...markerPosition,
      [name]: event.target.value,
    });
  };

  const circleRef = useRef(null);

  const onCircleEdit = useCallback(() => {
    if (circleRef.current) {
      const center = circleRef.current.getCenter();

      // Assuming you want to update state with center and radius
      setGeoActualCoords([{ lat: center.lat(), lng: center.lng() }]);
    }
  }, []);

  const handleRadiusEdit = () => {
    if (circleRef.current) {
      let radius = circleRef.current.getRadius();
      setRadius(parseInt(radius));
    }
  };

  const onCircleLoad = useCallback((circle) => {
    circleRef.current = circle;
    // Assuming you want to set initial state with center and radius
    const center = circle.getCenter();
    const radius = circleRef.current.getRadius();

    setGeoActualCoords([{ lat: center.lat(), lng: center.lng() }]);
    // Handle onLoad event if needed
  }, []);

  const onUnmount = useCallback(() => {
    circleRef.current = null;
  }, []);

  const handleAddPinLocation = () => {
    setEditingLocationMode(true);

    setMarkerPosition({
      lat: defaultCenter.lat,
      lng: defaultCenter.lng,
    });
  };
  const handleAddGeoCoords = () => {
    setEditingGeoCoordsMode(true);

    setGeoActualCoords([
      {
        lat: defaultCenter.lat + 0.002,
        lng: defaultCenter.lng,
      },
      {
        lat: defaultCenter.lat - 0.001,
        lng: defaultCenter.lng + 0.001,
      },
      {
        lat: defaultCenter.lat - 0.001,
        lng: defaultCenter.lng - 0.001,
      },
    ]);
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '250px',
  };
  const style = {
    width: '100%',
    height: '100%',
  };

  const restLocationForm = () => {
    setResetMarkerPosition(restMarkerPosition);
  };

  const resetGeoCoordsForm = () => {
    setGeoActualCoords(restGeoCoords);
    setRadius(restGeoCoords[0]?.redius || 1000);
  };

  const submitLocationMarkerHandler = async () => {
    setButtonStatus(1);
    const response = await updateMainLocation(
      data.id,
      { lat: markerPosition.lat || 0, lng: markerPosition.lng || 0 },
      organisationId
    );
    if (response.status == 200) {
      setResetMarkerPosition({
        lat: markerPosition.lat,
        lng: markerPosition.lng,
      });
      setButtonStatus(0);

      setSuccessButton(true);

      setTimeout(() => {
        setEditingLocationMode(false);
        setSuccessButton(false);
      }, 1000);
      await submitGeoCoordsHandler(true);
      setDefaultGeoCenter({
        lat: markerPosition.lat,
        lng: markerPosition.lng,
      });
      toast.success(t.locations.location_updated_success);
    }
  };

  const submitGeoCoordsHandler = async (fromLocationEdit = false) => {
    setGeoButtonStatus(1);
    let cordData = cloneDeep(
      fromLocationEdit
        ? [{ lat: markerPosition.lat, lng: markerPosition.lng }]
        : geoActualCoords
    );
    cordData[0].redius = radius;
    const response = await updateGeofencingCoords(data.id, cordData);
    if (response.status == 200) {
      setResetGeoCoords(cordData);
      setGeoActualCoords(cordData);
      setGeoButtonStatus(0);

      setSuccessGeoButton(true);

      setTimeout(() => {
        setEditingGeoCoordsMode(false);
        setSuccessGeoButton(false);
      }, 1000);
      if (!fromLocationEdit) {
        toast.success(t.locations.geo_add_success);
      }
      reload();
    }
  };

  // re-render map on editing mode
  useEffect(() => {
    if (editingLocationMode) {
      setResetMarkerPosition(defaultCenter);
      setTimeout(() => setResetMarkerPosition(restMarkerPosition), 1);
    }
  }, [editingLocationMode]);

  return (
    <div className="preview-details-wrapper">
      <div className="d-flex align-items-center  mb-3">
        <LocationMapIcon />
        <label className="workorder-intro-value ml-2 mr-0 mt-2">
          {t.locations.form.map_location}
        </label>
      </div>
      <div className="item-value">
        {restMarkerPosition.lat || editingLocationMode ? (
          <>
            <Form.Field>
              <label className="label-item">{t.locations.latitude}</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.locations.latitude_add}
                type="number"
                value={markerPosition.lat}
                disabled={!editingLocationMode}
                onChange={handleChange('lat')}
              />
            </Form.Field>
            <Form.Field>
              <label className="label-item">{t.locations.longitude}</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.locations.longitude_add}
                type="number"
                value={markerPosition.lng}
                disabled={!editingLocationMode}
                onChange={handleChange('lng')}
              />
            </Form.Field>

            {userPermissions?.location?.edit ? (
              <div className="map-button-container mb-5">
                {!editingLocationMode ? (
                  <button
                    className="map-modal-form-action-button blue"
                    onClick={() => {
                      setEditingLocationMode(true);
                    }}
                  >
                    {t.common.edit}
                  </button>
                ) : (
                  <>
                    <button
                      className="map-modal-form-action-button"
                      loading={buttonStatus == 1}
                      onClick={() => {
                        restLocationForm();
                        setEditingLocationMode(false);
                      }}
                    >
                      {t.common.cancel}
                    </button>

                    <button
                      className="map-modal-form-action-button blue"
                      loading={buttonStatus == 1}
                      onClick={() => {
                        submitLocationMarkerHandler();
                      }}
                    >
                      {successButton ? t.common.saved : t.common.save}
                    </button>
                  </>
                )}
              </div>
            ) : null}
          </>
        ) : userPermissions?.location?.edit ? (
          <>
            <p style={{ marginTop: '20px' }}>
              {t.locations.form.assign_data_statement}
            </p>
            <div className="map-button-container mb-5">
              <button
                className="map-modal-form-action-button blue"
                loading={buttonStatus == 1}
                onClick={() => {
                  handleAddPinLocation();
                }}
              >
                {t.common.add}
              </button>
            </div>
          </>
        ) : null}

        <Map
          google={google}
          style={style}
          containerStyle={containerStyle}
          center={restMarkerPosition.lat ? restMarkerPosition : defaultCenter}
          initialCenter={defaultCenter}
          zoom={editingLocationMode ? 12 : 10}
          version="weekly"
        >
          <Marker
            position={
              editingLocationMode
                ? markerPosition
                : restMarkerPosition.lat
                ? restMarkerPosition
                : defaultCenter
            }
            draggable={editingLocationMode}
            onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
            icon={{
              url: CustomIcon,
              scaledSize: new window.google.maps.Size(35, 35),
            }}
          />
        </Map>
      </div>

      <Divider />

      <div className="list-item">
        <div className="d-flex align-items-center  mb-3">
          <LocationMapIcon />
          <label className="workorder-intro-value ml-2 mr-0 mt-2">
            {t.locations.form.geo_markers}
          </label>
        </div>
        <div className="item-value">
          <GoogleMap
            google={google}
            mapContainerStyle={containerStyle}
            style={style}
            center={defaultGeoCenter}
            zoom={editingGeoCoordsMode ? 14 : 12}
            version="weekly"
            on
          >
            {geoActualCoords.length > 0 || editingGeoCoordsMode ? (
              <>
                <Circle
                  center={geoActualCoords[0]} // Use sample coordinates for center
                  radius={radius} // Set radius to 1 kilometer (1000 meters)
                  editable={editingGeoCoordsMode}
                  draggable={editingGeoCoordsMode}
                  onMouseUp={onCircleEdit}
                  onLoad={onCircleLoad}
                  onUnmount={onUnmount}
                  options={{
                    strokeColor: '#0C66E4',
                    fillColor: 'rgba(12, 102, 228, 0.7)',
                  }}
                  onRadiusChanged={() => {
                    handleRadiusEdit();
                  }}
                />
              </>
            ) : null}
          </GoogleMap>

          {userPermissions?.location?.edit ? (
            <>
              {restGeoCoords.length > 0 || editingGeoCoordsMode ? (
                <div className="map-button-container mt-5">
                  {!editingGeoCoordsMode ? (
                    <button
                      className="map-modal-form-action-button blue"
                      onClick={() => {
                        setEditingGeoCoordsMode(true);
                      }}
                    >
                      {t.common.edit}
                    </button>
                  ) : (
                    <>
                      <button
                        className="map-modal-form-action-button"
                        onClick={() => {
                          resetGeoCoordsForm();
                          setEditingGeoCoordsMode(false);
                        }}
                      >
                        {t.common.cancel}
                      </button>

                      <button
                        className="map-modal-form-action-button blue"
                        loading={buttonGeoStatus == 1}
                        onClick={() => {
                          submitGeoCoordsHandler();
                        }}
                      >
                        {successGeoButton ? t.common.saved : t.common.save}
                      </button>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <p style={{ marginTop: '20px' }}>
                    {t.locations.form.assign_geo_data_statement}
                  </p>
                  <div className="map-button-container mb-5">
                    <button
                      className="map-modal-form-action-button blue"
                      loading={buttonStatus == 1}
                      onClick={() => {
                        handleAddGeoCoords();
                      }}
                    >
                      {t.common.add}
                    </button>
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: GMAPAPIKEY,
})(Gmap);
