import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectTranslations } from "../../../config/i18n/slice";
import { DashboardWOStatusIcon, FullArrow } from "../../../../Assets/Icons/svg";
import useDisplayFormattedText from "../../../../hooks/useDisplayFormattedText";
import NoDashboardData from "../noDashboardData";

const DisposalSchedule = ({
  setOpenModal,
  setModalData,
  openModal,
  setAssetDashBoardData,
  assetDashBoardData,
}) => {
  const t = useSelector(selectTranslations);
  const [showAssign, setShowAssign] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const history = useHistory();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [assetDisposalData, setAssetDisposalData] = useState([]);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  useEffect(() => {
    if (assetDashBoardData?.disposalSchedule?.length > 0) {
      setAssetDisposalData(assetDashBoardData?.disposalSchedule);
    }
  }, [assetDashBoardData]);

  return (
    <Grid.Column className="completion-column pl-0 pt-0">
      <div
        className={
          openModal
            ? "completion-column-data no-border"
            : "completion-column-data"
        }
      >
        <div className="completion-cotainer d-flex justify-content-between mt-2">
          <div className="completion-title d-flex">
            <span className="title">
              {t.dashboard.asset_card.disposal_schedule}
            </span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowAssign(true)}
              onMouseLeave={() => setShowAssign(false)}
              className={selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"}
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "en" ? "right" : "left"}
                show={showAssign}
                textBoxWidth="150px"
                fontSize="10px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="0px 0px 0px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="8px"
                // moveDown="10px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.asset_tooltip.disposal}
                </span>
              </Tooltip>
              <DashboardWOStatusIcon />
            </p>
          </div>

          <span style={{ marginTop: "-10px", gap: "10px" }} className="d-flex">
            <span
              onClick={() => {
                setOpenModal(true);
                setModalData("DisposalSchedule");
                setShowExpandTooltip(false);
              }}
              style={{ marginTop: "2px" }}
              className=""
            >
              {openModal ? null : (
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowExpandTooltip(true)}
                  onMouseLeave={() => setShowExpandTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showExpandTooltip}
                    textBoxWidth="80px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="start"
                    moveRight="5px"
                    moveDown="3px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.expand_full}
                    </span>
                  </Tooltip>
                  <FullArrow />
                </p>
              )}
            </span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowAddCustomizedTooltip(true)}
              onMouseLeave={() => setShowAddCustomizedTooltip(false)}
              className=" mt-1"
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position="top"
                show={showAddCustomizedTooltip}
                textBoxWidth="105px"
                fontSize="10px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="8px 8px 8px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="0px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.add_customized}
                </span>
              </Tooltip>
              {/* <AddIconDashboard /> */}
            </p>
          </span>
        </div>

        <div className="completion-status-part">
          <Grid className="wo-data-container mr-0" columns={"equal"}>
            {/* <Grid.Column className="wo-data-chart d-flex align-items-center text-center">
<div className="w-100">

<Chart
options={chartOptions}
series={[70, 90, 50, 10]}
type="donut"
height={350}
/>
</div>
</Grid.Column> */}

            <Grid.Column className="wo-data-chart pr-0 d-flex align-items-center text-center">
              <div className="resolution-time-table w-100">
                <div
                  style={{ paddingLeft: "17px" }}
                  className={
                    openModal
                      ? "table-container asset-schedual h-500"
                      : "table-container asset-schedual"
                  }
                >
                  {" "}
                  {assetDashBoardData?.disposalSchedule?.length > 0 ? (
                    <table style={{ width: "-webkit-fill-available" }}>
                      <thead>
                        <tr>
                          <th style={{ width: "" }} rowspan="2">
                            {t.dashboard.asset_card.asset_id}
                          </th>
                          <th style={{ width: "" }} rowspan="2">
                            {t.dashboard.asset_card.asset_name}
                          </th>
                          <th style={{ width: "" }} className="">
                            {t.dashboard.asset_card.asset_category_label}
                          </th>{" "}
                          <th style={{ width: "" }} className="">
                            {t.dashboard.asset_card.remaining_life}
                          </th>
                          <th style={{ width: "" }} className="">
                            {t.dashboard.asset_card.scheduled_date}
                          </th>{" "}
                          <th style={{ width: "" }} className="">
                            {t.dashboard.asset_card.reason}
                          </th>
                          <th style={{ width: "" }} className="">
                            {t.dashboard.asset_card.status}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {// Take the first 5 entries
                        assetDisposalData?.map((asset, index) => (
                          <tr key={index}>
                            <td>
                              <div>
                                {asset?.assetId ? asset?.assetId : t.common.na}
                              </div>
                            </td>
                            <td>
                              <div
                                onClick={() =>
                                  history.push(`/assets?id=${asset?.assetId}`)
                                }
                              >
                                {asset?.assetName
                                  ? DisplayFormattedText(asset?.assetName, 20)
                                  : t.common.na}
                              </div>
                            </td>
                            <td>
                              <div>
                                {asset?.assetCategory
                                  ? DisplayFormattedText(
                                      asset?.assetCategory,
                                      20
                                    )
                                  : t.common.na}
                              </div>
                            </td>
                            <td>
                              <div>
                                {asset?.remainingUsefulLife
                                  ? asset?.remainingUsefulLife
                                  : t.common.na}
                              </div>
                            </td>
                            <td>
                              <div>
                                {asset?.scheduledDisposalDate
                                  ? asset?.scheduledDisposalDate
                                  : t.common.na}
                              </div>
                            </td>
                            <td>
                              <div>
                                {asset?.reasonForDisposal
                                  ? DisplayFormattedText(
                                      asset?.reasonForDisposal,
                                      20
                                    )
                                  : t.common.na}
                              </div>
                            </td>
                            <td>
                              <div
                                style={
                                  { padding: "0px 4px 0px 4px",  width: " max-content" }
                                  
                                }
                                className={`pill ${
                                  asset?.status === "active" ||
                                  asset?.status === "true"
                                    ? "active-green"
                                    : asset?.status === "inactive" ||
                                      asset?.status === "false"
                                    ? "inactive-grey"
                                    : "high-low"
                                }`}
                              >
                                {asset.status === "active" ||
                                asset.status === "true"
                                  ? t.assets.active
                                  : asset?.status === "inactive" ||
                                    asset.status === "false"
                                  ? t.assets.inActive
                                  : t.assets.disposed_asset}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : <NoDashboardData height="190px"/>}
                </div>
              </div>
              {/* <div className="status-container">
{statusOptions.map((status, index) => (
<React.Fragment key={index}>
  <div
    style={{ gap: '16px' }}
    className="item-value mb-3 d-flex align-items-center justify-content-end"
  >
    <div className="label-text">
      {status.label}
      <p className=" text-right">15</p>
    </div>
    <div className="">
      <Label
        circular
        empty
        color={status.color}
        style={{
          marginRight: '5px',
          width: '12px',
          height: '12px',
        }}
        size="medium"
      />
    </div>
  </div>
</React.Fragment>
))}
</div> */}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </Grid.Column>
  );
};

export default DisposalSchedule;
