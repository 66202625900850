import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AddIconDashboard,
  DashboardWOStatusIcon,
  FullArrow,
  RefreshSvg,
} from "../../../../Assets/Icons/svg";
import { selectTranslations } from "../../../config/i18n/slice";
import { Grid, Label } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import Chart from "react-apexcharts";
import NoDashboardData from "../noDashboardData";
const WoAssigneeChart = ({
  setOpenModal,
  openModal,
  optionsData,
  setModalData,
  dashBoardData,
  height = 187,
  
}) => {
  const t = useSelector(selectTranslations);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [woAssigneeChart, setAssigneeChart] = useState({
    series: [],
    options: {
      chart: {
        width: 187,
        height: height,
        type: "donut",
      },
      labels: [],
      legend: {
        show: false,
      },
      colors: [],
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "25px",
          fontFamily: "Roboto",
          fontWeight: 100,
          colors: ["#e9f2ff"],
        },
      },

      // dataLabels: {
      //   enabled: true,
      //   formatter: function (val, opts) {
      //     const percentages = opts.w.config.seriesPercentages || [];
      //     return `${percentages[opts.seriesIndex] || 0}%`;
      //   },
      //   style: {
      //     fontSize: "25px",
      //     fontFamily: "Roboto",
      //     fontWeight: 100,
      //     colors: ["#e9f2ff"],
      //   },
      // },
      stroke: {
        show: false,
        width: 0,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          const value =
            w.config.series[seriesIndex][dataPointIndex] ||
            w.config.series[seriesIndex];
          const label = w.config.labels[seriesIndex];
          const color = w.config.colors[seriesIndex];

          return `
                <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
                  <div style="display: flex; align-items: center;">
                   <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%;  ${
            selectedLanguage == "en" ? "margin-right: 4px" : "margin-left: 4px"
          }"></div>
                    <span>${label} : </span>&nbsp; ${value}
                  </div>
                  </div>
                </div>
              `;
        },
      },
    },
  });
  const [statusOptions, setStatusOptions] = useState([]);
  function generateRandomColor(existingColors) {
    const letters = "0123456789ABCDEF";
    let color;

    do {
      color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    } while (existingColors.has(color)); // Check if color is already in the set

    return color;
  }

  function getRandomColors(dataLength) {
    const randomColors = [];
    for (let i = 0; i < dataLength; i++) {
      randomColors.push(generateRandomColor());
    }
    return randomColors;
  }

  useEffect(() => {
    if (dashBoardData?.workOrdersByAssigneeData?.length > 0) {
      const existingColors = new Set();
      let predefinedColors = [
        "#C0D9FC",
        "#AECEFA",
        "#9CC2F7",
        "#8AB7F5",
        "#78ABF2",
        "#5494EE",
        "#307DE9",
        "#0C66E4",
        "#0A55BD",
        "#084396",
        "#063270",
        "#05295C",
        "#042049",
        "#031835",
        "#020F22",
        "#D9EDDE",
        "#CBE6D2",
        "#BDE0C6",
        "#B0D9BB",
        "#A2D3AF",
        "#86C597",
        "#6BB880",
        "#4FAB68",
        "#428F57",
        "#357346",
        "#295735",
        "#22492D",
        "1C3B24",
        "#152D1C",
        "#0F1F13",
        "#FEEEB3",
        "#FBE8A1",
        "#F8E290",
        "#F6DC7E",
        "#F3D66D",
        "#EDCA49",
        "#E8BE26",
        "#E2B203",
        "#BA9202",
        "#927202",
        "#695301",
        "#554301",
        "#413301",
        "#2D2300",
        "#191300",
        "#F2F4F7",
        "#E6EAEF",
        "#DADFE7",
        "#CDD5DE",
        "#C1CAD6",
        "#A9B6C6",
        "#90A1B5",
        "#788CA5",
        "#66788E",
        "#556477",
        "#43505F",
        "#3A4654",
        "#323C48",
        "#29323D",
        "#202831",
        "#F5EDFF",
        "#EADAFF",
        "#E0C8FF",
        "#D5B5FF",
        "#C191FF",
        "#AC6CFF",
        "#9747FF",
        "#8339E4",
        "#702BCA",
        "#5C1CAF",
        "#5215A2",
        "#490E95",
        "#3F0787",
        "#35007A",
      ];

      const counts = dashBoardData?.workOrdersByAssigneeData?.map(
        (item) => item.count
      );
      const labels = dashBoardData?.workOrdersByAssigneeData?.map(
        (item) => item.name
      );
      // const randomColors = dashBoardData?.workOrdersByAssigneeData?.map(item => {

      //     return item?.name === 'Unassigned' ? '#788CA5' : generateRandomColor();
      //   });

      const randomColors = dashBoardData?.workOrdersByAssigneeData?.map(
        (item) => {
          let color;

          if (item?.name === "Unassigned") {
            color = "#788CA5";
          } else {
            if (predefinedColors.length > 0) {
              color = predefinedColors.shift(); // Use and remove the first color from predefinedColors
            } else {
              color = generateRandomColor(existingColors); // Generate a random color once predefined colors are exhausted
            }
          }

          existingColors.add(color); // Add the color to the existingColors set
          return color;
        }
      );

      const colorArray = Array.from(existingColors);
      //   const updatedStatusOptions = statusOptions.map(option => {
      //     const count = dashBoardData?.workOrderStatus[option.value]?.count || 0;
      //     return { ...option, count };
      //   });

      const updatedAssigneeOptions = dashBoardData?.workOrdersByAssigneeData?.map(
        (user, index) => ({
          ...user,
          color: colorArray[index % colorArray.length],
        })
      );

      setStatusOptions(updatedAssigneeOptions);

      setAssigneeChart((prevState) => ({
        ...prevState,
        series: counts,
        options: {
          ...prevState.options,
          labels: labels,
          colors: colorArray,
        },
      }));

      // const seriesPercentages = [
      //   open.percentage,
      //   inReview.percentage,
      //   onHold.percentage,
      //   completed.percentage,
      //   inProgress.percentage,
      // ];
    }
  }, [dashBoardData]);
  return (
    <>
      {dashBoardData?.workOrdersByAssigneeData?.length > 0 ? (
        <Grid.Column className={"completion-column pl-0"}>
          <div
            className={
              height == "400"
                ? "completion-column-data no-border"
                : "completion-column-data"
            }
          >
            <div className="completion-cotainer d-flex justify-content-between mt-2 pb-0">
              <div className="completion-title d-flex">
                <span className="title">{t.dashboard.cards.assignee}</span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className={
                    selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                  }
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={selectedLanguage == "en" ? "right" : "left"}
                    show={showTooltip}
                    textBoxWidth="105px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="0px 0px 0px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="8px"
                    // moveDown="10px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.tooltip.assignee}
                    </span>
                  </Tooltip>
                  <DashboardWOStatusIcon />
                </p>
              </div>

              <span
                style={{ marginTop: "-10px", gap: "10px" }}
                className="d-flex"
              >
                <span
                  onClick={() => {
                    setOpenModal(true);
                    setModalData("Assignee");
                  }}
                  style={{ marginTop: "2px" }}
                  className=""
                >
                  {height == "400" ? null : (
                    <p
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        marginTop: "-10px",
                      }}
                      onMouseOver={() => setShowExpandTooltip(true)}
                      onMouseLeave={() => setShowExpandTooltip(false)}
                      className=" mt-1"
                    >
                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top"
                        show={showExpandTooltip}
                        textBoxWidth="80px"
                        fontSize="10px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == "ar"
                            ? "Noto Kufi Arabic"
                            : "Roboto"
                        }
                        padding="8px 8px 8px 8px"
                        borderRadius="5px"
                        static
                        arrowAlign="start"
                        moveRight="5px"
                        moveDown="3px"
                      >
                        <span
                          style={{ padding: "0px 10px" }}
                          className="work-order-tooltip"
                        >
                          {t.dashboard.expand_full}
                        </span>
                      </Tooltip>
                      <FullArrow />
                    </p>
                  )}
                </span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowAddCustomizedTooltip(true)}
                  onMouseLeave={() => setShowAddCustomizedTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="left"
                    show={showAddCustomizedTooltip}
                    textBoxWidth="105px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="0px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.add_customized}
                    </span>
                  </Tooltip>
                  {/* <AddIconDashboard /> */}
                </p>
              </span>
            </div>
            {dashBoardData?.workOrdersByAssigneeData?.length === 1 && dashBoardData?.workOrdersByAssigneeData[0]?.name === "Unassigned" && dashBoardData?.workOrdersByAssigneeData[0]?.count === 0 ?  
            <NoDashboardData/> :
            <div className="completion-status-part">
              <Grid className="wo-data-container" columns={"equal"}>
                <Grid.Column className="wo-data-chart d-flex align-items-center text-center">
                  <div className="w-100">
                    <Chart
                      options={woAssigneeChart?.options}
                      series={woAssigneeChart?.series || []}
                      type="donut"
                      height={woAssigneeChart?.options?.chart?.height} //
                      // className="d-flex justify-content-center align-items-center"
                    />
                    {/* <Chart
            options={chartOptions}
            series={[70, 90, 50, 10]}
            type="donut"
            height={350}
          /> */}
                  </div>
                </Grid.Column>
                {height == 400 ? null : (
                  <Grid.Column
                    className={
                      selectedLanguage == "en"
                        ? "wo-data-status p-0 pr-15"
                        : "wo-data-status p-0 pl-15"
                    }
                  >
                    <div
                      className={`status-container scroll-data assignee ${
                        height == 400 ? "h-500" : ""
                      }`}
                    >
                      <div
                        className={`${
                          selectedLanguage == "en" ? "text-right" : "text-left"
                        } mt-7`}
                      >
                        <span
                          style={
                            selectedLanguage == "en"
                              ? {
                                  padding: "6px 8px 6px 8px",
                                  borderRadius: "8px",
                                  fontSize: "16px",
                                  background: "rgba(228, 230, 234, 1)",
                                }
                              : {
                                  padding: "0px 8px 0px 8px",
                                  borderRadius: "8px",
                                  fontSize: "16px",
                                  background: "rgba(228, 230, 234, 1)",
                                }
                          }
                        >
                          {t.dashboard.cards.total}:{" "}
                          {dashBoardData?.totalWorkOrder
                            ? dashBoardData?.totalWorkOrder
                            : 0}
                        </span>
                      </div>
                      {statusOptions.map((status, index) => (
                        <React.Fragment key={index}>
                          <div
                            style={{ gap: "16px" }}
                            className={`item-value mb-3 d-flex align-items-center justify-content-end ${
                              index == 0 ? "mt-3" : ""
                            } ${index == 5 ? "mb-8" : ""}`}
                          >
                            <div className="label-text">
                              {status?.name ? status?.name : "Unknown"}
                              <p
                                className={`${
                                  selectedLanguage == "en"
                                    ? "text-right"
                                    : "text-left"
                                }`}
                              >
                                {status?.count ? status?.count : 0}
                              </p>
                            </div>
                            <div className="">
                              <div
                                className="circular-label"
                                style={{ backgroundColor: status.color }}
                              />
                            </div>
                            {/* <div className="">
                      <Label
                        circular
                        empty
                        color={status.color}
                        style={{
                          marginRight: "5px",
                          width: "12px",
                          height: "12px",
                        }}
                        size="medium"
                      />
                    </div> */}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </Grid.Column>
                )}
              </Grid>
            </div>}
          </div>
        </Grid.Column>
      ) : null}
    </>
  );
};

export default WoAssigneeChart;
