import React, { useState, useEffect, createRef } from "react";
import {
  List,
  Header,
  Modal,
  Checkbox,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Popup,
  Icon,
  TextArea,
  GridColumn,
  Label,
  FormInput,
  Segment,
  Dimmer,
  Loader,
  DropdownItem,
  DropdownMenu,
  DropdownHeader,
} from "semantic-ui-react";

import { selectTranslations } from "../../config/i18n/slice";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import AddCategoryModal from "../../components/modal/category/CategoryCreateModal";

import {
  getAllProcedures,
  createNewInventory,
  getAllAssets,
  getAllVendros,
  uploadFile,
  IMAGES_URL,
  getAllCategories,
  getAllParentLocations,
  handleKeyDown,
  getAllLocations,
  getAllAreaByLocationId,
  getAllAreaLocations,
  getActiveUsersByRole,
  getAllTeams,
  currencyDecimalPlaces,
  getAllAssetsOption,
} from "../../config/functions";

import QRCode from "react-qr-code";
import { v4 as uuidv4 } from "uuid";

import { Country, State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { generateUniqueInteger } from "../../../utilFunctions/serialNumberGenerator";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import {
  AddIcon,
  AddIconWithCircle,
  AddImage,
  AddedFileIcon,
  BarcodeIcon,
  CloseIcon,
  CustomerPlusIcon,
  DeleteIcon,
  InfoIcon,
  InfoIconBlue,
  InventoryIcon,
  QRcodeIcon,
  QRcodeIconInactive,
  Repeat,
  StoreInfoIcon,
  TextSnippet,
} from "../../../Assets/Icons/svg";
import ToolTip from "react-power-tooltip";
import useCancelModal from "../../../hooks/Messages/useCancelModal";
import Asterisk from "../../components/common/Asterisk";
import { useDropzone } from "react-dropzone";
import ReactToPrint from "react-to-print";
import QRComponentToPrint from "../Assets/preview/qrCodeComponent";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
import { fetchPartsInitData } from "../../../redux/reducers/partReducer";
import { Resizable } from "re-resizable";
const numberRegex = /^[0-9]\d*$/;
const positiveNumRegex = /^[1-9]\d*$/;

//File Upload Function
const FileUpload = ({
  onFileChange,
  onCertificateFileChange,
  name = "",
  attachments = {},
  setAttachments = () => {},
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    audio: ["audio/mpeg"],
    video: ["video/mp4"],
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept:
    name === "image"
      ? Object.fromEntries(
          fileTypes.images
            .concat(fileTypes.audio, fileTypes.video)
            .map((type) => [type, []])
        )
      : Object.fromEntries(
          [
            ...fileTypes.images,
            ...fileTypes.documents,
            ...fileTypes.audio,
            ...fileTypes.video,
          ].map((type) => [type, []])
        ),
        onDrop: (acceptedFiles) => {
          let totalFile = attachments?.warrantyFile?.length + acceptedFiles?.length;
    
          if (name !== "image" && totalFile > 10) {
            toast.error(`You can only upload a maximum of 10 files.`);
            return;
          }
          const validFiles = acceptedFiles.filter((file) => {
            const isImage = fileTypes.images.includes(file.type);
            const isDocument = fileTypes.documents.includes(file.type);
            const isAudio = fileTypes.audio.includes(file.type);
            const isVideo = fileTypes.video.includes(file.type);
    
            const validSize =
              ((isImage || isDocument) && file.size < 5 * 1024 * 1024) ||
              ((isAudio || isVideo) && file.size < 50 * 1024 * 1024);
    
            if (!validSize) {
              toast.error(`File size too large: ${file.name}`);
              return false;
            }
            return true;
          });
    
          name === "image"
            ? onFileChange(validFiles, name)
            : onCertificateFileChange(validFiles, name);
        },
  });
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const renderFile = (file, index) => (
    <div
      className={`image-dropzone file d-flex justify-content-between text-start ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label style={{ fontSize: "16px" }} className="mb-0">
          <span
            className={`${
              selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
            }`}
          >
            <TextSnippet />
          </span>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.warrantyFile.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            warrantyFile: newCovidCopy,
          });
        }}
      >
        <CloseIcon />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );

  const renderImageFile = (file, index) => (
    <div
      className={`image-dropzone file d-flex justify-content-between text-center ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label style={{ fontSize: "16px" }} className="mb-0">
          <span
            className={`${
              selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
            }`}
          >
            <AddedFileIcon />
          </span>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.image.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            image: newCovidCopy,
          });
        }}
      >
        <CloseIcon />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );
  // console.log(attachments);
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          // <div className="image-dropzone file d-flex justify-content-between text-center">
          //   <div>
          //     <label style={{ fontSize: '16px' }} className="mb-0">
          //       <span
          //         className={`${
          //           selectedLanguage == 'en' ? 'ml-5 mr-8' : 'ml-8 mr-5'
          //         }`}
          //       >
          //         <AddedFileIcon />
          //       </span>

          //       {attachments?.image?.name}
          //     </label>
          //   </div>

          //   <div
          //     className="mr-5 ml-5 mt-1 c-pointer"
          //     onClick={(e) => {
          //       e.stopPropagation();
          //       setAttachments({
          //         ...attachments,
          //         image: null,
          //       });
          //     }}
          //   >
          //     <CloseIcon />
          //   </div>
          // </div>

          <div className="image-dropzone file d-flex justify-content-between text-center">
            <div>
              <label style={{ fontSize: "16px" }} className="mb-0">
                <span
                  className={`${
                    selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
                  }`}
                >
                  <AddedFileIcon />
                </span>

                {attachments?.image?.name}
              </label>
            </div>

            <div
              className="mr-5 ml-5 mt-1 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <CloseIcon />
            </div>
          </div>
        ) : (
          <div className="image-dropzone text-center">
            <div>
              <div className="mb-3">
                <AddImage />
              </div>
              <p className="p1">
                <span className="mr-2 ml-2"></span>
                <span className="mt-1">{t.workRequests.form.drag}</span>
                <span className="mt-1" style={{ color: "#0C66E4" }}>
                  {" "}
                  {t.workRequests.form.choose}
                </span>{" "}
                <span className="mt-1">{t.workRequests.form.upload}</span>
                <p className="text-center">PNG or JPEG</p>
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.length > 0 ? (
        <>
          {attachments.warrantyFile.map((file, index) =>
            renderFile(file, index)
          )}

          <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between mt-5">
            <div className="d-flex">
              <span className="mt-1">
                <TextSnippet />
              </span>
              <div className="pl-8 pr-8">
                <p className="p1 m-0">{t.workOrders.form.file}</p>
                <p className="p2 m-0">{t.parts.form.attach_file}</p>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        </>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <span className="mt-1">
              <TextSnippet />
            </span>
            <div className="pl-8 pr-8">
              <p className="p1 m-0">{t.workOrders.form.file}</p>
              <p className="p2 m-0">{t.parts.form.attach_file}</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};

//Add part compoment
function Add({
  fromPO = false,
  setFieldValue,
  handleSuccess12 = () => {},
  toggled,
  untoggle,
  refresh,
  setRefresh,
  setFilterOptions,
  role = "",
  allIds = [],
}) {
  const t = useSelector(selectTranslations);
  const { setCancelModal, CancelModal } = useCancelModal();
  const { userPermissions } = usePermissionsSimplified();
  const qrRef = createRef();
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  //set modal width
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const { initData } = useSelector((state) => ({
    initData: state.parts.partsInitData,
  }));

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [openCategoryAddModal, setOpenCategoryAddModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [initChange, setInitChange] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [avtiveBtn, setActiveBtn] = useState(2);
  const [showQtyTooltip, setQtyTooltip] = useState({});
  const [showMinQtyTooltip, setMinQtyTooltip] = useState({});
  const [showPeopleTooltip, setPeopleTooltip] = useState(false);
  const [showTeamsTooltip, setTeamsTooltip] = useState(false);
  const [showMaxQtyTooltip, setMaxQtyTooltip] = useState({});
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: [],
  });

  const [data, setData] = useState({
    quantityPerStore: [
      {
        location: "",
        quantity: null,
        unitCost: null,
        minimumQuantity: null,
        maximumQuantity: null,
        // unitCost: '',
        // totalCost: '',
      },
    ],
    name: "",
    description: "",
    category: "",
    minimumQuantity: null,
    maximumQuantity: null,
    // unitCost: null,
    costPer: "",
    partType: "",
    isNonStock: false,
    qrcode: "",
    barcode: "",
    serialNumber: "",
    vendor: [],
    asset: [],
    people: [],
    teams: "",
  });

  const [responseData, setResponseData] = useState({});
  const [showCancleTooltip, setCancleTooltip] = useState(false);

  const [options, setOptions] = useState({
    vendors: [],
    assets: [],
    procedures: [],
    categories: [],
    locations: [],
    location: [
      {
        option: [],
      },
    ],
    area: [
      {
        option: [],
      },
    ],

    allQtyOptions: [
      {
        key: 0,
        value: "pieces",
        text: t.parts.qty_unit.pic,
      },
      {
        key: 1,
        value: "items",
        text: t.parts.qty_unit.item,
      },
      {
        key: 2,
        value: "units",
        text: t.parts.qty_unit.units,
      },
      {
        key: 3,
        value: "liters (L)",
        text: t.parts.vol_unit.liter,
      },
      {
        key: 4,
        value: "gallons (gal)",
        text: t.parts.vol_unit.gallons,
      },
      {
        key: 5,
        value: "cubic meters (m³)",
        text: t.parts.vol_unit.cubic_mtr,
      },
      {
        key: 6,
        value: "cubic feet (ft³)",
        text: t.parts.vol_unit.cubic_feet,
      },
      {
        key: 7,
        value: "barrels",
        text: t.parts.vol_unit.barrel,
      },
      {
        key: 8,
        value: "kilograms (kg)",
        text: t.parts.mass_unit.kilo,
      },
      {
        key: 9,
        value: "pounds (lb)",
        text: t.parts.mass_unit.pounds,
      },
      {
        key: 10,
        value: "tons",
        text: t.parts.mass_unit.ton,
      },
      {
        key: 11,
        value: "metric tonnes",
        text: t.parts.mass_unit.mat_tons,
      },
    ],
    costPerQtyUnit: [
      {
        key: 0,
        value: "pieces",
        text: t.parts.qty_unit.pic,
      },
      {
        key: 1,
        value: "items",
        text: t.parts.qty_unit.item,
      },
      {
        key: 2,
        value: "units",
        text: t.parts.qty_unit.units,
      },
    ],
    costPerVolUnit: [
      {
        key: 0,
        value: "liters (L)",
        text: t.parts.vol_unit.liter,
      },
      {
        key: 1,
        value: "gallons (gal)",
        text: t.parts.vol_unit.gallons,
      },
      {
        key: 2,
        value: "cubic meters (m³)",
        text: t.parts.vol_unit.cubic_mtr,
      },
      {
        key: 3,
        value: "cubic feet (ft³)",
        text: t.parts.vol_unit.cubic_feet,
      },
      {
        key: 4,
        value: "barrels",
        text: t.parts.vol_unit.barrel,
      },
    ],
    costPerMassUnit: [
      {
        key: 0,
        value: "kilograms (kg)",
        text: t.parts.mass_unit.kilo,
      },
      {
        key: 1,
        value: "pounds (lb)",
        text: t.parts.mass_unit.pounds,
      },
      {
        key: 10,
        value: "tons",
        text: t.parts.mass_unit.ton,
      },
      {
        key: 11,
        value: "metric tonnes",
        text: t.parts.mass_unit.mat_tons,
      },
    ],

    partType: [
      {
        key: 1,
        text: t.parts.type.critical,
        value: "critical",
      },
      {
        key: 2,
        text: t.parts.type.non_critical,
        value: "non-critical",
      },
    ],
  });
  const fetchLocations = async () => {
    setInitLoading(true)
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(role === "super_admin" || role === "admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));
      setInitLoading(false)
      return the_options;
    }

    
  };

  /*
   * The fetchArea function retrieves and updates area options based on a selected location.
   */
  const fetchArea = async (id, index) => {
    let currentSelectedLocation = options.locations.find(
      (location) => location.value == `${id}`
    );
    console.log(currentSelectedLocation, "dsds");

    let the_options_area = [];
    if (currentSelectedLocation) {
      currentSelectedLocation.area.forEach((el) => {
        if (!data?.quantityPerStore.some((i) => i.area == el.id)) {
          the_options_area.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: `${el.attributes.name}`,
          });
        }
      });
    }

    let areaOptions = [...options.area];

    areaOptions[index].option = the_options_area;
  };

  const fetchLocationOption = async (id, index) => {
    let locationOptions = [...options.locations];

    const locations = data?.quantityPerStore
      .map((store) => store.location)
      .filter((location) => location !== "" && location != id);

    let updated_loc = locationOptions?.map((location) => {
      if (locations.includes(location?.value)) {
        // Disable the matching location
        return { ...location, disabled: true };
      }
      return location;
    });

    let areaOptions = [...options.location];


    areaOptions[index].option = updated_loc;

    setOptions((prev) => {
      return {
        ...prev,
        location: areaOptions,
      };
    });

    // let currentSelectedLocation = locationOptions?.find(
    //   (location) => location.value == `${id}`
    // );
    // console.log(currentSelectedLocation, "dsds");

    // let the_options_area = [];
    // if (currentSelectedLocation) {
    //   currentSelectedLocation.area.forEach((el) => {
    //     if (!data?.quantityPerStore.some((i) => i.area == el.id)) {
    //       the_options_area.push({
    //         key: `${el.id}`,
    //         value: `${el.id}`,
    //         text: `${el.attributes.name}`,
    //       });
    //     }
    //   });
    // }

    // let areaOptions = [...options.area];

    // areaOptions[index].option = the_options_area;

    // setOptions((prev) => ({
    //   ...prev,
    //   locations: the_options,
    // }));

    // return the_options;
  };
  useEffect(() => {

    if (toggled) {
 
      fetchLocations();
     
    }
  }, [toggled]);


  const [customFields, setCustomFields] = useState([]);

  /*
   * validation state
   */
  const [validation, setValidation] = useState({
    name: {
      error: true,
      pattern: (name) => name && name.trim() != "",
      skipped: false,
      message: t.common.required_field,
    },
    // unitCost: {
    //   error: true,
    //   pattern: (cost) => {
    //     if (!cost || cost === null) {
    //       return {
    //         isValid: false,
    //         message: t.common.cost_must_be_greater_than_zero,
    //       };
    //     } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(cost)) {
    //       return {
    //         isValid: false,
    //         message: t.common.valid_digits,
    //       };
    //     } else if (cost <= 0) {
    //       return {
    //         isValid: false,
    //         message: t.common.cost_must_be_greater_than_zero,
    //       };
    //     }
    //     return { isValid: true };
    //   },
    //   skipped: false,
    //   message: "",
    // },
    costPer: {
      error: true,
      pattern: (store) => store != "",
      skipped: false,
      message: t.common.required_field,
    },
    partType: {
      error: true,
      pattern: (store) => store != "",
      skipped: false,
      message: t.common.required_field,
    },
    people: {
      error: true,
      pattern: (users) => users.length > 0,
      skipped: false,
      message: t.common.required_field,
    },
    teams: {
      error: true,
      pattern: (customer) => customer != "",
      skipped: false,
      message: t.common.required_field,
    },

    quantityPerStore: [
      {
        quantity: {
          error: true,
          pattern: (quantity) => {
            if (quantity === null || quantity === undefined) {
              return false;
            } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(quantity)) {
              return false;
            } else if (quantity < 0) {
              return false;
            }
            return true;
          },
          skipped: false,
        },

        unitCost: {
          error: true,
          pattern: (unitCost) => {
            if (unitCost === null ||unitCost === ''|| unitCost === undefined) {
              return true;
            } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(unitCost)) {
              return false;
            } else if (unitCost < 0) {
              return true;
            }
            return true;
          },
          skipped: false,
        },

        minimumQuantity: {
          error: false, // Initially set to false
          pattern: (minQty, maxQty) => minQty < maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
          skipped: false, // Initially set to false
          message: t.parts.minQty_valid, // Error message for invalid minimum quantity
        },
        maximumQuantity: {
          error: false, // Initially set to false
          pattern: (maxQty, minQty) => maxQty > minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
          skipped: false, // Initially set to false
          message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
        },
        // unitCost: {
        //   error: true,
        //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
        //   skipped: false,
        //   message: t.common.required_field,
        // },
        // minimumQuantity: {
        //   error: true,
        //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
        //   skipped: false,
        //   message: t.common.required_field,
        // },
        location: {
          error: true,
          pattern: (store) => store != "",
          skipped: false,
          message: t.common.required_field,
        },
        // area: {
        //   error: true,
        //   pattern: (store) => store != "",
        //   skipped: false,
        //   message: t.common.required_field,
        // },
      },
    ],
    serialNumber: {
      error: true,
      pattern: (serialNumber) => serialNumber != "",
      skipped: false,
      message: t.common.required_field,
    },

    // minimumQuantity: {
    //   error: false, // Initially set to false
    //   pattern: (minQty, maxQty) => minQty < maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
    //   skipped: false, // Initially set to false
    //   message: t.parts.minQty_valid, // Error message for invalid minimum quantity
    // },
    // maximumQuantity: {
    //   error: false, // Initially set to false
    //   pattern: (maxQty, minQty) => maxQty > minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
    //   skipped: false, // Initially set to false
    //   message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
    // },
  });

  /*
   * The function fetches active users by role and organizes their data into options for a dropdown menu.
   */
  const fetchPeople = async () => {
    if (!data?.people) {
      setOptions({
        ...options,

        people: [],
      });
    }

    const locations = data.quantityPerStore
      .map((store) => store.location)
      .filter((location) => location !== "");

    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      !(role === "super_admin" || role === "admin")
     
          ? allIds
       
        : []
    );
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetches team options data from an API and formats it for display.
   */
  const fetchTeams = async () => {
    if (!data?.teams) {
      setOptions({
        ...options,

        teams: [],
      });
    }

    const locations = data.quantityPerStore
      .map((store) => store.location)
      .filter((location) => location !== "");

    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(role === "super_admin" || role === "admin")
      
          ? allIds
       
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: the_options,
      }));

      return the_options;
    }
  };
  const [validForm, setValidForm] = useState(false);

  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };
  /*
   * The `resetForm` function resets form data, validation rules, options, and sets certain states to their initial values.
   */
  const resetForm = () => {
    setInitLoading(false)
    setData({
      quantityPerStore: [
        {
          location: "",
          // area: "",
          quantity: "",
          unitCost: "",
          minimumQuantity: null,
          maximumQuantity: null,
        },
      ],
      name: "",
      description: "",
      // minimumQuantity: null,
      // maximumQuantity: null,
      category: "",
      // unitCost: "",
      costPer: "",
      partType: "",
      isNonStock: false,
      barcode: "",
      qrcode: "",
      serialNumber: "",
      vendor: [],
      asset: [],
      people: [],
      teams: "",
    });

    setAttachments({
      image: null,
      warrantyFile: [],
    });

    setValidation({
      quantityPerStore: [
        {
          quantity: {
            error: true,
            pattern: (quantity) => {
              if (quantity === null || quantity === ''|| quantity === undefined) {
                return false;
              } else if (!/^\d+(\.\d{1,4})?$/.test(quantity)) {
                return false;
              } else if (quantity < 0) {
                return false;
              }
              return true;
            },
            skipped: false,
           
          },
          unitCost: {
            error: true,
            pattern: (quantity) => {
              if (quantity === null || quantity === ''|| quantity === undefined) {
                return true;
              } else if (!/^\d+(\.\d{1,4})?$/.test(quantity)) {
                return false;
              } else if (quantity < 0) {
                return true;
              }
              return true;
            },
            skipped: false,
          },

          minimumQuantity: {
            error: false, // Initially set to false
            pattern: (minQty, maxQty) => minQty <= maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
            skipped: false, // Initially set to false
            message: t.parts.minQty_valid, // Error message for invalid minimum quantity
          },
          maximumQuantity: {
            error: false, // Initially set to false
            pattern: (maxQty, minQty) => maxQty >= minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
            skipped: false, // Initially set to false
            message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
          },

          // unitCost: {
          //   error: true,
          //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
          //   skipped: false,
          //   message: t.common.required_field,
          // },

          location: {
            error: true,
            pattern: (store) => store != "",
            skipped: false,
            message: t.common.required_field,
          },
          // area: {
          //   error: true,
          //   pattern: (store) => store != "",
          //   skipped: false,
          //   message: t.common.required_field,
          // },
        },
      ],
      name: {
        error: false,
        pattern: (name) => name && name.trim() != "",
        skipped: false,
        message: t.common.required_field,
      },
      // minimumQuantity: {
      //   error: false, // Initially set to false
      //   pattern: (minQty, maxQty) => minQty <= maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
      //   skipped: false, // Initially set to false
      //   message: t.parts.minQty_valid, // Error message for invalid minimum quantity
      // },
      // maximumQuantity: {
      //   error: false, // Initially set to false
      //   pattern: (maxQty, minQty) => maxQty >= minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
      //   skipped: false, // Initially set to false
      //   message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
      // },
      people: {
        error: true,
        pattern: (users) => users.length > 0,
        skipped: false,
        message: t.common.required_field,
      },
      teams: {
        error: true,
        pattern: (customer) => customer != "",
        skipped: false,
        message: t.common.required_field,
      },

      partType: {
        error: true,
        pattern: (store) => store != "",
        skipped: false,
        message: t.common.required_field,
      },
      // unitCost: {
      //   error: true,
      //   pattern: (cost) => {
      //     if (!cost || cost === null) {
      //       return {
      //         isValid: false,
      //         message: t.common.cost_must_be_greater_than_zero,
      //       };
      //     } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(cost)) {
      //       return {
      //         isValid: false,
      //         message: t.common.valid_digits,
      //       };
      //     } else if (cost <= 0) {
      //       return {
      //         isValid: false,
      //         message: t.common.cost_must_be_greater_than_zero,
      //       };
      //     }
      //     return { isValid: true };
      //   },
      //   skipped: false,
      //   message: "",
      // },
      costPer: {
        error: true,
        pattern: (store) => store != "",
        skipped: false,
        message: t.common.required_field,
      },
      // quantity: {
      //   error: false,
      //   pattern: (quantity) => quantity >= 1,
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      // cost: {
      //   error: false,
      //   pattern: (quantity) => quantity >= 1,
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      serialNumber: {
        error: false,
        pattern: (serialNumber) => serialNumber != "",
        skipped: false,
        message: t.common.required_field,
      },
      // minimumQuantity: {
      //   error: false,
      //   pattern: (quantity) =>
      //     quantity === '' || (!isNaN(quantity) && Number(quantity) >= 0),
      //   skipped: false,
      //   message: 'Please enter valid integer value',
      // },
    });

    setModalWidth(Math.max(window.innerWidth / 2, 514));

    setOptions({
      vendors: [],
      assets: [],
      procedures: [],
      categories: [],
      locations: [],
      location: [
        {
          option: [],
        },
      ],
      allQtyOptions: [
        {
          key: 0,
          value: "pieces",
          text: t.parts.qty_unit.pic,
        },
        {
          key: 1,
          value: "items",
          text: t.parts.qty_unit.item,
        },
        {
          key: 2,
          value: "units",
          text: t.parts.qty_unit.units,
        },
        {
          key: 3,
          value: "liters (L)",
          text: t.parts.vol_unit.liter,
        },
        {
          key: 4,
          value: "gallons (gal)",
          text: t.parts.vol_unit.gallons,
        },
        {
          key: 5,
          value: "cubic meters (m³)",
          text: t.parts.vol_unit.cubic_mtr,
        },
        {
          key: 6,
          value: "cubic feet (ft³)",
          text: t.parts.vol_unit.cubic_feet,
        },
        {
          key: 7,
          value: "barrels",
          text: t.parts.vol_unit.barrel,
        },
        {
          key: 8,
          value: "kilograms (kg)",
          text: t.parts.mass_unit.kilo,
        },
        {
          key: 9,
          value: "pounds (lb)",
          text: t.parts.mass_unit.pounds,
        },
        {
          key: 10,
          value: "tons",
          text: t.parts.mass_unit.ton,
        },
        {
          key: 11,
          value: "metric tonnes",
          text: t.parts.mass_unit.mat_tons,
        },
      ],
      costPerQtyUnit: [
        {
          key: 0,
          value: "pieces",
          text: t.parts.qty_unit.pic,
        },
        {
          key: 1,
          value: "items",
          text: t.parts.qty_unit.item,
        },
        {
          key: 2,
          value: "units",
          text: t.parts.qty_unit.units,
        },
      ],
      costPerVolUnit: [
        {
          key: 0,
          value: "liters (L)",
          text: t.parts.vol_unit.liter,
        },
        {
          key: 1,
          value: "gallons (gal)",
          text: t.parts.vol_unit.gallons,
        },
        {
          key: 2,
          value: "cubic meters (m³)",
          text: t.parts.vol_unit.cubic_mtr,
        },
        {
          key: 3,
          value: "cubic feet (ft³)",
          text: t.parts.vol_unit.cubic_feet,
        },
        {
          key: 4,
          value: "barrels",
          text: t.parts.vol_unit.barrel,
        },
      ],
      costPerMassUnit: [
        {
          key: 0,
          value: "kilograms (kg)",
          text: t.parts.mass_unit.kilo,
        },
        {
          key: 1,
          value: "pounds (lb)",
          text: t.parts.mass_unit.pounds,
        },
        {
          key: 10,
          value: "tons",
          text: t.parts.mass_unit.ton,
        },
        {
          key: 11,
          value: "metric tonnes",
          text: t.parts.mass_unit.mat_tons,
        },
      ],

      partType: [
        {
          key: 1,
          text: t.parts.type.critical,
          value: "critical",
        },
        {
          key: 2,
          text: t.parts.type.non_critical,
          value: "non-critical",
        },
      ],
    });
    setActiveBtn(2);

    setInitChange(false);
    setValidForm(false);
  };

  /*
   * Handle measuring Unit Dropdown
   */
  const onValuesChange2 = (name, value) => {
    setData({
      ...data,
      costPer: value,
    });
  };
  /*
   * The function `onValuesChange` handles changes in form field values, updating state and performing validations based on the field name.
   */
  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    let currentFieldValue = "";
    const dropdown_fields = [
      "vendor",
      "people",
      "teams",
      "asset",
      "costPer",
      "procedure",
      "category",
      "location",
      // "area",
      "partType",
    ];

    const boolean_fields = ["isNonStock"];

    if (!dropdown_fields.includes(name) && !boolean_fields.includes(name)) {
      currentFieldValue = event.target.value;

      // if (name == "unitCost") {
      //   if (event.target.value) {

      //     let value = handleDecimalPoints(event.target.value);
      //     // const value = parseFloat(event.target.value).toFixed(4);
      //     setData({
      //       ...data,
      //       [name]: Number(value),
      //     });
      //   } else {
      //     setData({
      //       ...data,
      //       [name]: null,
      //     });
      //   }
      // } else {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }
    // }

    if (dropdown_fields.includes(name)) {
      currentFieldValue = value.value;

      setData({
        ...data,
        [name]: value.value,
      });
    }

    if (boolean_fields.includes(name)) {
      currentFieldValue = value.checked;
      setData({
        ...data,
        [name]: value.checked,
      });
    }

    let fieldValidation = validation[name];
    if (fieldValidation) {
      // console.log(
      //   fieldValidation.pattern(currentFieldValue) ? false : true,
      //   currentFieldValue
      // );
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
          skipped: true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
    // if (name == "unitCost") {
    //   if (currentFieldValue == 0) {
    //     setValidation({
    //       ...validation,
    //       unitCost: {
    //         ...validation.unitCost,
    //         error: true,
    //         skipped: true,
    //         message: t.common.cost_must_be_greater_than_zero,
    //       },
    //     });
    //   }
    // }
  };

  /*
   * The `addNewStore` function refer to add new inventory.
   */
  const addNewStore = () => {
    let areaData = [...data.quantityPerStore];
    areaData.push({
      location: "",
      unitCost: null,
      // area: "",
      minimumQuantity: null,
      maximumQuantity: null,
      quantity: null,
    });

    let areaDataValidation = [...validation.quantityPerStore];

    areaDataValidation.push({
      quantity: {
        error: true,
        pattern: (quantity) => {
          if (quantity === null || quantity === undefined) {
            return false;
          } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(quantity)) {
            return false;
          } else if (quantity < 0) {
            return false;
          }
          return true;
        },
        skipped: false,
      },
      unitCost: {
        error: true,
        pattern: (quantity) => {
          if (quantity === null || quantity === ''|| quantity === undefined) {
            return true;
          } else if (!/^(\d{1,8})(\.\d{1,4})?$/.test(quantity)) {
            return false;
          } else if (quantity < 0) {
            return true;
          }
          return true;
        },
        skipped: false,
      },

      minimumQuantity: {
        error: false, // Initially set to false
        pattern: (minQty, maxQty) => minQty <= maxQty || maxQty === null, // Check if minQty is less than or equal to maxQty or if maxQty is not provided
        skipped: false, // Initially set to false
        message: t.parts.minQty_valid, // Error message for invalid minimum quantity
      },
      maximumQuantity: {
        error: false, // Initially set to false
        pattern: (maxQty, minQty) => maxQty >= minQty || minQty === null, // Check if maxQty is greater than or equal to minQty or if minQty is not provided
        skipped: false, // Initially set to false
        message: t.parts.maxQty_valid, // Error message for invalid maximum quantity
      },
      // unitCost: {
      //   error: true,
      //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      // costPer: {
      //   error: true,
      //   pattern: (store) => store != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      // minimumQuantity: {
      //   error: true,
      //   pattern: (quantity) => quantity >= 1 && numberRegex.test(quantity),
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      location: {
        error: true,
        pattern: (store) => store != "",
        skipped: false,
        message: t.common.required_field,
      },
      // area: {
      //   error: true,
      //   pattern: (store) => store != "",
      //   skipped: false,
      //   message: t.common.required_field,
      // },
    });

    let areaDataOptions = [...options.location];

    areaDataOptions.push({
      option: [],
    });
    setData({
      ...data,
      quantityPerStore: areaData,
    });

    setValidation({
      ...validation,
      quantityPerStore: areaDataValidation,
    });
    setOptions({
      ...options,
      location: areaDataOptions,
    });
  };

  /*
   * The `onFileChange` function sets a random number as an ID for a file and updates the single attachments state with file information.
   */
  const onFileChange = (files, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  /*
   * The `onCertificateFileChange` function sets a random number as an ID for a file and updates the multiple attachments state with file information.
   */
  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };

  /*
   * The fetchAssets function retrieves data for active assets and maps the response to create options for a dropdown menu.
   */
  const fetchAssets = async () => {
    if (!data?.asset) {
      setOptions({
        ...options,

        assets: [],
      });
    }


    const fetchData = await getAllAssetsOption(
      organisationId,
      "all_asset",
      1,
      9999,
      data?.locations
        ? [data?.locations]
        : !(role === "super_admin" || role === "admin")
        ? data?.locations
          ? [data?.locations]
          : []
        : [],
      [],
      "",
      "",
      [],
      ["name:asc"]
    );

    let assetOptions = [];
    let parentToChildMap = new Map();
    let childToGrandChildMap = new Map();
    let hierarchicalOptions = [];

    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        const isDisposed = el?.attributes?.status === "dispose";
    
        // Access rule: Non-admin users can't access certain assets
        const isActive = !isDisposed && (!(role === "super_admin" || role === "admin")
          ? (data?.locations
            ? data?.locations == el?.attributes?.location?.data?.id
            : allIds?.includes(el?.attributes?.location?.data?.id))
          : true);
    
        // Filter out disposed or inactive assets with no descendants
        if (isDisposed || (!isActive && !el?.attributes?.storedAssets?.length)) {
          return;
        }
    
        // Create parent asset option
        const parentAsset = {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { src: parent_icon }, // Parent asset icon
          className: "main-location-dropdown-option",
          disabled: !isActive, // Disable only for non-admin roles
        };
    
        let hasAccessibleChildren = false;
        let hasAccessibleGrandchildren = false;
    
        parentToChildMap.set(el.id, []);
        assetOptions.push(parentAsset);
    
        // Collect child assets
        if (el?.attributes?.storedAssets?.length > 0) {
          el.attributes.storedAssets.forEach((childEl) => {
            if (childEl?.childAsset?.data) {
              const childAsset = childEl.childAsset.data;
              const childIsDisposed = childAsset?.attributes?.status === "dispose";
    
              const childIsActive = !childIsDisposed && (!(role === "super_admin" || role === "admin")
                ? (data?.locations
                  ? data?.locations == childAsset?.attributes?.location?.data?.id
                  : allIds?.includes(childAsset?.attributes?.location?.data?.id))
                : true);
    
              // Filter out disposed or inactive child assets with no grandchildren
              if (childIsDisposed || (!childIsActive && !childEl?.grandChildAsset?.data?.length)) {
                return;
              }
    
              // Track whether there are any accessible children
              if (childIsActive) hasAccessibleChildren = true;
    
              const childOption = {
                key: childAsset.id,
                value: childAsset.id,
                text: `${childAsset.attributes.name}`,
                image: { src: child_icon }, // Child asset icon
                className: "sub-location-dropdown-option",
                parentId: el.id,
                disabled: !childIsActive, // Disable child based on role
              };
    
              parentToChildMap.get(el.id).push(childOption);
              childToGrandChildMap.set(childAsset.id, []);
    
              // Collect grandchild assets
              if (childEl?.grandChildAsset?.data?.length > 0) {
                childEl.grandChildAsset.data.forEach((grandChildEl) => {
                  const grandChildIsDisposed = grandChildEl?.attributes?.status === "dispose";
    
                  const grandChildIsActive = !grandChildIsDisposed && (!(role === "super_admin" || role === "admin")
                    ? (data?.locations
                      ? data?.locations == grandChildEl?.attributes?.location?.data?.id
                      : allIds?.includes(grandChildEl?.attributes?.location?.data?.id))
                    : true);
    
                  // Filter out disposed or inactive grandchild assets
                  if (grandChildIsDisposed || !grandChildIsActive) {
                    return;
                  }
    
                  // Track whether there are any accessible grandchildren
                  if (grandChildIsActive) hasAccessibleGrandchildren = true;
    
                  const grandChildOption = {
                    key: grandChildEl.id,
                    value: grandChildEl.id,
                    text: `${grandChildEl.attributes.name}`,
                    image: { src: gchild_icon }, // Grandchild asset icon
                    className: "grandchild-asset-dropdown-option",
                    parentId: el.id,
                    childParentId: childAsset.id,
                    disabled: !grandChildIsActive, // Disable based on role
                  };
    
                  childToGrandChildMap.get(childAsset.id).push(grandChildOption);
                });
              }
            }
          });
        }
    
        // Ensure the parent asset is displayed if it has no accessible children or grandchildren
        if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
          // Clear the child options and only show the parent
          parentToChildMap.set(el.id, []);
        }
      });
    
      // Sort child and grandchild options alphabetically within each parent and child context
      parentToChildMap.forEach((children) => {
        children.sort((a, b) => a.text.localeCompare(b.text));
      });
    
      childToGrandChildMap.forEach((grandChildren) => {
        grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      });
    
      // Combine all the options into a hierarchical structure
      assetOptions.forEach((parentOption) => {
        hierarchicalOptions.push(parentOption);
    
        const children = parentToChildMap.get(parentOption.key) || [];
        children.forEach((childOption) => {
          hierarchicalOptions.push(childOption);
    
          const grandChildren = childToGrandChildMap.get(childOption.key) || [];
          grandChildren.forEach((grandChildOption) => {
            hierarchicalOptions.push(grandChildOption);
          });
        });
      });
      setOptions((prev) => {
            return {
              ...prev,
              assets: hierarchicalOptions,
            };
          });
    
          return hierarchicalOptions;

    } 
    // const locations = data.quantityPerStore
    //   .map((store) => store.location)
    //   .filter((location) => location !== "");
    // const fetchData = await getAllAssetsOption(
    //   organisationId,
    //   "all_asset",
    //   1,
    //   9999,
    //   !(role === "super_admin" || role === "admin")
    //   ?  allIds
    //     : [],
    //   [],
    //   "",
    //   "",
    //   [],
    //   ["name:asc"]
    // );

    // let assetOptions = [];
    // let parentToChildMap = new Map();
    // let childToGrandChildMap = new Map();

    // if (fetchData?.response?.length > 0) {
    //   fetchData.response.forEach((el) => {
    //     // Default to active if the user is admin or super_admin
    //     const isDisposed = el?.attributes?.status === "dispose";

    //     const isActive = isDisposed    ? !isDisposed   : !(role === "super_admin" || role === "admin") ?   allIds?.includes(el?.attributes?.location?.data?.id)  : !isDisposed; // In
    //     // (roleData === "super_admin" || roleData === "admin" || allIds?.includes(el?.attributes?.location?.data?.id)) &&
    //     // !isDisposed; // Include status check for active
    //     // const isActive = roleData === "super_admin" || roleData === "admin" ||
    //     //                  allIds?.includes(el?.attributes?.location?.data?.id);

    //     // Create the parent asset option
    //     const parentAsset = {
    //       key: el.id,
    //       value: el.id,
    //       text: el.attributes.name,
    //       image: { src: parent_icon }, // Use a location icon for the main asset
    //       className: "main-location-dropdown-option",
    //       disabled: !isActive, // Disable only for non-admin roles
    //     };

    //     parentToChildMap.set(el.id, []);
    //     assetOptions.push(parentAsset);

    //     // Collect child assets
    //     if (el?.attributes?.storedAssets?.length > 0) {
    //       el.attributes.storedAssets.forEach((childEl) => {
    //         if (childEl?.childAsset?.data) {
    //           const childAsset = childEl.childAsset.data;
    //           const childIsDisposed =
    //             childAsset?.attributes?.status === "dispose";


    //             const childIsActive = childIsDisposed    ? !childIsDisposed   : !(role === "super_admin" || role === "admin") ?   allIds?.includes(childAsset?.attributes?.location?.data?.id)  : !childIsDisposed; // In

    //           // const childIsActive = childIsDisposed
    //           //   ? !childIsDisposed
    //           //   : !(role === "super_admin" || role === "admin")
    //           //   ? locations?.length > 0
    //           //     ? locations?.includes(
    //           //         childAsset?.attributes?.location?.data?.id
    //           //       )
    //           //     : allIds?.includes(childAsset?.attributes?.location?.data?.id)
    //           //   : locations?.length > 0
    //           //   ? locations?.includes(
    //           //       childAsset?.attributes?.location?.data?.id
    //           //     )
    //           //   : !childIsDisposed; // In

    //           const childOption = {
    //             key: childAsset.id,
    //             value: childAsset.id,
    //             text: `${childAsset.attributes.name}`,
    //             image: { src: child_icon }, // Use a sub-location icon for child assets
    //             className: "sub-location-dropdown-option",
    //             parentId: el.id,
    //             disabled: !childIsActive, // Disable child based on role
    //           };

    //           parentToChildMap.get(el.id).push(childOption);

    //           // Initialize a child-to-grandchild mapping for this child
    //           childToGrandChildMap.set(childAsset.id, []);

    //           // Collect grandchild assets under this child
    //           if (childEl?.grandChildAsset?.data?.length > 0) {
    //             childEl.grandChildAsset.data.forEach((grandChildEl) => {
    //               const grandChildIsDisposed =
    //                 grandChildEl?.attributes?.status === "dispose";

    //               const grandChildIsActive = grandChildIsDisposed
    //                 ? !grandChildIsDisposed
    //                 : !(role === "super_admin" || role === "admin")
                   
    //                   ? allIds?.includes(
    //                       grandChildEl?.attributes?.location?.data?.id
    //                     )
                   
    //                 : !grandChildIsDisposed;

    //               const grandChildOption = {
    //                 key: grandChildEl.id,
    //                 value: grandChildEl.id,
    //                 text: `${grandChildEl.attributes.name}`,
    //                 image: { src: gchild_icon }, // Optionally, use a different icon for grandchild
    //                 className: "grandchild-asset-dropdown-option",
    //                 parentId: el.id, // Track the top-level parent asset
    //                 childParentId: childAsset.id, // Track the immediate child parent ID
    //                 disabled: !grandChildIsActive, // Disable grandchild based on role
    //               };

    //               childToGrandChildMap
    //                 .get(childAsset.id)
    //                 .push(grandChildOption);
    //             });
    //           }
    //         }
    //       });
    //     }
    //   });

    //   // Sort child and grandchild options alphabetically within each parent and child context
    //   parentToChildMap.forEach((children) => {
    //     children.sort((a, b) => a.text.localeCompare(b.text));
    //   });

    //   childToGrandChildMap.forEach((grandChildren) => {
    //     grandChildren.sort((a, b) => a.text.localeCompare(b.text));
    //   });

    //   // Combine all the options into a hierarchical structure
    //   let hierarchicalOptions = [];

    //   // Traverse each parent option and append its children and grandchildren
    //   assetOptions.forEach((parentOption) => {
    //     hierarchicalOptions.push(parentOption);

    //     // Add children under this parent
    //     const children = parentToChildMap.get(parentOption.key) || [];
    //     children.forEach((childOption) => {
    //       hierarchicalOptions.push(childOption);

    //       // Add grandchildren under this child
    //       const grandChildren = childToGrandChildMap.get(childOption.key) || [];
    //       grandChildren.forEach((grandChildOption) => {
    //         hierarchicalOptions.push(grandChildOption);
    //       });
    //     });
    //   });

    //   setOptions((prev) => {
    //     return {
    //       ...prev,
    //       assets: hierarchicalOptions,
    //     };
    //   });

    //   return hierarchicalOptions;
    // }
  };

  /*
   * The `fetchVendorsData` function fetches vendor data, processes it, and sets the options with vendor information.
   */
  const fetchVendorsData = async () => {
    if (!data?.vendor) {
      setOptions({
        ...options,

        vendors: [],
      });
    }

    const locations = data.quantityPerStore
      .map((store) => store.location)
      .filter((location) => location !== "");

    const fetchData = await getAllVendros(
      organisationId,
      "",
      1,
      9999,
      !(role === "super_admin" || role === "admin") ? allIds : []
    );
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions({
        ...options,
        vendors: the_options,
      });
    }
  };

  /*
   * The function fetchCategoryOptions fetches category options from an API and sets the options with category information.
   */
  const fetchCategoryOptions = async () => {
    const fetchData = await getAllCategories(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });

      setOptions({
        ...options,
        categories: the_options,
      });

      return the_options;
    }
  };

  /*
   * The function `removeThisData` removes a inventory
   */
  const removeThisData = (id) => (e) => {
    e.stopPropagation();
    const index = id;
    let newfields = [...data.quantityPerStore];
    let newfieldsOption = [...data.quantityPerStore];
    newfields.splice(index, 1);

    let newfieldsValidation = [...validation.quantityPerStore];
    newfieldsValidation.splice(index, 1);

    let newfieldsOptions = [...options.locations];
    let curretFieldOptions = newfieldsOption[index].location;
    let updated_loc = newfieldsOptions?.map((location) => {
      if (location?.value === curretFieldOptions) {
        // Disable the matching location
        return { ...location, disabled: false };
      }
      return location;
    });

    let newfieldsLocOptions = [...options.location];
    // newfieldsOptions.splice(index, 1);
    newfieldsLocOptions.splice(index, 1);

    setData({
      ...data,
      quantityPerStore: newfields,
      // vendor: [],
      asset: [],
      people: [],
      teams: "",
    });

    setOptions((prev) => {
      return {
        ...prev,
        location: newfieldsLocOptions,
      };
    });

    setValidation({
      ...validation,
      quantityPerStore: newfieldsValidation,
    });
  };

  /*
   * The function `uploadFilessHandler` filters and maps files that have been changed before uploading them, returning the upload response or false if no files are changed.
   */
  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  /*
   * The `cancelHandler` function resets a form, adjusts modal width, hides a cancel modal, untoggles something, and sets an initial change to false.
   */
  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    setNewCancelModal(false);
    untoggle();
    // toggleCancelModal(false);
    setInitChange(false);
  };

  /**
   * The function `downloadThisQR` converts an SVG element to a PNG image and initiates a download linkfor the PNG file.
   */
  const downloadThisQR = () => {
    const svg = document.getElementById("QRCodeDownloadable");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  /*
   * The `validationHandler` function in JavaScript updates validation properties based on input data and
   * checks for errors before returning a boolean indicating if there are any errors.
   */
  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      teams: {
        ...validation.teams,
        error: validation.teams.pattern(data.teams) ? false : true,
        skipped: true,
      },
      people: {
        ...validation.people,
        error: validation.people.pattern(data.people) ? false : true,
        skipped: true,
      },
      // unitCost: {
      //   ...validation.unitCost,
      //   error: validation.unitCost.pattern(data.unitCost).isValid
      //     ? false
      //     : true,
      //   skipped: true,
      //   message: validation.unitCost.pattern(data.unitCost).message,
      // },
      partType: {
        ...validation.partType,
        error: validation.partType.pattern(data.partType) ? false : true,
        skipped: true,
      },
      costPer: {
        ...validation.costPer,
        error: validation.costPer.pattern(data.costPer) ? false : true,
        skipped: true,
      },
      // minimumQuantity: {
      //   ...validation.minimumQuantity,
      //   error: validation.minimumQuantity.pattern(
      //     data.minimumQuantity,
      //     data.maximumQuantity
      //   )
      //     ? false
      //     : true,
      //   skipped: true,
      // },
      // maximumQuantity: {
      //   ...validation.maximumQuantity,
      //   error: validation.maximumQuantity.pattern(
      //     data.maximumQuantity,
      //     data.minimumQuantity
      //   )
      //     ? false
      //     : true,
      //   skipped: true,
      // },

      serialNumber: {
        ...validation.serialNumber,
        error: validation.serialNumber.pattern(data.serialNumber)
          ? false
          : true,
        skipped: true,
      },
    };

    currentValidation.quantityPerStore = validation.quantityPerStore.map(
      (item, index) => {
        let newItem = {};
        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            if (key == "minimumQuantity") {
              newItem[key] = {
                ...item[key],
                error: item[key].pattern(
                  data.quantityPerStore[index][key],
                  data?.quantityPerStore[index]?.maximumQuantity
                )
                  ? false
                  : true,
                skipped: true,
              };
            } else if (key == "maximumQuantity") {
              newItem[key] = {
                ...item[key],
                error: item[key].pattern(
                  data.quantityPerStore[index][key],
                  data?.quantityPerStore[index]?.minimumQuantity
                )
                  ? false
                  : true,
                skipped: true,
              };
            } else {
              newItem[key] = {
                ...item[key],
                error: item[key].pattern(data.quantityPerStore[index][key])
                  ? false
                  : true,
                skipped: true,
              };
        

              if (key == "quantity" || key == "unitCost") {
                newItem[key].message = item[key].pattern(
                  data.quantityPerStore[index][key]
                )
                  ? ""
                  : data.quantityPerStore[index][key] === null || data.quantityPerStore[index][key] === '' 
                  ? t.common.required_field
                  : t.common.valid_digits;
              }
            }
          }
        }
        return newItem;
      }
    );

    if (data.people.length > 0 || data.teams) {
      currentValidation.people.error = false;
      currentValidation.teams.error = false;
    } else {
      currentValidation.people.error = true;
      currentValidation.teams.error = true;
    }

    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some(([key, value]) => {
      if (Array.isArray(value)) {
        return value.some((obj) => {
          return Object.values(obj).some((subItem) => subItem.error);
        });
      } else {
        return value.error;
      }
    });

    return errorsInData;
  };

  // useEffect(() => {
  //   const validationToArray = Object.entries(validation);
  //   const cond = validationToArray.some((el) => el[1].error && el[1].skipped);

  //   if (initChange) setValidForm(!cond);
  // }, [validation, initChange]);

  // useEffect(() => {
  //   validationHandler();
  // }, [data.name, data.quantity]);

  /*
   * The function `createNewInventoryHandler` handles the creation of new inventory items, including
   * validation, file uploads, and error handling.
   */
  const createNewInventoryHandler = async () => {
    const errorsInData = validationHandler();

    if (!errorsInData) {
      setButtonStatus(1);
      const inventoryImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;

      const inventoryFilesReponse =
        attachments.warrantyFile && attachments?.warrantyFile?.length
          ? await uploadFilessHandler(attachments.warrantyFile)
          : null;
      createNewInventory2Handler(
        inventoryImageReponse?.status == 200
          ? inventoryImageReponse.data[0].id
          : null,
        inventoryFilesReponse?.status == 200
          ? inventoryFilesReponse.data.map((el) => el.id)
          : null
      );
    } else {
      scrollToError();
      if (data.people.length == 0 && !data.teams) {
        // Show toast message here
        toast.error(t.parts.people_valid);
      } else {
        scrollToError();
      }
    }
  };

  /*
   * The function `createNewInventory2Handler` is an asynchronous function that handles the creation of
   * new inventory items with various data inputs and performs error handling and success notifications based on the response.
   */
  const createNewInventory2Handler = async (image, files) => {


    console.log('data?.quantityPerStore: ', data?.quantityPerStore);
    const quantityPerStore = data?.quantityPerStore?.map(store => ({
      ...store,
      unitCost: store?.unitCost === "" ? null : store?.unitCost,
    })) || null;
    const response = await createNewInventory(
      String(data?.name),
      String(data?.description),
      data?.category ? Number(data.category) : null,
      // data?.unitCost ? Number(data.unitCost) : null,
      data?.costPer ? String(data.costPer) : null,
      data?.partType ? String(data.partType) : null,
      data?.isNonStock,
      avtiveBtn == 2 ? true : false,
      avtiveBtn == 1 ? true : false,
      data?.serialNumber ? String(data.serialNumber) : null,
      // avtiveBtn == 1 ? true : false,
      // String(data.area),
      // String(data.additionalInformation),
      // customFields,
      data?.vendor ? data.vendor : null,
      data?.asset ? data.asset : null,
      data?.people ? data.people : null,
      data?.teams ? data.teams : null,
      image,
      files,
      organisationId,
      currentUserId,
      quantityPerStore,
      // data?.minimumQuantity ? data?.minimumQuantity : null,
      // data?.maximumQuantity ? data?.maximumQuantity : null
    );
    // console.log(response);

    if (response && response.status == 200) {
      untoggle();
      resetForm();
      setResponseData({
        id: response.data.id,
        name: response.data.name,
        qrcode: response.data.qrcode,
      });

      if (fromPO) {
        return handleSuccess12(response?.data);
      }

      if (initData?.length == 0) {
        const fetchData = await dispatch(
          fetchPartsInitData({ organisationId, role, allIds })
        );
      }

      toast.success(t.parts.added_success);

      setFilterOptions({
        vendors: [],
        serialNumber: "",
        categories: [],
        teams: [],
        status: [""],
        partType: [""],
        assets: [],
        locations: [],
        // area: [],
        serialNumber: "",
      });

      // toggleSuccessModal(true);
    } else if (response.status == 400) {
      if (
        response.error &&
        response.error.details &&
        response.error.details.errors?.length
      ) {
        response.error.details.errors.map((one) => {
          if (one?.path[0] && one?.path[0] === "serialNumber") {
            toast.error(t.assets.serial_number_exist);
          } else {
            toast.error(`${one?.path[0]}- ${one.message}`);
          }
        });
      } else if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
      }
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  /// Executed after category is created to select the newly crested category
  const categoryCreateSuccess = (res) => {
    fetchCategoryOptions();
    if (res?.data?.data?.attributes?.name) {
      setData({
        ...data,
        category: res?.data?.data?.id,
      });
    }
  };

  return (
    <>
      <div
        style={{ maxWidth: `${modalWidth}px` }}
        clearing
        className={`aside-modal procedure ${toggled ? "active" : ""} ${
          fromPO ? "po" : ""
        }`}
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between  align-center">
              <div className="name">
                {fromPO ? t.parts.create_part : t.parts.form.add_part}
              </div>
              <div className="flexbox align-center workorder-header-buttons">
                <span
                  className="procedure-header-button c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en" ? "left center" : "right center"
                    }
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    arrowAlign="end"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
              {/* <div>
              <Icon
                name="close"
                size="large"
                title="Close"
                onClick={() =>
                  initChange ? toggleCancelModal(true) : cancelHandler()
                }
              />
            </div> */}
            </div>
          </div>
          <div className="body" style={{
                height: "90vh",
                overflowX: "hidden",
                overflowY: "scroll",
                paddingInline: "20px" 
              }} >
            {buttonStatus == 1 ? (
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
            ) : (
              ""
            )}
            <Form.Field>
              <label className="label-item">
                {t.parts.form.name}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.parts.form.add_name}
                value={data.name}
                onChange={onValuesChange("name")}
                error={validation.name.error && validation.name.skipped}
                onBlur={(e) => {
                  if (validation.name.pattern(data.name)) {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.name.error && validation.name.skipped ? (
                <div className="label-error">{validation.name.message}</div>
              ) : (
                ""
              )}
            </Form.Field>
            <Form.Field>
              <FileUpload
                onFileChange={onFileChange}
                name="image"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>
            <Form style={{ marginBottom: "20px" }}>
              <Form.Field>
                <label
                  className={`label-item ${
                    attachments?.image?.name ? "mt-5" : "mt-7"
                  }`}
                >
                  {t.parts.form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange("description")}
                  rows={4}
                  fluid
                  placeholder={t.customers.form.add_description}
                />
              </Form.Field>
            </Form>
            <Form.Field>
              <label className="label-item">{t.parts.form.category}</label>
              <div className="d-flex">
                <Dropdown
                  selection
                  noResultsMessage={t.common.no_results_found}
                  clearable
                  search
                  fluid
                  options={options.categories}
                  placeholder={t.parts.form.select_category}
                  value={data.category}
                  onChange={onValuesChange("category")}
                  onOpen={fetchCategoryOptions}
                />
                {userPermissions?.category?.create &&
                userPermissions?.category?.view ? (
                  //  (
                  //   <Button
                  //     icon
                  //     primary
                  //     title="Auto-Gen"
                  //     onClick={() => {
                  //       setOpenCategoryAddModal(true);
                  //     }}
                  //   >
                  //     <Icon name="add" />
                  //   </Button>
                  // )

                  <span
                    className="ml-5 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                    onClick={() => {
                      setOpenCategoryAddModal(true);
                    }}
                    style={{ backgroundColor: "#E9F2FF" }}
                  >
                    <span className="">
                      {" "}
                      <AddIcon />
                    </span>
                  </span>
                ) : null}
              </div>
            </Form.Field>
            {/* <Form.Field>
              <label className="label-item">
                {t.parts.form.unit_cost}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                type="number"
                min="0"
                fluid
                placeholder={t.parts.form.enter_unit_cost}
                value={data.unitCost}
                onChange={onValuesChange("unitCost")}
                onKeyDown={(evt) => handleKeyDown(evt)}
                error={validation.unitCost.error && validation.unitCost.skipped}
                onBlur={(e) => {
                  const unitCostValidation = validation.unitCost.pattern(
                    data.unitCost
                  );
                  if (unitCostValidation?.isValid) {
                    setValidation({
                      ...validation,
                      unitCost: {
                        ...validation.unitCost,
                        error: false,
                        skipped: true,
                        message: "",
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      unitCost: {
                        ...validation.unitCost,
                        error: true,
                        skipped: true,
                        message: unitCostValidation.message,
                      },
                    });
                  }
                }}
              >
                <input />
                <span
                  className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                    selectedLanguage == "en" ? "ml-5" : "mr-5"
                  }`}
                  style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                >
                  {organisationCurrency}
                </span>
              </Input>
              {validation.unitCost.error && validation.unitCost.skipped ? (
                <div className="label-error">{validation.unitCost.message}</div>
              ) : (
                ""
              )}
            </Form.Field> */}
            <Form.Field className="wraper-drop-masure">
              <label className="label-item ">
                {t.parts.form.unit_type}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.parts.form.select_cost_type}
                noResultsMessage={t.common.no_results_found}
                fluid
                search
                clearable
                onChange={(e, { value }) => {
                  if (!value) {
                    
                    // Reset selected option on clear
                    onValuesChange2("costPer", '');
                   
                    setValidation({
                      ...validation,
                      costPer: {
                        ...validation.costPer,
                        error: true,
                        skipped: true,
                      },
                    });
                  } else {
                    console.log("first")
                    onValuesChange2("costPer", value);
                  }
                }}
                selection
                value={data?.costPer}
                options={options.allQtyOptions}
                error={validation.costPer.error && validation.costPer.skipped}
                onBlur={(e) => {
                  if (validation.costPer.pattern(data.costPer)) {
                    setValidation({
                      ...validation,
                      costPer: {
                        ...validation.costPer,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      costPer: {
                        ...validation.costPer,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              >
                <DropdownMenu>
                  <DropdownHeader content={t.parts.qty_unit.qty_title} />
                  {options.costPerQtyUnit.map((option) => (
                    <DropdownItem
                      onClick={() => {
                        onValuesChange2("costPer", option.value);
                      }}
                      key={option.value}
                      {...option}
                    />
                  ))}
                  <DropdownHeader content={t.parts.vol_unit.vol_title} />
                  {options.costPerVolUnit.map((option) => (
                    <DropdownItem
                      onClick={() => {
                        onValuesChange2("costPer", option.value);
                      }}
                      key={option.value}
                      {...option}
                    />
                  ))}
                  <DropdownHeader content={t.parts.mass_unit.mass_title} />
                  {options.costPerMassUnit.map((option) => (
                    <DropdownItem
                      onClick={() => {
                        onValuesChange2("costPer", option.value);
                      }}
                      key={option.value}
                      {...option}
                    />
                  ))}
                </DropdownMenu>
              </Dropdown>
              {validation.costPer.error && validation.costPer.skipped ? (
                <div className="label-error">{validation.costPer.message}</div>
              ) : (
                ""
              )}
            </Form.Field>
            {/* <Form.Field>
              <label className="label-item parts-minimum-qty">
                {t.parts.form.minimum_qty}{" "}
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseEnter={() => setMinQtyTooltip(true)}
                  onMouseLeave={() => setMinQtyTooltip(false)}
                >
                  {showMinQtyTooltip ? (
                    <>
                      <InfoIconBlue />
                      <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="right top"
                        show={showMinQtyTooltip}
                        textBoxWidth="105px"
                        arrowAlign="center"
                        fontSize="9px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == "ar"
                            ? "Noto Kufi Arabic"
                            : "Roboto"
                        }
                        padding="4px 14px 4px 7px"
                        lineHeight="16px"
                        borderRadius="6px"
                        static
                        moveDown="0px"
                      >
                        <span className="work-order-preview-tooltip parts">
                          {t.parts.form.min_qty_tooltip}
                        </span>
                      </ToolTip>
                    </>
                  ) : (
                    <InfoIcon />
                  )}
                </span>
              </label>
              <Input
                type="number"
                fluid
                onKeyDown={(evt) => handleKeyDown(evt)}
                error={
                  validation.minimumQuantity.error &&
                  validation.minimumQuantity.skipped
                }
                autoComplete="new-password"
                placeholder={t.parts.form.enter_minimum_qty}
                value={data?.minimumQuantity ? data?.minimumQuantity : ""}
                onChange={(e) => {
                  let valueData = Number(e.target.value);
                  const isMaxQtyValid =
                    data.maximumQuantity !== null &&
                    !/^(\d{1,8})(\.\d{1,4})?$/.test(data?.maximumQuantity);
                  if (valueData) {
                   
                    setData({
                      ...data,
                      minimumQuantity: valueData,
                    });
                    if (!/^(\d{1,8})(\.\d{1,4})?$/.test(valueData)) {
                      console.log("isMaxQtyValid: ", isMaxQtyValid);
                      if (isMaxQtyValid) {
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: true, 
                            skipped: true,
                            message: t.common.valid_digits, 
                          },
                          maximumQuantity: {
                            ...prevValidation.maximumQuantity,
                            error: true, // Reset error to false
                            skipped: true, // Reset skipped to false
                            message: t.common.valid_digits,
                          },
                        }));
                      } else {
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: true, // Reset error to false
                            skipped: true,
                            message: t.common.valid_digits, // Reset skipped to false
                          },
                     
                        }));
                      }
                    }
                    // Check if maximum quantity is provided
                    else if (
                      data.maximumQuantity !== null &&
                      valueData >= data.maximumQuantity
                    ) {
                    
                      setValidation((prevValidation) => ({
                        ...prevValidation,
                        minimumQuantity: {
                          ...prevValidation.minimumQuantity,
                          error: true, // Set error to true
                          skipped: true, // Set skipped to true
                          message: t.parts.minQty_valid,
                        },
                        maximumQuantity: {
                          ...prevValidation.maximumQuantity,
                          error: false, // Set error to true
                          skipped: false, // Set skipped to true
                        },
                      }));
                    } else {
                      if (isMaxQtyValid) {
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          maximumQuantity: {
                            ...prevValidation.maximumQuantity,
                            error: true, // Reset error to false
                            skipped: true, // Reset skipped to false
                            message: t.common.valid_digits,
                          },
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: false, // Reset error to false
                            skipped: false, // Reset skipped to false
                          },
                        }));
                      } else {
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: false, // Reset error to false
                            skipped: false, // Reset skipped to false
                          },
                          maximumQuantity: {
                            ...prevValidation.maximumQuantity,
                            error: false, // Reset error to false
                            skipped: false, // Reset skipped to false
                          },
                        }));
                      }
                    
                    }
                  } else {
                   
                    if (isMaxQtyValid) {
                      setValidation((prevValidation) => ({
                        ...prevValidation,
                        maximumQuantity: {
                          ...prevValidation.maximumQuantity,
                          error: true, // Reset error to false
                          skipped: true, // Reset skipped to false
                          message: t.common.valid_digits,
                        },
                        minimumQuantity: {
                          ...prevValidation.minimumQuantity,
                          error: false, // Reset error to false
                          skipped: false, // Reset skipped to false
                        },
                      }));
                    } else {
                      setValidation((prevValidation) => ({
                        ...prevValidation,
                        minimumQuantity: {
                          ...prevValidation.minimumQuantity,
                          error: false, // Reset error to false
                          skipped: false, // Reset skipped to false
                        },
                        maximumQuantity: {
                          ...prevValidation.maximumQuantity,
                          error: false, // Reset error to false
                          skipped: false, // Reset skipped to false
                        },
                      }));
                    }

                 
                    setData({
                      ...data,
                      minimumQuantity: null,
                    });
                  }
                }}
               
              ></Input>
              {validation.minimumQuantity.error &&
              validation.minimumQuantity.skipped ? (
                <div className="label-error">
                  {validation.minimumQuantity.message}
                </div>
              ) : (
                ""
              )}

              
            </Form.Field>
            <Form.Field>
              <label className="label-item">
                {t.parts.form.miximum_qty}{" "}
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseEnter={() => setMaxQtyTooltip(true)}
                  onMouseLeave={() => setMaxQtyTooltip(false)}
                >
                  {showMaxQtyTooltip ? (
                    <>
                      <InfoIconBlue />
                      <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="right top"
                        show={showMaxQtyTooltip}
                        textBoxWidth="105px"
                        arrowAlign="center"
                        fontSize="9px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == "ar"
                            ? "Noto Kufi Arabic"
                            : "Roboto"
                        }
                        padding="4px 14px 4px 7px"
                        lineHeight="16px"
                        borderRadius="6px"
                        static
                        moveDown="0px"
                      >
                        <span className="work-order-preview-tooltip parts">
                          {t.parts.form.max_qty_tooltip}
                        </span>
                      </ToolTip>
                    </>
                  ) : (
                    <InfoIcon />
                  )}
                </span>
              </label>
              <div className="">
                <Input
                  type="number"
                  min={1}
                  onKeyDown={(evt) => handleKeyDown(evt)}
                  autoComplete="new-password"
                  fluid
                  error={
                    validation.maximumQuantity.error &&
                    validation.maximumQuantity.skipped
                  }
                  placeholder={t.parts.form.enter_miximum_qty}
                  value={data?.maximumQuantity ? data?.maximumQuantity : ""}
                  onChange={(e) => {
                    let valueData = Number(e.target.value);
                    const isMaxQtyValid =
                      data.minimumQuantity !== null &&
                      !/^(\d{1,8})(\.\d{1,4})?$/.test(data?.minimumQuantity);
                    if (valueData) {
                      // Update the minimum quantity in the form data
                      setData({
                        ...data,
                        maximumQuantity: valueData,
                      });

                      if (!/^(\d{1,8})(\.\d{1,4})?$/.test(valueData)) {
                        console.log("isMaxQtyValid: ", isMaxQtyValid);
                        if (isMaxQtyValid) {
                          setValidation((prevValidation) => ({
                            ...prevValidation,
                            minimumQuantity: {
                              ...prevValidation.minimumQuantity,
                              error: true, // Reset error to false
                              skipped: true,
                              message: t.common.valid_digits, // Reset skipped to false
                            },
                            maximumQuantity: {
                              ...prevValidation.maximumQuantity,
                              error: true, // Reset error to false
                              skipped: true, // Reset skipped to false
                              message: t.common.valid_digits,
                            },
                          }));
                        } else {
                          setValidation((prevValidation) => ({
                            ...prevValidation,
                            maximumQuantity: {
                              ...prevValidation.maximumQuantity,
                              error: true, // Reset error to false
                              skipped: true,
                              message: t.common.valid_digits, // Reset skipped to false
                            },
                         
                          }));
                        }
                      }

                     
                      else if (
                        data.minimumQuantity !== null &&
                        valueData <= data.minimumQuantity
                      ) {
                     
                     
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          maximumQuantity: {
                            ...prevValidation.maximumQuantity,
                            error: true, // Set error to true
                            skipped: true,
                            message: t.parts.maxQty_valid, // Set skipped to true
                          },
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: false, // Set error to true
                            skipped: false, // Set skipped to true
                          },
                        }));
                      } else {
                        console.log("inside else");
                      
                        if (isMaxQtyValid) {
                          setValidation((prevValidation) => ({
                            ...prevValidation,
                            minimumQuantity: {
                              ...prevValidation.minimumQuantity,
                              error: true, // Reset error to false
                              skipped: true, // Reset skipped to false
                              message: t.common.valid_digits,
                            },
                            maximumQuantity: {
                              ...prevValidation.maximumQuantity,
                              error: false, // Reset error to false
                              skipped: false, // Reset skipped to false
                            },
                          }));
                        } else {
                          setValidation((prevValidation) => ({
                            ...prevValidation,
                            minimumQuantity: {
                              ...prevValidation.minimumQuantity,
                              error: false, // Reset error to false
                              skipped: false, // Reset skipped to false
                            },
                            maximumQuantity: {
                              ...prevValidation.maximumQuantity,
                              error: false, // Reset error to false
                              skipped: false, // Reset skipped to false
                            },
                          }));
                        }
                      }
                    } else {
                    
                      if (isMaxQtyValid) {
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: true, // Reset error to false
                            skipped: true, // Reset skipped to false
                            message: t.common.valid_digits,
                          },
                          maximumQuantity: {
                            ...prevValidation.maximumQuantity,
                            error: false, // Reset error to false
                            skipped: false, // Reset skipped to false
                          },
                        }));
                      } else {
                        setValidation((prevValidation) => ({
                          ...prevValidation,
                          minimumQuantity: {
                            ...prevValidation.minimumQuantity,
                            error: false, // Reset error to false
                            skipped: false, // Reset skipped to false
                          },
                          maximumQuantity: {
                            ...prevValidation.maximumQuantity,
                            error: false, // Reset error to false
                            skipped: false, // Reset skipped to false
                          },
                        }));
                      }

                     
                      setData({
                        ...data,
                        maximumQuantity: null,
                      });
                    }
                  }}
                >
                  <input />
                </Input>
              </div>
              {validation.maximumQuantity.error &&
              validation.maximumQuantity.skipped ? (
                <div className="label-error">
                  {validation.maximumQuantity.message}
                </div>
              ) : (
                ""
              )}
            </Form.Field> */}
            <Form.Field>
              <label className="label-item">
                {t.parts.filter.partType}
                <Asterisk color="#172B4D" />
                {/* <Icon name="asterisk" color="red" size="small" /> */}
              </label>{" "}
              {/* {validation.vendor.error && validation.vendor.skipped ? (
                  <div className="label-error">{validation.vendor.message}</div>
                ) : (
                  ''
                )} */}
              <Dropdown
                placeholder={t.parts.form.select_part}
                noResultsMessage={t.common.no_results_found}
                fluid
                search
                clearable
                selection
                options={options.partType}
                value={data.partType}
                onChange={onValuesChange("partType")}
                error={validation.partType.error && validation.partType.skipped}
                onBlur={(e) => {
                  if (validation.partType.pattern(data.partType)) {
                    setValidation({
                      ...validation,
                      partType: {
                        ...validation.partType,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      partType: {
                        ...validation.partType,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
                // onOpen={() => fetchAssets()}
                // error={validation.asset.error && validation.asset.skipped}
                // onBlur={(e) => {
                //   if (validation.asset.pattern(data.asset)) {
                //     setValidation({
                //       ...validation,
                //       asset: {
                //         ...validation.asset,
                //         error: false,
                //         skipped: true,
                //       },
                //     });
                //   } else {
                //     setValidation({
                //       ...validation,
                //       asset: {
                //         ...validation.asset,
                //         error: true,
                //         skipped: true,
                //       },
                //     });
                //   }
                // }}
              />
              {validation.partType.error && validation.partType.skipped ? (
                <div className="label-error">{validation.partType.message}</div>
              ) : (
                ""
              )}
            </Form.Field>
            {/* <Form.Field> */}
            <Checkbox
              value={data.isNonStock}
              label={t.parts.form.non_stock}
              onChange={onValuesChange("isNonStock")}
            />
            <Divider />
            <div className="billing-info mt-6  d-flex">
              <InventoryIcon />
              <p
                style={{ fontSize: "16px", lineHeight: "24px" }}
                className={`required-text ${
                  selectedLanguage == "en" ? "ml-2" : "mr-2"
                }`}
              >
                {t.parts.form.store_qty}
              </p>
            </div>
            {data.quantityPerStore.map((one, index) => {
              return (
                <>
                  <div className="store-details">
                    <Segment className="p-6 mt-6">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label className={`label-item `}>
                            {t.parts.form.inv_loc}
                            <Asterisk color="#172B4D" />
                          </label>
                        </div>

                        {data?.quantityPerStore?.length == 1 ? null : (
                          <div onClick={removeThisData(index)} className="mb-2">
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                      <Dropdown
                        placeholder={t.assets.form.select_location}
                        noResultsMessage={t.common.no_results_found}
                        fluid
                        clearable
                        className="loc-dropdown"
                        selection
                        disabled={initLoading}
                        search
                        // options={options.location[index].option}
                        options={options?.location[index]?.option}
                        onOpen={()=>fetchLocationOption(one?.location, index)}
                        value={one?.location}
                        // onChange={(e, value) =>
                        //   handleLocationChange(e, index, value)
                        // }

                        onChange={(e, value) => {
                          if (value) {
                            let areaData = [...data.quantityPerStore];
                            areaData[index].location = value.value;

                            // let loc_options = [...options.locations];
                            // const updatedLocations = loc_options.filter(
                            //   (location) => location?.value !== value?.value
                            // );

                            // let updated_loc = loc_options?.map((location) => {
                            //   if (location?.value === value?.value) {
                            //     // Disable the matching location
                            //     return { ...location, disabled: true };
                            //   }
                            //   return location;
                            // });
                            // areaData[index].area = "";

                            setData({
                              ...data,
                              quantityPerStore: areaData,
                              // vendor: [],
                              // asset: [],
                              // people: [],
                              // teams: "",
                            });

                            // setOptions((prev) => {
                            //   return {
                            //     ...prev,
                            //     locations: updated_loc,
                            //   };
                            // });

                            // fetchArea(value.value, index);
                            setValidation((prevValidation) => ({
                              ...prevValidation,
                              quantityPerStore: prevValidation.quantityPerStore.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      location: {
                                        ...item.location,
                                        error: false,
                                        skipped: true,
                                      },
                                    };
                                  }
                                  return item;
                                }
                              ),
                            }));
                          } else if (!value) {
                            let areaData = [...data.quantityPerStore];
                            areaData[index].location = "";

                            setData({
                              ...data,
                              quantityPerStore: areaData,
                              // vendor: [],
                              // asset: [],
                              // people: [],
                              // teams: "",
                            });

                            // setData({
                            //   ...data,
                            //   quantityPerStore: areaData,
                            //   vendor: [],
                            //   asset: [],
                            //   people: [],
                            //   teams: "",
                            // });

                            setValidation((prevValidation) => ({
                              ...prevValidation,
                              quantityPerStore: prevValidation.quantityPerStore.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      location: {
                                        ...item.location,
                                        error: true,
                                        skipped: true,
                                      },
                                    };
                                  }
                                  return item;
                                }
                              ),
                            }));
                          }
                        }}
                        // onOpen={fetchLocations}
                        error={
                          validation.quantityPerStore[index].location.error &&
                          validation.quantityPerStore[index].location.skipped
                        }
                        onBlur={(e) => {
                          if (
                            validation.quantityPerStore[index].location.pattern(
                              one.location
                            )
                          ) {
                            setValidation((prevValidation) => ({
                              ...prevValidation,
                              quantityPerStore: prevValidation.quantityPerStore.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      location: {
                                        ...item.location,
                                        error: false,
                                        skipped: true,
                                      },
                                    };
                                  }
                                  return item;
                                }
                              ),
                            }));
                          } else {
                            setValidation((prevValidation) => ({
                              ...prevValidation,
                              quantityPerStore: prevValidation.quantityPerStore.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      location: {
                                        ...item.location,
                                        error: true,
                                        skipped: true,
                                      },
                                    };
                                  }
                                  return item;
                                }
                              ),
                            }));
                          }
                        }}
                      />
                      {validation.quantityPerStore[index].location.error &&
                      validation.quantityPerStore[index].location.skipped ? (
                        <div className="label-error">
                          {validation.quantityPerStore[index].location.message}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <label className={`label-item mt-5`}>
                        {t.parts.filter.area}
                        <Asterisk color="#172B4D" />
                      </label>
                      <Dropdown
                        placeholder={t.parts.form.add_area}
                        noResultsMessage={t.common.no_results_found}
                        fluid
                        selection
                        search
                        options={options?.area[index]?.option}
                        value={one?.area}
                        // onChange={(e, value) =>
                        //   handleLocationChange(e, index, value)
                        // }

                        onChange={(e, value) => {
                          if (value) {
                            let areaData = [...data.quantityPerStore];
                            areaData[index].area = value.value;
                            setData({
                              ...data,
                              quantityPerStore: areaData,
                            });
                            setValidation((prevValidation) => ({
                              ...prevValidation,
                              quantityPerStore: prevValidation.quantityPerStore.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      area: {
                                        ...item.area,
                                        error: false,
                                        skipped: true,
                                      },
                                    };
                                  }
                                  return item;
                                }
                              ),
                            }));
                          } else if (!value) {
                            let areaData = [...data.quantityPerStore];
                            areaData[index].area = "";

                            setData({
                              ...data,
                              quantityPerStore: areaData,
                            });
                            setValidation((prevValidation) => ({
                              ...prevValidation,
                              quantityPerStore: prevValidation.quantityPerStore.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      area: {
                                        ...item.area,
                                        error: true,
                                        skipped: true,
                                      },
                                    };
                                  }
                                  return item;
                                }
                              ),
                            }));
                          }
                        }}
                        // onOpen={fetchLocations}
                        error={
                          validation.quantityPerStore[index].area.error &&
                          validation.quantityPerStore[index].area.skipped
                        }
                        onBlur={(e) => {
                          if (
                            validation.quantityPerStore[index].area.pattern(
                              one.area
                            )
                          ) {
                            setValidation((prevValidation) => ({
                              ...prevValidation,
                              quantityPerStore: prevValidation.quantityPerStore.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      area: {
                                        ...item.area,
                                        error: false,
                                        skipped: true,
                                      },
                                    };
                                  }
                                  return item;
                                }
                              ),
                            }));
                          } else {
                            setValidation((prevValidation) => ({
                              ...prevValidation,
                              quantityPerStore: prevValidation.quantityPerStore.map(
                                (item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      area: {
                                        ...item.area,
                                        error: true,
                                        skipped: true,
                                      },
                                    };
                                  }
                                  return item;
                                }
                              ),
                            }));
                          }
                        }}
                      />
                      {validation.quantityPerStore[index].area.error &&
                      validation.quantityPerStore[index].area.skipped ? (
                        <div className="label-error">
                          {validation.quantityPerStore[index].area.message}
                        </div>
                      ) : (
                        ""
                      )} */}
                      <Form.Field style={{ minHeight: "58px" }}>
                        <label className="label-item mt-5">
                          {t.parts.table.quantity}
                          {/* {`(${t.parts.form.pre_unit})`} */}
                          <Asterisk color="#172B4D" />{" "}
                          <span
                            className="c-pointer"
                            style={{ position: "relative" }}
                            onMouseEnter={() =>
                              setQtyTooltip({
                                ...showQtyTooltip,
                                [index]: true,
                              })
                            }
                            onMouseLeave={() =>
                              setQtyTooltip({
                                ...showQtyTooltip,
                                [index]: false,
                              })
                            }
                          >
                            {showQtyTooltip[index] ? (
                              <>
                                <InfoIconBlue />
                                <ToolTip
                                  color="#fff"
                                  backgroundColor="#172B4D"
                                  position={
                                    selectedLanguage == "ar"
                                      ? "left top"
                                      : "right top"
                                  }
                                  show={showQtyTooltip[index]}
                                  textBoxWidth="130px"
                                  arrowAlign="center"
                                  fontSize="9px"
                                  fontWeight="400"
                                  fontFamily={
                                    selectedLanguage == "ar"
                                      ? "Noto Kufi Arabic"
                                      : "Roboto"
                                  }
                                  padding="4px 14px 4px 7px"
                                  lineHeight="16px"
                                  borderRadius="6px"
                                  static
                                  moveDown="0px"
                                >
                                  <span className="work-order-preview-tooltip parts">
                                    {t.parts.form.qty_tooltip}
                                  </span>
                                </ToolTip>
                              </>
                            ) : (
                              <InfoIcon />
                            )}
                          </span>
                        </label>
                        <Input
                          type="number"
                          fluid
                          onKeyDown={(evt) => handleKeyDown(evt)}
                          onWheel={(e) => e.target.blur()}
                          autoComplete="new-password"
                          placeholder={t.parts.form.enter_qty}
                          value={one.quantity}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            // const value = Number(e.target.value).toFixed(4);
                            let areaData = [...data.quantityPerStore];
                            if (value) {
                              areaData[index].quantity = Number(
                                value.toFixed(4)
                              );

                              // if (areaData[index].unitCost) {
                              //   areaData[index].totalCost =
                              //     Number(value) *
                              //     Number(areaData[index].unitCost);
                              // }
                              setData({
                                ...data,
                                quantityPerStore: areaData,
                              });
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        quantity: {
                                          ...item.quantity,
                                          error: false,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            } else if (!value) {
                              if (
                                e.target.value == 0 &&
                                e.target.value !== ""
                              ) {
                                areaData[index].quantity = 0;
                              } else {
                                areaData[index].quantity = null;
                              }
                              setData({
                                ...data,
                                quantityPerStore: areaData,
                              });

                              if (value === null) {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          quantity: {
                                            ...item.quantity,
                                            error: true,
                                            skipped: true,
                                            message: t.common.required_field,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              } else {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          quantity: {
                                            ...item.quantity,
                                            error: false,
                                            skipped: false,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              }
                            }
                          }}
                          error={
                            validation.quantityPerStore[index].quantity.error &&
                            validation.quantityPerStore[index].quantity.skipped
                          }
                          onBlur={(e) => {
                            const value = e.target.value;

                            if (
                              validation.quantityPerStore[
                                index
                              ].quantity.pattern(value)
                            ) {
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        quantity: {
                                          ...item.quantity,
                                          error: false,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            } else {
                              let errorMessage;
                              if (!value) {
                                errorMessage = t.common.required_field;
                              } else {
                                errorMessage = t.common.valid_digits;
                              }
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        quantity: {
                                          ...item.quantity,
                                          error: true,
                                          skipped: true,
                                          message: errorMessage,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            }
                          }}
                        />
                        {validation.quantityPerStore[index].quantity.error &&
                        validation.quantityPerStore[index].quantity.skipped ? (
                          <div className="label-error">
                            {
                              validation.quantityPerStore[index].quantity
                                .message
                            }
                          </div>
                        ) : (
                          ""
                        )}
                      </Form.Field>{" "}
                    
                      <Form.Field style={{ minHeight: "58px" }}>
                        <label className="label-item parts-minimum-qty mt-5">
                          {t.parts.form.minimum_qty}{" "}
                          <span
                            className="c-pointer"
                            style={{ position: "relative" }}
                            onMouseEnter={() =>
                              setMinQtyTooltip({
                                ...showQtyTooltip,
                                [index]: true,
                              })
                            }
                            onMouseLeave={() =>
                              setMinQtyTooltip({
                                ...showQtyTooltip,
                                [index]: false,
                              })
                            }
                            // onMouseEnter={() => setMinQtyTooltip(true)}
                            // onMouseLeave={() => setMinQtyTooltip(false)}
                          >
                            {showMinQtyTooltip[index] ? (
                              <>
                                <InfoIconBlue />
                                <ToolTip
                                  color="#fff"
                                  backgroundColor="#172B4D"
                                  position="right top"
                                  show={showMinQtyTooltip[index]}
                                  textBoxWidth="105px"
                                  arrowAlign="center"
                                  fontSize="9px"
                                  fontWeight="400"
                                  fontFamily={
                                    selectedLanguage == "ar"
                                      ? "Noto Kufi Arabic"
                                      : "Roboto"
                                  }
                                  padding="4px 14px 4px 7px"
                                  lineHeight="16px"
                                  borderRadius="6px"
                                  static
                                  moveDown="0px"
                                >
                                  <span className="work-order-preview-tooltip parts">
                                    {t.parts.form.min_qty_tooltip}
                                  </span>
                                </ToolTip>
                              </>
                            ) : (
                              <InfoIcon />
                            )}
                          </span>
                        </label>
                        <Input
                          type="number"
                          fluid
                          onKeyDown={(evt) => handleKeyDown(evt)}
                          error={
                            validation.quantityPerStore[index].minimumQuantity
                              .error &&
                            validation.quantityPerStore[index].minimumQuantity
                              .skipped
                          }
                          // error={
                          //   validation.minimumQuantity.error &&
                          //   validation.minimumQuantity.skipped
                          // }
                          autoComplete="new-password"
                          placeholder={t.parts.form.enter_minimum_qty}
                          value={
                            one?.minimumQuantity ? one?.minimumQuantity : ""
                          }
                          onChange={(e) => {
                            let valueData = Number(e.target.value);
                            const isMaxQtyValid =
                              one?.maximumQuantity !== null &&
                              !/^(\d{1,8})(\.\d{1,4})?$/.test(
                                one?.maximumQuantity
                              );
                            if (valueData) {
                              let areaData = [...data.quantityPerStore];

                              areaData[index].minimumQuantity = valueData;
                              setData({
                                ...data,
                                quantityPerStore: areaData,
                              });
                              if (!/^(\d{1,8})(\.\d{1,4})?$/.test(valueData)) {
                                if (isMaxQtyValid) {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: true,
                                              skipped: true,
                                              message: t.common.valid_digits,
                                            },
                                            maximumQuantity: {
                                              ...item.maximumQuantity,
                                              error: true, // Reset error to false
                                              skipped: true, // Reset skipped to false
                                              message: t.common.valid_digits,
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));
                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: true,
                                  //     skipped: true,
                                  //     message: t.common.valid_digits,
                                  //   },
                                  //   maximumQuantity: {
                                  //     ...prevValidation.maximumQuantity,
                                  //     error: true, // Reset error to false
                                  //     skipped: true, // Reset skipped to false
                                  //     message: t.common.valid_digits,
                                  //   },
                                  // }));
                                } else {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: true, // Reset error to false
                                              skipped: true,
                                              message: t.common.valid_digits, // Reset skipped to false
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));
                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: true, // Reset error to false
                                  //     skipped: true,
                                  //     message: t.common.valid_digits, // Reset skipped to false
                                  //   },

                                  // }));
                                }
                              }
                              // Check if maximum quantity is provided
                              else if (
                                one.maximumQuantity !== null &&
                                valueData >= one.maximumQuantity
                              ) {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          minimumQuantity: {
                                            ...item.minimumQuantity,
                                            error: true, // Set error to true
                                            skipped: true, // Set skipped to true
                                            message: t.parts.minQty_valid,
                                          },
                                          maximumQuantity: {
                                            ...item.maximumQuantity,
                                            error: false, // Set error to true
                                            skipped: false, // Set skipped to true
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));

                                // setValidation((prevValidation) => ({
                                //   ...prevValidation,
                                //   minimumQuantity: {
                                //     ...prevValidation.minimumQuantity,
                                //     error: true, // Set error to true
                                //     skipped: true, // Set skipped to true
                                //     message: t.parts.minQty_valid,
                                //   },
                                //   maximumQuantity: {
                                //     ...prevValidation.maximumQuantity,
                                //     error: false, // Set error to true
                                //     skipped: false, // Set skipped to true
                                //   },
                                // }));
                              } else {
                                if (isMaxQtyValid) {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            maximumQuantity: {
                                              ...item.maximumQuantity,
                                              error: true, // Reset error to false
                                              skipped: true, // Reset skipped to false
                                              message: t.common.valid_digits,
                                            },
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: false, // Reset error to false
                                              skipped: false, // Reset skipped to false
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));
                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   maximumQuantity: {
                                  //     ...prevValidation.maximumQuantity,
                                  //     error: true, // Reset error to false
                                  //     skipped: true, // Reset skipped to false
                                  //     message: t.common.valid_digits,
                                  //   },
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: false, // Reset error to false
                                  //     skipped: false, // Reset skipped to false
                                  //   },
                                  // }));
                                } else {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: false, // Reset error to false
                                              skipped: false, // Reset skipped to false
                                            },
                                            maximumQuantity: {
                                              ...item.maximumQuantity,
                                              error: false, // Reset error to false
                                              skipped: false, // Reset skipped to false
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));
                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: false, // Reset error to false
                                  //     skipped: false, // Reset skipped to false
                                  //   },
                                  //   maximumQuantity: {
                                  //     ...prevValidation.maximumQuantity,
                                  //     error: false, // Reset error to false
                                  //     skipped: false, // Reset skipped to false
                                  //   },
                                  // }));
                                }
                              }
                            } else {
                              if (isMaxQtyValid) {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          maximumQuantity: {
                                            ...item.maximumQuantity,
                                            error: true, // Reset error to false
                                            skipped: true, // Reset skipped to false
                                            message: t.common.valid_digits,
                                          },
                                          minimumQuantity: {
                                            ...item.minimumQuantity,
                                            error: false, // Reset error to false
                                            skipped: false, // Reset skipped to false
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                                // setValidation((prevValidation) => ({
                                //   ...prevValidation,
                                //   maximumQuantity: {
                                //     ...prevValidation.maximumQuantity,
                                //     error: true, // Reset error to false
                                //     skipped: true, // Reset skipped to false
                                //     message: t.common.valid_digits,
                                //   },
                                //   minimumQuantity: {
                                //     ...prevValidation.minimumQuantity,
                                //     error: false, // Reset error to false
                                //     skipped: false, // Reset skipped to false
                                //   },
                                // }));
                              } else {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          minimumQuantity: {
                                            ...item.minimumQuantity,
                                            error: false, // Reset error to false
                                            skipped: false, // Reset skipped to false
                                          },
                                          maximumQuantity: {
                                            ...item.maximumQuantity,
                                            error: false, // Reset error to false
                                            skipped: false, // Reset skipped to false
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));

                                // setValidation((prevValidation) => ({
                                //   ...prevValidation,
                                //   minimumQuantity: {
                                //     ...prevValidation.minimumQuantity,
                                //     error: false, // Reset error to false
                                //     skipped: false, // Reset skipped to false
                                //   },
                                //   maximumQuantity: {
                                //     ...prevValidation.maximumQuantity,
                                //     error: false, // Reset error to false
                                //     skipped: false, // Reset skipped to false
                                //   },
                                // }));
                              }
                              let areaDataFinal = [...data.quantityPerStore];
                              areaDataFinal[index].minimumQuantity = null;
                              setData({
                                ...data,
                                quantityPerStore: areaDataFinal,
                              });
                            }
                          }}
                        ></Input>
                        {validation.quantityPerStore[index].minimumQuantity
                          .error &&
                        validation.quantityPerStore[index].minimumQuantity
                          .skipped ? (
                          <div className="label-error">
                            {
                              validation.quantityPerStore[index].minimumQuantity
                                .message
                            }
                          </div>
                        ) : (
                          ""
                        )}
                      </Form.Field>
                      <Form.Field style={{ minHeight: "58px" }}>
                        <label className="label-item mt-5">
                          {t.parts.form.miximum_qty}{" "}
                          <span
                            className="c-pointer"
                            style={{ position: "relative" }}
                            onMouseEnter={() =>
                              setMaxQtyTooltip({
                                ...showQtyTooltip,
                                [index]: true,
                              })
                            }
                            onMouseLeave={() =>
                              setMaxQtyTooltip({
                                ...showQtyTooltip,
                                [index]: false,
                              })
                            }
                            // onMouseEnter={() => setMaxQtyTooltip(true)}
                            // onMouseLeave={() => setMaxQtyTooltip(false)}
                          >
                            {showMaxQtyTooltip[index] ? (
                              <>
                                <InfoIconBlue />
                                <ToolTip
                                  color="#fff"
                                  backgroundColor="#172B4D"
                                  position="right top"
                                  show={showMaxQtyTooltip[index]}
                                  textBoxWidth="105px"
                                  arrowAlign="center"
                                  fontSize="9px"
                                  fontWeight="400"
                                  fontFamily={
                                    selectedLanguage == "ar"
                                      ? "Noto Kufi Arabic"
                                      : "Roboto"
                                  }
                                  padding="4px 14px 4px 7px"
                                  lineHeight="16px"
                                  borderRadius="6px"
                                  static
                                  moveDown="0px"
                                >
                                  <span className="work-order-preview-tooltip parts">
                                    {t.parts.form.max_qty_tooltip}
                                  </span>
                                </ToolTip>
                              </>
                            ) : (
                              <InfoIcon />
                            )}
                          </span>
                        </label>
                        <div className="">
                          <Input
                            type="number"
                            min={1}
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            autoComplete="new-password"
                            fluid
                            error={
                              validation.quantityPerStore[index].maximumQuantity
                                .error &&
                              validation.quantityPerStore[index].maximumQuantity
                                .skipped
                            }
                            // error={
                            //   validation.maximumQuantity.error &&
                            //   validation.maximumQuantity.skipped
                            // }
                            placeholder={t.parts.form.enter_miximum_qty}
                            value={
                              one?.maximumQuantity ? one?.maximumQuantity : ""
                            }
                            onChange={(e) => {
                              let valueData = Number(e.target.value);
                              const isMaxQtyValid =
                                one?.minimumQuantity !== null &&
                                !/^(\d{1,8})(\.\d{1,4})?$/.test(
                                  one?.minimumQuantity
                                );
                              if (valueData) {
                                let areaData = [...data.quantityPerStore];

                                areaData[index].maximumQuantity = valueData;
                                // Update the minimum quantity in the form data
                                setData({
                                  ...data,
                                  quantityPerStore: areaData,
                                });

                                if (
                                  !/^(\d{1,8})(\.\d{1,4})?$/.test(valueData)
                                ) {
                                  if (isMaxQtyValid) {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: true, // Reset error to false
                                                skipped: true,
                                                message: t.common.valid_digits, // Reset skipped to false
                                              },
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: true, // Reset error to false
                                                skipped: true, // Reset skipped to false
                                                message: t.common.valid_digits,
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));
                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: true, // Reset error to false
                                    //     skipped: true,
                                    //     message: t.common.valid_digits, // Reset skipped to false
                                    //   },
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: true, // Reset error to false
                                    //     skipped: true, // Reset skipped to false
                                    //     message: t.common.valid_digits,
                                    //   },
                                    // }));
                                  } else {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: true, // Reset error to false
                                                skipped: true,
                                                message: t.common.valid_digits, // Reset skipped to false
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));
                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: true, // Reset error to false
                                    //     skipped: true,
                                    //     message: t.common.valid_digits, // Reset skipped to false
                                    //   },

                                    // }));
                                  }
                                } else if (
                                  one?.minimumQuantity !== null &&
                                  valueData <= one?.minimumQuantity
                                ) {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            maximumQuantity: {
                                              ...item.maximumQuantity,
                                              error: true, // Set error to true
                                              skipped: true,
                                              message: t.parts.maxQty_valid, // Set skipped to true
                                            },
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: false, // Set error to true
                                              skipped: false, // Set skipped to true
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));

                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   maximumQuantity: {
                                  //     ...prevValidation.maximumQuantity,
                                  //     error: true, // Set error to true
                                  //     skipped: true,
                                  //     message: t.parts.maxQty_valid, // Set skipped to true
                                  //   },
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: false, // Set error to true
                                  //     skipped: false, // Set skipped to true
                                  //   },
                                  // }));
                                } else {
                                  if (isMaxQtyValid) {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: true, // Reset error to false
                                                skipped: true, // Reset skipped to false
                                                message: t.common.valid_digits,
                                              },
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: false, // Reset error to false
                                                skipped: false, // Reset skipped to false
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));
                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: true, // Reset error to false
                                    //     skipped: true, // Reset skipped to false
                                    //     message: t.common.valid_digits,
                                    //   },
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: false, // Reset error to false
                                    //     skipped: false, // Reset skipped to false
                                    //   },
                                    // }));
                                  } else {
                                    setValidation((prevValidation) => ({
                                      ...prevValidation,
                                      quantityPerStore: prevValidation.quantityPerStore.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              minimumQuantity: {
                                                ...item.minimumQuantity,
                                                error: false, // Reset error to false
                                                skipped: false, // Reset skipped to false
                                              },
                                              maximumQuantity: {
                                                ...item.maximumQuantity,
                                                error: false, // Reset error to false
                                                skipped: false, // Reset skipped to false
                                              },
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                    }));
                                    // setValidation((prevValidation) => ({
                                    //   ...prevValidation,
                                    //   minimumQuantity: {
                                    //     ...prevValidation.minimumQuantity,
                                    //     error: false, // Reset error to false
                                    //     skipped: false, // Reset skipped to false
                                    //   },
                                    //   maximumQuantity: {
                                    //     ...prevValidation.maximumQuantity,
                                    //     error: false, // Reset error to false
                                    //     skipped: false, // Reset skipped to false
                                    //   },
                                    // }));
                                  }
                                }
                              } else {
                                if (isMaxQtyValid) {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: true, // Reset error to false
                                              skipped: true, // Reset skipped to false
                                              message: t.common.valid_digits,
                                            },
                                            maximumQuantity: {
                                              ...item.maximumQuantity,
                                              error: false, // Reset error to false
                                              skipped: false, // Reset skipped to false
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));

                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: true, // Reset error to false
                                  //     skipped: true, // Reset skipped to false
                                  //     message: t.common.valid_digits,
                                  //   },
                                  //   maximumQuantity: {
                                  //     ...prevValidation.maximumQuantity,
                                  //     error: false, // Reset error to false
                                  //     skipped: false, // Reset skipped to false
                                  //   },
                                  // }));
                                } else {
                                  setValidation((prevValidation) => ({
                                    ...prevValidation,
                                    quantityPerStore: prevValidation.quantityPerStore.map(
                                      (item, i) => {
                                        if (i === index) {
                                          return {
                                            ...item,
                                            minimumQuantity: {
                                              ...item.minimumQuantity,
                                              error: false, // Reset error to false
                                              skipped: false, // Reset skipped to false
                                            },
                                            maximumQuantity: {
                                              ...item.maximumQuantity,
                                              error: false, // Reset error to false
                                              skipped: false, // Reset skipped to false
                                            },
                                          };
                                        }
                                        return item;
                                      }
                                    ),
                                  }));

                                  // setValidation((prevValidation) => ({
                                  //   ...prevValidation,
                                  //   minimumQuantity: {
                                  //     ...prevValidation.minimumQuantity,
                                  //     error: false, // Reset error to false
                                  //     skipped: false, // Reset skipped to false
                                  //   },
                                  //   maximumQuantity: {
                                  //     ...prevValidation.maximumQuantity,
                                  //     error: false, // Reset error to false
                                  //     skipped: false, // Reset skipped to false
                                  //   },
                                  // }));
                                }
                                let areaDataFinal = [...data.quantityPerStore];
                                areaDataFinal[index].maximumQuantity = null;

                                setData({
                                  ...data,
                                  quantityPerStore: areaDataFinal,
                                });
                              }
                            }}
                          >
                            <input />
                          </Input>
                        </div>
                        {validation.quantityPerStore[index].maximumQuantity
                          .error &&
                        validation.quantityPerStore[index].maximumQuantity
                          .skipped ? (
                          <div className="label-error">
                            {
                              validation.quantityPerStore[index].maximumQuantity
                                .message
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {validation.maximumQuantity.error &&
              validation.maximumQuantity.skipped ? (
                <div className="label-error">
                  {validation.maximumQuantity.message}
                </div>
              ) : (
                ""
              )} */}
                      </Form.Field>

                      <Form.Field style={{ minHeight: "58px" }}>
                        <label className="label-item  mt-5">
                          {t.parts.form.unit_cost}
                          {/* <Asterisk color="#172B4D" /> */}
                        </label>
                        <Input
                          autoComplete="new-password"
                          type="number"
                          min="0"
                          fluid
                          placeholder={t.parts.form.enter_unit_cost}
                          value={one.unitCost}
                          // onChange={onValuesChange("unitCost")}
                          onChange={(e) => {
                            const value = Number(e.target.value);
                            // const value = Number(e.target.value).toFixed(4);
                            let areaData = [...data.quantityPerStore];
                            if (value) {
                              areaData[index].unitCost = Number(
                                value.toFixed(4)
                              );

                              // if (areaData[index].unitCost) {
                              //   areaData[index].totalCost =
                              //     Number(value) *
                              //     Number(areaData[index].unitCost);
                              // }
                              setData({
                                ...data,
                                quantityPerStore: areaData,
                              });
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        unitCost: {
                                          ...item.unitCost,
                                          error: false,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            } else if (!value) {
                              if (
                                e.target.value == 0 &&
                                e.target.value !== ""
                              ) {
                                areaData[index].unitCost = 0;
                              } else {
                                areaData[index].unitCost = null;
                              }
                              setData({
                                ...data,
                                quantityPerStore: areaData,
                              });

                              if (value === null) {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          unitCost: {
                                            ...item.unitCost,
                                            error: false,
                                            skipped: true,
                                            message: t.common.required_field,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              } else {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          unitCost: {
                                            ...item.unitCost,
                                            error: false,
                                            skipped: false,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              }
                            }
                          }}
                          onKeyDown={(evt) => handleKeyDown(evt)}
                          error={
                            validation.quantityPerStore[index].unitCost.error &&
                            validation.quantityPerStore[index].unitCost.skipped
                          }
                          // error={validation.unitCost.error && validation.unitCost.skipped}
                          onBlur={(e) => {
                            const value = e.target.value;

                            if (
                              validation.quantityPerStore[
                                index
                              ].unitCost.pattern(value)
                            ) {
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        unitCost: {
                                          ...item.unitCost,
                                          error: false,
                                          skipped: true,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            } else {
                              let errorMessage;
                              if (!value) {
                                errorMessage = t.common.required_field;
                              } else {
                                errorMessage = t.common.valid_digits;
                              }
                              setValidation((prevValidation) => ({
                                ...prevValidation,
                                quantityPerStore: prevValidation.quantityPerStore.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        unitCost: {
                                          ...item.unitCost,
                                          error: true,
                                          skipped: true,
                                          message: errorMessage,
                                        },
                                      };
                                    }
                                    return item;
                                  }
                                ),
                              }));
                            }
                          }}
                          // onBlur={(e) => {
                          //   const unitCostValidation = validation.unitCost.pattern(
                          //     data.unitCost
                          //   );
                          //   if (unitCostValidation?.isValid) {
                          //     setValidation({
                          //       ...validation,
                          //       unitCost: {
                          //         ...validation.unitCost,
                          //         error: false,
                          //         skipped: true,
                          //         message: "",
                          //       },
                          //     });
                          //   } else {
                          //     setValidation({
                          //       ...validation,
                          //       unitCost: {
                          //         ...validation.unitCost,
                          //         error: true,
                          //         skipped: true,
                          //         message: unitCostValidation.message,
                          //       },
                          //     });
                          //   }
                          // }}
                        >
                          <input />
                          <span
                            className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                              selectedLanguage == "en" ? "ml-5" : "mr-5"
                            }`}
                            style={{
                              backgroundColor: "#E9F2FF",
                              color: "#0C66E4",
                            }}
                          >
                            {organisationCurrency}
                          </span>
                        </Input>
                        {validation.quantityPerStore[index].unitCost.error &&
                        validation.quantityPerStore[index].unitCost.skipped ? (
                          <div className="label-error">
                            {
                              validation.quantityPerStore[index].unitCost
                                .message
                            }
                          </div>
                        ) : (
                          ""
                        )}
                      </Form.Field>
                      {/* <Grid.Column className="pr-0 pb-0 pt-2" width={14}>
                        
                      </Grid.Column> */}
                      {/* <Grid.Column width={7} className="pr-0 pb-0 pt-2">
                        <Form.Field style={{ minHeight: '58px' }}>
                          <label className="label-item">
                            {t.parts.form.unit_cost}{' '}
                            {`(${t.parts.form.pre_unit})`}
                            <Asterisk color="#172B4D" />
                          </label>
                          <Input
                            type="number"
                            min={1}
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            autoComplete="new-password"
                            fluid
                            placeholder={t.parts.form.enter_unit_cost}
                            value={one.unitCost}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (
                                e.target.value &&
                                positiveNumRegex.test(e.target.value)
                              ) {
                                let areaData = [...data.quantityPerStore];
                                areaData[index].unitCost = Number(
                                  e.target.value
                                );

                                if (areaData[index].quantity) {
                                  areaData[index].totalCost =
                                    Number(e.target.value) *
                                    Number(areaData[index].quantity);
                                }
                                setData({
                                  ...data,
                                  quantityPerStore: areaData,
                                });
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          unitCost: {
                                            ...item.unitCost,
                                            error: false,
                                            skipped: true,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              } else if (!e.target.value) {
                                let areaData = [...data.quantityPerStore];
                                areaData[index].unitCost = '';

                                if (areaData[index].quantity) {
                                  areaData[index].totalCost =
                                    Number(e.target.value) *
                                    Number(areaData[index].quantity);
                                }
                                setData({
                                  ...data,
                                  quantityPerStore: areaData,
                                });
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          unitCost: {
                                            ...item.unitCost,
                                            error: true,
                                            skipped: true,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              }
                            }}
                            error={
                              validation.quantityPerStore[index].unitCost
                                .error &&
                              validation.quantityPerStore[index].unitCost
                                .skipped
                            }
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (
                                validation.quantityPerStore[
                                  index
                                ].unitCost.pattern(value)
                              ) {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          unitCost: {
                                            ...item.unitCost,
                                            error: false,
                                            skipped: true,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              } else {
                                setValidation((prevValidation) => ({
                                  ...prevValidation,
                                  quantityPerStore: prevValidation.quantityPerStore.map(
                                    (item, i) => {
                                      if (i === index) {
                                        return {
                                          ...item,
                                          unitCost: {
                                            ...item.unitCost,
                                            error: true,
                                            skipped: true,
                                          },
                                        };
                                      }
                                      return item;
                                    }
                                  ),
                                }));
                              }
                            }}
                          >
                            <input />
                          </Input>

                          {validation.quantityPerStore[index].unitCost.error &&
                          validation.quantityPerStore[index].unitCost
                            .skipped ? (
                            <div className="label-error">
                              {
                                validation.quantityPerStore[index].unitCost
                                  .message
                              }
                            </div>
                          ) : (
                            ''
                          )}
                        </Form.Field>{' '}
                      </Grid.Column> */}
                      {/* <Grid.Column
                        width={2}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span
                          className="mt-7"
                          style={{
                            backgroundColor: '#E9F2FF',
                            color: '#0C66E4',
                            fontSize: '14px',
                            padding: '7px 3px',
                          }}
                        >
                          {organisationCurrency}
                        </span>
                      </Grid.Column> */}
                    </Segment>
                  </div>
                </>
              );
            })}
            <div
              className={`buttun-type-link mt-5 ${
                data.quantityPerStore.length == 1 ? "" : " "
              }`}
              basic
              onClick={addNewStore}
            >
              <span className="customer-add-btn">
                {" "}
                <CustomerPlusIcon />{" "}
              </span>

              <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                {t.parts.form.add_inv_loc}
              </p>
            </div>
            {/* {avtiveBtn == 3 ? (
            <div className="label-error">{t.common.required_field}</div>
          ) : null} */}
            <Form.Field>
              <label className="label-item mt-6">
                {t.workRequests.form.assets}
                {/* <Icon name="asterisk" color="red" size="small" /> */}
              </label>{" "}
              {/* {validation.vendor.error && validation.vendor.skipped ? (
                  <div className="label-error">{validation.vendor.message}</div>
                ) : (
                  ''
                )} */}
              <Dropdown
                placeholder={t.workRequests.form.add_assets}
                noResultsMessage={t.common.no_results_found}
                fluid
                // disabled={
                //   !data.quantityPerStore?.some(
                //     (store) => store.location !== ""
                //   ) && !(role === "super_admin" || role === "admin")
                // }
                search
                selection
                multiple
                className="people-dropdown"
                options={options.assets}
                value={data.asset}
                onChange={(e, value) => {
                  // Find the currently selected options
                  const selectedOptions = options.assets.filter((option) =>
                    value?.value.includes(option.value)
                  );

                  let newSelectedValues = [...value?.value];

                  selectedOptions.forEach((option) => {
                    // When selecting a parent asset
                    if (
                      !option?.disabled &&
                      option.className === "main-location-dropdown-option"
                    ) {
                      // Find child assets of this parent
                      const childOptions = options.assets.filter(
                        (subOption) =>
                          subOption.parentId === option.key &&
                          !subOption.disabled
                      );

                      // Add child assets to the selected values if they are not disabled
                      newSelectedValues = [
                        ...newSelectedValues,
                        ...childOptions.map((child) => child.value),
                      ];

                      // For each child, find its grandchildren (if the child is selected)
                      childOptions.forEach((child) => {
                        const grandChildren = options.assets.filter(
                          (grandChild) =>
                            grandChild.childParentId === child.key &&
                            !grandChild.disabled
                        );

                        // Add grandchildren to the selected values if they are not disabled
                        newSelectedValues = [
                          ...newSelectedValues,
                          ...grandChildren.map(
                            (grandChild) => grandChild.value
                          ),
                        ];
                      });
                    }

                    // When selecting a child asset
                    if (
                      !option?.disabled &&
                      option.className === "sub-location-dropdown-option"
                    ) {
                      // Find grandchild assets of this child
                      const grandChildren = options.assets.filter(
                        (grandChild) =>
                          grandChild.childParentId === option.key &&
                          !grandChild.disabled
                      );

                      // Add grandchildren to the selected values if they are not disabled
                      newSelectedValues = [
                        ...newSelectedValues,
                        ...grandChildren.map((grandChild) => grandChild.value),
                      ];
                    }
                  });

                  // Update state with new selected values
                  setData({
                    ...data,
                    asset: [...new Set(newSelectedValues)], // Ensure unique values only
                  });
                }}
                // onChange={onValuesChange("asset")}
                onOpen={() => fetchAssets()}
                // error={validation.asset.error && validation.asset.skipped}
                // onBlur={(e) => {
                //   if (validation.asset.pattern(data.asset)) {
                //     setValidation({
                //       ...validation,
                //       asset: {
                //         ...validation.asset,
                //         error: false,
                //         skipped: true,
                //       },
                //     });
                //   } else {
                //     setValidation({
                //       ...validation,
                //       asset: {
                //         ...validation.asset,
                //         error: true,
                //         skipped: true,
                //       },
                //     });
                //   }
                // }}
              />
              {/* {validation.asset.error && validation.asset.skipped ? (
                  <div className="label-error">{validation.asset.message}</div>
                ) : (
                  ''
                )} */}
            </Form.Field>
            <Form.Field>
              <label className="label-item">
                {t.parts.form.vendors}
                {/* <Icon name="asterisk" color="red" size="small" /> */}
              </label>
              <Dropdown
                placeholder={t.parts.form.select_vendor}
                noResultsMessage={t.common.no_results_found}
                fluid
                // disabled={
                //   !data.quantityPerStore?.some(
                //     (store) => store.location !== ""
                //   ) && !(role === "super_admin" || role === "admin")
                // }
                search
                multiple
                selection
                options={options.vendors}
                value={data.vendor}
                onChange={onValuesChange("vendor")}
                onOpen={() => fetchVendorsData()}
                // error={validation.vendor.error && validation.vendor.skipped}
                // onBlur={(e) => {
                //   if (validation.vendor.pattern(data.vendor)) {
                //     setValidation({
                //       ...validation,
                //       vendor: {
                //         ...validation.vendor,
                //         error: false,
                //         skipped: true,
                //       },
                //     });
                //   } else {
                //     setValidation({
                //       ...validation,
                //       vendor: {
                //         ...validation.vendor,
                //         error: true,
                //         skipped: true,
                //       },
                //     });
                //   }
                // }}
              />
              {/* {validation.vendor.error && validation.vendor.skipped ? (
                  <div className="label-error">{validation.vendor.message}</div>
                ) : (
                  ''
                )} */}
            </Form.Field>
            <Form.Field>
              <label className="label-item">
                {t.workRequests.form.people}{" "}
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setPeopleTooltip(true)}
                  onMouseLeave={() => setPeopleTooltip(false)}
                >
                  {showPeopleTooltip ? (
                    <>
                      <InfoIconBlue />
                      <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position={
                          selectedLanguage == "ar" ? "left top" : "right top"
                        }
                        show={showPeopleTooltip}
                        textBoxWidth="97px"
                        arrowAlign="center"
                        fontSize="9px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == "ar"
                            ? "Noto Kufi Arabic"
                            : "Roboto"
                        }
                        padding="4px 14px 4px 7px"
                        lineHeight="16px"
                        borderRadius="6px"
                        static
                        moveDown="0px"
                      >
                        <span className="work-order-preview-tooltip parts">
                          {t.parts.form.people_tooltip}
                        </span>
                      </ToolTip>
                    </>
                  ) : (
                    <InfoIcon />
                  )}
                </span>
              </label>
              <Dropdown
                placeholder={t.workRequests.form.add_people}
                // disabled={!data?.locations.length}
                fluid
                noResultsMessage={t.common.no_results_found}
                selection
                // disabled={
                //   !data.quantityPerStore?.some(
                //     (store) => store.location !== ""
                //   ) && !(role === "super_admin" || role === "admin")
                // }
                search
                multiple
                options={options.people}
                value={data.people}
                onChange={onValuesChange("people")}
                onOpen={fetchPeople}
                // error={validation.people.error && validation.people.skipped}
                onBlur={(e) => {
                  if (validation.people.pattern(data.people)) {
                    setValidation({
                      ...validation,
                      people: {
                        ...validation.people,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    if (validation.teams.pattern(data.teams)) {
                      setValidation({
                        ...validation,
                        people: {
                          ...validation.people,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        people: {
                          ...validation.people,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }
                }}
              />
              {/* {validation.people.error && validation.people.skipped ? (
              <div className="label-error" style={{ marginTop: '-15px' }}>
                {validation.people.message}
              </div>
            ) : (
              ''
            )} */}
            </Form.Field>
            <Form.Field>
              <label className="label-item">
                {t.workRequests.form.teams}{" "}
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setTeamsTooltip(true)}
                  onMouseLeave={() => setTeamsTooltip(false)}
                >
                  {showTeamsTooltip ? (
                    <>
                      <InfoIconBlue />
                      <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position={
                          selectedLanguage == "ar" ? "left top" : "right top"
                        }
                        show={showTeamsTooltip}
                        textBoxWidth="91px"
                        arrowAlign="center"
                        fontSize="9px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == "ar"
                            ? "Noto Kufi Arabic"
                            : "Roboto"
                        }
                        padding="4px 14px 4px 7px"
                        lineHeight="16px"
                        borderRadius="6px"
                        static
                        moveDown="0px"
                      >
                        <span className="work-order-preview-tooltip parts">
                          {t.parts.form.teams_tooltip}
                        </span>
                      </ToolTip>
                    </>
                  ) : (
                    <InfoIcon />
                  )}
                </span>
              </label>
              <Dropdown
                placeholder={t.workRequests.form.add_teams}
                fluid
                clearable
                // disabled={!data?.locations.length}
                noResultsMessage={t.common.no_results_found}
                selection
                // disabled={
                //   !data.quantityPerStore?.some(
                //     (store) => store.location !== ""
                //   ) && !(role === "super_admin" || role === "admin")
                // }
                search
                options={options.teams}
                value={data.teams}
                onChange={onValuesChange("teams")}
                onOpen={fetchTeams}
                // error={validation.teams.error && validation.teams.skipped}
                onBlur={(e) => {
                  if (validation.teams.pattern(data.teams)) {
                    setValidation({
                      ...validation,
                      teams: {
                        ...validation.teams,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    if (validation.people.pattern(data.people)) {
                      setValidation({
                        ...validation,
                        teams: {
                          ...validation.teams,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        teams: {
                          ...validation.teams,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }
                }}
              />
              {/* {validation.teams.error && validation.teams.skipped ? (
              <div className="label-error">{validation.teams.message}</div>
            ) : (
              ''
            )} */}
            </Form.Field>
            <Form.Field autoComplete="off">
              <label className="label-item">
                {t.parts.form.serial_number}
                <Asterisk color="#172B4D" />
              </label>

              <div className="flexbox">
                <Input
                  style={{ flex: 1 }}
                  autoComplete="new-password"
                  fluid
                  type="text"
                  maxLength={13}
                  placeholder={t.assets.form.enter_serial_number}
                  value={data.serialNumber}
                  onKeyDown={(evt) => handleKeyDown(evt)}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits and limit to 13 characters
                    if (/^[a-zA-Z0-9.]{0,13}$/.test(value)) {
                      onValuesChange("serialNumber")(e);
                    }
                  }}
                  error={
                    validation.serialNumber.error &&
                    validation.serialNumber.skipped
                  }
                  onBlur={(e) => {
                    if (validation.serialNumber.pattern(data.serialNumber)) {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />

                <span
                  className="ml-5 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                  style={{ backgroundColor: "#E9F2FF" }}
                  onClick={() => {
                    let generatedSerialNumber = generateUniqueInteger();
                    setData({
                      ...data,
                      serialNumber: generatedSerialNumber,
                      // Math.floor(Math.random() * 9000000000) + 1000000000,
                    });
                    if (
                      validation.serialNumber.pattern(generatedSerialNumber)
                    ) {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                >
                  <Repeat />
                </span>
              </div>
              {validation.serialNumber.error &&
              validation.serialNumber.skipped ? (
                <div className="label-error">
                  {validation.serialNumber.message}
                </div>
              ) : (
                ""
              )}
            </Form.Field>
            <label className="label-item">
              {t.parts.form.add_QR}
              <Asterisk color="#172B4D" />
            </label>
            <div className="flexbox barcode-btns">
              <button
                active={avtiveBtn == 1}
                onClick={() => setActiveBtn(1)}
                className={`qr-button ${avtiveBtn == 1 ? "active" : ""}`}
              >
                <span>
                  <BarcodeIcon />
                </span>{" "}
                {t.parts.form.barcode}
              </button>

              <button
                className={`qr-button ${avtiveBtn == 2 ? "active" : ""}`}
                active={avtiveBtn == 2}
                onClick={() => setActiveBtn(2)}
              >
                <span className="mr-1">
                  <QRcodeIconInactive />
                </span>
                {t.parts.form.qrcode}
              </button>
            </div>
            {avtiveBtn == 2 ? (
              <div className="mt-6">
                <QRComponentToPrint titleData={"ID"} ref={qrRef} />
              </div>
            ) : null}
            {avtiveBtn == 1 ? (
              <div className="mt-3">
                <QRComponentToPrint
                  titleData={"ID"}
                  isQRcode={false}
                  ref={qrRef}
                />
              </div>
            ) : null}
            {/* <Grid columns="equal">
            <Grid.Column>
              <Form.Field>
                <label className="label-item">{t.parts.form.procedure}</label>
                <Dropdown
                  placeholder={t.parts.form.select_procedure}
                  fluid
                  selection
                  search
                  options={options.procedures}
                  value={data.procedure}
                  onChange={onValuesChange('procedure')}
                  onOpen={fetchProceduresOptions}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid> */}
            {/* <Form.Field>
            <label className="label-item">
              {t.parts.form.additional_part_details}
            </label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder={t.parts.form.additional_part_details}
              value={data.additionalInformation}
              onChange={onValuesChange('additionalInformation')}
            />
          </Form.Field> */}
            {/* <Form.Field>
            <label className="label-item">{t.parts.form.image}</label>
            {image ? (
              <List divided relaxed>
                <Popup
                  key={image?.id}
                  content={t.common.remove_file}
                  on="click"
                  pinned
                  open={image?.cancelPopup}
                  trigger={
                    <List.Item
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        let currentFile = { ...image };
                        currentFile.cancelPopup = !currentFile.cancelPopup;
                        setImage(currentFile);
                      }}
                    >
                      <List.Icon
                        name={
                          image?.type.match('image.*')
                            ? 'image'
                            : image?.type.match('pdf')
                            ? 'file pdf'
                            : 'file'
                        }
                        size="large"
                        color="black"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header>{image?.name}</List.Header>
                      </List.Content>
                    </List.Item>
                  }
                >
                  <Header as="h4">{t.common.remove_file}</Header>
                  <Button
                    color="black"
                    onClick={(e) => {
                      e.stopPropagation();
                      let currentFile = image;
                      currentFile.cancelPopup = false;
                      setImage(currentFile);
                    }}
                  >
                    {t.common.keep}
                  </Button>
                  <Button
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImage(null);
                    }}
                  >
                    {t.common.yes_remove}
                  </Button>
                </Popup>
              </List>
            ) : (
              <div>
                <input
                  ref={inventoryImageRef}
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => onImageChange(e, 'image')}
                />
                <div
                  className="buttun-type-link"
                  onClick={() => inventoryImageRef.current.click()}
                >
                  <Icon name="add" />
                  {t.common.add}
                </div>
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: '5px',
                    color: '#7e7e7e',
                  }}
                >
                  {t.common.allowed_type} jpg, jpeg, png
                </p>
              </div>
            )}
          </Form.Field> */}
            {/* <Form.Field>
            <label className="label-item">{t.parts.form.files}</label>
            {files.length > 0 ? (
              <List divided relaxed>
                {files.map((el, i) => (
                  <Popup
                    key={el.id}
                    content={t.common.remove_file}
                    on="click"
                    pinned
                    open={el.cancelPopup}
                    trigger={
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          let newFiles = [...files];
                          let currentFile = { ...files[i] };
                          currentFile.cancelPopup = !currentFile.cancelPopup;
                          newFiles[i] = currentFile;
                          setFiles(newFiles);
                        }}
                      >
                        <List.Icon
                          name={
                            el.type.match('image.*')
                              ? 'image'
                              : el.type.match('pdf')
                              ? 'file pdf'
                              : 'file'
                          }
                          size="large"
                          color="black"
                          verticalAlign="middle"
                        />
                        <List.Content>
                          <List.Header>{el.name}</List.Header>
                        </List.Content>
                      </List.Item>
                    }
                  >
                    <Header as="h4">{t.common.remove_file}</Header>
                    <Button
                      color="black"
                      onClick={(e) => {
                        e.stopPropagation();
                        let newFiles = [...files];
                        let currentFile = { ...files[i] };
                        currentFile.cancelPopup = false;
                        newFiles[i] = currentFile;
                        setFiles(newFiles);
                      }}
                    >
                      {t.common.keep}
                    </Button>
                    <Button
                      color="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        let newFiles = [...files];
                        newFiles.splice(i, 1);
                        setFiles(newFiles);
                      }}
                    >
                      {t.common.yes_remove}
                    </Button>
                  </Popup>
                ))}
              </List>
            ) : (
              ''
            )}
            <div>
              <input
                ref={inventoryFilesRef}
                type="file"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf, image/*"
                hidden
                multiple
                onChange={(e) => onFilesChange(e)}
              />
              <div
                className="buttun-type-link"
                onClick={() => inventoryFilesRef.current.click()}
              >
                <Icon name="add" />
                {t.parts.form.add_files}
              </div>
              <p
                style={{
                  fontSize: '12px',
                  marginTop: '5px',
                  color: '#7e7e7e',
                }}
              >
                {t.common.allowed_type} jpg, jpeg, png, pdf, doc, docx, xlsx,
                csv
              </p>
            </div>
          </Form.Field> */}
            <Form.Field className="mt-8">
              <FileUpload
                onCertificateFileChange={onCertificateFileChange}
                name="warrantyFile"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>
            {/* {generatedCustomDataFields()}
          <div className="buttun-type-link" basic onClick={addCustomData}>
            <Icon name="add" />
            {t.parts.form.custom_data}
          </div>
          <Divider /> */}
            <div className="form-button-container mt-10">
              <button
                onClick={() =>
                  initChange ? setNewCancelModal(true) : cancelHandler()
                }
                // onClick={createNewInventoryHandler}
                className="form-action-button"
              >
                {" "}
                {t.assets.form.cancel}
              </button>
              <button
                content={t.parts.form.cancel}
                className="form-action-button blue"
                basic
                onClick={createNewInventoryHandler}
              >
                {" "}
                {t.assets.form.submit}
              </button>
            </div>
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* cancel modal */}

      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>

      {/* Add new category modal */}
      <AddCategoryModal
        isOpen={openCategoryAddModal}
        setModalOpenState={setOpenCategoryAddModal}
        handleSuccess={(res) => categoryCreateSuccess(res)}
      />
    </>
  );
}

export default Add;
