import React, { useState, useEffect, createRef } from 'react';
import {
  Icon,
  Image,
  Header,
  Popup,
  Menu,
  Divider,
  Loader,
  Dimmer,
  Segment,
  Radio,
  Form,
  Input,
  Checkbox,
  Modal,
  Button,
  Tab,
} from 'semantic-ui-react';
import {
  humanize,
  getUserProfileByID,
  IMAGES_URL,
  deleteProcedure,
} from '../../../config/functions';

import md5 from 'md5';
import Moment from 'react-moment';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { LetteredAvatarImageUrl } from '../../../components/common/userProfilePlaceholder';
import { toast } from 'react-toastify';
import {
  CheckboxIcon,
  TextIcon,
  NumberIcon,
  AmountIcon,
  MultipleChoiceIcon,
  ChecklistIcon,
  InspectionIcon,
  SignatureIcon,
  DateIcon,
  FileIcon,
  PhotoIcon,
  HeadingIcon,
  CloseIcon,
  DeleteIcon,
  EditTextIcon,
  ShareIcon,
  FileDownloadIcon,
  DownloadIconBucket,
  Attachment,
  Success,
  Close,
  RequiredIcon,
  EvidanceIcon,
  DownArrow,
  UpArrow,
  GreyFileIcon,
  GreyWOIcon,
  UpArrowAr,
  PhotoSvg,
  CSVSvg,
  ZipSvg,
  DocSvg,
  TextSvg,
} from '../../../../Assets/Icons/svg';

import { useDeletePermissionModal } from '../../../../hooks/Messages/useDeletePermissionModal';
import { useDeleteResultModal } from '../../../../hooks/Messages/useDeleteResultModal';
import moment from 'moment';
import DownloadExcelFileWithData from '../../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../../services/DownloadPdfFileWithData';
import Tooltip from 'react-power-tooltip';
import { fetchProcedureInitData } from '../../../../redux/reducers/procedureReducer';
import Toggle from 'react-toggle';
import Asterisk from '../../../components/common/Asterisk';

const Details = ({ targetedData }) => {
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const t = useSelector(selectTranslations);
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [procedureFields, setProcedureFields] = useState([]);
  const [headerProcedureFields, setHeaderProcedureFields] = useState([]);
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);

  const [showExclTooltip, setShowExclTooltip] = useState(false);
  const [showPdfTooltip, setShowPdfTooltip] = useState(false);
  const [showShareTooltip, setShareTooltip] = useState(false);
  const [showEditTooltip, setEditTooltip] = useState(false);
  const [showDeleteTooltip, setDeleteTooltip] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [showAttachmentTooltip, setAttachmentTooltip] = useState({});
  const [procedureItemsVisible, setProcedureItemsVisible] = useState({});
  const fieldTypes = [
    {
      key: 'checkbox',
      value: 'checkbox',
      text: t.procedures.form.type_options[0],
      icon: <CheckboxIcon width="24px" height="24px" />,
    },
    {
      key: 'text',
      value: 'text',
      text: t.procedures.form.type_options[1],
      icon: <TextIcon width="24px" height="24px" />,
    },
    {
      key: 'number',
      value: 'number',
      text: t.procedures.form.type_options[2],
      icon: <NumberIcon width="24px" height="24px" />,
    },
    {
      key: 'amount',
      value: 'amount',
      text: t.procedures.form.type_options[3],
      icon: <AmountIcon width="24px" height="24px" />,
    },
    {
      key: 'multipleChoice',
      value: 'multipleChoice',
      text: t.procedures.form.type_options[4],
      icon: <MultipleChoiceIcon width="24px" height="24px" />,
    },
    {
      key: 'checklist',
      value: 'checklist',
      text: t.procedures.form.type_options[5],
      icon: <ChecklistIcon width="24px" height="24px" />,
    },
    {
      key: 'inspectionCheck',
      value: 'inspectionCheck',
      text: t.procedures.form.type_options[6],
      icon: <InspectionIcon width="24px" height="24px" />,
    },
    {
      key: 'signature',
      value: 'signature',
      text: t.procedures.form.type_options[7],
      icon: <SignatureIcon width="24px" height="24px" />,
    },
    {
      key: 'date',
      value: 'date',
      text: t.procedures.form.type_options[8],
      icon: <DateIcon width="24px" height="24px" />,
    },
    {
      key: 'file',
      value: 'file',
      text: t.procedures.form.type_options[9],
      icon: <FileIcon width="24px" height="24px" />,
    },
    {
      key: 'photo',
      value: 'photo',
      text: t.procedures.form.type_options[10],
      icon: <PhotoIcon width="24px" height="24px" />,
    },
    {
      key: 'heading',
      value: 'heading',
      text: t.procedures.form.heading,
      icon: <HeadingIcon width="24px" height="24px" />,
    },
  ];

  const inspectionTypes = [
    {
      type: 'yesNoNA',
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'goodFairPoorNA',
      options: [
        { label: 'Good', value: 'good' },
        { label: 'Fair', value: 'fair' },
        { label: 'Poor', value: 'poor' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'safeRiskNA',
      options: [
        { label: 'Safe', value: 'safe' },
        { label: 'Risk', value: 'risk' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'passFailNA',
      options: [
        { label: 'Pass', value: 'pass' },
        { label: 'Fail', value: 'fail' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'compliantNonCompliantNA',
      options: [
        { label: 'Compliant', value: 'compliant' },
        { label: 'Non-Compliant', value: 'nonCompliant' },
        { label: 'N/A', value: 'N/A' },
      ],
    },
    {
      type: 'scaleFive',
      options: [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
      ],
    },
  ];
  const categoryOptions = [
    {
      value: 'damage',
      label: t.workOrders.form.category_options[0],
    },
    {
      value: 'corrective action Downtime',
      label: t.workOrders.form.category_options[1],
    },
    {
      value: 'planned Downtime',
      label: t.workOrders.form.category_options[2],
    },
    {
      value: 'unplanned Downtime',
      label: t.workOrders.form.category_options[3],
    },
    {
      value: 'inspection',
      label: t.workOrders.form.category_options[4],
    },
    {
      value: 'electrical',
      label: t.workOrders.form.category_options[5],
    },
    {
      value: 'mechanical',
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: 'HVAC',
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: 'preventive maintenance',
      label: t.workOrders.form.category_options[8],
    },
    {
      value: 'project',
      label: t.workOrders.form.category_options[9],
    },
    {
      value: 'safety',
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: 'sop',
      label: t.workOrders.form.category_options[11],
    },
  ];

  const headers = [
    { label: t.procedures.table.ID, key: 'id' },
    { label: t.procedures.table.title, key: 'title' },
    { label: t.procedures.form.description, key: 'description' },
    { label: t.procedures.details_view.category, key: 'category' },
    { label: t.procedures.form.procedure_items, key: 'fields' },
    { label: t.procedures.details_view.assets, key: 'assets' },
    { label: t.procedures.details_view.locations, key: 'locations' },
    { label: t.procedures.details_view.parts, key: 'parts' },
    { label: t.procedures.table.createdByUser, key: 'createdByUser' },
    { label: t.procedures.table.createdAt, key: 'createdAt' },
    { label: t.procedures.table.updatedAt, key: 'updatedAt' },
  ];

  const categoryStatus = categoryOptions.find(
    (status) => status.value == targetedData?.attributes?.category
  );

  const downloadXLS = () => {
    let csvData = [];

    let procedure_data = [];

    procedure_data.id = targetedData.id;
    procedure_data.title = targetedData.attributes.title || t.common.na;
    procedure_data.description =
      targetedData.attributes.description || t.common.na;

    procedure_data.category = categoryStatus?.label || t.common.na;
    procedure_data.fields = targetedData.attributes.fields.length || 0;

    let assetsData = targetedData.attributes?.assets?.data;
    let assetsString = '';

    if (assetsData?.length > 0) {
      assetsString = assetsData
        .map((people) => people.attributes?.name)
        .join(', ');
    } else {
      assetsString = t.common.na;
    }

    procedure_data.assets = assetsString;

    let locationData = targetedData.attributes?.locations?.data;
    let locationString = '';

    if (locationData?.length > 0) {
      locationString = locationData
        .map((each) => each.attributes?.name)
        .join(', ');
    } else {
      locationString = t.common.na;
    }

    procedure_data.locations = locationString;

    let partsData = targetedData.attributes?.parts?.data;
    let partsString = '';

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(', ');
    } else {
      partsString = t.common.na;
    }

    procedure_data.parts = partsString;

    procedure_data.createdByUser =
      targetedData?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;
    procedure_data.createdAt =
      moment(targetedData.attributes.createdAt).format('DD-MM-YYYY LT') ||
      t.common.na;
    procedure_data.updatedAt =
      moment(targetedData.attributes.updatedAt).format('DD-MM-YYYY LT') ||
      t.common.na;

    csvData.push([
      procedure_data.id,
      procedure_data.title,
      procedure_data.description,
      procedure_data.category,
      procedure_data.fields,
      procedure_data.assets,
      procedure_data.locations,
      procedure_data.parts,
      procedure_data.createdByUser,
      procedure_data.createdAt,
      procedure_data.updatedAt,
    ]);
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Description column
      { wch: 25 }, // Category column
      { wch: 15 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 20 }, // Created At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      targetedData?.attributes?.title || 'Single-Procedure'
    );
  };

  const generatePDF = () => {
    let csvData = [];

    let procedure_data = [];

    procedure_data.id = targetedData.id;
    procedure_data.title = targetedData.attributes.title || t.common.na;
    procedure_data.description =
      targetedData.attributes.description || t.common.na;

    procedure_data.category = categoryStatus?.label || t.common.na;
    procedure_data.fields = targetedData.attributes.fields.length || 0;

    let assetsData = targetedData.attributes?.assets?.data;
    let assetsString = '';

    if (assetsData?.length > 0) {
      assetsString = assetsData
        .map((people) => people.attributes?.name)
        .join(', ');
    } else {
      assetsString = t.common.na;
    }

    procedure_data.assets = assetsString;

    let locationData = targetedData.attributes?.locations?.data;
    let locationString = '';

    if (locationData?.length > 0) {
      locationString = locationData
        .map((each) => each.attributes?.name)
        .join(', ');
    } else {
      locationString = t.common.na;
    }

    procedure_data.locations = locationString;

    let partsData = targetedData.attributes?.parts?.data;
    let partsString = '';

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(', ');
    } else {
      partsString = t.common.na;
    }

    procedure_data.parts = partsString;

    procedure_data.createdByUser =
      targetedData?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;
    procedure_data.createdAt =
      moment(targetedData.attributes.createdAt).format('DD-MM-YYYY LT') ||
      t.common.na;
    procedure_data.updatedAt =
      moment(targetedData.attributes.updatedAt).format('DD-MM-YYYY LT') ||
      t.common.na;

    csvData.push([
      procedure_data.id,
      procedure_data.title,
      procedure_data.description,
      procedure_data.category,
      procedure_data.fields,
      procedure_data.assets,
      procedure_data.locations,
      procedure_data.parts,
      procedure_data.createdByUser,
      procedure_data.createdAt,
      procedure_data.updatedAt,
    ]);

    const columnWidths = [, , , , , , , , , 22, 22];

    DownloadPdfFileWithData(
      headers,
      csvData,
      targetedData?.attributes?.title || t.procedures.procedure,
      columnWidths,
      'A3'
    );
  };

  useEffect(() => {
    if (targetedData?.attributes.fields.length > 0) {
      let newFields = targetedData.attributes.fields.map((field) => ({
        ...field,
        isExpanded: false,
      }));

      setProcedureFields(newFields);
    }
    if (targetedData?.attributes?.headers?.length > 0) {
      let newFields = targetedData.attributes?.headers.map((field) => ({
        ...field,
        isExpanded: false,
      }));

      setHeaderProcedureFields(newFields);
    }
  }, [
    targetedData?.attributes.fields,
    targetedData?.attributes?.headers?.fields,
  ]);

  const renderTriggers = (data) => {
    const actionTrigger = data.find((item) => item.trigger === 'action');
    const evidenceTrigger = data.find((item) => item.trigger === 'evidence');
    return (
      <>
        {actionTrigger && (
          <>
            <label
              style={
                selectedLanguage == 'en'
                  ? { marginLeft: '-5px' }
                  : { marginRight: '-5px' }
              }
              className=""
            >
              <span className="">
                <RequiredIcon />{' '}
              </span>{' '}
              {t.procedures.form.then_options[0]}
            </label>

            <p className="detail-text-isc">
              <Segment secondary>
                <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>
                  <GreyWOIcon />
                </span>
                {t.procedures.form.wo_placeholder}
              </Segment>
            </p>
          </>
        )}
        {evidenceTrigger && (
          <>
            <label
              style={
                selectedLanguage == 'en'
                  ? { marginLeft: '-5px' }
                  : { marginRight: '-5px' }
              }
              className=""
            >
              <EvidanceIcon /> {t.procedures.form.then_options[1]}
            </label>
            <p className="detail-text-isc">
              <Segment secondary>
                <span className={selectedLanguage == 'ar' ? 'ml-3' : 'mr-3'}>
                  <GreyFileIcon />
                </span>
                {t.procedures.form.file_placeholder}
              </Segment>
            </p>
          </>
        )}
      </>
    );
  };

  const expandedArray = [
    'checkbox',
    'multipleChoise',
    'checklist',
    'inspectionCheck',
  ];

  const generateSubFields = (field) => {
    const generatedOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.item}</p>
            <input type="checkbox" checked={false} />
          </div>
        );
      });
    };

    const generatedMultiOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.item}</p>
            <input type="radio" checked={false} />
          </div>
        );
      });
    };

    const generatedInspectionOptions = (field) => {
      let filteredArray = inspectionTypes.filter(
        (insp) => insp.type == field.inspectionType
      );
      filteredArray = filteredArray[0];

      let filteredFailed = filteredArray?.options?.filter(
        (option) => option?.value == field?.failedValue
      );
      filteredFailed = filteredFailed ? filteredFailed[0] : null;

      let logicAnswer = filteredArray?.options?.filter(
        (option) => option.value == field?.logicAnswer
      );
      logicAnswer = logicAnswer ? logicAnswer[0] : null;

      return (
        <div className="wrapper-new-label-inspection-check">
          <label
            style={
              selectedLanguage == 'en'
                ? { marginLeft: '-5px' }
                : { marginRight: '-5px' }
            }
            className="title-text-isc"
          >
            {t.procedures.form.type_options[6]}
          </label>
          <div className="mt-3 mb-5">
            {filteredArray?.options?.map((option) => (
              <span className="new-added-add-logic-txt option-inspection">
                {option.label
                  ? t.procedures.inspectionTypes[option.label.toLowerCase()] ||
                    option.label
                  : ''}
              </span>

              // <div
              //   key={option.value} // Add a unique key for each mapped element
              //   style={
              //     filteredFailed?.value === option?.value
              //       ? { backgroundColor: '#fff6f6' }
              //       : {}
              //   }
              //   className="procedure-item-option"
              // >
              //   <p>
              //     {option.label
              //       ? t.procedures.inspectionTypes[option.label.toLowerCase()] ||
              //         option.label
              //       : ''}
              //   </p>
              // </div>
            ))}
          </div>

          <label
            style={
              selectedLanguage == 'en'
                ? { marginLeft: '-5px' }
                : { marginRight: '-5px' }
            }
            className="title-text-isc"
          >
            {t.procedures.logic}
          </label>

          <div className="logic-box-for-isc mt-1">
            <label className="detail-text-isc mb-0">
              {t.procedures.answer}
            </label>

            <div className="mt-3 mb-5">
              {filteredArray?.options?.map((option) => (
                <span
                  key={option.value}
                  style={
                    filteredFailed?.value === option?.value
                      ? {
                          backgroundColor: '#fff6f6',
                          borderColor: '#D14040',
                          color: '#D14040',
                        }
                      : {}
                  }
                  className="new-added-add-logic-txt option-inspection"
                >
                  {option.label
                    ? t.procedures.inspectionTypes[
                        option.label.toLowerCase()
                      ] || option.label
                    : ''}
                </span>

                // <div
                //   key={option.value} // Add a unique key for each mapped element
                //   style={
                //     filteredFailed?.value === option?.value
                //       ? { backgroundColor: '#fff6f6' }
                //       : {}
                //   }
                //   className="procedure-item-option"
                // >
                //   <p>
                //     {option.label
                //       ? t.procedures.inspectionTypes[option.label.toLowerCase()] ||
                //         option.label
                //       : ''}
                //   </p>
                // </div>
              ))}
            </div>

            {renderTriggers(field?.logics)}

            {/* <label>{t.procedures.form.then_options[0]}</label>
            <p>action</p>
            <label>{t.procedures.form.then_options[1]}</label>
            <p>evidance</p> */}
          </div>
        </div>
      );
    };

    let returnFields = null;
    switch (field.fieldType) {
      case 'text':
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>
              {/* {fieldTypes[1]?.icon} */}
              <TextIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.text_placeholder}
          </Segment>
        );
        break;
      case 'number':
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>
              {/* {fieldTypes[2]?.icon} */}
              <NumberIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.number_placeholder}
          </Segment>
        );

        break;

      case 'checkbox':
        returnFields = generatedOptions(field?.options);

        // returnFields = (
        //   <>
        //     <div className="procedure-item-option">
        //       <p>{'option 1'}</p>
        //       <input type="checkbox" checked={false} />
        //     </div>
        //     {/* <Segment secondary className='m-5'>
        //         <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>

        //         </span>
        //         {t.procedures.form.option}
        //       </Segment>{' '} */}
        //   </>
        // );
        break;
      case 'amount':
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>
              {/* {fieldTypes[3]?.icon} */}
              <AmountIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.amount_placeholder}
          </Segment>
        );
        break;

      case 'signature':
        returnFields = (
          <>
            <Segment secondary>
              <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>
                {/* {fieldTypes[7]?.icon} */}
                <SignatureIcon width="24px" height="24px" fill="#788CA5" />
              </span>
              {t.procedures.form.signature_placeholder}
            </Segment>{' '}
          </>
        );
        break;
      case 'date':
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>
              {/* {fieldTypes[8]?.icon} */}
              <DateIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.date_placeholder}{' '}
          </Segment>
        );
        break;
      case 'file':
        // text,number,amount, signature, date, file fields
        //isrequired
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>
              {/* {fieldTypes[9]?.icon} */}
              <FileIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.file_placeholder}
          </Segment>
        );
        break;

      case 'photo':
        // text,number,amount, signature, date, file fields
        //isrequired
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == 'ar' ? 'ml-2' : 'mr-2'}>
              {/* {fieldTypes[10]?.icon} */}
              <PhotoIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.photo_placeholder}
          </Segment>
        );
        break;

      case 'multipleChoice':
        returnFields = generatedMultiOptions(field.options);
        break;
      case 'checklist':
        // multipleChoice fields
        //isRequired & options
        returnFields = generatedOptions(field.options);
        break;
      case 'inspectionCheck':
        // inspectionCheck fields
        //isRequired & inspectionTypes & logic
        returnFields = generatedInspectionOptions(field);

        break;
      default:
        returnFields = null;
    }

    return (
      <>
        {returnFields}
        {/* <Divider /> */}
        {/* <div className="d-flex align-items-center justify-content-end">
          <Toggle
          
            className="toggle-data mt-2"
            checked={field.isRequired}
            disabled
          />
          <span className="required-text ml-2 mt-2 mr-2">
            {t.procedures.form.is_required}
          </span>
        </div> */}
        <div className=" mt-2">
          {field?.attachments?.data?.length > 0 && (
            <>
              {field?.attachments?.data?.map((prev, index) => {
             
                return (
                  <>
                    <div
                      onClick={() => {
                        window.open(IMAGES_URL + prev.attributes.url, '_blank');
                      }}
                      className="c-pointer file-name-show-procedure details"
                    >
                      <div
                        className="c-pointer"
                        style={{ position: 'relative' }}
                        onMouseOver={() =>
                          setAttachmentTooltip({
                            ...showAttachmentTooltip,
                            [index]: true,
                          })
                        }
                        onMouseLeave={() =>
                          setAttachmentTooltip({
                            ...showAttachmentTooltip,
                            [index]: false,
                          })
                        }
                      >
                        <span className="ml-2 mr-2">
                          {['.jpg', '.jpeg', '.png', '.svg'].includes(
                            prev?.attributes?.ext
                          ) ? (
                            <PhotoSvg />
                          ) : ['.pdf'].includes(
                            prev?.attributes?.ext
                            ) ? (
                            <PhotoSvg />
                          ) : ['.csv', '.xlsx'].includes(
                            prev?.attributes?.ext
                            ) ? (
                            <CSVSvg />
                          ) : ['.zip'].includes(
                            prev?.attributes?.ext
                            ) ? (
                            <ZipSvg />
                          ) : ['.doc', '.docx'].includes(
                            prev?.attributes?.ext
                            ) ? (
                            <DocSvg />
                          ) : ['.txt'].includes(
                            prev?.attributes?.ext
                            ) ? (
                            <TextSvg />
                          ) : (
                            // field?.file?.data?.attributes?.ext.match('.txt') ? <TextSvg /> :
                            <Attachment/>
                          )}
                          {/* <Attachment /> */}
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position="bottom center"
                            show={showAttachmentTooltip[index]}
                            textBoxWidth="66px"
                            arrowAlign="center"
                            fontSize="9px"
                            fontWeight="400"
                            fontFamily="Roboto"
                            padding="5px 5px 5px 7px"
                            borderRadius="5px"
                            moveUp="5px"
                            static
                          >
                            <span className="work-order-preview-tooltip">
                              {t.people.personal_info_form.attachments}
                            </span>
                          </Tooltip>
                        </span>
                      </div>
                      <p className="mt-1 c-pointer">
                        {' '}
                        {prev?.attributes?.name}
                      </p>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </div>
      </>
    );
  };

  // const generateFields = (fields,isHeader=false) => {

  //   return procedureFields.map((field, index) =>
  //     field?.fieldType == 'heading' ? (
  //       <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
  //         {field?.label}
  //       </ProcedureItemContainerCard>
  //     ) : (
  //       <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
  //         <div className="item-value">{generateSubFields(field)}</div>
  //       </ProcedureItemContainerCard>
  //     )
  //   );
  // };

  const generateFields = (fields, isHeader = false) => {
    const toggleProcedureItems = (index) => {
      if (procedureItemsVisible[index]) {
        setProcedureItemsVisible({
          ...procedureItemsVisible,
          [index]: false,
        });
      } else {
        setProcedureItemsVisible({
          ...procedureItemsVisible,
          [index]: true,
        });
      }
      // setProcedureItemsVisible(!procedureItemsVisible);
    };

    if (isHeader) {
      return headerProcedureFields.map((field, index) => (
        <React.Fragment key={field.id}>
          <div
            className={
              field?.fields?.length == 0
                ? 'hide-border mb-5'
                : !procedureItemsVisible[index]
                ? 'show-border mb-5'
                : 'mb-5 hide-border'
            }
            style={
              field?.fields?.length == 0
                ? {}
                : !procedureItemsVisible[index]
                ? { borderRadius: '8px', border: '1px solid #0C66E4' }
                : {}
            }
          >
            <ProcedureItemContainerCard
              toggleProcedureItems={toggleProcedureItems}
              procedureItemsVisible={procedureItemsVisible}
              fieldCount={field?.fields?.length}
              field={field}
              fieldTypes={fieldTypes}
              index={index}
            >
              {field?.label}
            </ProcedureItemContainerCard>

            {!procedureItemsVisible[index] &&
              field?.fields &&
              field?.fields.length > 0 &&
              field.fields.map((subField) => (
                <ProcedureItemContainerCard
                  isHeader={isHeader}
                  key={subField.id}
                  field={subField}
                  fieldTypes={fieldTypes}
                >
                  <div className="item-value">
                    {generateSubFields(subField)}
                  </div>
                </ProcedureItemContainerCard>
              ))}
          </div>
        </React.Fragment>
      ));
    } else {
      return procedureFields.map((field, index) =>
        field?.fieldType == 'heading' ? (
          <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
            {field?.label}
          </ProcedureItemContainerCard>
        ) : (
          <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
            <div className="item-value">{generateSubFields(field)}</div>
          </ProcedureItemContainerCard>
        )
      );
    }
  };

  return (
    <>
      {targetedData ? (
        <div className="preview-details-wrapper">
          <div className="procedure-data-header">
            <div className="header-title d-flex justify-content-between align-items-center">
              <div className="header-pill-container">
                <p className="procedure-title">
                  {targetedData?.attributes?.title}
                </p>
              </div>
            </div>
            {/* <div>
                    <p className="procedure-updated-title">
                      {t.procedures.table.updatedAt}
                      {':'}{' '}
                      <span>
                        {
                          <Moment
                            format="DD-MM-YYYY LT"
                            date={targetedData?.attributes.updatedAt}
                          />
                        }
                      </span>
                    </p>
                  </div> */}
          </div>
          <div className="procedure-intro">
            <div className="d-flex align-items-start mt-5">
              <label className="procedure-intro-label procedure-label">
                {t.procedures.form.description}
                {':'}
              </label>
              <p className="procedure-intro-value">
                {targetedData?.attributes?.description
                  ? targetedData?.attributes?.description
                  : t.common.na}
              </p>
            </div>
            {/* <div className="d-flex align-items-center mt-5">
                    <label className="procedure-intro-label">
                      {t.procedures.details_view.created_by}
                      {':'}
                    </label>
                    <p className="procedure-intro-value">{createdByName}</p>
                  </div> */}
            <div className="d-flex align-items-center mt-5">
              <label className="procedure-intro-label procedure-label">
                {t.procedures.details_view.category}
                {':'}
              </label>
              <p className="procedure-intro-value">
                {categoryStatus?.label || t.common.na}
              </p>
            </div>
            <div className="d-flex align-items-center mt-5">
              <label className="procedure-intro-label procedure-label" >
                {t.procedures.details_view.assets}
                {':'}
              </label>
              <p className="procedure-intro-value">
                {targetedData?.attributes?.assets &&
                targetedData?.attributes?.assets?.data.length
                  ? targetedData?.attributes?.assets?.data.map((each) => {
                      return (
                        <span
                          className="procedure-intro-link ml-4 mr-4"
                          basic
                          onClick={() =>
                            history.push(
                              `/assets?id=${each?.attributes?.countId}`
                            )
                          }
                        >
                          {each?.attributes?.name}
                        </span>
                      );
                    })
                  : t.common.na}
              </p>
            </div>
            {/* <div className="d-flex align-items-center mt-5">
                    <label className="procedure-intro-label">
                      {t.procedures.details_view.parts}
                      {':'}
                    </label>
                    <p className="procedure-intro-value">
                      {targetedData?.attributes?.parts &&
                      targetedData?.attributes?.parts?.data.length
                        ? targetedData?.attributes?.parts?.data.map((each) => {
                            return (
                              <span
                                className="procedure-intro-link ml-4 mr-4"
                                basic
                                onClick={() =>
                                  history.push(`/parts?id=${each?.id}`)
                                }
                              >
                                {each?.attributes?.name}
                              </span>
                            );
                          })
                        : t.common.na}
                    </p>
                  </div> */}
            {/* <div className="d-flex align-items-center mt-5">
                      <label className="procedure-intro-label">
                        {t.procedures.details_view.locations}
                        {':'}
                      </label>
                      <p className="procedure-intro-value">
                        {targetedData?.attributes?.locations &&
                        targetedData?.attributes?.locations?.data.length
                          ? targetedData?.attributes?.locations?.data.map(
                              (each) => {
                                return (
                                  <span
                                    className="procedure-intro-link ml-4 mr-4"
                                    basic
                                    onClick={() =>
                                      history.push(`/locations?id=${each?.id}`)
                                    }
                                  >
                                    {each?.attributes?.name}
                                  </span>
                                );
                              }
                            )
                          : t.common.na}
                      </p>
                    </div> */}
          </div>
          <ul className="details-list">
            <li className="list-item" style={{ marginBottom: '10px' }}>
              <label className="item-label procedure-label procedure-item-header">
                {t.procedures.details_view.procedure_items} &nbsp; &nbsp;
                {targetedData?.attributes?.procedureItems
                  ? targetedData?.attributes?.procedureItems
                  : 0}
              </label>
              <div className="item-value ">
                {targetedData?.attributes?.headers?.length > 0
                  ? generateFields(targetedData?.attributes?.headers, true)
                  : null}
              </div>
              <div className="item-value ">
                {targetedData?.attributes.fields.length > 0
                  ? generateFields(targetedData?.attributes.fields)
                  : null}
              </div>
            </li>
          </ul>
          {/* <ul className="details-list">
            <li className="list-item" style={{ marginBottom: '10px' }}>
              <label className="item-label procedure-label procedure-item-header">
                {t.procedures.details_view.procedure_items} &nbsp; &nbsp;
                {targetedData?.attributes.fields.length}
              </label>
              <div className="item-value ">
                {targetedData?.attributes.fields.length > 0
                  ? generateFields(targetedData?.attributes.fields)
                  : t.common.na}
              </div>
            </li>
          </ul> */}

          <Divider />

          <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label work-request-label grey">
              {t.workOrders.table.createdByUser}
              {':'}
            </label>
            <p className="workorder-intro-value grey">
              {targetedData?.attributes?.createdByUser?.data?.attributes
                ?.firstName &&
              targetedData?.attributes?.createdByUser?.data?.attributes
                ?.lastName
                ? targetedData?.attributes?.createdByUser?.data?.attributes
                    ?.firstName +
                  ' ' +
                  targetedData?.attributes?.createdByUser?.data?.attributes
                    ?.lastName
                : t.common.na}
              {', '}
              <Moment
                format="DD-MM-YYYY LT"
                date={targetedData?.attributes?.createdAt}
              />
            </p>
          </div>

          <div className="d-flex align-items-center mt-3">
            <label className="workorder-intro-label work-request-label grey">
              {t.common.update_by}
              {':'}
            </label>
            <p className="workorder-intro-value grey">
              {targetedData?.attributes?.updatedByUser?.data
                ? targetedData?.attributes?.updatedByUser?.data?.attributes
                    ?.firstName +
                  ' ' +
                  targetedData?.attributes?.updatedByUser?.data?.attributes
                    ?.lastName +
                  ', ' +
                  moment(targetedData?.attributes?.updatedAt).format(
                    'DD-MM-YYYY LT'
                  )
                : t.common.na}
            </p>
          </div>
        </div>
      ) : (
        <Dimmer active inverted>
          <Loader inverted>{t.common.loading}</Loader>
        </Dimmer>
      )}
    </>
  );
};

const ProcedureItemContainerCard = ({
  fieldTypes,
  field,
  children,
  isHeader,
  fieldCount = null,
  index = null,
  toggleProcedureItems = () => {},
  procedureItemsVisible,
}) => {
  const generatedFieldType = (type) => {
    let filteredFields = fieldTypes.filter((field) => field.value == type);
    filteredFields = filteredFields[0];
    return (
      <>
        {type !== 'heading' ? (
          <>
            {filteredFields.icon}
            <div className="photo">{filteredFields.text}</div>
          </>
        ) : null}
      </>
    );
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  return (
    <div className={isHeader ? 'm-3' : ''}>
      {/* <div
        className={
          field?.fieldType == 'heading'
            ? 'procedure-item-parent header-div'
            : 'procedure-item-parent'
        }
      > */}
      <div
        className={`procedure-item-parent ${
          field?.fieldType == 'heading' ? 'header-div' : ''
        } ${
          field?.fieldType == 'heading' &&
          field?.fields &&
          field?.fields?.length == 0
            ? 'show-header'
            : field?.fieldType == 'heading' && procedureItemsVisible[index]
            ? 'show-header'
            : ''
        }`}
      >
        <div className={field?.fieldType == 'heading' ? 'head-title' : 'title'}>
          {field?.fieldType == 'heading' && field?.fields?.length > 0 ? (
            !procedureItemsVisible[index] ? (
              <span
                className="mt-2 c-pointer"
                onClick={() => toggleProcedureItems(index)}
              >
                {<DownArrow />}
              </span>
            ) : (
              <span
                className="mt-2 c-pointer"
                onClick={() => toggleProcedureItems(index)}
              >
                {selectedLanguage == 'en' ? <UpArrow /> : <UpArrowAr />}
              </span>
            )
          ) : null}
          {/* {field?.fieldType == 'checkbox' ? (
            <input type="checkbox" checked={false} className="ml-2 mr-2" />
          ) : null} */}
          {field.label}
          {field?.isRequired ? <Asterisk color="#D14040" /> : null}
        </div>

        <div className="type-parent">
          {generatedFieldType(field?.fieldType)}
          {field?.fieldType == 'heading' && fieldCount !== 0 ? (
            <span
              className={
                selectedLanguage == 'ar' ? 'mr-2 pl-1 pr-1' : 'ml-3 pl-1 pr-1'
              }
              // onClick={removeThisField(i)}
              style={{
                background: '#0C66E4',
                color: 'white',
                borderRadius: '3px',
              }}
            >
              {fieldCount}
            </span>
          ) : null}
        </div>
      </div>
      {field?.fieldType && !['heading'].includes(field?.fieldType) ? (
        <div className="procedure-sub-item-parent">{children}</div>
      ) : (
        <div className={!isHeader ? 'mb-5' : ''}></div>
      )}
    </div>
  );
};

export default Details;
