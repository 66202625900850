import React, { useEffect, useState } from 'react'
import { selectTranslations } from '../../../config/i18n/slice';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import Tooltip from "react-power-tooltip";
import {
    AddIconDashboard,
    CostIconEstimate,
    DashboardWOStatusIcon,
    FullArrow,
    TimeIconEstimate,
  } from "../../../../Assets/Icons/svg";
  import Chart from "react-apexcharts";
import NoDashboardData from '../noDashboardData';

const AssetValue = ({
    setChartDateOption,
    chartDateOption,
    setOpenModal,
    openModal,
    setModalData,
    chartConfigValue,
    setChartConfigValue,
    assetDashBoardData,
    setAssetDashBoardData,
}) => {
  let predefinedColors = [
    "#C0D9FC",
    "#AECEFA",
    "#9CC2F7",
    "#8AB7F5",
    "#78ABF2",
    "#5494EE",
    "#307DE9",
    "#0C66E4",
    "#0A55BD",
    "#084396",
    "#063270",
    "#05295C",
    "#042049",
    "#031835",
    "#020F22",
    "#D9EDDE",
    "#CBE6D2",
    "#BDE0C6",
    "#B0D9BB",
    "#A2D3AF",
    "#86C597",
    "#6BB880",
    "#4FAB68",
    "#428F57",
    "#357346",
    "#295735",
    "#22492D",
    "1C3B24",
    "#152D1C",
    "#0F1F13",
    "#FEEEB3",
    "#FBE8A1",
    "#F8E290",
    "#F6DC7E",
    "#F3D66D",
    "#EDCA49",
    "#E8BE26",
    "#E2B203",
    "#BA9202",
    "#927202",
    "#695301",
    "#554301",
    "#413301",
    "#2D2300",
    "#191300",
    "#F2F4F7",
    "#E6EAEF",
    "#DADFE7",
    "#CDD5DE",
    "#C1CAD6",
    "#A9B6C6",
    "#90A1B5",
    "#788CA5",
    "#66788E",
    "#556477",
    "#43505F",
    "#3A4654",
    "#323C48",
    "#29323D",
    "#202831",
    "#F5EDFF",
    "#EADAFF",
    "#E0C8FF",
    "#D5B5FF",
    "#C191FF",
    "#AC6CFF",
    "#9747FF",
    "#8339E4",
    "#702BCA",
    "#5C1CAF",
    "#5215A2",
    "#490E95",
    "#3F0787",
    "#35007A",
  ];
    const t = useSelector(selectTranslations);
    const organisationId = useSelector((state) => {
      if (state?.auth?.user?.organisation) {
        return state.auth.user.organisation.id;
      } else {
        return null;
      }
    });
    const [showTooltip, setShowTooltip] = useState(false);
    const [showExpandTooltip, setShowExpandTooltip] = useState(false);
    const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
      false
    );

    const selectedLanguage = useSelector((state) => {
        if (state?.i18n?.lang) {
          return state.i18n.lang;
        } else {
          return "en";
        }
      });

      useEffect(() => {
        if (assetDashBoardData?.assetValue?.length > 0) {
          const data = assetDashBoardData.assetValue.map((item, index) => ({
            x: item.category, // Set category as x
            y: item.totalAssetValue,  // Set assetNo as y
            fillColor: predefinedColors[index % predefinedColors.length], // Cycle through colors
        }));
    
        const labels = assetDashBoardData.assetValue.map((item) => item.category);
          
          // const counts = assetDashBoardData?.assetValue?.map(
          //   (item) => item.totalAssetValue
          // );
          // const labels = assetDashBoardData?.assetByCategory?.map(
          //   (item) => item.category
          // );
         
          // const randomColors = dashBoardData?.workOrdersByAssigneeData?.map(item => {
    
          //     return item?.name === 'Unassigned' ? '#788CA5' : generateRandomColor();
          //   });
    
          //   const updatedStatusOptions = statusOptions.map(option => {
          //     const count = dashBoardData?.workOrderStatus[option.value]?.count || 0;
          //     return { ...option, count };
          //   });
    
          setChartConfigValue((prevState) => ({
            ...prevState,
            series: [
              {
                name: t.dashboard.asset_card.price,
                data: data, // Set count data
              },
            ],
            options: {
              ...prevState.options,
              xaxis: {
                ...prevState.options.xaxis,
                categories: labels, // Set label data
              },
            },
          }));
    
          // const seriesPercentages = [
          //   open.percentage,
          //   inReview.percentage,
          //   onHold.percentage,
          //   completed.percentage,
          //   inProgress.percentage,
          // ];
        }
      }, [assetDashBoardData]);
  return (
    <>
    
    <Grid.Column className="completion-column pl-0 pt-0">
      <div
        className={
          openModal
            ? "completion-column-data no-border"
            : "completion-column-data"
        }
      >
        <div className="completion-cotainer d-flex justify-content-between mt-2">
          <div className="completion-title d-flex">
            <span className="title">{t.dashboard.asset_card.value}</span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className={
                selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
              }
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "en" ? "right" : "left"}
                show={showTooltip}
                textBoxWidth="140px"
                fontSize="10px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="0px 0px 0px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="8px"
                // moveDown="10px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.asset_tooltip.value_asset}
                </span>
              </Tooltip>
              <DashboardWOStatusIcon />
            </p>
          </div>

          <span style={{ marginTop: "-10px", gap: "10px" }} className="d-flex">
            <span
              onClick={() => {
                setOpenModal(true);
                setModalData("Value");
                setShowExpandTooltip(false);
              }}
              style={{ marginTop: "2px" }}
              className=""
            >
              {openModal ? null : (
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowExpandTooltip(true)}
                  onMouseLeave={() => setShowExpandTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showExpandTooltip}
                    textBoxWidth="80px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="start"
                    moveRight="5px"
                    moveDown="3px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.expand_full}
                    </span>
                  </Tooltip>
                  <FullArrow />
                </p>
              )}
            </span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowAddCustomizedTooltip(true)}
              onMouseLeave={() => setShowAddCustomizedTooltip(false)}
              className=" mt-1"
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position="top"
                show={showAddCustomizedTooltip}
                textBoxWidth="105px"
                fontSize="10px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="8px 8px 8px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="0px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.add_customized}
                </span>
              </Tooltip>
              {/* <AddIconDashboard /> */}
            </p>
          </span>
        </div>
        {assetDashBoardData?.assetValue?.length > 0 ? (
        <div className="completion-status-part">
          <Grid className="wo-data-container" columns={1}>
            <Grid.Column className="wo-data-chart  align-items-center text-center">
              <div className="d-flex justify-content-between align-items-center">
              </div>
              <div
                className="scroll-data res"
                style={{ overflowX: "auto", minWidth: "500px" }}
              >
                <div style={{ minWidth: "1000px" }}>
                  {" "}
                  {/* Adjust minWidth as needed */}
                  <Chart
                    options={chartConfigValue?.options}
                    series={chartConfigValue?.series}
                    type="bar"
                    height={openModal ? 400 : 295}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </div>) : <NoDashboardData height="313px"/>}
      </div>
    </Grid.Column>  </>
  )
}

export default AssetValue