import React, { useEffect, useState } from "react";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { useHistory } from "react-router";
import {
  AddIconDashboard,
  CostIconEstimate,
  DashboardWOStatusIcon,
  FullArrow,
  TimeIconEstimate,
} from "../../../../Assets/Icons/svg";
import Chart from "react-apexcharts";
import NoDashboardData from "../noDashboardData";
const MTBF = ({
  setOpenModal,
  isHoverDisabled,
  openModal,
  setModalData,
  chartConfigMTBF,
  maintenanceData,
  setChartConfigMTBF,
  removeTooltip,
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const history = useHistory();

  const [showTooltip, setShowTooltip] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  function handleAssetClick(index) {
   history.push(`/assets?id=${index}`);

    // You can add any logic you need here for when an asset is clicked
  }

  useEffect(() => {
    if (maintenanceData?.MTBF?.length > 0) {
      // const counts = maintenanceData?.maintenanceCostPerAssetCategory?.map(
      //   (item) => item?.totalActualCost
      // );
      const labels = maintenanceData.MTBF.map((item) => item.categoryName);
      const chartData = maintenanceData.MTBF.map((item) => item.totalMTBF);
      // const randomColors = dashBoardData?.workOrdersByAssigneeData?.map(item => {

      //     return item?.name === 'Unassigned' ? '#788CA5' : generateRandomColor();
      //   });

      //   const updatedStatusOptions = statusOptions.map(option => {
      //     const count = dashBoardData?.workOrderStatus[option.value]?.count || 0;
      //     return { ...option, count };
      //   });

      setChartConfigMTBF((prevState) => ({
        ...prevState,
        series: [
          {
            ...prevState.series[0],
            data: chartData, // Set y values
          },
        ],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: labels, // Set label data
          },
          chart: {
            ...prevState.options.chart,
            events: {
              dataPointSelection: function(
                event,
                chartContext,
                { seriesIndex, dataPointIndex, w }
              ) {
                isHoverDisabled = true;
                const selectedData = maintenanceData.MTBF[dataPointIndex];
                const assets = selectedData.uniqueAssets;
                const labelData = t.dashboard.asset_card.asset_name;
                const mtbf_label = t.dashboard.maintenance_card.mtbf_lebal;
                // Remove any existing click tooltip
                removeTooltip();

                // Create new tooltip HTML for each asset

                const assetsHTML = `
                      <div style="display: flex; gap: 5px;">
                        <div style="display: flex; flex-direction: column; gap: 1px; align-items: start; border-right: 2px solid #E4E6EA; padding-right: 20px;">
                          <div style="font-size: 12px; font-weight: 500; color: #44546F;">${labelData}</div>
                               ${assets
                                 .map(
                                   (asset) => `
      <div   style="font-size: 12px; cursor:pointer; color: #0C66E4; font-weight: bold;" data-asset-id="${asset?.countId}">
        ${asset.assetName}
      </div>
    `
                                 )
                                 .join("")}
                        </div>
                        <div style="border-bottom: 1px solid #ccc;"></div>
                        <div style="display: flex; flex-direction: column; gap: 1px; align-items: start;">
                          <div style="font-size: 12px; font-weight: 500; color: #44546F;">${mtbf_label}</div>
                           ${assets
                             .map(
                               (asset) => `
                          <div style="font-size: 12px; color: #172B4D; font-weight: 400;">${asset.totalMTBF}</div>`
                             )
                             .join("")}
                        </div>
                      </div>
                    `;
                const tooltipDiv = document.createElement("div");
                tooltipDiv.className = "click-tooltip";
                tooltipDiv.style.position = "absolute";
                tooltipDiv.style.background = "white";
                tooltipDiv.style.boxShadow = "5px 4px 6px #00000026";
                tooltipDiv.style.border = "1px solid white";
                tooltipDiv.style.color = "black";
                tooltipDiv.style.padding = "5px";
                tooltipDiv.style.borderRadius = "8px";
                tooltipDiv.style.zIndex = "1000";
                tooltipDiv.innerHTML = `<div style="display: flex; flex-direction: column; gap: 5px;">${assetsHTML}</div>`;

                // Position and display tooltip
                const { pageX, pageY } = event;
                tooltipDiv.style.left = `${pageX + 10}px`;
                tooltipDiv.style.top = `${pageY - 40}px`;
                document.body.appendChild(tooltipDiv);

                // Event listeners for tooltip
                tooltipDiv.addEventListener("mouseenter", () => {
                  isHoverDisabled = true;
                });
                tooltipDiv.addEventListener("mouseleave", () => {
                  removeTooltip();
                  isHoverDisabled = false;
                });

                tooltipDiv.addEventListener("click", function (e) {
                  
                    const assetId = e?.target?.getAttribute("data-asset-id");
                    if(assetId){
                      removeTooltip();
                      isHoverDisabled = false;
                      handleAssetClick(assetId);
                    }
                   
                 
                });
              },
              dataPointMouseLeave: function() {
                // Intentionally left blank to handle tooltip hover events
              },
            },
          },
        },
      }));

      // const seriesPercentages = [
      //   open.percentage,
      //   inReview.percentage,
      //   onHold.percentage,
      //   completed.percentage,
      //   inProgress.percentage,
      // ];
    }
  }, [maintenanceData]);

  return (
    <>
      <Grid.Column className="completion-column pl-0 pt-0">
        <div
          className={
            openModal
              ? "completion-column-data no-border"
              : "completion-column-data"
          }
        >
          <div className="completion-cotainer d-flex justify-content-between mt-2 mb-6">
            <div className="completion-title d-flex">
              <span className="title">{t.dashboard.maintenance_card.mtbf}</span>
              <p
                style={{
                  position: "relative",
                  cursor: "pointer",
                  marginTop: "-10px",
                }}
                onMouseOver={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className={selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"}
              >
                <Tooltip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position={selectedLanguage == "en" ? "right" : "left"}
                  show={showTooltip}
                  textBoxWidth="140px"
                  fontSize="10px"
                  fontWeight="400"
                  fontFamily="Roboto"
                  padding="0px 0px 0px 8px"
                  borderRadius="5px"
                  static
                  arrowAlign="end"
                  moveRight="8px"
                  // moveDown="10px"
                >
                  <span
                    style={{ padding: "0px 10px" }}
                    className="work-order-tooltip"
                  >
                    {t.dashboard.asset_tooltip.category_asset}
                  </span>
                </Tooltip>
                <DashboardWOStatusIcon />
              </p>
            </div>

            <span
              style={{ marginTop: "-10px", gap: "10px" }}
              className="d-flex"
            >
              <span
                onClick={() => {
                  setOpenModal(true);
                  setModalData("MTBF");
                  setShowExpandTooltip(false);
                }}
                style={{ marginTop: "2px" }}
                className=""
              >
                {openModal ? null : (
                  <p
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      marginTop: "-10px",
                    }}
                    onMouseOver={() => setShowExpandTooltip(true)}
                    onMouseLeave={() => setShowExpandTooltip(false)}
                    className=" mt-1"
                  >
                    <Tooltip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position="top"
                      show={showExpandTooltip}
                      textBoxWidth="80px"
                      fontSize="10px"
                      fontWeight="400"
                      fontFamily="Roboto"
                      padding="8px 8px 8px 8px"
                      borderRadius="5px"
                      static
                      arrowAlign="start"
                      moveRight="5px"
                      moveDown="3px"
                    >
                      <span
                        style={{ padding: "0px 10px" }}
                        className="work-order-tooltip"
                      >
                        {t.dashboard.expand_full}
                      </span>
                    </Tooltip>
                    <FullArrow />
                  </p>
                )}
              </span>
              <p
                style={{
                  position: "relative",
                  cursor: "pointer",
                  marginTop: "-10px",
                }}
                onMouseOver={() => setShowAddCustomizedTooltip(true)}
                onMouseLeave={() => setShowAddCustomizedTooltip(false)}
                className=" mt-1"
              >
                <Tooltip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="top"
                  show={showAddCustomizedTooltip}
                  textBoxWidth="105px"
                  fontSize="10px"
                  fontWeight="400"
                  fontFamily="Roboto"
                  padding="8px 8px 8px 8px"
                  borderRadius="5px"
                  static
                  arrowAlign="end"
                  moveRight="0px"
                >
                  <span
                    style={{ padding: "0px 10px" }}
                    className="work-order-tooltip"
                  >
                    {t.dashboard.add_customized}
                  </span>
                </Tooltip>
                {/* <AddIconDashboard /> */}
              </p>
            </span>
          </div>
          {maintenanceData?.MTBF?.length > 0 ? (
            <div className="completion-status-part res">
              <Grid className="wo-data-container" columns={1}>
                <Grid.Column className="wo-data-chart  align-items-center text-center">
                  <div
                    className="scroll-data res"
                    style={{
                      overflowX: "auto",
                      minWidth: "500px",
                      paddingLeft: "8px",
                    }}
                  >
                    <div style={{ minWidth: "1000px" }}>
                      {/* Adjust minWidth as needed */}
                      <Chart
                        options={chartConfigMTBF?.options}
                        series={chartConfigMTBF?.series}
                        type="bar"
                        height={openModal ? 400 : 277}
                      />
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            </div>
          ) : (
            <NoDashboardData height="296px" />
          )}
        </div>
      </Grid.Column>
    </>
  );
};

export default MTBF;
