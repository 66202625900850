import React, { useEffect, useState } from 'react';

import {
  Grid,
  Divider,
  Image,
  List,
Icon,
  Image as SemanticImage,
} from 'semantic-ui-react';

import md5 from 'md5';
import { selectTranslations } from '../../../config/i18n/slice';

import { getCustomerByID, IMAGES_URL } from '../../../config/functions';
import QRComponentToPrint from '../preview/qrCodeComponent';
import ReactToPrint from 'react-to-print';
import { Country, State, City } from 'country-state-city';

import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { createRef } from 'react';

import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../../components/common/userProfilePlaceholder';
import { useSelector } from 'react-redux';
import {
  AssigneesIcon,
  FileDownloadIcon,
  LocationMapIcon,
  Printer,
} from '../../../../Assets/Icons/svg';
import useDisplayFormattedText from '../../../../hooks/useDisplayFormattedText';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ToolTip from 'react-power-tooltip';
import moment from 'moment';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';

function General({ data }) {
  const t = useSelector(selectTranslations);
  const { userPermissions,checkForLinkPermission, checkForPermission } = usePermissionsSimplified();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [showPrintTooltip, setPrintTooltip] = useState(false);
  const [showDownloadTooltip, setDownloadTooltip] = useState(false);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const qrRef = createRef();

  const roles=[
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,
     
      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description:"",
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,
     
      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,
     
      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,
   
      description: t.people.add_modal.viewer_description,
    },
    {
      key: 2,
      value: 6,
      text: t.people.add_modal.requester,
      description:
       t.people.add_modal.requester_desc
    },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ]

  // const roles = [
  //   {
  //     key: 0,
  //     value: 5,
  //     text: 'Admin',
  //   },
  //   {
  //     key: 98,
  //     value: 4,
  //     text: 'Super Admin',
  //   },
  //   {
  //     key: 89,
  //     value: 7,
  //     text: 'Supervisor',
  //   },
  //   {
  //     key: 1,
  //     value: 8,
  //     text: 'User',
  //   },
  //   {
  //     key: 2,
  //     value: 6,
  //     text: 'Requester only',
  //   },
  //   {
  //     key: 3,
  //     value: 3,
  //     text: 'Viewer only',
  //   },
  // ];
  // console.log('data', data);
  const history = useHistory();

  const peopleNameImg = (person) => {
    // console.log(person?.attributes);
    let filteredRole = roles.filter(
      (role) => person?.attributes?.role?.data?.id == role.value
    );
    let roleText = filteredRole && filteredRole[0] ? filteredRole[0].text : '';
    if (roleText)
      return person?.attributes?.profileImage?.data?.attributes?.url ? (
        <List.Item>
          <Image
            avatar
            title={
              person?.attributes?.firstName + ' ' + person?.attributes?.lastName
            }
            src={
              IMAGES_URL +
              person?.attributes?.profileImage?.data?.attributes?.url
            }
          />
          <List.Content>
            <List.Header as="a">
              {person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName}
            </List.Header>
            <span style={{ fontSize: '13px' }}>{roleText}</span>
            <List.Description style={{ fontSize: '12px' }}>
              Hour Rate:{' '}
              {person?.attributes?.userProfile?.data?.attributes?.hourRate} JOD
            </List.Description>
          </List.Content>
        </List.Item>
      ) : (
        <List.Item>
          <Image
            avatar
            title={
              person?.attributes?.firstName + ' ' + person?.attributes?.lastName
            }
            src={LetteredAvatarImageUrl(
              person?.attributes?.firstName + ' ' + person?.attributes?.lastName
            )}
          />
          <List.Content>
            <List.Header as="a">
              {person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName}
            </List.Header>
            <span style={{ fontSize: '13px' }}>{roleText}</span>
            <List.Description style={{ fontSize: '12px' }}>
              Hour Rate:{' '}
              {person.attributes?.userProfile?.data?.attributes?.hourRate} JOD
            </List.Description>
          </List.Content>
        </List.Item>
      );
  };

  let area = data?.attributes?.areas?.data;
  let areaString = '';
  if (area?.length > 0) {
    areaString = area.map((people) => people.attributes.name).join(', ');
  } else {
    areaString = t.common.na;
  }

  // let store = data?.attributes?.areas?.data?.filter(item => item?.attributes?.isStore === true);
  // let storeString = '';
  // if (store?.length > 0) {
  //   storeString = store.map((people) => people.attributes.name).join(', ');
  // } else {
  //   storeString = t.common.na;
  // }

  let createdByName = data?.attributes.createdByUser?.data ? (
    <div>
      <Image
        avatar
        title={
          data?.attributes.createdByUser?.data?.attributes?.firstName +
          ' ' +
          data?.attributes.createdByUser?.data?.attributes?.lastName
        }
        src={
          data?.attributes.createdByUser?.data?.attributes?.profileImage?.data
            ?.attributes?.url
            ? IMAGES_URL +
              data?.attributes.createdByUser?.data?.attributes?.profileImage
                ?.data?.attributes?.url
            : LetteredAvatarImageUrl(
                data?.attributes.createdByUser?.data?.attributes?.firstName +
                  ' ' +
                  data?.attributes.createdByUser?.data?.attributes?.lastName
              )
        }
      />
      <span style={{ marginLeft: '4px' }}>
        {data?.attributes.createdByUser?.data?.attributes?.firstName +
          ' ' +
          data?.attributes.createdByUser?.data?.attributes?.lastName}
      </span>
    </div>
  ) : (
    ' '
  );
  let customers = data.attributes?.customers?.data;
  if (customers?.length > 0) {
    customers = customers.map((people, index) => {
      if (people?.attributes?.logo?.data?.attributes?.url) {
        return (

          checkForLinkPermission('customer') ?
          <div
            className="d-flex mt-2"
            onClick={() => history.push(`/customers?id=${people?.attributes?.countId}`)}
          >
            <Image
              key={index}
              avatar
              title={people.attributes.name + ' ' + ' '}
              src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
              style={
                index !== 0
                  ? { marginLeft: '-6px', width: '34px', height: '34px' }
                  : { width: '34px', height: '34px' }
              }
            />

            <div
              className="work-order-link mt-1"
              basic
              style={{ marginLeft: '4px' }}
            >
              {people?.attributes?.name}
            </div>
            {/* <span className='ml-2'>
              {people?.attributes?.name }
            </span> */}
          </div> :  <div
            className="d-flex mt-2"
            // onClick={() => history.push(`/customers?id=${people.id}`)}
          >
            <Image
              key={index}
              avatar
              title={people.attributes.name + ' ' + ' '}
              src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
              style={
                index !== 0
                  ? { marginLeft: '-6px', width: '34px', height: '34px' }
                  : { width: '34px', height: '34px' }
              }
            />

            <div
              className=" mt-1"
              basic
              style={{ marginLeft: '4px' }}
            >
              {people?.attributes?.name}
            </div>
            {/* <span className='ml-2'>
              {people?.attributes?.name }
            </span> */}
          </div>
        );
      } else {

     
        return (
          checkForLinkPermission('customer') ?
          <div
            className="d-flex mt-2"
            onClick={() => history.push(`/customers?id=${people?.attributes?.countId}`)}
          >
            <UserProfilePlaceholder
              key={index}
              name={`${
                people?.attributes?.name ? people?.attributes?.name : ''
              } ${''}`}
              width={'34px'}
              height={'34px'}
            />
            <div
              className="work-order-link mt-1"
              basic
              style={{ marginLeft: '4px' }}
            >
              {people?.attributes?.name}
            </div>
          </div> :  <div
            className="d-flex mt-2"
            // onClick={() => history.push(`/customers?id=${people.id}`)}
          >
            <UserProfilePlaceholder
              key={index}
              name={`${
                people?.attributes?.name ? people?.attributes?.name : ''
              } ${''}`}
              width={'34px'}
              height={'34px'}
            />
            <div
              className=" mt-1"
              basic
              style={{ marginLeft: '4px' }}
            >
              {people?.attributes?.name}
            </div>
          </div>
        );
      }
    });
  } else {
    customers = '';
  }

  let vendors = data?.attributes.vendors?.data;

  if (vendors?.length > 0) {
    vendors = vendors.map((people, index) => {
      if (people?.attributes?.logo?.data?.attributes?.url) {
        return (
          checkForLinkPermission('vendor') ?
          <div
            className="d-flex"
            onClick={() => history.push(`/vendors?id=${people?.attributes?.countId}`)}
          >
            <Image
              key={index}
              avatar
              title={people.attributes.name + ' ' + ' '}
              src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
              style={
                index !== 0
                  ? { marginLeft: '-6px', width: '34px', height: '34px' }
                  : { width: '34px', height: '34px' }
              }
            />

            <div
              className="work-order-link mt-1"
              basic
              style={{ marginLeft: '4px' }}
            >
              {people?.attributes?.name}
            </div>
            {/* <span className='ml-2'>
              {people?.attributes?.name }
            </span> */}
          </div> :  
           <div
            className="d-flex"
            // onClick={() => history.push(`/vendors?id=${people.id}`)}
          >
            <Image
              key={index}
              avatar
              title={people.attributes.name + ' ' + ' '}
              src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
              style={
                index !== 0
                  ? { marginLeft: '-6px', width: '34px', height: '34px' }
                  : { width: '34px', height: '34px' }
              }
            />

            <div
              className=" mt-1"
              basic
              style={{ marginLeft: '4px' }}
            >
              {people?.attributes?.name}
            </div>
            {/* <span className='ml-2'>
              {people?.attributes?.name }
            </span> */}
          </div>
        );
      } else {
        return (
          checkForLinkPermission('vendor') ?
          <div
            className="d-flex mt-2"
            onClick={() => history.push(`/vendors?id=${people?.attributes?.countId}`)}
          >
            <UserProfilePlaceholder
              key={index}
              name={`${
                people?.attributes?.name ? people?.attributes?.name : ''
              } ${''}`}
              width={'34px'}
              height={'34px'}
            />
            <div
              className="work-order-link mt-1"
              basic
              style={{ marginLeft: '4px' }}
            >
              {people?.attributes?.name}
            </div>
          </div> : 
            <div
            className="d-flex mt-2"
            // onClick={() => history.push(`/vendors?id=${people.id}`)}
          >
            <UserProfilePlaceholder
              key={index}
              name={`${
                people?.attributes?.name ? people?.attributes?.name : ''
              } ${''}`}
              width={'34px'}
              height={'34px'}
            />
            <div
              className=" mt-1"
              basic
              style={{ marginLeft: '4px' }}
            >
              {people?.attributes?.name}
            </div>
          </div>
        );
      }
    });
  } else {
    vendors = '';
  }

  let customFields = data?.attributes?.customFields;

  if (customFields.length > 0) {
    customFields = customFields.map((el, index) => {
      return (
        <>
          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label location-label">
              {t.locations.form.label} {index + 1}
              {':'}
            </label>
            <p className="detail-intro-value">
              {el?.label ? el.label : t.common.na}
              {/* {sub.attributes.address ? sub.attributes.address : t.common.na} */}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label location-label">
              {t.locations.form.value} {index + 1}
              {':'}
            </label>
            <p className="detail-intro-value">
              {el?.value ? el.value : t.common.na}
              {/* {sub.attributes.address ? sub.attributes.address : t.common.na} */}
            </p>
          </div>
        </>
      );
    });
  } else {
    customFields = '';
  }

  let peopleName = data?.attributes?.users?.data;
  if (peopleName?.length > 0) {
    peopleName = peopleName.map((person, index) => {
      let filteredRole = roles.filter(
        (role) => person?.attributes?.role?.data?.id == role.value
      );
      let roleText =
        filteredRole && filteredRole[0] ? filteredRole[0].text : '';
      if (roleText)
        return person?.attributes?.profileImage?.data?.attributes?.url ? (

          checkForLinkPermission('people') ?
          <div className="d-flex mb-5">
            <Image
              avatar
              title={
                person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName
              }
              src={
                IMAGES_URL +
                person?.attributes?.profileImage?.data?.attributes?.url
              }
              height="34px"
              width="34px"
            />

            <div style={{ marginLeft: '4px' }}  onClick={() => history.push(`/people?id=${person?.attributes?.countId}`)} className="work-order-link mt-1">
              {person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName}
            </div>
          </div> : <div className="d-flex mb-5">
            <Image
              avatar
              title={
                person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName
              }
              src={
                IMAGES_URL +
                person?.attributes?.profileImage?.data?.attributes?.url
              }
              height="34px"
              width="34px"
            />

            <div style={{ marginLeft: '4px' }} className=" mt-1">
              {person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName}
            </div>
          </div>
        ) : (
          checkForLinkPermission('people') ?
          <div className="d-flex mb-5">
            <Image
              height="34px"
              width="34px"
              avatar
              title={
                person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName
              }
              src={LetteredAvatarImageUrl(
                person?.attributes?.firstName +
                  ' ' +
                  person?.attributes?.lastName
              )}
            />

            <div className="work-order-link mt-1" onClick={() => history.push(`/people?id=${person?.attributes?.countId}`)}>
              {person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName}
            </div>
          </div> :  <div className="d-flex mb-5">
            <Image
              height="34px"
              width="34px"
              avatar
              title={
                person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName
              }
              src={LetteredAvatarImageUrl(
                person?.attributes?.firstName +
                  ' ' +
                  person?.attributes?.lastName
              )}
            />

            <div className=" mt-1">
              {person?.attributes?.firstName +
                ' ' +
                person?.attributes?.lastName}
            </div>
          </div>
        );
    });
  } else {
    peopleName = '';
  }

  let subLocations = data?.attributes?.subLocations?.data;
  if (subLocations?.length > 0) {
    subLocations = subLocations.map((sub, index) => {
      const subCitiesOfCountry = State.getStatesOfCountry(
        sub.attributes.country
      );
      const sub_city = sub.attributes.city
        ? subCitiesOfCountry.filter((city) => {
            return city.isoCode == sub.attributes.city;
          })[0]?.name
        : t.common.na;

      let createdByUserSub = sub.attributes.createdByUser?.data?.attributes ? (
        <div>
          <Image
            avatar
            title={
              sub.attributes.createdByUser?.data?.attributes.firstName +
              ' ' +
              sub.attributes.createdByUser?.data?.attributes.lastName
            }
            src={
              sub.attributes.createdByUser?.data?.attributes?.profileImage?.data
                ?.attributes?.url
                ? IMAGES_URL +
                  sub.attributes.createdByUser?.data?.attributes?.profileImage
                    ?.data?.attributes?.url
                : LetteredAvatarImageUrl(
                    sub.attributes.createdByUser?.data?.attributes.firstName +
                      ' ' +
                      sub.attributes.createdByUser?.data?.attributes.lastName
                  )
            }
            style={{ width: '20px', height: '20px' }}
          />
          <span style={{ marginLeft: '4px' }}>
            {sub.attributes.createdByUser?.data?.attributes.firstName}
          </span>
        </div>
      ) : (
        t.common.na
      );
      // let customerIMG = t.common.na;
      // if (sub.attributes.customer.data) {
      //   customerIMG = sub.attributes.customer.data
      //     .attributes?.logo.data ? (
      //     <div>
      //       <Image
      //         avatar
      //         title={
      //           sub.attributes.customer.data?.attributes
      //             .name
      //         }
      //         src={
      //           IMAGES_URL +
      //           sub.attributes.customer.data?.attributes
      //             ?.logo?.data?.attributes?.url
      //         }
      //       />
      //       <span style={{ marginLeft: '4px' }}>
      //         {
      //           sub.attributes.customer.data.attributes
      //             .name
      //         }
      //       </span>
      //     </div>
      //   ) : (
      //     <div>
      //       <Image
      //         avatar
      //         title={
      //           sub.attributes.customer.data.attributes
      //             .name
      //         }
      //         src={LetteredAvatarImageUrl(
      //           sub.attributes.customer.data.attributes
      //             .name
      //         )}
      //       />
      //       <span style={{ marginLeft: '4px' }}>
      //         {
      //           sub.attributes.customer.data.attributes
      //             .name
      //         }
      //       </span>
      //     </div>
      //   );
      // }
      let subCustomers = sub.attributes?.customers?.data;
      if (subCustomers?.length > 0) {
        subCustomers = subCustomers.map((people, index) => {
          if (people?.attributes?.logo?.data?.attributes?.url) {
            return (
              checkForLinkPermission('customer') ? 
              <div
                className="d-flex"
                onClick={() => history.push(`/customers?id=${people.attributes?.countId}`)}
              >
                <Image
                  key={index}
                  avatar
                  title={people.attributes.name + ' ' + ' '}
                  src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                  style={
                    index !== 0
                      ? { marginLeft: '-6px', width: '34px', height: '34px' }
                      : { width: '34px', height: '34px' }
                  }
                />

                <div
                  className="work-order-link mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                </div>
                {/* <span className='ml-2'>
                  {people?.attributes?.name }
                </span> */}
              </div> :   <div
                className="d-flex"
                // onClick={() => history.push(`/customers?id=${people.id}`)}
              >
                <Image
                  key={index}
                  avatar
                  title={people.attributes.name + ' ' + ' '}
                  src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                  style={
                    index !== 0
                      ? { marginLeft: '-6px', width: '34px', height: '34px' }
                      : { width: '34px', height: '34px' }
                  }
                />

                <div
                  className="mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                </div>
                {/* <span className='ml-2'>
                  {people?.attributes?.name }
                </span> */}
              </div>
            );
          } else {
            return (
              checkForLinkPermission('customer') ?
              <div
                className="d-flex"
                onClick={() => history.push(`/customers?id=${people?.attributes?.countId}`)}
              >
                <UserProfilePlaceholder
                  key={index}
                  name={`${
                    people?.attributes?.name ? people?.attributes?.name : ''
                  } ${''}`}
                  width={'34px'}
                  height={'34px'}
                />
                <div className=" mt-1" basic style={{ marginLeft: '4px' }}>
                  {people?.attributes?.name}
                </div>
              </div> :
                <div
                className="d-flex"
                // onClick={() => history.push(`/customers?id=${people.id}`)}
              >
                <UserProfilePlaceholder
                  key={index}
                  name={`${
                    people?.attributes?.name ? people?.attributes?.name : ''
                  } ${''}`}
                  width={'34px'}
                  height={'34px'}
                />
                <div className=" mt-1" basic style={{ marginLeft: '4px' }}>
                  {people?.attributes?.name}
                </div>
              </div>
            );
          }
        });
      } else {
        subCustomers = t.common.na;
      }

      let subVendors = sub?.attributes;

      if (subVendors?.length > 0) {
        subVendors = subVendors.map((people, index) => {
          if (people?.attributes?.logo?.data?.attributes?.url) {
            return (
              checkForLinkPermission('vendor') ? 
              <div
                className="d-flex mt-2"
                onClick={() => history.push(`/vendors?id=${people.attributes?.countId}`)}
              >
                <Image
                  key={index}
                  avatar
                  title={people.attributes.name + ' ' + ' '}
                  src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                  style={
                    index !== 0
                      ? { marginLeft: '-6px', width: '34px', height: '34px' }
                      : { width: '34px', height: '34px' }
                  }
                />

                <div
                  className="work-order-link mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                </div>
                {/* <span className='ml-2'>
                  {people?.attributes?.name }
                </span> */}
              </div> :  <div
                className="d-flex mt-2"
                // onClick={() => history.push(`/vendors?id=${people.id}`)}
              >
                <Image
                  key={index}
                  avatar
                  title={people.attributes.name + ' ' + ' '}
                  src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                  style={
                    index !== 0
                      ? { marginLeft: '-6px', width: '34px', height: '34px' }
                      : { width: '34px', height: '34px' }
                  }
                />

                <div
                  className=" mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                </div>
                {/* <span className='ml-2'>
                  {people?.attributes?.name }
                </span> */}
              </div>
            );
          } else {
            return (
              checkForLinkPermission('vendor') ? 
              <div
                className="d-flex mt-2"
                onClick={() => history.push(`/vendors?id=${people.attributes?.countId}`)}
              >
                <UserProfilePlaceholder
                  key={index}
                  name={`${
                    people?.attributes?.name ? people?.attributes?.name : ''
                  } ${''}`}
                  width={'34px'}
                  height={'34px'}
                />
                <div
                  className="work-order-link mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                </div>
              </div> :   <div
                className="d-flex mt-2"
                // onClick={() => history.push(`/vendors?id=${people.id}`)}
              >
                <UserProfilePlaceholder
                  key={index}
                  name={`${
                    people?.attributes?.name ? people?.attributes?.name : ''
                  } ${''}`}
                  width={'34px'}
                  height={'34px'}
                />
                <div
                  className=" mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                </div>
              </div>
            );
          }
        });
      } else {
        subVendors = t.common.na;
      }

      return (
        <>
          <p className="detail-intro-label location-label">{sub?.attributes.name}</p>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label location-label">
              {t.locations.table.country}
              {':'}
            </label>
            <p className="detail-intro-value">
              {sub?.attributes?.country
                ? Country.getCountryByCode(sub?.attributes?.country)?.name
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label location-label">
              {t.locations.table.city}
              {':'}
            </label>
            <p className="detail-intro-value">{sub_city}</p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label location-label">
              {t.locations.table.address}
              {':'}
            </label>
            <p className="detail-intro-value">
              {sub.attributes.address ? sub.attributes.address : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label location-label">
              {t.locations.table.customer}
              {':'}
            </label>
            <p className="detail-intro-value">
              {subCustomers ? subCustomers : t.common.na}
            </p>
          </div>

          {/* <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label location-label">
              {t.locations.form.vendors}
              {':'}
            </label>
            <p className="detail-intro-value">
              {subVendors ? subVendors : t.common.na}
            </p>
          </div> */}
        </>
      );

      // <>
      //   <h3>{sub?.attributes.name}</h3>

      //   <div className="d-flex align-items-center mt-5">
      //     <label className="detail-intro-label location-label">
      //       {t.locations.table.address}
      //       {':'}
      //     </label>
      //     <p className="detail-intro-value">
      //       {sub?.attributes.address || t.common.na}
      //     </p>
      //   </div>
      // </>;
      // <List.Item className="d-flex mb-2">
      //    <div className="d-flex align-items-center">
      //   <List.Icon name="map outline" />

      //    </div>
      //   <List.Content className="p-0 ml-3 ">
      //     <List.Header as="a">{sub?.attributes.name}</List.Header>
      //     <List.Description style={{ fontSize: '12px' }}>
      //       {sub?.attributes.address}
      //     </List.Description>
      //   </List.Content>
      // </List.Item>
    });
  } else {
    subLocations = t.common.na;
  }

  let teamName = data?.attributes?.teams?.data;

  if (teamName?.length > 0) {
    
    teamName = teamName.map((team, index) => (
      checkForLinkPermission('team') ? 
      <div className="d-flex mb-5">
        <Image
          avatar
          title={team?.attributes.name}
          src={LetteredAvatarImageUrl(team?.attributes.name)}
          height="34px"
          width="34px"
        />

        <div style={{ marginLeft: '4px' }} onClick={() => history.push(`/teams?id=${team?.attributes?.countId}`)} className="work-order-link mt-1 ">
          {team?.attributes?.name ? team.attributes.name : t.common.na}
        </div>
      </div> :
          <div className="d-flex mb-5">
          <Image
            avatar
            title={team?.attributes.name}
            src={LetteredAvatarImageUrl(team?.attributes.name)}
            height="34px"
            width="34px"
          />
  
          <div style={{ marginLeft: '4px' }} className="mt-1 ">
            {team?.attributes?.name ? team.attributes.name : t.common.na}
          </div>
        </div>
    ));
  } else {
    teamName = '';
  }

  let vendorName = (vendor) =>
    vendor?.attributes?.logo?.data ? (
      <List.Item>
        <Image
          avatar
          title={vendor?.attributes.name}
          src={IMAGES_URL + vendor?.attributes?.logo?.data?.attributes?.url}
        />
        <List.Content>
          <List.Header as="a">{vendor?.attributes.name}</List.Header>
          <List.Description style={{ fontSize: '12px' }}>
            {vendor?.attributes.serviceType}
          </List.Description>
        </List.Content>
      </List.Item>
    ) : (
      <List.Item>
        <Image
          avatar
          title={vendor?.attributes.name}
          src={LetteredAvatarImageUrl(vendor?.attributes.name)}
        />
        <List.Content>
          <List.Header as="a">{vendor?.attributes.name}</List.Header>
          <List.Description style={{ fontSize: '12px' }}>
            {vendor?.attributes.serviceType}
          </List.Description>
        </List.Content>
      </List.Item>
    );

  return (
    <div className="preview-details-wrapper">
      <div className="data-header">
      <div
          style={
            data?.attributes?.image?.data?.attributes
              ? { borderRadius: "8px", background: "lightgrey" }
              : {}
          }
          className="d-flex justify-content-center align-items-center"
        >
          <SemanticImage
            src={
              data?.attributes.image?.data?.attributes
                ? IMAGES_URL + data.attributes.image.data.attributes.url
                : toAbsoluteUrl('/media/default-img.png')
            }
            size="medium"
            className={
              data?.attributes?.image?.data?.attributes
                ? " data-header-image"
                : "no-img"
            }
          />
        </div>
        <div className="header-title mt-5 d-flex justify-content-between align-items-center">
          <div className="header-pill-container">
            <p className="detail-title">{data?.attributes?.name}</p>
          </div>
       
        </div>
        {/* <div className="d-flex justify-content-between align-items-center mt-3 mb-7">
          <p className="detail-updated-title">
            {t.assets.table.updatedAt}
            {':'}{' '}
            <span>
              {
                <Moment
                  format="DD-MM-YYYY LT"
                  date={data?.attributes.updatedAt}
                />
              }
            </span>
          </p>
        </div> */}
      </div>
      {/* <div className="intro">
        <div className="flexbox align-center" style={{ fontSize: '15px' }}>
          <span style={{ marginRight: '2px' }}>{t.common.created_by}:</span>
          {createdByName}
          <span style={{ margin: '0 2px' }}>@</span>
          <Moment format="DD-MM-YYYY LT" date={data?.attributes.updatedAt} />
        </div>

        <h2>{data?.attributes.name}</h2>
        <p>{data?.attributes.address}</p>
      </div> */}
      <Divider />

      <div className="detail-intro">
        <div className="d-flex align-items-center mt-6">
          <label className="detail-intro-label location-label">
            {t.locations.table.country}
            {':'}
          </label>
          <p className="detail-intro-value">
            {data?.attributes?.country
              ? Country.getCountryByCode(data?.attributes.country)?.name
              : t.common.na}
          </p>
        </div>

        <div className="d-flex align-items-center mt-5">
          <label className="detail-intro-label location-label">
            {t.locations.table.city}
            {':'}
          </label>
          <p className="detail-intro-value">
            {data?.attributes?.city
              ? State.getStateByCodeAndCountry(
                  data?.attributes?.city,
                  data?.attributes?.country
                )?.name
              : t.common.na}
          </p>
        </div>

        <div className="d-flex align-items-center mt-5">
          <label className="detail-intro-label location-label">
            {t.locations.table.address}
            {':'}
          </label>
          <p className="detail-intro-value">
            {data?.attributes.address || t.common.na}
          </p>
        </div>
        {!data?.attributes?.isParent ? 
        <div className="d-flex align-items-center mt-5">
          <label className="detail-intro-label location-label">
            {t.assets.form.area}
            {':'}
          </label>
          <p className="detail-intro-value">
            {areaString}
            {/* {sub.attributes.address ? sub.attributes.address : t.common.na} */}
          </p>
        </div> : null}

        {/* <div className="d-flex align-items-center mt-5">
          <label className="detail-intro-label location-label">
            {t.locations.store}
            {':'}
          </label>
          <p className="detail-intro-value">
            {storeString}
           
          </p>
        </div> */}

        <div className="d-flex align-items-center mt-5 mb-5">
          <label className="detail-intro-label">
            {t.locations.form.propertyType}
            {':'}
          </label>
          <p className="detail-intro-value">
            {(() => {
              const locationData = data.attributes?.propertyType;

              if (locationData && Array.isArray(locationData)) {
                const displayText = locationData
                  .map((location) => {
                    const nameParts = location?.property;

                    return nameParts;
                  })
                  .join(', '); // Adjust the join character as needed

                return <span>{displayText ? displayText : t.common.na}</span>;
              } else {
                return <span>{t.common.na}</span>;
              }
            })()}
          </p>
        </div>

        {customFields.length > 0 ? customFields : null}

        {/* <Grid columns="equal">
          <Grid.Column>
            <li className="list-item">
              <label className="item-label">{t.locations.form.country}</label>
              <div className="item-value">
                {data?.attributes.country
                  ? Country.getCountryByCode(data?.attributes.country).name
                  : t.common.na}
              </div>
            </li>
          </Grid.Column>
          <Grid.Column>
            <li className="list-item">
              <label className="item-label">{t.locations.form.city}</label>
              <div className="item-value">
                {data?.attributes?.city
                  ? State.getStateByCodeAndCountry(
                      data?.attributes?.city,
                      data?.attributes?.country
                    ).name
                  : t.common.na}
              </div>
            </li>
          </Grid.Column>
        </Grid> */}

        <Divider />
        <div className="d-flex mt-2">
          <label className="workorder-intro-label location-label mt-5">
              {t.locations.table.customer}
            {':'}
          </label>
          <p
            className={`workorder-intro-value ${
              customers.length > 0 ? 'mt-3' : 'mt-5'
            }`}
          >
            {customers.length > 0 ? customers : t.common.na}
          </p>
        </div>

        <div className="d-flex mb-5">
          <label className="workorder-intro-label location-label mt-5">
            {t.locations.form.vendors}
            {':'}
          </label>
          <p
            className={`workorder-intro-value ${
              vendors.length > 0 ? 'mt-3' : 'mt-5'
            }`}
          >
            {vendors.length > 0 ? vendors : t.common.na}
          </p>
        </div>

        {/* <Grid columns="equal">
          <Grid.Column>
            <li className="list-item">
              <label className="item-label">{t.locations.form.customer}</label>
              <div className="item-value">{customers || t.common.na}</div>
            </li>
          </Grid.Column>
          <Grid.Column>
            <li className="list-item">
              <label className="item-label">{t.locations.form.vendors}</label>
              <div className="item-value">
                {data?.attributes.vendors?.data.length > 0 ? (
                  <List relaxed vertical>
                    {data.attributes.vendors.data.map((vendor) =>
                      vendorName(vendor)
                    )}
                  </List>
                ) : (
                  t.common.na
                )}
              </div>
            </li>
          </Grid.Column>
          <Grid.Column>
            <li className="list-item">
              <label className="item-label">
                {t.locations.form.parent_location}
              </label>
              <div className="item-value">
                {data?.attributes.parentLocation.data
                  ? data.attributes.parentLocation.data.attributes.name
                  : t.common.na}
              </div>
            </li>
          </Grid.Column>
        </Grid> */}
        {/* <Grid columns="equal">
          <Grid.Column>
            <li className="list-item">
              <label className="item-label">{t.locations.form.qr_code}</label>
              <div className="item-value flexbox align-center justify-between">
                <div className="d-flex">
                  <div>
                    <QRComponentToPrint ref={qrRef} data={data} />
                  
                  </div>
                  <div className="m-3">
                    <ReactToPrint
                      trigger={() => (
                        <Icon
                          name="print"
                          color="blue"
                          size="large"
                          className="c-pointer"
                          // onClick={handlePrint}
                        />
                      )}
                      content={() => qrRef.current}
                    />
                  </div>
                </div>
              </div>
            </li>
          </Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid> */}
        <Divider />

        {/* <div className="d-flex align-items-center mt-5">
          <AssigneesIcon />
          <label className={`workorder-intro-value ml-2 mt-3 mr-2`}>
            {t.locations.form.assignees}
          </label>
        </div> */}

        <div className="d-flex mt-5">
          <label className="workorder-intro-label location-label mt-2">
            {t.locations.form.people}
            {':'}
          </label>
          <p className={`workorder-intro-value mt-2`}>
            {peopleName.length > 0 ? peopleName : t.common.na}
          </p>
        </div>

        <div className={`d-flex ${peopleName.length > 0 ? 'mt-0' : 'mt-5'}`}>
          <label className="workorder-intro-label location-label mt-2">
            {t.locations.form.teams}
            {':'}
          </label>
          <p className={`workorder-intro-value mt-2`}>
            {teamName.length > 0 ? teamName : t.common.na}
          </p>
        </div>
        

        <Divider />

        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label location-label ">
            {t.assets.filter.qrcode}
            {":"}
          </label>
          <p className="workorder-intro-value set-width">
          <div className="d-flex">
          <div>
            <QRComponentToPrint
              titleData={t.assets.table.ID}
              titleName={t.assets.table.name}
              ref={qrRef}
              data={{
                ...data,
                attributes: {
                  name: data?.attributes?.name,
                  serialNumber: data?.id,
                  count: data?.attributes?.countId,
                },
              }}
              titleString={'LT-'}
            />
          </div>
          <div className="m-3">
            <ReactToPrint
              trigger={() => (
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setPrintTooltip(true)}
                  onMouseLeave={() => setPrintTooltip(false)}
                >
                  <Printer />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top center"
                    show={showPrintTooltip}
                    textBoxWidth="35px"
                    arrowAlign="center"
                    fontSize="9px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="5px 5px 5px 8px"
                    borderRadius="6px"
                    static
                    moveUp="5px"
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.print}
                    </span>
                  </ToolTip>
                </span>
              )}
              content={() => {
                return qrRef.current;
              }}
            />
            <span
              className="ml-2 mr-2 c-pointer"
              style={{ position: 'relative' }}
              onMouseOver={() => setDownloadTooltip(true)}
              onMouseLeave={() => setDownloadTooltip(false)}
              onClick={() => {
                qrRef.current.exposeDownloadFunction();
              }}
            >
              <FileDownloadIcon />
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position="top center"
                show={showDownloadTooltip}
                textBoxWidth="92px"
                arrowAlign="center"
                fontSize="9px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="5px 5px 5px 8px"
                borderRadius="6px"
                static
                moveUp="5px"
              >
                <span className="work-order-preview-tooltip">
                  {t.common.download_QR}
                </span>
              </ToolTip>
            </span>
          </div>
        </div>
          </p>
        </div>
        {/* <p className='workorder-intro-value ml-0 mr-0 '>{t.assets.filter.qrcode}</p>
        <div className="d-flex">
          <div>
            <QRComponentToPrint
              titleData={t.assets.table.ID}
              titleName={t.assets.table.name}
              ref={qrRef}
              data={{
                ...data,
                attributes: {
                  name: data?.attributes?.name,
                  serialNumber: data?.id,
                  count: data?.attributes?.countId,
                },
              }}
              titleString={'LT-'}
            />
          </div>
          <div className="m-3">
            <ReactToPrint
              trigger={() => (
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setPrintTooltip(true)}
                  onMouseLeave={() => setPrintTooltip(false)}
                >
                  <Printer />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top center"
                    show={showPrintTooltip}
                    textBoxWidth="35px"
                    arrowAlign="center"
                    fontSize="9px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="5px 5px 5px 8px"
                    borderRadius="6px"
                    static
                    moveUp="5px"
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.print}
                    </span>
                  </ToolTip>
                </span>
              )}
              content={() => {
                return qrRef.current;
              }}
            />
            <span
              className="ml-2 mr-2 c-pointer"
              style={{ position: 'relative' }}
              onMouseOver={() => setDownloadTooltip(true)}
              onMouseLeave={() => setDownloadTooltip(false)}
              onClick={() => {
                qrRef.current.exposeDownloadFunction();
              }}
            >
              <FileDownloadIcon />
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position="top center"
                show={showDownloadTooltip}
                textBoxWidth="92px"
                arrowAlign="center"
                fontSize="9px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="5px 5px 5px 8px"
                borderRadius="6px"
                static
                moveUp="5px"
              >
                <span className="work-order-preview-tooltip">
                  {t.common.download_QR}
                </span>
              </ToolTip>
            </span>
          </div>
        </div> */}
        <Divider />
        <div className="d-flex align-items-center mt-5">
          <label className="workorder-intro-label location-label grey">
            {t.workOrders.table.createdByUser}
            {':'}
          </label>
          <p className="workorder-intro-value grey">
            {data?.attributes?.createdByUser?.data?.attributes?.firstName &&
            data?.attributes?.createdByUser?.data?.attributes?.lastName
              ? data?.attributes?.createdByUser?.data?.attributes?.firstName +
                ' ' +
                data?.attributes?.createdByUser?.data?.attributes?.lastName
              : t.common.na}
            {', '}
            <Moment format="DD-MM-YYYY LT" date={data?.attributes?.createdAt} />
          </p>
        </div>

        <div className="d-flex align-items-center mt-3">
          <label className="workorder-intro-label location-label grey">
            {t.common.update_by}
            {':'}
          </label>
          <p className="workorder-intro-value grey">
            {data?.attributes?.updatedByUser?.data
              ? data?.attributes?.updatedByUser?.data?.attributes?.firstName +
                ' ' +
                data?.attributes?.updatedByUser?.data?.attributes?.lastName +
                ', ' +
                moment(data?.attributes?.updatedAt).format('DD-MM-YYYY LT')
              : t.common.na}
          </p>
        </div>
        {/* 
        <div className="d-flex align-items-center mt-5">
          <LocationMapIcon />
          <label className="workorder-intro-value ml-2 mr-0 mt-2">
            {t.locations.form.sub_locations}
          </label>
        </div>

        {subLocations.length > 0 ? subLocations : <>{t.common.na}</>} */}

        {/* {data?.attributes.customFields?.length > 0 ? (
          <>
            <Grid columns="2">
              {data.attributes.customFields.map((data) => (
                <Grid.Column>
                  <li className="list-item">
                    <label className="item-label">{data.label}</label>
                    <div className="item-value">{data.value}</div>
                  </li>
                </Grid.Column>
              ))}
            </Grid>
            <Divider />
          </>
        ) : (
          ''
        )} */}
      </div>
    </div>
  );
}

export default General;
