import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import {
  AddIconDashboard,
  DashboardWOStatusIcon,
  FullArrow,
} from "../../../../Assets/Icons/svg";
import NoDashboardData from "../noDashboardData";

const ResponseTimeByTechnician = ({
  data12,
  dashBoardData,
  openModal,
  setModalData,
  setOpenModal,
}) => {
  const t = useSelector(selectTranslations);
  const [showAssign, setShowAssign] = useState(false);
  const [technicianData, setTechnicianData] = useState([]);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  useEffect(() => {
    if (dashBoardData?.responseTimesByTechnician?.length > 0) {
      setTechnicianData(dashBoardData?.responseTimesByTechnician);
    }
  }, [dashBoardData]);
  return (
    <Grid.Column className=" completion-column pl-0 pt-0">
      <div
        className={
          openModal
            ? "completion-column-data no-border"
            : "completion-column-data"
        }
      >
        <div className="completion-cotainer d-flex justify-content-between mt-2">
          <div className="completion-title d-flex">
            <span className="title">{t.dashboard.cards.technician_res}</span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowAssign(true)}
              onMouseLeave={() => setShowAssign(false)}
              className={selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"}
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "en" ? "right" : "left"}
                show={showAssign}
                textBoxWidth="140px"
                fontSize="10px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="0px 0px 0px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="8px"
                // moveDown="10px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.tooltip.response_technician}
                </span>
              </Tooltip>
              <DashboardWOStatusIcon />
            </p>
          </div>

          <span style={{ marginTop: "-10px", gap: "10px" }} className="d-flex">
            <span
              onClick={() => {
                setOpenModal(true);
                setModalData("ResponseByTechnician");
                setShowExpandTooltip(false);
              }}
              style={{ marginTop: "2px" }}
              className=""
            >
              {openModal ? null : (
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowExpandTooltip(true)}
                  onMouseLeave={() => setShowExpandTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showExpandTooltip}
                    textBoxWidth="80px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="start"
                    moveRight="5px"
                    moveDown="3px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.expand_full}
                    </span>
                  </Tooltip>
                  <FullArrow />
                </p>
              )}
            </span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowAddCustomizedTooltip(true)}
              onMouseLeave={() => setShowAddCustomizedTooltip(false)}
              className=" mt-1"
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "ar" ? "right" : "left"}
                show={showAddCustomizedTooltip}
                textBoxWidth="105px"
                fontSize="10px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="8px 8px 8px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="0px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.add_customized}
                </span>
              </Tooltip>
              {/* <AddIconDashboard /> */}
            </p>
          </span>
        </div>
        {dashBoardData?.responseTimesByTechnician?.length >0 ? 
        <div className="completion-status-part">
          <Grid className="wo-data-container" columns={1}>
            {/* <Grid.Column className="wo-data-chart d-flex align-items-center text-center">
    <div className="w-100">
   
      <Chart
        options={chartOptions}
        series={[70, 90, 50, 10]}
        type="donut"
        height={350}
      />
    </div>
  </Grid.Column> */}

            <Grid.Column className="wo-data-chart d-flex align-items-center text-center">
              <div className="resolution-time-table w-100">
                <div
                  className={
                    openModal ? "table-container h-500" : "table-container"
                  }
                >
                  {dashBoardData?.responseTimesByTechnician?.length > 0 ? (
                    <table style={{ width: "-webkit-fill-available" }}>
                      <thead>
                        <tr>
                          <th rowspan="2">
                            <div
                              style={
                                openModal
                                  ? { marginTop: "-27px", width: "327px" }
                                  : { marginTop: "-27px" }
                              }
                            >
                              {t.dashboard.cards.technician_name}
                            </div>
                          </th>
                          <th rowspan="2">
                            <div
                              style={
                                openModal
                                  ? {
                                      marginTop: "-27px",
                                      width:
                                        selectedLanguage === "en"
                                          ? "169px"
                                          : "175px",
                                    }
                                  : {
                                      marginTop: "-27px",
                                      width:
                                        selectedLanguage === "en"
                                          ? "96px"
                                          : "104px",
                                    }
                              }
                            >
                              {t.dashboard.cards.no_of_WO}
                            </div>
                          </th>
                          <th className="text-center" colspan="3">
                            <div style={openModal ? { marginTop: "-6px", width: "242px" } : { marginTop: "-6px", width: "156px"}}>
                              {" "}
                              {t.dashboard.cards.avg_time}
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <th>{t.dashboard.cards.high}</th>
                          <th>{t.dashboard.cards.low}</th>
                          <th>{t.dashboard.cards.med}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {technicianData?.length > 0 &&
                          technicianData.map((technician, index) => (
                            <tr key={index}>
                              <td style={{ minWidth: "186px" }}>
                                {technician?.technicianName}
                              </td>
                              <td>
                                <div
                                  style={{ width: "96px", textAlign: "center" }}
                                >
                                  {technician?.workOrderCount}
                                </div>
                              </td>
                              <td>{technician?.avgResponseTimeHigh}</td>
                              <td>{technician?.avgResponseTimeMedium}</td>
                              <td>{technician?.avgResponseTimeLow}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : null}
                </div>
              </div>
              {/* <div className="status-container">
      {statusOptions.map((status, index) => (
        <React.Fragment key={index}>
          <div
            style={{ gap: '16px' }}
            className="item-value mb-3 d-flex alignp-items-center justify-content-end"
          >
            <div className="label-text">
              {status.label}
              <p className=" text-right">15</p>
            </div>
            <div className="">
              <Label
                circular
                empty
                color={status.color}
                style={{
                  marginRight: '5px',
                  width: '12px',
                  height: '12px',
                }}
                size="medium"
              />
            </div>
          </div>
        </React.Fragment>
      ))}
    </div> */}
            </Grid.Column>
          </Grid>
        </div> : <NoDashboardData height="324px"/> }
      </div>
    </Grid.Column>
  );
};

export default ResponseTimeByTechnician;
