import React, { useState, useCallback } from "react";
import { ResizeHandleIcon } from "../../../Assets/Icons/svg";

// Resize Handle Component
const ResizeHandle = ({ onResize,setLocalIsResizing,IsResizing }) => {
  
   
  const [isResizing, setIsResizing] = useState(false);
  

  const handleMouseDown = (e) => {
    setLocalIsResizing(true)
    
    e.stopPropagation(); // Prevent sorting on resize start
    setIsResizing(true);
  
  };

  const handleMouseUp = (e) => {
   
    e.stopPropagation(); 
    setLocalIsResizing(false)
    setIsResizing(false);
   
};

  const handleMouseMove = useCallback(
    (e) => {
        e.stopPropagation(); 
      if (isResizing) {
       
        onResize(e.movementX);
      }
    },
    [isResizing, onResize]
  );

  React.useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleMouseMove]);

  return (
    <div
      onMouseDown={handleMouseDown}
      style={{
        cursor: "col-resize",
        display: "inline-block",
        paddingLeft: "4px",
        paddingRight: "4px",
      }}
    >
      <ResizeHandleIcon/>
    </div>
  );
};
export default ResizeHandle;