import React from 'react'
import { selectTranslations } from '../../config/i18n/slice';
import { useSelector } from 'react-redux';
import { NoDashboardIcon } from '../../../Assets/Icons/svg';

const NoDashboardData = ({height = "326px"}) => {
    const t = useSelector(selectTranslations);
  return (
    <div style={{minHeight:height}} className="d-flex flex-column align-items-center justify-content-center">
                        <NoDashboardIcon />
                        <p
                          style={{
                            color: "#44546F",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                          className="mt-3"
                        >
                          {t.common.no_data}
                        </p>
                      </div>

  )
}

export default NoDashboardData