import React, { useEffect, useState } from "react";
import {
  Image,
  Button,
  Header,
  Icon,
  Placeholder,
  Modal,
  Input,
  DropdownItem,
  DropdownMenu,
  Loader,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import Moment from "react-moment";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import DataTable from "react-data-table-component";
import Pagination from "../../components/common/CommonPagination";
import JobTitleBulkUploadResult from "../../components/modal/job_title/JobtitleBulkUploadResult";

import {
  getAllJobTitles,
  updateJobTitle,
  deleteJobTitle,
  IMAGES_URL,
} from "../../config/functions";
import { toast } from "react-toastify";
import moment from "moment";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import JobTitleCreateModal from "../../components/modal/job_title/JobTitleCreateModal";
import useGetCurrentTimeAccZone from "../../../hooks/useGetCurrentTimeAccZone";
import DateConvert from "../../../utilFunctions/DateFormat";
import {
  AssetIcon,
  ProcedurePlusIcon,
  WhiteDownChevron,
  ShareIcon,
  DownloadIcon,
  PdfIcon,
  ArrowLeft,
  ArrowRight,
  CloseIcon,
  Fail,
  JobtitleIcon,
  CategoryEditIcon,
  DeleteIcon,
  SearchIcon,
  Close,
  UploadIcon,
  JobTitleBlueIcon,
  SortIcon,
  CreateIcon,
  ActionBtnIcon,
} from "../../../Assets/Icons/svg";
import AddBulkJobtitleModal from "../../components/modal/job_title/UploadBulkJobtitleModal";
import DownloadPdfFileWithData from "../../../services/DownloadPdfFileWithData";
import DownloadExcelFileWithData from "../../../services/DownloadExcelFileWithData";
import useDeletePermissionModal from "../../../hooks/Messages/useDeletePermissionModal";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import useDeleteResultModal from "../../../hooks/Messages/useDeleteResultModal";
import _ from "lodash";
import Asterisk from "../../components/common/Asterisk";
import ToolTip from "react-power-tooltip";
import { useDebounce } from "use-debounce";
import {
  fetchAllJobTitleData,
  fetchJobTitle,
  fetchJobTitleInitData,
  fetchJobTitleWithFilter,
} from "../../../redux/reducers/jobTitleReducer";
import Animation from "../../components/common/CustomLoader";

const customStyles = {
  headCells: {
    style: {
      paddingTop: "11px", // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: "16px", // override the cell padding for data cells
      paddingRight: "16px",
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
};

/* The `JobTitle` function is a component that handles displaying and managing jobtitles with features like sorting, filtering, pagination, and modals for editing and deleting jobtitle.*/

const JobTitle = () => {
  const { userPermissions } = usePermissionsSimplified();
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const dispatch = useDispatch();

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const { DisplayFormattedText } = useDisplayFormattedText();
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();
  const [showActionTooltip, setShowActionTooltip] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [sortData, setSortData] = useState();
  const [loader, setloader] = useState(false);

  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  /* The function `handleSort` sorts data based on a specified column and sort direction */
  const handleSort = async (column, sortDirection) => {
    setloader(true);
    let sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchJobTitleWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        sort: sort,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

  /* The function `handleRemoveSorting` for remove sorts data */
  const handleRemoveSorting = async () => {
    setloader(true);
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchJobTitleWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        sort: sort,
      })
    );

    setloader(false);
  };

  /**
   * Table Fields
   */
  const columns = [
    {
      width: selectedLanguage === "en" ? "124px" : "141px",
      name: t.jobTitle.table.ID,
      sortField: "countNumId",
      sortable: true,
      cell: (row) => (
        <span className="id-text-style">{row?.attributes?.countId}</span>
      ),
    },
    {
      minWidth: "180px",
      name: t.jobTitle.table.name,
      sortField: "title",
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <div>
            <span className="id-text-style">{row?.attributes?.title}</span>
          </div>
        </div>
      ),
    },

    {
      name: t.jobTitle.table.createdByUser,
      minWidth: "180px",
      sortField: "createdByUser.firstName",
      sortable: true,
      cell: (row) => {
        let createdByUser = row?.attributes?.createdByUser?.data ? (
          <div>
            <Image
              avatar
              title={
                row.attributes.createdByUser.data.attributes.firstName +
                " " +
                row.attributes.createdByUser.data.attributes.lastName
              }
              src={
                row?.attributes?.createdByUser?.data?.attributes?.profileImage
                  ?.data?.attributes?.url
                  ? IMAGES_URL +
                    row?.attributes?.createdByUser?.data?.attributes
                      ?.profileImage?.data?.attributes?.url
                  : LetteredAvatarImageUrl(
                      row?.attributes?.createdByUser?.data?.attributes
                        .firstName +
                        " " +
                        row?.attributes?.createdByUser?.data?.attributes
                          ?.lastName
                    )
              }
              style={
                selectedLanguage == "en"
                  ? { width: "34px", height: "34px", right: "5px" }
                  : { width: "34px", height: "34px", left: "5px" }
              }
            />
            <span className="id-text-style">
              {row?.attributes?.createdByUser?.data?.attributes?.firstName
                ? DisplayFormattedText(
                    row?.attributes?.createdByUser?.data?.attributes?.firstName,
                    8
                  )
                : ""}
            </span>
          </div>
        ) : (
          t.common.na
        );
        return <span className="ml-1 mr-1">{createdByUser}</span>;
      },
    },
    {
      name: t.jobTitle.table.createdAt,
      minWidth: "194px",
      sortField: "createdAt",

      sortable: true,
      cell: (row) => (
        <div>
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.createdAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.createdAt} />
          </div>
        </div>
      ),
    },
    {
      name: t.jobTitle.table.updatedAt,
      minWidth: "194px",
      sortField: "updatedAt",
      sortable: true,
      cell: (row) => (
        <div>
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.updatedAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.updatedAt} />
          </div>
        </div>
      ),
    },
    {
      name: t.categories.table.action,
      cell: (row) => (
        <div className="d-flex">
          {userPermissions?.jobTitle?.edit ? (
            <div
              onClick={() => {
                setTargetedId(row.id);
                setJobTitleName(row.attributes.title || "");
                setJobTitleEditModal(true);
              }}
            >
              {" "}
              <CategoryEditIcon />{" "}
            </div>
          ) : null}

          {userPermissions?.jobTitle?.delete ? (
            <div
              onClick={() => {
                if (
                  !row.attributes?.assets?.data?.length &&
                  !row.attributes?.parts?.data?.length
                ) {
                  setTargetedId(row.id);
                  setDeletePermissionModal(true);
                } else {
                  setDeletePermissionModal(true);
                }
              }}
              className="ml-15 mr-15"
            >
              {" "}
              <DeleteIcon />{" "}
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.jobTitle.jobTitleData,
    isLoading: state.jobTitle.isLoading,
    error: state.jobTitle.error,
    initData: state.jobTitle.initjobTitleData,
    page: state.jobTitle.page,
    pageSize: state.jobTitle.pageSize,
    total: state.jobTitle.total,
    initLoading: state.jobTitle.initLoading,
  }));

  const [openJobTitleAddModal, setOpenJobTitleAddModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [categoryUploadModal, setCategoryUploadModal] = useState(false);
  const [categoryUploadResultModal, setCategoryUploadResultModal] = useState(
    false
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [fetching, setFetchLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [targetedId, setTargetedId] = useState(null);
  const [jobTitleEditModal, setJobTitleEditModal] = useState(false);
  const [jobTitleName, setJobTitleName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [debounceValue] = useDebounce(searchTitle, 500);
  const [bulkUploadResultData, setBulkUploadResultData] = useState([]);

  /* Excel and Pdf file Headers Data */
  const headers = [
    { label: t.jobTitle.table.ID, key: "id" },
    { label: t.jobTitle.table.name, key: "title" },
    { label: t.jobTitle.table.createdByUser, key: "CreatedBy" },
    { label: t.jobTitle.table.createdAt, key: "CreatedAt" },
    { label: t.jobTitle.table.updatedAt, key: "UpdatedAt" },
  ];

  /* Fetching initial data for jobtitles based on the `organisationId`. */
  useEffect(() => {
    dispatch(fetchJobTitleInitData(organisationId));
  }, []);

  /* Fetching Filter data for jobtitles based on the `organisationId`. */
  useEffect(() => {
    (async function() {
      setloader(true);
      await dispatch(
        fetchJobTitleWithFilter({
          organisationId,
          searchName: debounceValue,
          page: "1",
          pageSize: "25",
          sort: sortData,
        })
      );
      setloader(false);
    })();
  }, [debounceValue]);

  /* The function `downloadXLS` fetches jobtitle data, formats it, and downloads it as an Excel file. */
  const downloadXLS = async () => {
    let csvData = [];

    let fileData = data;

    if (debounceValue == "") {
      let mydata = await dispatch(fetchAllJobTitleData(organisationId));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }

    for (let i = 0; i < fileData.length; i++) {
      let jobTitle_data = [];

      jobTitle_data.id = fileData[i].id;
      jobTitle_data.title = fileData[i].attributes.title || t.common.na;
      jobTitle_data.createdBy =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;
      jobTitle_data.createdAt =
        moment(fileData[i].attributes.createdAt).format("DD-MM-YYYY LT") ||
        t.common.na;
      jobTitle_data.updatedAt =
        moment(fileData[i].attributes.updatedAt).format("DD-MM-YYYY LT") ||
        t.common.na;

      csvData.push([
        jobTitle_data.id,
        jobTitle_data.title,
        jobTitle_data.createdBy,
        jobTitle_data.createdAt,
        jobTitle_data.updatedAt,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.jobTitle.title
    );
  };

  /*
   * The function `generatePDF` fetches jobtitle data, formats it, and then downloads a PDF file with the data.
   */
  const generatePDF = async () => {
    let csvData = [];

    let fileData = data;

    if (debounceValue == "") {
      let mydata = await dispatch(fetchAllJobTitleData(organisationId));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    for (let i = 0; i < fileData.length; i++) {
      let jobTitle_data = [];

      jobTitle_data.id = fileData[i].id;
      jobTitle_data.title = fileData[i].attributes.title || t.common.na;
      jobTitle_data.createdBy =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;
      jobTitle_data.createdAt =
        moment(fileData[i].attributes.createdAt).format("DD-MM-YYYY LT") ||
        t.common.na;
      jobTitle_data.updatedAt =
        moment(fileData[i].attributes.updatedAt).format("DD-MM-YYYY LT") ||
        t.common.na;

      csvData.push([
        jobTitle_data.id,
        jobTitle_data.title,
        jobTitle_data.createdBy,
        jobTitle_data.createdAt,
        jobTitle_data.updatedAt,
      ]);
    }
    DownloadPdfFileWithData(headers, csvData, t.jobTitle.title);
  };

  /*
   * The function `handleCategoryEdit` updates a jobtitle with a new name and displays success or error messages accordingly.
   */
  const handleJobTitleEdit = async () => {
    if (jobTitleName && jobTitleName.trim()) {
      let res = await updateJobTitle(targetedId, jobTitleName.trim());
      if (res && res.status === 200) {
        toast.success(t.jobTitle.job_title_updated_success);
        await dispatch(
          fetchJobTitleWithFilter({
            organisationId,
            searchName: debounceValue,
            page: page,
            pageSize: pageSize,
            sort: sortData,
          })
        );
        setJobTitleEditModal(false);
        setJobTitleName("");
        setTargetedId(null);
      } else if (res && res.status === 400) {
        setErrorMsg(t.jobTitle.job_title_already_exist);
      } else {
        toast.error(t.jobTitle.not_updated);
      }

      setDeleteModal(false);
    } else {
      toast.error(t.jobTitle.add_job_title_name);
    }
  };

  /*
   * The function `handleDeleteCategory` deletes a jobtitle based on the targeted ID.
   */
  const handleDeleteJobTitle = async () => {
    if (targetedId) {
      let res = await deleteJobTitle(targetedId, jobTitleName.trim());
      if (res && res.status === 200) {
        toast.success(t.jobTitle.job_title_delete_success);
        await dispatch(
          fetchJobTitleWithFilter({
            organisationId,
            searchName: debounceValue,
            page: page,
            pageSize: pageSize,
            sort: sortData,
          })
        );
      } else {
        setDeleteResultModal(true);
      }
    } else {
      setDeleteResultModal(true);
    }

    setTargetedId(null);
  };

  /*
   * The above code is a React component that displays a list of jobtitles with various functionalities such as filtering, sorting, pagination, and adding/editing categories.
   */
  return (
    <>
       <div className="new-listing-container table-header-dashboard stick-header pb-5">
          <div className="header-actions">
            <Header className="procedure-title mt-5" as="h2">
              {" "}
              <JobtitleIcon />
              {t.jobTitle.title}
            </Header>
            <div></div>

            {userPermissions?.jobTitle?.create &&
            userPermissions?.jobTitle?.view &&
            initData.length > 0 ? (
            //   <button
            //   onClick={() => {
            //     setOpenJobTitleAddModal(true);
            //   }}
            //   className={`add-item-btn ${
            //     selectedLanguage == "en" ? "mr-7" : "ml-7"
            //   }`}
            // >
            //   <span>
            //     <CreateIcon />
            //     {t.jobTitle.add_job}
            //   </span>
            // </button>
              <Button
                icon="add"
                className={`add-item-btn ${
                  selectedLanguage == "en" ? "mr-7" : "ml-7"
                }`}
                primary
                onClick={() => {
                  setOpenJobTitleAddModal(true);
                }}
              >
                {t.jobTitle.add_job}
                {/* <ProcedurePlusIcon /> */}
              </Button>
            ) : null}
          </div>
          {initData?.length > 0 ? (
            <div className="new-listing-filter mb-0 filters-wrapper mt-8 justify-content-end">
            
              <div className="flexbox">

              <div
                className="table-searchbar d-flex work-order-upload"
                style={{ width: "240px" }}
              >
                <span className="search-svg">
                  <SearchIcon />
                </span>
                <Input
                  icon={{ name: "search" }}
                  placeholder={t.people.filter.search}
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
              </div>
              <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 ">
                  <UncontrolledDropdown className="mr-2" direction="down">
                  <DropdownToggle
              style={{ position: "relative", cursor: "pointer" }}
              onMouseOver={() => setShowActionTooltip(true)}
              onMouseLeave={() => setShowActionTooltip(false)}
              color="primary"
              className="option-toggle"
            >
              <ToolTip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "ar" ? "top right" : "top left"}
                show={showActionTooltip}
                textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                fontSize="12px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="4px 4px 4px 8px"
                borderRadius="5px"
                static
                moveRight="10px"
                moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
              >
                <span className="work-order-tooltip">
                  {t.common.page_action}
                </span>
              </ToolTip>
              <ActionBtnIcon />{" "}
            </DropdownToggle>
                    <DropdownMenu
                      className={selectedLanguage == "ar" ? "rtl" : ""}
                    >
                      {/* {role !== 'view_only' ? (
                          <>
                            <DropdownItem>
                              <ShareIcon /> {t.common.share}
                            </DropdownItem>
                            <DropdownItem className="divider" />
                          </>
                        ) : null} */}
                      {/* <DropdownItem>
                         <ShareIcon /> Share
                       </DropdownItem> */}
                      <DropdownItem className="divider" />
                      <DropdownItem onClick={() => downloadXLS()}>
                        <DownloadIcon />
                        {t.common.download_sheet}
                      </DropdownItem>
                      <DropdownItem className="divider" />
                      {/* <DropdownItem onClick={() => generatePDF()}>
                         <PdfIcon /> {t.common.download_pdf}
                       </DropdownItem>
                       <DropdownItem className="divider" /> */}
                      <DropdownItem
                        onClick={() => setCategoryUploadModal(true)}
                      >
                        <UploadIcon /> {t.common.upload}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      <div className="new-listing-container">
     

        {initLoading || initData.length < 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "500px" }}
          >
            <Animation />
            {/* <Placeholder fluid>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder> */}
          </div>
        ) : (
          <div className="new-listing-filter">
            {initData?.length > 0 ? (
              <>
                {error ? (
                  <div className="mt-8 mb-8 ">
                    <Animation />
                  </div>
                ) : (
                  <DataTable
                    onSort={handleSort}
                    fixedHeader
                    className="datatable-div"
                    columns={columns}
                    customStyles={customStyles}
                    data={data}
                    noDataComponent={
                      <div className="no-new-listing-container">
                        <div className="">
                          <p className="no-new-listing-text">
                            {t.common.no_result_found}
                          </p>
                        </div>
                      </div>
                    }
                    direction={selectedLanguage === "en" ? "ltr" : "rtl"}
                    sortIcon={
                      sortData ? (
                        <div>
                          <span>
                            <SortIcon />
                          </span>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveSorting();
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )
                    }
                    persistTableHead={true}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    progressPending={loader}
                    progressComponent={
                      <div className="mt-8 mb-8 ">{t.common.loading}</div>
                    }
                  />
                )}

                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={total}
                    prevItem={
                      selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={page}
                    limit={pageSize}
                    handleNext={async () => {
                      setloader(true);
                      await dispatch(
                        fetchJobTitleWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page + 1,
                          pageSize: pageSize,
                          sort: sortData,
                        })
                      );
                      setloader(false);
                    }}
                    handlePrevious={async () => {
                      setloader(true);
                      await dispatch(
                        fetchJobTitleWithFilter({
                          organisationId,
                          searchName: debounceValue,
                          page: page - 1,
                          pageSize: pageSize,
                          sort: sortData,
                        })
                      );
                      setloader(false);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="">
                <div className="no-data-container">
                  <div className="no-work-orders pb-15">
                    <JobTitleBlueIcon />
                    <p className="no-data-text">
                      {t.common.there_no}{" "}
                      <span
                        className="link-text"
                        style={{ position: "relative", cursor: "pointer" }}
                        onMouseOver={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        <ToolTip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="top left"
                          show={showTooltip}
                          textBoxWidth={
                            selectedLanguage == "ar" ? "35px" : "70px"
                          }
                          arrowAlign="start"
                          fontSize="14px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="0px 0px 0px 8px"
                          borderRadius="5px"
                          static
                          moveRight={
                            selectedLanguage == "ar" ? "190px" : "88px"
                          }
                          moveUp="-1px"
                        >
                          <span className="work-order-tooltip">
                            {t.jobTitle.how_to_add_jobtitle}
                          </span>
                        </ToolTip>
                        {" " + t.jobTitle.title_small + " "}
                      </span>{" "}
                      {t.common.added}
                    </p>
                    {userPermissions?.jobTitle?.create ? (
                      <button
                        className="work-order-button-transparent"
                        onClick={() => {
                          setOpenJobTitleAddModal(true);
                        }}
                      >
                        {t.jobTitle.no_jobtitle_added}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Modal component for editing jobtitle. */}
        <Modal
          size="tiny"
          open={jobTitleEditModal}
          className="assets-modal"
          onClose={() => {
            setJobTitleEditModal(false);
            setErrorMsg("");
          }}
        >
          <div className="header-container">
            <span className="assets-header">{t.jobTitle.edit_job_title}</span>
            <div>
              <span
                className="c-pointer text-right"
                onClick={() => {
                  setJobTitleEditModal(false);
                  setErrorMsg("");
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>

          <Modal.Content className="">
            <label className="assets-label" style={{ lineHeight: "22px" }}>
              {t.jobTitle.jobTitle}
            </label>
            <span className="label-item d-inline">
              {" "}
              <Asterisk color="#172B4D" />
            </span>

            <div className="category-input">
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.common.enter_jobTitle_name}
                value={jobTitleName}
                onChange={(e) => {
                  setJobTitleName(e.target.value);
                  setErrorMsg("");
                }}
              />
              {errorMsg ? <p className="mt-2 text-danger">{errorMsg}</p> : null}
            </div>
          </Modal.Content>

          <div
            className={`modal-button-container mt-4 ${
              selectedLanguage == "en" ? "mr-8" : "ml-10"
            }`}
          >
            <button
              className="modal-form-action-button black"
              onClick={() => {
                setErrorMsg("");
                setJobTitleEditModal(false);
              }}
            >
              {t.common.cancel}
            </button>

            <button
              onClick={() => handleJobTitleEdit()}
              className="modal-form-action-button blue"
            >
              {t.common.save}
            </button>
          </div>
        </Modal>

        {/* Modal component for add jobtitle. */}
        <JobTitleCreateModal
          isOpen={openJobTitleAddModal}
          setModalOpenState={setOpenJobTitleAddModal}
          handleSuccess={() =>
            dispatch(
              fetchJobTitleWithFilter({
                organisationId,
                searchName: debounceValue,
                page: page,
                pageSize: pageSize,
                sort: sortData,
              })
            )
          }
        />

        {/* Modal component for delete jobtitle premission. */}
        <DeletePermissionModal
          yesCallback={() => {
            handleDeleteJobTitle();
          }}
          title={t.jobTitle.add}
        >
          <span>{t.jobTitle.delete_job_title_statement}</span>
        </DeletePermissionModal>

        {/* Modal component for delete jobtitle result. */}
        <DeleteResultModal
          closeCallback={() => {
            setDeleteInfoModal(false);
          }}
          deleteErrorMessage={"delete"}
          btnName={t.common.ok}
        >
          <div className="mb-2">
            <Close />
          </div>
          <p
            style={{
              paddingInline: "62px",
              fontSize: "16px",
              fontWeight: "400",
            }}
            className="status-modal-popup-message mt-5"
          >
            {t.jobTitle.cannot_delete_job_title_statement}
          </p>
        </DeleteResultModal>

        {/* Modal component for upload jobtitle. */}
        <AddBulkJobtitleModal
          isOpen={categoryUploadModal}
          setModalOpenState={setCategoryUploadModal}
          handleSuccess={(res) => {
            setBulkUploadResultData(res.data);
            setCategoryUploadResultModal(true);
            setCategoryUploadModal(false);
            dispatch(
              fetchJobTitleWithFilter({
                organisationId,
                searchName: debounceValue,
                page: page,
                pageSize: pageSize,
                sort: sortData,
              })
            );
          }}
        />
        {/* Modal component for upload jobtitle result. */}
        <JobTitleBulkUploadResult
          isOpen={categoryUploadResultModal}
          setModalOpenState={setCategoryUploadResultModal}
          data={bulkUploadResultData}
        />
      </div>
    </>
  );
};

export default JobTitle;
