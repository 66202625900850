import React, { useEffect, useState } from "react";
import { Grid, Label } from "semantic-ui-react";
import {
  AddIconDashboard,
  DashboardWOStatusIcon,
  FullArrow,
  NoDashboardIcon,
  RefreshSvg,
} from "../../../../Assets/Icons/svg";
import Tooltip from "react-power-tooltip";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import NoDashboardData from "../noDashboardData";
const WoStatusChart = ({
  setOpenModal,
  openModal,
  setModalData,
  dashBoardData,
  height = 187,
}) => {
  const t = useSelector(selectTranslations);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [woStatusChart, setWoStatusChart] = useState({
    series: [],
    options: {
      chart: {
        width: 187,
        height: height,
        type: "donut",
      },
      labels: [],
      legend: {
        show: false,
      },
      colors: [
        "#0C66E4",
        "#172B4D",
        "#D14040",
        "#4FAB68",
        "#E2B203",
        "#788CA5",
      ],
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "25px",
          fontFamily: "Roboto",
          fontWeight: 100,
          colors: ["#e9f2ff"],
        },
      },

      // dataLabels: {
      //   enabled: true,
      //   formatter: function (val, opts) {
      //     const percentages = opts.w.config.seriesPercentages || [];
      //     return `${percentages[opts.seriesIndex] || 0}%`;
      //   },
      //   style: {
      //     fontSize: "25px",
      //     fontFamily: "Roboto",
      //     fontWeight: 100,
      //     colors: ["#e9f2ff"],
      //   },
      // },
      stroke: {
        show: false,
        width: 0,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          const value =
            w.config.series[seriesIndex][dataPointIndex] ||
            w.config.series[seriesIndex];
          const label = w.config.labels[seriesIndex];
          const color = w.config.colors[seriesIndex];

          return `
                <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
                  <div style="display: flex; align-items: center;">
                    <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%;  ${selectedLanguage == 'en' ? 'margin-right: 4px' : 'margin-left: 4px'}"></div>
                    <span>${label} : </span>&nbsp; ${value}
                  </div>
                </div>
              `;
        },
      },
    },
  });
  const [statusOptions, setStatusOptions] = useState([
    {
      value: "open",
      color: "blue",
      label: t.workOrders.work_order_status.open,
      count: 0,
    },
    {
      value: "inProgress",
      color: "yellow",
      label: t.workOrders.work_order_status.inProgress,
      count: 0,
    },
    {
      value: "onHold",
      color: "red",
      label: t.workOrders.work_order_status.onHold,
      count: 0,
    },
    {
      value: "inReview",
      color: "black",
      label: t.workOrders.work_order_status.inReview,
      count: 0,
    },
    {
      value: "completed",
      color: "green",
      label: t.workOrders.work_order_status.completed,
      count: 0,
    },
    // { value: 'cancelled', color: 'darkgrey', label: t.workOrders.work_order_status.cancel, count: 0 },
  ]);

  useEffect(() => {
    if (Object.values(dashBoardData?.workOrderStatus)?.length > 0) {
      const updatedSeries = [
        dashBoardData.workOrderStatus.open.count,
        dashBoardData.workOrderStatus.inReview.count,
        dashBoardData.workOrderStatus.onHold.count,
        dashBoardData.workOrderStatus.completed.count,
        dashBoardData.workOrderStatus.inProgress.count,
      ];

      const updatedLabels = [
        t.workOrders.work_order_status.open,
        t.workOrders.work_order_status.inReview,
        t.workOrders.work_order_status.hold,
        t.workOrders.work_order_status.completed,
        t.workOrders.work_order_status.inProgress,
        // "In-Review",
        // "On-Hold",
        // "Completed",
        // "In-Progress",
      ];

      const updatedStatusOptions = statusOptions.map((option) => {
        const count = dashBoardData?.workOrderStatus[option.value]?.count || 0;
        return { ...option, count };
      });

      setStatusOptions(updatedStatusOptions);

      setWoStatusChart((prevState) => ({
        ...prevState,
        series: updatedSeries,
        options: {
          ...prevState.options,
          labels: updatedLabels,
        },
      }));

      // const seriesPercentages = [
      //   open.percentage,
      //   inReview.percentage,
      //   onHold.percentage,
      //   completed.percentage,
      //   inProgress.percentage,
      // ];
    }
  }, [dashBoardData]);

  return (
    <>
      {Object.values(dashBoardData?.workOrderStatus)?.length > 0 ? (
        <Grid.Column className={"completion-column pl-0"}>
          <div
            className={
              height == "400"
                ? "completion-column-data no-border"
                : "completion-column-data"
            }
          >
            <div className="completion-cotainer d-flex justify-content-between mt-2 pb-0">
              <div className="completion-title d-flex">
                <span className="title">{t.dashboard.cards.status}</span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  className={
                    selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                  }
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={selectedLanguage == "en" ? "right" : "left"}
                    show={showTooltip}
                    textBoxWidth="250px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="0px 0px 0px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="8px"
                    // moveDown="10px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.tooltip.WOstatus}
                    </span>
                  </Tooltip>
                  <DashboardWOStatusIcon />
                </p>
              </div>

              <span
                style={{ marginTop: "-10px", gap: "10px" }}
                className="d-flex"
              >
                <span
                  onClick={() => {
                    setOpenModal(true);
                    setModalData("status");
                  }}
                  style={{ marginTop: "2px" }}
                  className=""
                >
                  {height == "400" ? null : (
                    <p
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        marginTop: "-10px",
                      }}
                      onMouseOver={() => setShowExpandTooltip(true)}
                      onMouseLeave={() => setShowExpandTooltip(false)}
                      className=" mt-1"
                    >
                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top"
                        show={showExpandTooltip}
                        textBoxWidth="80px"
                        fontSize="10px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == "ar"
                            ? "Noto Kufi Arabic"
                            : "Roboto"
                        }
                        padding="8px 8px 8px 8px"
                        borderRadius="5px"
                        static
                        arrowAlign="start"
                        moveRight="5px"
                        moveDown="3px"
                      >
                        <span
                          style={{ padding: "0px 10px" }}
                          className="work-order-tooltip"
                        >
                          {t.dashboard.expand_full}
                        </span>
                      </Tooltip>
                      <FullArrow />
                    </p>
                  )}
                </span>
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowAddCustomizedTooltip(true)}
                  onMouseLeave={() => setShowAddCustomizedTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showAddCustomizedTooltip}
                    textBoxWidth="105px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="end"
                    moveRight="0px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.add_customized}
                    </span>
                  </Tooltip>
                  {/* <AddIconDashboard /> */}
                </p>
              </span>
            </div>
            {Object.values(dashBoardData?.workOrderStatus).every(status => status.count === 0) ?  
            <NoDashboardData/> :
            <div className="completion-status-part">
              <Grid className="wo-data-container" columns={"equal"}>
                <Grid.Column className="wo-data-chart d-flex align-items-center text-center">
                  <div className="w-100">
                    <Chart
                      options={woStatusChart?.options}
                      series={woStatusChart?.series || []}
                      type="donut"
                      height={woStatusChart?.options.chart.height} //
                      // className="d-flex justify-content-center align-items-center"
                    />
                    {/* <Chart
              options={chartOptions}
              series={[70, 90, 50, 10]}
              type="donut"
              height={350}
            /> */}
                  </div>
                </Grid.Column>
                {height == "400" ? null : (
                  <Grid.Column
                    className={
                      selectedLanguage == "en"
                        ? "wo-data-status p-0 pr-15"
                        : "wo-data-status p-0 pl-15"
                    }
                  >
                    <div className="status-container">
                      <div
                        className={`${
                          selectedLanguage == "en" ? "text-right" : "text-left"
                        } mt-7`}
                      >
                        <span
                          style={
                            selectedLanguage == "en"
                              ? {
                                  padding: "6px 8px 6px 8px",
                                  borderRadius: "8px",
                                  fontSize: "16px",
                                  background: "rgba(228, 230, 234, 1)",
                                }
                              : {
                                  padding: "0px 8px 0px 8px",
                                  borderRadius: "8px",
                                  fontSize: "16px",
                                  background: "rgba(228, 230, 234, 1)",
                                }
                          }
                        >
                          {t.dashboard.cards.total}:{" "}
                          {dashBoardData?.totalWorkOrder
                            ? dashBoardData.totalWorkOrder
                            : 0}
                        </span>
                      </div>
                      {statusOptions.map((status, index) => (
                        <React.Fragment key={index}>
                          <div
                            style={{ gap: "16px" }}
                            className={`item-value mb-3 d-flex align-items-center justify-content-end ${
                              index == 0 ? "mt-3" : ""
                            } ${index == 4 ? "mb-8" : ""}`}
                          >
                            <div className="label-text">
                              {status.label}
                              <p
                                className={`${
                                  selectedLanguage == "en"
                                    ? "text-right"
                                    : "text-left"
                                }`}
                              >
                                {status?.count ? status.count : 0}
                              </p>
                            </div>
                            <div className="">
                              <Label
                                circular
                                empty
                                color={status.color}
                                style={{
                                  marginRight: "5px",
                                  width: "12px",
                                  height: "12px",
                                }}
                                size="medium"
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </Grid.Column>
                )}
              </Grid>
            </div>}
          </div>
        </Grid.Column>
      ) : null}
    </>
  );
};

export default WoStatusChart;
