import React, { useState, useEffect, createRef } from "react";
import {
  Segment,
  Header,
  Popup,
  TextArea,
  Dropdown,
  Modal,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import Asterisk from "../../components/common/Asterisk";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  getUsersByRole,
  getAllCustomers,
  getAllLocations,
  createNewTeam,
  humanize,
  IMAGES_URL,
  getActiveUsersByRole,
  getAllParentLocations,
} from "../../config/functions";

import md5 from "md5";
import { useDispatch, useSelector } from "react-redux";
import { useCancelModal } from "../../../hooks/Messages/useCancelModal";

import { selectTranslations } from "../../config/i18n/slice";

import AddCustomerModal from "./popups/add-customer";
import AddLocationModal from "./popups/add-location";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { toast } from "react-toastify";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import { CloseIcon } from "../../../Assets/Icons/svg";
import ToolTip from "react-power-tooltip";
import { fetchTeamsInitData } from "../../../redux/reducers/teamsReducer";
import { Resizable } from "re-resizable";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";

function Add({
  toggled,
  untoggle,
  refresh,
  setRefresh,
  setSortData,
  setSearchTitle,
  setFilterOptions,
  filterOptions,
  userRole,
  allIds,
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  useEffect(() => {
    if (toggled) {
      // fetchAssetsOptions();
      // fetchSets();
      fetchLocationsOptions();
    }
  }, [toggled]);

  const { initData } = useSelector((state) => ({
    initData: state.teams.teamsInitData,
  }));
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const [initChange, setInitChange] = useState(false);
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [matchingCustomers, setMatchingCustomers] = useState("");
  const [buttonStatus, setButtonStatus] = useState(0);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState({});

  const [addCustomerModal, toggleAddCustomerModal] = useState(false);
  const [addLocationModal, toggleAddLocationModal] = useState(false);
  const [customerText, setCustomerText] = useState("");
  const [locationText, setLocationText] = useState("");

  const [options, setOptions] = useState({
    supervisors: [],
    users: [],
    locations: [],
    customers: [],
  });

  const [data, setData] = useState({
    name: "",
    description: "",
    users: [],
    customer: [],
    locations: [],
    superior: "",
  });

  const [validation, setValidation] = useState({
    name: {
      error: true,
      pattern: (name) => name != "",
      skipped: false,
      message: t.common.required_field,
    },
    users: {
      error: true,
      pattern: (users) => users.length > 0,
      skipped: false,
      message: t.common.required_field,
    },

    superior: {
      error: true,
      pattern: (superior) => true,
      skipped: false,
      message: t.common.required_field,
    },
    customer: {
      error: true,
      pattern: (superior) => true,
      skipped: false,
      message: t.common.required_field,
    },

    locations: {
      error: true,
      pattern: (customer) => customer != "",
      skipped: false,
      message: t.common.required_field,
      messageData: (label) => {

        if (label === "") {
      
          return t.common.required_field;
        } else if (label) {
          return t.teams.location_valid + ' ' + label +  ' '+ t.teams.selected_data;
        }
        return t.common.required_field;
      },
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      ...data,
      name: "",
      description: "",
      users: [],
      customer: [],
      locations: [],
      superior: "",
    });

    setMatchingCustomers("");

    setValidation({
      name: {
        error: true,
        pattern: (name) => name != "",
        skipped: false,
        message: t.common.required_field,
      },
      users: {
        error: true,
        pattern: (users) => users.length > 0,
        skipped: false,
        message: t.common.required_field,
      },
      customer: {
        error: true,
        pattern: (superior) => true,
        skipped: false,
        message: t.common.required_field,
      },

      superior: {
        error: true,
        pattern: (superior) => true,
        skipped: false,
        message: t.common.required_field,
      },

      locations: {
        error: true,
        pattern: (customer) => customer != "",
        skipped: false,
        message: t.common.required_field,
        messageData: (label) => {

          if (label === "") {
            return t.common.required_field;
          } else if (label) {
            return t.teams.location_valid + ' ' + label +  ' '+ t.teams.selected_data;
          }
          return t.common.required_field;
        },
      },
    });

    setModalWidth(Math.max(window.innerWidth / 2, 514));

    setInitChange(false);
  };

  const fetchCustomerOptions = async () => {
    const fetchData = await getAllCustomers(
      organisationId,
      "",
      1,
      99999,
      !(userRole === "super_admin" || userRole === "admin") ? allIds : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
          location: el?.attributes?.locations?.data?.map(
            (el_loc) => el_loc?.id
          ) || [],
        };
      });
      setOptions((prev) => {
        return {
          ...prev,
          customers: the_options,
        };
      });

      return the_options;
    }
  };

  const fetchLocationsOptions = async (updatedAllIds = []) => {
    let finalIds = [...allIds];

    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(userRole === "super_admin" || userRole === "admin")) {
          // Check if the element's ID is included in allIds
          if (finalIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (finalIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => finalIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (finalIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  // useEffect(() => {
  //   if (data.customer) fetchLocationsOptions(organisationId);
  // }, [data.customer]);

  // add popups
  const handleAddCustomer = (e, { value }) => {
    setCustomerText(value);

    toggleAddCustomerModal(true);
  };

  const returnAddCustomer = (id) => {
    fetchCustomerOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          customer: id,
        });
      }
    });
  };
  const handleAddLocation = (e, { value }) => {
    setLocationText(value);
    toggleAddLocationModal(true);
  };

  const returnAddLocation = (id) => {
    fetchLocationsOptions(data.customer).then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          locations: [...data.locations, id],
        });
      }
    });
  };

  const fetchSupervisorsData = async () => {
    const fetchData = await getActiveUsersByRole(
      ["admin", "limited_admin"],
      organisationId,
      !(userRole === "super_admin" || userRole === "admin") ? allIds : [],true
    );

    if (fetchData?.data) {
      // console.log(fetchData);
      let g_users = fetchData.data.map((el) => {
        const the_image = el?.profileImage?.url
          ? IMAGES_URL + el?.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el?.lastName);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions({
        ...options,
        supervisors: g_users,
      });
    }
  };

  const roles = [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,

      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description: t.people.add_modal.admin_description,
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,

      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,

      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,

      description: t.people.add_modal.viewer_description,
    },
    // {
    //   key: 2,
    //   value: 6,
    //   text: t.people.add_modal.requester,
    //   icon: <ViewerIcon />,
    //   description:
    //    t.people.add_modal.requester_desc
    // },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ];

  // const roles = [
  //   {
  //     key: 0,
  //     value: 5,
  //     text: t.people.add_modal.admin,
  //     icon: 'cog',
  //     description: t.people.add_modal.admin_description,
  //   },

  //   {
  //     key: 1,
  //     value: 8,
  //     text: t.people.add_modal.technician,
  //     icon: 'wpforms',
  //     description: t.people.add_modal.user_description,
  //   },
  //   {
  //     key: 2,
  //     value: 9,
  //     text: t.people.add_modal.limited_admin,
  //     icon: 'phone volume',
  //     description: '',
  //   },
  //   {
  //     key: 3,
  //     value: 3,
  //     text: t.people.add_modal.viewer,
  //     icon: 'sitemap',
  //     description: '',
  //   },
  // ];

  const fetchUsersData = async () => {
    const fetchData = await getActiveUsersByRole(
      [
        "user",
        "supervisor",
        "view_only",
        "admin",
        "limited_admin",
        "requester",
      ],
      organisationId,
      !(userRole === "super_admin" || userRole === "admin") ? allIds : [],
      true
    );

    if (fetchData?.data) {
      // console.log(fetchData);
      let g_users = fetchData.data
        // Filter out users with matching superior ID
        .map((el) => {
          let role = roles.find((each) => each.value == el.role.id);

          const the_image = el?.profileImage?.url
            ? IMAGES_URL + el?.profileImage?.url
            : LetteredAvatarImageUrl(el?.firstName + " " + el?.lastName);

          return {
            key: el.id,
            value: el.id,
            text:
              el.firstName +
              " " +
              el.lastName +
              " (" +
              t.people.roles[el.role.name] +
              ")",
            image: { avatar: true, src: the_image, size: "tiny" },
          };
        });

      setOptions({
        ...options,
        users: g_users,
      });
    }
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    let currentFieldValue = "";
    const dropdown_fields = ["users", "superior", "customer", "locations"];

    if (!dropdown_fields.includes(name)) {
      currentFieldValue = event.target.value;
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      currentFieldValue = value.value;

      if (name == "customer") {
        let locationData = options.customers.filter((customer) =>
          currentFieldValue.includes(customer.value)
      );
     

        if (locationData?.length > 0) {
          let locationArray = data?.locations;
          locationData.forEach((location) => {
            // Check if location?.location is an array before processing
            if (Array.isArray(location?.location)) {
              location.location.forEach((loc) => {
                if (!locationArray.some((inv) => inv === loc)) {
                  locationArray.push(loc);
                }
              });
            }
          });
          // locationData.map((location) => {
          //   if (!locationArray.some((inv) => inv == location?.location)) {
          //     locationArray.push(location?.location);
          //   }
          // });

          setData({
            ...data,
            [name]: value.value,
            locations: locationArray,
          });
        } else {
          setData({
            ...data,
            [name]: value.value,
          });
        }
      }  else {
        setData({
          ...data,
          [name]: value.value,
        });
      }
    }
    let fieldValidation = validation[name];

    if (fieldValidation) {
      let currentValidation;
      if (name == "locations") {
        let locationArray = value.value;
        let myArray = [];
       
        let locationData = options.customers.filter((customer) =>
          data?.customer.includes(customer.value)
        );
        if (locationData?.length > 0) {
          locationData.forEach((location) => {
            if (Array.isArray(location?.location)) {
              location.location.forEach((loc) => myArray.push(loc));
            }
          });
        }
        const notIncludedIds = myArray.filter(
          (id) => !locationArray.includes(id)
        );
       
        let matchingCustomersData = "";

        if (notIncludedIds.length > 0) {
          matchingCustomersData = options.customers
          .filter((customer) => 
            data?.customer.includes(customer.value) &&
            customer.location.some(loc => notIncludedIds.includes(loc))
          )
            // .filter((customer) => notIncludedIds.includes(customer.location))
            .map((customer) => customer.text) // Get the customer names
            .join(", "); // Join the names with commas
          setMatchingCustomers(matchingCustomersData);

          currentValidation = {
            ...validation,
            [name]: {
              ...fieldValidation,
              error: true,
              skipped: true,
              message:
                typeof fieldValidation.messageData === "function"
                  ? fieldValidation.messageData(matchingCustomersData)
                  : t.common.required_field,
            },
          };
       
        } else {
          currentValidation = {
            ...validation,
            [name]: {
              ...fieldValidation,
              error: fieldValidation.pattern(currentFieldValue) ? false : true,
              skipped: true,
              message:
                typeof fieldValidation.messageData === "function"
                  ? fieldValidation.messageData(matchingCustomersData)
                  : t.common.required_field,
              // message: typeof fieldValidation.messageData === 'function' ?fieldValidation.messageData(matchingCustomers) : t.common.required_field
            },
          };
          setMatchingCustomers("");
       
        }
      } else if (name == "customer") {
        currentValidation = {
          ...validation,
          [name]: {
            ...fieldValidation,
            error: fieldValidation.pattern(currentFieldValue) ? false : true,
            skipped: true,
          },
          ["locations"]: {
            ...fieldValidation,
            error: fieldValidation.pattern(data?.locations) ? false : true,
            skipped: true,
            messageData: (label) => {
            
              if (label === "") {
              
                return t.common.required_field;
              } else if (label) {
                return t.teams.location_valid + ' ' + label +  ' '+ t.teams.selected_data;
              }
              return t.common.required_field;
            },
            message:
              typeof fieldValidation.messageData === "function"
                ? fieldValidation.messageData("")
                : t.common.required_field,
          },
        };
      } else {
        currentValidation = {
          ...validation,
          [name]: {
            ...fieldValidation,
            error: fieldValidation.pattern(currentFieldValue) ? false : true,
            skipped: true,
          },
        };
      }
      // console.log(
      //   fieldValidation.pattern(currentFieldValue) ? false : true,
      //   currentFieldValue
      // );

      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  const validationHandler = () => {
    let locationArray = data?.locations;
    let myArray = [];

    let locationData = options.customers.filter((customer) =>
      data?.customer.includes(customer.value)
    );
    if (locationData?.length > 0) {
      locationData.forEach((location) => {
        if (Array.isArray(location?.location)) {
          location.location.forEach((loc) => myArray.push(loc));
        }
      });
    }
    const notIncludedIds = myArray.filter((id) => !locationArray.includes(id));
    let matchingCustomersData = "";

    if (notIncludedIds.length > 0) {
      matchingCustomersData = options.customers
      .filter((customer) => 
        data?.customer.includes(customer.value) &&
        customer.location.some(loc => notIncludedIds.includes(loc))
      )
        .map((customer) => customer.text) // Get the customer names
        .join(", "); // Join the names with commas
      setMatchingCustomers(matchingCustomersData);
    } else {
      setMatchingCustomers("");
    }

    let currentValidation = {
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      customer: {
        ...validation.name,
        error: false,
        skipped: true,
      },
      users: {
        ...validation.users,
        error: validation.users.pattern(data.users) ? false : true,
        skipped: true,
      },
      superior: {
        ...validation.superior,
        error: validation.superior.pattern(data.superior) ? false : true,
        skipped: true,
      },

      locations: {
        ...validation.locations,
        error:
          notIncludedIds.length > 0
            ? true
            : validation.locations.pattern(data.locations)
            ? false
            : true,
        skipped: true,
        message:
          typeof validation.locations.messageData === "function"
            ? validation.locations.messageData(matchingCustomersData)
            : t.common.required_field,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const cond = validationToArray.some((el) => el[1].error);
    return cond;
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
    // console.log(validationToArray);

    if (initChange) setValidForm(!cond);
  }, [validation, initChange]);

  const createNewTeamHandler = async () => {
    let errorsInData = validationHandler();
    if (!errorsInData) {
      setButtonStatus(1);
      // const filtered_locations_per_customer = data.locations.filter((loc) =>
      //   options.locations.map((in_loc) => in_loc.value).includes(loc)
      // );

      const response = await createNewTeam(
        data.name,
        data.description,
        data.users,
        data.superior || null,
        data.locations,
        organisationId,
        currentUserId,
        data.customer
      );
      // console.log(response);

      if (response.status == 200) {
        setButtonStatus(0);
        untoggle();
        resetForm();
        setResponseData({
          id: response.data.id,
          name: response.data.name,
        });

        setFilterOptions({
         
          locations: [],
          customers: [],
          teams: [],
          superior: [],

          priority: [],
          status: [],
        });
        setSortData([]);
        setSearchTitle("");
        if (initData?.length == 0) {
          const fetchData = await dispatch(
            fetchTeamsInitData({
              organisationId,
              userRole,
              currentUserId: allIds,
            })
          );
        }
        toast.success(t.teams.add + " " + t.common.added_success);
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
          // untoggle();
        } else {
          toast.error(t.common.something_wrong);
          untoggle();
          resetForm();
        }
      }
      setButtonStatus(0);
    } else {
      scrollToError();
    }
  };

  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    toggleCancelModal(false);
    setNewCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? "aside-modal active procedure" : "aside-modal procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">{t.teams.add_team}</div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div style={{
                    height: '90vh',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                  }} className="body">
            {buttonStatus == 1 ? (
              <Dimmer active inverted>
                <Loader inverted content={t.common.loading} />
              </Dimmer>
            ) : (
              ""
            )}
            <Form.Field>
              <label className="label-item">
                {t.teams.team_info_form.team_name}

                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.teams.team_info_form.add_team}
                value={data.name}
                onChange={onValuesChange("name")}
                error={validation.name.error && validation.name.skipped}
                onBlur={(e) => {
                  if (validation.name.pattern(data.name)) {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.name.error && validation.name.skipped ? (
                <div className="label-error">{validation.name.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            <Form style={{ marginBottom: "20px" }}>
              <Form.Field>
                <label className="label-item">
                  {t.teams.team_info_form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange("description")}
                  rows={4}
                  fluid
                  placeholder={t.teams.team_info_form.description_placeholder}
                />
              </Form.Field>
            </Form>

            <Form.Field>
              <label className="label-item">
                {t.teams.team_info_form.team_superior}
                {/* <Icon name="asterisk" color="red" size="small" /> */}
              </label>
              <Dropdown
                placeholder={t.teams.team_info_form.add_team_superior}
                fluid
                clearable
                noResultsMessage={t.common.no_results_found}
                search
                selection
                options={options.supervisors}
                value={data.superior}
                onChange={onValuesChange("superior")}
                onOpen={fetchSupervisorsData}
                error={validation.superior.error && validation.superior.skipped}
                onBlur={(e) => {
                  if (validation.superior.pattern(data.superior)) {
                    setValidation({
                      ...validation,
                      superior: {
                        ...validation.superior,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      superior: {
                        ...validation.superior,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.superior.error && validation.superior.skipped ? (
                <div className="label-error">{validation.superior.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.teams.team_info_form.team_members}
                <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.teams.team_info_form.add_team_members}
                fluid
                search
                selection
                noResultsMessage={t.common.no_results_found}
                multiple
                options={options.users}
                value={data.users}
                onChange={onValuesChange("users")}
                onOpen={fetchUsersData}
                error={validation.users.error && validation.users.skipped}
                onBlur={(e) => {
                  if (validation.users.pattern(data.users)) {
                    setValidation({
                      ...validation,
                      users: {
                        ...validation.users,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      users: {
                        ...validation.users,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.users.error && validation.users.skipped ? (
                <div className="label-error" style={{ marginTop: "-15px" }}>
                  {validation.users.message}
                </div>
              ) : (
                ""
              )}
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.teams.team_info_form.customer}
              </label>
              <Dropdown
                placeholder={t.teams.team_info_form.customer_placeholder}
                //allowAdditions
                search
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                multiple
                onAddItem={handleAddCustomer}
                options={options.customers}
                value={data.customer}
                onChange={onValuesChange("customer")}
                onOpen={fetchCustomerOptions}
              />
            </Form.Field>
            {/* Location  */}
            <Form.Field>
              <label className="label-item">
                {t.teams.team_info_form.location} <Asterisk color="#172B4D" />
                {/* <Icon name="asterisk" color="red" size="small" /> */}
              </label>
              <Dropdown
                placeholder={t.teams.team_info_form.location_placeholder}
                fluid
                selection
                //allowAdditions
                multiple
                search
                noResultsMessage={t.common.no_results_found}
                onAddItem={handleAddLocation}
                // onOpen={fetchLocationsOptions}
                className="people-dropdown"
                options={options.locations}
                value={data.locations}
                onChange={onValuesChange("locations")}
                error={
                  validation.locations.error && validation.locations.skipped
                }
                onBlur={(e) => {
                  if (
                    validation.locations.pattern(data.locations) &&
                    matchingCustomers?.length == 0
                  ) {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        locations: {
                          ...validation.locations,
                          error: false,
                          skipped: true,
                        },
                      };
                    });
                  } else {
                    setValidation((prev) => {
                      return {
                        ...prev,
                        locations: {
                          ...validation.locations,
                          error: true,
                          skipped: true,
                        },
                      };
                    });
                  }
                }}
              />
              {validation.locations.error && validation.locations.skipped ? (
                <div className="label-error" style={{ marginTop: "-15px" }}>
                  {validation.locations.message}
                </div>
              ) : (
                ""
              )}
            </Form.Field>

            <div className="form-button-container mt-10">
              <button
                // content={t.procedures.form.cancel_button}
                // basic
                onClick={() =>
                  initChange ? setNewCancelModal(true) : cancelHandler()
                }
                className="form-action-button"
              >
                {t.assets.form.cancel}
              </button>

              <button
                // content={t.procedures.form.submit.add}
                // primary
                onClick={createNewTeamHandler}
                // loading={buttonStatus == 1}
                className="form-action-button blue"
                disabled={buttonStatus == 1}
              >
                {t.common.add}
              </button>
            </div>

            {/* <div>
            <Button
              content={t.common.submit}
              primary
              onClick={createNewTeamHandler}
              loading={buttonStatus == 1}
            />

            <Button
              content={t.common.cancel}
              basic
              onClick={() =>
                initChange ? toggleCancelModal(true) : cancelHandler()
              }
            />
          </div> */}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>
      {/* <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content className="cancel-text">
          <p>{t.common.cancel_statement}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            {t.common.keep}
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            {t.common.yes_cancel}
          </Button>
        </Modal.Actions>
      </Modal> */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>

      {/* <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          setSuccessModal(false);
          setRefresh(!refresh);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              {t.teams.add} - {responseData.name} {t.common.added_success}!
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              setSuccessModal(false);
              setRefresh(!refresh);
            }}
          >
            {t.common.ok}
          </Button>
        </Modal.Actions>
      </Modal> */}

      {/* on the go popups */}
      <AddCustomerModal
        toggled={addCustomerModal}
        untoggle={() => {
          toggleAddCustomerModal(false);
        }}
        returnAddCustomer={returnAddCustomer}
        title={customerText}
      />

      <AddLocationModal
        toggled={addLocationModal}
        untoggle={() => {
          toggleAddLocationModal(false);
        }}
        customer={data.customer}
        returnAddLocation={returnAddLocation}
        title={locationText}
      />
    </>
  );
}

export default Add;
