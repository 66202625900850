import React, { useState, useEffect, createRef } from "react";
import Moment from "react-moment";
import {
  Icon,
  Image as SemanticImage,
  Grid,
  Header,
  Loader,
  Dimmer,
  Divider,
  List,
  Menu,
  Modal,
  Popup,
  Image,
  Button,
  Input,
  Radio,
} from "semantic-ui-react";
import QRComponentToPrint from "./qrCodeComponent";
import {
  IMAGES_URL,
  toggle,
  updateAssetsStatus,
} from "../../../config/functions";
import ReactToPrint from "react-to-print";
import { selectTranslations } from "../../../config/i18n/slice";
import { useDispatch, useSelector } from "react-redux";
import md5 from "md5";
import Toggle from "react-toggle";
import QRCode from "react-qr-code";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import { LetteredAvatarImageUrl } from "../../../components/common/userProfilePlaceholder";
import {
  CloseIcon,
  Fail,
  FileDownloadIcon,
  Printer,
  Close,
  Success,
  AssetDisposeIcon,
} from "../../../../Assets/Icons/svg";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";

import "react-toggle/style.css";
import { toast } from "react-toastify";
import ToolTip from "react-power-tooltip";
import {
  fetchAssets,
  fetchAssetsInitData,
} from "../../../../redux/reducers/assetReducer";
import moment from "moment";
import useDisplayFormattedText from "../../../../hooks/useDisplayFormattedText";

const Details = ({
  data,
  refresh,
  setRefresh,
  untoggle,
  setFilterOptions,
  setDisposeModal,
  setSearchTitle,
  setSelectedDates,
  toggled,
}) => {
  const t = useSelector(selectTranslations);
  const { DisplayFormattedText } = useDisplayFormattedText();
  const {
    userPermissions,
    checkForLinkPermission,
    checkForPermission,
  } = usePermissionsSimplified();
  const dispatch = useDispatch();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [updateSuccessModal, setUpdateSuccessModal] = useState(false);
  const [onlyParent, setOnlyParent] = useState(true);
  const [reason, setReason] = useState();
  const [showPrintTooltip, setPrintTooltip] = useState(false);
  const [showDownloadTooltip, setDownloadTooltip] = useState(false);

  const [updateModal, setUpdateModal] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(
    data?.attributes?.status == "active" ? true : false
  );

  useEffect(() => {
    if (data?.attributes?.status) {
      setToggleStatus(data?.attributes?.status == "active" ? true : false);
    }
  }, [toggled, data?.attributes]);

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const roles = [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,

      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description: t.people.add_modal.admin_description,
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,

      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,

      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,

      description: t.people.add_modal.viewer_description,
    },
    {
      key: 2,
      value: 6,
      text: t.people.add_modal.requester,

      description: t.people.add_modal.requester_desc,
    },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ];

  const qrRef = createRef();
  const history = useHistory();

  /*
   * The function `handleAssetStatus` sets the update modal to true and toggles the status based on a switch input.
   */
  const handleAssetStatus = (id, e) => {
    setUpdateModal(true);
    setToggleStatus(e.target.checked);
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  /*
   * The function `updateStatusHandler` toggles the status of an asset between active and inactive based on a boolean value, and displays success or error messages accordingly.
   */
  const updateStatusHandler = async (flag = "") => {
    let finalStatus = toggleStatus ? "active" : "inactive";
    const response = await updateAssetsStatus(
      data?.id,
      finalStatus,
      "",
      organisationId,
      onlyParent ? "no" : "yes"
    );
    if (response.status == 200) {
      if (response?.data?.status == "active") {
        toggleUpdateSuccessModal("close");
        toast.success(t.assets.form.update_status_asset_success);
      } else {
        toggleUpdateSuccessModal("close");
        toast.success(t.assets.form.update_status_asset_success_deactivate);
      }
      toggleUpdateSuccessModal();
    } else if (response.status == 400) {
      toggleUpdateSuccessModal();
      toast.error(t.assets.form.related_status_asset_error);
      setToggleStatus(data?.attributes?.status == "active" ? true : false);
    } else {
      toast.error(t.common.something_wrong);
    }

    setOnlyParent(true);
  };

  /*
   * The function `toggleUpdateSuccessModal` toggles a modal and resets filter options and search parameters if data is provided.
   */
  const toggleUpdateSuccessModal = (data) => {
    setUpdateSuccessModal(!updateSuccessModal);
    if (data) {
      setFilterOptions({
        status: [],
        searchTitleType: 0,
        wed: {
          value: 0,
          text: "Any Date",
        },
        createdAt: {
          value: 0,
          text: t.assets.filter.createdAt,
        },
        locations: [],
        createdByUser: [],
        customers: [],
        vendors: [],
        categories: [],
        serialNumber: "",
      });

      setSearchTitle("");
      setSelectedDates([]);
      untoggle();
    }
  };

  let peopleName = data?.attributes?.peoples?.data;
  if (peopleName?.length > 0) {
    peopleName = peopleName.map((person, index) => {
      return person?.attributes?.profileImage?.data?.attributes?.url ? (
        checkForLinkPermission("people") ? (
          <div className={peopleName?.length == 1 ? "d-flex" : "d-flex mb-5"}>
            <Image
              avatar
              title={
                person?.attributes?.firstName +
                " " +
                person?.attributes?.lastName
              }
              src={
                IMAGES_URL +
                person?.attributes?.profileImage?.data?.attributes?.url
              }
              height="34px"
              width="34px"
            />

            <div
              style={{ marginLeft: "4px" }}
              onClick={() =>
                history.push(`/people?id=${person?.attributes?.countId}`)
              }
              className="work-order-link mt-1"
            >
              {person?.attributes?.firstName +
                " " +
                person?.attributes?.lastName}
            </div>
          </div>
        ) : (
          <div className={peopleName?.length == 1 ? "d-flex" : "d-flex mb-5"}>
            <Image
              avatar
              title={
                person?.attributes?.firstName +
                " " +
                person?.attributes?.lastName
              }
              src={
                IMAGES_URL +
                person?.attributes?.profileImage?.data?.attributes?.url
              }
              height="34px"
              width="34px"
            />

            <div style={{ marginLeft: "4px" }} className=" mt-1">
              {person?.attributes?.firstName +
                " " +
                person?.attributes?.lastName}
            </div>
          </div>
        )
      ) : checkForLinkPermission("people") ? (
        <div className={peopleName?.length == 1 ? "d-flex" : "d-flex mb-5"}>
          <Image
            height="34px"
            width="34px"
            avatar
            title={
              person?.attributes?.firstName + " " + person?.attributes?.lastName
            }
            src={LetteredAvatarImageUrl(
              person?.attributes?.firstName + " " + person?.attributes?.lastName
            )}
          />

          <div
            className="work-order-link mt-1"
            onClick={() =>
              history.push(`/people?id=${person?.attributes?.countId}`)
            }
          >
            {person?.attributes?.firstName + " " + person?.attributes?.lastName}
          </div>
        </div>
      ) : (
        <div className={peopleName?.length == 1 ? "d-flex" : "d-flex mb-5"}>
          <Image
            height="34px"
            width="34px"
            avatar
            title={
              person?.attributes?.firstName + " " + person?.attributes?.lastName
            }
            src={LetteredAvatarImageUrl(
              person?.attributes?.firstName + " " + person?.attributes?.lastName
            )}
          />

          <div className=" mt-1">
            {person?.attributes?.firstName + " " + person?.attributes?.lastName}
          </div>
        </div>
      );
    });
  } else {
    peopleName = "";
  }
  let teamName = data?.attributes?.teams?.data;

  if (teamName?.length > 0) {
    teamName = teamName.map((team, index) =>
      checkForLinkPermission("team") ? (
        <div className="d-flex mb-5">
          <Image
            avatar
            title={team?.attributes.name}
            src={LetteredAvatarImageUrl(team?.attributes.name)}
            height="34px"
            width="34px"
          />

          <div
            style={{ marginLeft: "4px" }}
            onClick={() =>
              history.push(`/teams?id=${team?.attributes?.countId}`)
            }
            className="work-order-link mt-1 "
          >
            {team?.attributes?.name ? team.attributes.name : t.common.na}
          </div>
        </div>
      ) : (
        <div className="d-flex mb-5">
          <Image
            avatar
            title={team?.attributes.name}
            src={LetteredAvatarImageUrl(team?.attributes.name)}
            height="34px"
            width="34px"
          />

          <div style={{ marginLeft: "4px" }} className="mt-1 ">
            {team?.attributes?.name ? team.attributes.name : t.common.na}
          </div>
        </div>
      )
    );
  } else {
    teamName = "";
  }

  /*
   * The function `confirmDeclineUser` displays a button to dispose an asset if its status is not already set to 'dispose'.
   */
  const confirmDeclineUser = () => {
    if (data?.attributes.status !== "dispose") {
      return (
        <div className="hold-button-container">
          <button
            onClick={() => {
              setDisposeModal(true);
            }}
            style={
              selectedLanguage == "en"
                ? { marginBottom: "22px" }
                : { marginBottom: "22px" }
            }
            className="form-action-button  light-blue"
          >
            {t.assets.disposed}
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <div className="preview-details-wrapper">
        <div className="intro">
          <div
            className={
              data?.attributes?.status === "completed" ||
              data?.attributes?.status === "inProgress" ||
              data?.attributes?.status === "onHold"
                ? "text-right"
                : ""
            }
            style={{ fontSize: "15px" }}
          ></div>
        </div>

        <div className="data-header">
          {userPermissions?.asset?.edit && data?.attributes?.status
            ? confirmDeclineUser()
            : null}

<div
          style={
            data?.attributes?.image?.data?.attributes?.ext == ".mp3" ||
            data?.attributes?.image?.data?.attributes?.ext == ".mp4"
              ? {}
              : data?.attributes?.image?.data?.attributes
              ? { borderRadius: "8px", background: "lightgrey" }
              : {}
          }
          className="d-flex justify-content-center align-items-center"
        >
          { data?.attributes?.image?.data?.attributes?.ext == ".mp3" ||
          data?.attributes?.image?.data?.attributes?.ext == ".mp4" ?
          <video width="500" height={data?.attributes?.image?.data?.attributes?.ext == ".mp3" ? "50" : "156"} controls>
            <source
              src={IMAGES_URL + data.attributes.image.data.attributes.url}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video> :
          <SemanticImage
            src={
              data?.attributes.image?.data?.attributes
                ? IMAGES_URL + data.attributes.image.data.attributes.url
                : toAbsoluteUrl("/media/default-img.png")
            }
            size="medium"
            className={
              data?.attributes?.image?.data?.attributes
                ? "data-header-image"
                : "no-img"
            }
          /> }
        </div>

        {/* <div
          style={
            data?.attributes?.image?.data?.attributes
              ? { borderRadius: "8px", background: "lightgrey" }
              : {}
          }
          className="d-flex justify-content-center align-items-center"
        >
            <SemanticImage
              src={
                data?.attributes?.image?.data
                  ? IMAGES_URL + data.attributes.image.data.attributes.url
                  : toAbsoluteUrl("/media/default-img.png")
              }
              size="medium"
              className={
                data?.attributes?.image?.data?.attributes
                  ? " data-header-image"
                  : "no-img"
              }
            />
          </div> */}
          <div className="header-title mt-5 d-flex justify-content-between align-items-center">
            <div className="header-pill-container">
              <p className="detail-title">{data?.attributes?.name}</p>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {userPermissions?.asset?.statusToggle &&
            data?.attributes?.status !== "dispose" ? (
              <div>
                <div className="d-flex align-items-center">
                  <div className="ml-3 mr-3">
                    {data?.attributes?.status == "active"
                      ? t.assets.active
                      : t.assets.inActive}
                  </div>
                  <Toggle
                    checked={toggleStatus}
                    defaultChecked={
                      data?.attributes?.status == "active" ? true : false
                    }
                    onChange={(e) => handleAssetStatus(data?.id, e)}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Divider />
        <div className="detail-intro">
          <div className="d-flex align-items-start">
            <label className="detail-intro-label asset-label">
              {t.assets.form.description}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.description || t.common.na}
            </p>
          </div>
          <div className="d-flex align-items-start mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.serial_number}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.serialNumber || t.common.na}
            </p>
          </div>
          <div className="d-flex align-items-start mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.model}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.model || t.common.na}
            </p>
          </div>
          <div className="d-flex align-items-start mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.category}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.categories?.data?.attributes?.name ||
                t.common.na}
            </p>
          </div>
          <div className="d-flex align-items-start mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.procedure}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.procedure?.data?.length ? (
                data?.attributes?.procedure?.data.map((each) =>
                  checkForLinkPermission("procedure") ? (
                    <span
                      className="detail-intro-link ml-4 mr-4"
                      basic
                      key={each?.id} // Add key for better performance with lists
                      onClick={() =>
                        history.push(
                          `/procedures?id=${each?.attributes?.countId}`
                        )
                      }
                    >
                      {each?.attributes?.title}
                    </span>
                  ) : (
                    <span
                      className=" ml-1 mr-1"
                      basic
                      key={each?.id} // Add key for better performance with lists
                    >
                      {each?.attributes?.title}
                    </span>
                  )
                )
              ) : (
                <p>{t.common.na}</p>
              )}
              {/* {data?.attributes?.procedure &&
              data?.attributes?.procedure?.data.length
                ? data?.attributes?.procedure?.data.map((each) => {

                  checkForLinkPermission('procedure') ?
                    return (
                      <span
                        className="detail-intro-link ml-4 mr-4"
                        basic
                        onClick={() =>
                          history.push(`/procedures?id=${each?.id}`)
                        }
                      >
                        {each?.attributes?.title}
                      </span>
                    ) : return null
                  })
                : t.common.na} */}
            </p>
          </div>
          <div className="d-flex align-items-start mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.location}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.location?.data?.id ? (
                checkForLinkPermission("location") ? (
                  <span
                    className="detail-intro-link"
                    basic
                    onClick={() =>
                      history.push(
                        `/locations?id=${data?.attributes?.location?.data?.attributes?.countId}`
                      )
                    }
                  >
                    {data?.attributes?.location?.data?.attributes?.name}
                  </span>
                ) : (
                  <span className=" ml-1 mr-1" basic>
                    {data?.attributes?.location?.data?.attributes?.name}
                  </span>
                )
              ) : (
                t.common.na
              )}
            </p>
          </div>
          {/* <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label">
              {t.assets.form.sub_locations}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.subLocations?.data?.attributes?.name ||
                t.common.na}
            </p>
          </div> */}
          <div className="d-flex align-items-start mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.area}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.area?.data?.attributes?.name
                ? data?.attributes?.area?.data?.attributes?.name
                : t.common.na}
            </p>
          </div>
          <div className="d-flex align-items-start mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.customer}
              {":"}
            </label>
            <p  className={`workorder-intro-value ${data?.attributes?.customer?.data ? 'detail-intro-link' : ""} `}>
              {data?.attributes?.customer?.data ? (
                <Image
                  avatar
                  title={
                    data?.attributes?.customer?.data?.attributes.name + " "
                  }
                  src={
                    data?.attributes?.customer?.data?.attributes?.logo?.data
                      ?.data?.attributes?.customer?.data?.attributes?.logo?.data
                      ?.attributes?.url
                      ? IMAGES_URL +
                        data?.attributes?.customer?.data?.attributes?.logo?.data
                          ?.attributes?.url
                      : LetteredAvatarImageUrl(
                          data?.attributes?.customer?.data?.attributes.name +
                            " "
                        )
                  }
                  style={
                    selectedLanguage == "en"
                      ? { width: "34px", height: "34px", right: "4px" }
                      : { width: "34px", height: "34px", left: "4px" }
                  }
                />
              ) : null}
              {/* <span style={{ marginLeft: '4px', marginRight: '4px' }}>
             
            </span> */}
       {data?.attributes?.customer?.data ?  <span onClick={()=>{
                    history.push(`/customers?id=${data?.attributes?.customer?.data?.attributes.countId}`)
                  }}> {data?.attributes?.customer?.data?.attributes.name}</span>  : t.common.na}
            </p>
          </div>
          <Divider />
          <p className="asset-title-info">{t.assets.asset_info}</p>
          <div className="d-flex align-items-start mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.vendor}
              {":"}
            </label>
            <p  className={`workorder-intro-value ${data?.attributes?.vendor?.data ? 'detail-intro-link' : ""} `}>
              {data?.attributes?.vendor?.data ? (
                <Image
                  avatar
                  title={data?.attributes?.vendor?.data?.attributes.name + " "}
                  src={
                    data?.attributes?.vendor?.data?.attributes?.logo?.data?.data
                      ?.attributes?.vendor?.data?.attributes?.logo?.data
                      ?.attributes?.url
                      ? IMAGES_URL +
                        data?.attributes?.vendor?.data?.attributes?.logo?.data
                          ?.attributes?.url
                      : LetteredAvatarImageUrl(
                          data?.attributes?.vendor?.data?.attributes.name + " "
                        )
                  }
                  style={
                    selectedLanguage == "en"
                      ? { width: "34px", height: "34px", right: "4px" }
                      : { width: "34px", height: "34px", left: "4px" }
                  }
                />
              ) : null}
              {/* <span style={{ marginLeft: '4px', marginRight: '4px' }}>
             
            </span> */}
                {data?.attributes?.vendor?.data ? <span onClick={()=>{
                    history.push(`/vendors?id=${data?.attributes?.vendor?.data?.attributes.countId}`)
                  }}>   {data?.attributes?.vendor?.data?.attributes?.name}</span>  : t.common.na} 
            </p>
          </div>

          <div className="d-flex mt-3">
            <label className="workorder-intro-label asset-label mt-3">
              {t.locations.form.people}
              {":"}
            </label>
            <p
              className={`workorder-intro-value ${
                peopleName.length > 0 ? "" : "ml-5 mr-5 mt-3"
              }`}
            >
              {peopleName.length > 0 ? peopleName : t.common.na}
            </p>
          </div>

          <div className="d-flex mt-3">
            <label className="workorder-intro-label asset-label mt-3">
              {t.locations.form.teams}
              {":"}
            </label>
            <p
              className={`workorder-intro-value ${
                teamName.length > 0 ? "" : "ml-5 mr-5 mt-3"
              }`}
            >
              {teamName.length > 0 ? teamName : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label asset-label">
              {t.assets.form.parts}
              {":"}
            </label>
            <p className="workorder-intro-value">
              {data?.attributes?.parts?.data?.length
                ? data.attributes.parts.data.map((asset, index) => (
                    <React.Fragment key={index}>
                      {checkForLinkPermission("part") ? (
                        <span
                          className={`detail-intro-link mb-1 ${
                            selectedLanguage == "en"
                              ? "text-left"
                              : "text-right"
                          }`}
                          basic
                          onClick={() =>
                            history.push(
                              `/parts?id=${asset?.attributes?.countId}`
                            )
                          }
                        >
                          {asset.attributes.name}
                        </span>
                      ) : (
                        <span className=" ml-1 mr-1">
                          {asset?.attributes?.name}
                        </span>
                      )}
                      {index !== data?.attributes?.parts?.data?.length - 1 &&
                        ", "}
                    </React.Fragment>
                  ))
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.purchase_price}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.purchasePrice
                ? data.attributes.purchasePrice + " " + organisationCurrency
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.manufacturer}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.manufacturer
                ? data.attributes.manufacturer
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.depreciation_rate}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.descriptionRate
                ? data.attributes.descriptionRate
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.estimated_life}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.estimatedUsefulLife
                ? data.attributes.estimatedUsefulLife +
                  " " +
                  t.workOrders.details_view.years
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.placed_in_service_date}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.placedDate ? (
                <Moment
                  format="MMMM DD, YYYY"
                  date={data?.attributes?.placedDate}
                />
              ) : (
                t.common.na
              )}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.warranty_expiration_date}
              {":"}
            </label>
            <p className="detail-intro-value">
              {data?.attributes?.warrantyExpirationDate ? (
                <Moment
                  format="MMMM DD, YYYY"
                  date={data?.attributes?.warrantyExpirationDate}
                />
              ) : (
                t.common.na
              )}
            </p>
          </div>

          <div className="d-flex align-items-start mt-5">
            <label className="detail-intro-label asset-label">
              {t.assets.form.warranty_file}
              {":"}
            </label>
            <p className="workorder-intro-value">
            {data?.attributes?.warrantyFile?.data?.length
              ? data.attributes.warrantyFile.data.map((attachment, index) => (
                  <React.Fragment key={index}>
                    <p
                      className={`detail-intro-link mb-1 ${
                        selectedLanguage == 'en' ? 'text-left' : 'text-right'
                      }`}
                      basic
                      onClick={() => {
                        window.open(attachment?.attributes?.url, '_blank');
                      }}
                    >
                      {attachment?.attributes?.name}
                    </p>
                  </React.Fragment>
                ))
              : t.common.na}
          </p>
            {/* <p className="detail-intro-value">
              {data?.attributes?.warrantyFile?.data?.attributes?.url ? (
                <span
                  className="detail-intro-link ml-4 mr-4"
                  basic
                  onClick={() =>
                    window.open(
                      `${IMAGES_URL +
                        data?.attributes?.warrantyFile?.data?.attributes?.url}`,
                      "_blank"
                    )
                  }
                >
                  {data?.attributes?.warrantyFile?.data?.attributes?.name}
                </span>
              ) : (
                t.common.na
              )}
            </p> */}
          </div>

          <Divider />
          <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label asset-label ">
          {data?.attributes?.qrcode
            ? t.assets.filter.qrcode
            : t.assets.filter.barcode}
            {":"}
          </label>
          <p className="workorder-intro-value set-width">
          <div className="d-flex">
            <div>
              <QRComponentToPrint
                isQRcode={data?.attributes?.qrcode}
                ref={qrRef}
                data={data}
                titleData={t.assets.form.serial_number}
                titleName={t.assets.table.name}
              />
            </div>
            <div className="m-3">
              <ReactToPrint
                trigger={() => (
                  <span
                    className="c-pointer"
                    style={{ position: "relative" }}
                    onMouseOver={() => setPrintTooltip(true)}
                    onMouseLeave={() => setPrintTooltip(false)}
                  >
                    <Printer />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position="top center"
                      show={showPrintTooltip}
                      textBoxWidth="35px"
                      arrowAlign="center"
                      fontSize="9px"
                      fontWeight="400"
                      fontFamily="Roboto"
                      padding="5px 5px 5px 8px"
                      borderRadius="6px"
                      static
                      moveUp="5px"
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.print}
                      </span>
                    </ToolTip>
                  </span>
                )}
                content={() => {
                  return qrRef.current;
                }}
              />
              <span
                className="ml-2 mr-2 c-pointer"
                style={{ position: "relative" }}
                onMouseOver={() => setDownloadTooltip(true)}
                onMouseLeave={() => setDownloadTooltip(false)}
                onClick={() => {
                  qrRef.current.exposeDownloadFunction();
                }}
              >
                <FileDownloadIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="top center"
                  show={showDownloadTooltip}
                  textBoxWidth="92px"
                  arrowAlign="center"
                  fontSize="9px"
                  fontWeight="400"
                  fontFamily="Roboto"
                  padding="5px 5px 5px 8px"
                  borderRadius="6px"
                  static
                  moveUp="5px"
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.download_QR}
                  </span>
                </ToolTip>
              </span>
            </div>
          </div></p></div>

          {data?.attributes?.dispose?.id ? (
            <>
              <Divider />

              <p className="asset-title-info d-flex">
                <AssetDisposeIcon />{" "}
                <span className="ml-2 mr-2 mt-1">
                  {t.assets.asset_dispose_statement}
                </span>
              </p>

              <div className="d-flex align-items-center mt-5">
                <label className="detail-intro-label">
                  {t.assets.dispose_reason}
                  {":"}
                </label>
                <p className="detail-intro-value mt-1 ">
                  {data?.attributes?.dispose.reason
                    ? data?.attributes?.dispose.reason
                    : t.common.na}
                </p>
              </div>

              <div className="d-flex align-items-center mt-5">
                <label className="detail-intro-label">
                  {t.assets.added_cost}
                  {":"}
                </label>
                <p className="detail-intro-value mt-1">
                  {data?.attributes?.dispose.costs
                    ? data?.attributes?.dispose.costs +
                      " " +
                      organisationCurrency
                    : t.common.na}
                </p>
              </div>
            </>
          ) : null}

          <Divider />

          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label asset-label grey">
              {t.workOrders.table.createdByUser}
              {":"}
            </label>
            <p className="workorder-intro-value asset-label grey">
              {data?.attributes?.createdByUser?.data?.attributes?.firstName &&
              data?.attributes?.createdByUser?.data?.attributes?.lastName
                ? data?.attributes?.createdByUser?.data?.attributes?.firstName +
                  " " +
                  data?.attributes?.createdByUser?.data?.attributes?.lastName
                : t.common.na}
              {", "}
              <Moment
                format="DD-MM-YYYY LT"
                date={data?.attributes?.createdAt}
              />
            </p>
          </div>

          <div className="d-flex align-items-start mt-3">
            <label className="workorder-intro-label asset-label grey">
              {t.common.update_by}
              {":"}
            </label>
            <p className="workorder-intro-value grey">
              {data?.attributes?.updatedByUser?.data
                ? data?.attributes?.updatedByUser?.data?.attributes?.firstName +
                  " " +
                  data?.attributes?.updatedByUser?.data?.attributes?.lastName +
                  ", " +
                  moment(data?.attributes?.updatedAt).format("DD-MM-YYYY LT")
                : t.common.na}
            </p>
          </div>
        </div>
      </div>

      {/* Asset active/deactive modal */}
      <Modal
        size="tiny"
        open={updateModal}
        className="assets-modal"
        onClose={() => {
          setUpdateModal(false);
          setToggleStatus(data?.attributes?.status == "active" ? true : false);
        }}
        style={{ minHeight: "191px" }}
      >
        <div className="header-container">
          <span className="assets-header">
            {data?.attributes?.status == "active"
              ? t.assets.deactivate_asset
              : t.assets.activate_asset}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setUpdateModal(false);
                setToggleStatus(
                  data?.attributes?.status == "active" ? true : false
                );
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content>
          <p className="assets-label ml-1 mt-3" style={{ lineHeight: "24px" }}>
            {data?.attributes?.status == "active" &&
            data?.attributes?.storedAssets?.length > 0
              ? t.assets.deactivate_label_parent
              : data?.attributes?.storedAssets?.length > 0
              ?  `${t.assets.form.activate_asset_statement1} ${data?.attributes?.name} ?`
              : data?.attributes?.status == "active"
              ? `${t.assets.form.deactivate_asset_statement1} ${data?.attributes?.name} ?`
              : `${t.assets.form.activate_asset_statement1} ${data?.attributes?.name} ?`}
          </p>
          {data?.attributes?.storedAssets?.length > 0 && data?.attributes?.status == "active"? (
            <div className="d-flex flex-column mt-7 ml-3 radio-text ">
              <div className="d-flex mb-4">
                <input
                  className="procedure-item-option-radio-asset"
                  type="checkbox"
                  value={onlyParent ? "true" : "false"}
                  checked={onlyParent}
                  onChange={() => {
                    setOnlyParent(true);
                  }}
                />
                {/* <Radio
                // label="This work order "
                name="procedure-item-option-radio-assets"
                className="wo-radio"
                value={onlyParent ? 'true' : 'false'}
                checked={onlyParent}
                onChange={() => {
                  setOnlyParent(true);
                }}
              /> */}
                <label className="radio-text asset ml-3 mr-3">
                  {" "}
                  {t.assets.deactivate_label_option1}
                </label>
              </div>

              <div className="d-flex">
                <input
                  className="procedure-item-option-radio-asset"
                  type="checkbox"
                  value={onlyParent ? "false" : "true"}
                  checked={!onlyParent}
                  onChange={() => {
                    setOnlyParent(false);
                  }}
                />
                {/* <Radio
                // label="This work order "
                name="radioGroup"
                className="wo-radio"
                value={onlyParent ? 'false' : 'true'}
                checked={!onlyParent}
                onChange={() => {
                  setOnlyParent(false);
                }}
              /> */}
                <label className="radio-text asset ml-3 mr-3">
                  {" "}
                  {t.assets.deactivate_label_option2}
                </label>
              </div>
            </div>
          ) : null}

          <div
            style={{ fontWeight: "500", cursor: "default " }}
            className="assets-label work-order-link ml-1"
          >
            {!onlyParent && data?.attributes?.status == "active" &&
              data?.attributes?.storedAssets?.map((el) => (
                <>
                  {el?.childAsset && el?.childAsset?.data ? (
                    <>
                      <p
                        className={
                          selectedLanguage == "en" ? "c-pointer mb-1 ml-10" : "c-pointer mr-10 mb-1"
                        }
                        onClick={() => {
                          history.push(
                            `/assets?id=${el?.childAsset?.data?.attributes?.countId}`
                          );
                          setUpdateModal(false);
                          setToggleStatus(
                            data?.attributes?.status == "active" ? true : false
                          );
                        }}
                      >
                        {selectedLanguage == "en"
                          ? `#${el?.childAsset?.data?.attributes?.countId}`
                          : `${el?.childAsset?.data?.attributes?.countId}#`}
                        {"-"}
                        {DisplayFormattedText(
                          el?.childAsset?.data?.attributes?.name,
                          25
                        )}
                      </p>
                    </>
                  ) : (
                    ""
                  )}

                  {el?.grandChildAsset?.data?.length > 0
                    ? el?.grandChildAsset?.data?.map((el) => (
                        <>
                          <p
                            className={
                              selectedLanguage == "en" ? "c-pointer ml-14" : "c-pointer mr-14"
                            }
                            onClick={() => {
                              history.push(
                                `/assets?id=${el?.attributes?.countId}`
                              );
                              setUpdateModal(false);
                              setToggleStatus(
                                data?.attributes?.status == "active"
                                  ? true
                                  : false
                              );
                            }}
                          >
                            {selectedLanguage == "en"
                              ? `#${el?.attributes?.countId}`
                              : `${el?.attributes?.countId}#`}
                            {"-"}
                            {DisplayFormattedText(el?.attributes?.name, 25)}
                          </p>
                        </>
                      ))
                    : null}
                </>
              ))}
          </div>
        </Modal.Content>
        <div
          className={`modal-button-container mt-3 ${
            selectedLanguage == "en" ? "mr-6" : "ml-10"
          }`}
        >
          <button
            onClick={() => {
              setUpdateModal(false);
              setToggleStatus(
                data?.attributes?.status == "active" ? true : false
              );
            }}
            style={{ color: "black" }}
            className="modal-form-action-button"
          >
            {t.common.cancel}
          </button>

          <button
            onClick={() => {
              setUpdateModal(false);
              updateStatusHandler();
            }}
            className="modal-form-action-button light-blue"
          >
            {data?.attributes?.status == "active"
              ? t.assets.deactivate
              : t.assets.activate}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Details;
