import React from 'react';
import Moment from 'react-moment';
import { Divider, Image, Label } from 'semantic-ui-react';
import {
  Attachment,
  Kanbanflag,
  KanbanLocation,
  KanbanOrg,
  KanbanUser,
} from '../../../Assets/Icons/svg';
import { humanize, IMAGES_URL } from '../../config/functions';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
import UserProfilePlaceholder from '../../components/common/userProfilePlaceholder';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';

const KanbarHeader = ({
  svg,
  color,
  name = '',
  data = [],
  orderClick = () => {},
}) => {
  const t = useSelector(selectTranslations);
  const { DisplayFormattedText } = useDisplayFormattedText();
  const lang = useSelector((state) => state.i18n.lang);
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();
  const categoryOptions = [
    {
      value: 'damage',
      label: t.workOrders.form.category_options[0],
    },
    {
      value: 'corrective action Downtime',
      label: t.workOrders.form.category_options[1],
    },
    {
      value: 'planned Downtime',
      label: t.workOrders.form.category_options[2],
    },
    {
      value: 'unplanned Downtime',
      label: t.workOrders.form.category_options[3],
    },
    {
      value: 'inspection',
      label: t.workOrders.form.category_options[4],
    },
    {
      value: 'electrical',
      label: t.workOrders.form.category_options[5],
    },
    {
      value: 'mechanical',
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: 'HVAC',
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: 'preventive maintenance',
      label: t.workOrders.form.category_options[8],
    },
    {
      value: 'project',
      label: t.workOrders.form.category_options[9],
    },
    {
      value: 'safety',
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: 'sop',
      label: t.workOrders.form.category_options[11],
    },
  ];
  const priorityOptions = [
    {
      value: 'none',
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: 'low',
      label: t.workOrders.priority_status.low,

      color: 'green',
    },
    {
      value: 'medium',
      label: t.workOrders.priority_status.medium,

      color: 'orange',
    },
    {
      value: 'high',
      label: t.workOrders.priority_status.high,

      color: 'red',
    },
  ];
  const handleAttachmentClick = (one) => {
    if (one?.attributes?.attachments?.data) {
      one.attributes.attachments.data.map((file) => {
        window.open(IMAGES_URL + file.attributes.url, '_blank');
      });
    }
  };
  return (
    <>
      <div className="">
        <div className={`kanbar-header ${color}`}>
          <div className="svg-header">{svg}</div>
          <p className="workorder-intro-value d-flex gap-3 align-items-center">
            <div className="item-value ">
              <Label
                circular
                empty
                color={color}
                style={{ marginRight: '5px', marginTop: '5px' }}
                size="big"
              />
            </div>
            <span className="mt-1 ml-2 mr-2">{name}</span>
            <span className="kanban_total_count">{data.length}</span>
          </p>
        </div>
        {data.map((one, index) => {
          let priorityColor = priorityOptions.find(
            (e) => e.value == one?.attributes?.priority
          );

          let categoryColor = categoryOptions.find(
            (e) => e.value == one?.attributes?.category
          );
          let assigneePeople = one?.attributes?.people?.data;
          let totalLength = one?.attributes?.people?.data
            ? one?.attributes?.people?.data.length
            : 0;

          if (assigneePeople?.length) {
            if (assigneePeople?.length < 3) {
              assigneePeople = assigneePeople.map((people, index) => {
                if (people?.attributes?.profileImage?.data?.attributes?.url) {
                  {
                    return (
                      <Image
                        avatar
                        title={
                          people.attributes.firstName +
                          ' ' +
                          people.attributes.lastName
                        }
                        src={`${IMAGES_URL}${people?.attributes?.profileImage?.data?.attributes?.url}`}
                        style={
                          index != 0
                            ? {
                                marginLeft: '-6px',
                                width: '34px',
                                height: '34px',
                              }
                            : { width: '34px', height: '34px' }
                        }
                        className={index == 0 ? 'img-first' : ''}
                      />
                    );
                  }
                } else {
                  return (
                    <UserProfilePlaceholder
                      name={`${
                        people?.attributes?.firstName
                          ? people?.attributes?.firstName
                          : ''
                      } ${
                        people?.attributes?.lastName
                          ? people?.attributes?.lastName
                          : ''
                      }`}
                      width={'34px'}
                      height={'34px'}
                      className={index == 0 ? 'img-first' : ''}
                    />
                  );
                }
              });
            } else {
              assigneePeople = assigneePeople
                .slice(0, 2)
                .map((people, index) => {
                  if (people?.attributes?.profileImage?.data?.attributes?.url) {
                    {
                      return (
                        <Image
                          avatar
                          title={
                            people.attributes.firstName +
                            ' ' +
                            people.attributes.lastName
                          }
                          src={`${IMAGES_URL}${people?.attributes?.profileImage?.data?.attributes?.url}`}
                          style={
                            index != 0
                              ? {
                                  marginLeft: '-6px',
                                  width: '34px',
                                  height: '34px',
                                }
                              : { width: '34px', height: '34px' }
                          }
                        />
                      );
                    }
                  } else {
                    return (
                      <UserProfilePlaceholder
                        name={`${
                          people?.attributes?.firstName
                            ? people?.attributes?.firstName
                            : ''
                        } ${
                          people?.attributes?.lastName
                            ? people?.attributes?.lastName
                            : ''
                        }`}
                        width={'34px'}
                        height={'34px'}
                      />
                    );
                  }
                });
              assigneePeople.push(
                <Image
                  onClick={(e) => {
                    orderClick(one);
                  }}
                  avatar
                  title={`${totalLength - 2}`}
                  src={`https://ui-avatars.com/api/?name=${totalLength -
                    2}%2B&background=8CCA9D&bold=true&color=FFFFFF`}
                  style={{
                    width: '34px',
                    height: '34px',
                  }}
                />
              );
            }
          }
          return (
            <div
              className="kanban-card c-pointer"
              onClick={() => orderClick(one)}
              // key={one?.attributes?.title}
            >
              <div className="d-flex justify-content-between">
                <div className="detail-container">
                  <p className="title m-0">
                    {DisplayFormattedText(one?.attributes?.title)}
                  </p>
                  <p className="description">
                    {DisplayFormattedText(one?.attributes?.description)}
                  </p>
                </div>
                
                <div className="profile-container">
                  <div className="multi-profile-container">
                    {assigneePeople}
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <span className="pill grey">
                  {categoryColor?.label ? categoryColor?.label : null}
                </span>
              
              </div>
              <div className="mt-4">
                <span className="mr-3" title={priorityColor?.label}>
                  <Kanbanflag fill={priorityColor?.color} />
                </span>
                <span className="kanban-due-date pr-2">
                  <Moment format="DD-MM-YYYY" date={one?.attributes?.dueDate} />
                </span>{' '}
                <span className="kanban-due-time pr-2">
                  <Moment
                    format=" h:m a"
                    date={
                      one?.attributes?.dueDate +
                      ' ' +
                      `${one?.attributes?.dueTime || '08:00:00'}`
                    }
                  />
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                {/* {userPermissions?.customer && userPermissions?.customer?.view ?  */}
                <>
                  <span>
                    <KanbanOrg />
                  </span>
                  <span className="ml-3 mr-3 kanban-org">
                    {DisplayFormattedText(
                      one?.attributes?.customer?.data?.attributes?.name,
                      10
                    ) || t.common.na}
                  </span></>
                  {/* :null} */}
                </div>
                <div>
                  <span>
                    <KanbanLocation />
                  </span>
                  <span className="ml-3 mr-3 kanban-org">
                    {DisplayFormattedText(
                      one?.attributes?.location?.data?.attributes.name,
                      10
                    ) || t.common.na}
                  </span>
                </div>
              </div>

              <Divider />
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  <span>
                    <KanbanUser />
                  </span>
                  <span className="ml-3 mr-3 kanban-created-by">
                    {one?.attributes?.createdByUser?.data?.attributes
                      ? DisplayFormattedText(
                          `${one?.attributes?.createdByUser?.data?.attributes?.firstName} ${one?.attributes?.createdByUser?.data?.attributes?.lastName}`,
                          15
                        )
                      : t.common.na}
                  </span>
                </div>
                <div>
                  {one?.attributes?.attachments?.data ? (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAttachmentClick(one);
                      }}
                    >
                      <Attachment />
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default KanbarHeader;
