import React, { useState, useEffect, createRef } from "react";
import {
  List,
  Header,
  Modal,
  TextArea,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Popup,
  Icon,
  Checkbox,
  Label,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import child_icon from "../../../Assets/Icons/childAsset.png";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import { format } from "date-fns";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import QRCode from "react-qr-code";
import AddCategoryModal from "../../components/modal/category/CategoryCreateModal";

import { useStateWithCallbackLazy } from "use-state-with-callback";
import "react-datepicker/dist/react-datepicker.css";

import {
  updateAsset,
  getAllCustomers,
  getAllVendros,
  uploadFile,
  IMAGES_URL,
  urlToFile,
  getAllProcedures,
  getAllCategories,
  getAllParentLocations,
  getAllTeams,
  getActiveUsersByRole,
  handleKeyDown,
  getAllAreaByLocationIdActive,
  getAllPeopleByLocation,
  getAllAssets,
  getAllLocations,
  getAllAssetsOption,
} from "../../config/functions";

import { useDispatch, useSelector } from "react-redux";
import { generateUniqueInteger } from "../../../utilFunctions/serialNumberGenerator";
import { useHistory } from "react-router-dom";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { selectTranslations } from "../../config/i18n/slice";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import {
  AddIcon,
  AddIconWithCircle,
  AddImage,
  BarcodeIcon,
  CalenderGreyIcon,
  CloseIcon,
  DeleteIcon,
  PercentageIcon,
  PriceIcon,
  QRcodeIconInactive,
  Repeat,
  Success,
  TextSnippet,
} from "../../../Assets/Icons/svg";

import { useDropzone } from "react-dropzone";
import Asterisk from "../../components/common/Asterisk";

import { useSuccessModal } from "../../../hooks/Messages/useSuccessModal";
import { useCancelModal } from "../../../hooks/Messages/useCancelModal";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import DatePicker from "react-multi-date-picker";
import ToolTip from "react-power-tooltip";
import { fetchAssets } from "../../../redux/reducers/assetReducer";
import { Resizable } from "re-resizable";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";

/*
 * The FileUpload component handles file uploads with conditional rendering based on the file type.
 */
const FileUpload = ({
  onFileChange,
  name = "",
  onCertificateFileChange,
  attachments = {},
  setAttachments = () => {},
}) => {
  const fileTypes = {
    images: ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/svg+xml"],
    documents: ["text/plain", "application/pdf", "text/csv", "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
    audio: ["audio/mpeg"],
    video: ["video/mp4"],
  };
  

  const { getRootProps, getInputProps } = useDropzone({
    accept: name === "image" 
    ? Object.fromEntries(fileTypes.images.map(type => [type, []])) 
    : Object.fromEntries(
        [...fileTypes.images, ...fileTypes.documents, ...fileTypes.audio, ...fileTypes.video].map(type => [type, []])
      ),
      onDrop: (acceptedFiles) => {
        let totalFile = attachments?.warrantyFile?.length + acceptedFiles?.length
  
        if (name !== "image" && totalFile > 10) {
          toast.error(`You can only upload a maximum of 10 files.`);
          return;
        }
        const validFiles = acceptedFiles.filter((file) => {
          const isImage = fileTypes.images.includes(file.type);
          const isDocument = fileTypes.documents.includes(file.type);
          const isAudio = fileTypes.audio.includes(file.type);
          const isVideo = fileTypes.video.includes(file.type);
          
          const validSize = 
            ((isImage || isDocument) && file.size <= 5 * 1024 * 1024) || 
            ((isAudio || isVideo) && file.size <= 50 * 1024 * 1024);
  
          if (!validSize) {
            toast.error(`File size too large: ${file.name}`);
            return false;
          }
          return true;
        });
  
        name === "image" ? onFileChange(validFiles, name) : onCertificateFileChange(validFiles, name);
      },
  });
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const renderFile = (file, index) => (
    <div
      className={`image-dropzone file d-flex justify-content-between text-start ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label style={{ fontSize: "16px" }} className="d-flex align-items-center mb-0">
          <div
            className={`${
              selectedLanguage == "en" ? "ml-5 mr-8" : "ml-8 mr-5"
            }`}
          >
            <TextSnippet />
          </div>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.warrantyFile.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            warrantyFile: newCovidCopy,
          });
        }}
      >
        <CloseIcon />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );


  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          <div className="image-dropzone text-center mb-5">
            <label>
              {attachments?.image?.name}
              <span
                className="ml-3 mr-3 c-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setAttachments({
                    ...attachments,
                    image: null,
                  });
                }}
              >
                <DeleteIcon />
              </span>
            </label>
          </div>
        ) : (
          <div className="image-dropzone text-center mb-5">
            <div>
              <p className="p1">
                <span className="mr-2 ml-2">
                  <AddImage />
                </span>
                <span className="mt-1">{t.workOrders.drag_img}</span>
                <span className="mt-1" style={{ color: "#0C66E4" }}>
                  {" "}
                  {t.workOrders.upload_a_file}
                </span>{" "}
              </p>
            </div>
          </div>
        )
      ): attachments?.warrantyFile?.length > 0 ? (
        <>
          {attachments.warrantyFile.map((file, index) =>
            renderFile(file, index)
          )}

          <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between mt-5">
            <div className="d-flex">
              <span className="mt-1">
                <TextSnippet />
              </span>
              <div className="pl-8 pr-8">
                <p className="p1 m-0">{t.workOrders.form.file}</p>
                <p className="p2 m-0">{t.workRequests.form.attchfile}</p>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        </>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <span className="mt-1">
              <TextSnippet />
            </span>
            <div className="pl-8 pr-8">
              <p className="p1 m-0">{t.workOrders.form.file}</p>
              <p className="p2 m-0">{t.workRequests.form.attchfile}</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};


/*
 * The `Edit` function in JavaScript is a component that allows users to edit asset information with features like file uploads, dropdown selections, date pickers, and validation checks.
 */
function Edit({
  toggled,
  untoggle,
  refresh,
  setRefresh,
  targetedData,
  setFilterOptions,
  role,
  allIds,
  setActiveIndex,
  setSearchTitle,
  setSelectedDates,
}) {
  const t = useSelector(selectTranslations);
  const { userPermissions } = usePermissionsSimplified();
  const { setSuccessModal, SuccessModal } = useSuccessModal();
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [isCancleModal, setIsCancleModal] = useState(true);
  const { setCancelModal, CancelModal } = useCancelModal();
  const dispatch = useDispatch();

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const history = useHistory();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const [assetData, setAssetData] = useState([]);
  const [initChange, setInitChange] = useState(false);
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [openCategoryAddModal, setOpenCategoryAddModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const [cancelModal, toggleCancelModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);

  const [fetchingCustomers, setFetchingCustomers] = useState(true);
  const [fetchingVendors, setFetchingVendros] = useState(true);
  const [fetchingPeople, setFetchingPeople] = useState(true);
  const [fetchingTeams, setFetchingTeams] = useState(true);

  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: [],
  });
  const [responseData, setResponseData] = useState({});

  const [options, setOptions] = useState({
    vendors: [],
    customers: [],
    procedures: [],
    categories: [],
    locations: [],
    subLocations: [],
    people: [],
    teams: [],
    area: [],
    child_asset: [],
  });

  const [data, setData] = useState({
    name: "",
    description: "",
    isChild: false,
    parentAsset: "",
    model: "",
    category: "",
    // area: '',
    qrcode: "",
    barcode: "",
    serialNumber: "",
    vendor: "",
    customer: "",
    purchasePrice: 0,
    manufacturer: "",
    descriptionRate: "",
    estimatedUsefulLife: 0,
    placedDate: "",
    warrantyExpirationDate: "",
    additionalInformation: "",
    procedure: "",
    isSubLocation: false,
    isArea: false,
    subLocations: "",
    area: "",
    people: [],
    teams: [],
  });

  const [validation, setValidation] = useStateWithCallbackLazy({
    name: {
      error: false,
      pattern: (name) => name != "",
      skipped: false,
      message: t.common.required_field,
    },
    location: {
      error: true,
      pattern: (name) => name != "",
      skipped: false,
      message: t.common.required_field,
    },
    category: {
      error: false,
      pattern: (category) => category != "",
      skipped: false,
      message: t.common.required_field,
    },
    parent_asset: {
      error: true,
      pattern: (name) => name != "",
      skipped: false,
      message: t.common.required_field,
    },
    serialNumber: {
      error: false,
      pattern: (serialNumber) => serialNumber != "",
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validForm, setValidForm] = useState(false);

  /*
   * The function `resetForm` resets all form data, attachments, validation states, and fetching flags to their initial values.
   */
  const resetForm = () => {
    setData({
      name: "",
      description: "",
      isChild: false,
      parentAsset: "",
      model: "",
      category: "",
      // area: '',
      qrcode: "",
      barcode: "",
      serialNumber: "",
      vendor: "",
      customer: "",
      placedDate: "",
      purchasePrice: 0,
      manufacturer: "",
      descriptionRate: "",
      estimatedUsefulLife: 0,
      warrantyExpirationDate: "",
      additionalInformation: "",
      procedure: "",
      isSubLocation: false,
      isArea: false,
      subLocations: "",
      people: [],
      teams: [],
      area: "",
    });

    setModalWidth(Math.max(window.innerWidth / 2, 514));

    setAttachments({
      image: null,
      warrantyFile: [],
    });

    setFetchingCustomers(true);
    setFetchingVendros(true);
    setFetchingPeople(true);
    setFetchingTeams(true);
    setValidation({
      name: {
        error: false,
        pattern: (name) => name != "",
        skipped: false,
        message: t.common.required_field,
      },
      location: {
        error: true,
        pattern: (name) => name != "",
        skipped: false,
        message: t.common.required_field,
      },
      parent_asset: {
        error: true,
        pattern: (name) => name != "",
        skipped: false,
        message: t.common.required_field,
      },
      category: {
        error: false,
        pattern: (category) => category != "",
        skipped: false,
        message: t.common.required_field,
      },
      serialNumber: {
        error: false,
        pattern: (serialNumber) => serialNumber != "",
        skipped: false,
        message: t.common.required_field,
      },
    });

    setValidForm(false);

    setInitChange(false);
  };

  /*
   * The function fetches active users by role and organizes their data into options for a dropdown menu.
   */
  const fetchPeopleOptions = async () => {
    if (!data?.people) {
      setOptions({
        ...options,
        people: [],
      });
    }

    const fetchData = await getAllPeopleByLocation(
      !(role === "super_admin" || role === "admin")
        ? data?.location
          ? data?.location
          : allIds
        : data?.location
        ? data?.location
        : [],
      organisationId
    );
    if (fetchData) {
      let the_options = fetchData.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);

        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",

          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetchTeamsOptions fetches team data from an API and maps it to create options with key, value, text, and image properties.
   */
  const fetchTeamsOptions = async () => {
    if (!data?.teams) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: [],
      }));
    }
    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(role === "super_admin" || role === "admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: the_options,
      }));

      return the_options;
    }
  };

  const fetchCustomers = async () => {
    if (!data?.customer) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        customers: [],
      }));
    }
    const fetchData = await getAllCustomers(
      organisationId,
      "",
      1,
      99999,
      !(role === "super_admin" || role === "admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions({
        ...options,
        customers: the_options,
      });
    }
  };

  /* The above code is a React useEffect hook that runs when the `toggled` state changes or when the`targetedData` state changes. */
  useEffect(() => {
    if (toggled) {
      fetchOptions();
      if (targetedData) {
        setData({
          ...data,
          name: targetedData.attributes.name,

          isChild: targetedData?.attributes?.isChild
            ? targetedData?.attributes?.isChild
            : false,
          description: targetedData.attributes.description,
          parentAsset: targetedData?.attributes?.isChild
            ? targetedData?.attributes?.childAssets?.data[0]?.id
              ? targetedData?.attributes?.childAssets?.data[0]?.id
              : targetedData?.attributes?.parentAsset?.data?.id
            : null,
          model: targetedData.attributes.model,
          category: targetedData.attributes.categories.data?.id || "",
          area: targetedData?.attributes?.area?.data?.id
            ? targetedData?.attributes?.area?.data?.id
            : "",
          qrcode: targetedData.attributes.qrcode,
          barcode: targetedData.attributes.barcode,
          serialNumber: targetedData.attributes.serialNumber || "",
          customer: targetedData?.attributes?.customer?.data?.id,
          vendor: targetedData.attributes.vendor.data?.id,
          purchasePrice: targetedData.attributes.purchasePrice || 0,
          manufacturer: targetedData.attributes.manufacturer || "",
          descriptionRate:
            parseFloat(targetedData.attributes.descriptionRate) || "",
          estimatedUsefulLife: targetedData.attributes.estimatedUsefulLife || 0,
          procedure:
            targetedData.attributes?.procedure?.data &&
            targetedData.attributes?.procedure?.data.length
              ? targetedData.attributes?.procedure?.data.map((one) => one.id)
              : [],
          placedDate: targetedData.attributes.placedDate
            ? format(new Date(targetedData.attributes.placedDate), "yyyy-MM-dd")
            : null,
          people:
            targetedData?.attributes?.peoples?.data?.length > 0
              ? targetedData.attributes.peoples.data.map(
                  (el_user) => el_user.id
                )
              : [],
          teams:
            targetedData.attributes.teams.data?.length > 0
              ? targetedData.attributes.teams.data.map((el_team) => el_team.id)
              : [],
          warrantyExpirationDate: targetedData.attributes.warrantyExpirationDate
            ? format(
                new Date(targetedData.attributes.warrantyExpirationDate),
                "yyyy-MM-dd"
              )
            : null,
          additionalInformation: targetedData.attributes.additionalInformation,
          isSubLocation: targetedData?.attributes?.subLocations?.data
            ? true
            : targetedData?.attributes?.area &&
              targetedData?.attributes?.area?.length
            ? true
            : false,
          isArea:
            targetedData?.attributes?.area &&
            targetedData?.attributes?.area?.length
              ? true
              : false,
          location: targetedData?.attributes?.location?.data?.id || null,
          subLocations:
            targetedData?.attributes?.subLocations?.data?.id || null,
        });

        console.log(targetedData?.attributes.warrantyFile,"sods")

        setAttachments({
          ...attachments,
          image: targetedData?.attributes.image?.data
            ? {
                id: targetedData?.attributes.image?.data?.id,
                file: urlToFile(
                  targetedData?.attributes.image?.data?.attributes.url,
                  targetedData?.attributes.image?.data?.attributes.name
                ),
                name: targetedData?.attributes.image?.data?.attributes.name,
                type: targetedData?.attributes.image?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          warrantyFile: targetedData?.attributes?.warrantyFile?.data && targetedData?.attributes?.warrantyFile?.data?.length
          ? targetedData?.attributes.warrantyFile?.data?.map((one) => {
            return {
              id: one?.id,
              file: urlToFile(
                one?.attributes?.url,
                one?.attributes?.name
              ),
              name: one?.attributes?.name,
              type: one?.attributes?.mime,
              cancelPopup: false,
              isChanged: false,
            };
          })
        : [],  
          // ? {
            //     id: targetedData?.attributes.warrantyFile?.data?.id,
            //     file: urlToFile(
            //       targetedData?.attributes.warrantyFile?.data?.attributes.url,
            //       targetedData?.attributes.warrantyFile?.data?.attributes.name
            //     ),
            //     name:
            //       targetedData?.attributes.warrantyFile?.data?.attributes.name,
            //     type:
            //       targetedData?.attributes.warrantyFile?.data?.attributes.mime,
            //     cancelPopup: false,
            //     isChanged: false,
            //   }
            // : null,
        });
      }
      // }
    }
  }, [targetedData, toggled]);
  /*
   * The function fetches parent locations data and maps it to create options for a dropdown menu.
   */
  const fetchLocations = async () => {
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(role === "super_admin" || role === "admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };
  /*
   * The function fetchSubLocations retrieves sub locations data based on a given location ID and updates the options state with the fetched data.
   */
  const fetchSubLocations = async () => {
    let the_options = [];
    setOptions({
      ...options,
      subLocations: [],
    });
    if (data.location) {
      const fetchData = await getAllParentLocations(
        organisationId,
        "",
        1,
        99999
      );
      if (fetchData.response) {
        let locationData = fetchData.response.find(
          (one) => one.id == data.location
        );
        // console.log(data, locationData);
        if (locationData && locationData?.attributes?.subLocations?.data) {
          let new_option = locationData?.attributes?.subLocations?.data.map(
            (el) => {
              the_options.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
              });
            }
          );
        }
        setOptions({
          ...options,
          subLocations: the_options,
        });
      }
    }
    // const fetchData = await getAllParentLocations(organisationId);
    // if (fetchData) {
    // console.log(options);

    // }
  };

  const fetchAllAssets = async () => {
    if (data?.isChild) {
      const fetchData = await getAllAssetsOption(
        organisationId,
        "",
        1,
        99999,
        !(role === "super_admin" || role === "admin") ? [] : [],
        [],
        "",
        "",
        [],
        [],
        "",
        !(role === "super_admin" || role === "admin") ? [] : [],
        true
      );

      let the_options = [];
      let parentOptions = [];
      let childOptions = [];
      setAssetData(fetchData);

      if (fetchData?.response?.length > 0) {
        fetchData.response.forEach((el) => {
          if (!(role === "super_admin" || role === "admin")) {
            const isParentDisposed = el.attributes.status === "dispose";

            // Collect child assets and check their status
            let activeChildCount = 0;
            let shouldShowParent = false;

            if (el?.attributes?.childAssets?.data?.length > 0) {
              el.attributes.childAssets.data.forEach((childEl) => {
                const isChildDisposed = childEl.attributes.status === "dispose";
                const childLocationId = childEl.attributes.location?.data?.id;

                // Check if child is active (not disposed) and location is in allIds
                const childIsActive =
                  !isChildDisposed && allIds?.includes(childLocationId);

                if (childIsActive) {
                  activeChildCount++;

                  // Add active child asset option
                  childOptions.push({
                    key: childEl.id,
                    value: childEl.id,
                    text: childEl.attributes.name,
                    image: { src: child_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id,
                    disabled: false, // Active child asset is enabled
                  });
                }

                // Check if we should show the parent based on child activity
                if (childIsActive) {
                  shouldShowParent = true;
                }
              });
            }

            // Determine if the parent should be shown based on conditions
            const parentLocationId = el?.attributes?.location?.data?.id;
            const parentIsActive =
              !isParentDisposed && allIds?.includes(parentLocationId);

            // Conditions for showing and disabling the parent
            if (parentIsActive || shouldShowParent) {
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: parent_icon },
                className: "main-location-dropdown-option",
                disabled:
                  isParentDisposed || (shouldShowParent && !parentIsActive), // Disable if disposed or child is active but parent is not in allIds
              });
            }
          } else {
            const isParentDisposed = el.attributes.status === "dispose";

            // Collect child assets and check their status
            let activeChildCount = 0;
            if (el?.attributes?.childAssets?.data?.length > 0) {
              el.attributes.childAssets.data.forEach((childEl) => {
                const isChildDisposed = childEl.attributes.status === "dispose";
                if (!isChildDisposed) {
                  activeChildCount++;
                  // Add active child asset option
                  childOptions.push({
                    key: childEl.id,
                    value: childEl.id,
                    text: childEl.attributes.name,
                    image: { src: child_icon }, // Use appropriate icon
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                    disabled: false, // Active child asset is enabled
                  });
                }
              });
            }

            // Only add the parent option if:
            // - It's not disposed, OR
            // - It's disposed but has active child assets
            if (!isParentDisposed || activeChildCount > 0) {
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: parent_icon }, // Use appropriate icon
                className: "main-location-dropdown-option",
                disabled: isParentDisposed, // Parent option is disabled if disposed
              });
            }
          }
          // Check if the parent asset is disposed
        });

        // Sort child options alphabetically
        childOptions.sort((a, b) => a.text.localeCompare(b.text));

        // Merge parent options with sorted child options
        parentOptions.forEach((parentOption) => {
          the_options.push(parentOption);
          // Add sorted child options belonging to this parent
          childOptions.forEach((childOption) => {
            if (childOption.parentId === parentOption.key) {
              the_options.push(childOption);
            }
          });
        });

        // Update the options state
        setOptions((prev) => ({
          ...prev,
          child_asset: the_options,
        }));

        return the_options;
      }
    }
  };

  const fetchArea = async () => {
    let the_options_area = [];
    if (data.location) {
      const response = await getAllAreaByLocationIdActive(organisationId, [
        data?.location,
        data?.subLocations,
      ]);
      if (response.length > 0) {
        response.forEach((el) => {
          the_options_area.push({
            key: `${el.id}`,
            value: el.id,
            text: `${el.attributes.name}`,
          });
        });
      }
    }

    setOptions((prev) => {
      return {
        ...prev,
        area: the_options_area,
      };
    });
    // const fetchData = await getAllParentLocations(organisationId);
    // if (fetchData) {
    // console.log(options);

    // }
  };

  /* `useEffect` hooko call the `validationHandler` function whenever the `data` dependency changes. */
  useEffect(() => {
    validationHandler();
  }, [data]);

  /*
   * The function `fetchOptions` retrieves data for customers, vendors, procedures, categories, locations, people, and teams, and formats them into options for a dropdown menu.
   */
  const fetchOptions = async () => {
    const [
      customers,
      vendors,
      procedures,
      categories,
      locations,
      people,
      teams,
      area,
      fetchData,
    ] = await Promise.all([
      getAllCustomers(
        organisationId,
        "",
        1,
        99999,
        !(role === "super_admin" || role === "admin")
          ? targetedData?.attributes?.location?.data?.id
            ? [targetedData?.attributes?.location?.data?.id]
            : allIds
          : targetedData?.attributes?.location?.data?.id
          ? [targetedData?.attributes?.location?.data?.id]
          : []
      ),
      getAllVendros(organisationId, "", 1, 9999),
      getAllProcedures(false, organisationId, "", 1, 99999),
      getAllCategories(organisationId, "", 1, 99999),
      getAllParentLocations(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        ["name:asc"]
      ),
      getAllPeopleByLocation(
        !(role === "super_admin" || role === "admin")
          ? targetedData?.attributes?.location?.data?.id
            ? targetedData?.attributes?.location?.data?.id
            : allIds
          : targetedData?.attributes?.location?.data?.id
          ? targetedData?.attributes?.location?.data?.id
          : [],
        organisationId
      ),
      getAllTeams(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        !(role === "super_admin" || role === "admin")
          ? targetedData?.attributes?.location?.data?.id
            ? [targetedData?.attributes?.location?.data?.id]
            : allIds
          : targetedData?.attributes?.location?.data?.id
          ? [targetedData?.attributes?.location?.data?.id]
          : []
      ),
      getAllAreaByLocationIdActive(organisationId, [
        targetedData?.attributes?.location?.data?.id,
      ]),
      getAllAssetsOption(
        organisationId,
        "all_asset",
        1,
        99999,
        !(role === "super_admin" || role === "admin") ? [] : [],
        [],
        "",
        "",
        [],
        [],
        "",
        !(role === "super_admin" || role === "admin") ? [] : [],
        true
      ),
    ]);

    let customers_options = [];
    if (customers.response) {
      customers_options = customers.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingCustomers(false);
    }

    let the_options_area = [];
    if (area.length > 0) {
      area.forEach((el) => {
        the_options_area.push({
          key: `${el.id}`,
          value: el.id,
          text: `${el.attributes.name}`,
        });
      });
    }

    let vendors_options = [];
    if (vendors.response) {
      vendors_options = vendors.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingVendros(false);
    }

    let people_options = [];
    if (people.length > 0) {
      people_options = people.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);

        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",

          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingPeople(false);
    }

    let teams_options = [];
    if (teams.response) {
      teams_options = teams.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingTeams(false);
    }

    let procedures_options = [];
    if (procedures.response) {
      procedures_options = procedures.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });
      procedures_options.unshift({
        key: "",
        value: "",
        text: "Select Procedure",
      });
    }
    let parentOptions = [];
    let subOptions = [];
    let sub_location_options = [];

    let the_options = [];
    if (locations?.response?.length > 0) {
      locations.response.forEach((el) => {
        if (!(role === "super_admin" || role === "admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
            className: "main-location-dropdown-option",
          });

          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });
    }

    // setOptions({
    //   ...options,
    //   customers: customers?.length > 0 ? customers_options : [],
    //   vendors: vendors?.length > 0 ? vendors_options : [],
    //   procedures: procedures?.length > 0 ? procedures_options : [],

    // });

    let category_options = [];
    if (categories.response) {
      category_options = categories.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
    }
    let child_asset_options = [];
    let parenAssettOptions = [];
    let childOptions = [];
    setAssetData(fetchData);

    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(role === "super_admin" || role === "admin")) {
          const isParentDisposed = el.attributes.status === "dispose";

          // Collect child assets and check their status
          let activeChildCount = 0;
          let shouldShowParent = false;

          if (el?.attributes?.childAssets?.data?.length > 0) {
            el.attributes.childAssets.data.forEach((childEl) => {
              const isChildDisposed = childEl.attributes.status === "dispose";
              const childLocationId = childEl.attributes.location?.data?.id;

              // Check if child is active (not disposed) and location is in allIds
              const childIsActive =
                !isChildDisposed && allIds?.includes(childLocationId);

              if (childIsActive) {
                activeChildCount++;

                // Add active child asset option
                childOptions.push({
                  key: childEl.id,
                  value: childEl.id,
                  text: childEl.attributes.name,
                  image: { src: child_icon },
                  className: "sub-location-dropdown-option",
                  parentId: el.id,
                  disabled: false, // Active child asset is enabled
                });
              }

              // Check if we should show the parent based on child activity
              if (childIsActive) {
                shouldShowParent = true;
              }
            });
          }

          // Determine if the parent should be shown based on conditions
          const parentLocationId = el?.attributes?.location?.data?.id;
          const parentIsActive =
            !isParentDisposed && allIds?.includes(parentLocationId);

          // Conditions for showing and disabling the parent
          if (parentIsActive || shouldShowParent) {
            parenAssettOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: parent_icon },
              className: "main-location-dropdown-option",
              disabled:
                isParentDisposed || (shouldShowParent && !parentIsActive), // Disable if disposed or child is active but parent is not in allIds
            });
          }
        } else {
          const isParentDisposed = el.attributes.status === "dispose";

          // Collect child assets and check their status
          let activeChildCount = 0;
          if (el?.attributes?.childAssets?.data?.length > 0) {
            el.attributes.childAssets.data.forEach((childEl) => {
              const isChildDisposed = childEl.attributes.status === "dispose";
              if (!isChildDisposed) {
                activeChildCount++;
                // Add active child asset option
                childOptions.push({
                  key: childEl.id,
                  value: childEl.id,
                  text: childEl.attributes.name,
                  image: { src: child_icon }, // Use appropriate icon
                  className: "sub-location-dropdown-option",
                  parentId: el.id, // Keep track of the parent
                  disabled: false, // Active child asset is enabled
                });
              }
            });
          }

          // Only add the parent option if:
          // - It's not disposed, OR
          // - It's disposed but has active child assets
          if (!isParentDisposed || activeChildCount > 0) {
            parenAssettOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: parent_icon }, // Use appropriate icon
              className: "main-location-dropdown-option",
              disabled: isParentDisposed, // Parent option is disabled if disposed
            });
          }
        }
        // Check if the parent asset is disposed
      });

      // Sort child options alphabetically
      childOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted child options
      parenAssettOptions.forEach((parentOption) => {
        child_asset_options.push(parentOption);
        // Add sorted child options belonging to this parent
        childOptions.forEach((childOption) => {
          if (childOption.parentId === parentOption.key) {
            child_asset_options.push(childOption);
          }
        });
      });

      // Update the options state
    }

    setOptions({
      ...options,
      customers: customers.response?.length > 0 ? customers_options : [],
      vendors: vendors.response?.length > 0 ? vendors_options : [],
      procedures: procedures.response?.length > 0 ? procedures_options : [],
      categories: categories.response?.length > 0 ? category_options : [],
      locations: locations?.response?.length > 0 ? the_options : [],
      subLocations: sub_location_options || [],
      people: people?.length > 0 ? people_options : [],
      teams: teams?.response?.length > 0 ? teams_options : [],
      area: area?.length > 0 ? the_options_area : [],
      child_asset: fetchData?.response?.length > 0 ? child_asset_options : [],
    });
  };

  /*
   * The function fetches procedure options from an API and maps the response data to a specific format before setting the options in the state.
   */
  const fetchProceduresOptions = async () => {
    const fetchData = await getAllProcedures(false, organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setOptions({
        ...options,
        procedures: the_options,
      });

      return the_options;
    }
  };

  /*
   * The function fetchCategoryOptions fetches category options from an API and maps the response data to a specific format before setting the options state.
   */
  const fetchCategoryOptions = async () => {
    const fetchData = await getAllCategories(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });

      setOptions({
        ...options,
        categories: the_options,
      });

      return the_options;
    }
  };

  /*
   * The function `onValuesChange` handles changes in form input values based on the type of field being updated.
   */
  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const dropdown_fields = [
      "vendor",
      "customer",
      "parentAsset",
      "procedure",
      "category",
      "location",
      "subLocations",
      "teams",
      "people",
      "area",
    ];
    const boolean_fields = ["isSubLocation", "isArea", "isChild"];
    if (!dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.checked,
      });
    }

    if (dropdown_fields.includes(name)) {
      if (name == "location") {
        setData({
          ...data,
          [name]: value.value,
          area: "",
          people: [],
          teams: [],
          customer: "",
        });
        setOptions({
          ...options,
          people: [],
          teams: [],
          area: [],
          customers: [],
        });
      } else if (name == "parentAsset") {
        let locationData = options?.child_asset?.filter(
          (customer) => value.value == customer.value
        );
        console.log("locationData: ", locationData);
        if (locationData?.length > 0) {
          setData({
            ...data,
            [name]: value.value,
            location: locationData[0]?.locations,
          });
        } else {
          setData({
            ...data,
            [name]: "",
          });
        }
      } else {
        setData({
          ...data,
          [name]: value.value,
        });
      }
    }

    // if (name == 'serialNumber') {
    //   if (event.target.value.length > 0 && event.target.value.length !== 10) {
    //     setValidation({
    //       ...validation,
    //       serialNumber: {
    //         ...validation.serialNumber,
    //         error: true,
    //         skipped: true,
    //         message: t.assets.serial_valid,
    //       },
    //     });
    //   }
    // }
  };

  /*
   * The `onFileChange` function sets a random ID for a file and updates the attachments state with file information.
   */
  const onFileChange = (files, name) => {
    setInitChange(true);

    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };
  /*
   * The `cancelHandler` function resets the form, untoggles a modal, and sets certain state variables to false.
   */

  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);
    setIsCancleModal(true);
    setInitChange(false);
  };
  const reviewHirachy = () => {
    history.push(
      `/assets?id=${targetedData?.attributes?.countId}&isChild=true`
    );
    cancelHandler();
  };

  const downloadThisQR = () => {
    const svg = document.getElementById("QRCodeDownloadable");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  /*
   * The `validationHandler` function updates validation states for name, category, and serial number fields based on certain conditions and sets the overall form validity accordingly.
   */
  const validationHandler = async () => {
    await setValidation(
      {
        ...validation,
        name: {
          ...validation.name,
          error: validation.name.pattern(data.name) ? false : true,
          skipped: true,
        },
        parent_asset: {
          ...validation.parent_asset,
          error: validation.parent_asset.pattern(
            data?.isChild && !data?.parentAsset
          )
            ? true
            : false,
          skipped: true,
        },
        location: {
          ...validation.location,
          error: validation.location.pattern(data.location) ? false : true,
          skipped: true,
        },

        category: {
          ...validation.category,
          error: validation.category.pattern(data.category) ? false : true,
          skipped: true,
        },
        serialNumber: {
          ...validation.serialNumber,
          error: validation.serialNumber.pattern(data.serialNumber)
            ? false
            : true,
          skipped: true,
        },
      },
      (currentValidation) => {
        const validationToArray = Object.entries(currentValidation);
        const cond = validationToArray.some(
          (el) => el[1].error && el[1].skipped
        );

        setValidForm(!cond);
      }
    );
  };

  /*
   * The `updateAssetHandler` function performs validation on data, sets button status, uploads files if changed, and updates asset information if there are no errors.
   */
  const updateAssetHandler = async () => {
    // validationHandler();

    await validationHandler();

    const validationToArray = Object.entries(validation);
    const errorsInData = validationToArray.some(
      (el) => el[1].error && el[1].skipped
    );
    if (!errorsInData) {
      setButtonStatus(1);
      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;
      const warrantyFileResponse =
        attachments.warrantyFile && attachments?.warrantyFile?.length
          ? await uploadFilessHandler(attachments.warrantyFile)
          : null;


          let theAttachments =
        warrantyFileResponse?.status == 200
          ? warrantyFileResponse.data.map((el) => el.id)
          : [];
      let unchangedAttachments = attachments.warrantyFile.filter(
        (el) => !el.isChanged
      );
      unchangedAttachments =
        unchangedAttachments.length > 0
          ? unchangedAttachments.map((el) => el.id)
          : [];
      // console.log(unchangedAttachments)
      const toBeSendedCertificateFiles = theAttachments.concat(
        unchangedAttachments
      );

      updateAsset2Handler(
        assetImageReponse?.status == 200
          ? assetImageReponse.data[0].id
          : attachments?.image?.id
          ? attachments?.image.id
          : null,
          toBeSendedCertificateFiles,
      );
    } else {
      scrollToError();
    }
  };

  const updateAsset2Handler = async (image, warranty) => {
    const response = await updateAsset(
      Number(targetedData.id),
      String(data.name),
      String(data.description),
      String(data.model),
      data.category ? data.category : null,
      data?.area ? data.area : null,
      String(data.serialNumber),
      data.barcode,
      Number(data.vendor),
      data?.customer ? Number(data.customer) : null,
      data.placedDate ? format(new Date(data.placedDate), "yyyy-MM-dd") : null,
      data.warrantyExpirationDate
        ? format(new Date(data.warrantyExpirationDate), "yyyy-MM-dd")
        : null,
      String(data.additionalInformation),
      image,
      warranty,
      data.qrcode ? true : false,
      data.procedure ? data.procedure : null,
      data.location || null,
      data?.subLocations ? null : null,
      data.purchasePrice ? Number(data.purchasePrice) : 0,
      data.manufacturer ? data.manufacturer : null,
      data.descriptionRate ? `${String(data.descriptionRate)}%` : null,
      data.estimatedUsefulLife ? Number(data.estimatedUsefulLife) : 0,
      data.people,
      data.teams,
      organisationId,
      data?.isChild,
      data?.isChild ? Number(data?.parentAsset) : null
    );

    if (response.status == 200) {
      untoggle();
      resetForm();
      setResponseData({
        id: response.data.id,
        name: response.data.name,
        qrcode: response.data.qrcode,
      });

      setFilterOptions({
        status: [],

        locations: [],
        createdByUser: [],
        customers: [],
        vendors: [],
        categories: [],
        serialNumber: "",
      });

      setSearchTitle("");
      setSelectedDates([]);

      toast.success(t.assets.asset + " " + t.common.updated_success);
    } else if (response.status == 400) {
      if (
        response.error &&
        response.error.details &&
        response.error.details.errors?.length
      ) {
        response.error.details.errors.map((one) => {
          if (one?.path[0] && one?.path[0] === "serialNumber") {
            toast.error(t.assets.serial_number_exist);
          } else {
            toast.error(`${one?.path[0]}- ${one.message}`);
          }
        });
      } else if (response.error && response.error.message) {
        console.log(
          "response.error && response.error.message: ",
          response.error
        );

        if (response.error.message == "Unable to Change Asset Hierarchy") {
          setIsCancleModal(false);
          setNewCancelModal(true);
        } else {
          toast.error(response.error.message);
        }
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
      }
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  /// Executed after category is created to select the newly crested category
  const categoryCreateSuccess = (res) => {
    fetchCategoryOptions();
    if (res?.data?.data?.attributes?.name) {
      setData({
        ...data,
        category: res?.data?.data?.id,
      });
    }
  };

  /*
   * The `addNewArea` function adds a new empty object to the `area` array in the `data` object.
   */
  const addNewArea = () => {
    let areaData = [...data.area];
    areaData.push({ area: "" });
    setData({
      ...data,
      area: areaData,
    });
  };

  /*
   * The `handleAreaChange` function updates the `area` property of an item in the `data` object at a specific index with a new value.
   */
  const handleAreaChange = (value, index) => {
    // Create a shallow copy of the area data array
    let areaData = [...data.area];

    // Create a shallow copy of the specific object you want to update
    let updatedAreaObject = { ...areaData[index] };

    // Update the 'area' property of the copied object
    updatedAreaObject.area = value;

    // Replace the specific object in the copied array with the updated object
    areaData[index] = updatedAreaObject;

    // Update the state with the new area data array
    setData({
      ...data,
      area: areaData,
    });
  };

  /*
   * The `removeArea` function removes an element from the `area` array in the `data` object at the specified index.
   */
  const removeArea = (index) => {
    let areaData = [...data.area];
    areaData.splice(index, 1);
    setData({
      ...data,
      area: areaData,
    });
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? "aside-modal active procedure" : "aside-modal procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.assets.edit_asset} #{targetedData?.attributes?.countId}
              </div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() => {
                    if (initChange) {
                      setNewCancelModal(true); // This will be executed if initChange is true
                      setIsCancleModal(true);
                    } else {
                      cancelHandler(); // This will be executed if initChange is false
                    }
                  }}
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    arrowAlign="center"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "90vh",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
            className="body"
          >
            <Form.Field>
              <label className="label-item">
                {t.assets.form.name}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.assets.form.enter_asset_name}
                value={data.name}
                onChange={onValuesChange("name")}
                error={validation.name.error && validation.name.skipped}
                onBlur={(e) => {
                  if (validation.name.pattern(data.name)) {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      name: {
                        ...validation.name,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.name.error && validation.name.skipped ? (
                <div className="label-error">{validation.name.message}</div>
              ) : (
                ""
              )}
            </Form.Field>
            <Form.Field>
              <FileUpload
                onFileChange={onFileChange}
                name="image"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>

            <Form className="mb-5">
              <Form.Field>
                <label className="label-item">
                  {t.assets.form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange("description")}
                  rows={2}
                  fluid
                  placeholder={t.assets.form.describe_asset}
                />
              </Form.Field>
            </Form>

            <div className="mt-3">
              <Checkbox
                // disabled={data?.isChild}
                checked={data?.isChild}
                label={t.assets.child_asset}
                onChange={onValuesChange("isChild")}
              />{" "}
            </div>
            {data?.isChild ? (
              <Form.Field>
                <label className="label-item mt-3">
                  {t.assets.parent_asset} <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.assets.parent_asset_place}
                  fluid
                  clearable
                  selection
                  noResultsMessage={t.common.no_results_found}
                  search
                  onOpen={fetchAllAssets}
                  options={
                    targetedData?.id
                      ? options.child_asset.filter(
                          (e) => e.value !== targetedData?.id
                        )
                      : options.child_asset
                  }
                  value={data.parentAsset}
                  onChange={onValuesChange("parentAsset")}
                  error={
                    validation.parent_asset.error &&
                    validation.parent_asset.skipped
                  }
                  onBlur={(e) => {
                    if (
                      validation.parent_asset.pattern(
                        data?.isChild && !data?.parentAsset
                      )
                    ) {
                      setValidation({
                        ...validation,
                        parent_asset: {
                          ...validation.parent_asset,
                          error: true,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        parent_asset: {
                          ...validation.parent_asset,
                          error: false,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.parent_asset.error &&
                validation.parent_asset.skipped ? (
                  <div className="label-error">
                    {validation.parent_asset.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>
            ) : null}

            <Form.Field autoComplete="off">
              <label
                className={data?.isChild ? "label-item" : "label-item mt-2"}
              >
                {t.assets.form.serial_number}
                <Asterisk color="#172B4D" />
              </label>

              <div className="flexbox">
                <Input
                  style={{ flex: 1 }}
                  autoComplete="new-password"
                  fluid
                  maxLength={13}
                  type="text"
                  placeholder={t.assets.form.enter_serial_number}
                  value={data.serialNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits and limit to 13 characters
                    if (/^[a-zA-Z0-9.]{0,13}$/.test(value)) {
                      onValuesChange("serialNumber")(e);
                    }
                  }}
                  error={
                    validation.serialNumber.error &&
                    validation.serialNumber.skipped
                  }
                  onBlur={(e) => {
                    if (validation.serialNumber.pattern(data.serialNumber)) {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                <span
                  className="ml-2 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                  style={{ backgroundColor: "#E9F2FF" }}
                  onClick={() => {
                    let generatedSerialNumber = generateUniqueInteger();
                    setData({
                      ...data,
                      serialNumber: generatedSerialNumber,
                    });

                    if (
                      validation.serialNumber.pattern(generatedSerialNumber)
                    ) {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        serialNumber: {
                          ...validation.serialNumber,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                >
                  <Repeat />
                </span>
              </div>

              {validation.serialNumber.error &&
              validation.serialNumber.skipped ? (
                <div className="label-error">
                  {validation.serialNumber.message}
                </div>
              ) : (
                ""
              )}
            </Form.Field>

            <label className="label-item mb-2">
              {t.parts.form.add_QR}
              <Asterisk color="#172B4D" />
            </label>

            <div className="flexbox barcode-btns">
              <button
                active={data.barcode}
                onClick={() =>
                  setData({
                    ...data,
                    barcode: true,
                    qrcode: false,
                  })
                }
                className={`qr-button ${data.barcode ? "active" : ""}`}
              >
                <span>
                  <BarcodeIcon />
                </span>{" "}
                {t.assets.filter.barcode}
              </button>

              <button
                className={`qr-button ${data.qrcode ? "active" : ""}`}
                active={data.barcode}
                onClick={() =>
                  setData({
                    ...data,
                    qrcode: true,
                    barcode: false,
                  })
                }
              >
                <span className="mr-1 ml-1">
                  <QRcodeIconInactive />
                </span>
                {t.assets.filter.qrcode}
              </button>
            </div>

            <Form.Field>
              <label className="label-item mt-6">{t.assets.form.model}</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.assets.form.enter_asset_model}
                value={data.model}
                onChange={onValuesChange("model")}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.assets.form.category}</label>
              <div className="flexbox">
                <Dropdown
                  style={{ flex: 1 }}
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  fluid
                  clearable
                  options={options.categories}
                  placeholder={t.assets.form.select_category}
                  value={data.category}
                  onChange={onValuesChange("category")}
                  onOpen={fetchCategoryOptions}
                  error={
                    validation.category.error && validation.category.skipped
                  }
                  onBlur={(e) => {
                    if (validation.category.pattern(data.category)) {
                      setValidation({
                        ...validation,
                        category: {
                          ...validation.category,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        category: {
                          ...validation.category,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {userPermissions?.category?.create &&
                userPermissions?.category?.view ? (
                  <span
                    className="ml-2 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                    onClick={() => {
                      setOpenCategoryAddModal(true);
                    }}
                    style={{ backgroundColor: "#E9F2FF" }}
                  >
                    <AddIcon />
                  </span>
                ) : null}
              </div>
              {validation.category.error && validation.category.skipped ? (
                <div className="label-error">{validation.category.message}</div>
              ) : (
                ""
              )}
            </Form.Field>
            <Form.Field>
              <label className="label-item">{t.assets.form.procedure}</label>
              <Dropdown
                placeholder={t.assets.form.select_procedure}
                noResultsMessage={t.common.no_results_found}
                fluid
                selection
                search
                multiple
                options={options.procedures}
                value={data.procedure}
                onChange={onValuesChange("procedure")}
                onOpen={fetchProceduresOptions}
              />
            </Form.Field>
            {/* {console.log(data)} */}
            <Form.Field>
              {/* <Form.Field> */}
              <label className="label-item">
                {t.assets.form.location} <Asterisk color="#172B4D" />
              </label>
              <Dropdown
                placeholder={t.assets.form.select_location}
                noResultsMessage={t.common.no_results_found}
                fluid
                className="loc-dropdown"
                selection
                search
                clearable
                error={validation.location.error && validation.location.skipped}
                options={options.locations}
                value={data.location}
                onChange={onValuesChange("location")}
                onOpen={fetchLocations}
                onBlur={(e) => {
                  if (validation.location.pattern(data.location)) {
                    setValidation({
                      ...validation,
                      location: {
                        ...validation.location,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      location: {
                        ...validation.location,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
                 {validation.location.error && validation.location.skipped ? (
                <div className="label-error">{validation.location.message}</div>
              ) : (
                ""
              )}
            </Form.Field>

            {/* <Form.Field>
              
                  <>
                    <label className="label-item">
                      {t.assets.form.sub_locations}
                    </label>
                    <Dropdown
                      placeholder={t.assets.form.select_sub_locations}
                      noResultsMessage={t.common.no_results_found}
                      fluid
                      selection
                      search
                      options={options.subLocations}
                      value={data.subLocations}
                      onChange={onValuesChange('subLocations')}
                      onOpen={fetchSubLocations}
                    />
                  </>
                  </Form.Field> */}
            {/* ) : null} */}
            <Form.Field>
              <>
                <label className="label-item">{t.assets.form.area}</label>
                <Dropdown
                  placeholder={t.assets.form.add_area}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  clearable
                  selection
                  disabled={!data?.location}
                  search
                  options={options.area}
                  value={data.area}
                  onChange={onValuesChange("area")}
                  onOpen={fetchArea}
                />
              </>
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.assets.form.customer}</label>
              <Dropdown
                placeholder={t.assets.form.select_customer}
                noResultsMessage={t.common.no_results_found}
                fluid
                clearable
                search
                selection
                // disabled={
                //   !data.location &&
                //   !(role === "super_admin" || role === "admin")
                // }
                options={options.customers}
                onOpen={() => fetchCustomers()}
                value={data.customer}
                onChange={onValuesChange("customer")}
                // disabled={fetchingCustomers}
                // loading={fetchingCustomers}
              />
            </Form.Field>
            <Form.Field>
              <label className="label-item">{t.assets.form.vendor}</label>
              <Dropdown
                placeholder={t.assets.form.select_vendor}
                noResultsMessage={t.common.no_results_found}
                fluid
                search
                clearable
                selection
                options={options.vendors}
                value={data.vendor}
                onChange={onValuesChange("vendor")}
                disabled={fetchingVendors}
                // loading={fetchingVendors}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workOrders.form.teams}</label>
              <Dropdown
                placeholder={t.workOrders.form.teams_placeholder}
                noResultsMessage={t.common.no_results_found}
                fluid
                selection
                search
                multiple
                options={options.teams}
                // disabled={
                //   !data.location &&
                //   !(role === "super_admin" || role === "admin")
                // }
                value={data.teams}
                onChange={onValuesChange("teams")}
                onOpen={fetchTeamsOptions}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.people.title}</label>
              <Dropdown
                placeholder={t.workOrders.form.people_placeholder}
                noResultsMessage={t.common.no_results_found}
                fluid
                selection
                search
                multiple
                options={options.people}
                // disabled={
                //   !data.location &&
                //   !(role === "super_admin" || role === "admin")
                // }
                value={data.people}
                onChange={onValuesChange("people")}
                onOpen={fetchPeopleOptions}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.assets.form.purchase_price}
              </label>

              <Input
                autoComplete="new-password"
                type="number"
                fluid
                onKeyDown={(evt) => handleKeyDown(evt)}
                min="0"
                placeholder={t.assets.form.add_purchase_price}
                value={data.purchasePrice}
                onChange={onValuesChange("purchasePrice")}
              >
                <input />
                <span
                  className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                    selectedLanguage == "en" ? "ml-5" : "mr-5"
                  }`}
                  // onClick={() => {
                  //   setOpenJobTitleAddModal(true);
                  // }}
                  style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                >
                  {organisationCurrency}
                </span>
              </Input>
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.assets.form.manufacturer}</label>

              <Input
                autoComplete="new-password"
                type="text"
                fluid
                placeholder={t.assets.form.add_manufacturer}
                value={data.manufacturer}
                onChange={onValuesChange("manufacturer")}
              >
                <input />
              </Input>
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.assets.form.depreciation_rate}
              </label>
              <div className="estimation-time-div">
                <Input
                  autoComplete="new-password"
                  type="number"
                  fluid
                  onKeyDown={(evt) => handleKeyDown(evt)}
                  min="0"
                  placeholder={t.assets.form.add_depreciation_rate}
                  value={data.descriptionRate}
                  onChange={onValuesChange("descriptionRate")}
                >
                  <input />
                  <span
                    style={
                      selectedLanguage == "en"
                        ? { right: "0px" }
                        : { left: "0px" }
                    }
                    className="mr-3"
                  >
                    <PercentageIcon />
                  </span>
                </Input>
              </div>
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.assets.form.estimated_life}
              </label>

              <Input
                type="number"
                fluid
                onKeyDown={(evt) => handleKeyDown(evt)}
                min="0"
                placeholder={t.assets.form.add_estimated_life}
                value={data.estimatedUsefulLife}
                onChange={onValuesChange("estimatedUsefulLife")}
              >
                <input />
                <span
                  className={`mr-2 d-flex align-items-center justify-content-center ${
                    selectedLanguage == "en" ? "ml-5" : "mr-5"
                  }`}
                  style={{
                    padding: "8px",
                    backgroundColor: "#E9F2FF",
                    color: "#0C66E4",
                  }}
                >
                  {"Yr"}
                </span>
              </Input>
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {" "}
                {t.assets.form.placed_in_service_date}
              </label>
              <div className="change-date-time-inll position-relative">
                <DatePicker
                  style={{ width: "100%" }}
                  value={data.placedDate ? new Date(data.placedDate) : null}
                  onChange={(value) => {
                    if (value && value.valueOf()) {
                      setData({
                        ...data,
                        placedDate: format(value.valueOf(), "yyyy-MM-dd"),
                      });
                    } else {
                      setData({
                        ...data,
                        placedDate: null,
                      });
                    }
                  }}
                  showMonthDropdown
                  showYearDropdown
                  placeholder={t.assets.form.add_placed_in_service_date}
                  className={"active"}
                  weekDays={[
                    t.workOrders.details_view.sun,
                    t.workOrders.details_view.mon,
                    t.workOrders.details_view.tue,
                    t.workOrders.details_view.wed,
                    t.workOrders.details_view.thu,
                    t.workOrders.details_view.fri,
                    t.workOrders.details_view.sat,
                  ]}
                  months={[
                    t.common.january,
                    t.common.february,
                    t.common.march,
                    t.common.april,
                    t.common.may,
                    t.common.june,
                    t.common.july,
                    t.common.august,
                    t.common.september,
                    t.common.october,
                    t.common.november,
                    t.common.december,
                  ]}
                  format="YYYY-MM-DD"
                ></DatePicker>

                <img
                  className="position-absolute"
                  style={
                    selectedLanguage == "en"
                      ? { top: "8px", right: "7px" }
                      : { top: "8px", left: "7px" }
                  }
                  src={calendar_icon}
                />
              </div>
            </Form.Field>
            <Form.Field>
              <label className="label-item">
                {" "}
                {t.assets.form.warranty_expiration_date}
              </label>
              <div className="change-date-time-inll position-relative">
                <DatePicker
                  style={{ width: "100%" }}
                  value={
                    data.warrantyExpirationDate
                      ? new Date(data.warrantyExpirationDate)
                      : null
                  }
                  onChange={(value) => {
                    if (value && value.valueOf()) {
                      setData({
                        ...data,
                        warrantyExpirationDate: format(
                          value.valueOf(),
                          "yyyy-MM-dd"
                        ),
                      });
                    } else {
                      setData({
                        ...data,
                        warrantyExpirationDate: null,
                      });
                    }
                  }}
                  showMonthDropdown
                  showYearDropdown
                  placeholder={t.assets.form.add_warranty_expiration_date}
                  className={"active"}
                  weekDays={[
                    t.workOrders.details_view.sun,
                    t.workOrders.details_view.mon,
                    t.workOrders.details_view.tue,
                    t.workOrders.details_view.wed,
                    t.workOrders.details_view.thu,
                    t.workOrders.details_view.fri,
                    t.workOrders.details_view.sat,
                  ]}
                  months={[
                    t.common.january,
                    t.common.february,
                    t.common.march,
                    t.common.april,
                    t.common.may,
                    t.common.june,
                    t.common.july,
                    t.common.august,
                    t.common.september,
                    t.common.october,
                    t.common.november,
                    t.common.december,
                  ]}
                  format="YYYY-MM-DD"
                ></DatePicker>

                <img
                  className="position-absolute"
                  style={
                    selectedLanguage == "en"
                      ? { top: "8px", right: "7px" }
                      : { top: "8px", left: "7px" }
                  }
                  src={calendar_icon}
                />
              </div>
            </Form.Field>

            <Form.Field autoComplete="off">
              <label className="label-item">
                {t.assets.form.additional_information}
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.assets.form.add_additional_information}
                value={data.additionalInformation}
                onChange={onValuesChange("additionalInformation")}
              />
            </Form.Field>

            <Form.Field>
              <FileUpload
                onFileChange={onFileChange}
                name="warrantyFile"
                onCertificateFileChange={onCertificateFileChange}
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>

            <div className="form-button-container">
              <button
                onClick={() => {
                  if (initChange) {
                    setNewCancelModal(true);
                    setIsCancleModal(true); // Additional action when initChange is true
                  } else {
                    cancelHandler();
                  }
                }}
                className="form-action-button"
              >
                {t.common.cancel}
              </button>

              <button
                onClick={updateAssetHandler}
                className="form-action-button blue"
                disabled={buttonStatus == 1}
              >
                {t.common.edit}
              </button>
            </div>
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        // onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
        onClick={() => {
          if (initChange) {
            setNewCancelModal(true);
            setIsCancleModal(true); // Additional action when initChange is true
          } else {
            cancelHandler();
          }
        }}
      ></div>

      {/* success modal */}
      <SuccessModal
        closeCallback={() => {
          setFilterOptions({
            status: [],

            locations: [],
            createdByUser: [],
            customers: [],
            vendors: [],
            categories: [],
            serialNumber: "",
          });

          setSearchTitle("");
          setSelectedDates([]);
        }}
      >
        <span>
          {t.assets.asset} <b>{responseData.name}</b> {t.common.updated_success}
        </span>
      </SuccessModal>

      {/* cancel modal */}
      <NewCancelModal
        yesCallback={() => {
          isCancleModal ? cancelHandler() : reviewHirachy();
        }}
        fromAsset={isCancleModal ? false : true}
        title={
          isCancleModal ? t.parts.form.changes : t.assets.hirachy_error_title
        }
      >
        <p style={{ lineHeight: "24px" }}>
          {" "}
          {isCancleModal
            ? t.common.cancel_2statement
            : t.assets.hirachy_error_desc}
        </p>
      </NewCancelModal>

      {/* Add category modal */}
      <AddCategoryModal
        isOpen={openCategoryAddModal}
        setModalOpenState={setOpenCategoryAddModal}
        handleSuccess={(res) => categoryCreateSuccess(res)}
      />
    </>
  );
}

export default Edit;
