import React from "react";
import { selectTranslations } from "../../../config/i18n/slice";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { Header, Label } from "semantic-ui-react";
import { LinkWhiteIcon } from "../../../../Assets/Icons/svg";
import useDisplayFormattedText from "../../../../hooks/useDisplayFormattedText";
const ChildAsset = ({ data, isChildAsset, role, allIds }) => {
  const t = useSelector(selectTranslations);
  const { DisplayFormattedText } = useDisplayFormattedText();
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const history = useHistory();

  const { checkForLinkPermission } = usePermissionsSimplified();
  return (
    <div>
      {data?.attributes?.storedAssets?.length > 0 ? (
        <>
          <div className="detail-intro">
            {data?.attributes?.storedAssets?.map((el) => {
              return (
                <>
                  {el?.childAsset && el?.childAsset?.data ? (
                    <div
                      className="log-detail-card asset-card mt-5"
                      style={{ background: "#E9F2FF80" }}
                    >
                      <div className="data-header">
                        <div className=" d-flex justify-content-between align-items-baseline">
                          <div
                            style={{ width: "auto" }}
                            className="header-pill-container"
                          >
                            <div
                              style={{ width: "auto" }}
                              className=" detail-title"
                            >
                              {checkForLinkPermission("asset") ? (
                                <div
                                  className={`mt-1 ${
                                    !(role === "super_admin" || role === "admin")? allIds?.includes(
                                      el.childAsset?.data?.attributes?.location
                                        ?.data?.id
                                    )
                                      ? "location-link dd mb-0 d-flex align-items-baseline"
                                      : ""
                                       : "location-link dd mb-0 d-flex align-items-baseline"
                                  }`}
                                  basic
                                  onClick={() => {
                                    if( !(role === "super_admin" || role === "admin")){
                                      if (
                                        allIds?.includes(
                                          el.childAsset?.data?.attributes
                                            ?.location?.data?.id
                                        )
                                      ) {
                                        history.push(
                                          `/assets?id=${el?.childAsset?.data?.attributes?.countId}`
                                        );
                                      }
                                    }else{
                                      history.push(
                                        `/assets?id=${el?.childAsset?.data?.attributes?.countId}`
                                      );
                                    }
                                   
                                  }}
                                  style={{
                                    marginLeft: "4px",
                                    fontSize: "24px",
                                  }}
                                >
                                  {" "}
                                  <p className="mb-1">
                                    {DisplayFormattedText(
                                      el?.childAsset?.data?.attributes?.name,
                                      25
                                    )}
                                  </p>
                                  {/* <p
                  style={{ color: '#0C66E4',background:"rgba(12, 102, 228, 1)",borderRadius:"10px",padding:"3px",minWidth:"47px",display:"flex"}}
                  className="ml-2 mr-2 link-span justify-content-center align-items-center"
                >
                  <LinkWhiteIcon />
               
                  {el?.grandChildAsset?.data?.length > 1 ?  <p style={{color:"white",fontSize:"13px"}} className="ml-1"> {el?.grandChildAsset?.data?.length} </p> : null}
                   
                
                </p> */}
                                  {/* <span>{el?.grandChildAsset?.data?.length}</span> */}
                                </div>
                              ) : (
                                <div
                                  className=" mt-1"
                                  basic
                                  style={{ marginLeft: "4px" }}
                                >
                                  {" "}
                                  {DisplayFormattedText(
                                    el?.childAsset?.data?.attributes?.name,
                                    25
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          {el?.grandChildAsset?.data?.length > 0 && (
                            <div
                              style={{
                                color: "#0C66E4",
                                background: "rgba(12, 102, 228, 1)",
                                borderRadius: "10px",
                                padding: "3px",
                                minWidth: "47px",
                                display: "flex",
                              }}
                              className={`link-span justify-content-center align-items-center ${
                                selectedLanguage == "en" ? "ml-2 mr-2" : " mr-2"
                              }`}
                            >
                              <LinkWhiteIcon />
                              {el?.grandChildAsset?.data?.length > 1 && (
                                <p
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                  className="ml-1"
                                >
                                  {el?.grandChildAsset?.data?.length}
                                </p>
                              )}
                            </div>
                          )}
                          {/* </div> */}

                          {/* <label className={`${selectedLanguage == 'en' ? '' : 'ml-2'}`}>{t.assets.table.status}</label>
                      {':'} */}
                          {/* <span>{el?.childAsset?.data?.attributes?.countId}</span> */}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="detail-updated-title">
                            {t.assets.table.updatedAt}
                            {":"}{" "}
                            <span>
                              {
                                <Moment
                                  format="DD-MM-YYYY LT"
                                  date={
                                    el?.childAsset?.data?.attributes?.updatedAt
                                  }
                                />
                              }
                            </span>
                          </div>
                          <div
                            style={{ marginTop: "-6px" }}
                            className={`pill ${
                              el?.childAsset?.data?.attributes?.status ===
                                "active" ||
                              el?.childAsset?.data?.attributes?.status ===
                                "true"
                                ? "active-green"
                                : el?.childAsset?.data?.attributes?.status ===
                                    "inactive" ||
                                  el?.childAsset?.data?.attributes?.status ===
                                    "false"
                                ? "inactive-grey"
                                : "high-low"
                            }`}
                          >
                            {el?.childAsset?.data?.attributes?.status ===
                              "active" ||
                            el?.childAsset?.data?.attributes?.status === "true"
                              ? t.assets.active
                              : el?.childAsset?.data?.attributes?.status ===
                                  "inactive" ||
                                el?.childAsset?.data?.attributes?.status ===
                                  "false"
                              ? t.assets.inActive
                              : t.assets.disposed_asset}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center mt-3">
                        <label className="detail-intro-label">
                          {t.assets.table.serialNumber}
                          {":"}
                        </label>
                        <p className="detail-intro-value">
                          {el?.childAsset?.data?.attributes?.serialNumber}
                        </p>
                      </div>
                      <div className="d-flex align-items-start mt-3">
                        <label
                          style={
                            selectedLanguage == "en"
                              ? { minWidth: "84px" }
                              : { minWidth: "96px" }
                          }
                          className="detail-intro-label"
                        >
                          {t.assets.form.location}
                          {":"}
                        </label>
                        <p className="detail-intro-value">
                          {el?.childAsset?.data?.attributes?.location?.data ? (
                            checkForLinkPermission("location") ? (
                              <span
                                className={`${
                                  !(role === "super_admin" || role === "admin")? allIds?.includes(
                                    el.childAsset?.data?.attributes?.location
                                      ?.data?.id
                                  )
                                    ? "detail-intro-link"
                                    : ""
                                     : "detail-intro-link"
                                } ml-4 mr-4`}
                                basic
                                onClick={() =>{
                                  if( !(role === "super_admin" || role === "admin")){
                                    if (
                                      allIds?.includes(
                                        el.childAsset?.data?.attributes
                                          ?.location?.data?.id
                                      )
                                    ) {
                                      history.push(
                                        `/location?id=${el.childAsset?.data?.attributes?.location?.data?.attributes?.countId}`
                                      )
                                    }
                                  }else{
                                    history.push(
                                      `/location?id=${el.childAsset?.data?.attributes?.location?.data?.attributes?.countId}`
                                    )
                                  }
                                }
                                 
                                }
                              >
                                {
                                  el.childAsset?.data?.attributes?.location
                                    ?.data?.attributes?.name
                                }
                              </span>
                            ) : (
                              <span className=" ml-4 mr-4" basic>
                                {
                                  el.childAsset?.data?.attributes?.location
                                    ?.data?.attributes?.name
                                }
                              </span>
                            )
                          ) : (
                            <span className=" ml-4 mr-4">{t.common.na}</span>
                          )}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {el?.grandChildAsset?.data?.length > 0
                    ? el?.grandChildAsset?.data?.map((el) => (
                        <div
                          style={{ background: "#E9F2FF" }}
                          className={`log-detail-card asset-card mt-5 ${
                            isChildAsset
                              ? ""
                              : selectedLanguage == "en"
                              ? "ml-7"
                              : "mr-7"
                          }`}
                        >
                          <div className="data-header">
                            <div className=" d-flex justify-content-between align-items-baseline">
                              <div
                                style={{ width: "auto" }}
                                className="header-pill-container"
                              >
                                <p
                                  style={{ width: "auto", fontSize: "24px" }}
                                  className="detail-title"
                                >
                                  {checkForLinkPermission("asset") ? (
                                    <div
                                      className={` mt-1 ${
                                        !(role === "super_admin" || role === "admin")? allIds?.includes(
                                          el.attributes?.location?.data?.id
                                        )
                                          ? "location-link"
                                          : ""
                                          :"location-link"
                                      }`}
                                      basic
                                      onClick={() => {

                                        if( !(role === "super_admin" || role === "admin")){
                                          if (
                                            allIds?.includes(
                                              el.childAsset?.data?.attributes
                                                ?.location?.data?.id
                                            )
                                          ) {
                                            history.push(
                                              `/assets?id=${el?.attributes?.countId}`
                                            );
                                          }
                                        }else{
                                          history.push(
                                            `/assets?id=${el?.attributes?.countId}`
                                          );
                                        }
                                       
                                      }}
                                      style={{ marginLeft: "4px" }}
                                    >
                                      {" "}
                                      {DisplayFormattedText(
                                        el?.attributes?.name,
                                        25
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      className=" mt-1"
                                      basic
                                      style={{ marginLeft: "4px" }}
                                    >
                                      {" "}
                                      {DisplayFormattedText(
                                        el?.attributes?.name,
                                        25
                                      )}
                                    </div>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="detail-updated-title">
                                {t.assets.table.updatedAt}
                                {":"}{" "}
                                <span>
                                  {
                                    <Moment
                                      format="DD-MM-YYYY LT"
                                      date={el?.attributes?.updatedAt}
                                    />
                                  }
                                </span>
                              </div>
                              <div
                                style={{ marginTop: "-8px" }}
                                className={`pill ${
                                  el?.attributes?.status === "active" ||
                                  el?.attributes?.status === "true"
                                    ? "active-green"
                                    : el?.attributes?.status === "inactive" ||
                                      el?.attributes?.status === "false"
                                    ? "inactive-grey"
                                    : "high-low"
                                }`}
                              >
                                {el?.attributes?.status === "active" ||
                                el?.attributes?.status === "true"
                                  ? t.assets.active
                                  : el?.attributes?.status === "inactive" ||
                                    el?.attributes?.status === "false"
                                  ? t.assets.inActive
                                  : t.assets.disposed_asset}
                              </div>
                            </div>
                            {/* <div>
                              <p className="detail-updated-title">
                                {t.assets.table.updatedAt}
                                {':'}{' '}
                                <span>
                                  {
                                    <Moment
                                      format="DD-MM-YYYY LT"
                                      date={el?.attributes?.updatedAt}
                                    />
                                  }
                                </span>
                              </p>
                            </div> */}
                          </div>

                          <div className="d-flex align-items-center mt-3">
                            <label className="detail-intro-label">
                              {t.assets.table.serialNumber}
                              {":"}
                            </label>
                            <p className="detail-intro-value">
                              {el?.attributes?.serialNumber}
                            </p>
                          </div>
                          <div className="d-flex align-items-start mt-3">
                            <label
                              style={
                                selectedLanguage == "en"
                                  ? { minWidth: "84px" }
                                  : { minWidth: "96px" }
                              }
                              className="detail-intro-label"
                            >
                              {t.assets.form.location}
                              {":"}
                            </label>
                            <p className="detail-intro-value">
                              {el?.attributes?.location?.data ? (
                                checkForLinkPermission("location") ? (
                                  <span
                                  className={`${
                                    !(role === "super_admin" || role === "admin")? allIds?.includes(
                                      el?.attributes?.location?.data?.id
                                    )
                                      ? "detail-intro-link"
                                      : ""
                                       : "detail-intro-link"
                                  } ml-4 mr-4`}
                                    basic
                                    onClick={() =>{
                                      if( !(role === "super_admin" || role === "admin")){
                                        if (
                                          allIds?.includes(
                                            el?.attributes?.location?.data?.id
                                          )
                                        ) {
                                          history.push(
                                            `/location?id=${el.attributes?.location?.data?.attributes?.countId}`
                                          )
                                        }
                                      }else{
                                        history.push(
                                          `/location?id=${el.attributes?.location?.data?.attributes?.countId}`
                                        )
                                      }
                                    }
                                     
                                    }
                                  >
                                    {
                                      el.attributes?.location?.data?.attributes
                                        ?.name
                                    }
                                  </span>
                                ) : (
                                  <span className=" ml-4 mr-4" basic>
                                    {
                                      el.attributes?.location?.data?.attributes
                                        ?.name
                                    }
                                  </span>
                                )
                              ) : (
                                <span className=" ml-4 mr-4">
                                  {" "}
                                  {t.common.na}{" "}
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      ))
                    : null}

                  {isChildAsset && el?.grandChildAsset?.data?.length == 0 ? (
                    <div style={{ textAlign: "center" }}>
                      <Header as="h3">{t.common.na}</Header>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Header as="h3">{t.common.na}</Header>
        </div>
      )}
    </div>
  );
};

export default ChildAsset;
