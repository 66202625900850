import { useEffect, useState } from 'react';
import { TIMEZONES } from '../app/config/constants';
export const useTimeZone = () => {
  const [country, setCountryForTimeZone] = useState(null);
  const [countryTimeZone, setCountryTimeZone] = useState(null);

  const getCountryTimeZone = (country) => {
    let UTC_PLUS_TWO_COUNTRY = ['TR', 'SY', 'LB', 'PS', 'EG'];
    let UTC_PLUS_THREE_COUNTRY = ['SA', 'YE', 'IQ', 'JO', 'KW', 'QA', 'BH'];
    let UTC_PLUS_FOUR_COUNTRY = ['AE', 'OM'];
    if (UTC_PLUS_TWO_COUNTRY.includes(country)) {
      return 'UTC+2';
    } else if (UTC_PLUS_THREE_COUNTRY.includes(country)) {
      return 'UTC+3';
    } else if (UTC_PLUS_FOUR_COUNTRY.includes(country)) {
      return 'UTC+4';
    } else {
      return null;
    }
  };
  //   useEffect(() => {}, [country]);
  return { getCountryTimeZone };
};
