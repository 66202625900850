import React, { createRef, useEffect, useState } from "react";
import {
  Card,
  Loader,
  Segment,
  Header,
  Dimmer,
  Modal,
  Dropdown,
  Image,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  List,
  Grid,
  Popup,
} from "semantic-ui-react";
import { Country } from "country-state-city";
import Moment from "react-moment";
import ImageCrop from "./ImageCrop";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import {
  getOrganisationData,
  getUserData,
  updateCompanyProfile,
  IMAGES_URL,
  uploadFile,
  imageUrlToFile,
  updateUserProfileImage,
} from "../../config/functions";
import * as auth from "../../modules/Auth/_redux/authRedux";
import ReactFlagsSelect from "react-flags-select";
import { useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  TIMEZONES,
  currencyData,
  currencyWithCountry,
} from "../../config/constants";
import * as Yup from "yup";
import { useTimeZone } from "../../../hooks/useTimeZone";
import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../_metronic/_helpers";
import { selectTranslations } from "../../config/i18n/slice";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import { useDispatch } from "react-redux";

const MyProfile = (props) => {
  const t = useSelector(selectTranslations);
  const { userPermissions } = usePermissionsSimplified();

  const { getCountryTimeZone } = useTimeZone();
  const location = useLocation();

  const history = useHistory();
  const contractCopyRef = createRef();
  const userProfileRef = createRef();
  const [imageSrc, setImageSrc] = useState(""); // initially uploaded image
  const [file, setFile] = useState(""); //
  const [userProfileSrc, setUserProfileSrc] = useState(""); // initially uploaded image
  const [userFile, setUserFile] = useState(""); //
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };
  const [attachments, setAttachments] = useState({
    profileImage: null,
    userProfileImage: null,
  });
  const dispatch = useDispatch();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const jwtToken = useSelector((state) => {
    if (state?.auth?.authToken) {
      return state.auth.authToken;
    } else {
      return null;
    }
  });
  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });
  const [organisationData, setOrganisationData] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [userImageButtonStatus, setUserImageButtonStatus] = useState(0);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    (async function() {
      let attachmentData = {};
      const [res, userData] = await Promise.all([
        getOrganisationData(organisationId),
        getUserData(user.id),
      ]);
      if (res) {
        dispatch(props.SetOrganisation(res?.attributes));
        setOrganisationData(res);
        attachmentData.profileImage = res?.attributes?.profileImage?.data
          ? {
              id: res?.attributes?.profileImage?.data?.id,
              file: imageUrlToFile(
                res?.attributes?.profileImage?.data?.attributes.url,
                res?.attributes?.profileImage?.data?.attributes.name
              ),
              name: res?.attributes?.profileImage?.data?.attributes.name,
              type: res?.attributes?.profileImage?.data?.attributes.mime,
              cancelPopup: false,
              isChanged: false,
            }
          : null;
      }
      if (userData) {
        setCurrentUserData(userData);
        attachmentData.userProfileImage = userData?.profileImage
          ? {
              id: userData?.profileImage?.id,
              file: imageUrlToFile(
                userData?.profileImage?.url,
                userData?.profileImage?.name
              ),
              name: userData?.profileImage?.name,
              type: userData?.profileImage?.mime,
              cancelPopup: false,
              isChanged: false,
            }
          : null;
      }
      setAttachments(attachmentData);
    })();
  }, [reload]);
  useEffect(() => {
    const countries = Country.getAllCountries();
    if (countries) {
      const _g_options = countries.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setCountryData(_g_options);
    }
  }, []);

  const onFileChange = (e, name) => {
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: e.target.files[0],
        id: randomnumber,
        name: e.target.files[0].name,
        type: e.target.files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  const handleSubmit = async (values) => {
    setButtonStatus(1);
    const profileImageResponse =
      attachments.profileImage && attachments.profileImage.isChanged
        ? await uploadFile([attachments.profileImage.file])
        : null;
    let res = await updateCompanyProfile(
      organisationId,
      values.currency,
      values.country,
      values.timezone,
      profileImageResponse?.status == 200
        ? profileImageResponse.data[0].id
        : attachments.profileImage && attachments.profileImage.id
        ? attachments.profileImage.id
        : null
    );
    if (res && res.status == 200) {
      toast.success(t.common.company_profile_update_success);
      await props.requestUser();
    } else {
      toast.error("Company profile not updated.");
    }
    setButtonStatus(0);
    setReload(!reload);
  };

  const handleUserProfileImageChange = async (imageData, update = true) => {
    setUserImageButtonStatus(1);
    let image = null;
    if (update) {
      const profileImageResponse =
        imageData && imageData.isChanged
          ? await uploadFile([imageData.file])
          : null;
      if (profileImageResponse && profileImageResponse.status == 200) {
        image = profileImageResponse.data[0].id;
      } else if (imageData.id) {
        image = imageData.id;
      } else {
        image = null;
      }
    }
    let res = await updateUserProfileImage(user.id, image);
    if (res && res.status == 200) {
      toast.success("Profile image updated successfully.");
    } else {
      toast.error("Profile image not updated.");
    }
    setUserProfileSrc("");
    setUserFile("");
    setUserImageButtonStatus(0);
    setReload(!reload);
  };

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.type) {
      return state?.auth?.user?.role?.type;
    } else {
      return "";
    }
  });

  //console.log('Image', userProfileSrc, userFile);
  return (
    <div className={`ml-5 mr-5`}>
      <div className="table-header-dashbaord list-view-option-setting mt-5">
       
          <Button.Group
            className="add-people-btn"
            basic
            fluid
            style={{ width: "22%" }}
            size="medium"
          >
             {userRole == "super_admin" ? 
            <Button
              // active={activePane == 0}
              // onClick={() => changeTab(0)}
              type="button"
              active={true}
            >
              {t.common.company_profile}
            </Button> : 
        null}

            <Button
              // className="add-people-tab"
              onClick={() => history.push("/settings")}
              type="button"
             
            >
              {t.common.language}
            </Button>

            {/* <Button
active={activePane == 2}
onClick={() => {
  // setFieldValue('tab', 2).then((errors) => {
  //   changeTab(2, errors, setFieldTouched, values);
  //   if (errors) {
  //     scrollToError();
  //   }
  // });
}}
type="button"
>
{t.people.attachments_form.attachment_title}
</Button> */}
          </Button.Group>
        

        {/* <p className="single-view-setting ml-4 mr-4">
          <NavLink to="/settings"> {t.common.language}</NavLink>
        </p> */}
      </div>
      {/* <div className="header-actions">
        <Button.Group basic>
          <NavLink to="/company-profile">
            <Button
              color={getMenuItemActive('/company-profile', false) ? 'teal' : ''}
            >
              {t.common.company_profile}
            </Button>
          </NavLink>
          <NavLink to="/settings">
            <Button color={getMenuItemActive('/settings', false) ? 'teal' : ''}>
              {t.common.language}
            </Button>
          </NavLink>
        </Button.Group>
      </div> */}
      <div className="mb-5 pb-5">
        <div className="d-flex justify-content-between">
          <div></div>

          <div>
            <p className="invited-by-tag">
              {`${t.common.invited_by} Quiqflow`} {t.common.on}
              <Moment
                format="MMM, DD, YYYY"
                date={organisationData?.attributes?.createdAt}
                className="ml-2 mr-2"
              />
            </p>
          </div>
        </div>

        {organisationData ? (
          <Formik
            initialValues={{
              companyName: organisationData?.attributes?.name,
              description: organisationData?.attributes?.description || "",
              industry: organisationData?.attributes?.Industry || "",
              country: organisationData?.attributes?.country || "",
              currency: organisationData?.attributes?.currency || "",
              timezone: organisationData?.attributes?.timezone || "",
              profileImage: null,
            }}
            enableReinitialize
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              errors,
              touched,
              setFieldValue,
              values,
              setFieldTouched,
              handleBlur,
              submitForm,
            }) => (
              <FormikForm>
                <fieldset>
                  <legend>{` ${t.common.company_information} `}</legend>
                  <Grid columns="equal">
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">
                          {t.common.company_name}
                        </label>
                        <p>{values.companyName}</p>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">
                          {t.common.description}
                        </label>
                        <p>{values.description}</p>
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Grid columns="equal">
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">
                          {t.common.industry}
                        </label>
                        <p>{values.industry}</p>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className="m-5">
                      <Form.Field>
                        <label className="label-item">
                          {t.common.company_logo}
                        </label>
                        {imageSrc && (
                          <ImageCrop
                            // ratio={1 / 1}
                            src={imageSrc}
                            minWidth={20}
                            minHeight={20}
                            fileData={file}
                            onAccept={(croppedImageUrl) => {
                              //console.log('Cropped', croppedImageUrl);
                              let CroppedFile = new File(
                                [croppedImageUrl],
                                file.name,
                                { type: file.type }
                              );
                              //console.log(CroppedFile);
                              var randomnumber =
                                Math.floor(Math.random() * (0 - 100 + 1)) + 100;
                              setAttachments({
                                ...attachments,
                                profileImage: {
                                  file: CroppedFile,
                                  id: randomnumber,
                                  name: CroppedFile.name,
                                  type: CroppedFile.type,
                                  cancelPopup: false,
                                  isChanged: true,
                                },
                              });
                              // Resizer.imageFileResizer(
                              //   croppedImageUrl,
                              //   300,
                              //   300,
                              //   'PNG',
                              //   100,
                              //   0,
                              //   async (uri) => {
                              //     let data = {
                              //       fileName:
                              //         file.name.replace(/[\W_]+/g, '_') +
                              //         '.' +
                              //         file.type.split('/')[1],
                              //       source: uri,
                              //       folder: 'job',
                              //     };
                              //     api(`file`, data, 'post', true)
                              //       .then((data) => {
                              //         setFieldValue('image', data.data);
                              //       })
                              //       .catch((err) => {
                              //         Toast.error(
                              //           t(`apiResponses.${err.error}`) ||
                              //             t('generalValidation.somethingWrongTry')
                              //         );
                              //       });
                              //   },
                              //   'base64',
                              //   300,
                              //   300
                              // );
                              // };
                              // reader.readAsDataURL(croppedImageUrl);
                            }}
                            onCancel={() => {
                              setImageSrc("");
                              setFile("");
                            }}
                          />
                        )}
                        {attachments?.profileImage ? (
                          <List divided relaxed>
                            <Popup
                              key={attachments?.profileImage?.id}
                              content="Remove file?"
                              on="click"
                              pinned
                              open={attachments?.profileImage?.cancelPopup}
                              trigger={
                                <List.Item
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      userPermissions?.companySettings?.edit
                                    ) {
                                      let currentFile =
                                        attachments?.profileImage;
                                      currentFile.cancelPopup = !currentFile.cancelPopup;
                                      setAttachments({
                                        ...attachments,
                                        profileImage: currentFile,
                                      });
                                    }
                                  }}
                                >
                                  <List.Icon
                                    name={
                                      attachments?.profileImage?.type.match(
                                        "image.*"
                                      )
                                        ? "image"
                                        : attachments?.profileImage?.type.match(
                                            "pdf"
                                          )
                                        ? "file pdf"
                                        : "file"
                                    }
                                    size="large"
                                    color="black"
                                    verticalAlign="middle"
                                  />
                                  <List.Content>
                                    <List.Header>
                                      {attachments?.profileImage?.name}
                                      {userPermissions?.companySettings
                                        ?.edit ? (
                                        <Button
                                          icon
                                          primary
                                          title="Edit"
                                          className="ml-5"
                                          type="button"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            let currentFile =
                                              attachments?.profileImage;
                                            currentFile.cancelPopup = !currentFile.cancelPopup;
                                            setAttachments({
                                              ...attachments,
                                              profileImage: currentFile,
                                            });
                                          }}
                                        >
                                          <Icon name="edit" />
                                        </Button>
                                      ) : null}
                                    </List.Header>
                                  </List.Content>
                                </List.Item>
                              }
                            >
                              {userPermissions?.companySettings?.edit ? (
                                <>
                                  <Header as="h4">
                                    {t.common.remove_file}
                                  </Header>
                                  <Button
                                    color="black"
                                    type="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      let currentFile =
                                        attachments?.profileImage;
                                      currentFile.cancelPopup = false;
                                      setAttachments({
                                        ...attachments,
                                        profileImage: currentFile,
                                      });
                                    }}
                                  >
                                    {t.common.keep}
                                  </Button>
                                  <Button
                                    color="red"
                                    type="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setAttachments({
                                        ...attachments,
                                        profileImage: null,
                                      });
                                      setFile("");
                                      setImageSrc("");
                                    }}
                                  >
                                    {t.common.yes_remove}
                                  </Button>
                                </>
                              ) : null}
                            </Popup>
                          </List>
                        ) : (
                          <div>
                            {!file && (
                              <input
                                ref={contractCopyRef}
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => {
                                  // onFileChange(e, 'profileImage');
                                  let acceptedFiles = e.target.files;
                                  if (
                                    acceptedFiles &&
                                    acceptedFiles.length > 0
                                  ) {
                                    if (
                                      !acceptedFiles[0].type.startsWith(
                                        "image/"
                                      )
                                    ) {
                                      toast.error(
                                        "Uploaded file type is not allowed. Please select any image file to upload."
                                      );
                                      return;
                                    }
                                    setFile({
                                      name: acceptedFiles[0].name,
                                      type: acceptedFiles[0].type,
                                    });
                                    const reader = new FileReader();
                                    reader.addEventListener("load", () => {
                                      setImageSrc(reader.result);
                                    });
                                    reader.readAsDataURL(acceptedFiles[0]);
                                  }
                                }}
                              />
                            )}
                            <div
                              className="buttun-type-link"
                              onClick={() => contractCopyRef.current.click()}
                            >
                              <Icon name="add" />
                              {t.common.add}
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                                marginTop: "5px",
                                color: "#7e7e7e",
                              }}
                            >
                              {t.common.allowed_type} jpg, jpeg, png
                            </p>
                          </div>
                        )}
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Grid columns="equal">
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">{t.common.country}</label>
                        {/* <Dropdown
                        placeholder="Country"
                        fluid
                        search
                        selection
                        options={countryData}
                        value={values.country}
                        onChange={(e, value) => {
                          setFieldValue('country', value.value);
                        }}
                      /> */}
                        <ReactFlagsSelect
                          searchable
                          disabled={!userPermissions?.companySettings?.edit}
                          placeholder={t.common.country}
                          selected={values.country}
                          onSelect={(code) => {
                            setFieldValue("country", code);
                            let findCountry = currencyWithCountry.find(
                              (one) => one.country == code
                            );
                            if (findCountry) {
                              setFieldValue("currency", findCountry.value);
                            }
                            if (getCountryTimeZone(code)) {
                              setFieldValue(
                                "timezone",
                                getCountryTimeZone(code)
                              );
                            }
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">
                          {t.common.currency}
                        </label>
                        <Dropdown
                          placeholder={t.common.currency}
                          noResultsMessage={t.common.no_results_found}
                          fluid
                          search
                          selection
                          options={currencyData}
                          value={values.currency}
                          onChange={(e, value) => {
                            setFieldValue("currency", value.value);
                          }}
                          disabled={!userPermissions?.companySettings?.edit}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Grid columns="equal">
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">
                          {t.common.timezone}
                        </label>
                        <Dropdown
                          placeholder={t.common.timezone}
                          noResultsMessage={t.common.no_results_found}
                          fluid
                          search
                          selection
                          options={TIMEZONES}
                          value={values.timezone}
                          onChange={(e, value) => {
                            setFieldValue("timezone", value.value);
                          }}
                          disabled={!userPermissions?.companySettings?.edit}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className="m-5"></Grid.Column>
                  </Grid>
                </fieldset>
                <fieldset className="mt-5">
                  <legend>{` ${t.common.contact_information} `}</legend>
                  <Grid columns="equal">
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">
                          {t.common.user_name}
                        </label>
                        <p>
                          {organisationData?.attributes?.firstName}{" "}
                          {organisationData?.attributes?.lastName}
                        </p>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">
                          {t.common.user_email}
                        </label>
                        <p>{organisationData?.attributes?.Email}</p>
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                </fieldset>
                <fieldset className="mt-5">
                  <legend>{` ${t.common.license_information} `}</legend>
                  <Grid columns="equal">
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">{t.common.plan}</label>
                        <p>{organisationData?.attributes?.plan}</p>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className="m-5">
                      <Form.Field autoComplete="off">
                        <label className="label-item">
                          {t.common.licenses}
                        </label>
                        <p>{organisationData?.attributes?.Licenses}</p>
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                </fieldset>
                {userPermissions?.companySettings?.edit ? (
                  <div className="d-flex justify-content-center">
                    <Button
                      content={t.common.save}
                      primary
                      type="submit"
                      loading={buttonStatus == 1}
                      className="m-5"
                    />

                    <Button
                      content={t.common.cancel}
                      type="button"
                      basic
                      onClick={() => history.goBack()}
                      className="m-5"
                    />
                  </div>
                ) : null}
              </FormikForm>
            )}
          </Formik>
        ) : (
          "Loading ..."
        )}
      </div>
    </div>
  );
};

export default connect(null, auth.actions)(MyProfile);
